import { makeStyles, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { useCommonStyles } from '../../../../../hooks/useCommonStyles'
import { ChromeExtensionMode } from '../../../../../modules/chrome-extension-mode'
import { webConfig } from '../../../../../modules/web-config'
import { Gap } from '../../../../Gap'
import {
  GLOBAL_PADDING_X_CHROME_EXTENSION_UNIT,
  GLOBAL_PADDING_X_UNIT,
  MAC_DESKTOP_TOOLS_RESERVED_SPACE_PX,
} from '../constants'

const useStyles = makeStyles(
  theme => ({
    placeholder: {
      width: '100%',
      height: theme.spacing(8),
      flex: 'none',
    },
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: theme.spacing(8),
      flex: 'none',
      padding: theme.spacing(
        0,
        ChromeExtensionMode.isActive ? GLOBAL_PADDING_X_CHROME_EXTENSION_UNIT : GLOBAL_PADDING_X_UNIT
      ),
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      overflowX: 'auto',
      overflowY: 'hidden',
    },
  }),
  { name: 'TitleBarLayout' }
)

export function TitleBarLayout({
  placeholderClassName,
  className,
  children,
  ...otherProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { placeholderClassName?: string }) {
  const theme = useTheme()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  const isDesktop = webConfig.environment.agent === 'electron'
  const isMacDesktop = isDesktop && webConfig.os === 'macOS'

  return (
    <div className={classNames(classes.placeholder, placeholderClassName)}>
      <div {...otherProps} className={classNames(commonClasses.electronDrag, classes.root, className)}>
        {isMacDesktop && (
          <Gap
            horizontal={
              MAC_DESKTOP_TOOLS_RESERVED_SPACE_PX -
              theme.spacing(
                ChromeExtensionMode.isActive ? GLOBAL_PADDING_X_CHROME_EXTENSION_UNIT : GLOBAL_PADDING_X_UNIT
              )
            }
            px
          />
        )}

        {children}
      </div>
    </div>
  )
}

import { DecodedAccessToken } from '@zettelooo/server-shared'

export interface Authentication {
  readonly accessToken: string
  readonly refreshToken: string
  readonly decodedAccessToken: DecodedAccessToken
}

export namespace Authentication {
  export function decode(accessToken: string, refreshToken: string): Authentication {
    return {
      accessToken,
      refreshToken,
      decodedAccessToken: JSON.parse(window.atob(accessToken.split('.')[1])),
    }
  }
}

import { ZettelExtensions } from '@zettelooo/extension-api'
import { useEffect } from 'react'
import { useUpdate } from 'react-use'
import { useRefWrap } from '../../../../hooks/useRefWrap'
import { referenceKey } from './referenceKey'
import { stateAndListenersMap } from './stateAndListenersMap'
import { WithReferenceKey } from './withReferenceKey'

export function useState<S>(
  htmlContentWithReferenceKey: WithReferenceKey<ZettelExtensions.HtmlContent<S>>,
  options?: { noDuplicatedUpdateAlongWithRender?: boolean }
): S {
  const referenceValue = htmlContentWithReferenceKey[referenceKey]
  const stateAndListeners = stateAndListenersMap.get(referenceValue)
  const state = stateAndListeners ? stateAndListeners.state : htmlContentWithReferenceKey.initialState

  const update = useUpdate()

  const contentRef = useRefWrap({
    options,
    update,
  })

  useEffect(() => {
    if (stateAndListeners) {
      const listener: (typeof stateAndListeners.listeners)[number] = (newState, isRenderAlsoUpdated) => {
        if (!(contentRef.current.options?.noDuplicatedUpdateAlongWithRender && isRenderAlsoUpdated)) {
          contentRef.current.update()
        }
      }
      stateAndListeners.listeners.push(listener)
      return () => {
        const index = stateAndListeners.listeners.indexOf(listener)
        if (index >= 0) {
          stateAndListeners.listeners.splice(index, 1)
        }
      }
    }
  }, [stateAndListeners])

  return state
}

import { Id, Writable } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useCallback } from 'react'
import { useExtensionManager } from '../extension'

export function useCardDataDictionaryManipulators(): {
  updateCardDataDictionary(
    partiallyUpdatedCardDataDictionary: Model.DataDictionary,
    updatedExtensionId: Id,
    extensionIds: readonly Id[]
  ): Model.DataDictionary
  refineCardDataDictionary(cardDataDictionary: Model.DataDictionary, extensionIds: readonly Id[]): Model.DataDictionary
} {
  const { extensionManager } = useExtensionManager()

  return {
    updateCardDataDictionary: useCallback(
      (partiallyUpdatedCardDataDictionary, updatedExtensionId, extensionIds) => {
        const updatedData = partiallyUpdatedCardDataDictionary[updatedExtensionId]
        let updatedCardDataDictionary = partiallyUpdatedCardDataDictionary as Writable<Model.DataDictionary>
        extensionIds.forEach(extensionId => {
          if (extensionId === updatedExtensionId) return
          const data = partiallyUpdatedCardDataDictionary[extensionId]
          const extraction = extensionManager.extractCardData(updatedExtensionId, extensionId, updatedData)
          const newData = extensionManager.constructCardData(extensionId, data, [extraction], () =>
            Object.keys(partiallyUpdatedCardDataDictionary)
              .filter(otherExtensionId => otherExtensionId !== updatedExtensionId && otherExtensionId !== extensionId)
              .map(otherExtensionId =>
                extensionManager.extractCardData(
                  otherExtensionId,
                  extensionId,
                  partiallyUpdatedCardDataDictionary[otherExtensionId]
                )
              )
          )
          if (newData === data) return
          if (updatedCardDataDictionary === partiallyUpdatedCardDataDictionary) {
            updatedCardDataDictionary = { ...partiallyUpdatedCardDataDictionary }
          }
          if (newData === undefined) {
            delete updatedCardDataDictionary[extensionId]
          } else {
            updatedCardDataDictionary[extensionId] = newData
          }
        })
        return updatedCardDataDictionary
      },
      [extensionManager]
    ),

    refineCardDataDictionary: useCallback(
      (cardDataDictionary, extensionIds) => {
        // Add missing card data:
        const cardDataExtensionIds = Object.keys(cardDataDictionary)
        const extendedCardDataDictionary = extensionIds.reduce((current, extensionId) => {
          if (extensionId in cardDataDictionary) return current
          const extractions = cardDataExtensionIds.map(cardDataExtensionId =>
            extensionManager.extractCardData(cardDataExtensionId, extensionId, cardDataDictionary[cardDataExtensionId])
          )
          const addedPublicData = extensionManager.constructCardData(extensionId, undefined, extractions, () => [])
          if (addedPublicData === undefined) return current
          if (current === cardDataDictionary)
            return {
              ...cardDataDictionary,
              [extensionId]: addedPublicData,
            }
          current[extensionId] = addedPublicData
          return current
        }, cardDataDictionary as Writable<typeof cardDataDictionary>)

        // Remove extra card data:
        const toBeRemovedExtensionIds = Object.keys(extendedCardDataDictionary).filter(
          extensionId => !extensionIds.includes(extensionId)
        )
        if (toBeRemovedExtensionIds.length === 0) return extendedCardDataDictionary
        const prunedCardDataDictionary =
          extendedCardDataDictionary === cardDataDictionary ? { ...cardDataDictionary } : extendedCardDataDictionary
        toBeRemovedExtensionIds.forEach(extensionId => {
          delete prunedCardDataDictionary[extensionId]
        })

        return prunedCardDataDictionary
      },
      [extensionManager]
    ),
  }
}

import { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useRefWrap } from '../../../../hooks/useRefWrap'
import { webConfig } from '../../../../modules/web-config'
import { AppHistoryContext } from './AppHistoryProvider'
import { getLocationPath } from './getLocationPath'
import { AppHistoryState } from './types'

export function useAppHistoryState(): {
  appHistoryStateStatic: {
    readonly currentPath: string
    readonly backwardPath: string | undefined
    readonly forwardPath: string | undefined

    readonly canGoBackward: boolean
    readonly canGoForward: boolean
  }
} {
  const { stateRef } = useContext(AppHistoryContext)

  const location = useLocation<AppHistoryState>()

  const locationRef = useRefWrap(location)

  function getStateStatic(): AppHistoryState {
    return webConfig.environment.agent === 'electron' ? stateRef.current : locationRef.current.state
  }

  return {
    appHistoryStateStatic: useMemo(
      () => ({
        get currentPath() {
          return getLocationPath(locationRef.current)
        },

        get backwardPath() {
          const state = getStateStatic()
          return state?.stack[state.index - 1]
        },

        get forwardPath() {
          const state = getStateStatic()
          return state?.stack[state.index + 1]
        },

        get canGoBackward() {
          return Boolean(this.backwardPath)
        },

        get canGoForward() {
          return Boolean(this.forwardPath)
        },
      }),
      []
    ),
  }
}

import { KeyValueEngineByDatabase, createKeyValueStore } from '../../../../modules/key-value-store'
import { PersistentDatabase } from '../databases'
import { defaultValues } from './defaultValues'
import { equalityCheckers } from './equalityCheckers'
import { initializePersistent } from './initializePersistent'
import { Persistent } from './types'

export async function createPersistentOnDatabase(persistentDatabase: PersistentDatabase): Promise<Persistent> {
  const engine = await KeyValueEngineByDatabase.create(() => defaultValues, equalityCheckers, persistentDatabase)
  const persistent = createKeyValueStore(engine)

  initializePersistent(persistent)

  return persistent
}

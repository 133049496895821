import { Divider, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  theme => ({
    root: {
      marginBottom: theme.spacing(4),
    },
  }),
  { name: 'Separator' }
)

export function Separator() {
  const classes = useStyles()

  return <Divider className={classes.root} />
}

import qs from 'qs'

interface ExpectedInitialQuery {
  m: 'demo' | 'peek' | 'chrome' // Mode
  t: 'light' | 'dark' // Theme, for demo mode
  l: string // Language, for demo mode
  u: string // Username, for demo mode
  at: string // Access token, for peek mode
  rt: string // Refresh token, for peek mode
}
type InitialQuery = {
  readonly [K in keyof ExpectedInitialQuery]?: (string & {}) | ExpectedInitialQuery[K]
}

export const initialQuery: InitialQuery =
  globalThis.window && globalThis.window.document.location.pathname.toLowerCase() === '/'
    ? qs.parse(globalThis.window.document.location.search, { ignoreQueryPrefix: true })
    : {}

import { HandlerOutput, Id } from '@zettelooo/commons'
import { BrowserSuggestion } from '@zettelooo/desktop-shared'
import { Model } from '@zettelooo/server-shared'

export enum CompromiseResultType {
  PhoneNumber = 'PHONENUMBER',
  HashTag = 'HASHTAG',
  Email = 'EMAIL',
  AtMention = 'ATMENTION',
  Url = 'URL',
  Person = 'PERSON',
  Place = 'PLACE',
  Organization = 'ORGANIZATION',
}

export interface CardSearchResult {
  readonly id: Id
  readonly card: Model.Card
  readonly text: string
  readonly compromiseResult?: {
    readonly type: CompromiseResultType
    readonly value: string
  }
}

export type SearchResultItem<T extends SearchResultItem.Type = SearchResultItem.Type> = {
  [SearchResultItem.Type.CardSearchResult]: {
    readonly type: SearchResultItem.Type.CardSearchResult
    readonly cardSearchResult: CardSearchResult
  }
  [SearchResultItem.Type.BrowserSuggestion]: {
    readonly type: SearchResultItem.Type.BrowserSuggestion
    readonly browserSuggestion: BrowserSuggestion
  }
  [SearchResultItem.Type.BrowserSearchQuery]: {
    readonly type: SearchResultItem.Type.BrowserSearchQuery
    readonly searchEngine?: BrowserSuggestion.SearchEngine
    readonly query: string
  }
  [SearchResultItem.Type.BrowserBrowseUrl]: {
    readonly type: SearchResultItem.Type.BrowserBrowseUrl
    readonly url: string
  }
}[T] & {
  readonly id: Id
  handleSubmit(source: 'click' | 'keyboard'): HandlerOutput
  handleCtrlSubmit?(source: 'click' | 'keyboard'): HandlerOutput
}

export namespace SearchResultItem {
  export enum Type {
    CardSearchResult = 'CARD_SEARCH_RESULT',
    BrowserSuggestion = 'BROWSER_SUGGESTION',
    BrowserSearchQuery = 'BROWSER_SEARCH_QUERY',
    BrowserBrowseUrl = 'BROWSER_BROWSE_URL',
  }
}

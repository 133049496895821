import { IconButton, List, ListItem, makeStyles } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import { useContext, useLayoutEffect, useRef } from 'react'
import { arrayHelpers } from '../../../../../../../../../helpers/native/arrayHelpers'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { tokenize } from '../../../../../../../../../modules/search'
import { TypographyNoWrap } from '../../../../../../../../TypographyNoWrap'
import { usePersistent, PersistentKey } from '../../../../../../../modules/persistent'
import { Initializer } from '../../../../../../Initializer'
import { SearchProvider, useSearchCards } from '../../../../../modules/search'
import { Panel, useSwitchPanel } from '../../panel'
import { SearchItemLayout } from './SearchItemLayout'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(1),
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
    },
    clearButton: {
      marginRight: theme.spacing(1),
    },
    list: {
      flexGrow: 1,
      margin: theme.spacing(1),
      maxHeight: theme.spacing(45),
      overflowY: 'auto',
    },
    item: {
      marginBottom: theme.spacing(1.5),
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      height: theme.spacing(7.25),
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      '&:not(.Mui-selected)': {
        borderRadius: theme.spacing(1),
      },
    },
  }),
  { name: 'SearchRecents' }
)

export function SearchRecents({
  selectedCardIndex,
  setSelectedCardIndex,
  onSelectCard,
}: {
  selectedCardIndex: number
  setSelectedCardIndex(index: number): void
  onSelectCard(card: Model.Card): void
}) {
  const { persistent } = usePersistent(PersistentKey.RecentSearches)

  const { allPages } = useContexts(Initializer.Contexts)
  const { cardSearchContentsDictionaryStatic } = useContext(SearchProvider.Context)

  const cardListRef = useRef<HTMLUListElement>(null)

  const { switchPanelStatic } = useSwitchPanel()

  useLayoutEffect(() => {
    cardListRef.current?.children[selectedCardIndex]?.scrollIntoView({ block: 'nearest' })
  })

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <TypographyNoWrap variant="subtitle1" color="textSecondary">
          Recent
        </TypographyNoWrap>
        <IconButton
          onClick={() => {
            persistent[PersistentKey.RecentSearches] = []
          }}
          className={classes.clearButton}
        >
          <CustomIcon name="Close" size="small" color="text.secondary" />
        </IconButton>
      </div>

      <List ref={cardListRef} disablePadding className={classes.list}>
        {persistent[PersistentKey.RecentSearches].map((recentSearch, index) => {
          const cardSearchContent = cardSearchContentsDictionaryStatic[recentSearch.cardId]
          if (!cardSearchContent) return null

          return (
            <ListItem
              key={index}
              className={classes.item}
              selected={index === selectedCardIndex}
              onMouseMove={() => {
                if (selectedCardIndex === index) return
                setSelectedCardIndex(index)
              }}
              onClick={() => {
                onSelectCard(cardSearchContent.card)

                const page = allPages.dictionary[cardSearchContent.card.pageId]
                if (!page) return

                switchPanelStatic({
                  type: Panel.Type.Page,
                  pageId: page.id,
                  cardId: cardSearchContent.card.id,
                })
              }}
            >
              <SearchItemLayout
                item={{
                  id: recentSearch.cardId,
                  card: cardSearchContent.card,
                  text:
                    recentSearch.filter === useSearchCards.SearchFilter.All
                      ? cardSearchContent.text
                      : recentSearch.filter === useSearchCards.SearchFilter.Files
                      ? cardSearchContent.files
                      : '',
                }}
                tokens={arrayHelpers.distinct(tokenize(recentSearch.query, 1))}
              />
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}

import { PaletteType } from '@material-ui/core'
import { webConfig } from '../web-config'
import { Data } from './types'

export const data = ((): Data => {
  const { m, t, l, u } = webConfig.initialQuery

  if (m !== 'demo') return undefined

  const themeType: PaletteType | undefined = t === 'light' ? 'light' : t === 'dark' ? 'dark' : undefined
  if (!themeType) return undefined

  const languageCode = l
  if (!languageCode || typeof languageCode !== 'string') return undefined

  const username = u
  if (username && typeof username !== 'string') return undefined

  return {
    themeType,
    languageCode,
    ...(username ? { username } : {}),
  }
})()

import { delay } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useEffect, useMemo } from 'react'
import { arrayHelpers } from '../../../../../helpers/native/arrayHelpers'
import { useRefWrap } from '../../../../../hooks/useRefWrap'
import { DemoMode } from '../../../../../modules/demo-mode'
import { webConfig } from '../../../../../modules/web-config'
import { PeriodicAsyncAction } from '../../../../../types/PeriodicAsyncAction'
import { useDatabases } from '../../databases'
import { EphemeralKey, useEphemeral } from '../../ephemeral'
import { isExtensionCompatible } from '../../extension'
import { PersistentKey, usePersistent } from '../../persistent'
import { useServices } from '../../services'

export function useExtensionsDatabaseMaintenance(): void {
  const [, authentication] = usePersistent(PersistentKey.Authentication)

  const [ephemeral, onMemoryModelsRegistrations, newlyUpdatedExtensionIds] = useEphemeral(
    EphemeralKey.OnMemoryModelsRegistrations,
    EphemeralKey.NewlyUpdatedExtensionIds
  )

  const contentRef = useRefWrap({
    authentication,
    onMemoryModelsRegistrations,
  })

  const { databases } = useDatabases()

  const { services } = useServices()

  const periodicAsyncAction = useMemo(
    () =>
      new PeriodicAsyncAction({
        successInterval: webConfig.timings.retryMaintainingExtensionsDatabase.succeeded,
        failureInterval: webConfig.timings.retryMaintainingExtensionsDatabase.failed,

        async action() {
          if (DemoMode.data) {
            await delay(1000) // Absolutely no idea why this delay solves not triggering extensions updates problem in the Demo mode!
          }
          const onDatabasePages = await databases.mutablesDatabase.getAllPages()
          const onDatabasePageExtensionIds = onDatabasePages.flatMap(page =>
            Model.ExtensionConfiguration.getExtensionIds(page.extensionConfiguration)
          )
          const onMemoryModelsExtensionIds = Object.values(contentRef.current.onMemoryModelsRegistrations).flatMap(
            onMemoryModels =>
              onMemoryModels[Model.Type.Page]?.flatMap(page =>
                Model.ExtensionConfiguration.getExtensionIds(page.extensionConfiguration)
              ) ?? []
          )
          const requiredExtensionIds = arrayHelpers.distinctString([
            ...onDatabasePageExtensionIds,
            ...onMemoryModelsExtensionIds,
          ])
          const { headers: requiredExtensionHeaders } =
            requiredExtensionIds.length > 0
              ? await services.extension.getHeadersByIds(requiredExtensionIds)
              : { headers: [] }
          const requiredCompatibleExtensionHeaders: typeof requiredExtensionHeaders = []
          const requiredIncompatibleExtensionHeaders: typeof requiredExtensionHeaders = []
          requiredExtensionHeaders.forEach(extensionHeader =>
            (isExtensionCompatible(extensionHeader)
              ? requiredCompatibleExtensionHeaders
              : requiredIncompatibleExtensionHeaders
            ).push(extensionHeader)
          )
          requiredIncompatibleExtensionHeaders.forEach(extensionHeader => {
            log.log(
              `Incompatible extension "${extensionHeader.name}" ${extensionHeader.version} (${extensionHeader.id})`
            )
          })
          const requiredCompatibleExtensionHeadersById = arrayHelpers.toDictionaryById(
            requiredCompatibleExtensionHeaders
          )
          const existingExtensionHeaders = await databases.extensionsDatabase.getAllHeaders()
          const existingExtensionHeadersById = arrayHelpers.toDictionaryById(existingExtensionHeaders)
          const toBeRemovedExtensionIds = existingExtensionHeaders
            .filter(extensionHeader => !(extensionHeader.id in requiredCompatibleExtensionHeadersById))
            .map(extensionHeader => extensionHeader.id)
          const toBeAddedOrUpdatedExtensionHeaders = requiredCompatibleExtensionHeaders.filter(
            extensionHeader =>
              !(extensionHeader.id in existingExtensionHeadersById) ||
              extensionHeader.version !== existingExtensionHeadersById[extensionHeader.id].version
          )
          // TODO: If there are excluding extensions due to incompatible Extension API (above checks), display appropriate messages, hinting users to either update their clients into the latest version or wait until the extension developers update their extensions
          await Promise.all(
            toBeRemovedExtensionIds.map(extensionId => databases.extensionsDatabase.remove(extensionId))
          )
          await Promise.all(
            toBeAddedOrUpdatedExtensionHeaders.map(async extensionHeader => {
              const extensionBody = await services.extension.getBody(extensionHeader)
              const extension = {
                ...extensionHeader,
                ...extensionBody,
              }
              await databases.extensionsDatabase.put(extension)
            })
          )
        },

        onFailure(error) {
          log.error('useExtensionsDatabaseMaintenance', error)
          // There are too many reports and on-screen feedback modals while this request can not be fulfilled:
          // // We don't need to fetch network related errors:
          // if (error.constructor !== TypeError) {
          //   captureException(error, { tags: { module: 'extensions' }, })
          // }
        },

        initiallyStarted: true,
      }),
    [databases, services]
  )

  useEffect(() => periodicAsyncAction.actImmediate(), [authentication, onMemoryModelsRegistrations])

  useEffect(() => {
    if (newlyUpdatedExtensionIds.length > 0) {
      // TODO: Do it more specifically depending on the newlyUpdatedExtensionIds, in order to be more performant:
      periodicAsyncAction.actImmediate()
      ephemeral(EphemeralKey.NewlyUpdatedExtensionIds).set([])
    }
  }, [newlyUpdatedExtensionIds])

  useEffect(() => {
    const { subscriptionKey } = databases.mutablesDatabase.subscribe({
      handleMutations(models) {
        if (models.some(model => model.type === Model.Type.Account || model.type === Model.Type.Page)) {
          periodicAsyncAction.actImmediate()
        }
      },
      handleReload() {
        periodicAsyncAction.actImmediate()
      },
    })

    return () => {
      databases.mutablesDatabase.unsubscribe(subscriptionKey)
    }
  }, [databases, periodicAsyncAction])
}

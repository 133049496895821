import { DependencyList, useContext, useEffect, useMemo } from 'react'
import { CommandsContext } from '../CommandsProvider'
import { CommandGroup } from '../types'

export function useCommandGroupRegistration(
  groupFactory: () => CommandGroup,
  dependencies: DependencyList,
  options?: {
    removed?: boolean
    disabled?: boolean
  }
): void {
  const { commandManager } = useContext(CommandsContext)

  const group = useMemo(groupFactory, dependencies)

  const registrationKey = useMemo(
    () => (options?.removed ? undefined : commandManager.registerCommandGroup(group.name)),
    [options?.removed, commandManager, group.name]
  )

  useEffect(
    () => () => {
      if (registrationKey) {
        commandManager.unregisterCommandGroup(registrationKey)
      }
    },
    [commandManager, registrationKey]
  )

  useEffect(() => {
    if (registrationKey) {
      if (options?.disabled) {
        commandManager.clearCommandGroupRegistration(registrationKey)
      } else {
        commandManager.setCommandGroupRegistration(registrationKey, group)
      }
    }
  }, [options?.disabled, commandManager, registrationKey, group])
}

import { Divider, List, makeStyles, MenuItem, Switch, Typography, useTheme } from '@material-ui/core'
import { getEnumKeyValues } from '../../../../../../../helpers/getEnumKeyValues'
import { Gap } from '../../../../../../Gap'
import { useAppHistoryControl } from '../../../../../modules/app-history'
import { usePersistent, PersistentKey } from '../../../../../modules/persistent'
import { AutomaticUpdater } from '../../../../../modules/update'
import { routes } from '../../../modules/routes'
import { ButtonPanel } from './panels/ButtonPanel'
import { TypographyPanel } from './panels/TypographyPanel'
import { Panel } from './types'

const useStyles = makeStyles(
  theme => ({
    root: {
      height: '100vh',
      display: 'flex',
    },
    sidebar: {
      width: theme.spacing(40),
      height: '100%',
      overflowY: 'auto',
      flex: 'none',
    },
    content: {
      flex: 'auto',
      overflow: 'auto',
    },
    themeSwitchContainer: {
      padding: theme.spacing(1, 2),
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: 'DesignPage' }
)

export function DesignPage() {
  const { panel } = routes.design.useParams()

  const { persistent } = usePersistent()

  const { appHistoryControlStatic } = useAppHistoryControl()

  const theme = useTheme()

  const classes = useStyles()

  return (
    <>
      <AutomaticUpdater />

      <div className={classes.root}>
        <div className={classes.sidebar}>
          <div className={classes.themeSwitchContainer}>
            <Typography variant="subtitle1">Theme:</Typography>
            <Gap grow />
            <Typography variant="button">Light</Typography>
            <Switch
              color="default"
              checked={theme.palette.type === 'dark'}
              onChange={(event, checked) => persistent(PersistentKey.ThemeType).set(checked ? 'dark' : 'light')}
            />
            <Typography variant="button">Dark</Typography>
          </div>

          <Divider />

          <List>
            {getEnumKeyValues<Panel>(Panel).map(({ key, value }) => (
              <MenuItem
                key={value}
                selected={panel === value}
                onClick={() => appHistoryControlStatic.push(routes.design.getPath({ panel: value }, {}))}
              >
                {key}
              </MenuItem>
            ))}
          </List>
        </div>

        <Divider orientation="vertical" />

        <div className={classes.content}>
          {((): React.JSX.Element => {
            switch (panel as Panel) {
              case Panel.Typography:
                return <TypographyPanel />

              case Panel.Button:
                return <ButtonPanel />
            }
          })()}
        </div>
      </div>
    </>
  )
}

import { makeStyles, Switch, Tooltip, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { KeyboardCombinationView } from '../../../../../../../../../modules/keyboard-handler'
import { PersistentKey, usePersistent } from '../../../../../../../modules/persistent'

const useStyles = makeStyles(
  theme => ({
    root: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
    },
    switchThumb: {
      position: 'relative',
      '&::after': {
        content: theme.palette.type === 'light' ? "'\u263C'" : "'\u263D'",
        position: 'absolute',
        fontSize: theme.spacing(2),
        color: theme.palette.text.primary,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
  }),
  { name: 'ThemeSwitchItem' }
)

export function ThemeSwitchItem() {
  const { persistent } = usePersistent()

  const theme = useTheme()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <div className={classNames(classes.root, commonClasses.electronNoDrag)}>
      <Tooltip
        title={
          <KeyboardCombinationView
            combination={{ cmd: true, shift: true, code: 'KeyL' }}
            prefix={`Turn to ${theme.palette.type === 'dark' ? 'light' : 'dark'} mode`}
            highlight
          />
        }
      >
        <Switch
          classes={{ thumb: classes.switchThumb }}
          checked={theme.palette.type === 'dark'}
          onChange={(_, checked) => persistent(PersistentKey.ThemeType).set(checked ? 'dark' : 'light')}
        />
      </Tooltip>
    </div>
  )
}

import { makeStyles, Typography } from '@material-ui/core'
import { BrowserSuggestion } from '@zettelooo/desktop-shared'
import { pascalCase } from 'change-case'
import classNames from 'classnames'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { DefaultedImage } from '../../../../../../../../DefaultedImage'
import { SearchResultItem } from '../types'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      height: theme.spacing(4),
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      cursor: 'pointer',
    },
    iconWrapper: {
      width: theme.spacing(4),
      display: 'flex',
      justifyContent: 'center',
      flex: 'none',
    },
    favicon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      objectFit: 'contain',
    },
    title: {
      width: 0,
      flex: 'auto',
      overflow: 'hidden',
    },
    info: {
      color: theme.palette.info.main,
    },
    dash: {
      '&::before': {
        content: '"\u00A0\u2013\u00A0"', // &nbsp;&ndash;&nbsp;
        color: theme.palette.text.hint,
      },
    },
  }),
  { name: 'BrowserSuggestionItem' }
)

export function BrowserSuggestionItem({
  item,
}: {
  item: SearchResultItem<
    | SearchResultItem.Type.BrowserSuggestion
    | SearchResultItem.Type.BrowserSearchQuery
    | SearchResultItem.Type.BrowserBrowseUrl
  >
}) {
  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  switch (item.type) {
    case SearchResultItem.Type.BrowserSuggestion:
      switch (item.browserSuggestion.type) {
        case BrowserSuggestion.Type.SearchHistory:
          return (
            <div className={classNames(classes.root, commonClasses.electronNoDrag)}>
              <div className={classes.iconWrapper}>
                <CustomIcon name="Time" size={2.5} color="text.secondary" />
              </div>
              <div className={classes.title}>
                <Typography variant="subtitle1" color="textPrimary" noWrap>
                  {item.browserSuggestion.query}
                </Typography>
              </div>
            </div>
          )

        case BrowserSuggestion.Type.BrowseHistory:
          return (
            <div className={classNames(classes.root, commonClasses.electronNoDrag)}>
              <div className={classes.iconWrapper}>
                <DefaultedImage src={item.browserSuggestion.faviconUrl} className={classes.favicon}>
                  <CustomIcon name="Globe" size={2.5} color="text.secondary" />
                </DefaultedImage>
              </div>
              <div className={classes.title}>
                <Typography variant="subtitle1" display="inline" color="textPrimary" noWrap>
                  {item.browserSuggestion.title}
                </Typography>
                <Typography variant="body2" display="inline" color="textSecondary" noWrap className={classes.info}>
                  <span className={classes.dash} />
                  {item.browserSuggestion.url}
                </Typography>
              </div>
            </div>
          )

        case BrowserSuggestion.Type.Bookmark:
          return (
            <div className={classNames(classes.root, commonClasses.electronNoDrag)}>
              <div className={classes.iconWrapper}>
                <DefaultedImage src={item.browserSuggestion.faviconUrl} className={classes.favicon}>
                  <CustomIcon name="Globe" size={2.5} color="text.secondary" />
                </DefaultedImage>
              </div>
              <div className={classes.title}>
                <Typography variant="subtitle1" display="inline" color="textPrimary" noWrap>
                  {item.browserSuggestion.title}
                </Typography>
                <Typography variant="body2" display="inline" color="textSecondary" noWrap className={classes.info}>
                  <span className={classes.dash} />
                  {item.browserSuggestion.url}
                </Typography>
              </div>
            </div>
          )

        case BrowserSuggestion.Type.SearchEngineSuggestedQuery:
          return (
            <div className={classNames(classes.root, commonClasses.electronNoDrag)}>
              <div className={classes.iconWrapper}>
                <CustomIcon name="Search" size={2.5} color="text.secondary" />
              </div>
              <div className={classes.title}>
                <Typography variant="subtitle1" color="textPrimary" noWrap>
                  {item.browserSuggestion.suggestedQuery}
                </Typography>
              </div>
            </div>
          )

        case BrowserSuggestion.Type.SearchEngineSuggestedUrl:
          return (
            <div className={classNames(classes.root, commonClasses.electronNoDrag)}>
              <div className={classes.iconWrapper}>
                <DefaultedImage src={item.browserSuggestion.faviconUrl} className={classes.favicon}>
                  <CustomIcon name="Globe" size={2.5} color="text.secondary" />
                </DefaultedImage>
              </div>
              <div className={classes.title}>
                <Typography variant="subtitle1" color="textPrimary" noWrap>
                  {item.browserSuggestion.primaryText}
                </Typography>
                <Typography variant="body2" color="textSecondary" noWrap>
                  {item.browserSuggestion.secondaryText}
                </Typography>
              </div>
            </div>
          )

        default:
          throw Error('Unsupported browser suggestion type.')
      }

    case SearchResultItem.Type.BrowserSearchQuery:
      return (
        <div className={classNames(classes.root, commonClasses.electronNoDrag)}>
          <div className={classes.iconWrapper}>
            <DefaultedImage src={item.searchEngine?.faviconUrl} className={classes.favicon}>
              <CustomIcon name="Search" size={2.5} color="text.secondary" />
            </DefaultedImage>
          </div>
          <div className={classes.title}>
            <Typography variant="subtitle1" display="inline" color="textPrimary" noWrap>
              {item.query}
            </Typography>
            {item.searchEngine?.name && (
              <Typography variant="body2" display="inline" color="textSecondary" noWrap>
                <span className={classes.dash} />
                {pascalCase(item.searchEngine.name)} Search
              </Typography>
            )}
          </div>
        </div>
      )

    case SearchResultItem.Type.BrowserBrowseUrl:
      return (
        <div className={classNames(classes.root, commonClasses.electronNoDrag)}>
          <div className={classes.iconWrapper}>
            <CustomIcon name="Globe" size={2.5} color="text.secondary" />
          </div>
          <div className={classes.title}>
            <Typography variant="body2" display="inline" color="textSecondary" noWrap className={classes.info}>
              {item.url}
            </Typography>
          </div>
        </div>
      )
  }
}

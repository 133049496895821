import { makeStyles, Typography } from '@material-ui/core'
import { useSyncEffect } from '../../../../../../hooks/useSyncEffect'
import { Authentication } from '../../../../modules/authentication'
import { usePersistent, PersistentKey } from '../../../../modules/persistent'
import { routes } from '../../modules/routes'
import { RedirectToHome } from '../RedirectToHome'

const useStyles = makeStyles(
  theme => ({
    redirectingMessage: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  { name: 'AuthenticatedPage' }
)

// TODO: We don't seem to open this path with search parameters like `registered` or `mobile` anymore.
//       Currently, this page only get's opened from the sign-in page, only after successful sign-in to authenticate the app.
//       For mobile, later on, pass it to the sign-in page directly instead of here.

export function AuthenticatedPage() {
  const { persistent } = usePersistent()

  const { at, rt, mobile } = routes.authenticated.useQuery()

  useSyncEffect(() => {
    if (!mobile && at && rt) {
      persistent[PersistentKey.Authentication] = Authentication.decode(at, rt)
    }
  }, [mobile, at, rt])

  const classes = useStyles()

  if (mobile) {
    window.location.href = `${mobile}://?at=${at}&rt=${rt}`
    return (
      <div className={classes.redirectingMessage}>
        <Typography variant="h4">Redirecting to the app...</Typography>
      </div>
    )
  }

  return <RedirectToHome />
}

import { ZettelExtensions } from '@zettelooo/extension-api'
import { useContexts } from '../../../../../../../../modules/contexts'
import { useExtensionLifeSpan } from '../../../../../../modules/extension'
import { PersistentKey, usePersistent } from '../../../../../../modules/persistent'
import { AccountData } from '../../../../modules/account-data'
import { Panel, useSwitchPanel } from '../panel'

export function useExtensionMainViewLifeSpan(
  panel: Panel | undefined,
  switchPanelStatic: ReturnType<typeof useSwitchPanel>['switchPanelStatic']
): {
  // titleBarItems: readonly ZettelExtensions.LifeSpan.Shared.MainView.TitleBarItem[]
} {
  const [, deviceId] = usePersistent(PersistentKey.DeviceId)

  const { account } = useContexts(AccountData.Contexts)

  // const [titleBarItems, setTitleBarItems] = useState<readonly ZettelExtensions.LifeSpan.Shared.MainView.TitleBarItem[]>([])

  useExtensionLifeSpan({
    name: 'mainView',
    target: {},
    role: 'MainPage',
    scopedValues: {
      [ZettelExtensions.Scope.Device]: deviceId,
      [ZettelExtensions.Scope.User]: account.id,
    },
    dataFactories: {
      openedPageId: panel?.type === Panel.Type.Page ? panel.pageId : undefined,
    },
    accessFactory: () => ({
      openPage(pageId, cardId) {
        switchPanelStatic({
          type: Panel.Type.Page,
          pageId,
          cardId,
        })
      },
    }),
    registryFactory: () => ({
      // titleBarItem(getter) {
      //   let currentValue: ReturnType<typeof getter>
      //   const reference: { current?: ZettelExtensions.LifeSpan.Shared.MainView.TitleBarItem.Reference } = {}
      //   return [
      //     () => {
      //       currentValue = getter()
      //       reference.current = {
      //         update(updates) {
      //           setTitleBarItems(current => {
      //             const evaluatedUpdates = typeof updates === 'function' ? updates(currentValue) : updates
      //             const oldValue = currentValue
      //             const newValue = { ...oldValue, ...evaluatedUpdates }
      //             currentValue = newValue
      //             return arrayHelpers.replace(current, oldValue, newValue)
      //           })
      //         },
      //       }
      //       setTitleBarItems(current => [...current, currentValue])
      //       return () => {
      //         setTitleBarItems(current => arrayHelpers.remove(current, currentValue))
      //         delete reference.current
      //       }
      //     },
      //     reference,
      //   ]
      // },
    }),
    dependencies: [deviceId, account.id],
  })

  return {
    // titleBarItems,
  }
}

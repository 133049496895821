import { useContext } from 'react'
import { ConnectionStatusContext } from './ConnectionStatusProvider'

export function useConnectionStatus(): {
  isOnline: boolean
  isConnectedToServer: boolean
} {
  const { isOnline, isConnectedToServer } = useContext(ConnectionStatusContext)

  return {
    isOnline,
    isConnectedToServer,
  }
}

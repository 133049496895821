export function is<T>(target: T): {
  oneOf(...items: T[]): boolean
  notOneOf(...items: T[]): boolean
} {
  return {
    oneOf(...items) {
      return items.includes(target)
    },
    notOneOf(...items) {
      return !items.includes(target)
    },
  }
}

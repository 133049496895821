import { Id } from '@zettelooo/commons'
import { webConfig } from '../../web-config'

export function getFilePreviewUrl(
  fileId: Id,
  options:
    | {
        readonly width: number
        revisePreviewWidth?(previewWidth: number): number
      }
    | {
        readonly height: number
        revisePreviewHeight?(previewHeight: number): number
      }
    | {
        readonly width: number
        readonly height: number
        readonly objectFit: 'contain' | 'cover'
        revisePreviewWidthAndHeight?(
          previewWidth: number,
          previewHeight: number
        ): [revisedPreviewWidth: number, revisedPreviewHeight: number]
      },
  fileName?: string
): string {
  if (!fileId) return ''

  const previewWidth = 'width' in options && Math.round(options.width * window.devicePixelRatio)
  const previewHeight = 'height' in options && Math.round(options.height * window.devicePixelRatio)
  const [revisedPreviewWidth, revisedPreviewHeight] =
    previewWidth && previewHeight && 'revisePreviewWidthAndHeight' in options && options.revisePreviewWidthAndHeight
      ? options.revisePreviewWidthAndHeight(previewWidth, previewHeight)
      : [
          previewWidth && 'revisePreviewWidth' in options && options.revisePreviewWidth
            ? options.revisePreviewWidth(previewWidth)
            : previewWidth,
          previewHeight && 'revisePreviewHeight' in options && options.revisePreviewHeight
            ? options.revisePreviewHeight(previewHeight)
            : previewHeight,
        ]

  const queryParts: string[] = []
  if (revisedPreviewWidth) {
    queryParts.push(`w=${revisedPreviewWidth}`)
  }
  if (revisedPreviewHeight) {
    queryParts.push(`h=${revisedPreviewHeight}`)
  }
  if ('objectFit' in options) {
    queryParts.push(`o=${options.objectFit}`)
  }

  const query = queryParts.join('&')

  return `${webConfig.services.baseUrls.preview}/${fileId}${fileName ? `/${fileName}` : ''}${query ? `?${query}` : ''}`
}

import { List, makeStyles, ListItem } from '@material-ui/core'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useCommonStyles } from '../../../../../../../hooks/useCommonStyles'
import { sendAnalyticEvent } from '../../../../../../../modules/analytics'
import { CustomIcon } from '../../../../../../../modules/custom-icon'
import { useKeyboardHandling, commonKeyboardCombinations } from '../../../../../../../modules/keyboard-handler'
import { TypographyNoWrap } from '../../../../../../TypographyNoWrap'
import { BookmarkMessage } from './types'

const useStyles = makeStyles(
  theme => ({
    root: {
      maxHeight: theme.spacing(36.5),
      overflowY: 'auto',
    },
    item: {
      marginBottom: theme.spacing(0.25),
      height: theme.spacing(6),
      color: theme.palette.text.secondary,
      borderRadius: theme.spacing(1.5),
      cursor: 'pointer',
      '&.Mui-selected': {
        color: theme.palette.text.primary,
      },
    },
    itemContent: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    bookmarkTitle: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
    },
    buttonText: {
      textAlign: 'left',
      marginLeft: theme.spacing(1.5),
    },
    buttonUrl: {
      fontSize: '0.625rem',
      fontWeight: 100,
    },
  }),
  { name: 'LauncherButtons' }
)

export function LauncherButtons({
  onOpenComposer,
  bookmarkMessage,
  onOpenComposeBookmark,
}: {
  onOpenComposer(): void
  bookmarkMessage: BookmarkMessage
  onOpenComposeBookmark(): void
}) {
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    setSelectedIndex(bookmarkMessage.url ? 1 : 0)
  }, [bookmarkMessage])

  useKeyboardHandling(
    [
      {
        combinations: 'ArrowDown',
        noConsumption: true,
        handler() {
          sendAnalyticEvent('Launcher Buttons', 'Arrow Down')
          const newSelectedIndex = selectedIndex + 1
          if (newSelectedIndex > (bookmarkMessage.url ? 1 : 0)) return
          setSelectedIndex(newSelectedIndex)
        },
      },
      {
        combinations: 'ArrowUp',
        noConsumption: true,
        handler() {
          sendAnalyticEvent('Launcher Buttons', 'Arrow Up')
          if (selectedIndex === 0) return
          setSelectedIndex(selectedIndex - 1)
        },
      },
      {
        combinations: commonKeyboardCombinations.enter,
        handler() {
          if (selectedIndex === 0) {
            sendAnalyticEvent('Launcher', 'Open composer by enter')
            onOpenComposer()
          } else if (selectedIndex === 1) {
            sendAnalyticEvent('Launcher', 'Open composer by cmd+enter')
            onOpenComposeBookmark()
          }
        },
      },
    ],
    [selectedIndex]
  )

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <List disablePadding className={classes.root}>
      <ListItem
        className={classNames(classes.item, commonClasses.electronNoDrag)}
        selected={selectedIndex === 0}
        onMouseMove={() => {
          if (selectedIndex === 0) return
          setSelectedIndex(0)
        }}
        onClick={onOpenComposer}
      >
        <div className={classes.itemContent}>
          <CustomIcon name="EditField" size="small" />
          <TypographyNoWrap className={classes.buttonText}>Compose</TypographyNoWrap>
        </div>
      </ListItem>

      {bookmarkMessage.url && (
        <ListItem
          className={classNames(classes.item, commonClasses.electronNoDrag)}
          selected={selectedIndex === 1}
          onMouseMove={() => {
            if (selectedIndex === 1) return
            setSelectedIndex(1)
          }}
          onClick={() => {
            sendAnalyticEvent(
              'Launcher',
              'Open composer by click quick capture',
              JSON.stringify({
                url: bookmarkMessage.url ? '***' : null,
                title: bookmarkMessage.title ? '***' : null,
              })
            )
            onOpenComposeBookmark()
          }}
        >
          <div className={classes.itemContent}>
            <CustomIcon name="Bookmark" size="small" />
            <span className={classes.bookmarkTitle}>
              <TypographyNoWrap className={classes.buttonText}>Save: {bookmarkMessage.title}</TypographyNoWrap>
              <TypographyNoWrap className={classNames(classes.buttonText, classes.buttonUrl)}>
                {bookmarkMessage.url}
              </TypographyNoWrap>
            </span>
          </div>
        </ListItem>
      )}
    </List>
  )
}

import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { CSSProperties, forwardRef, PropsWithChildren, useMemo } from 'react'
import { useCommonStyles } from '../../hooks/useCommonStyles'

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'inline-block',
      position: 'relative',
      flexShrink: 0,
    },
    svg: {
      position: 'absolute',
    },
    svgSquare: {},
    svgRectangle: {
      top: 0,
      left: 0,
    },
  }),
  { name: 'SvgComponent' }
)

export const SvgComponent = forwardRef<
  HTMLSpanElement,
  PropsWithChildren<{
    width: number
    height: number
    className?: string
    style?: CSSProperties
    sizePixels: number
    applyOnWidth: boolean
    applyOnHeight: boolean
    square: boolean
  }>
>(function SvgComponent(
  { width, height, className, style, sizePixels, applyOnWidth, applyOnHeight, square, children },
  ref
) {
  const { actualWidth, actualHeight } = useMemo<{ actualWidth: number; actualHeight: number }>(() => {
    if (applyOnWidth && (!applyOnHeight || width >= height))
      return {
        actualWidth: sizePixels,
        actualHeight: sizePixels * (height / width),
      }
    return {
      actualWidth: sizePixels * (width / height),
      actualHeight: sizePixels,
    }
  }, [width, height, sizePixels, applyOnWidth, applyOnHeight])

  const actualMax = Math.max(actualWidth, actualHeight)

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <span
      ref={ref}
      className={classNames(classes.root, className)}
      style={{ ...style, width: square ? actualMax : actualWidth, height: square ? actualMax : actualHeight }}
    >
      <svg
        className={classNames(classes.svg, {
          [commonClasses.center]: square,
          [classes.svgSquare]: square,
          [classes.svgRectangle]: !square,
        })}
        style={{ width: actualWidth, height: actualHeight }}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {children}
      </svg>
    </span>
  )
})

import { List, ListItem, makeStyles, Typography, useTheme } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { useLayoutEffect, useMemo } from 'react'
import { arrayHelpers } from '../../../../../../../../../helpers/native/arrayHelpers'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { useInfiniteVirtualScrollList } from '../../../../../../../../../modules/infinite-virtual-scroll'
import { tokenize } from '../../../../../../../../../modules/search'
import { Initializer } from '../../../../../../Initializer'
import { SearchResultItem } from '../types'
import { BrowserSuggestionItem } from './BrowserSuggestionItem'
import { CardPreview } from './CardPreview'
import { CardSearchResultItem } from './CardSearchResultItem'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'stretch',
      height: theme.spacing(45),
    },
    column: {
      width: 0,
      flexGrow: 1,
      margin: theme.spacing(1),
      maxHeight: theme.spacing(45),
      overflowY: 'auto',
    },
    itemsColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(1.5),
    },
    noResult: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: 'Result' }
)

export function Result({
  debouncedQuery,
  selectedSearchResultItemIndex,
  setselectedSearchResultItemIndex,
  searchResultItems,
  onOpenPage,
}: {
  debouncedQuery: string
  selectedSearchResultItemIndex: number
  setselectedSearchResultItemIndex(index: number): void
  searchResultItems: readonly SearchResultItem[]
  onOpenPage(card: Model.Card): void
}) {
  const { allPages } = useContexts(Initializer.Contexts)

  const theme = useTheme()

  const selectedSearchResultItem = searchResultItems[selectedSearchResultItemIndex] as SearchResultItem | undefined

  const {
    setScrollElementStatic,
    getScrollElementStatic,
    setItemElementFactoryStatic,
    getItemElementStatic,
    itemsFrom,
    itemsTo,
    clippedItems,
    getIsScrollAtBottomStatic,
    jumpToTopStatic,
    jumpToButtomStatic,
  } = useInfiniteVirtualScrollList(
    {
      minimumThresholdInViewsCount: 2,
      minimumThresholdInPixels: theme.spacing(100),
      maximumThresholdInViewsCount: 4,
      maximumThresholdInPixels: theme.spacing(400),
      ultimateMaximumThresholdInViewsCount: 6,
      ultimateMaximumThresholdInPixels: theme.spacing(600),
      averageItemHeight: theme.spacing(7),
      highlightedItemId: selectedSearchResultItem?.id,
      highlightedItemScrollIntoViewOption: 'nearest',
      items: searchResultItems,
      initialItemsCount: 10,
    },
    []
  )

  useLayoutEffect(() => {
    if (selectedSearchResultItem?.id) {
      getItemElementStatic(selectedSearchResultItem.id)?.scrollIntoView({ block: 'nearest' })
    }
  }, [selectedSearchResultItem?.id])

  const queryTokens = useMemo(() => arrayHelpers.distinct(tokenize(debouncedQuery, 1)), [debouncedQuery])

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {clippedItems.length > 0 ? (
        <>
          <List ref={setScrollElementStatic} disablePadding className={classNames(classes.column, classes.itemsColumn)}>
            {clippedItems.map((item, index) => (
              <ListItem
                key={item.id}
                ref={setItemElementFactoryStatic(item.id)}
                className={commonClasses.electronNoDrag}
                selected={index + (itemsFrom ?? 0) === selectedSearchResultItemIndex}
                onMouseEnter={() => setselectedSearchResultItemIndex(index + (itemsFrom ?? 0))}
                onClick={() => item.handleSubmit('click')}
              >
                {item.type === SearchResultItem.Type.CardSearchResult ? (
                  <CardSearchResultItem item={item} tokens={queryTokens} />
                ) : (
                  <BrowserSuggestionItem item={item} />
                )}
              </ListItem>
            ))}
          </List>

          {selectedSearchResultItem?.type === SearchResultItem.Type.CardSearchResult && (
            <div className={classes.column}>
              {selectedSearchResultItem?.type === SearchResultItem.Type.CardSearchResult && (
                <CardPreview
                  key={`${selectedSearchResultItem.id}-${debouncedQuery}`}
                  card={selectedSearchResultItem.cardSearchResult.card}
                  searchText={
                    selectedSearchResultItem.cardSearchResult.compromiseResult
                      ? selectedSearchResultItem.cardSearchResult.compromiseResult!.value
                      : debouncedQuery
                          .replace(/(^\s+|\s+$)/g, '')
                          .replace(/^[^\w]+|[^\w]+$/g, '') // Characters to strip from start and end of the input string
                          .replace(/[^\w'-]+/g, '|') // Characters used to break up the input string into words
                          .replace(/^\||\|$/g, '')
                  }
                  onOpenPage={() => {
                    const page = allPages.dictionary[selectedSearchResultItem.cardSearchResult.card.pageId]
                    if (!page) return
                    onOpenPage(selectedSearchResultItem.cardSearchResult.card)
                  }}
                />
              )}
            </div>
          )}
        </>
      ) : (
        <div className={classes.noResult}>
          <Typography variant="subtitle1" color="textSecondary">
            No Results
          </Typography>
          <div>&nbsp;</div>
          <Typography variant="subtitle2" color="textSecondary">
            There was no results.
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Try a new search.
          </Typography>
        </div>
      )}
    </div>
  )
}

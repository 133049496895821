import { Writable } from '@zettelooo/commons'
import { EqualityCheckers, KeyValueStoreTypeBase } from '../types'

/**
 * Not persistent, only on memory.
 * Works only for one client window.
 */
export class KeyValueEngine<KeyValueStoreType extends KeyValueStoreTypeBase> {
  protected values: Writable<KeyValueStoreType>

  constructor(
    protected readonly getDefaultValues: () => Readonly<KeyValueStoreType>,
    protected readonly equalityCheckers: EqualityCheckers<KeyValueStoreType>,
    initialValues: Partial<KeyValueStoreType>
  ) {
    this.values = {
      ...getDefaultValues(),
      ...initialValues,
    }
  }

  getAllKeys(): (keyof KeyValueStoreType)[] {
    return Object.keys(this.getDefaultValues())
  }

  get<K extends keyof KeyValueStoreType>(key: K): KeyValueStoreType[K] {
    return this.values[key]
  }

  set<K extends keyof KeyValueStoreType>(key: K, newValue: KeyValueStoreType[K]): void | Promise<void> {
    if (this.equalityCheckers[key]?.(this.values[key], newValue)) return
    this.values[key] = newValue
  }

  reset<K extends keyof KeyValueStoreType>(key: K): void | Promise<void> {
    return this.set(key, this.getDefaultValues()[key])
  }

  // TODO: It also has to provide an unwatch method as well

  // We need this empty method so the inheritor classes can implement that:
  // eslint-disable-next-line class-methods-use-this
  watch<K extends keyof KeyValueStoreType>(key: K, handler: () => void): void {
    // The default implementation only works with one client thread, so there is no changes by an outsider thread
  }
}

import { Keyboard } from '@zettelooo/commons'
import { webConfig } from '../../web-config'

export function doesKeyboardEventMatchCombinations(
  event: KeyboardEvent,
  combinations?: Keyboard.Combinations
): boolean {
  if (!combinations) return true

  return (Array.isArray(combinations) ? combinations : [combinations]).some(combination => {
    if (typeof combination === 'string')
      return event.code === combination && !event.ctrlKey && !event.shiftKey && !event.altKey && !event.metaKey

    return (
      event.code === combination.code &&
      event.shiftKey === Boolean(combination.shift) &&
      event.altKey === Boolean(combination.alt) &&
      (combination.cmd === undefined
        ? event.ctrlKey === Boolean(combination.ctrl) && event.metaKey === Boolean(combination.meta)
        : (webConfig.keyboard.cmdIsMeta ? event.metaKey : event.ctrlKey) === combination.cmd)
    )
  })
}

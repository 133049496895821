import { objectHelpers } from '../../../../../helpers/native/objectHelpers'
import { useContexts } from '../../../../../modules/contexts'
import { useWindowDev } from '../../../../../modules/window-dev'
import { tourSteps } from '../constants/tourSteps'
import { OnboardingProviderContexts } from './OnboardingProvider'

export function WindowDev() {
  const {
    refs: { getOnboardingState, setOnboardingState },
  } = useContexts(OnboardingProviderContexts)

  useWindowDev(
    () => ({
      onboarding: {
        get state() {
          return getOnboardingState()
        },
        tours: objectHelpers.map(tourSteps, (key, value) => ({
          start(stepIndex = 0) {
            setOnboardingState({
              tour: key,
              step: value[stepIndex],
            })
          },
          get steps() {
            return value
          },
        })),
        abortTour() {
          setOnboardingState(undefined)
        },
      },
    }),
    []
  )

  return <></>
}

import { useContext } from 'react'
import { useEffectOnce } from 'react-use'
import { SetDisabledContext } from './contexts/SetDisabledContext'

export function useNoSuperWindowClient(): void {
  const setDisabled = useContext(SetDisabledContext)

  useEffectOnce(() => {
    setDisabled(true)
    return () => setDisabled(false)
  })
}

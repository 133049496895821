// Probably should stay at the top, don't re-order these:
import './modules/fonts'
import { CssBaseline } from '@material-ui/core'

import { useSyncEffect } from '../../hooks/useSyncEffect'
import { webConfig } from '../../modules/web-config'
import { useWindowDev } from '../../modules/window-dev'
import { FatalError } from './components/FatalError'
import { Initializer } from './components/Initializer'
import { Provider } from './components/Provider'
import { UIErrorGenerator } from './components/UIErrorGenerator'
import { AppHelmet } from './components/AppHelmet'
import { FatalErrorStandalone } from './components/FatalErrorStandalone'
import { printWelcomeMessage } from './helpers/printWelcomeMessage'
import { ErrorBoundary, withProfiler } from '@sentry/react'

export const App = withProfiler(function App() {
  useSyncEffect(printWelcomeMessage, [])

  useWindowDev(() => ({ webConfig }), [])

  return (
    <ErrorBoundary fallback={({ error }) => <FatalErrorStandalone error={error} />}>
      <Provider>
        {() => (
          <ErrorBoundary fallback={({ error }) => <FatalError error={error} />}>
            <AppHelmet />
            <CssBaseline />
            <Initializer.Component />
            <UIErrorGenerator />
          </ErrorBoundary>
        )}
      </Provider>
    </ErrorBoundary>
  )
})

import { makeStyles } from '@material-ui/core'
import { commonMutablesDatabaseReaders } from '../../../../../../../../../modules/databases'
import { ExtensionRolesProvider } from '../../../../../../../../../modules/extension'
import { CardViewer } from '../../../../../../../modules/card'
import { Panel, useSwitchPanel } from '../../../../panel'
import { ActivityCardInfo } from './ActivityCardInfo'

const useStyles = makeStyles(
  theme => ({
    root: {
      marginBottom: theme.spacing(3),
    },
  }),
  { name: 'ActivityCard' }
)

export function ActivityCard({ cardActivity, className }: { cardActivity: any; className?: string }) {
  const { card } = commonMutablesDatabaseReaders.useCard(cardActivity.cardId)
  const { page } = commonMutablesDatabaseReaders.usePage(card?.pageId ?? '', { disabled: !card })

  const { switchPanelStatic } = useSwitchPanel()

  const classes = useStyles()

  if (!page || !card) return null

  return (
    <ExtensionRolesProvider role="ActivityCard">
      <div className={classes.root}>
        <ActivityCardInfo cardActivity={cardActivity} pageId={card.pageId} />

        <div
          onClick={() => {
            switchPanelStatic({
              type: Panel.Type.Page,
              pageId: card.pageId,
              cardId: card.id,
            })
          }}
        >
          <CardViewer className={className} page={page} card={card} />
        </div>
      </div>
    </ExtensionRolesProvider>
  )
}

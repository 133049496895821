import { useMemo } from 'react'
import { Persistent } from '../persistent'
import { createServices } from './createServices'
import { Services } from './types'

export function useNewServices(persistent: Persistent | undefined): {
  services: Services | undefined
} {
  const services = useMemo(() => persistent && createServices(persistent), [persistent])

  return {
    services,
  }
}

import { useContexts } from '../../../../../modules/contexts'
import { OnboardingProviderContexts } from '../OnboardingProvider'
import { OnboardingState } from '../types'

export function useOnboardingState(): {
  onboardingState: OnboardingState | undefined
  getOnboardingStateStatic(): OnboardingState | undefined
} {
  const {
    refs: { getOnboardingState },
    onboardingState,
  } = useContexts(OnboardingProviderContexts)

  return {
    onboardingState,
    getOnboardingStateStatic: getOnboardingState,
  }
}

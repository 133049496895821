import { useContexts } from '../../contexts'
import { NavigationAreaContexts } from '../components/NavigationArea'

// Derived from useNavigation() hook:
export function useNavigationAreaAbilityStatus(): {
  isNavigationEnabledForThisArea: boolean
} {
  const { isAreaEnabled } = useContexts(NavigationAreaContexts)

  return {
    isNavigationEnabledForThisArea: isAreaEnabled,
  }
}

import {
  AppBar,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Id, PartialRecord } from '@zettelooo/commons'
import { AdminServiceSignature, UserDailyActivity } from '@zettelooo/server-shared'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCopyToClipboard, useMountedState } from 'react-use'
import { arrayHelpers } from '../../../../../../../helpers/native/arrayHelpers'
import { objectHelpers } from '../../../../../../../helpers/native/objectHelpers'
import { percent } from '../../../../../../../helpers/percent'
import { CustomIcon } from '../../../../../../../modules/custom-icon'
import { webConfig } from '../../../../../../../modules/web-config'
import { Gap } from '../../../../../../Gap'
import { useAppNotistack } from '../../../../../modules/app-notistack'
import { useServices } from '../../../../../modules/services'
import { Authentication } from './Authentication'

const useStyles = makeStyles(
  theme => ({
    initialLoadingMessage: {
      position: 'fixed',
      top: '50vh',
      left: '50vw',
      transform: 'translate(-50%, -50%)',
    },
    root: {
      minHeight: '100vh',
    },
    appBarButtonDisabled: {
      color: 'inherit !important',
      opacity: 0.3,
    },
    body: {
      padding: theme.spacing(2),
    },
    // sectionContainer:{
    //   margin: theme.spacing(6, 0),
    // },
    tableContainer: {
      margin: theme.spacing(6, 0),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  }),
  { name: 'AdminView' }
)

export function AdminView() {
  const [disabled, setDisabled] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [pageData, setPageData] = useState<AdminServiceSignature.PageData>()

  const { signOutStatic } = Authentication.useControl()

  const { services } = useServices()

  const isMounted = useMountedState()

  const fetchData = useCallback(async (): Promise<void> => {
    try {
      setFetching(true)
      const newPageData = await services.admin.getPageData()
      if (!isMounted()) return
      setPageData(newPageData)
    } catch {
      // Do nothing!
    } finally {
      setFetching(false)
    }
  }, [services])

  useEffect(() => {
    fetchData()
    // For now, since the query can be heavy, we don't just pull the changes periodically:
    // const interval = setInterval(() => fetchData(), webConfig.timings.developerPageFetchDataInterval)
    // return () => clearInterval(interval)
  }, [fetchData])

  const [, copyToClipboard] = useCopyToClipboard()

  const { enqueueSnackbar } = useAppNotistack()

  const normalized = useMemo(() => {
    const userDailyActivities = pageData?.userDailyActivities ?? []
    const enhancedUserDailyActivities = userDailyActivities.map(userDailyActivity => {
      const totalCount = Object.values(userDailyActivity.count?.byAction ?? {}).reduce(
        (current, item) => current + (item ?? 0),
        0
      )
      return {
        ...userDailyActivity,
        totalCount,
      } as const
    })
    const users = pageData?.users ?? []
    const enhancedUserDailyActivitiesByUserIdAndDate: PartialRecord<
      Id,
      PartialRecord<Id, (typeof enhancedUserDailyActivities)[number]>
    > = {}
    const enhancedUserDailyActivitiesByDate: PartialRecord<Id, typeof enhancedUserDailyActivities> = {}
    let firstDate: string | undefined
    let lastDate: string | undefined
    enhancedUserDailyActivities.forEach(enhancedUserDailyActivity => {
      if (!(enhancedUserDailyActivity.userId in enhancedUserDailyActivitiesByUserIdAndDate)) {
        enhancedUserDailyActivitiesByUserIdAndDate[enhancedUserDailyActivity.userId] = {}
      }
      enhancedUserDailyActivitiesByUserIdAndDate[enhancedUserDailyActivity.userId]![enhancedUserDailyActivity.date] =
        enhancedUserDailyActivity
      if (!(enhancedUserDailyActivity.date in enhancedUserDailyActivitiesByDate)) {
        enhancedUserDailyActivitiesByDate[enhancedUserDailyActivity.date] = []
      }
      enhancedUserDailyActivitiesByDate[enhancedUserDailyActivity.date]!.push(enhancedUserDailyActivity)
      if (!firstDate || enhancedUserDailyActivity.date < firstDate) {
        firstDate = enhancedUserDailyActivity.date
      }
      if (!lastDate || enhancedUserDailyActivity.date > lastDate) {
        lastDate = enhancedUserDailyActivity.date
      }
    })
    const usersById = arrayHelpers.toDictionaryById(users)
    const dates: string[] = []
    if (firstDate && lastDate) {
      let currentDate = firstDate
      do {
        dates.push(currentDate)
        currentDate = UserDailyActivity.getRelativeDate(currentDate, +1)
      } while (currentDate <= lastDate)
    }
    return {
      enhancedUserDailyActivities: {
        all: userDailyActivities,
        byUserIdAndDate: enhancedUserDailyActivitiesByUserIdAndDate,
        byDate: enhancedUserDailyActivitiesByDate,
      },
      users: {
        all: users,
        byId: usersById,
      },
      dates: {
        all: dates,
        first: firstDate,
        last: lastDate,
      },
      demoModeByUsername: pageData?.demoModeByUsername ?? {},
    } as const
  }, [pageData])

  const calculated = useMemo(() => {
    const calculatedUsers = normalized.users.all.map(user => {
      const dailyActivities = normalized.dates.all.map(date => {
        const enhancedUserDailyActivity = normalized.enhancedUserDailyActivities.byUserIdAndDate[user.id]?.[date]
        const totalCount = enhancedUserDailyActivity?.totalCount ?? 0
        const perClientRatio = arrayHelpers.toDictionary(
          UserDailyActivity.clients,
          item => item,
          item => (totalCount === 0 ? 0 : (enhancedUserDailyActivity?.count?.byClient[item] ?? 0) / totalCount)
        )
        const perActionRatio = arrayHelpers.toDictionary(
          UserDailyActivity.actions,
          item => item,
          item => (totalCount === 0 ? 0 : (enhancedUserDailyActivity?.count?.byAction[item] ?? 0) / totalCount)
        )
        return {
          totalCount,
          perClientRatio,
          perActionRatio,
        } as const
      })
      let totalCountMaximum = 0
      let totalCountAverage = 0
      let totalCountRecentAverage = 0
      const perClientRatioAverage = arrayHelpers.toDictionary(
        UserDailyActivity.clients,
        item => item,
        () => 0
      )
      const perActionRatioAverage = arrayHelpers.toDictionary(
        UserDailyActivity.actions,
        item => item,
        () => 0
      )
      const RECENT_EFECTIVE_NUMBER_OF_DAYS = 2 * 7
      const OLDER_DAYS_EFFECT_RATIO = 0.3
      const AGING_FACTOR = OLDER_DAYS_EFFECT_RATIO ** (1 / RECENT_EFECTIVE_NUMBER_OF_DAYS)
      dailyActivities.forEach(dailyActivity => {
        totalCountMaximum = Math.max(totalCountMaximum, dailyActivity.totalCount)
        totalCountAverage += dailyActivity.totalCount
        totalCountRecentAverage = AGING_FACTOR * totalCountRecentAverage + (1 - AGING_FACTOR) * dailyActivity.totalCount
        UserDailyActivity.clients.forEach(client => {
          perClientRatioAverage[client] += dailyActivity.perClientRatio[client] * dailyActivity.totalCount
        })
        UserDailyActivity.actions.forEach(action => {
          perActionRatioAverage[action] += dailyActivity.perActionRatio[action] * dailyActivity.totalCount
        })
      })
      if (totalCountAverage > 0) {
        UserDailyActivity.clients.forEach(client => {
          perClientRatioAverage[client] /= totalCountAverage
        })
        UserDailyActivity.actions.forEach(action => {
          perActionRatioAverage[action] /= totalCountAverage
        })
      }
      if (dailyActivities.length > 0) {
        totalCountAverage /= dailyActivities.length
      }
      const dailyActivenessString = dailyActivities
        .map(dailyActivity => Math.ceil((9 * dailyActivity.totalCount) / totalCountMaximum))
        .map(normalizedTotalCount => (normalizedTotalCount === 0 ? ' ' : String(normalizedTotalCount)))
        .join('')
        .trimStart()
        .replaceAll(' ', '\u00B7' /* '·' (middle dot) */)
      // TODO: The same for weekly and monthly activeness too
      const firstDate = normalized.dates.last
        ? UserDailyActivity.getRelativeDate(normalized.dates.last, 1 - dailyActivenessString.length)
        : undefined
      const numberOfDays = dailyActivenessString.length
      const numberOfActiveDays = dailyActivities.filter(dailyActivity => dailyActivity.totalCount > 0).length
      return {
        user,
        dailyActivities,
        totalCount: {
          maximum: totalCountMaximum,
          average: totalCountAverage,
          recentAverage: totalCountRecentAverage,
        },
        perClientRatioAverage,
        perActionRatioAverage,
        activenessString: {
          daily: dailyActivenessString,
        },
        firstDate,
        numberOfDays,
        numberOfActiveDays,
      } as const
    })
    const calculatedUsersById = arrayHelpers.toDictionary(calculatedUsers, item => item.user.id)
    const calculatedDates = normalized.dates.all.map((date, dateIndex) => {
      const activeUsers = (normalized.enhancedUserDailyActivities.byDate[date] ?? []).map(userDailyActivity => {
        const calculatedUser = calculatedUsersById[userDailyActivity.userId]
        const dailyActivity = calculatedUser.dailyActivities[dateIndex]
        return {
          user: calculatedUser.user,
          dailyActivity,
          firstDate: calculatedUser.firstDate === date,
        } as const
      })
      let totalCount = 0
      const firstDateActiveUsers: typeof activeUsers = []
      const returningActiveUsers: typeof activeUsers = []
      activeUsers.forEach(activeUser => {
        totalCount += activeUser.dailyActivity.totalCount
        if (activeUser.firstDate) {
          firstDateActiveUsers.push(activeUser)
        } else {
          returningActiveUsers.push(activeUser)
        }
      })
      return {
        date,
        totalCount,
        activeUsers: {
          all: activeUsers,
          firstDate: firstDateActiveUsers,
          returning: returningActiveUsers,
        },
      } as const
    })
    const calculatedDatesByDate = arrayHelpers.toDictionary(calculatedDates, item => item.date)
    const aggregatedOverallPerClientCount = arrayHelpers.toDictionary(
      UserDailyActivity.clients,
      item => item,
      () => 0
    )
    normalized.enhancedUserDailyActivities.all.forEach(enhancedUserDailyActivity => {
      Object.keys(enhancedUserDailyActivity.count?.byClient ?? {}).forEach(client => {
        const typedClient = client as UserDailyActivity.Client
        aggregatedOverallPerClientCount[typedClient] += enhancedUserDailyActivity.count?.byClient[typedClient] ?? 0
      })
    })
    const overallTotalCount = Object.values(aggregatedOverallPerClientCount).reduce(
      (current, item) => current + item,
      0
    )
    const aggregatedOverallPerClientPercent = objectHelpers.map(aggregatedOverallPerClientCount, (key, value) =>
      percent(value, overallTotalCount, 1)
    )
    const demoModeUsernames = Object.keys(normalized.demoModeByUsername).map(username => ({
      username,
      visitDates: normalized.demoModeByUsername[username].map(timestamp =>
        UserDailyActivity.formatDate(new Date(timestamp))
      ),
    }))
    return {
      users: {
        all: calculatedUsers,
        byId: calculatedUsersById,
      },
      dates: {
        all: calculatedDates,
        byDate: calculatedDatesByDate,
      },
      aggregatedOverallPerClientPercent,
      demoModeUsernames,
    } as const
  }, [normalized])

  const theme = useTheme()

  const classes = useStyles()

  if (!pageData)
    return (
      <Typography variant="h6" className={classes.initialLoadingMessage}>
        Loading...
      </Typography>
    )

  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6">Admin &nbsp;&mdash;&nbsp; Meng</Typography>

          <Gap grow />

          <Button
            variant="text"
            color="inherit"
            classes={{ disabled: classes.appBarButtonDisabled }}
            disabled={fetching}
            onClick={fetchData}
          >
            <CustomIcon name="Sync" />
            &nbsp;&nbsp;Refresh
          </Button>

          <Gap horizontal={2} />

          <Button variant="text" color="inherit" onClick={signOutStatic}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>

      <div className={classes.body}>
        <div className={classes.tableContainer}>
          <Typography variant="h5">💻 Clients distribution:</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {UserDailyActivity.clients.map(client => (
                    <TableCell key={client}>{client}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {UserDailyActivity.clients.map(client => (
                  <TableCell key={client}>{calculated.aggregatedOverallPerClientPercent[client]}%</TableCell>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className={classes.tableContainer}>
          <Typography variant="h5">👥 Users:</Typography>
          {calculated.users.all.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No active users.
            </Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>User name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>First date</TableCell>
                    <TableCell>Active days</TableCell>
                    <TableCell>Average daily activity</TableCell>
                    {/* <TableCell>Recent daily activity</TableCell> */}
                    <TableCell>Daily activity diagram</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calculated.users.all.map((calculatedUser, index) => (
                    <TableRow key={index}>
                      <TableCell>{calculatedUser.user.name}</TableCell>
                      <TableCell>{calculatedUser.user.userName}</TableCell>
                      <TableCell>{calculatedUser.user.email}</TableCell>
                      <TableCell>{calculatedUser.firstDate}</TableCell>
                      <TableCell>
                        {calculatedUser.numberOfActiveDays}&nbsp;/&nbsp;{calculatedUser.numberOfDays}&nbsp;&mdash;&nbsp;
                        <small>{percent(calculatedUser.numberOfActiveDays, calculatedUser.numberOfDays)}%</small>
                      </TableCell>
                      <TableCell>{Math.round(calculatedUser.totalCount.average * 100) / 100}</TableCell>
                      {/* <TableCell>{Math.round(calculatedUser.totalCount.recentAverage * 100) / 100}</TableCell> */}
                      <TableCell align="right">
                        <pre>{calculatedUser.activenessString.daily}</pre>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Take a look">
                          <span>
                            <IconButton
                              disabled={disabled}
                              onClick={async () => {
                                try {
                                  setDisabled(true)
                                  const { accessToken, refreshToken } = await services.admin.getPeekUserData(
                                    calculatedUser.user.id
                                  )
                                  const pickLink = `${webConfig.origin}/?m=peek&at=${accessToken}&rt=${refreshToken}`
                                  copyToClipboard(pickLink)
                                  enqueueSnackbar(
                                    'Link is copied into the clipboard',
                                    'IMPORTANT NOTE: Only open the link in private/incognito mode.',
                                    { variant: 'warning' }
                                  )
                                } catch (error) {
                                  log.error(error)
                                  enqueueSnackbar('Error', String(error), { variant: 'error' })
                                } finally {
                                  if (isMounted()) {
                                    setDisabled(false)
                                  }
                                }
                              }}
                            >
                              <CustomIcon name="Search" color={theme.palette.warning.main} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        <div className={classes.tableContainer}>
          <Typography variant="h5">☀️ Days:</Typography>
          {calculated.users.all.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No active days.
            </Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Total activity</TableCell>
                    <TableCell>Active users</TableCell>
                    <TableCell>First date active users</TableCell>
                    <TableCell>Returning active users</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calculated.dates.all.map((calculatedDate, index) => (
                    <TableRow key={index}>
                      <TableCell>{calculatedDate.date}</TableCell>
                      <TableCell>{calculatedDate.totalCount}</TableCell>
                      <TableCell>{calculatedDate.activeUsers.all.length}</TableCell>
                      <TableCell>
                        {calculatedDate.activeUsers.firstDate.length}&nbsp;&mdash;&nbsp;
                        <small>
                          {percent(calculatedDate.activeUsers.firstDate.length, calculatedDate.activeUsers.all.length)}%
                        </small>
                      </TableCell>
                      <TableCell>
                        {calculatedDate.activeUsers.returning.length}&nbsp;&mdash;&nbsp;
                        <small>
                          {percent(calculatedDate.activeUsers.returning.length, calculatedDate.activeUsers.all.length)}%
                        </small>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        <div className={classes.tableContainer}>
          <Typography variant="h5">
            🪧 Demo mode visits: &nbsp;&nbsp;
            <Button
              variant="outlined"
              size="small"
              color="primary"
              disabled={disabled}
              onClick={async () => {
                try {
                  setDisabled(true)
                  await services.admin.deleteDemoModeByUsername()
                  await fetchData()
                } catch (error) {
                  log.error(error)
                  enqueueSnackbar('Error', String(error), { variant: 'error' })
                } finally {
                  if (isMounted()) {
                    setDisabled(false)
                  }
                }
              }}
            >
              Clear status
            </Button>
          </Typography>
          {calculated.demoModeUsernames.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No demo visits.
            </Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>visits</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calculated.demoModeUsernames.map(demoModeUsername => (
                    <TableRow key={demoModeUsername.username}>
                      <TableCell>{demoModeUsername.username}</TableCell>
                      <TableCell>{demoModeUsername.visitDates.join(', ')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </div>
  )
}

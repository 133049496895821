import { AiServiceSignature } from '@zettelooo/server-shared'
import { Api } from './Api'

export interface Action extends AiServiceSignature.SmartCommand.Action {
  readonly function: Action.Function
}

export namespace Action {
  export interface Function {
    (this: Api & Api.Internal, ...inputs: any[]): any
  }

  export function convertActionToAiServiceAction(action: Action): AiServiceSignature.SmartCommand.Action {
    const { function: _, ...aiServiceAction } = action
    return aiServiceAction
  }
}

import { useTheme } from '@material-ui/core'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { PropsWithChildren, useMemo } from 'react'
import { ThemeContext } from './ThemeContext'

// TODO: Merge this with the ExtensionProvider after you figure out a way to put that under the StylingProvider:
export function ThemeProvider({ children }: PropsWithChildren<{}>) {
  const originalTheme = useTheme()

  const theme = useMemo<ZettelExtensions.HtmlContent.Context.Theme>(
    () => ({
      palette: {
        type: originalTheme.palette.type,
        background: originalTheme.palette.background,
        text: originalTheme.palette.text,
        action: originalTheme.palette.action,
        primary: originalTheme.palette.primary,
        secondary: originalTheme.palette.secondary,
        divider: originalTheme.palette.divider,
        success: originalTheme.palette.success,
        warning: originalTheme.palette.warning,
        info: originalTheme.palette.info,
        error: originalTheme.palette.error,
      },
      unitPx: originalTheme.spacing(1),
    }),
    [originalTheme]
  )

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

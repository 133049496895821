import { webConfig } from '../../../modules/web-config'

export function getVersion(): string {
  const version =
    webConfig.environment.agent === 'browser'
      ? `Web ${webConfig.version}`
      : webConfig.environment.agent === 'electron'
      ? `Desktop ${webConfig.version}`
      : `Unknown Environment ${webConfig.version}`

  const target =
    webConfig.environment.target === 'local'
      ? 'Development'
      : webConfig.environment.target === 'stage'
      ? 'Stage'
      : webConfig.environment.target === 'live'
      ? 'Live'
      : 'Unknown Environment'

  return `${version} (${target})`
}

import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { DependencyList, useCallback, useEffect, useMemo } from 'react'
import { useGetSet, useMountedState } from 'react-use'
import { useRefWrap } from '../../../../../hooks/useRefWrap'
import { useStateAccessor } from '../../../../../hooks/useStateAccessor'
import { useDatabases } from '../useDatabases'
import { MutablesDatabase } from './MutablesDatabase'

type Subscription<S> =
  | {
      [T in Model.Type]: {
        readonly type: T
        readonly id?: Id
        onMutation?(model: Model.ByType<T>, state: S, reload: () => S): S
        onMutations?(models: readonly Model.ByType<T>[], state: S, reload: () => S): S
      }
    }[Model.Type]
  | {
      readonly type: undefined
      onMutation?(model: Model.ByType, state: S, reload: () => S): S
      onMutations?(models: readonly Model.ByType[], state: S, reload: () => S): S
    }

interface SubscriptionGeneralized<S> {
  readonly type: Model.Type | undefined
  readonly id?: Id
  onMutation?(model: Model.ByType, state: S, reload: () => S): S
  onMutations?(models: readonly Model.ByType[], state: S, reload: () => S): S
}

export function useMutablesDatabaseReader<S, R>(
  load: (db: MutablesDatabase, previousState?: S) => S | Promise<S>,
  subscription: Subscription<S>,
  dependencies: DependencyList,
  mapper: (loading: boolean, state: S | undefined) => R,
  options?: {
    stateEqualityFunction?(firstState: S | undefined, secondState: S): boolean
  }
): R {
  const {
    databases: { mutablesDatabase },
  } = useDatabases()

  const subscriptionRef = useRefWrap(subscription as SubscriptionGeneralized<S>)

  const [getLoading, setLoading] = useGetSet(true)
  const stateAccessor = useStateAccessor<S | undefined>(undefined, [], {
    equalityFunction: options?.stateEqualityFunction,
  })

  const isMounted = useMountedState()

  // TODO: What if we have a new call to reload before the previous one finishes?
  const reload = useCallback(async (): Promise<void> => {
    setLoading(true)
    const newState = await load(mutablesDatabase, stateAccessor.get())
    if (!isMounted()) return
    stateAccessor.set(newState)
    setLoading(false)
  }, [...dependencies, mutablesDatabase])

  useEffect(() => {
    reload()
  }, [reload])

  useEffect(() => {
    const { subscriptionKey } = mutablesDatabase.subscribe({
      handleMutations(models) {
        if (getLoading()) return

        const filteredDistinctModelsById: Record<Id, Model.ByType> = {}
        models.forEach(model => {
          if (subscriptionRef.current.type && model.type !== subscriptionRef.current.type) return
          if (subscriptionRef.current.id && model.id !== subscriptionRef.current.id) return
          if (model.id in filteredDistinctModelsById) {
            if (model.version < filteredDistinctModelsById[model.id].version) return
            delete filteredDistinctModelsById[model.id] // So the latest models come last in the values
          }
          filteredDistinctModelsById[model.id] = model
        })
        const filteredDistinctModels = Object.values(filteredDistinctModelsById)
        if (filteredDistinctModels.length === 0) return

        let toBeReloaded = false
        let newState: S = stateAccessor.get()!

        function reloadParameter(): S {
          toBeReloaded = true
          return newState
        }

        if (subscriptionRef.current.onMutations) {
          newState = subscriptionRef.current.onMutations(filteredDistinctModels, newState, reloadParameter)
        } else if (subscriptionRef.current.onMutation) {
          for (let i = 0; i < filteredDistinctModels.length; i += 1) {
            newState = subscriptionRef.current.onMutation(filteredDistinctModels[i], newState, reloadParameter)
            if (toBeReloaded) break
          }
        }

        if (toBeReloaded) return reload()

        stateAccessor.set(newState)
      },

      handleReload() {
        return reload()
      },
    })

    return () => {
      mutablesDatabase.unsubscribe(subscriptionKey)
    }
  }, [...dependencies, mutablesDatabase])

  const result = useMemo(() => mapper(getLoading(), stateAccessor.get()), [getLoading(), stateAccessor.get()])

  return result
}

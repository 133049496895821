import { Model } from '@zettelooo/server-shared'
import { PropsWithChildren, useMemo } from 'react'
import { arrayHelpers } from '../../../../../../helpers/native/arrayHelpers'
import { useContexts } from '../../../../../../modules/contexts'
import { PersistentKey, usePersistent } from '../../../../modules/persistent'
import { Initializer } from '../../../Initializer'
import { Contexts } from './Contexts'

export function Provider({
  account,
  children,
}: PropsWithChildren<{
  account: Model.Account
}>) {
  const { persistent } = usePersistent(PersistentKey.PageRecentData)

  const { allPages, allCards } = useContexts(Initializer.Contexts)

  const accountPagesAreLoading = allPages.stillLoading

  const accountPagesOrdered = useMemo(
    () =>
      arrayHelpers.orderByDescending(
        allPages.array.filter(page => page.memberUserIds.includes(account.id)),
        item => persistent[PersistentKey.PageRecentData][item.id]?.latestTimestamp ?? item.updatedAt ?? 0
      ),
    [account.id, persistent[PersistentKey.PageRecentData], allPages.array]
  )

  const accountCardsAreLoading = allCards.stillLoading || accountPagesAreLoading

  const accountCardsOrdered = useMemo(
    () =>
      allCards.array.filter(
        card => allPages.dictionary[card.pageId] && allPages.dictionary[card.pageId]?.memberUserIds.includes(account.id)
      ),
    [account.id, allPages.dictionary, allCards.array]
  )

  return (
    <Contexts.Provider
      parameters={{
        account,
        accountPagesAreLoading,
        accountPagesOrdered,
        accountCardsAreLoading,
        accountCardsOrdered,
      }}
      windowDev
    >
      {children}
    </Contexts.Provider>
  )
}

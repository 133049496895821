import { Id } from '@zettelooo/commons'

interface ArrayHelpers {
  /** Since `Array.prototype.at(0)` is not widely supported yet, we use this helper method instead. */
  first<T>(array: readonly T[]): T | undefined
  /** Since `Array.prototype.at(-1)` is not widely supported yet, we use this helper method instead. */
  last<T>(array: readonly T[]): T | undefined

  sortBy<T>(array: T[], ...propertySelectors: (keyof T | ((item: T) => any))[]): T[]
  sortByDescending<T>(array: T[], ...propertySelectors: (keyof T | ((item: T) => any))[]): T[]

  orderBy<T>(array: readonly T[], ...propertySelectors: (keyof T | ((item: T) => any))[]): T[]
  orderByDescending<T>(array: readonly T[], ...propertySelectors: (keyof T | ((item: T) => any))[]): T[]

  distinct<T>(array: readonly T[], equalityFunction?: (a: T, b: T) => boolean): T[]
  distinctString<T extends string>(array: readonly T[]): T[]

  groupBy<T extends Record<any, any>, K extends keyof T>(array: readonly T[], key: K): Record<T[K], T[]>
  groupBy<T, U extends string | number | symbol>(array: readonly T[], key: (item: T) => U): Record<U, T[]>
  groupBy<T extends Record<any, any>, K extends keyof T, I>(
    array: readonly T[],
    key: K,
    mapper: (group: T[], groupKey: T[K]) => I
  ): Record<T[K], I>
  groupBy<T, U extends string | number | symbol, I>(
    array: readonly T[],
    key: (item: T) => U,
    mapper: (group: T[], groupKey: U) => I
  ): Record<U, I>

  toDictionary<T extends Record<any, any>, K extends keyof T>(array: readonly T[], key: K): Record<T[K], T>
  toDictionary<T, U extends string | number | symbol>(array: readonly T[], key: (item: T) => U): Record<U, T>
  toDictionary<T extends Record<any, any>, K extends keyof T, I>(
    array: readonly T[],
    key: K,
    mapper: (item: T, itemKey: T[K]) => I
  ): Record<T[K], I>
  toDictionary<T, U extends string | number | symbol, I>(
    array: readonly T[],
    key: (item: T) => U,
    mapper: (item: T, itemKey: U) => I
  ): Record<U, I>

  toDictionaryById<T extends { readonly id: Id }>(array: readonly T[]): Record<Id, T>

  remove<T>(array: readonly T[], oldItem: T | ((item: T) => any)): T[]
  removeIndex<T>(array: readonly T[], index: number): T[]

  replace<T>(array: readonly T[], oldItem: T | ((item: T) => any), newItem: T): T[]
  replaceIndex<T>(array: readonly T[], index: number, newItem: T): T[]

  extend<T>(array: T[], extension: readonly T[]): void
}

export const arrayHelpers: ArrayHelpers = {
  first(array: readonly any[]): any {
    return array[0]
  },
  last(array: readonly any[]): any {
    return array[array.length - 1]
  },

  sortBy(array: any[], ...propertySelectors: (any | ((item: any) => any))[]): any[] {
    return array.sort((a, b) => {
      for (let index = 0; index < propertySelectors.length; index += 1) {
        const propertySelector = propertySelectors[index]
        const generalPropertySelector =
          typeof propertySelector === 'function' ? propertySelector : (item: any) => item[propertySelector]
        const aValue = generalPropertySelector(a)
        const bValue = generalPropertySelector(b)
        if (aValue > bValue) return +1
        if (aValue < bValue) return -1
      }
      return 0
    })
  },
  sortByDescending(array: any[], ...propertySelectors: (any | ((item: any) => any))[]): any[] {
    return array.sort((a, b) => {
      for (let index = 0; index < propertySelectors.length; index += 1) {
        const propertySelector = propertySelectors[index]
        const generalPropertySelector =
          typeof propertySelector === 'function' ? propertySelector : (item: any) => item[propertySelector]
        const aValue = generalPropertySelector(a)
        const bValue = generalPropertySelector(b)
        if (aValue > bValue) return -1
        if (aValue < bValue) return +1
      }
      return 0
    })
  },

  orderBy(array: readonly any[], ...propertySelectors: (any | ((item: any) => any))[]): any[] {
    return arrayHelpers.sortBy([...array], ...propertySelectors)
  },
  orderByDescending(array: readonly any[], ...propertySelectors: (any | ((item: any) => any))[]): any[] {
    return arrayHelpers.sortByDescending([...array], ...propertySelectors)
  },

  distinct(array: readonly any[], equalityFunction?: (a: any, b: any) => boolean): any[] {
    if (!equalityFunction) return array.filter(isNotDuplicatedBefore)
    const result: any[] = []
    array.forEach(newItem => result.some(item => equalityFunction(item, newItem)) || result.push(newItem))
    return result

    function isNotDuplicatedBefore(item: any, index: number): boolean {
      return array.indexOf(item) === index
    }
  },

  distinctString(array: readonly string[]): any[] {
    const object: any = {}
    array.forEach(item => {
      object[item] = object
    })
    return Object.keys(object)
  },

  groupBy(
    array: readonly any[],
    key: any | ((item: any) => any),
    mapper?: (group: any[], groupKey: any) => any
  ): Record<any, any> {
    const result: Record<any, any> = {}
    array.forEach(item => {
      const selector = typeof key === 'function' ? key(item) : item[key]
      result[selector] = result[selector] || []
      result[selector].push(item)
    })
    if (mapper) {
      Object.keys(result).forEach(selector => {
        result[selector] = mapper(result[selector], selector)
      })
    }
    return result
  },

  toDictionary(
    array: readonly any[],
    key: keyof any | ((item: any) => any),
    mapper?: (item: any, itemKey: any) => any
  ): Record<any, any> {
    const result: Record<any, any> = {}
    array.forEach(item => {
      const selector = typeof key === 'function' ? key(item) : item[key]
      result[selector] = mapper ? mapper(item, selector) : item
    })
    return result
  },

  toDictionaryById(array: readonly any[]): Record<Id, any> {
    return arrayHelpers.toDictionary(array, 'id')
  },

  remove(array: readonly any[], oldItem: any): any[] {
    const index = typeof oldItem === 'function' ? array.findIndex(oldItem) : array.indexOf(oldItem)
    return arrayHelpers.removeIndex(array, index)
  },
  removeIndex(array: readonly any[], index: number): any[] {
    if (index === -1) return [...array]
    const result = [...array]
    result.splice(index, 1)
    return result
  },

  replace(array: readonly any[], oldItem: any, newItem: any): any[] {
    const index = typeof oldItem === 'function' ? array.findIndex(oldItem) : array.indexOf(oldItem)
    return arrayHelpers.replaceIndex(array, index, newItem)
  },
  replaceIndex(array: readonly any[], index: number, newItem: any): any[] {
    if (index === -1) return [...array]
    const result = [...array]
    result.splice(index, 1, newItem)
    return result
  },

  extend(array: any[], extension: readonly any[]): void {
    extension.forEach(item => array.push(item))
  },
}

import { Model } from '@zettelooo/server-shared'
import { arrayHelpers } from '../../../../../../../helpers/native/arrayHelpers'
import { useMutablesDatabaseReader } from '../../useMutablesDatabaseReader'
import { commonMutablesDatabaseReaders } from '../commonMutablesDatabaseReaders'

export function useAllPages(options?: { disabled?: boolean }): {
  allPages: commonMutablesDatabaseReaders.AllModels<Model.Type.Page>
} {
  return useMutablesDatabaseReader(
    async db => (options?.disabled ? {} : arrayHelpers.toDictionaryById(await db.getAllPages())),
    {
      type: Model.Type.Page,
      onMutations(models, state, reload) {
        if (options?.disabled) return {}
        const newState = { ...state }
        models.forEach(model => {
          if (model.isDeleted) {
            delete newState[model.id]
          } else {
            newState[model.id] = model
          }
        })
        return newState
      },
    },
    [options?.disabled],
    (loading, state) => ({
      allPages: {
        stillLoading: loading,
        array: !options?.disabled && state ? Object.values(state) : [],
        dictionary: !options?.disabled && state ? state : {},
      },
    })
  )
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { createContext, PropsWithChildren, useCallback, useRef, useState } from 'react'
import { useGetSet } from 'react-use'
import { useRefWrap } from '../../../../hooks/useRefWrap'
import { CustomIcon } from '../../../../modules/custom-icon'
import { useKeyboardHandling, commonKeyboardCombinations } from '../../../../modules/keyboard-handler'
import { NavigationArea, Navigable } from '../../../../modules/navigation'
import { MemoizedContextProvider } from '../../../MemoizedContextProvider'
import { TypographyNoWrap } from '../../../TypographyNoWrap'
import { ConfirmationDialogConfirm, ConfirmationDialogOptions } from './types'

const useStyles = makeStyles(
  theme => ({
    root: {
      zIndex: `${theme.zIndex.tooltip} !important` as any, // To bring the confirmation dialog on top of the onboarding tooltips
    },
    paper: {
      width: theme.spacing(62.5),
    },
    title: {
      width: 0,
      flexGrow: 1,
    },
  }),
  { name: 'ConfirmationDialogProvider' }
)

export const ConfirmationDialogContext = createContext<{
  confirmStatic: ConfirmationDialogConfirm
}>(undefined as any)

const defaultConfirmationDialogOptions: ConfirmationDialogOptions = {
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  forceSelect: false,
}

export function ConfirmationDialogProvider({
  preDecidedConfirmationResult,
  children,
}: PropsWithChildren<{
  preDecidedConfirmationResult?: boolean
}>) {
  const [overridingOptions, setOverridingOptions] = useState<Partial<ConfirmationDialogOptions> | undefined>(undefined)
  const [getOpen, setOpen] = useGetSet(false)

  const preDecidedConfirmationResultRef = useRefWrap(preDecidedConfirmationResult)
  const resolveRef = useRef<((result: boolean) => void) | undefined>(undefined)

  const confirmStatic = useCallback<ConfirmationDialogConfirm>(options => {
    if (preDecidedConfirmationResultRef.current !== undefined)
      return Promise.resolve(preDecidedConfirmationResultRef.current)

    return new Promise(resolve => {
      resolveRef.current = resolve

      setOverridingOptions(options)
      setOpen(true)
    })
  }, [])

  const approveStatic = useCallback((): void => {
    setOpen(false)
    resolveRef.current?.(true)
  }, [])

  const disapproveStatic = useCallback((): void => {
    setOpen(false)
    resolveRef.current?.(false)
  }, [])

  useKeyboardHandling(
    [
      {
        combinations: commonKeyboardCombinations.enter,
        handler(event) {
          approveStatic()
        },
      },
    ],
    [],
    {
      removed: !getOpen(),
    }
  )

  const applyingOptions: ConfirmationDialogOptions = {
    ...defaultConfirmationDialogOptions,
    ...overridingOptions,
  }

  const classes = useStyles()

  return (
    <MemoizedContextProvider
      context={ConfirmationDialogContext}
      value={{
        confirmStatic,
      }}
      dependencies={[]}
    >
      {children}

      <NavigationArea disabled={!getOpen()}>
        <Navigable>
          {({ connectNavigable }) => (
            <Dialog
              classes={{
                root: classes.root,
                paper: classes.paper,
              }}
              open={getOpen()}
              onClose={() => !applyingOptions.forceSelect && disapproveStatic()}
              PaperProps={{
                ref: connectNavigable,
              }}
            >
              {applyingOptions.title !== undefined && (
                <DialogTitle>
                  {typeof applyingOptions.title === 'string' ? (
                    <TypographyNoWrap variant="subtitle1">{applyingOptions.title}</TypographyNoWrap>
                  ) : (
                    <div className={classes.title}>{applyingOptions.title}</div>
                  )}

                  {!applyingOptions.forceSelect && (
                    <IconButton size="small" onClick={disapproveStatic}>
                      <CustomIcon name="Close" size={2.5} />
                    </IconButton>
                  )}
                </DialogTitle>
              )}

              {applyingOptions.content !== undefined && (
                <DialogContent dividers={applyingOptions.title !== undefined}>
                  {typeof applyingOptions.content === 'string' ? (
                    <Typography variant="body2">{applyingOptions.content}</Typography>
                  ) : (
                    <>{applyingOptions.content}</>
                  )}
                </DialogContent>
              )}

              <DialogActions>
                <Button onClick={disapproveStatic}>{applyingOptions.cancelLabel}</Button>

                <Button variant="contained" color="primary" onClick={approveStatic}>
                  {applyingOptions.confirmLabel}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Navigable>
      </NavigationArea>
    </MemoizedContextProvider>
  )
}

import Color from 'color'
import { extendThemeOptions } from '../extendThemeOptions'
import { baseThemeOptions } from './baseThemeOptions'

const palettePrimaryMain = '#F5F5F7'

export const darkThemeOptions = extendThemeOptions(baseThemeOptions, {
  palette: {
    type: 'dark',
    primary: {
      main: palettePrimaryMain,
    },
    background: {
      default: '#27282A',
      paper: '#37393A',
      code: '#27282B',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // Scrollbar for FireFox only:
        '*': {
          scrollbarWidth: 'thin',
          scrollbarColor: 'transparent transparent',
          '&:hover': {
            scrollbarColor: '#555 transparent',
          },
        },
        // Scrollbar for other browsers:
        '::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
          backgroundColor: 'transparent',
        },
        '::-webkit-scrollbar-track': {
          margin: 0,
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(255, 255, 255, 0.0)',
          borderRadius: '10px',
        },
        ':hover::-webkit-scrollbar-thumb': {
          backgroundColor: '#444',
        },
        '::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555',
        },

        '@media screen and (max-width: 480px)': {
          '::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
          },
        },
      },
    },
    MuiChip: {
      colorPrimary: {
        color: new Color(palettePrimaryMain).lighten(0.4).toString(),
        backgroundColor: new Color(palettePrimaryMain).desaturate(0.4).darken(0.5).toString(),
      },
    },
  },
})

import { app } from './app'
import { environment } from './environment'

// TODO: Shouldn't we simply extract it from the current location?!
export const origin =
  environment.target === 'local'
    ? 'http://localhost:5000'
    : environment.target === 'stage'
    ? `https://app-stage.${app.domain}`
    : environment.target === 'live'
    ? `https://app.${app.domain}`
    : ''

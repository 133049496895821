import { Message } from './Message'
import { Registration } from './types'

export function RegisteredMessage({ registration, onClose }: { registration: Registration; onClose(): void }) {
  return (
    <Message
      containerProps={{ ref: registration.containerRef }}
      variant={registration.options?.variant}
      onClose={() => {
        onClose()
        registration.options?.onClose?.()
      }}
    />
  )
}

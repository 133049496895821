import { Model } from '@zettelooo/server-shared'
import { useContexts } from '../../../../../../../../../../../../../modules/contexts'
import { useApplyAction } from '../../../../../../../../../../../hooks/useApplyAction'
import { AccountData } from '../../../../../../../../../modules/account-data'
import { StringField } from '../../StringField'

export function WalletAddressField() {
  const { account } = useContexts(AccountData.Contexts)

  const { applyActionStatic } = useApplyAction()

  return (
    <StringField
      placeholder="0x..."
      value={account.walletAddress ?? ''}
      onChange={async value => {
        await applyActionStatic.updateModel({
          type: Model.Type.Account,
          id: account.id,
          walletAddress: value || null,
        })
      }}
    />
  )
}

import { Grid, IconButton, Input, InputAdornment, Tooltip, Typography } from '@material-ui/core'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { ComponentProps, ComponentRef, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useGetSet } from 'react-use'
import { arrayHelpers } from '../../../../../../../../../../../helpers/native/arrayHelpers'
import { useCombineRefs } from '../../../../../../../../../../../hooks/useCombineRefs'
import { useContextMenu } from '../../../../../../../../../../../hooks/useContextMenu'
import { sendAnalyticEvent } from '../../../../../../../../../../../modules/analytics'
import { NavigableWithCommands, useManageCommands } from '../../../../../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../../../modules/custom-icon'
import { useHumanizedTimestamp } from '../../../../../../../../../../../modules/date-time'
import { DeviceProvider } from '../../../../../../../../../../../modules/device'
import { NativeDragObject, useDrop } from '../../../../../../../../../../../modules/drag-and-drop'
import { Navigable, useNavigation } from '../../../../../../../../../../../modules/navigation'
import { Gap } from '../../../../../../../../../../Gap'
import { StaticBadge } from '../../../../../../../../../../StaticBadge'
import { AppDragObject, AppDropResult } from '../../../../../../../../../modules/app-drag-and-drop'
import { ExtensionScopeProvider } from '../../../../../../../../../modules/extension'
import { PersistentKey, usePersistent } from '../../../../../../../../../modules/persistent'
import { Initializer } from '../../../../../../../../Initializer'
import { AccountData } from '../../../../../../../modules/account-data'
import { useCreateTextCard } from '../../../../../../../modules/card'
import { PageAvatar } from '../../../../../../PageAvatar'
import { MainPage } from '../../../../MainPage'
import { Panel } from '../../../../panel'
import { SideBar } from '../../SideBar'
import { ContextMenu } from './ContextMenu'
import { useCommandGroupProps } from './useCommandGroupProps'
import { useStyles } from './useStyles'

export function PageItem({ page }: { page: Model.Page }) {
  const { persistent } = usePersistent(PersistentKey.PageRecentData)

  const { isMobile } = useContext(DeviceProvider.Context)
  const { groupedBadges } = useContexts(Initializer.Contexts)
  const { account } = useContexts(AccountData.Contexts)
  const { panel } = useContexts(MainPage.Contexts)
  const { minimized } = useContext(SideBar.Context)

  const [getIsRenaming, setIsRenaming] = useGetSet(false)
  const [getNewPageName, setNewPageName] = useGetSet('')
  const [getDisabled, setDisabled] = useGetSet(false)
  const [isDraggingOver, setIsDraggingOver] = useState(false)

  const rootRef = useRef<HTMLDivElement>(null)
  const formInputRef = useRef<HTMLInputElement>(null)
  const pageAvatarRef = useRef<ComponentRef<typeof PageAvatar>>(null)
  const buttonFocusedRef = useRef(false)

  useEffect(() => {
    formInputRef.current?.focus()
    buttonFocusedRef.current = false
  }, [getIsRenaming()])

  const lastCard = persistent(PersistentKey.PageRecentData).get()[page.id]?.lastCard
  const latestTimestamp = persistent(PersistentKey.PageRecentData).get()[page.id]?.latestTimestamp

  const badgeStatus = useMemo<ComponentProps<typeof PageAvatar>['badgeStatus']>(() => {
    const badges = groupedBadges.byPageId.get(page.id)
    if (!badges) return undefined
    const badgesByActionType = arrayHelpers.groupBy(badges, item => item.action.type)
    return {
      justJoined: (badgesByActionType[Model.Badge.Action.Type.JoinPage]?.length ?? 0) > 0,
      unreadCount:
        (badgesByActionType[Model.Badge.Action.Type.CreateCard]?.length ?? 0) +
        (badgesByActionType[Model.Badge.Action.Type.UpdateCard]?.length ?? 0),
      mentionedCount: 0, // TODO: We no longer have mentioned users in cards
    }
  }, [page.id, groupedBadges])

  const { handleContextMenuStatic, closeContextMenuStatic, isContextMenuOpenStatic, menuProps } = useContextMenu()

  const { isKeyboardNavigationActiveForThisArea } = useNavigation()

  const { runCommandStatic } = useManageCommands()

  const { createTextCard } = useCreateTextCard()

  const { connectDropTarget } = useDrop<
    NativeDragObject.File | NativeDragObject.Html | NativeDragObject.Text | NativeDragObject.Url | AppDragObject.Card,
    AppDropResult.CardOnPage
  >({
    check(event, dragObject) {
      switch (dragObject.type) {
        case NativeDragObject.Type.File:
        case NativeDragObject.Type.Html:
        case NativeDragObject.Type.Text:
        case NativeDragObject.Type.Url:
          return 'allowed'

        case AppDragObject.Type.Card:
          return dragObject.card.pageId === page.id ? 'denied' : 'allowed'
      }
    },
    drag(event, dragObject, { accepted }) {
      switch (dragObject.type) {
        case NativeDragObject.Type.File:
        case NativeDragObject.Type.Html:
        case NativeDragObject.Type.Text:
        case NativeDragObject.Type.Url:
          setIsDraggingOver(accepted)
          return 'copy'

        case AppDragObject.Type.Card:
          setIsDraggingOver(accepted)
          return 'move'
      }
    },
    drop(event, dragObject, drop) {
      switch (dragObject.type) {
        // case NativeDragObject.Type.File:
        //   createAttachmentCard(page.id, dragObject.items)
        //   break

        case NativeDragObject.Type.Html:
          if (dragObject.text) {
            createTextCard(page.id, dragObject.text)
          }
          break

        case NativeDragObject.Type.Text:
          createTextCard(page.id, dragObject.text)
          break

        case NativeDragObject.Type.Url:
          createTextCard(page.id, dragObject.urls.join('\n'))
          break

        case AppDragObject.Type.Card:
          sendAnalyticEvent('Side Bar', 'Drop on page item')
          drop({
            type: AppDropResult.Type.CardOnPage,
            pageId: page.id,
          })
          break
      }
    },
  })

  const extensionIds = useMemo(() => Model.ExtensionConfiguration.getExtensionIds(page.extensionConfiguration), [page])

  const latestTimestampHumanized = useHumanizedTimestamp(latestTimestamp, 'abbreviated date or time')

  const combineRootRefs = useCombineRefs(rootRef, connectDropTarget)
  const combineFormInputRefs = useCombineRefs(formInputRef)

  const classes = useStyles()

  return (
    <ExtensionScopeProvider scope={ZettelExtensions.Scope.Page} value={page.id} extensionIds={extensionIds}>
      <NavigableWithCommands
        {...useCommandGroupProps(
          account,
          page,
          getIsRenaming,
          setIsRenaming,
          getNewPageName,
          setNewPageName,
          getDisabled,
          setDisabled,
          rootRef,
          pageAvatarRef,
          handleContextMenuStatic,
          closeContextMenuStatic,
          isContextMenuOpenStatic
        )}
      >
        {({ connectNavigable }) => (
          <Tooltip title={isMobile ? '' : page.name} placement="right">
            <Grid
              ref={combineRootRefs(connectNavigable)}
              container
              alignItems="center"
              wrap="nowrap"
              className={classNames(
                classes.root,
                panel?.type === Panel.Type.Page && panel.pageId === page.id && classes.selected,
                isDraggingOver && classes.isDraggingOver
              )}
              onClick={() => {
                if (pageAvatarRef.current?.getState() === 'picker') return
                if (buttonFocusedRef.current) return
                sendAnalyticEvent('Side Bar', 'Click on page item')
                runCommandStatic('page.open')
              }}
              onContextMenu={event => {
                sendAnalyticEvent('Side Bar', 'Right Click on page item')
                handleContextMenuStatic(event)
              }} // TODO: Maybe, we should introduce commands with arguments
            >
              <Grid item>
                {/* TODO: The click events on the popover inside the PageAvatar below trigger page opening */}
                <PageAvatar
                  ref={pageAvatarRef}
                  page={page}
                  size={6.75}
                  popoverExpands="right"
                  badgeStatus={minimized ? badgeStatus : undefined}
                  disableUpdateOnClick
                  className={classes.pageAvatar}
                  onUpdateStarted={() => sendAnalyticEvent('Side Bar', 'Update page avatar started')}
                >
                  <CustomIcon name="Home" color="primary" />
                </PageAvatar>
              </Grid>

              {getIsRenaming() ? (
                <Grid>
                  <Navigable selectable focusable autoNavigate padding={1}>
                    {({ connectNavigable: connectInput, connectFocusable }) => (
                      <div ref={connectInput} className={classes.form}>
                        <Input
                          inputRef={combineFormInputRefs(connectFocusable)}
                          fullWidth
                          disableUnderline
                          placeholder="Page Name"
                          classes={{ input: classes.formInput }}
                          endAdornment={
                            isMobile ? (
                              <InputAdornment position="end">
                                <Gap horizontal={1} />

                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    sendAnalyticEvent('Side Bar', 'Click on cancel page rename')
                                    runCommandStatic('page.cancelRename')
                                  }}
                                  onFocus={() => {
                                    buttonFocusedRef.current = true
                                  }}
                                  onBlur={() => {
                                    buttonFocusedRef.current = false
                                  }}
                                >
                                  <CustomIcon name="Close" />
                                </IconButton>

                                <Gap horizontal={1} />

                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    sendAnalyticEvent('Side Bar', 'Click on submit page rename')
                                    runCommandStatic('page.submitRename')
                                  }}
                                  onFocus={() => {
                                    buttonFocusedRef.current = true
                                  }}
                                  onBlur={() => {
                                    buttonFocusedRef.current = false
                                  }}
                                >
                                  <CustomIcon name="Check" />
                                </IconButton>
                              </InputAdornment>
                            ) : undefined
                          }
                          disabled={getDisabled()}
                          value={getNewPageName()}
                          onChange={event => setNewPageName(event.target.value)}
                          onBlur={() => {
                            // Timeout to let the focused refs be updated first:
                            setTimeout(() => {
                              if (buttonFocusedRef.current) return
                              if (isKeyboardNavigationActiveForThisArea) return
                              sendAnalyticEvent('Side Bar', 'Blur to submit page rename')
                              runCommandStatic('page.submitRename')
                            })
                          }}
                        />
                      </div>
                    )}
                  </Navigable>
                </Grid>
              ) : (
                <Grid
                  item
                  xs
                  className={classNames(
                    classes.infoWrapper,
                    badgeStatus && badgeStatus.unreadCount > 0 && classes.infoWrapperUnread
                  )}
                >
                  <div className={classes.nameWrapper}>
                    <Typography variant="subtitle1" display="block" noWrap className={classes.name}>
                      {page.name}
                    </Typography>
                    <div className={classes.badges}>
                      {!minimized &&
                        badgeStatus &&
                        (badgeStatus.mentionedCount > 0 ? (
                          <StaticBadge badgeContent={badgeStatus.mentionedCount} className={classes.badge} />
                        ) : badgeStatus.justJoined || badgeStatus.unreadCount > 0 ? (
                          <StaticBadge variant="dot" className={classes.badge} />
                        ) : null)}
                      {page.memberUserIds.length > 1 && (
                        <CustomIcon name="People" size="small" color="text.disabled" className={classes.membersIcon} />
                      )}
                    </div>
                  </div>
                  <div className={classes.snippetWrapper}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      display="block"
                      noWrap
                      className={classes.snippet}
                    >
                      &nbsp;
                      {/* TODO: {lastCard?.text} */}
                    </Typography>
                    {lastCard && latestTimestampHumanized && (
                      <Typography variant="caption" display="block" className={classes.timestamp}>
                        {latestTimestampHumanized}
                      </Typography>
                    )}
                  </div>
                </Grid>
              )}
            </Grid>
          </Tooltip>
        )}
      </NavigableWithCommands>

      <ContextMenu account={account} page={page} menuProps={menuProps} disabled={getDisabled()} />
    </ExtensionScopeProvider>
  )
}

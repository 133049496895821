import { makeStyles } from '@material-ui/core'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { forwardRef, MutableRefObject, useImperativeHandle, useState } from 'react'
import { arrayHelpers } from '../../../../../../../../../../../helpers/native/arrayHelpers'
import { HtmlContentTools } from '../../../../../../../../../modules/HtmlContentTools'
import { ExtendedMessage } from './ExtendedMessage'
import { RegisteredMessage } from './RegisteredMessage'
import { Registration } from './types'

const useStyles = makeStyles(
  theme => ({
    root: {
      paddingBottom: theme.spacing(2),
    },
  }),
  { name: 'Messages' }
)

export const Messages = forwardRef<
  {
    registerMessageContainerRef(containerRef: Messages.ContainerRef, options?: Messages.Options): void
    unregisterMessageContainerRef(containerRef: Messages.ContainerRef): void
    reset(): void
  },
  {
    extendedMessages: readonly HtmlContentTools.WithReferenceKey<
      ZettelExtensions.LifeSpan.Shared.PagePanel.Message<any>
    >[]
  }
>(function Messages({ extendedMessages }, ref) {
  const [registrations, setRegistrations] = useState<readonly Registration[]>([])

  useImperativeHandle(
    ref,
    () => ({
      registerMessageContainerRef(containerRef, options?) {
        setRegistrations(current => [...current, { containerRef, options }])
      },
      unregisterMessageContainerRef(containerRef) {
        setRegistrations(current => arrayHelpers.remove(current, item => item.containerRef === containerRef))
      },
      reset() {
        setRegistrations([])
      },
    }),
    []
  )

  const classes = useStyles()

  if (registrations.length + extendedMessages.filter(message => !message.hidden).length === 0) return null

  return (
    <div className={classes.root}>
      {registrations.map((registration, index) => (
        <RegisteredMessage
          key={index}
          registration={registration}
          onClose={() => setRegistrations(current => arrayHelpers.remove(current, registration))}
        />
      ))}

      {extendedMessages.map((message, index) =>
        message.hidden ? null : <ExtendedMessage key={index} message={message} />
      )}
    </div>
  )
})

export namespace Messages {
  export type Variant = 'information' | 'success' | 'warning' | 'error'

  export type ContainerRef = MutableRefObject<HTMLDivElement | null>

  export interface Options {
    readonly variant?: Messages.Variant
    readonly onClose?: () => void
  }
}

import { makeStyles, Paper, Popper, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useRef, useState } from 'react'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { useConnectionStatus } from '../../../../../../../modules/connection-status'
import { useEphemeral, EphemeralKey } from '../../../../../../../modules/ephemeral'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
    },
    icon: {
      marginRight: theme.spacing(0.5),
    },
    iconOffline: {
      color: theme.palette.warning.main,
    },
    spinAnimation: {
      animation: '$spinAnimation 1s infinite linear',
    },
    popper: {
      zIndex: theme.zIndex.tooltip,
    },
    paper: {
      position: 'relative',
      top: theme.spacing(0.5),
      width: theme.spacing(28.5),
      padding: theme.spacing(1.5, 1, 1, 1),
    },
    '@keyframes spinAnimation': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
  }),
  { name: 'SyncAndOfflineItem' }
)

export function SyncAndOfflineItem({ className }: { className?: string }) {
  const [, synchronizingActionsDebounced] = useEphemeral(EphemeralKey.SynchronizingActionsDebounced)

  const rootRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)

  const { isConnectedToServer } = useConnectionStatus()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  if (!isConnectedToServer)
    return (
      <>
        <div
          ref={rootRef}
          className={classNames(classes.root, commonClasses.electronNoDrag, className)}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <CustomIcon name="Offline" size="small" className={classNames(classes.icon, classes.iconOffline)} />
          <Typography variant="caption" color="inherit">
            Offline
          </Typography>
        </div>

        <Popper open={open} anchorEl={rootRef.current} className={classes.popper}>
          <Paper elevation={8} className={classes.paper}>
            <Typography variant="subtitle2" color="textPrimary" gutterBottom>
              You are offline
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Any changes you make will be synced after you regain your connectivity.
            </Typography>
            <Typography variant="body2" color="textPrimary" display="inline">
              Note:&nbsp;
            </Typography>
            <Typography variant="body2" color="textSecondary" display="inline">
              Your changes will overwrite those of your online list members
            </Typography>
          </Paper>
        </Popper>
      </>
    )

  if (synchronizingActionsDebounced)
    return (
      <div className={classNames(classes.root, commonClasses.electronNoDrag, className)}>
        <CustomIcon name="Sync" size="small" className={classNames(classes.icon, classes.spinAnimation)} />
        <Typography variant="caption" color="inherit">
          Syncing
        </Typography>
      </div>
    )

  return null
}

import { useRef } from 'react'
import { ChromeExtensionMode } from '../../../../modules/chrome-extension-mode'

export function useChromeExtensionDragAndDropMessageHandling(): void {
  const targetRef = useRef<Element | null>(null)

  ChromeExtensionMode.useHandleMessage(message => {
    switch (message.subType) {
      case ChromeExtensionMode.Message.SubType.FromContentScriptDragControl:
        if (message.event === 'drag end' && targetRef.current) {
          const dragEvent = new DragEvent('dragleave', {
            bubbles: true, // TODO: Is this really needed?
          })
          Object.defineProperty(dragEvent, 'target', { value: targetRef.current }) // TODO: Is this really needed?
          targetRef.current.dispatchEvent(dragEvent)
        }
        targetRef.current = null
        break

      case ChromeExtensionMode.Message.SubType.FromContentScriptDragEvent: {
        const dispatchDragEvent = (targetElement: Element, type: string): void => {
          const dragEvent = new DragEvent(type, {
            bubbles: true, // TODO: Is this really needed?
            clientX: message.x, // TODO: Is this really needed? Or, even correct?!
            clientY: message.y, // TODO: Is this really needed? Or, even correct?!
            dataTransfer:
              (message.serializedDataTransfer &&
                ChromeExtensionMode.Message.deserializeDataTransfer(message.serializedDataTransfer)) ||
              null,
          })
          Object.defineProperty(dragEvent, 'target', { value: targetElement }) // TODO: Is this really needed?
          targetElement.dispatchEvent(dragEvent)
        }
        const target = window.document.elementFromPoint(message.x, message.y)
        if (targetRef.current && targetRef.current !== target) {
          dispatchDragEvent(targetRef.current, 'dragleave')
        }
        if (target && target !== targetRef.current) {
          dispatchDragEvent(target, 'dragenter')
        }
        if (target) {
          dispatchDragEvent(target, message.eventType)
        }
        targetRef.current = message.eventType === 'dragleave' ? null : target
        break
      }

      default:
        return 'not handled'
    }
  }, [])
}

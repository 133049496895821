import { DependencyList, useContext, useEffect } from 'react'
import { CommandsContext } from '../CommandsProvider'
import { CommandHandler } from '../types'

export function useBatchCommandHandling(
  commandHandlers: Record<string, CommandHandler>,
  dependencies: DependencyList,
  options?: { disabled?: boolean }
): void {
  const { commandManager } = useContext(CommandsContext)

  useEffect(() => {
    if (!options?.disabled) {
      const codeNames = Object.keys(commandHandlers)

      codeNames.forEach(codeName => commandManager.subscribeToCommand(codeName, commandHandlers[codeName]))

      return () => {
        codeNames.forEach(codeName => commandManager.unsubscribeFromCommand(codeName, commandHandlers[codeName]))
      }
    }
  }, [commandManager, ...dependencies])
}

import { Writable } from '@zettelooo/commons'
import { DesktopSync, DesktopSyncIpcMessageType, IpcChannel, User } from '@zettelooo/desktop-shared'
import { createContext, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react'
import { useAsyncEffect } from '../../../hooks/useAsyncEffect'
import { getFileUrl } from '../../../modules/file'
import { webConfig } from '../../../modules/web-config'
import { MemoizedContextProvider } from '../../MemoizedContextProvider'
import { commonMutablesDatabaseReaders } from '../modules/databases'
import { invokeIpcMessage, sendIpcMessage, useIpcMessageHandling } from '../modules/electron'
import { PersistentKey, usePersistent } from '../modules/persistent'
import { useSuperWindowClientStatus } from '../modules/super-window-client'

export function DesktopSyncProvider({ children }: PropsWithChildren<{}>) {
  const {
    persistent,
    currentValues: [authentication, themeType],
  } = usePersistent(PersistentKey.Authentication, PersistentKey.ThemeType)

  const [electronBased, setElectronBased] = useState<Partial<DesktopSync.ElectronBased>>({})

  const { accountIsLoading, account } = commonMutablesDatabaseReaders.useAccount(
    authentication?.decodedAccessToken.userId ?? '',
    {
      disabled: !authentication?.decodedAccessToken.userId,
    }
  )

  const currentUser = useMemo<User | null | undefined>(
    () =>
      !authentication?.decodedAccessToken.userId
        ? null // User is not signed in yet
        : accountIsLoading || !account || account.id !== authentication.decodedAccessToken.userId
        ? undefined // CurrentUser data is not ready yet
        : {
            id: account.id,
            email: account.email,
            color: account.color,
            avatarFileUrl: account.avatarFileId ? getFileUrl(account.avatarFileId, account.name) : undefined,
            username: account.userName,
            displayName: account.name,
            lastActive: account.lastActiveTimestamp,
            isOnboarded: account.isOnboarded,
          },
    [authentication?.decodedAccessToken.userId, accountIsLoading, account]
  )

  const { isSuperWindowClient } = useSuperWindowClientStatus()

  useIpcMessageHandling(
    IpcChannel.DesktopSync,
    (event, message) => {
      switch (message.type) {
        case DesktopSyncIpcMessageType.ElectronSetBrowserBased:
          if (!isSuperWindowClient) break
          if (message.updates.themeType !== undefined) {
            persistent(PersistentKey.ThemeType).set(message.updates.themeType)
          }
          if (message.updates.currentUser !== undefined) {
            // For now, main process is only allowed to sign the user out:
            if (message.updates.currentUser === null) {
              persistent(PersistentKey.Authentication).reset()
            }
          }
          break

        case DesktopSyncIpcMessageType.ElectronSetElectronBased:
          setElectronBased(current => ({
            ...current,
            ...message.updates,
          }))
          break
      }
    },
    [isSuperWindowClient, persistent]
  )

  useAsyncEffect(async () => {
    if (webConfig.environment.agent !== 'electron') return

    const electronBased = await invokeIpcMessage(IpcChannel.DesktopSync, {
      type: DesktopSyncIpcMessageType.BrowserGetElectronBased,
    })

    setElectronBased(electronBased)
  }, [])

  const previousBrowserBasedRef = useRef<Partial<DesktopSync.BrowserBased>>({})

  const updates: Writable<Partial<DesktopSync.BrowserBased>> = {}
  if (themeType !== undefined && themeType !== previousBrowserBasedRef.current.themeType) {
    updates.themeType = themeType
  }
  if (currentUser !== undefined && currentUser !== previousBrowserBasedRef.current.currentUser) {
    updates.currentUser = currentUser
  }

  const hasUpdates = Object.keys(updates).length > 0
  if (hasUpdates) {
    sendIpcMessage(IpcChannel.DesktopSync, {
      type: DesktopSyncIpcMessageType.BrowserSetBrowserBased,
      updates,
    })
  }

  if (hasUpdates) {
    previousBrowserBasedRef.current = {
      ...previousBrowserBasedRef.current,
      ...updates,
    }
  }

  const updateElectronBasedStatic = useCallback((updates: Partial<DesktopSync.ElectronBased>): void => {
    if (webConfig.environment.agent !== 'electron') return

    setElectronBased(current => ({
      ...current,
      ...updates,
    }))
    sendIpcMessage(IpcChannel.DesktopSync, {
      type: DesktopSyncIpcMessageType.BrowserSetElectronBased,
      updates,
    })
  }, [])

  return (
    <MemoizedContextProvider
      context={DesktopSyncProvider.Context}
      value={{
        electronBased,
        updateElectronBasedStatic,
      }}
      dependencies={[electronBased]}
    >
      {children}
    </MemoizedContextProvider>
  )
}

export namespace DesktopSyncProvider {
  export const Context = createContext<{
    electronBased: Partial<DesktopSync.ElectronBased>
    updateElectronBasedStatic(updates: Partial<DesktopSync.ElectronBased>): void
  }>(undefined as any)
}

import { captureException } from '@sentry/react'
import { Id } from '@zettelooo/commons'
import * as tus from 'tus-js-client'
import { percent } from '../../../helpers/percent'
import { writable } from '../../../helpers/writable'
import { useFileInput } from '../../../modules/file'
import { webConfig } from '../../../modules/web-config'
import { PersistentKey, usePersistent } from '../modules/persistent'

export function useFileInputDirectUpload({ allowedFileTypes, ...callbacks }: useFileInputDirectUpload.Options): {
  openFileInputAndUploadFile(): Promise<{
    abortFileUpload(): void
  } | void>
  renderFileInput(): React.JSX.Element
} {
  const { persistent } = usePersistent()

  const { openFileInputStatic, renderFileInput } = useFileInput({
    allowedFileTypes,
  })

  return {
    async openFileInputAndUploadFile() {
      const files = await openFileInputStatic()
      const file = files?.[0]
      if (!file) return

      const fileId = callbacks.onFileSelectAndUploadStart(file)
      callbacks.onFileUploadProgress?.(file, fileId, 0)

      const tusUpload = new tus.Upload(file, {
        endpoint: webConfig.services.baseUrls.upload,
        retryDelays: writable(webConfig.timings.uploadRetryDelays),
        chunkSize: 1 * 1024 * 1024,
        urlStorage: undefined,
        storeFingerprintForResuming: false,
        metadata: {
          'X-File-ID': fileId,
          'X-File-Name': file.name,
        },
        headers: {
          Authorization: `Bearer ${persistent[PersistentKey.Authentication]?.accessToken}`,
        },
        onProgress(bytesSent, bytesTotal) {
          callbacks.onFileUploadProgress?.(file, fileId, percent(bytesSent, bytesTotal))
        },
        onSuccess() {
          callbacks.onFileUploadProgress?.(file, fileId, 100)
          setTimeout(() => callbacks.onFileUploadSuccess?.(file, fileId)) // Just in case, to be done slightly after the %100 progress callback
        },
        onError(error) {
          log.error('tus', 'upload failed', error)
          captureException(error, { tags: { module: 'attachment', action: 'upload' } })
          callbacks.onFileUploadError?.(file, fileId, error)
          tusUpload.abort(true)
        },
      })

      tusUpload.start()

      return {
        abortFileUpload() {
          tusUpload.abort(true)
        },
      }
    },

    renderFileInput,
  }
}

export namespace useFileInputDirectUpload {
  export interface Options extends Pick<useFileInput.Options, 'allowedFileTypes'> {
    /** Must provide a new ID for the file. */ onFileSelectAndUploadStart(file: File): Id
    onFileUploadProgress?(file: File, fileId: Id, percent: number): void
    onFileUploadSuccess?(file: File, fileId: Id): void
    onFileUploadError?(file: File, fileId: Id, error: any): void
  }
}

import { Button, makeStyles, TextField } from '@material-ui/core'
import classNames from 'classnames'
import { useState } from 'react'
import { useGetSet } from 'react-use'
import { useRefWrap } from '../../../../../../../../../../../hooks/useRefWrap'
import { NavigableWithCommands } from '../../../../../../../../../../../modules/commands'
import { commonKeyboardCombinations } from '../../../../../../../../../../../modules/keyboard-handler'
import { Navigable, NavigableStatus } from '../../../../../../../../../../../modules/navigation'
import { FieldPaper } from './FieldPaper'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(1),
    },
    input: {
      ...theme.typography.body2,
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  }),
  { name: 'UsernameField' }
)

export function StringField({
  placeholder,
  className,
  readOnly,
  value,
  onValidate,
  onChange,
}: {
  placeholder?: string
  className?: string
  readOnly?: boolean
  value: string
  onValidate?(value: string): void | string | Promise<void | string>
  onChange?(value: string): void | Promise<void>
}) {
  const [getValue, setValue] = useGetSet(value)
  const [errorMessage, setErrorMessage] = useState('')
  const [getIsEditing, setIsEditing] = useGetSet(false)
  const [getIsSubmitting, setIsSubmitting] = useGetSet(false)

  const submitRef = useRefWrap(async (): Promise<void> => {
    if (getValue() !== value) {
      setIsSubmitting(true)

      const newErrorMessage = await onValidate?.(getValue())
      setErrorMessage(newErrorMessage || '')
      if (newErrorMessage) {
        setIsSubmitting(false)
        return
      }

      await onChange?.(getValue())
      setIsSubmitting(false)
    }
    setIsEditing(false)
  })

  const cancelRef = useRefWrap((): void => {
    setValue(value)
    setErrorMessage('')
    setIsEditing(false)
  })

  const classes = useStyles()

  return (
    <Navigable>
      {({ connectNavigable: connectRoot }) => (
        <FieldPaper ref={connectRoot} className={classNames(classes.root, className)}>
          <NavigableWithCommands
            selectable
            focusable
            disabled={!getIsEditing()}
            commandGroup={({ navigableRef }) => ({
              name: 'stringField',
              displayName: 'Text field',
              disabled() {
                return getIsSubmitting() || !getIsEditing()
              },
              commands: [
                {
                  name: 'submit',
                  displayName: 'Submit',
                  disabled() {
                    return getValue() === value
                  },
                  defaultShortcutKeys: commonKeyboardCombinations.enter,
                  handler() {
                    submitRef.current()
                  },
                },
                {
                  name: 'cancel',
                  displayName: 'Cancel',
                  disabled() {
                    return navigableRef.current?.navigableStatus === NavigableStatus.Selected
                  },
                  defaultShortcutKeys: commonKeyboardCombinations.escape,
                  handler() {
                    cancelRef.current()
                  },
                },
              ],
            })}
            commandGroupDependencies={[value]}
          >
            {({ connectNavigable, connectFocusable }) => (
              <TextField
                ref={connectNavigable}
                variant="standard"
                InputProps={{
                  inputRef: connectFocusable,
                  disableUnderline: true,
                  className: classes.input,
                  readOnly: !getIsEditing(),
                }}
                fullWidth
                autoFocus
                placeholder={placeholder}
                helperText={errorMessage}
                error={Boolean(errorMessage)}
                disabled={getIsSubmitting()}
                value={getValue()}
                onChange={event => {
                  setErrorMessage('')
                  setValue(event.target.value)
                }}
              />
            )}
          </NavigableWithCommands>

          {!readOnly && (
            <div className={classes.actions}>
              {getIsEditing() ? (
                <>
                  <Navigable>
                    {({ connectNavigable }) => (
                      <Button
                        ref={connectNavigable}
                        size="small"
                        disabled={getIsSubmitting()}
                        onClick={cancelRef.current}
                      >
                        Cancel
                      </Button>
                    )}
                  </Navigable>

                  <Navigable>
                    {({ connectNavigable }) => (
                      <Button
                        ref={connectNavigable}
                        color="secondary"
                        size="small"
                        disabled={getIsSubmitting()}
                        onClick={submitRef.current}
                      >
                        Confirm
                      </Button>
                    )}
                  </Navigable>
                </>
              ) : (
                <>
                  <Navigable>
                    {({ connectNavigable }) => (
                      <Button
                        ref={connectNavigable}
                        color="secondary"
                        size="small"
                        onClick={() => {
                          setValue(value)
                          setErrorMessage('')
                          setIsEditing(true)
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Navigable>
                </>
              )}
            </div>
          )}
        </FieldPaper>
      )}
    </Navigable>
  )
}

import { makeStyles, useTheme } from '@material-ui/core'
import { Id } from '@zettelooo/commons'
import classNames from 'classnames'
import { DetailedHTMLProps, forwardRef, HTMLAttributes, ImgHTMLAttributes, useMemo, useState } from 'react'
import { useCommonStyles } from '../hooks/useCommonStyles'
import { getAvatarFilePreviewUrl } from '../modules/file'

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
      borderRadius: '50%',
      width: theme.spacing(6),
      height: theme.spacing(6),
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bold',
      overflow: 'hidden',
      userSelect: 'none',
    },
    img: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      color: 'transparent',
      textAlign: 'center',
      textIndent: '10000px',
    },
  }),
  { name: 'CustomAvatar' }
)

const FULL_TEXT_AVATAR_ICON_SIZE_RATIO = 1.35
const FULL_CONTAINED_AVATAR_ICON_SIZE_RATIO = 2.7
const COMPACT_TEXT_AVATAR_ICON_SIZE_RATIO = 1.15
const COMPACT_CONTAINED_AVATAR_ICON_SIZE_RATIO = 2.35

export const CustomAvatar = forwardRef<
  HTMLDivElement,
  {
    size: number
    styleSize?: number | string
    styleFontSize?: number | string
    compact?: boolean
    avatarFileId: Id | null
    name: string | undefined
    emoji?: string
    color?: string
    margin?: number | [number, number] | [number, number, number] | [number, number, number, number]
    imgProps?: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
  } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>(function CustomAvatar(
  {
    size,
    styleSize,
    styleFontSize,
    compact,
    avatarFileId,
    name,
    emoji,
    color,
    margin,
    children,
    imgProps,
    ...divProps
  },
  ref
) {
  const [imageIsLoaded, setImageIsLoaded] = useState(false)

  const theme = useTheme()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  const contained = Boolean(divProps.style?.color ?? color)
  const avatarIconSizeRatio = compact
    ? contained
      ? COMPACT_CONTAINED_AVATAR_ICON_SIZE_RATIO
      : COMPACT_TEXT_AVATAR_ICON_SIZE_RATIO
    : contained
    ? FULL_CONTAINED_AVATAR_ICON_SIZE_RATIO
    : FULL_TEXT_AVATAR_ICON_SIZE_RATIO
  const iconText = useMemo(
    () =>
      emoji ||
      name
        ?.split(' ')
        .filter(Boolean)
        .slice(0, 2)
        .map(part => part[0].toUpperCase())
        .join(''),
    [emoji, name]
  )
  const imgSrc = imgProps?.src ?? (avatarFileId && getAvatarFilePreviewUrl(avatarFileId, { size: theme.spacing(size) }))

  return (
    <div
      ref={ref}
      {...divProps}
      className={classNames(classes.root, divProps.className)}
      style={{
        ...divProps.style,

        width: divProps.style?.width ?? styleSize ?? theme.spacing(size),
        height: divProps.style?.height ?? styleSize ?? theme.spacing(size),

        fontSize:
          divProps.style?.fontSize ??
          styleFontSize ??
          (typeof styleSize === 'string'
            ? `calc(${styleSize} / ${avatarIconSizeRatio})`
            : typeof styleSize === 'number'
            ? `${styleSize / avatarIconSizeRatio}px`
            : `${theme.spacing(size) / avatarIconSizeRatio}px`),

        backgroundColor: imageIsLoaded ? 'transparent' : divProps.style?.color ?? (color || 'unset'),

        ...(divProps.style?.color ?? color
          ? {
              color: divProps.style?.color ?? theme.palette.getContrastText((divProps.style?.color ?? color)!),
            }
          : {}),

        ...(margin !== undefined
          ? {
              margin:
                divProps.style?.margin ??
                (typeof margin === 'number'
                  ? theme.spacing(margin)
                  : theme.spacing(margin[0], margin[1], margin[2] ?? margin[0], margin[3] ?? margin[1])),
            }
          : {}),
      }}
    >
      {!imageIsLoaded && (iconText || children)}

      {imgSrc && (
        <img
          {...imgProps}
          className={classNames(classes.img, !imageIsLoaded && commonClasses.hidden, imgProps?.className)}
          src={imgSrc}
          alt={imgProps?.alt ?? name}
          onLoad={event => {
            setImageIsLoaded(true)
            imgProps?.onLoad?.(event)
          }}
        />
      )}
    </div>
  )
})

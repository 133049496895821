import { WindowType } from '@zettelooo/desktop-shared'
import { Redirect } from 'react-router-dom'
import { routes } from '../modules/routes'

export function RedirectToHome() {
  return (
    <Redirect
      to={routes.initializing.getPath(
        {},
        {
          then: window.electronProxy?.windowType === WindowType.Launcher ? 'launcher' : 'main',
        }
      )}
    />
  )
}

import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { FC, forwardRef, useMemo } from 'react'
import { webConfig } from '../../../../../../../../../../modules/web-config'
import { Gap } from '../../../../../../../../../Gap'
import { Panel, useSwitchPanel } from '../../../panel'
import { PanelMenu } from '../PanelMenu'
import { AboutSubPanel } from './components/sub-panels/AboutSubPanel'
import { AccountSubPanel } from './components/sub-panels/AccountSubPanel'
import { ApiSubPanel } from './components/sub-panels/ApiSubPanel'
import { DesktopSubPanel } from './components/sub-panels/DesktopSubPanel'
import { SubscriptionSubPanel } from './components/sub-panels/SubscriptionSubPanel'
import { ThemeSubPanel } from './components/sub-panels/ThemeSubPanel'

const useStyles = makeStyles(
  theme => ({
    limitedWidth: {
      '& > *': {
        maxWidth: theme.spacing(95),
      },
    },
  }),
  { name: 'SettingsPanel' }
)

const subPanels: readonly {
  readonly type: Panel.Settings.Type
  readonly caption?: string
  readonly label: string
  readonly SubPanel: FC
  readonly noLimitedWidth?: boolean
}[] = [
  { type: Panel.Settings.Type.Account, label: 'Account', SubPanel: AccountSubPanel },
  { type: Panel.Settings.Type.Theme, label: 'Theme', SubPanel: ThemeSubPanel, noLimitedWidth: true },
  { type: Panel.Settings.Type.Desktop, label: 'Launcher', SubPanel: DesktopSubPanel },
  { type: Panel.Settings.Type.Api, label: 'API', SubPanel: ApiSubPanel },
  { type: Panel.Settings.Type.Subscription, label: 'Subscription', SubPanel: SubscriptionSubPanel },
  { type: Panel.Settings.Type.About, label: 'About', SubPanel: AboutSubPanel },
]

export const SettingsPanel = forwardRef<
  HTMLDivElement,
  {
    panel: Panel<Panel.Type.Settings>
  }
>(function SettingsPanel({ panel }, ref) {
  const filteredSubPanels = useMemo(
    () =>
      subPanels.filter((subPanel): boolean => {
        switch (subPanel.type) {
          case Panel.Settings.Type.Account:
            return true

          case Panel.Settings.Type.Theme:
            return true

          case Panel.Settings.Type.Desktop:
            return webConfig.environment.agent === 'electron'

          case Panel.Settings.Type.Api:
            return !webConfig.temporary.temporarilyDisabledOrToBeRemoved

          case Panel.Settings.Type.Subscription:
            return true

          case Panel.Settings.Type.About:
            return true
        }
      }),
    []
  )

  const selectedSubPanel = filteredSubPanels.find(subPanel => subPanel.type === panel.subType) ?? filteredSubPanels[0]
  const SelectedSubPanel = selectedSubPanel.SubPanel

  const { switchPanelStatic } = useSwitchPanel()

  const classes = useStyles()

  return (
    <PanelMenu
      ref={ref}
      containerClassName={classNames(!selectedSubPanel.noLimitedWidth && classes.limitedWidth)}
      header="Settings"
      items={filteredSubPanels}
      getCaption={subPanel => subPanel.caption}
      getLabel={subPanel => subPanel.label}
      selectedItem={selectedSubPanel}
      onSelectItem={subPanel =>
        switchPanelStatic({
          type: Panel.Type.Settings,
          subType: subPanel.type,
        })
      }
    >
      <SelectedSubPanel />

      <Gap vertical={5} />
    </PanelMenu>
  )
})

import { generateSequence, Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useContexts } from '../../../../../../modules/contexts'
import { useApplyAction } from '../../../../hooks/useApplyAction'
import { useExtensionManager } from '../../../../modules/extension'
import { AccountData } from '../account-data'

export function useCreateTextCard(): {
  createTextCard: (pageId: Id, text: string, sequence?: string) => Promise<void>
} {
  const { accountPagesOrdered } = useContexts(AccountData.Contexts)

  const { extensionManager } = useExtensionManager()

  const { applyActionStatic } = useApplyAction()

  return {
    createTextCard: async (pageId, text, sequence?) => {
      const page = accountPagesOrdered.find(page => page.id === pageId)
      if (!page) return
      const dataDictionary: Model.DataDictionary = {}
      const extensionIds = Model.ExtensionConfiguration.getExtensionIds(page.extensionConfiguration)
      extensionIds.forEach(extensionId => {
        const extraction = extensionManager.extractCardDataFromCommonData(extensionId, { text })
        const cardData = extensionManager.constructCardData(extensionId, undefined, [extraction], () => [])
        if (cardData !== undefined) {
          dataDictionary[extensionId] = cardData
        }
      })
      await applyActionStatic.createModel({
        type: Model.Type.Card,
        pageId,
        sequence: sequence ?? generateSequence(),
        dataDictionary,
      })
    },
  }
}

import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useGetSet } from 'react-use'
import { useRefWrap } from '../../../../../../hooks/useRefWrap'
import { useStateAccessor } from '../../../../../../hooks/useStateAccessor'
import { sendAnalyticEvent } from '../../../../../../modules/analytics'
import { useContexts } from '../../../../../../modules/contexts'
import { useApplyAction } from '../../../../hooks/useApplyAction'
import { useGenerateModelId } from '../../../../hooks/useGenerateModelId'
import { INITIAL_PAGE_EXTENSION_SETUP } from '../../constants'
import { AccountData } from '../account-data'
import { Context } from './Context'
import { NewPage } from './types'
import { usePageCoverSuggestions } from './usePageCoverSuggestions'

export function Provider({
  onNewPageIsCanceled,
  onNewPageModelIsReady,
  children,
}: PropsWithChildren<{
  onNewPageIsCanceled?: () => void
  onNewPageModelIsReady?: (newPageId: Id) => void
}>) {
  const { account, accountPagesOrdered } = useContexts(AccountData.Contexts)

  const { makePageCoverSuggestions } = usePageCoverSuggestions()

  const { generateModelIdStatic } = useGenerateModelId()

  const newPageAccessor = useStateAccessor<NewPage>(
    () => ({
      id: generateModelIdStatic(),
      name: '',
      iconEmoji: '',
      color: '',
      avatarFileId: null,
    }),
    [],
    {
      middleware(newValue, oldValue) {
        return makePageCoverSuggestions(newValue)
      },
    }
  )
  const [getNewPageSubmitting, setNewPageSubmitting] = useGetSet(false)
  const [newlyCreatedPageId, setNewlyCreatedPageId] = useState<Id>()

  useEffect(() => {
    if (newlyCreatedPageId && accountPagesOrdered.some(page => page.id === newlyCreatedPageId)) {
      setNewlyCreatedPageId(undefined)
      onNewPageModelIsReady?.(newPageAccessor.value.id)
    }
  }, [accountPagesOrdered, newlyCreatedPageId])

  const { applyActionStatic } = useApplyAction()

  const contentRef = useRefWrap({
    onNewPageIsCanceled,
    account,
  })

  const submitNewPageStatic = useCallback(async (): Promise<void> => {
    if (getNewPageSubmitting()) return

    // TODO: Validate newPageAccessor.value.name better:
    if (!newPageAccessor.value.name) return

    sendAnalyticEvent('Side Bar', 'Create new page')

    setNewPageSubmitting(true)
    await applyActionStatic.createModel({
      type: Model.Type.Page,
      ...newPageAccessor.value,
      description: '', // TODO: To be filled, maybe by the template
      memberUserIds: [contentRef.current.account.id],
      public: false,
      ...INITIAL_PAGE_EXTENSION_SETUP,
    })
    setNewPageSubmitting(false)
    setNewlyCreatedPageId(newPageAccessor.value.id)
  }, [])

  const cancelNewPageStatic = useCallback((): void => {
    contentRef.current.onNewPageIsCanceled?.()
  }, [])

  return (
    <Context.Provider
      value={{
        newPage: newPageAccessor.value,
        newPageAccessor,
        newPageSubmitting: getNewPageSubmitting(),
        submitNewPageStatic,
        cancelNewPageStatic,
      }}
    >
      {children}
    </Context.Provider>
  )
}

import { useState } from 'react'
import { webConfig } from '../../../../../../../modules/web-config'
import { useAppHistoryControl } from '../../../../../modules/app-history'
import { routes } from '../../../modules/routes'
import { Query } from './useQuery'

export type FinishSignIn = (accessToken: string, refreshToken: string) => void

export function useFinishSignIn(query: Query): {
  openingDesktopAppUrl: string | undefined
  finishSignIn: FinishSignIn
} {
  const [openingDesktopAppUrl, setOpeningDesktopAppUrl] = useState<string>()

  const { appHistoryControlStatic } = useAppHistoryControl()

  return {
    openingDesktopAppUrl,

    finishSignIn(accessToken: string, refreshToken: string) {
      if (query.isForDesktop) {
        const newOpeningDesktopAppUrl = `${webConfig.urls.desktopApp}?at=${accessToken}&rt=${refreshToken}`
        setOpeningDesktopAppUrl(newOpeningDesktopAppUrl)
        window.location.href = newOpeningDesktopAppUrl
      } else {
        appHistoryControlStatic.push(
          routes.authenticated.getPath(
            {},
            {
              at: accessToken,
              rt: refreshToken,
            }
          )
        )
      }
    },
  }
}

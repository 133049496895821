import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { KeyboardCombinationView } from '../../../../../../../../../modules/keyboard-handler'
import { MainPage } from '../../MainPage'
import { Panel, useSwitchPanel } from '../../panel'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'SettingsItem' }
)

export function SettingsItem({ className }: { className?: string }) {
  const {
    refs: { setIsSideBarMenuOpen },
  } = useContexts(MainPage.Contexts)

  const { switchPanelStatic } = useSwitchPanel()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <Tooltip title={<KeyboardCombinationView combination={{ cmd: true, code: 'Comma' }} prefix="Settings" highlight />}>
      <IconButton
        className={classNames(classes.root, commonClasses.electronNoDrag, className)}
        onClick={() => {
          switchPanelStatic({
            type: Panel.Type.Settings,
            subType: Panel.Settings.Type.Account,
          })
          setIsSideBarMenuOpen(false)
        }}
      >
        <CustomIcon name="Settings" size={2.5} />
      </IconButton>
    </Tooltip>
  )
}

import { makeStyles, MenuProps } from '@material-ui/core'
import classNames from 'classnames'
import { DetailedHTMLProps, HTMLAttributes, ReactNode, useContext, useEffect, useRef } from 'react'
import { useEffectOnce } from 'react-use'
import { useRefEffect } from '../../hooks/useRefEffect'
import { useStateAccessor } from '../../hooks/useStateAccessor'
import { DeviceProvider } from '../../modules/device'

const useStyles = makeStyles(
  theme => ({
    parent: {
      '&:not(:hover) $root:not($rootMobile):not($shown)': {
        display: 'none',
      },
    },
    parentPositionRelative: {
      position: 'relative',
    },
    root: {
      position: 'absolute',
      borderRadius: theme.spacing(1),
    },
    rootMobile: {},
    shown: {},
    moreMenuButton: {
      padding: theme.spacing(1),
    },
    moreMenuPaper: {
      minWidth: theme.spacing(20),
    },
    hidden: {
      display: 'none',
    },
  }),
  { name: 'HoverContextMenu' }
)

export function HoverContextMenu({
  disabledParentPositionRelative,
  hidden,
  onMoreMenu,
  children,
  ...otherProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  disabledParentPositionRelative?: boolean
  hidden?: boolean
  onMoreMenu?(open: boolean): void
  children(provided: {
    connectMoreMenuButton(element: HTMLElement | null): void
    moreMenuProps: Pick<MenuProps, 'open' | 'anchorReference' | 'anchorPosition' | 'getContentAnchorEl' | 'classes'> & {
      onClose(): void
    }
  }): ReactNode
}) {
  const { isMobile } = useContext(DeviceProvider.Context)

  const moreMenuOpenAccessor = useStateAccessor(false, [], {
    onChangeBefore(newValue, oldValue) {
      onMoreMenu?.(newValue)
    },
  })

  const rootRef = useRef<HTMLDivElement>(null)

  const classes = useStyles()

  const connectMoreMenuButton = useRefEffect<HTMLElement>(moreMenuButtonElement => {
    if (moreMenuButtonElement) {
      const handleClick = (): void => void moreMenuOpenAccessor.set(true)
      moreMenuButtonElement.addEventListener('click', handleClick)
      return () => void moreMenuButtonElement.removeEventListener('click', handleClick)
    }
  }, [])

  // Since we don't have any way to detect changes in the parent element,
  // it's most safe to check the parent's classes on each and every render:
  useEffect(() => {
    const directParentElement = rootRef.current?.parentElement
    if (directParentElement) {
      directParentElement.classList.add(classes.parent)
      if (!disabledParentPositionRelative) {
        directParentElement.classList.add(classes.parentPositionRelative)
      }
      return () => void directParentElement.classList.remove(classes.parent, classes.parentPositionRelative)
    }
  })

  useEffectOnce(() => () => {
    if (moreMenuOpenAccessor.is(true)) {
      onMoreMenu?.(false)
    }
  })

  const moreMenuButtonBoundingClinetRect = connectMoreMenuButton.current?.getBoundingClientRect()

  return (
    <div
      ref={rootRef}
      {...otherProps}
      className={classNames(
        classes.root,
        isMobile && classes.rootMobile,
        hidden && classes.hidden,
        moreMenuOpenAccessor.is(true) && classes.shown,
        otherProps.className
      )}
      onClick={event => {
        event.stopPropagation()
        otherProps.onClick?.(event)
      }}
    >
      {children({
        connectMoreMenuButton,
        moreMenuProps: {
          open: moreMenuOpenAccessor.value,
          anchorReference: 'anchorPosition',
          anchorPosition: {
            top: (moreMenuButtonBoundingClinetRect?.top ?? 0) + (moreMenuButtonBoundingClinetRect?.height ?? 0) / 2,
            left: (moreMenuButtonBoundingClinetRect?.left ?? 0) + (moreMenuButtonBoundingClinetRect?.width ?? 0) / 2,
          },
          getContentAnchorEl: null,
          onClose: () => void moreMenuOpenAccessor.set(false),
          classes: { paper: classes.moreMenuPaper },
        },
      })}
    </div>
  )
}

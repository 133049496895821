import { memo } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ChromeExtensionMode } from '../../../../modules/chrome-extension-mode'
import { DemoMode } from '../../../../modules/demo-mode'
import { webConfig } from '../../../../modules/web-config'
import { Run } from '../../../Run'
import { ExtensionRolesProvider } from '../../modules/extension'
import { useNoSuperWindowClient } from '../../modules/super-window-client'
import { AuthenticationCheck } from './components/AuthenticationCheck'
import { AuthenticationRequired } from './components/AuthenticationRequired'
import { AdminPage } from './components/pages/AdminPage'
import { AuthenticatedPage } from './components/pages/AuthenticatedPage'
import { DesignPage } from './components/pages/DesignPage'
import { DeveloperPage } from './components/pages/DeveloperPage'
import { IconsPage } from './components/pages/IconsPage'
import { InitializingPage } from './components/pages/InitializingPage'
import { LauncherPage } from './components/pages/LauncherPage'
import { MainPage } from './components/pages/MainPage'
import { NotSubscribedPage } from './components/pages/NotSubscribedPage'
import { PublicCardViewPage } from './components/pages/PublicCardViewPage'
import { PublicPageViewPage } from './components/pages/PublicPageViewPage'
import { SignInPage } from './components/pages/SignInPage'
import { SignOutPage } from './components/pages/SignOutPage'
import { TestPage } from './components/pages/TestPage'
import { RedirectToHome } from './components/RedirectToHome'
import { SmartCommandApiProvider } from './components/SmartCommandApiProvider'
import { UnauthenticationRequired } from './components/UnauthenticationRequired'
import { useExtensionActivatedLifeSpan } from './hooks/useExtensionActivatedLifeSpan'
import { useMainIpcMessageHandling } from './hooks/useMainIpcMessageHandling'
import { useRoutingRelatedCommandHandling } from './hooks/useRoutingRelatedCommandHandling'
import { routes, RouteSpecification } from './modules/routes'
import { SearchProvider } from './modules/search'

export const Content = memo(function Content() {
  const { renderExtendedContent } = useExtensionActivatedLifeSpan()

  useRoutingRelatedCommandHandling()

  useMainIpcMessageHandling()

  return (
    <ExtensionRolesProvider role="Content">
      <SmartCommandApiProvider>
        <Switch>
          <Route
            exact
            path={routes.signIn.pattern}
            render={props => {
              const isForDesktop =
                (new URLSearchParams(props.location.search).get('agent') as RouteSpecification.Query<
                  typeof routes.signIn
                >['agent']) === 'electron'
              return isForDesktop ? <SignInPage /> : <UnauthenticationRequired render={() => <SignInPage />} />
            }}
          />

          <Route exact path={routes.signOut.pattern} render={() => <SignOutPage />} />

          <Route exact path={routes.authenticated.pattern} render={() => <AuthenticatedPage />} />

          <Route exact path={routes.initializing.pattern} render={() => <InitializingPage />} />

          <Route
            path={routes.main.pattern}
            render={() => (
              <AuthenticationRequired
                toBeInitialized
                render={({ subscribed }) =>
                  subscribed ? (
                    <SearchProvider>
                      <MainPage />
                    </SearchProvider>
                  ) : (
                    <NotSubscribedPage />
                  )
                }
              />
            )}
          />

          <Route
            exact
            path={routes.publicPageView.pattern}
            render={() => (
              <AuthenticationCheck>
                {({ authenticated, initialized }) => (!authenticated || initialized) && <PublicPageViewPage />}
              </AuthenticationCheck>
            )}
          />

          <Route
            exact
            path={routes.publicCardView.pattern}
            render={() => (
              <AuthenticationCheck>
                {({ authenticated, initialized }) => (!authenticated || initialized) && <PublicCardViewPage />}
              </AuthenticationCheck>
            )}
          />

          {webConfig.environment.agent === 'browser' && !ChromeExtensionMode.isActive && !DemoMode.data && (
            <Route
              exact
              path={routes.developer.pattern}
              render={() => (
                <>
                  <Run>{useNoSuperWindowClient}</Run>
                  <DeveloperPage />
                </>
              )}
            />
          )}

          {webConfig.environment.agent === 'browser' && !ChromeExtensionMode.isActive && !DemoMode.data && (
            <Route
              exact
              path={routes.admin.pattern}
              render={() => (
                <>
                  <Run>{useNoSuperWindowClient}</Run>
                  <AdminPage />
                </>
              )}
            />
          )}

          {/* Only available in the electron desktop app or for test & development: */}
          {(webConfig.environment.agent === 'electron' ||
            webConfig.environment.target === 'local' ||
            webConfig.environment.target === 'stage') && (
            <Route
              exact
              path={routes.launcher.pattern}
              render={() => (
                <AuthenticationRequired
                  toBeInitialized
                  render={({ subscribed }) =>
                    subscribed ? (
                      <SearchProvider>
                        <LauncherPage />
                      </SearchProvider>
                    ) : (
                      <NotSubscribedPage />
                    )
                  }
                />
              )}
            />
          )}

          {/* Just for test & development purposes, not accessible in production: */}
          {(webConfig.environment.target === 'local' || webConfig.environment.target === 'stage') && (
            <Route
              exact
              path={routes.test.pattern}
              render={() => (
                <>
                  <Run>{useNoSuperWindowClient}</Run>
                  <TestPage />
                </>
              )}
            />
          )}
          {(webConfig.environment.target === 'local' || webConfig.environment.target === 'stage') && (
            <Route
              exact
              path={routes.icons.pattern}
              render={() => (
                <>
                  <Run>{useNoSuperWindowClient}</Run>
                  <IconsPage />
                </>
              )}
            />
          )}
          {(webConfig.environment.target === 'local' || webConfig.environment.target === 'stage') && (
            <Route
              exact
              path={routes.design.pattern}
              render={() => (
                <>
                  <Run>{useNoSuperWindowClient}</Run>
                  <DesignPage />
                </>
              )}
            />
          )}

          <RedirectToHome />
        </Switch>

        {renderExtendedContent()}
      </SmartCommandApiProvider>
    </ExtensionRolesProvider>
  )
})

import { Id } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { convertCardModelToCardPublicModel, convertPageModelToPagePublicModel, Model } from '@zettelooo/server-shared'
import { useCallback } from 'react'
import { Run } from '../../../../../../Run'
import { useExtensionLifeSpan } from '../../../../../modules/extension'
import { PersistentKey, usePersistent } from '../../../../../modules/persistent'
import { useExtensionPageLifeSpan } from '../../../hooks/useExtensionPageLifeSpan'

export function useExtensionPublicPageViewLifeSpan(
  pageId: Id,
  page: Model.Page | undefined,
  cards: readonly Model.Card[]
): {
  renderExtensionLifeSpan(): React.JSX.Element | null
} {
  const [, deviceId, authentication] = usePersistent(PersistentKey.DeviceId, PersistentKey.Authentication)

  const { renderExtensionLifeSpan } = useExtensionPageLifeSpan({ page, cards })

  return {
    renderExtensionLifeSpan() {
      if (!page) return null

      return (
        <>
          {renderExtensionLifeSpan()}

          <Run key="publicPageViewLifeSpan">
            {() =>
              useExtensionLifeSpan({
                name: 'publicPageView',
                target: {
                  pageId,
                },
                scopedValues: {
                  [ZettelExtensions.Scope.Device]: deviceId,
                  [ZettelExtensions.Scope.User]: authentication?.decodedAccessToken.userId ?? '',
                  [ZettelExtensions.Scope.Page]: page.id,
                },
                dataFactories: {
                  page: useCallback(({ header }) => convertPageModelToPagePublicModel(page, header.id), [page]),
                  cards: useCallback(
                    ({ header }) => cards.map(card => convertCardModelToCardPublicModel(card, header.id)),
                    [cards]
                  ),
                },
                accessFactory: () => ({}),
                registryFactory: () => ({}),
                dependencies: [deviceId, authentication, pageId],
              })
            }
          </Run>
        </>
      )
    },
  }
}

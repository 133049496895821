import { analytics } from './analytics'
import { app } from './app'
import { developerLogs } from './developerLogs'
import { developmentOptions } from './developmentOptions'
import { environment } from './environment'
import { initialQuery } from './initialQuery'
import { keyboard } from './keyboard'
import { origin } from './origin'
import { os } from './os'
import { services } from './services'
import { serviceWorker } from './serviceWorker'
import { temporary } from './temporary'
import { timings } from './timings'
import { urls } from './urls'

export const webConfig = {
  version: process.env.VERSION!,
  publicUrl: process.env.PUBLIC_URL ?? '', // It resolves "./" to "." and ..., in Web it's "" and in Desktop it's "."

  app,
  origin,
  environment,
  os,
  initialQuery,
  keyboard,
  developmentOptions,
  services,
  urls,
  analytics,
  serviceWorker,
  timings,
  developerLogs,
  temporary,

  // TODO: Improve categorizing the following items:

  supportEmail: `zettelyay@gmail.com`, // TODO: What is our support email?

  tidioBundleUrl: process.env.TIDIO_BUNDLE_URL,

  sentry: {
    dsn: process.env.SENTRY_DSN,
  },

  tally: {
    actionLinkUrl: process.env.TALLY_ACTION_LINK_URL,
  },

  signIn: {
    infuraId: process.env.SIGN_IN_INFURA_ID,
  },

  localStorageBasedIdGeneratorKeyName: `${app.name}/id-base`, // TODO: It's usage does not work as expected, fix that and then rename/replace this property
} as const

import { Keyboard } from '@zettelooo/commons'
import { memo } from 'react'
import { Part } from './Part'

export const Section = memo(function Section({ className, section }: { className?: string; section: Section.Data }) {
  return (
    <div className={className}>
      {typeof section === 'string' ? section : section.map((part, index) => <Part key={index} part={part} />)}
    </div>
  )
})

export namespace Section {
  export type Data =
    | string
    | readonly (
        | string
        | {
            readonly type: 'styled text'
            readonly text: string
            readonly strong?: boolean
            readonly emphasis?: boolean
          }
        | {
            readonly type: 'keyboard combination'
            readonly combination: Keyboard.Combination
          }
      )[]
}

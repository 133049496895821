import { captureException } from '@sentry/react'
import { Model } from '@zettelooo/server-shared'
import { useEffect, useMemo } from 'react'
import { dateHelpers } from '../../../../../helpers/native/dateHelpers'
import { useRefWrap } from '../../../../../hooks/useRefWrap'
import { useContexts } from '../../../../../modules/contexts'
import { webConfig } from '../../../../../modules/web-config'
import { PeriodicAsyncAction } from '../../../../../types/PeriodicAsyncAction'
import { Initializer } from '../../../components/Initializer'
import { useApplyAction } from '../../../hooks/useApplyAction'
import { useConnectionStatus } from '../../connection-status'

export function useAccountLastActiveUpdating(): void {
  const { account } = useContexts(Initializer.Contexts)

  const { isOnline } = useConnectionStatus()

  const { applyActionStatic } = useApplyAction()

  const accountRef = useRefWrap(account)

  const periodicAsyncAction = useMemo(
    () =>
      new PeriodicAsyncAction({
        successInterval: webConfig.timings.updateAccountLastActive.succeeded,
        failureInterval: webConfig.timings.updateAccountLastActive.failed,

        async action() {
          const accountId = accountRef.current?.id
          if (accountId) {
            await applyActionStatic.updateModel({
              type: Model.Type.Account,
              id: accountId,
              lastActiveTimestamp: dateHelpers.getCurrentTimestamp(),
            })
          }
        },

        onFailure(error) {
          log.error('use-current-user-last-active-updating', error)
          captureException(error, { tags: { module: 'super window client' } })
        },
      }),
    []
  )

  periodicAsyncAction.setStatus(Boolean(account && isOnline))

  useEffect(() => () => periodicAsyncAction.stop(), [periodicAsyncAction])
}

import {
  AppBar,
  Button,
  Container,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { Developer, Extension } from '@zettelooo/server-shared'
import { useCallback, useEffect, useState } from 'react'
import { useMountedState } from 'react-use'
import { CustomIcon } from '../../../../../../../modules/custom-icon'
import { webConfig } from '../../../../../../../modules/web-config'
import { Gap } from '../../../../../../Gap'
import { useAppNotistack } from '../../../../../modules/app-notistack'
import { useServices } from '../../../../../modules/services'
import { Authentication } from './Authentication'

const useStyles = makeStyles(
  theme => ({
    initialLoadingMessage: {
      position: 'fixed',
      top: '50vh',
      left: '50vw',
      transform: 'translate(-50%, -50%)',
    },
    root: {
      minHeight: '100vh',
    },
    appBarButtonDisabled: {
      color: 'inherit !important',
      opacity: 0.3,
    },
    tableContainer: {
      margin: theme.spacing(6, 0),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  }),
  { name: 'AdminView' }
)

export function AdminView() {
  const [disabled, setDisabled] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [developers, setDevelopers] = useState<readonly Developer.Display[]>()
  const [reviewingExtensionHeaders, setReviewingExtensionHeaders] = useState<readonly Extension.Header[]>()

  const { signOutStatic } = Authentication.useControl()

  const { services } = useServices()

  const isMounted = useMountedState()

  const fetchData = useCallback(async (): Promise<void> => {
    try {
      setFetching(true)
      const pageData = await services.developer.adminGetPageData()
      if (!isMounted()) return
      setDevelopers(pageData.developers)
      setReviewingExtensionHeaders(pageData.reviewingExtensionHeaders)
    } catch {
      // Do nothing!
    } finally {
      setFetching(false)
    }
  }, [services])

  useEffect(() => {
    fetchData()
    const interval = setInterval(() => fetchData(), webConfig.timings.developerPageFetchDataInterval)
    return () => clearInterval(interval)
  }, [fetchData])

  const { enqueueSnackbar } = useAppNotistack()

  const classes = useStyles()

  if (!developers || !reviewingExtensionHeaders)
    return (
      <Typography variant="h6" className={classes.initialLoadingMessage}>
        Loading...
      </Typography>
    )

  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6">Admin &nbsp;&mdash;&nbsp; Admin</Typography>
          <Gap grow />
          <Button
            variant="text"
            color="inherit"
            classes={{ disabled: classes.appBarButtonDisabled }}
            disabled={fetching}
            onClick={fetchData}
          >
            <CustomIcon name="Sync" />
            &nbsp;&nbsp;Refresh
          </Button>
          <Gap horizontal={2} />
          <Button variant="text" color="inherit" onClick={signOutStatic}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>

      <Container>
        <div className={classes.tableContainer}>
          <Typography variant="h5">
            🧑‍🤝‍🧑 Developers: &nbsp;&nbsp;
            <Button
              variant="outlined"
              size="small"
              color="primary"
              disabled={disabled}
              onClick={async () => {
                const id = prompt('ID:')
                if (!id) return
                if (!/^[a-z0-9-]+$/.test(id)) {
                  alert("Only a-z, 0-9, and '-'.")
                  return
                }
                const password = prompt('Password:')
                if (!password) return
                const name = prompt('Name:')
                if (!name) return
                const email = prompt('Email:')
                if (!email) return
                try {
                  setDisabled(true)
                  await services.developer.adminAddDeveloper({ id, password, name, email })
                  await fetchData()
                } catch (error) {
                  log.error(error)
                  enqueueSnackbar('Error', String(error), { variant: 'error' })
                } finally {
                  if (isMounted()) {
                    setDisabled(false)
                  }
                }
              }}
            >
              + Add developer
            </Button>
          </Typography>
          {developers.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No developers.
            </Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {developers.map((developer, index) => (
                    <TableRow key={index}>
                      <TableCell>{developer.name}</TableCell>
                      <TableCell>{developer.id}</TableCell>
                      <TableCell>{developer.email}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          disabled={disabled}
                          onClick={async () => {
                            const password = prompt('Password:')
                            if (!password) return
                            const name = prompt('Name:', developer.name)
                            if (!name) return
                            const email = prompt('Email:', developer.email)
                            if (!email) return
                            try {
                              setDisabled(true)
                              await services.developer.adminEditDeveloper(developer.id, { password, name, email })
                              await fetchData()
                            } catch (error) {
                              log.error(error)
                              enqueueSnackbar('Error', String(error), { variant: 'error' })
                            } finally {
                              if (isMounted()) {
                                setDisabled(false)
                              }
                            }
                          }}
                        >
                          <CustomIcon name="Edit" />
                        </IconButton>
                        <Gap horizontal={1} />
                        <IconButton
                          disabled={disabled}
                          onClick={async () => {
                            try {
                              setDisabled(true)
                              await services.developer.adminRemoveDeveloper(developer.id)
                              await fetchData()
                            } catch (error) {
                              log.error(error)
                              enqueueSnackbar('Error', String(error), { variant: 'error' })
                            } finally {
                              if (isMounted()) {
                                setDisabled(false)
                              }
                            }
                          }}
                        >
                          <CustomIcon name="Delete" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        <div className={classes.tableContainer}>
          <Typography variant="h5">🔰 To be reviewed extensions:</Typography>
          {reviewingExtensionHeaders.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No extension to be reviewed.
            </Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Version</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviewingExtensionHeaders.map((extensionHeader, index) => (
                    <TableRow key={index}>
                      <TableCell>{extensionHeader.name}</TableCell>
                      <TableCell>{extensionHeader.version}</TableCell>
                      <TableCell>{extensionHeader.id}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          size="small"
                          disabled={disabled}
                          onClick={async () => {
                            try {
                              setDisabled(true)
                              await services.developer.adminApproveExtension(extensionHeader.id)
                              await fetchData()
                            } catch (error) {
                              log.error(error)
                              enqueueSnackbar('Error', String(error), { variant: 'error' })
                            } finally {
                              if (isMounted()) {
                                setDisabled(false)
                              }
                            }
                          }}
                        >
                          Approve
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </Container>
    </div>
  )
}

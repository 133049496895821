import { makeStyles, Paper, Typography } from '@material-ui/core'
import { ActivityList } from './ActivityList'

const useStyles = makeStyles(
  theme => ({
    empty: {
      width: theme.spacing(64),
      maxWidth: '100%',
      margin: '20% auto',
      padding: theme.spacing(10, 6),
      textAlign: 'center',
    },
  }),
  { name: 'UnreadsSubPanel' }
)

export function UnreadsSubPanel({ cardActivities }: { cardActivities: readonly any[] }) {
  const classes = useStyles()

  if (cardActivities.length === 0)
    return (
      <Paper variant="outlined" className={classes.empty}>
        <Typography variant="h5" gutterBottom>
          You&apos;re all caught up!
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Whenever someone creates or updates a note in any of your pages, it will be saved here.
        </Typography>
      </Paper>
    )

  return <ActivityList cardActivities={cardActivities} />
}

import { makeStyles, useTheme } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { Dispatch, SetStateAction, useCallback, useContext, useLayoutEffect, useState } from 'react'
import { useGetSet, useUpdateEffect } from 'react-use'
import { useCommonStyles } from '../../../../../../../hooks/useCommonStyles'
import { useRefWrap } from '../../../../../../../hooks/useRefWrap'
import { sendAnalyticEvent, useAnalyticSendEvent } from '../../../../../../../modules/analytics'
import { ChromeExtensionMode } from '../../../../../../../modules/chrome-extension-mode'
import { useBatchCommandHandling } from '../../../../../../../modules/commands'
import { createContexts, useContexts } from '../../../../../../../modules/contexts'
import { DeviceProvider } from '../../../../../../../modules/device'
import { webConfig } from '../../../../../../../modules/web-config'
import { useApplyAction } from '../../../../../hooks/useApplyAction'
import { useAppHistoryControl } from '../../../../../modules/app-history'
import { OnboardingTour, useOnboardingControl } from '../../../../../modules/onboarding'
import { PersistentKey, usePersistent } from '../../../../../modules/persistent'
import { SmartCommand } from '../../../../../modules/SmartCommand'
import { OverviewUpdater } from '../../../../../modules/update'
import { GLOBAL_PADDING_X_CHROME_EXTENSION_UNIT, GLOBAL_PADDING_X_UNIT } from '../../../constants'
import { AccountData } from '../../../modules/account-data'
import { NewPage } from '../../../modules/NewPage'
import { Banner } from './components/Banner'
import { ChangeLogSlides } from './components/ChangeLogSlides'
import { InvitePageMembersDialog } from './components/InvitePageMembersDialog'
import { PageSearchDialog } from './components/PageSearchDialog'
import { PanelView } from './components/PanelView'
import { Resizer } from './components/Resizer'
import { SearchDialog } from './components/SearchDialog'
import { SideBar } from './components/SideBar'
import { TitleBar } from './components/TitleBar'
import { WelcomeSlides } from './components/WelcomeSlides'
import { useExtensionMainViewLifeSpan } from './hooks/useExtensionMainViewLifeSpan'
import { useMainPageCommands } from './hooks/useMainPageCommands'
import { useSideBarSizeHandling } from './hooks/useSideBarSizeHandling'
import { Panel, usePanel, useSwitchPanel } from './panel'

const useStyles = makeStyles(
  theme => ({
    root: {
      margin: 'auto',
      height: '100vh',
      maxWidth: theme.spacing(200),
      padding: theme.spacing(
        0,
        ChromeExtensionMode.isActive ? GLOBAL_PADDING_X_CHROME_EXTENSION_UNIT : GLOBAL_PADDING_X_UNIT
      ),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
    },
    body: {
      overflow: 'hidden', // Necessary for resizer
      display: 'flex',
      flexGrow: 1,
      gap: theme.spacing(ChromeExtensionMode.isActive ? GLOBAL_PADDING_X_CHROME_EXTENSION_UNIT : GLOBAL_PADDING_X_UNIT),
    },
    sideBar: {
      flex: 'none',
    },
    fullHeight: {
      height: '100%',
    },
    resizer: {
      backgroundColor: theme.palette.divider,
      zIndex: 1,
    },
    content: {
      width: 0,
      flexGrow: 1,
    },
  }),
  { name: 'MainPage' }
)

export function MainPage() {
  const {
    persistent,
    currentValues: [sidebarVisible],
  } = usePersistent(PersistentKey.SideBarVisible)

  const { screenWidth, isMobile } = useContext(DeviceProvider.Context)
  const { account, accountPagesOrdered } = useContexts(AccountData.Contexts)

  const [searchTabTextQuery, setSearchTabTextQuery] = useState('')
  const [getFullView, setFullView] = useGetSet(false)
  const [isSideBarMenuOpen, setIsSideBarMenuOpen] = useState(ChromeExtensionMode.isActive)
  const [showWelcomeSlides, setShowWelcomeSlides] = useState(
    !account.isOnboarded &&
      webConfig.environment.agent === 'browser' &&
      !webConfig.temporary.temporarilyDisabledOrToBeRemoved
  )
  const [getIsInvitePageMembersDialogOpen, setIsInvitePageMembersDialogOpen] = useGetSet(false)
  const [getIsCardAdderDialogOpen, setIsCardAdderDialogOpen] = useGetSet(false)
  const [isPageSearchDialogOpen, setIsPageSearchDialogOpen] = useState(false)
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)
  const [overridingSmartCommandApi, setOverridingSmartCommandApi] = useState<SmartCommand.Api>()

  const accountPagesOrderedRef = useRefWrap(accountPagesOrdered)
  const openInvitePageMembersDialogStatic = useCallback((): void => {
    if (accountPagesOrderedRef.current.length > 0) {
      setIsInvitePageMembersDialogOpen(true)
      sendAnalyticEvent('Invite Member', 'Open dialog')
    }
  }, [])

  useAnalyticSendEvent('Main Page', 'Page View')

  const panel = usePanel()

  const { switchPanelStatic } = useSwitchPanel()

  const { appHistoryControlStatic } = useAppHistoryControl()

  useUpdateEffect(() => {
    if (!isMobile) {
      setIsSideBarMenuOpen(false)
    }
  }, [isMobile])

  useLayoutEffect(() => {
    if (isMobile && !isSideBarMenuOpen && panel?.type === Panel.Type.NewPage) {
      appHistoryControlStatic.goBackward()
    }
  }, [isMobile, isSideBarMenuOpen, panel])

  const { handleResizerResize } = useSideBarSizeHandling()

  useMainPageCommands(
    getIsInvitePageMembersDialogOpen,
    setIsCardAdderDialogOpen,
    openInvitePageMembersDialogStatic,
    panel
  )

  useBatchCommandHandling(
    {
      'main.search'() {
        setIsSearchDialogOpen(true)
      },
      'main.pageSearch'() {
        setIsPageSearchDialogOpen(true)
      },
      'main.toggleFullView'() {
        setFullView(!getFullView())
        if (!getFullView()) {
          persistent[PersistentKey.SideBarVisible] = true
        }
      },
      'main.createNewPage'() {
        switchPanelStatic({ type: Panel.Type.NewPage })
        persistent[PersistentKey.SideBarVisible] = true
        setFullView(false)
      },
    },
    []
  )

  const { startOnboardingTourStatic } = useOnboardingControl()

  const { applyActionStatic } = useApplyAction()

  const {
    /* titleBarItems */
  } = useExtensionMainViewLifeSpan(panel, switchPanelStatic)

  const theme = useTheme()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  const isSideBarLocked =
    screenWidth < theme.constants.sidebar.resizableSideBarMinimumScreenWidth || panel?.type === Panel.Type.NewPage

  return (
    <>
      <OverviewUpdater />

      <MainPage.Contexts.Provider
        parameters={{
          searchTabTextQuery,
          setSearchTabTextQuery,
          getFullView,
          setFullView,
          isSideBarMenuOpen,
          setIsSideBarMenuOpen,
          isSideBarLocked,
          setIsCardAdderDialogOpen,
          overridingSmartCommandApi,
          setOverridingSmartCommandApi,
          panel,
        }}
      >
        <NewPage.Provider
          onNewPageIsCanceled={() => {
            switchPanelStatic(undefined)
          }}
          onNewPageModelIsReady={newPageId => {
            setIsSideBarMenuOpen(false)
            switchPanelStatic({
              type: Panel.Type.Page,
              pageId: newPageId,
              isNewPage: true,
            })
          }}
        >
          <div className={classes.root}>
            <TitleBar /* items={titleBarItems} */ />

            <Banner />

            <div className={classes.body}>
              <SideBar
                className={classNames(
                  classes.sideBar,
                  classes.fullHeight,
                  (isMobile ||
                    !sidebarVisible ||
                    (webConfig.temporary.temporarilyDisabledOrToBeRemoved &&
                      screenWidth <= theme.constants.sidebar.resizableSideBarMinimumScreenWidth) ||
                    getFullView()) &&
                    !isSideBarMenuOpen &&
                    commonClasses.hidden
                )}
              />

              {!webConfig.temporary.temporarilyDisabledOrToBeRemoved && (
                <Resizer
                  orientation="vertical"
                  className={classNames(
                    classes.fullHeight,
                    classes.resizer,
                    (isMobile || !sidebarVisible || getFullView() || isSideBarLocked) && commonClasses.hidden
                  )}
                  onResize={handleResizerResize}
                  disabled={panel?.type === Panel.Type.NewPage}
                />
              )}

              <PanelView className={classNames(classes.fullHeight, classes.content)} panel={panel} />
            </div>
          </div>

          {showWelcomeSlides && (
            <WelcomeSlides
              onClose={async () => {
                setShowWelcomeSlides(false)
                await applyActionStatic.updateModel({
                  type: Model.Type.Account,
                  id: account.id,
                  isOnboarded: true,
                })
              }}
              onFinish={() => {
                if (!webConfig.temporary.disableOnboardingWelcomeTour) {
                  startOnboardingTourStatic(OnboardingTour.Welcome)
                }
              }}
            />
          )}

          <ChangeLogSlides />

          {getIsInvitePageMembersDialogOpen() && (
            <InvitePageMembersDialog onClose={() => setIsInvitePageMembersDialogOpen(false)} />
          )}

          <PageSearchDialog open={isPageSearchDialogOpen} onClose={() => setIsPageSearchDialogOpen(false)} />

          <SearchDialog open={isSearchDialogOpen} onClose={() => setIsSearchDialogOpen(false)} />
        </NewPage.Provider>
      </MainPage.Contexts.Provider>
    </>
  )
}

export namespace MainPage {
  export const Contexts = createContexts(
    ({ memoize }) =>
      (parameters?: {
        isSideBarLocked: boolean
        searchTabTextQuery: string
        setSearchTabTextQuery: Dispatch<SetStateAction<string>>
        getFullView(): boolean
        setFullView: Dispatch<SetStateAction<boolean>>
        isSideBarMenuOpen: boolean
        setIsSideBarMenuOpen: Dispatch<SetStateAction<boolean>>
        setIsCardAdderDialogOpen: Dispatch<SetStateAction<boolean>>
        overridingSmartCommandApi: SmartCommand.Api | undefined
        setOverridingSmartCommandApi: Dispatch<SetStateAction<SmartCommand.Api | undefined>>
        panel: Panel | undefined
      }) => ({
        refs: memoize(
          () => ({
            setSearchTabTextQuery: parameters?.setSearchTabTextQuery!,
            getFullView: parameters?.getFullView!,
            exitFullView(): void {
              parameters?.setFullView(false)
            },
            setIsSideBarMenuOpen: parameters?.setIsSideBarMenuOpen!,
            setIsCardAdderDialogOpen: parameters?.setIsCardAdderDialogOpen!,
            setOverridingSmartCommandApi: parameters?.setOverridingSmartCommandApi!,
          }),
          []
        ),
        isSideBarLocked: parameters?.isSideBarLocked,
        searchTabTextQuery: parameters?.searchTabTextQuery!,
        fullView: parameters?.getFullView()!,
        isSideBarMenuOpen: parameters?.isSideBarMenuOpen!,
        overridingSmartCommandApi: parameters?.overridingSmartCommandApi,
        panel: parameters?.panel,
      }),
    'MainPage'
  )
}

import { ZettelExtensions } from '@zettelooo/extension-api'
import { RenderProvided } from './RenderProvided'
import { useRender } from './useRender'
import { WithReferenceKey } from './withReferenceKey'

export function Render<E extends Element = Element, S = undefined>({
  htmlContentWithReferenceKey,
  disabled,
  children,
}: {
  htmlContentWithReferenceKey: WithReferenceKey<ZettelExtensions.HtmlContent<S>>
  disabled?: boolean
  children: (provided: RenderProvided<E>) => React.JSX.Element
}) {
  return <>{children(useRender(htmlContentWithReferenceKey, { disabled }))}</>
}

import { Box, Typography, useTheme } from '@material-ui/core'
import { ReactNode } from 'react'

export function Hint({
  children,
  label = 'Referencing file:',
  description,
}: {
  children: ReactNode
  label?: string
  description?: ReactNode
}) {
  const theme = useTheme()

  return (
    <Box padding={3}>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        ⓘ&nbsp;&nbsp;{label}
      </Typography>

      <Box
        fontFamily="monospace"
        borderRadius={theme.spacing(1)}
        border={`1px solid ${theme.palette.divider}`}
        padding={1}
        whiteSpace="pre-line"
        style={{ wordBreak: 'break-all' }}
      >
        {children}
      </Box>

      {description && (
        <Typography variant="body2" noWrap>
          {description}
        </Typography>
      )}
    </Box>
  )
}

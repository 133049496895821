import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { DetailedHTMLProps, HTMLAttributes } from 'react'

const SQUARE_WIDTH_UNITS = 75
const SQUARE_HEIGHT_UNITS = 60
const SQUARE_DISTANCE_UNITS = 3

const useStyles = makeStyles(
  theme => ({
    square: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: theme.spacing(SQUARE_WIDTH_UNITS),
      height: theme.spacing(SQUARE_HEIGHT_UNITS),
      border: `5px solid ${theme.palette.text.primary}`,
      borderRadius: '5px',
      pointerEvents: 'none',
      [`@media screen and (max-width: ${theme.spacing(SQUARE_WIDTH_UNITS + 15)}px)`]: {
        display: 'none',
      },
      [`@media screen and (max-height: ${theme.spacing(SQUARE_HEIGHT_UNITS + 15)}px)`]: {
        display: 'none',
      },
    },
    square1: {
      animation: '$squareAnimation1 2s linear infinite',
    },
    square2: {
      animation: '$squareAnimation2 2s linear infinite',
    },
    square3: {
      animation: '$squareAnimation3 2s linear infinite',
    },
    '@keyframes squareAnimation1': {
      '0%': {
        transform: 'translate(-50%, -50%)',
        opacity: 1,
      },
      '50%': {
        transform: 'translate(-50%, -50%)',
        opacity: 0,
      },
      '100%': {
        transform: 'translate(-50%, -50%)',
        opacity: 1,
      },
    },
    '@keyframes squareAnimation2': {
      '0%': {
        transform: `translate(calc(-50% - ${theme.spacing(SQUARE_DISTANCE_UNITS)}px), calc(-50% + ${theme.spacing(
          SQUARE_DISTANCE_UNITS
        )}px))`,
        opacity: 1,
      },
      '50%': {
        transform: `translate(calc(-50% + ${theme.spacing(SQUARE_DISTANCE_UNITS)}px), calc(-50% - ${theme.spacing(
          SQUARE_DISTANCE_UNITS
        )}px))`,
        opacity: 0,
      },
      '100%': {
        transform: `translate(calc(-50% - ${theme.spacing(SQUARE_DISTANCE_UNITS)}px), calc(-50% + ${theme.spacing(
          SQUARE_DISTANCE_UNITS
        )}px))`,
        opacity: 1,
      },
    },
    '@keyframes squareAnimation3': {
      '0%': {
        transform: `translate(calc(-50% - ${theme.spacing(SQUARE_DISTANCE_UNITS * 2)}px), calc(-50% + ${theme.spacing(
          SQUARE_DISTANCE_UNITS * 2
        )}px))`,
        opacity: 1,
      },
      '50%': {
        transform: `translate(calc(-50% + ${theme.spacing(SQUARE_DISTANCE_UNITS * 2)}px), calc(-50% - ${theme.spacing(
          SQUARE_DISTANCE_UNITS * 2
        )}px))`,
        opacity: 0,
      },
      '100%': {
        transform: `translate(calc(-50% - ${theme.spacing(SQUARE_DISTANCE_UNITS * 2)}px), calc(-50% + ${theme.spacing(
          SQUARE_DISTANCE_UNITS * 2
        )}px))`,
        opacity: 1,
      },
    },
  }),
  { name: 'DecorativeSquares' }
)

export function DecorativeSquares({
  children,
  ...otherProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const classes = useStyles()

  return (
    <div {...otherProps}>
      {children}

      <div className={classNames(classes.square, classes.square1)}></div>
      <div className={classNames(classes.square, classes.square2)}></div>
      <div className={classNames(classes.square, classes.square3)}></div>
    </div>
  )
}

import { Timestamp } from '@zettelooo/commons'

interface DateHelpers {
  getCurrentTimestamp(timestampCorrection?: number): Timestamp
}

export const dateHelpers: DateHelpers = {
  getCurrentTimestamp(timestampCorrection?: number): Timestamp {
    return Math.round(Date.now() + (timestampCorrection ?? 0))
  },
}

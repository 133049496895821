import { DialogActions, DialogContent, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { ComponentProps, ReactNode, useContext } from 'react'
import { DeviceProvider } from '../../../../modules/device'
import { PresentationDialog } from './PresentationDialog'

const TOTAL_MAX_WIDTH_UNITS = 131
const CONTENT_LEFT_RIGHT_MARGIN_UNITS = 9
const CONTENT_HEIGHT_UNITS = 46
const RIGHT_PANE_WIDTH_UNITS = CONTENT_HEIGHT_UNITS * (16 / 9)
const LEFT_PANE_WIDTH_UNITS = TOTAL_MAX_WIDTH_UNITS - CONTENT_LEFT_RIGHT_MARGIN_UNITS * 2 - RIGHT_PANE_WIDTH_UNITS

const useStyles = makeStyles(
  theme => ({
    paper: {
      maxWidth: theme.spacing(TOTAL_MAX_WIDTH_UNITS),
      overflow: 'hidden',
    },
    content: {
      margin: theme.spacing(14, CONTENT_LEFT_RIGHT_MARGIN_UNITS),
      height: theme.spacing(CONTENT_HEIGHT_UNITS),
      display: 'flex',
    },
    leftPane: {
      width: theme.spacing(LEFT_PANE_WIDTH_UNITS),
      minWidth: theme.spacing(LEFT_PANE_WIDTH_UNITS * 0.85),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    rightPane: {
      width: theme.spacing(RIGHT_PANE_WIDTH_UNITS),
      height: '100%',
      flexShrink: 0,
      display: 'flex',
    },
    leftPaneContent: {
      flexGrow: 1,
      overflow: 'auto',
    },
    leftPaneActions: {
      flexShrink: 0,
      overflow: 'hidden',
    },
    mobileContent: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(2),
    },
  }),
  { name: 'PresentationDialogLayout' }
)

export function PresentationDialogLayout({
  content,
  actions,
  children,
  ...presentationDialogProps
}: Omit<ComponentProps<typeof PresentationDialog>, 'content'> & {
  content?: ReactNode
  actions?: ReactNode
}) {
  const { isMobile } = useContext(DeviceProvider.Context)

  const classes = useStyles()

  if (!isMobile)
    return (
      <PresentationDialog
        {...presentationDialogProps}
        classes={{
          ...presentationDialogProps.classes,
          paper: classNames(classes.paper, presentationDialogProps.classes?.paper),
        }}
      >
        <div className={classes.content}>
          <div className={classes.leftPane}>
            <div className={classes.leftPaneContent}>{content}</div>

            <div className={classes.leftPaneActions}>{actions}</div>
          </div>

          <div className={classes.rightPane}>{children}</div>
        </div>
      </PresentationDialog>
    )

  return (
    <PresentationDialog {...presentationDialogProps} scroll="paper">
      <DialogContent className={classes.mobileContent}>
        <div>{content}</div>
        <div>{children}</div>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </PresentationDialog>
  )
}

import { DependencyList, useEffect } from 'react'
import { useDatabases } from '../useDatabases'
import { ActionsDatabase } from './ActionsDatabase'

export function useActionsDatabaseReader(
  subscription: ActionsDatabase.EventSubscription,
  dependencies: DependencyList
): void {
  const {
    databases: { actionsDatabase },
  } = useDatabases()

  useEffect(() => {
    actionsDatabase.subscribe(subscription)

    return () => {
      actionsDatabase.unsubscribe(subscription)
    }
  }, [...dependencies, actionsDatabase])
}

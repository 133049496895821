import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { ReadonlyRecord } from '@zettelooo/commons'
import classNames from 'classnames'
import Color from 'color'
import { useMemo, useState } from 'react'
import { SearchBox } from '../../components/SearchBox'
import { useManageCommands } from '../commands'
import { CustomIcon } from '../custom-icon'
import { commonKeyboardCombinations } from '../keyboard-handler'
import { Navigable, NavigableWithKeyboardHandling } from '../navigation'
import { Emoji, emojiCategories, EmojiCategory, emojiData } from './emoji-data'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: theme.spacing(48),
      maxWidth: '100%',
      height: theme.spacing(38.75),
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    categoriesWrapper: {
      flex: 'none',
      paddingTop: theme.spacing(1.5),
      overflowX: 'auto',
    },
    categories: {
      minWidth: theme.spacing(48),
      display: 'flex',
      justifyContent: 'space-around',
    },
    category: {
      cursor: 'pointer',
    },
    categoryDisabled: {
      cursor: 'unset',
      color: theme.palette.text.disabled,
    },
    categoryTitle: {
      flex: 'none',
    },
    emojisContainer: {
      paddingLeft: theme.spacing(0.75),
      overflowY: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
    },
    emoji: {
      width: theme.spacing(5),
      padding: theme.spacing(0.5, 2.5),
      fontSize: theme.spacing(3),
      color: new Color(theme.palette.text.primary).rgb().string(), // The default color has some opacity
    },
  }),
  { name: 'EmojiSelect' }
)

// TODO: Add selection for the skin tone too

const emojiCategoryCustomIconNames: ReadonlyRecord<EmojiCategory, CustomIcon.Name> = {
  [EmojiCategory.SmileysAndEmotion]: 'SmileysEmotion',
  [EmojiCategory.PeopleAndBody]: 'PeopleBody',
  [EmojiCategory.AnimalsAndNature]: 'AnimalsNature',
  [EmojiCategory.FoodAndDrink]: 'FoodDrink',
  [EmojiCategory.Activities]: 'Activities',
  [EmojiCategory.TravelAndPlaces]: 'TravelPlaces',
  [EmojiCategory.Objects]: 'Objects',
  [EmojiCategory.Symbols]: 'Symbols',
  [EmojiCategory.Flags]: 'Flags',
}

export function EmojiSelect({ onSelect, className }: { onSelect?(emoji: Emoji): void; className?: string }) {
  const [category, setCategory] = useState<string | null>(emojiCategories[0])
  const [query, setQuery] = useState('')

  const emojis = useMemo(() => {
    if (query) return emojiData.searchEmoji(query, 40)
    if (!category) return [] // TODO: The recently used emojis
    return emojiData.emojiCategoryLookUp.get(category)!
  }, [query, category])

  const { runCommandStatic } = useManageCommands()

  const classes = useStyles()

  return (
    <div className={classNames(classes.root, className)}>
      <SearchBox
        query={query}
        onSearch={newQuery => {
          setCategory(null)
          setQuery(newQuery)
        }}
      />

      <Navigable group>
        {({ connectNavigable: connectCategories }) => (
          <div ref={connectCategories} className={classes.categoriesWrapper}>
            <div className={classes.categories}>
              <div key="Search" className={classNames(classes.category, !query && classes.categoryDisabled)}>
                <Tooltip title={query ? 'Search' : ''}>
                  {/* The wrapper is necessary for the Tooltip component, see: https://material-ui.com/components/tooltips/#custom-child-element */}
                  <span>
                    <CustomIcon name="Search" color={query ? 'text.primary' : 'inherit'} />
                  </span>
                </Tooltip>
              </div>

              <NavigableWithKeyboardHandling
                padding={[1, 1, 0.5, 1]}
                keyboardSubscription={({ navigableRef }) => [
                  {
                    combinations: commonKeyboardCombinations.enter,
                    handler(event) {
                      setCategory(null)
                      setQuery('')
                    },
                  },
                ]}
              >
                {({ connectNavigable }) => (
                  <div
                    key="Recent"
                    ref={connectNavigable}
                    className={classes.category}
                    onClick={() => runCommandStatic('emojiSelectRecent.open')}
                  >
                    <Tooltip title="Recently Used">
                      <span>
                        <CustomIcon
                          name="Time"
                          color={category === null && !query ? 'text.primary' : 'text.secondary'}
                        />
                      </span>
                    </Tooltip>
                  </div>
                )}
              </NavigableWithKeyboardHandling>

              {emojiCategories.map(emojiCategory => (
                <NavigableWithKeyboardHandling
                  key={emojiCategory}
                  padding={[1, 1, 0.5, 1]}
                  keyboardSubscription={({ navigableRef }) => [
                    {
                      combinations: commonKeyboardCombinations.enter,
                      handler(event) {
                        setCategory(emojiCategory)
                        setQuery('')
                      },
                    },
                  ]}
                >
                  {({ connectNavigable }) => (
                    <div
                      ref={connectNavigable}
                      className={classes.category}
                      onClick={() => {
                        setCategory(emojiCategory)
                        setQuery('')
                      }}
                    >
                      <Tooltip title={emojiCategory}>
                        <span>
                          <CustomIcon
                            name={emojiCategoryCustomIconNames[emojiCategory]}
                            color={category === emojiCategory ? 'text.primary' : 'text.secondary'}
                          />
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </NavigableWithKeyboardHandling>
              ))}
            </div>
          </div>
        )}
      </Navigable>

      <Typography variant="overline" display="block" color="textSecondary" noWrap className={classes.categoryTitle}>
        &nbsp;&nbsp;{query ? 'Search Result' : category ?? 'Recently Used'}
      </Typography>

      <Navigable>
        {({ connectNavigable }) => (
          <div key={`${category}-${query}`} ref={connectNavigable} className={classes.emojisContainer}>
            {emojis.map(emoji => (
              <Navigable key={emoji.short_name} padding={[0.25, 0.5, 0.5]}>
                {({ connectNavigable: connectEmoji }) => (
                  <Tooltip title={`:${emoji.short_name}:`} TransitionProps={{ timeout: 0 }}>
                    <IconButton
                      ref={connectEmoji}
                      size="small"
                      className={classes.emoji}
                      onClick={() => onSelect?.(emoji)}
                    >
                      {emoji.char}
                    </IconButton>
                  </Tooltip>
                )}
              </Navigable>
            ))}
          </div>
        )}
      </Navigable>
    </div>
  )
}

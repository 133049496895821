import { makeStyles, Tooltip, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import Color from 'color'
import { useRef } from 'react'
import { useEffectOnce, useUpdate } from 'react-use'
import { useSyncEffect } from '../../../../../../hooks/useSyncEffect'
import { TourStepAnchor } from '../../types'
import { StepCard } from './StepCard'

const useStyles = makeStyles(
  theme => ({
    tooltip: {
      maxWidth: theme.spacing(50),
      boxShadow: theme.shadows[12],
      borderRadius: theme.spacing(1.25),
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.background.paper,
      color: 'unset',
    },
    arrow: {
      color: theme.palette.background.paper,
    },
    target: {
      position: 'fixed',
      zIndex: theme.zIndex.tooltip + 1, // To render on top of anything
      pointerEvents: 'none',
    },
    highlight: {
      borderWidth: theme.spacing(0.5),
      borderStyle: 'solid',
      borderRadius: theme.spacing(1),
      animation: '$twinkle 1s ease 0s infinite alternate, $wave 2s linear 0s infinite normal',
    },
    '@keyframes twinkle': {
      from: {
        borderColor: theme.palette.primary.main,
      },
      '15%': {
        borderColor: theme.palette.primary.main,
      },
      to: {
        borderColor: Color(theme.palette.primary.main).alpha(0.2).string(),
      },
    },
    '@keyframes wave': {
      from: {
        boxShadow: `0 0 0 0 ${Color(theme.palette.primary.light).alpha(0.6).string()}`,
      },
      '70%': {
        boxShadow: `0 0 0 ${theme.spacing(2)}px ${Color(theme.palette.primary.light).alpha(0).string()}`,
      },
    },
  }),
  { name: 'TooltipStepView' }
)

export function TooltipStepView({ stepAnchor }: { stepAnchor: TourStepAnchor }) {
  const visibleRef = useRef(true)

  const update = useUpdate()

  useSyncEffect(() => {
    if (stepAnchor.options.delayMilliseconds === undefined) {
      visibleRef.current = true
    } else {
      visibleRef.current = false

      const timeout = setTimeout(() => {
        visibleRef.current = true
        update()
      }, stepAnchor.options.delayMilliseconds)

      return () => clearTimeout(timeout)
    }
  }, [stepAnchor])

  useEffectOnce(() => {
    const interval = setInterval(update, 200)

    return () => clearInterval(interval)
  })

  const theme = useTheme()
  const classes = useStyles()

  if (!visibleRef.current) return null

  stepAnchor.element.scrollIntoView({ block: 'nearest', inline: 'nearest' })

  const boundingClientRect = stepAnchor.element.getBoundingClientRect()

  const padding = {
    top: theme.spacing(
      !stepAnchor.options.padding
        ? 0
        : typeof stepAnchor.options.padding === 'number'
        ? stepAnchor.options.padding
        : stepAnchor.options.padding[0]
    ),
    right: theme.spacing(
      !stepAnchor.options.padding
        ? 0
        : typeof stepAnchor.options.padding === 'number'
        ? stepAnchor.options.padding
        : stepAnchor.options.padding[1] ?? stepAnchor.options.padding[0] ?? 0
    ),
    bottom: theme.spacing(
      !stepAnchor.options.padding
        ? 0
        : typeof stepAnchor.options.padding === 'number'
        ? stepAnchor.options.padding
        : stepAnchor.options.padding[2] ?? stepAnchor.options.padding[0] ?? stepAnchor.options.padding[0] ?? 0
    ),
    left: theme.spacing(
      !stepAnchor.options.padding
        ? 0
        : typeof stepAnchor.options.padding === 'number'
        ? stepAnchor.options.padding
        : stepAnchor.options.padding[3] ?? stepAnchor.options.padding[1] ?? stepAnchor.options.padding[0] ?? 0
    ),
  }

  const top = boundingClientRect.top - padding.top
  const left = boundingClientRect.left - padding.left
  const width = boundingClientRect.right + padding.right - (boundingClientRect.left - padding.left)
  const height = boundingClientRect.bottom + padding.bottom - (boundingClientRect.top - padding.top)

  return (
    <Tooltip
      arrow
      interactive
      open
      placement={stepAnchor.options.placement}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      title={<StepCard />}
    >
      <div
        key={`${top}-${left}-${width}-${height}`}
        className={classNames(classes.target, !stepAnchor.options.noHighlight && classes.highlight)}
        style={{ top, left, width, height }}
      />
    </Tooltip>
  )
}

import { ZettelExtensions } from '@zettelooo/extension-api'
import { sanitize } from 'dompurify'
import { RefCallback, useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react'
import { RenderProvided } from './RenderProvided'
import { ThemeContext } from './ThemeContext'
import { useState } from './useState'
import { useUniqueNameRepository } from './useUniqueNameRepository'
import { WithReferenceKey } from './withReferenceKey'

export function useRender<E extends Element = Element, S = undefined>(
  htmlContentWithReferenceKey: WithReferenceKey<ZettelExtensions.HtmlContent<S>>,
  options?: { readonly disabled?: boolean }
): RenderProvided<E> {
  const { render } = htmlContentWithReferenceKey
  const disabled = Boolean(options?.disabled)

  const classes = useMemo<ZettelExtensions.HtmlContent.Context.Classes>(() => ({}), [])

  const theme = useContext(ThemeContext)

  const state = useState(htmlContentWithReferenceKey, { noDuplicatedUpdateAlongWithRender: true })

  const context = useMemo<ZettelExtensions.HtmlContent.Context<S>>(
    () => ({
      classes,
      theme,
      state,
    }),
    [classes, theme, state]
  )

  const contentRef = useRef<{
    context: ZettelExtensions.HtmlContent.Context<S>
    render: typeof render
    disabled: boolean
    containerElement: E | null
    callbacks: ReturnType<NonNullable<ReturnType<typeof render>['onRendered']>> | undefined
  }>({
    context,
    render,
    disabled,
    containerElement: null,
    callbacks: undefined,
  })

  useLayoutEffect(() => {
    if (render !== contentRef.current.render || disabled !== contentRef.current.disabled) {
      contentRef.current.context = context
      contentRef.current.render = render
      contentRef.current.disabled = disabled
      renderStatic()
    } else if (context !== contentRef.current.context) {
      const previousContext = contentRef.current.context
      contentRef.current.context = context
      // We check theme changes first, since it most likely will cause classes change as well:
      if (previousContext.theme !== context.theme) {
        if (
          !contentRef.current.callbacks?.onUpdateTheme ||
          contentRef.current.callbacks.onUpdateTheme({ previousTheme: previousContext.theme }) === 'not handled'
        ) {
          renderStatic()
          return
        }
      }
      if (previousContext.classes !== context.classes) {
        if (
          !contentRef.current.callbacks?.onUpdateClasses ||
          contentRef.current.callbacks.onUpdateClasses({ previousClasses: previousContext.classes }) === 'not handled'
        ) {
          renderStatic()
          return
        }
      }
      if (previousContext.state !== context.state) {
        if (
          !contentRef.current.callbacks?.onUpdateState ||
          contentRef.current.callbacks.onUpdateState({ previousState: previousContext.state }) === 'not handled'
        ) {
          renderStatic()
          return
        }
      }
    }
  })

  const containerRefCallback = useCallback<RefCallback<E>>(containerElement => {
    contentRef.current.containerElement = containerElement
    renderStatic()
  }, [])

  const { uniqueNameRepositoryStatic } = useUniqueNameRepository()

  function renderStatic(): void {
    if (contentRef.current.callbacks) {
      contentRef.current.callbacks.onCleanUp?.()
      contentRef.current.callbacks = undefined
    }
    if (!contentRef.current.disabled && contentRef.current.containerElement) {
      const { encapsulated, html, onRendered } = contentRef.current.render({
        renderContext: contentRef.current.context,
        un: uniqueNameRepositoryStatic,
      })
      const containerElement = encapsulated
        ? contentRef.current.containerElement.shadowRoot ??
          contentRef.current.containerElement.attachShadow({ mode: 'open' })
        : contentRef.current.containerElement
      if (containerElement) {
        const sanitizedHtml = sanitize(html, {
          FORCE_BODY: true, // Allow style tag
          ADD_TAGS: ['iframe'], // Allow iframe tag
          ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'], // Allow iframe attributes
        })
        containerElement.innerHTML = sanitizedHtml
        const anchors = containerElement.querySelectorAll('a')
        for (let i = 0; i < anchors.length; i += 1) {
          anchors[i].setAttribute('target', '_blank')
        }
        contentRef.current.callbacks = onRendered?.({
          sanitizedHtml,
          containerElement: containerElement as any,
          currentContext: {
            get classes() {
              return contentRef.current.context.classes
            },
            get theme() {
              return contentRef.current.context.theme
            },
            get state() {
              return contentRef.current.context.state
            },
          },
        })
      }
    }
  }

  return {
    containerRefCallback,
  }
}

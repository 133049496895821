import { Id, HandlerOutput } from '@zettelooo/commons'

export type NavigablePath = readonly Id[]

export enum NavigableStatus {
  NotNavigated = 'NOT_NAVIGATED',
  NavigatedThrough = 'NAVIGATED_THROUGH',
  NavigatedTo = 'NAVIGATED_TO',
  Selected = 'SELECTED',
}

export interface NavigableProvided {
  connectNavigable<E extends HTMLElement = HTMLElement>(element: E | null): E | null
  connectFocusable<E extends HTMLElement = HTMLElement>(element: E | null): E | null
}

export interface NavigablePaddings {
  readonly top: number
  readonly right: number
  readonly bottom: number
  readonly left: number
}

export interface NavigationState {
  readonly navigatedPath: NavigablePath
  readonly selected: boolean
}

export type NavigationDirection = 'up' | 'down' | 'left' | 'right'

export interface NavigationAreaRegistration {
  readonly areaId: Id
  navigate(direction: NavigationDirection): HandlerOutput
  enter(): HandlerOutput
  escape(): HandlerOutput
}

import { initializeLoglevel } from './helpers/initializers/initializeLoglevel'
import { initializePolyfills } from './helpers/initializers/initializePolyfills'
import { initializeSentry } from './helpers/initializers/initializeSentry'
import { renderApplication } from './helpers/initializers/renderApplication'
import { initializeAnalytics } from './modules/analytics'

initializePolyfills()
initializeLoglevel()
initializeSentry()
initializeAnalytics()
renderApplication()

export enum ElectronPermissionStatus {
  Granted = 'GRANTED',
  NoAccessibilityPermission = 'NO_ACCESSIBILITY_PERMISSION',
  NoAutomationPermission = 'NO_AUTOMATION_PERMISSION',
}

export interface BookmarkMessage {
  title?: string
  url?: string
}

import { ChromeExtensionMode } from '../../../modules/chrome-extension-mode'
import { CommandsProvider } from '../../../modules/commands'
import { DemoMode } from '../../../modules/demo-mode'
import { DeviceProvider } from '../../../modules/device'
import { DragAndDropProvider } from '../../../modules/drag-and-drop'
import { KeyValueStoreProvider } from '../../../modules/key-value-store'
import { KeyboardHandlerProvider } from '../../../modules/keyboard-handler'
import { NavigationProvider } from '../../../modules/navigation'
import { AppContextsProvider } from '../modules/app-contexts'
import { AppHistoryProvider } from '../modules/app-history'
import { AppNotistackProvider } from '../modules/app-notistack'
import { AppRouterProvider } from '../modules/app-router'
import { AttachmentProvider } from '../modules/attachment'
import { ConfirmationDialogProvider } from '../modules/confirmation-dialog'
import { ConnectionStatusProvider } from '../modules/connection-status'
import { useNewDatabases, DatabasesProvider } from '../modules/databases'
import { useEnsureServiceWorker } from '../modules/ensure-service-worker'
import { useNewEphemeral } from '../modules/ephemeral'
import { ExtensionProvider } from '../modules/extension'
import { HtmlContentTools } from '../modules/HtmlContentTools'
import { LoadingLogoProvider, LoadingLogoSubmitter, LoadingReason } from '../modules/loading-logo'
import { LocalizationProvider } from '../modules/localization'
import { OnboardingProvider } from '../modules/onboarding'
import { useNewPersistent } from '../modules/persistent'
import { RenderedElementTools } from '../modules/RenderedElementTools'
import { useNewBroadcaster } from '../modules/service-worker'
import { useNewServices, ServicesProvider } from '../modules/services'
import { QuickStylingProvider, StylingProvider } from '../modules/styling'
import { SuperWindowClientProvider } from '../modules/super-window-client'
import { UpdateProvider } from '../modules/update'
import { DesktopSyncProvider } from './DesktopSyncProvider'

export function Provider({ children }: { children(): React.JSX.Element }) {
  const { serviceWorkerIsReady } = useEnsureServiceWorker()

  const { broadcaster } = useNewBroadcaster(serviceWorkerIsReady)

  const { databases } = useNewDatabases(broadcaster)

  const { persistent } = useNewPersistent(databases?.persistentDatabase)

  const { ephemeral } = useNewEphemeral(broadcaster, persistent)

  const { services } = useNewServices(persistent)

  return (
    <DemoMode.Provider>
      <ChromeExtensionMode.Provider>
        <DeviceProvider>
          {!serviceWorkerIsReady || !broadcaster || !databases || !persistent || !ephemeral || !services ? (
            <QuickStylingProvider>
              <LoadingLogoProvider>
                <LoadingLogoSubmitter reason={LoadingReason.SettingUpServiceWorker} />
              </LoadingLogoProvider>
            </QuickStylingProvider>
          ) : (
            <KeyValueStoreProvider keyValueStores={{ persistent, ephemeral }}>
              <DatabasesProvider databases={databases}>
                <ServicesProvider services={services}>
                  <RenderedElementTools.Provider>
                    <ExtensionProvider>
                      <ConnectionStatusProvider>
                        <LocalizationProvider>
                          <StylingProvider>
                            <HtmlContentTools.ThemeProvider>
                              <AppNotistackProvider>
                                <KeyboardHandlerProvider>
                                  <UpdateProvider>
                                    <SuperWindowClientProvider>
                                      <NavigationProvider>
                                        <ConfirmationDialogProvider>
                                          <OnboardingProvider>
                                            <CommandsProvider>
                                              <DragAndDropProvider>
                                                <AppHistoryProvider>
                                                  <AppRouterProvider>
                                                    <LoadingLogoProvider>
                                                      <AttachmentProvider>
                                                        <DesktopSyncProvider>
                                                          <AppContextsProvider>
                                                            <>{children()}</>
                                                          </AppContextsProvider>
                                                        </DesktopSyncProvider>
                                                      </AttachmentProvider>
                                                    </LoadingLogoProvider>
                                                  </AppRouterProvider>
                                                </AppHistoryProvider>
                                              </DragAndDropProvider>
                                            </CommandsProvider>
                                          </OnboardingProvider>
                                        </ConfirmationDialogProvider>
                                      </NavigationProvider>
                                    </SuperWindowClientProvider>
                                  </UpdateProvider>
                                </KeyboardHandlerProvider>
                              </AppNotistackProvider>
                            </HtmlContentTools.ThemeProvider>
                          </StylingProvider>
                        </LocalizationProvider>
                      </ConnectionStatusProvider>
                    </ExtensionProvider>
                  </RenderedElementTools.Provider>
                </ServicesProvider>
              </DatabasesProvider>
            </KeyValueStoreProvider>
          )}
        </DeviceProvider>
      </ChromeExtensionMode.Provider>
    </DemoMode.Provider>
  )
}

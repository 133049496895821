import { ReadonlyRecord, PartialReadonlyRecord, Id } from '@zettelooo/commons'
import { KeyValueStore, ValueAccessor } from '../../../../modules/key-value-store'
import { UploadingFile } from '../attachment/types'
import { ServiceWorkerMessage, ServiceWorkerMessageType } from '../service-worker'
import { OnMemoryModels } from './additionalTypes'

export enum EphemeralKey {
  RecentlyConnectedToServer = 'RECENTLY_CONNECTED_TO_SERVER',
  TimestampCorrection = 'TIMESTAMP_CORRECTION',
  SynchronizingActions = 'SYNCHRONIZING_ACTIONS',
  SynchronizingActionsDebounced = 'SYNCHRONIZING_ACTIONS_DEBOUNCED',
  UploadingFileDictionary = 'UPLOADING_FILE_DICTIONARY',
  OnMemoryModelsRegistrations = 'ON_MEMORY_MODELS_REGISTRATIONS',
  OnMemoryModelsToBeCleared = 'ON_MEMORY_MODELS_TO_BE_CLEARED',
  NewlyUpdatedExtensionIds = 'NEWLY_UPDATED_EXTENSION_IDS',
}

export interface EphemeralType {
  [EphemeralKey.RecentlyConnectedToServer]: boolean
  [EphemeralKey.TimestampCorrection]: number | undefined
  [EphemeralKey.SynchronizingActions]: boolean
  [EphemeralKey.SynchronizingActionsDebounced]: boolean
  [EphemeralKey.UploadingFileDictionary]: UploadingFile.Dictionary
  [EphemeralKey.OnMemoryModelsRegistrations]: ReadonlyRecord<string, OnMemoryModels>
  [EphemeralKey.OnMemoryModelsToBeCleared]: PartialReadonlyRecord<string, true>
  [EphemeralKey.NewlyUpdatedExtensionIds]: readonly Id[]
}

export type Ephemeral = KeyValueStore<EphemeralType>
export type EphemeralAccessor<K extends EphemeralKey> = ValueAccessor<EphemeralType, K>

export enum EphemeralServiceWorkerMessageSubType {
  RequestCurrentValues = 'REQUEST_CURRENT_VALUES',
  CurrentValues = 'CURRENT_VALUES',
}
export type EphemeralServiceWorkerMessage<
  T extends EphemeralServiceWorkerMessageSubType = EphemeralServiceWorkerMessageSubType
> = ServiceWorkerMessage<ServiceWorkerMessageType.Ephemeral> &
  {
    [EphemeralServiceWorkerMessageSubType.RequestCurrentValues]: {
      readonly subType: EphemeralServiceWorkerMessageSubType.RequestCurrentValues
    }
    [EphemeralServiceWorkerMessageSubType.CurrentValues]: {
      readonly subType: EphemeralServiceWorkerMessageSubType.CurrentValues
      readonly currentValues?: Readonly<EphemeralType>
    }
  }[T]

import { makeStyles } from '@material-ui/core'
import Color from 'color'

// Since, it's a global level hook, local styles hooks (which will be created later) can override these properties.
// Therefore, !important will be necessary sometimes:
const useStyles = makeStyles(
  theme => ({
    hidden: {
      display: 'none !important',
    },
    grow: {
      width: 0,
      height: 0,
      flexGrow: 1,
    },
    center: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    isBeingDragged: {
      opacity: 0.3,
    },
    errorButton: {
      color: theme.palette.error.main,
      backgroundColor: new Color(theme.palette.error.main).alpha(0.06).string(),
      '&:hover': {
        backgroundColor: new Color(theme.palette.error.main).alpha(0.12).string(),
      },
    },
    easeBlinkAnimation: {
      animation: '$easeBlinkAnimation 2s infinite ease',
    },
    '@keyframes easeBlinkAnimation': {
      from: {
        opacity: 0.6,
        transform: 'scale(0.96)',
      },
      '50%': {
        opacity: 1,
        transform: 'scale(1)',
      },
      to: {
        opacity: 0.6,
        transform: 'scale(0.96)',
      },
    },
    electronDrag: {
      '-webkit-app-region': 'drag', // To let the window be draggable in Desktop from this element
    },
    electronNoDrag: {
      '-webkit-app-region': 'no-drag', // To let the element be clickable inside an `electronDrag` element
    },
  }),
  { name: 'useCommonStyles' }
)

export function useCommonStyles() {
  return {
    commonClasses: useStyles(),
  }
}

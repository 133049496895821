import { useCallback } from 'react'
import { useRefWrap } from '../../../../../hooks/useRefWrap'
import { useContexts } from '../../../../../modules/contexts'
import { OnboardingProviderContexts } from '../OnboardingProvider'
import { OnboardingStep, OnboardingTour, TourStepAnchorOptions } from '../types'

export function useOnboardingConnect<T extends OnboardingTour>(
  tour: T,
  step: OnboardingStep<T>,
  options?: {
    readonly disabled?: boolean
  } & TourStepAnchorOptions
): {
  connectOnboardingAnchorElement<E extends HTMLElement = HTMLElement>(element: E | null): E | null
} {
  const {
    refs: { tourStepAnchorsRef, getOnboardingState, update },
  } = useContexts(OnboardingProviderContexts)

  const { disabled, ...anchorOptions } = options ?? {}

  const anchorOptionsRef = useRefWrap(anchorOptions)

  return {
    connectOnboardingAnchorElement: useCallback(
      (element: HTMLElement | null): any => {
        if (!(tour in tourStepAnchorsRef.current)) {
          tourStepAnchorsRef.current[tour] = {}
        }

        if (disabled || !element) {
          delete tourStepAnchorsRef.current[tour as OnboardingTour]![step as string]
        } else {
          tourStepAnchorsRef.current[tour as OnboardingTour]![step as string] = {
            element,
            options: anchorOptionsRef.current,
          }
        }

        if (getOnboardingState()) {
          update()
        }
      },
      [tour, step, disabled]
    ),
  }
}

import { AccountServiceSignature } from '@zettelooo/server-shared'
import { useMemo } from 'react'
import { routes, RouteSpecification } from '../../../modules/routes'

export interface Query {
  /** Signing in for the desktop application */
  readonly isForDesktop: boolean

  /** In the Web3 sign-in process */
  readonly isWeb3SigningIn: boolean

  /** In the Google sign-in process */
  readonly isGoogleSigningIn: boolean

  /** Signing in failed and we're in this page again */
  readonly didRegistrationFail: boolean

  /** Signing up, redirected from Stripe payment, payment status */
  readonly paymentStatus: 'none' | 'success' | 'cancel'

  readonly accessToken: string | undefined
  readonly refreshToken: string | undefined

  readonly rawQuery: RouteSpecification.Query<typeof routes.signIn>
}

export function useQuery(): Query {
  const rawQuery = routes.signIn.useQuery()

  const query = useMemo<Query>(() => {
    const { agent, method, registered, payment, at, rt } = rawQuery
    return {
      isForDesktop: agent === 'electron',
      isWeb3SigningIn: method === 'web3',
      isGoogleSigningIn: method === 'google',
      didRegistrationFail: registered === 'false',
      paymentStatus: payment ?? 'none',
      accessToken: at,
      refreshToken: rt,
      rawQuery,
    }
  }, [rawQuery])

  return query
}

import { ReadonlyRecord, Id } from '@zettelooo/commons'
import Dexie from 'dexie'

export class DatabaseTable<R extends DatabaseTable.RecordBase> {
  private constructor(readonly dexie: Dexie.Table<R, string>, readonly memory: DatabaseTable.MemoryTable<R>) {}

  static createOnDexie<R extends DatabaseTable.RecordBase>(dexie: Dexie, tableName: string): DatabaseTable<R> {
    return new DatabaseTable(dexie.table(tableName), undefined as any)
  }

  static createOnMemory<R extends DatabaseTable.RecordBase>(keyName: DatabaseTable.RecordKey<R>): DatabaseTable<R> {
    return new DatabaseTable(undefined as any, new DatabaseTable.MemoryTable(keyName))
  }
}

export namespace DatabaseTable {
  export type RecordBase = ReadonlyRecord<string, any>

  export type RecordKey<R extends RecordBase> = { [K in keyof R]: R[K] extends string ? K : never }[keyof R]

  export class MemoryTable<R extends RecordBase> {
    private records: Record<Id, R> = {}

    onCreating?: (this: DemoTable.CreatingContext, key: string, record: R) => void | any

    onUpdating?: (this: DemoTable.UpdatingContext<R>, key: string, newRecord: R, oldRecord: R) => void | any

    onReading?: (record: R) => void | any

    onDeleting?: (this: DemoTable.DeletingContext, key: string, record: R) => void

    constructor(private keyName: RecordKey<R>) {}

    count(): number {
      return Object.keys(this.records).length
    }

    has(key: string): boolean {
      return key in this.records
    }

    get(key: string): R | undefined {
      if (!(key in this.records)) return undefined
      const record = this.records[key]
      return this.onReading?.(record) || record
    }

    put(record: R): string {
      const key = record[this.keyName]
      if (key in this.records) {
        if (this.onUpdating) {
          const updatingContext: DemoTable.UpdatingContext<R> = {}
          const finalRecord = this.onUpdating.call(updatingContext, key, record, this.records[key]) || record
          this.records[key] = finalRecord
          updatingContext.onSuccess?.(finalRecord)
        } else {
          this.records[key] = record
        }
      } else if (this.onCreating) {
        const creatingContext: DemoTable.CreatingContext = {}
        const finalRecord = this.onCreating.call(creatingContext, key, record) || record
        this.records[key] = finalRecord
        creatingContext.onSuccess?.()
      } else {
        this.records[key] = record
      }
      return key
    }

    delete(key: string): void {
      if (!(key in this.records)) return
      const record = this.records[key]
      let deletingContext: DemoTable.DeletingContext | undefined
      if (this.onDeleting) {
        deletingContext = {}
        this.onDeleting.call(deletingContext, key, record)
      }
      delete this.records[key]
      deletingContext?.onSuccess?.()
    }

    getAll(): R[] {
      const keys = Object.keys(this.records)
      return keys.map(key => this.get(key)!)
    }

    putAll(records: readonly R[]): void {
      records.forEach(record => this.put(record))
    }

    deleteAll(): void {
      const keys = Object.keys(this.records)
      keys.forEach(key => this.delete(key))
    }
  }

  export namespace DemoTable {
    export interface CreatingContext {
      onSuccess?: () => void
    }

    export interface UpdatingContext<R extends RecordBase> {
      onSuccess?: (updatedRecord: R) => void
    }

    export interface DeletingContext {
      onSuccess?: () => void
    }
  }
}

import { ZettelExtensions } from '@zettelooo/extension-api'
import { referenceKey } from './referenceKey'
import { stateAndListenersMap } from './stateAndListenersMap'
import { WithReferenceKey } from './withReferenceKey'

export function setState<S>(
  htmlContentWithReferenceKey: WithReferenceKey<ZettelExtensions.HtmlContent<S>>,
  state: S
): void {
  const referenceValue = htmlContentWithReferenceKey[referenceKey]
  const stateAndListeners = stateAndListenersMap.get(referenceValue)
  if (stateAndListeners) {
    stateAndListeners.state = state
    stateAndListeners.listeners.forEach(listener => listener(stateAndListeners.state, false))
  }
}

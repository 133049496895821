import { Fab, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core'
import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { DetailedHTMLProps, HTMLAttributes, PropsWithoutRef } from 'react'
import { sendAnalyticEvent } from '../../../../../../../modules/analytics'
import { useBatchCommandHandling, useManageCommands } from '../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../modules/custom-icon'
import { useInfiniteVirtualScrollList } from '../../../../../../../modules/infinite-virtual-scroll'
import { AppContexts } from '../../../../../modules/app-contexts'
import { ExtensionRolesProvider } from '../../../../../modules/extension'
import { OnboardingTour, OnboardingWelcomeTourStep, useOnboardingConnect } from '../../../../../modules/onboarding'
import { Dropzone } from '../../../components/Dropzone'
import { CardAdder } from '../CardAdder'
import { useCreateTextCard } from '../useCreateTextCard'
import { CardViewerWrapper } from './CardViewerWrapper'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    containerWrapper: {
      overflowY: 'scroll', // TODO: Temporary solution to make both cards and composer aligned
    },
    cardsContainerWrapper: {
      height: 0,
      flexGrow: 1,
      paddingTop: theme.spacing(1),
    },
    cardAdderBoxContainerWrapper: {},
    container: {
      padding: 0,
      width: '100%',
      maxWidth: theme.spacing(101),
      margin: 'auto',
      overflow: 'hidden',
    },
    cardsContainer: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    cardAdderBoxContainer: {
      paddingTop: theme.spacing(2),
    },
    cardAdderBoxWrapper: {
      marginBottom: theme.spacing(2),
    },
    cardAdderBox: {},
    dropzoneActive: {},
    dropzoneHighlight: {
      top: theme.spacing(1),
      right: theme.spacing(0),
      bottom: theme.spacing(0),
      left: theme.spacing(0),
    },
    jumpToBottomFabWrapper: {
      position: 'relative',
      height: 0,
      zIndex: 1, // Cards have to have a positive z-index to better drag-and-drop preview image, so do this element
    },
    jumpToBottomFab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  }),
  { name: 'CardsViewer' }
)

export function CardsViewer({
  page,
  highlightedCardId,
  cardsAreLoading,
  cards,
  readOnly = false,
  onCardDoubleClick,
  ...otherProps
}: {
  page: Model.Page
  highlightedCardId?: Id
  cardsAreLoading?: boolean
  cards: readonly Model.Card[]
  readOnly?: boolean
  onCardDoubleClick?(card: Model.Card, event: React.MouseEvent<HTMLDivElement, MouseEvent>): void
} & PropsWithoutRef<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>) {
  const { externalDraggingOverStatus } = useContexts(AppContexts)

  const theme = useTheme()

  const {
    setScrollElementStatic,
    getScrollElementStatic,
    setItemElementFactoryStatic: setCardViewerWrapperElementFactoryStatic,
    getItemElementStatic: getCardViewerWrapperElementStatic,
    itemsFrom: cardsFrom,
    itemsTo: cardsTo,
    clippedItems: clippedCards,
    getIsScrollAtBottomStatic,
    jumpToTopStatic,
    jumpToButtomStatic,
  } = useInfiniteVirtualScrollList(
    {
      initiallyScrollIsAtBottom: true,
      minimumThresholdInViewsCount: 2,
      minimumThresholdInPixels: theme.spacing(200),
      maximumThresholdInViewsCount: 6,
      maximumThresholdInPixels: theme.spacing(600),
      ultimateMaximumThresholdInViewsCount: 10,
      ultimateMaximumThresholdInPixels: theme.spacing(1000),
      averageItemHeight: theme.spacing(15),
      highlightedItemId: highlightedCardId,
      itemsAreLoading: cardsAreLoading,
      items: cards,
      initialItemsCount: 10,
    },
    [page.id]
  )

  useBatchCommandHandling(
    {
      'navigation.jumpToTop'() {
        sendAnalyticEvent('Cards Viewer > CardsViewer', 'Jump to top')
        jumpToTopStatic()
      },
      'navigation.jumpToBottom'() {
        sendAnalyticEvent('Cards Viewer > CardsViewer', 'Jump to bottom')
        jumpToButtomStatic()
      },
    },
    []
  )

  const { runCommandStatic } = useManageCommands()

  const { createTextCard } = useCreateTextCard()

  const { connectOnboardingAnchorElement } = useOnboardingConnect(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.CardAdder
  )

  const classes = useStyles()

  return (
    <ExtensionRolesProvider role="CardsViewer">
      <div
        // TODO: It's a quick workaround for page scrolling solution.
        // For some unknown reasons, when changing pages, scroll to bottom functionality is broken.
        // This covers that, but we might need to figure out the actual cause and fix that.
        // The `highlightedCardId` part is only for when you have a certain page opened with a
        // certain highlighted card, then you open the page again as a fresh page,
        // it opens scrolled to the bottom initially:
        key={`${page.id}-${highlightedCardId}`}
        {...otherProps}
        className={classNames(classes.root, otherProps.className)}
      >
        <div
          ref={setScrollElementStatic}
          className={classNames(classes.containerWrapper, classes.cardsContainerWrapper)}
        >
          <Dropzone
            visible={externalDraggingOverStatus !== 'none'}
            disabled={clippedCards.length > 0}
            icon={<CustomIcon name="Upload" size="large" color="secondary" />}
            message={
              <Typography variant="h5" color="secondary" noWrap>
                {externalDraggingOverStatus === 'files'
                  ? 'Upload as card'
                  : externalDraggingOverStatus === 'html' || externalDraggingOverStatus === 'text'
                  ? 'Paste as card'
                  : externalDraggingOverStatus === 'urls'
                  ? 'Paste as link card'
                  : ''}
              </Typography>
            }
            className={classNames(classes.container, classes.cardsContainer)}
            activeClassName={classes.dropzoneActive}
            highlightClassName={classes.dropzoneHighlight}
            // onDroppedFiles={items => createAttachmentCard(page.id, items)}
            onDroppedText={text => createTextCard(page.id, text)}
          >
            {clippedCards.map((card, index) => (
              <CardViewerWrapper
                key={card.id}
                ref={setCardViewerWrapperElementFactoryStatic(card.id)}
                page={page}
                card={card}
                previousCard={cards[index + cardsFrom! - 1]}
                nextCard={cards[index + cardsFrom! + 1]}
                highlightedCardId={highlightedCardId}
                readOnly={readOnly}
                onDoubleClick={onCardDoubleClick && (event => onCardDoubleClick(card, event))}
              />
            ))}
          </Dropzone>
        </div>

        {!getIsScrollAtBottomStatic() && (
          <div className={classes.jumpToBottomFabWrapper}>
            <Tooltip title="Jump to bottom" placement="left">
              <Fab
                className={classes.jumpToBottomFab}
                onClick={() => {
                  // Without timeout, jumping to bottom is disturbed by the click event.
                  // For that or whatever other reason, it requires a timeout to work correctly:
                  setTimeout(() => runCommandStatic('navigation.jumpToBottom'))
                }}
              >
                <CustomIcon name="ChevronDown" size="large" />
              </Fab>
            </Tooltip>
          </div>
        )}

        {!readOnly && (
          <div className={classNames(classes.containerWrapper, classes.cardAdderBoxContainerWrapper)}>
            <div className={classNames(classes.container, classes.cardAdderBoxContainer)}>
              <div ref={connectOnboardingAnchorElement} className={classes.cardAdderBoxWrapper}>
                <CardAdder
                  key={page.id}
                  className={classes.cardAdderBox}
                  page={page}
                  onSubmitFinished={() => {
                    // Wait until the new card is being reflected into the cards here:
                    setTimeout(() => runCommandStatic('navigation.jumpToBottom'))
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </ExtensionRolesProvider>
  )
}

import { ZettelExtensions } from '@zettelooo/extension-api'
import { referenceKey } from './referenceKey'
import { stateAndListenersMap } from './stateAndListenersMap'
import { WithReferenceKey } from './withReferenceKey'

export function createReference<S>(
  htmlContentWithReference: WithReferenceKey<ZettelExtensions.HtmlContent<S>>
): ZettelExtensions.HtmlContent.Reference<S> {
  const referenceValue = htmlContentWithReference[referenceKey]
  stateAndListenersMap.set(referenceValue, {
    state: htmlContentWithReference.initialState,
    listeners: [],
  })
  const stateAndListeners = stateAndListenersMap.get(referenceValue)!

  return {
    setState(newState) {
      stateAndListeners.state =
        typeof newState === 'function' ? (newState as (oldState: S) => S)(stateAndListeners.state) : newState
      stateAndListeners.listeners.forEach(listener => listener(stateAndListeners.state, false))
    },
    getState() {
      return stateAndListeners.state
    },
  }
}

import { ReadonlyRecord } from '@zettelooo/commons'
import { createContext, PropsWithChildren } from 'react'
import { MemoizedContextProvider } from '../../components/MemoizedContextProvider'
import { KeyValueStore, KeyValueStoreTypeBase } from './types'

type KeyValueStores = ReadonlyRecord<string, KeyValueStore<KeyValueStoreTypeBase>>

export const KeyValueStoreContext = createContext<KeyValueStores>(undefined as any)

export function KeyValueStoreProvider({
  keyValueStores,
  children,
}: PropsWithChildren<{
  keyValueStores: KeyValueStores
}>) {
  return (
    <MemoizedContextProvider
      context={KeyValueStoreContext}
      value={keyValueStores}
      dependencies={Object.values(keyValueStores)}
    >
      {children}
    </MemoizedContextProvider>
  )
}

import { makeStyles } from '@material-ui/core'
import { useState } from 'react'
import { Layout } from './Layout'
import { useItemVisiblityCheck } from './useItemVisiblityCheck'

const useStyles = makeStyles(
  theme => ({
    root: {},
  }),
  { name: 'PromoteChromeExtension' }
)

export function PromoteChromeExtension() {
  const [visible, setVisible] = useState(false)

  useItemVisiblityCheck(visible)

  const classes = useStyles()

  if (!visible) return null

  return (
    <Layout variant="info" onClose={() => setVisible(false)}>
      Chrome!
    </Layout>
  )
}

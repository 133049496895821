import { UserServiceSignature } from '@zettelooo/server-shared'
import { DemoMode } from '../../../../../modules/demo-mode'
import { PeekMode } from '../../../../../modules/peek-mode'
import { Persistent } from '../../persistent'
import { RestService } from './RestService'

export class UserService extends RestService {
  constructor(persistent: Persistent) {
    super('user', persistent)
  }

  async inviteUsers(emails: string[]): Promise<void> {
    if (DemoMode.data || PeekMode.data) return

    const {} = await this.requestAuthenticated<
      UserServiceSignature.InviteUsers.Request,
      UserServiceSignature.InviteUsers.Response
    >('invite-users', { emails })
  }
}

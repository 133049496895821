import { makeStyles } from '@material-ui/core'
import { delay, Id } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Model } from '@zettelooo/server-shared'
import {
  ComponentRef,
  Dispatch,
  forwardRef,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useMountedState } from 'react-use'
import { arrayHelpers } from '../../../../../../../../../../helpers/native/arrayHelpers'
import { useCombineRefs } from '../../../../../../../../../../hooks/useCombineRefs'
import { useSyncEffect } from '../../../../../../../../../../hooks/useSyncEffect'
import { useCommandHandling } from '../../../../../../../../../../modules/commands'
import { createContexts, useContexts } from '../../../../../../../../../../modules/contexts'
import { DeviceProvider } from '../../../../../../../../../../modules/device'
import { Navigable } from '../../../../../../../../../../modules/navigation'
import { webConfig } from '../../../../../../../../../../modules/web-config'
import { useApplyAction } from '../../../../../../../../hooks/useApplyAction'
import { commonMutablesDatabaseReaders } from '../../../../../../../../modules/databases'
import { ExtensionRolesProvider, ExtensionScopeProvider } from '../../../../../../../../modules/extension'
import {
  OnboardingTour,
  OnboardingWelcomeTourStep,
  useOnboardingCondition,
  useOnboardingConnect,
  useOnboardingWaitFor,
} from '../../../../../../../../modules/onboarding'
import { useServices } from '../../../../../../../../modules/services'
import { Initializer } from '../../../../../../../Initializer'
import { AccountData } from '../../../../../../modules/account-data'
import { CardsViewer } from '../../../../../../modules/card'
import { MainPage } from '../../../MainPage'
import { Panel, useSwitchPanel } from '../../../panel'
import { PanelLayout } from '../PanelLayout'
import { Header } from './Header'
import { LoadingIndicator } from './LoadingIndicator'
import { Messages } from './Messages'
import { SideBar } from './SideBar'
import { SmartCommandApiProvider } from './SmartCommandApiProvider'
import { useExtensionPagePanelLifeSpan } from './useExtensionPagePanelLifeSpan'

const useStyles = makeStyles(
  theme => ({
    content: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    loadingIndicator: {
      flex: 'none',
    },
    cardsViewer: {
      height: 0,
      flexGrow: 1,
    },
  }),
  { name: 'PagePanel' }
)

export namespace PagePanel {
  export const Component = forwardRef<
    HTMLDivElement,
    {
      panel: Panel<Panel.Type.Page>
    }
  >(function PagePanel({ panel }, ref) {
    const { isMobile } = useContext(DeviceProvider.Context)
    const { groupedBadges } = useContexts(Initializer.Contexts)
    const { accountPagesAreLoading, accountPagesOrdered } = useContexts(AccountData.Contexts)
    const {
      refs: { getFullView, exitFullView, setIsSideBarMenuOpen },
      fullView,
      isSideBarMenuOpen,
    } = useContexts(MainPage.Contexts)

    const [sideBarMode, setSideBarMode] = useState<SideBar.Mode>()
    const [toBeInitializedExtensions, setToBeInitializedExtensions] = useState<
      readonly {
        readonly extensionId: Id
        readonly command?: string
      }[]
    >([])
    const [initializingSomeExtension, setInitializingSomeExtension] = useState(false)

    const page = useMemo(
      () =>
        accountPagesAreLoading ? undefined : accountPagesOrdered.find(accountPage => accountPage.id === panel.pageId),
      [accountPagesAreLoading, accountPagesOrdered, panel.pageId]
    )

    const messagesRef = useRef<ComponentRef<typeof Messages>>(null)

    const { switchPanelStatic } = useSwitchPanel()

    const { applyActionStatic } = useApplyAction()

    const { services } = useServices()

    const isMounted = useMountedState()

    const combineRefs = useCombineRefs(ref)

    const { cardsAreLoading, cards } = commonMutablesDatabaseReaders.useCardsForPage(panel.pageId)

    const {
      extentionInitializers,
      extendedMenuItems,
      extendedMessages,
      extendedLoadingIndicatorMessages,
      extendedQuickActions,
      // extendedCommandLinePromptInputs,
      renderExtensionLifeSpan,
    } = useExtensionPagePanelLifeSpan({ panel, page, cards })

    useEffect(() => {
      if (initializingSomeExtension) return
      const toBeInitializedExtension = toBeInitializedExtensions.find(
        ({ extensionId }) => extentionInitializers[extensionId]
      )
      if (toBeInitializedExtension) {
        log.log(`Initializing the extension "${toBeInitializedExtension.extensionId}"...`)
        Promise.resolve(
          extentionInitializers[toBeInitializedExtension.extensionId]!(
            toBeInitializedExtension.command !== undefined ? { command: toBeInitializedExtension.command } : {}
          )
        )
          .catch(error => {
            log.error(
              `Unable to initialize the extension "${toBeInitializedExtension.extensionId}":`,
              error,
              `Command: ${toBeInitializedExtension.command ?? 'undefined'}.`
            )
          })
          .then(async () => {
            if (!isMounted()) return
            await delay(500) // Just in case, no actual reason!
            setToBeInitializedExtensions(current => arrayHelpers.remove(current, toBeInitializedExtension))
            setInitializingSomeExtension(false)
          })
      }
    }, [toBeInitializedExtensions, extentionInitializers, initializingSomeExtension])

    // const extendedCommandLinePromptInput = extendedCommandLinePromptInputs[0] as
    //   | (typeof extendedCommandLinePromptInputs)[number]
    //   | undefined

    useSyncEffect(() => {
      if (!accountPagesAreLoading && !page) {
        switchPanelStatic(undefined, { replaceHistoryState: true })
      }
    }, [accountPagesAreLoading, page])

    useLayoutEffect(() => {
      if (isSideBarMenuOpen) {
        setSideBarMode(undefined)
      }
    }, [isSideBarMenuOpen])

    useLayoutEffect(() => {
      if (sideBarMode) {
        setIsSideBarMenuOpen(false)
      }
    }, [sideBarMode])

    useCommandHandling(
      'navigation.togglePageSettings',
      () => {
        const defaultSideBarMode: SideBar.Mode = webConfig.app.cashFlowPurpose
          ? SideBar.Mode.QuickActions
          : SideBar.Mode.CommandLine
        setSideBarMode(sideBarMode !== defaultSideBarMode ? defaultSideBarMode : undefined)
        if (getFullView()) {
          exitFullView()
        }
      },
      [panel.pageId]
    )

    useEffect(() => {
      groupedBadges.byPageId.get(panel.pageId)?.forEach(badge => applyActionStatic.deleteModel(badge))
    }, [panel.pageId, groupedBadges])

    useEffect(() => setSideBarMode(current => (fullView ? undefined : current)), [fullView])

    useEffect(() => {
      setSideBarMode(undefined)
      messagesRef.current?.reset()
    }, [panel.pageId])

    useEffect(() => {
      services.admin.putUserDailyActivityNoExceptions('open page')
    }, [panel.pageId, services])

    const extensionIds = useMemo(
      () => (page ? Model.ExtensionConfiguration.getExtensionIds(page.extensionConfiguration) : []),
      [page]
    )

    useOnboardingCondition(
      OnboardingTour.Welcome,
      OnboardingWelcomeTourStep.Card,
      cardsAreLoading || cards.length > 0,
      'This page has no notes. Please, either create a note or open a page with some notes to continue the tour.'
    )

    useOnboardingWaitFor(OnboardingTour.Welcome, OnboardingWelcomeTourStep.PageSideBar, !cardsAreLoading)

    const { connectOnboardingAnchorElement } = useOnboardingConnect(
      OnboardingTour.Welcome,
      OnboardingWelcomeTourStep.PageSideBar,
      {
        padding: [0.5, 0.5],
        placement: 'bottom-start',
      }
    )

    const classes = useStyles()

    if (!page) return null

    return (
      <ExtensionRolesProvider role="PagePanel">
        <ExtensionScopeProvider scope={ZettelExtensions.Scope.Page} value={panel.pageId} extensionIds={extensionIds}>
          {renderExtensionLifeSpan()}

          <Contexts.Provider
            parameters={{
              sideBarMode,
              setSideBarMode,
              setToBeInitializedExtensions,
              messagesRef,
              page,
              cards,
            }}
          >
            <SmartCommandApiProvider page={page}>
              <Navigable>
                {({ connectNavigable }) => (
                  <PanelLayout
                    ref={combineRefs(connectNavigable)}
                    header={<Header extendedMenuItems={extendedMenuItems} />}
                    sideBar={SideBar.render({
                      mode: sideBarMode,
                      // extendedCommandLinePromptInput,
                      extendedQuickActions,
                      isMobile,
                    })}
                  >
                    <div className={classes.content}>
                      <Messages ref={messagesRef} extendedMessages={extendedMessages} />

                      {extendedLoadingIndicatorMessages.length > 0 && (
                        <LoadingIndicator
                          className={classes.loadingIndicator}
                          message={arrayHelpers.last(extendedLoadingIndicatorMessages)!}
                        />
                      )}

                      <CardsViewer
                        className={classes.cardsViewer}
                        page={page}
                        highlightedCardId={panel.cardId}
                        cardsAreLoading={cardsAreLoading}
                        cards={cards}
                      />
                    </div>
                  </PanelLayout>
                )}
              </Navigable>
            </SmartCommandApiProvider>
          </Contexts.Provider>
        </ExtensionScopeProvider>
      </ExtensionRolesProvider>
    )
  })

  export const Contexts = createContexts(
    ({ memoize }) =>
      (parameters?: {
        sideBarMode: SideBar.Mode | undefined
        setSideBarMode: Dispatch<SetStateAction<SideBar.Mode | undefined>>
        setToBeInitializedExtensions: Dispatch<
          SetStateAction<
            readonly {
              readonly extensionId: Id
              readonly command?: string
            }[]
          >
        >
        messagesRef: RefObject<ComponentRef<typeof Messages>>
        page: Model.Page
        cards: readonly Model.Card[]
      }) => ({
        refs: memoize(
          () => ({
            setSideBarMode: parameters?.setSideBarMode!,
            addExtensionsToBeInitialized: (addedExtensionIds: readonly Id[], command?: string): void => {
              parameters?.setToBeInitializedExtensions(current => [
                ...current,
                ...addedExtensionIds.map(extensionId => ({ extensionId, command })),
              ])
            },
            removeExtensionsFromBeingInitialized: (removedExtensionIds: readonly Id[]): void => {
              parameters?.setToBeInitializedExtensions(current =>
                current.filter(item => !removedExtensionIds.includes(item.extensionId))
              )
            },
            messagesRef: parameters?.messagesRef!,
          }),
          []
        ),
        sideBarMode: parameters?.sideBarMode,
        page: parameters?.page!,
        cards: parameters?.cards!,
      }),
    'PagePanel'
  )
}

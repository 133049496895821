import { HandlerOutput } from '@zettelooo/commons'
import { useCallback, useContext } from 'react'
import { useMountedState } from 'react-use'
import { useRefWrap } from '../../../hooks/useRefWrap'
import { QueriedCommand } from '../CommandManager'
import { CommandsContext } from '../CommandsProvider'

export function useManageCommands(): {
  queryCommandStatic(codeName: string): QueriedCommand | undefined
  runCommandStatic(codeName: string): HandlerOutput | Promise<HandlerOutput>
} {
  const { commandManager } = useContext(CommandsContext)
  const commandManagerRef = useRefWrap(commandManager)

  const isMounted = useMountedState()
  const isMountedRef = useRefWrap(isMounted)

  return {
    queryCommandStatic: useCallback(codeName => commandManagerRef.current.queryAvailableCommand(codeName), []),

    runCommandStatic: useCallback(
      codeName => commandManagerRef.current.handleCommandDelayed(codeName, isMountedRef),
      []
    ),
  }
}

import { useContext, useEffect } from 'react'
import { useUpdate } from 'react-use'
import { KeyValueStoreContext } from './KeyValueStoreProvider'
import { KeyValueStore, KeyValueStoreTypeBase } from './types'

export function createUseKeyValueStoreFactory<KeyValueStoreType extends KeyValueStoreTypeBase>() {
  return function useKeyValueStoreFactory<KeyValueStoreName extends string>(keyValueStoreName: KeyValueStoreName) {
    return function useKeyValueStore<W extends readonly (keyof KeyValueStoreType)[]>(
      ...watchList: W
    ): W extends [infer K0, infer K1, infer K2, infer K3, infer K4, infer K5, infer K6, infer K7, infer K8, infer K9]
      ? [
          KeyValueStore<KeyValueStoreType>,
          K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
          K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
          K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
          K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never,
          K4 extends keyof KeyValueStoreType ? KeyValueStoreType[K4] : never,
          K5 extends keyof KeyValueStoreType ? KeyValueStoreType[K5] : never,
          K6 extends keyof KeyValueStoreType ? KeyValueStoreType[K6] : never,
          K7 extends keyof KeyValueStoreType ? KeyValueStoreType[K7] : never,
          K8 extends keyof KeyValueStoreType ? KeyValueStoreType[K8] : never,
          K9 extends keyof KeyValueStoreType ? KeyValueStoreType[K9] : never
        ] &
          Record<KeyValueStoreName, KeyValueStore<KeyValueStoreType>> & {
            currentValues: [
              K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
              K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
              K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
              K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never,
              K4 extends keyof KeyValueStoreType ? KeyValueStoreType[K4] : never,
              K5 extends keyof KeyValueStoreType ? KeyValueStoreType[K5] : never,
              K6 extends keyof KeyValueStoreType ? KeyValueStoreType[K6] : never,
              K7 extends keyof KeyValueStoreType ? KeyValueStoreType[K7] : never,
              K8 extends keyof KeyValueStoreType ? KeyValueStoreType[K8] : never,
              K9 extends keyof KeyValueStoreType ? KeyValueStoreType[K9] : never
            ]
          }
      : W extends [infer K0, infer K1, infer K2, infer K3, infer K4, infer K5, infer K6, infer K7, infer K8]
      ? [
          KeyValueStore<KeyValueStoreType>,
          K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
          K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
          K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
          K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never,
          K4 extends keyof KeyValueStoreType ? KeyValueStoreType[K4] : never,
          K5 extends keyof KeyValueStoreType ? KeyValueStoreType[K5] : never,
          K6 extends keyof KeyValueStoreType ? KeyValueStoreType[K6] : never,
          K7 extends keyof KeyValueStoreType ? KeyValueStoreType[K7] : never,
          K8 extends keyof KeyValueStoreType ? KeyValueStoreType[K8] : never
        ] &
          Record<KeyValueStoreName, KeyValueStore<KeyValueStoreType>> & {
            currentValues: [
              K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
              K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
              K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
              K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never,
              K4 extends keyof KeyValueStoreType ? KeyValueStoreType[K4] : never,
              K5 extends keyof KeyValueStoreType ? KeyValueStoreType[K5] : never,
              K6 extends keyof KeyValueStoreType ? KeyValueStoreType[K6] : never,
              K7 extends keyof KeyValueStoreType ? KeyValueStoreType[K7] : never,
              K8 extends keyof KeyValueStoreType ? KeyValueStoreType[K8] : never
            ]
          }
      : W extends [infer K0, infer K1, infer K2, infer K3, infer K4, infer K5, infer K6, infer K7]
      ? [
          KeyValueStore<KeyValueStoreType>,
          K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
          K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
          K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
          K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never,
          K4 extends keyof KeyValueStoreType ? KeyValueStoreType[K4] : never,
          K5 extends keyof KeyValueStoreType ? KeyValueStoreType[K5] : never,
          K6 extends keyof KeyValueStoreType ? KeyValueStoreType[K6] : never,
          K7 extends keyof KeyValueStoreType ? KeyValueStoreType[K7] : never
        ] &
          Record<KeyValueStoreName, KeyValueStore<KeyValueStoreType>> & {
            currentValues: [
              K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
              K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
              K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
              K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never,
              K4 extends keyof KeyValueStoreType ? KeyValueStoreType[K4] : never,
              K5 extends keyof KeyValueStoreType ? KeyValueStoreType[K5] : never,
              K6 extends keyof KeyValueStoreType ? KeyValueStoreType[K6] : never,
              K7 extends keyof KeyValueStoreType ? KeyValueStoreType[K7] : never
            ]
          }
      : W extends [infer K0, infer K1, infer K2, infer K3, infer K4, infer K5, infer K6]
      ? [
          KeyValueStore<KeyValueStoreType>,
          K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
          K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
          K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
          K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never,
          K4 extends keyof KeyValueStoreType ? KeyValueStoreType[K4] : never,
          K5 extends keyof KeyValueStoreType ? KeyValueStoreType[K5] : never,
          K6 extends keyof KeyValueStoreType ? KeyValueStoreType[K6] : never
        ] &
          Record<KeyValueStoreName, KeyValueStore<KeyValueStoreType>> & {
            currentValues: [
              K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
              K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
              K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
              K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never,
              K4 extends keyof KeyValueStoreType ? KeyValueStoreType[K4] : never,
              K5 extends keyof KeyValueStoreType ? KeyValueStoreType[K5] : never,
              K6 extends keyof KeyValueStoreType ? KeyValueStoreType[K6] : never
            ]
          }
      : W extends [infer K0, infer K1, infer K2, infer K3, infer K4, infer K5]
      ? [
          KeyValueStore<KeyValueStoreType>,
          K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
          K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
          K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
          K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never,
          K4 extends keyof KeyValueStoreType ? KeyValueStoreType[K4] : never,
          K5 extends keyof KeyValueStoreType ? KeyValueStoreType[K5] : never
        ] &
          Record<KeyValueStoreName, KeyValueStore<KeyValueStoreType>> & {
            currentValues: [
              K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
              K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
              K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
              K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never,
              K4 extends keyof KeyValueStoreType ? KeyValueStoreType[K4] : never,
              K5 extends keyof KeyValueStoreType ? KeyValueStoreType[K5] : never
            ]
          }
      : W extends [infer K0, infer K1, infer K2, infer K3, infer K4]
      ? [
          KeyValueStore<KeyValueStoreType>,
          K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
          K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
          K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
          K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never,
          K4 extends keyof KeyValueStoreType ? KeyValueStoreType[K4] : never
        ] &
          Record<KeyValueStoreName, KeyValueStore<KeyValueStoreType>> & {
            currentValues: [
              K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
              K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
              K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
              K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never,
              K4 extends keyof KeyValueStoreType ? KeyValueStoreType[K4] : never
            ]
          }
      : W extends [infer K0, infer K1, infer K2, infer K3]
      ? [
          KeyValueStore<KeyValueStoreType>,
          K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
          K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
          K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
          K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never
        ] &
          Record<KeyValueStoreName, KeyValueStore<KeyValueStoreType>> & {
            currentValues: [
              K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
              K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
              K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never,
              K3 extends keyof KeyValueStoreType ? KeyValueStoreType[K3] : never
            ]
          }
      : W extends [infer K0, infer K1, infer K2]
      ? [
          KeyValueStore<KeyValueStoreType>,
          K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
          K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
          K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never
        ] &
          Record<KeyValueStoreName, KeyValueStore<KeyValueStoreType>> & {
            currentValues: [
              K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
              K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never,
              K2 extends keyof KeyValueStoreType ? KeyValueStoreType[K2] : never
            ]
          }
      : W extends [infer K0, infer K1]
      ? [
          KeyValueStore<KeyValueStoreType>,
          K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
          K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never
        ] &
          Record<KeyValueStoreName, KeyValueStore<KeyValueStoreType>> & {
            currentValues: [
              K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never,
              K1 extends keyof KeyValueStoreType ? KeyValueStoreType[K1] : never
            ]
          }
      : W extends [infer K0]
      ? [KeyValueStore<KeyValueStoreType>, K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never] &
          Record<KeyValueStoreName, KeyValueStore<KeyValueStoreType>> & {
            currentValues: [K0 extends keyof KeyValueStoreType ? KeyValueStoreType[K0] : never]
          }
      : W extends []
      ? [KeyValueStore<KeyValueStoreType>] &
          Record<KeyValueStoreName, KeyValueStore<KeyValueStoreType>> & {
            currentValues: []
          }
      : [KeyValueStore<KeyValueStoreType>, ...KeyValueStoreType[keyof KeyValueStoreType][]] &
          Record<KeyValueStoreName, KeyValueStore<KeyValueStoreType>> & {
            currentValues: KeyValueStoreType[keyof KeyValueStoreType][]
          } {
      const keyValueStores = useContext(KeyValueStoreContext)
      const keyValueStore = keyValueStores[keyValueStoreName]

      const update = useUpdate()

      useEffect(() => {
        watchList.forEach(key => keyValueStore(key as string).subscribe(update))
        return () => watchList.forEach(key => keyValueStore(key as string).unsubscribe(update))
      }, watchList)

      const values = watchList.map((key: any) => keyValueStore[key])
      const output = [keyValueStore, ...values] as any
      output[keyValueStoreName] = keyValueStore
      output.currentValues = values

      return output
    }
  }
}

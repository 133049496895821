export const timings = {
  timestampCorrectionAlpha: 0.3, // Set to 0 to disable updating the correction and to 1 to disable correction history

  showUpdateOverviewInterval: 1 * 60 * 60 * 1000,
  clearOnMemoryModelsAttemptInterval: 3 * 60 * 1000,

  navigationPathHighlightRedrawingInterval: 500,

  searchingDebounce: 200,
  draftingDebounce: 200,

  mutablesDatabase: {
    mutationEvents: {
      debounce: 300,
      maximumDelay: 700,
      maximumCount: 100,
    },
  },

  actionSynchronizationStatusUpdateDebounce: 300,

  uploadRetryDelays: [0, 1 * 1000, 3 * 1000, 5 * 1000],

  funnelApiService: {
    getMessages: {
      clientTimeout: 30 * 1000,
      numberOfAllowedUnacknowledged: 10, // TODO: Is it fine to keep it here in timings?
      maximumBatchSize: 1000, // TODO: Is it fine to keep it here in timings?
      startingTimeout: 20 * 1000,
      closeRightAfterMessageDelay: 2 * 1000,
      retryStarting: 7 * 1000,
    },
    getPublicPageMessages: {
      clientTimeout: 30 * 1000,
      numberOfAllowedUnacknowledged: 100, // TODO: Is it fine to keep it here in timings?
      startingTimeout: 20 * 1000,
      closeRightAfterMessageDelay: 2 * 1000,
      retryStarting: 7 * 1000,
    },
  },

  retrySendingAction: { failed: 5.7 * 60 * 1000, succeeded: 13 * 60 * 1000 },
  retryMaintainingExtensionsDatabase: { failed: 16 * 1000, succeeded: 1 * 60 * 60 * 1000 },
  activityUpdaterInterval: { failed: 5 * 1000, succeeded: 10 * 1000 },
  updateAccountLastActive: { failed: 30 * 1000, succeeded: 90 * 1000 },

  fetchPreviewRetry: {
    base: 7 * 1000,
    increaseRate: 1.2,
    max: 4.5 * 60 * 1000,
  },

  developerPageFetchDataInterval: 50 * 1000,
} as const

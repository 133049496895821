import ReactGA, { EventArgs } from 'react-ga'
import { typed } from '../../../helpers/typed'
import { webConfig } from '../../web-config'

interface SendGoogleAnalyticsEvent {
  (event: EventArgs): void
  (category: string, action: string, label?: string, value?: number): void
}

export const sendGoogleAnalyticsEvent: SendGoogleAnalyticsEvent = (...args: any[]) => {
  if (webConfig.analytics.disabled || webConfig.analytics.googleAnalytics.disabled) return

  const event: EventArgs =
    typeof args[0] === 'string'
      ? typed<EventArgs>({ category: args[0], action: args[1], label: args[2], value: args[3] })
      : args[0]

  try {
    ReactGA.event(event)
  } catch (error) {
    log.error(error)
  }
}

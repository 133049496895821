import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { CustomIcon } from '../../../../../../modules/custom-icon'
import { TypographyNoWrap } from '../../../../../TypographyNoWrap'
import { HtmlContentTools } from '../../../../modules/HtmlContentTools'

const useStyles = makeStyles(
  theme => ({
    content: {
      position: 'relative',
      padding: 0,
    },
  }),
  { name: 'ExtendedDialog' }
)

export function ExtendedDialog({
  extendedDialog,
}: {
  extendedDialog: HtmlContentTools.WithReferenceKey<ZettelExtensions.LifeSpan.Shared.Activated.Dialog<any>>
}) {
  const classes = useStyles()

  return (
    <HtmlContentTools.Render htmlContentWithReferenceKey={extendedDialog}>
      {({ containerRefCallback }) => (
        <Dialog
          open
          onClose={(event, reason) => {
            extendedDialog.onCloseRequest?.(
              reason === 'escapeKeyDown'
                ? 'escape key press'
                : reason === 'backdropClick'
                ? 'backdrop click'
                : ('' as never)
            )
          }}
          fullScreen={extendedDialog.fullScreen}
          fullWidth={extendedDialog.fullWidth}
          maxWidth={extendedDialog.maxWidth}
          scroll={extendedDialog.scrollContainer}
        >
          {(extendedDialog.title || !extendedDialog.hideCloseIconButton) && (
            <DialogTitle>
              <TypographyNoWrap variant="subtitle1">{extendedDialog.title ?? '\u00A0'}</TypographyNoWrap>
              {!extendedDialog.hideCloseIconButton && (
                <IconButton
                  size="small"
                  onClick={() => void extendedDialog.onCloseRequest?.('close icon button click')}
                >
                  <CustomIcon name="Close" size={2.5} />
                </IconButton>
              )}
            </DialogTitle>
          )}

          <DialogContent ref={containerRefCallback} dividers className={classes.content} />

          {extendedDialog.actions && extendedDialog.actions.length > 0 && (
            <DialogActions>
              {extendedDialog.actions.map((action, index) => (
                <Button
                  key={index}
                  variant={action.variant}
                  color="primary"
                  disabled={action.disabled}
                  onClick={action.onClick}
                >
                  {action.title}
                </Button>
              ))}
            </DialogActions>
          )}
        </Dialog>
      )}
    </HtmlContentTools.Render>
  )
}

import { Context, DependencyList, PropsWithChildren, useMemo } from 'react'

export function MemoizedContextProvider<T>({
  context: Context,
  value,
  valueFactory,
  dependencies,
  children,
}: PropsWithChildren<{
  context: Context<T>
  value?: T
  valueFactory?(): T
  dependencies: DependencyList
}>) {
  const memoizedValue = useMemo((value ? () => value : valueFactory) ?? (() => undefined as any), dependencies)

  return <Context.Provider value={memoizedValue}>{children}</Context.Provider>
}

import { makeStyles, Typography } from '@material-ui/core'
import { ReadonlyRecord } from '@zettelooo/commons'
import classNames from 'classnames'
import { useContext } from 'react'
import { useCommonStyles } from '../../../../../hooks/useCommonStyles'
import { webConfig } from '../../../../../modules/web-config'
import { TitleBarAppNameItem } from '../../../components/TitleBarAppNameItem'
import { Context } from '../Context'
import { LoadingReason } from '../types'
import { DecorativeSquares } from './DecorativeSquares'

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.background.default,
      zIndex: theme.zIndex.tooltip + 1,
    },
    message: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }),
  { name: 'LoadingLogo' }
)

const reasonConfigs: ReadonlyRecord<LoadingReason, [initializing: boolean, message: string | null]> = {
  [LoadingReason.FetchingDemoContent]: [false, 'Preparing demo...'],
  [LoadingReason.SettingUpServiceWorker]: [true, 'Loading...'],
  [LoadingReason.WorkspaceInitialization]: [true, `Launching ${webConfig.app.name}...`],
  [LoadingReason.SigningOut]: [false, 'Signing out...'],
}

export function LoadingLogo() {
  const { reasons } = useContext(Context)

  const reasonConfig = reasons.length > 0 ? reasonConfigs[reasons[reasons.length - 1]] : undefined
  const initializing = reasonConfig?.[0]
  const message = reasonConfig?.[1]

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  if (reasons.length === 0) return null

  return (
    <DecorativeSquares className={classNames(commonClasses.electronDrag, classes.root)}>
      <TitleBarAppNameItem />

      <Typography variant="h6" color="textPrimary" className={classes.message}>
        {message}
      </Typography>
    </DecorativeSquares>
  )
}

import { Grid, makeStyles } from '@material-ui/core'
import { forwardRef, useContext } from 'react'
import { CustomAvatar } from '../../../../../../../../CustomAvatar'
import { TypographyNoWrap } from '../../../../../../../../TypographyNoWrap'
import { NewPage } from '../../../../../modules/NewPage'
import { Panel } from '../../panel'
import { PanelLayout } from './PanelLayout'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(3),
      overflow: 'auto',
    },
    header: {
      height: theme.spacing(8),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
    },
    titleWrapper: {
      width: 0,
      textAlign: 'start',
      overflow: 'hidden',
    },
    title: {
      marginLeft: theme.spacing(0.5),
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'NewPagePanel' }
)

export const NewPagePanel = forwardRef<
  HTMLDivElement,
  {
    panel: Panel<Panel.Type.NewPage>
  }
>(function NewPagePanel({ panel }, ref) {
  const { newPage } = useContext(NewPage.Context)

  const classes = useStyles()

  return (
    <PanelLayout
      ref={ref}
      className={classes.root}
      header={
        <Grid container wrap="nowrap" alignItems="center" spacing={1} className={classes.header}>
          <Grid item>
            <CustomAvatar
              size={3.5}
              compact
              avatarFileId={newPage.avatarFileId}
              name={newPage.name}
              emoji={newPage.iconEmoji}
            />
          </Grid>

          <Grid item xs className={classes.titleWrapper}>
            <TypographyNoWrap variant="h5" className={classes.title}>
              {newPage.name}
            </TypographyNoWrap>
          </Grid>
        </Grid>
      }
    />
  )
})

import { useContexts } from '../../../../../modules/contexts'
import { ExtensionProviderContexts } from '../components/ExtensionProvider'
import { InstalledExtensionsReader } from '../types'

export function useInstalledExtensionsReader(): {
  installedExtensionsReader: InstalledExtensionsReader
} {
  const { installedExtensionsReader } = useContexts(ExtensionProviderContexts)

  return {
    installedExtensionsReader,
  }
}

import i18next, { TFunction, InitOptions, Resource } from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { typed } from '../../../../helpers/typed'
import { webConfig } from '../../../../modules/web-config'
import { Persistent, PersistentKey } from '../persistent'
import { languageCodes, languageResources } from './constants'
import { LanguageCode } from './types'

export function initializeLocalization(persistent: Persistent): void {
  const baseOptions = createBaseInitOptions()

  switch (webConfig.environment.node) {
    case 'development':
      initializeForDevelopment(baseOptions)
      break

    case 'test':
      initializeForTest(baseOptions)
      break

    case 'production':
      initializeForProduction(baseOptions)
      break
  }

  function createBaseInitOptions(): InitOptions {
    return {
      resources: languageResources as Record<string, unknown> as Record<string, Resource>,
      fallbackLng: languageCodes[0],
      supportedLngs: languageCodes as string[],
      preload: languageCodes as string[],
      ns: 'translation',
      defaultNS: 'translation',
      partialBundledLanguages: true,
      interpolation: {
        escapeValue: false,
      },
      initImmediate: false,
    }
  }

  function initializeForDevelopment(options: InitOptions): Promise<TFunction> {
    return initializeForProduction({
      ...options,
      debug: webConfig.developerLogs.i18next,
    })
  }

  function initializeForTest(options: InitOptions): Promise<TFunction> {
    return i18next.use(initReactI18next).init({
      ...options,
      lng: 'en',
    })
  }

  function initializeForProduction(options: InitOptions): Promise<TFunction> {
    return i18next
      .use(createCustomLanguageDetector())
      .use(initReactI18next)
      .init({
        ...options,
        detection: {
          order: ['custom-language-detector'],
          caches: ['custom-language-detector'],
        },
      })

    function createCustomLanguageDetector(): I18nextBrowserLanguageDetector {
      const customLanguageDetector = new I18nextBrowserLanguageDetector()
      customLanguageDetector.addDetector({
        name: 'custom-language-detector',
        lookup() {
          return languageCodes.includes(persistent[PersistentKey.LanguageCode])
            ? persistent[PersistentKey.LanguageCode]
            : typed<LanguageCode>('en')
        },
        cacheUserLanguage(language: LanguageCode) {
          persistent[PersistentKey.LanguageCode] = language
        },
      })
      return customLanguageDetector
    }
  }
}

import { Id } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Extension, ExtensionServiceSignature, Model } from '@zettelooo/server-shared'
import { ExtensionBody } from '../../extension'
import { Persistent, PersistentKey } from '../../persistent'
import { RestService } from './RestService'

export class ExtensionService extends RestService {
  constructor(persistent: Persistent) {
    super('extension', persistent)
  }

  getFileUrl(header: Pick<Extension.Header, 'id' | 'version'>, extensionFileRelativePath: string): string {
    const filePathWithoutInitialSlash = extensionFileRelativePath.replace(/^\//, '')
    return this.getServiceEndPointUrl(`folders/${header.id}@${header.version}/${filePathWithoutInitialSlash}`)
  }

  async getBody(header: Pick<Extension.Header, 'id' | 'version'>): Promise<ExtensionBody> {
    const response = await fetch(this.getFileUrl(header, `index.js`))
    const js = await response.text()
    return {
      id: header.id,
      js,
    }
  }

  async getHeadersByIds(ids: readonly Id[]): Promise<{ headers: Extension.Header[] }> {
    const { headers } = await this.requestUnauthenticated<
      ExtensionServiceSignature.GetHeadersByIds.Request,
      ExtensionServiceSignature.GetHeadersByIds.Response
    >('get-headers-by-ids', {
      accountId: this.persistent(PersistentKey.Authentication).get()?.decodedAccessToken.userId,
      ids,
    })

    return {
      headers,
    }
  }

  async searchHeaders(query: string, scope: ZettelExtensions.Scope): Promise<{ headers: Extension.Header[] }> {
    const { headers } = await this.requestUnauthenticated<
      ExtensionServiceSignature.SearchHeaders.Request,
      ExtensionServiceSignature.SearchHeaders.Response
    >('search-headers', {
      accountId: this.persistent(PersistentKey.Authentication).get()?.decodedAccessToken.userId,
      query,
      scope,
    })

    return {
      headers,
    }
  }

  async modifyConfiguration(
    intention: string,
    extensionConfiguration: Model.ExtensionConfiguration
  ): Promise<{ extensionConfiguration: Model.ExtensionConfiguration }> {
    const { configuration } = await this.requestAuthenticated<
      ExtensionServiceSignature.ModifyConfiguration.Request,
      ExtensionServiceSignature.ModifyConfiguration.Response
    >('modify-configuration', {
      intention,
      configuration: extensionConfiguration,
    })

    return {
      extensionConfiguration: configuration,
    }
  }

  async refineConfiguration(
    extensionConfiguration: Model.ExtensionConfiguration
  ): Promise<{ extensionConfiguration: Model.ExtensionConfiguration }> {
    const { configuration } = await this.requestAuthenticated<
      ExtensionServiceSignature.RefineConfiguration.Request,
      ExtensionServiceSignature.RefineConfiguration.Response
    >('refine-configuration', {
      configuration: extensionConfiguration,
    })

    return {
      extensionConfiguration: configuration,
    }
  }
}

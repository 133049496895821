import { useMemo } from 'react'
import { matchPath } from 'react-router-dom'
import { useLocation } from 'react-use'
import { webConfig } from '../../../../../../../../modules/web-config'
import { getPageIdFromPublicId } from '../../../../helpers/getPageIdFromPublicId'
import { useQuery, routes } from '../../../../modules/routes'
import { Panel } from './Panel'

export function usePanel(): Panel | undefined {
  const location = useLocation()

  const pathName = useMemo(
    () =>
      webConfig.environment.agent === 'browser'
        ? location.pathname ?? ''
        : webConfig.environment.agent === 'electron'
        ? (location.hash ?? '').slice(1) // To remove the hash sign ('#') from its begining
        : '',
    [location]
  )

  const query = useQuery() as Panel.Query

  const panel = useMemo<Panel | undefined>(() => {
    const matchedPath = matchPath<{ panelType: Panel.Type | undefined }>(pathName, {
      path: `${routes.main.pattern}/:panelType?`,
      exact: false,
      strict: false,
    })

    if (!matchedPath) return undefined

    const {
      url,
      params: { panelType },
    } = matchedPath
    const unmatchedPath = pathName.slice(url.length + 1)
    const params = unmatchedPath.split('/') as Panel.Params

    switch (panelType) {
      case Panel.Type.Settings: {
        const [subPanelType] = params as Panel.Params<Panel.Type.Settings>
        const subType = Object.values(Panel.Settings.Type).includes(subPanelType)
          ? (subPanelType as Panel.Settings.Type)
          : Panel.Settings.Type.Account
        return {
          type: Panel.Type.Settings,
          subType,
        }
      }

      case Panel.Type.Page: {
        const [pagePublicId] = params as Panel.Params<Panel.Type.Page>
        if (!pagePublicId) return undefined
        const pageId = getPageIdFromPublicId(pagePublicId)
        if (!pageId) return undefined
        return {
          type: Panel.Type.Page,
          pageId,
          cardId: (query as Panel.Query<Panel.Type.Page>).c,
          isNewPage: (query as Panel.Query<Panel.Type.Page>).n === 'true',
        }
      }

      case Panel.Type.NewPage:
        return {
          type: Panel.Type.NewPage,
        }

      case Panel.Type.Activity:
        if (webConfig.temporary.temporarilyDisabledOrToBeRemoved) return undefined
        return {
          type: Panel.Type.Activity,
        }

      default:
        return undefined
    }
  }, [pathName, query])

  return panel
}

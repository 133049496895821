import { useEffect, useState } from 'react'
import { useMountedState } from 'react-use'
import { ExtensionModel } from '../../extension'
import { useDatabases } from '../useDatabases'
import { ExtensionsDatabase } from './ExtensionsDatabase'

export function useExtensionsDatabaseReader(): {
  loadingInstalledExtensions: boolean
  updatingInstalledExtensions: boolean
  installedExtensions: readonly ExtensionModel[]
} {
  const {
    databases: { extensionsDatabase },
  } = useDatabases()

  const [loadingInstalledExtensions, setLoadingInstalledExtensions] = useState(true)
  const [updatingInstalledExtensionsCount, setUpdatingInstalledExtensionsCount] = useState(0)
  const [installedExtensions, setInstalledExtensions] = useState<readonly ExtensionModel[]>([])

  const isMounted = useMountedState()

  useEffect(() => {
    setLoadingInstalledExtensions(true)
    extensionsDatabase
      .getAll()
      .then(newInstalledExtensions => isMounted() && setInstalledExtensions(newInstalledExtensions))
      .finally(() => isMounted() && setLoadingInstalledExtensions(false))
  }, [extensionsDatabase])

  useEffect(() => {
    if (!loadingInstalledExtensions) {
      extensionsDatabase.subscribe(subscription)

      return () => extensionsDatabase.unsubscribe(subscription)
    }

    async function subscription(event: ExtensionsDatabase.Event): Promise<void> {
      setUpdatingInstalledExtensionsCount(currentUpdatingInstalledExtensions => currentUpdatingInstalledExtensions + 1)

      try {
        switch (event.type) {
          case 'created': {
            const newExtension = await extensionsDatabase.get(event.id)
            setInstalledExtensions(currentInstalledExtensions =>
              currentInstalledExtensions.concat(newExtension!).filter(Boolean)
            )
            break
          }

          case 'updated': {
            const newExtension = await extensionsDatabase.get(event.id)
            setInstalledExtensions(currentInstalledExtensions =>
              currentInstalledExtensions
                .filter(extension => extension.id !== event.id)
                .concat(newExtension!)
                .filter(Boolean)
            )
            break
          }

          case 'deleted':
            setInstalledExtensions(currentInstalledExtensions =>
              currentInstalledExtensions.filter(extension => extension.id !== event.id)
            )
            break
        }
      } finally {
        setUpdatingInstalledExtensionsCount(
          currentUpdatingInstalledExtensions => currentUpdatingInstalledExtensions - 1
        )
      }
    }
  }, [extensionsDatabase, loadingInstalledExtensions])

  return {
    loadingInstalledExtensions,
    updatingInstalledExtensions: updatingInstalledExtensionsCount > 0,
    installedExtensions,
  }
}

import { setUserId } from '@amplitude/analytics-browser'
import { webConfig } from '../../web-config'

export function setAmplitudeUserIdentifier(userIdentifier: string): void {
  if (webConfig.analytics.disabled || webConfig.analytics.amplitude.disabled) return

  try {
    setUserId(userIdentifier)
  } catch (error) {
    log.error(error)
  }
}

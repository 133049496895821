import { PartialReadonlyRecord, PartialRecord } from '@zettelooo/commons'
import mimeDb from 'mime-db'
import { getFileExtension } from './getFileExtension'

const mimeTypeByExtension: PartialReadonlyRecord<string, string> = Object.keys(mimeDb).reduce(
  (developingMimeTypeByExtension, mimeType) => {
    mimeDb[mimeType].extensions?.forEach(extension => {
      developingMimeTypeByExtension[extension] = mimeType
    })
    return developingMimeTypeByExtension
  },
  {} as PartialRecord<string, string>
)

export function getFileMimeType(fileName: string): string {
  const fileExtension = getFileExtension(fileName)
  return (fileExtension && mimeTypeByExtension[fileExtension]) || 'application/octet-stream' // A general mime-type for all kinds of files
}

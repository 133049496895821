import { ZettelExtensions } from '@zettelooo/extension-api'
import { ReactNode } from 'react'
import { webConfig } from '../../../../../../../../../../../modules/web-config'
import { CommandLine } from './CommandLine'
import { ExtensionStore } from './ExtensionStore'
import { Members } from './Members'
import { QuickActions } from './QuickActions'
import { Mode } from './type'

export function render({
  mode,
  // extendedCommandLinePromptInput,
  extendedQuickActions,
  isMobile,
}: {
  mode: Mode | undefined
  // extendedCommandLinePromptInput: ZettelExtensions.LifeSpan.Shared.PagePanel.CommandLinePromptInput | undefined
  extendedQuickActions: readonly ZettelExtensions.LifeSpan.Shared.PagePanel.QuickAction[]
  isMobile: boolean
}): ReactNode {
  switch (mode) {
    case undefined:
      if (isMobile) return null
      return webConfig.app.cashFlowPurpose ? (
        <QuickActions asDefaultSideBar extendedQuickActions={extendedQuickActions} />
      ) : (
        <CommandLine
          asDefaultSideBar
          //  extendedCommandLinePromptInput={extendedCommandLinePromptInput}
        />
      )

    case Mode.CommandLine:
      return (
        <CommandLine
        // extendedCommandLinePromptInput={extendedCommandLinePromptInput}
        />
      )

    case Mode.QuickActions:
      return <QuickActions extendedQuickActions={extendedQuickActions} />

    case Mode.Members:
      return <Members />

    case Mode.ExtensionStore:
      return <ExtensionStore />
  }
}

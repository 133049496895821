import { Button, makeStyles, Typography } from '@material-ui/core'
import { IpcChannel, LauncherIpcMessageType } from '@zettelooo/desktop-shared'
import { getPublicResourceUrl } from '../../../../../../../helpers/getPublicResourceUrl'
import { webConfig } from '../../../../../../../modules/web-config'
import { TypographyNoWrap } from '../../../../../../TypographyNoWrap'
import { sendIpcMessage } from '../../../../../modules/electron'
import { ElectronPermissionStatus } from './types'

const useStyles = makeStyles(
  theme => ({
    noPermissionWrapper: {
      marginTop: theme.spacing(2),
    },
    noPermissionGuide: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    noPermissionGuideText: {
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(2),
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
    },
    noPermissionTitle: {
      marginBottom: theme.spacing(2),
      flexGrow: 0,
    },
    noPermissionText: {
      marginBottom: theme.spacing(1),
      flexGrow: 1,
    },
    noPermissionImageWrapper: {
      padding: theme.spacing(2),
      background:
        'conic-gradient(from 186.32deg at 50.97% 91.25%, #8B3474 -9.66deg, #9B3071 30.82deg, #E8365B 47.38deg, #C22464 84.7deg, #F02157 110.26deg, #F55152 137.9deg, #E0BE9C 150.6deg, #F9B448 153.45deg, #F89F43 171.14deg, #CED2DB 195.22deg, #53AFE2 216deg, #2E92CB 239.51deg, #EE2457 251.15deg, #753979 292.58deg, #8B3474 350.34deg, #9B3071 390.82deg)',
      borderRadius: theme.spacing(1),
    },
    noPermissionImage: {
      maxWidth: '400px',
      borderRadius: theme.spacing(2),
    },
    noPermissionButton: {
      width: '150px',
      margin: '0 auto',
    },
  }),
  { name: 'LauncherNoPermissions' }
)

export function LauncherNoPermissions({ permissionStatus }: { permissionStatus: ElectronPermissionStatus }) {
  const classes = useStyles()

  return (
    <div className={classes.noPermissionWrapper}>
      <div className={classes.noPermissionGuide}>
        <div className={classes.noPermissionGuideText}>
          <TypographyNoWrap variant="subtitle1" className={classes.noPermissionTitle}>
            {permissionStatus === ElectronPermissionStatus.NoAccessibilityPermission
              ? 'Accessibility '
              : permissionStatus === ElectronPermissionStatus.NoAutomationPermission
              ? 'Automation '
              : ''}
            permission
          </TypographyNoWrap>

          <Typography variant="body2" className={classes.noPermissionText}>
            To grant permission and activate quick capture of {webConfig.app.name}, press &quot;Go to settings&quot;
            button to open System Preferences and then click the checkbox
            {permissionStatus === ElectronPermissionStatus.NoAccessibilityPermission
              ? ' next to '
              : permissionStatus === ElectronPermissionStatus.NoAutomationPermission
              ? 'es under '
              : ''}
            {webConfig.app.name}.
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            className={classes.noPermissionButton}
            onClick={() =>
              sendIpcMessage(IpcChannel.Launcher, {
                type:
                  permissionStatus === ElectronPermissionStatus.NoAccessibilityPermission
                    ? LauncherIpcMessageType.LauncherGoToAccessibilitySettings
                    : permissionStatus === ElectronPermissionStatus.NoAutomationPermission
                    ? LauncherIpcMessageType.LauncherGoToAutomationSettings
                    : (undefined as any),
              })
            }
          >
            Go to settings
          </Button>
        </div>

        <div className={classes.noPermissionImageWrapper}>
          {permissionStatus === ElectronPermissionStatus.NoAccessibilityPermission ? (
            <img
              src={getPublicResourceUrl('accessibility-guide.gif')}
              alt="Accessibility permission guide"
              className={classes.noPermissionImage}
            />
          ) : permissionStatus === ElectronPermissionStatus.NoAutomationPermission ? (
            <img
              src={getPublicResourceUrl('automation-guide.gif')}
              alt="Automation permission guide"
              className={classes.noPermissionImage}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { arrayHelpers } from '../../../../../../../helpers/native/arrayHelpers'
import { useMutablesDatabaseReader } from '../../useMutablesDatabaseReader'

// TODO: Maybe, we can improve this function's performance by passing them the list of all the cards as an optional argument, just to replace the initial query:
export function useCardsForPage(
  pageId: Id,
  options?: {
    disabled?: boolean
  }
): {
  cardsAreLoading: boolean
  cards: readonly Model.Card[]
} {
  return useMutablesDatabaseReader(
    async db => ({
      pageId,
      dictionary: options?.disabled ? {} : arrayHelpers.toDictionaryById(await db.getCardsForPage(pageId)),
    }),
    {
      type: Model.Type.Card,
      onMutations(models, state, reload) {
        if (options?.disabled) return state
        let newState = state
        models.forEach(model => {
          if (model.pageId !== pageId) return
          if (newState === state) {
            newState = { ...state, dictionary: { ...state.dictionary } }
          }
          if (model.isDeleted) {
            delete newState.dictionary[model.id]
          } else {
            newState.dictionary[model.id] = model
          }
        })
        return newState
      },
    },
    [pageId, options?.disabled],
    (loading, state) => ({
      cardsAreLoading: loading,
      cards:
        !options?.disabled && state && state.pageId === pageId
          ? arrayHelpers.sortBy(Object.values(state.dictionary), 'sequence')
          : [],
    })
  )
}

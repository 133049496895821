import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { useContext, useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { useContexts } from '../../../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../../../modules/custom-icon'
import { DeviceProvider } from '../../../../../../../../../../../modules/device'
import { webConfig } from '../../../../../../../../../../../modules/web-config'
import { Gap } from '../../../../../../../../../../Gap'
import { SearchBox } from '../../../../../../../../../../SearchBox'
import { TypographyNoWrap } from '../../../../../../../../../../TypographyNoWrap'
import { PagePanel } from '../PagePanel'
import { Layout } from './Layout'
import { Mode } from './type'

const useStyles = makeStyles(
  theme => ({
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    searchBox: {
      marginBottom: theme.spacing(2),
    },
    quickActionItem: {
      padding: theme.spacing(0.25, 2, 0.25, 2),
    },
    quickActionListItemSecondaryAction: {
      paddingRight: theme.spacing(6),
    },
    quickActionListItemAvatar: {
      minWidth: theme.spacing(7),
    },
    quickActionListItemTextSecondary: {
      overflow: 'hidden',
      whiteSpace: 'break-spaces',
      maxHeight: theme.spacing(4.25),
    },
    quickActionListItemSecondaryActionRoot: {
      right: 4,
    },
  }),
  { name: 'QuickActions' }
)

const searchBoxPlaceholders = ['Every feature is an App', `Search ${webConfig.app.name} Apps`]

export function QuickActions({
  asDefaultSideBar,
  extendedQuickActions,
}: {
  asDefaultSideBar?: boolean
  extendedQuickActions: readonly ZettelExtensions.LifeSpan.Shared.PagePanel.QuickAction[]
}) {
  const { isMobile } = useContext(DeviceProvider.Context)
  const {
    refs: { setSideBarMode },
  } = useContexts(PagePanel.Contexts)

  const [searchBoxPlaceholderIndex, setSearchBoxPlaceholderIndex] = useState(0)
  const [query, setQuery] = useState('')

  useEffectOnce(() => {
    const interval = setInterval(() => {
      setSearchBoxPlaceholderIndex(current => (current + 1) % searchBoxPlaceholders.length)
    }, 5 * 1000)
    return () => void clearInterval(interval)
  })

  const filteredExtendedQuickActions = useMemo(() => {
    const refinedQuery = query.toLowerCase().trim()
    return extendedQuickActions.filter(
      quickAction =>
        (!webConfig.app.cashFlowPurpose || quickAction.title !== 'Text') &&
        (!refinedQuery ||
          quickAction.title.toLowerCase().includes(refinedQuery) ||
          quickAction.description?.toLowerCase().includes(refinedQuery))
    )
  }, [extendedQuickActions, query])

  const classes = useStyles()

  return (
    <Layout
      title={
        <div className={classes.header}>
          {asDefaultSideBar ? <Gap grow /> : <TypographyNoWrap>Quick Actions</TypographyNoWrap>}
          <Tooltip title="Module Store">
            <span>
              <IconButton onClick={() => setSideBarMode(Mode.ExtensionStore)}>
                <CustomIcon name="Extension" size={2.5} />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      }
    >
      <SearchBox
        className={classes.searchBox}
        placeholder={searchBoxPlaceholders[searchBoxPlaceholderIndex]}
        query={query}
        onSearch={setQuery}
      />

      <List disablePadding>
        {filteredExtendedQuickActions.map((quickAction, index) => (
          <Tooltip
            key={index}
            placement="left"
            title={
              isMobile ? (
                ''
              ) : (
                <div>
                  <Typography variant="body1" gutterBottom={Boolean(quickAction.description)}>
                    {quickAction.title}
                  </Typography>
                  {quickAction.description && <Typography variant="body2">{quickAction.description}</Typography>}
                </div>
              )
            }
          >
            {quickAction.onClick ? (
              <MenuItem
                className={classes.quickActionItem}
                {...{
                  ListItemClasses: { secondaryAction: classes.quickActionListItemSecondaryAction },
                }} // The property is supported by the MenuItem component, but the TypeScript definision lacks it
                disabled={quickAction.disabled}
                onClick={() => quickAction.onClick?.()}
              >
                <ListItemAvatar className={classes.quickActionListItemAvatar}>
                  <Avatar variant="square" src={quickAction.avatarUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={quickAction.title}
                  primaryTypographyProps={{ noWrap: true, gutterBottom: true }}
                  secondary={quickAction.description}
                  secondaryTypographyProps={{ className: classes.quickActionListItemTextSecondary }}
                />
                {quickAction.switchChecked !== undefined && (
                  <ListItemSecondaryAction
                    className={classes.quickActionListItemSecondaryActionRoot}
                    onClick={() => quickAction.onToggleSwitch?.(!quickAction.switchChecked)}
                  >
                    <Switch edge="end" disabled={quickAction.disabled} checked={quickAction.switchChecked} />
                  </ListItemSecondaryAction>
                )}
              </MenuItem>
            ) : (
              <ListItem
                classes={{
                  root: classes.quickActionItem,
                  secondaryAction: classes.quickActionListItemSecondaryAction,
                }}
              >
                <ListItemAvatar className={classes.quickActionListItemAvatar}>
                  <Avatar variant="square" src={quickAction.avatarUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={quickAction.title}
                  primaryTypographyProps={{ noWrap: true, gutterBottom: true }}
                  secondary={quickAction.description}
                  secondaryTypographyProps={{ className: classes.quickActionListItemTextSecondary }}
                />
                {quickAction.switchChecked !== undefined && (
                  <ListItemSecondaryAction
                    className={classes.quickActionListItemSecondaryActionRoot}
                    onClick={() => quickAction.onToggleSwitch?.(!quickAction.switchChecked)}
                  >
                    <Switch edge="end" disabled={quickAction.disabled} checked={quickAction.switchChecked} />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            )}
          </Tooltip>
        ))}
      </List>
    </Layout>
  )
}

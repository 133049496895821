import { memo, useEffect, useRef, useState } from 'react'
import { Divider, DividerProps, makeStyles, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { useCombineRefs } from '../../../../../../../../../hooks/useCombineRefs'
import { useRefWrap } from '../../../../../../../../../hooks/useRefWrap'
import { NavigableWithCommands } from '../../../../../../../../../modules/commands'
import { NavigableStatus } from '../../../../../../../../../modules/navigation'
import { usePersistent, PersistentKey } from '../../../../../../../modules/persistent'

// Also, look at the MainPage styles for complementary styling required for this resizer to work:
const useStyles = makeStyles(
  theme => ({
    root: {
      MozBoxSizing: 'border-box',
      WebkitBoxSizing: 'border-box',
      boxSizing: 'border-box',
      WebkitBackgroundClip: 'padding',
      backgroundClip: 'padding-box',
      borderColor: 'transparent',
    },
    horizontal: {
      height: 'calc(10px + 1pt)',
      width: '100%',
      margin: '-5px 0',
      cursor: 'row-resize',
      borderTopWidth: '5px',
      borderTopStyle: 'solid',
      borderBottomWidth: '5px',
      borderBottomStyle: 'solid',
    },
    vertical: {
      width: 'calc(10px + 1pt)',
      margin: '0 -5px',
      cursor: 'col-resize',
      borderLeftWidth: '5px',
      borderLeftStyle: 'solid',
      borderRightWidth: '5px',
      borderRightStyle: 'solid',
    },
    active: {
      '&$horizontal': {
        height: 'calc(200vh + 1pt)',
        margin: '-100vh 0',
        borderTopWidth: '100vh',
        borderBottomWidth: '100vh',
        zIndex: theme.zIndex.tooltip + 1,
      },
      '&$vertical': {
        width: 'calc(200vw + 1pt)',
        margin: '0 -100vw',
        borderLeftWidth: '100vw',
        borderRightWidth: '100vw',
        zIndex: theme.zIndex.tooltip + 1,
      },
    },
    disabled: {
      cursor: 'not-allowed',
    },
  }),
  { name: 'Resizer' }
)

export const Resizer = memo(function Resizer({
  orientation = 'horizontal',
  onResize,
  disabled,
  OverridedDividerProps,
  className,
}: {
  orientation?: DividerProps['orientation']
  onResize?(difference: number): number
  disabled?: boolean
  OverridedDividerProps?: DividerProps
  className?: string
}) {
  const { persistent } = usePersistent()

  const rootRef = useRef<HTMLHRElement>(null)
  const positionRef = useRef<null | number>(null)
  const onResizeRef = useRefWrap(onResize)

  const [active, setActive] = useState(false)

  useEffect(() => {
    const root = rootRef.current
    if (!root || disabled) return

    root.addEventListener('mousedown', handleMouseDown)
    root.addEventListener('mousemove', handleMouseMove)
    root.addEventListener('mouseup', handleMouseUp)
    root.addEventListener('touchstart', handleTouchStart)
    root.addEventListener('touchmove', handleTouchMove)
    root.addEventListener('touchend', handleTouchEnd)
    root.addEventListener('touchcancel', handleTouchCancel)

    return () => {
      root.removeEventListener('mousedown', handleMouseDown)
      root.removeEventListener('mousemove', handleMouseMove)
      root.removeEventListener('mouseup', handleMouseUp)
      root.removeEventListener('touchstart', handleTouchStart)
      root.removeEventListener('touchmove', handleTouchMove)
      root.removeEventListener('touchend', handleTouchEnd)
      root.removeEventListener('touchcancel', handleTouchCancel)
    }

    function handleMouseDown(this: HTMLHRElement, event: MouseEvent) {
      if (event.buttons !== 1) {
        positionRef.current = null
        setActive(false)
        return
      }
      const currentPosition = orientation === 'horizontal' ? event.screenY : event.screenX
      positionRef.current = currentPosition
      setActive(true)
      event.preventDefault()
    }
    function handleMouseMove(this: HTMLHRElement, event: MouseEvent) {
      if (event.buttons !== 1) {
        positionRef.current = null
        setActive(false)
        return
      }
      if (positionRef.current === null) return
      const currentPosition = orientation === 'horizontal' ? event.screenY : event.screenX
      const difference = currentPosition - positionRef.current
      const actualDifference = onResizeRef.current?.(difference) ?? difference
      const actualPosition = positionRef.current + actualDifference
      positionRef.current = actualPosition
      event.preventDefault()
    }
    function handleMouseUp(this: HTMLHRElement, event: MouseEvent) {
      positionRef.current = null
      setActive(false)
      event.preventDefault()
    }
    function handleTouchStart(this: HTMLHRElement, event: TouchEvent) {
      if (event.touches.length !== 1 && event.targetTouches.length !== 1) {
        positionRef.current = null
        setActive(false)
        return
      }
      const currentPosition =
        orientation === 'horizontal' ? event.targetTouches[0].screenY : event.targetTouches[0].screenX
      positionRef.current = currentPosition
      setActive(true)
      event.preventDefault()
    }
    function handleTouchMove(this: HTMLHRElement, event: TouchEvent) {
      if (event.touches.length !== 1 && event.targetTouches.length !== 1) {
        positionRef.current = null
        setActive(false)
        return
      }
      if (positionRef.current === null) return
      const currentPosition =
        orientation === 'horizontal' ? event.targetTouches[0].screenY : event.targetTouches[0].screenX
      const difference = currentPosition - positionRef.current
      const actualDifference = onResizeRef.current?.(difference) ?? difference
      const actualPosition = positionRef.current + actualDifference
      positionRef.current = actualPosition
      event.preventDefault()
    }
    function handleTouchEnd(this: HTMLHRElement, event: TouchEvent) {
      positionRef.current = null
      setActive(false)
      event.preventDefault()
    }
    function handleTouchCancel(this: HTMLHRElement, event: TouchEvent) {
      positionRef.current = null
      setActive(false)
      event.preventDefault()
    }
  }, [orientation, disabled])

  const combineRefs = useCombineRefs(rootRef)

  const theme = useTheme()
  const classes = useStyles()

  return (
    <NavigableWithCommands
      selectable
      commandGroup={({ navigableRef }) => ({
        name: 'sidebarResizer',
        displayName: 'Resizer',
        disabled() {
          return navigableRef.current?.navigableStatus !== NavigableStatus.Selected
        },
        commands: [
          {
            name: 'shrink',
            displayName: 'Shrink sidebar size',
            disabled() {
              const sidebarSize =
                persistent(PersistentKey.SideBarSize).get() ?? theme.constants.sidebar.expandedMaximumSize
              return sidebarSize <= theme.constants.sidebar.collapsedSize
            },
            defaultShortcutKeys: 'ArrowLeft',
            handler() {
              const sidebarSize =
                persistent(PersistentKey.SideBarSize).get() ?? theme.constants.sidebar.expandedMaximumSize
              persistent(PersistentKey.SideBarSize).set(
                sidebarSize === theme.constants.sidebar.expandedMinimumSize
                  ? theme.constants.sidebar.collapsedSize
                  : sidebarSize > theme.constants.sidebar.expandedMinimumSize
                  ? Math.max(sidebarSize - theme.spacing(3), theme.constants.sidebar.expandedMinimumSize)
                  : sidebarSize
              )
            },
          },
          {
            name: 'grow',
            displayName: 'Grow sidebar size',
            disabled() {
              const sidebarSize =
                persistent(PersistentKey.SideBarSize).get() ?? theme.constants.sidebar.expandedMaximumSize
              return sidebarSize >= theme.constants.sidebar.expandedMaximumSize
            },
            defaultShortcutKeys: 'ArrowRight',
            handler() {
              const sidebarSize =
                persistent(PersistentKey.SideBarSize).get() ?? theme.constants.sidebar.expandedMaximumSize
              persistent(PersistentKey.SideBarSize).set(
                sidebarSize === theme.constants.sidebar.collapsedSize
                  ? theme.constants.sidebar.expandedMinimumSize
                  : sidebarSize < theme.constants.sidebar.expandedMaximumSize
                  ? Math.min(sidebarSize + theme.spacing(3), theme.constants.sidebar.expandedMaximumSize)
                  : sidebarSize
              )
            },
          },
        ],
      })}
      commandGroupDependencies={[persistent, theme]}
    >
      {({ connectNavigable }) => (
        <Divider
          {...OverridedDividerProps}
          innerRef={combineRefs(connectNavigable)}
          variant="fullWidth"
          orientation={orientation}
          className={classNames(
            classes.root,
            {
              [classes.horizontal]: orientation === 'horizontal',
              [classes.vertical]: orientation === 'vertical',
              [classes.active]: active,
              [classes.disabled]: disabled,
            },
            OverridedDividerProps?.className,
            className
          )}
        />
      )}
    </NavigableWithCommands>
  )
})

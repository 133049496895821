import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'

export namespace AppDropResult {
  export enum Type {
    SpaceReorder = 'SPACE_REORDER',
    CardOnPage = 'CARD_ON_PAGE',
    CardReorder = 'CARD_REORDER',
  }

  export interface SpaceReorder {
    readonly type: AppDropResult.Type.SpaceReorder
    readonly previousSequence?: string
    readonly nextSequence?: string
  }

  export interface CardOnPage {
    readonly type: AppDropResult.Type.CardOnPage
    readonly pageId: Id
  }

  export interface CardReorder {
    readonly type: AppDropResult.Type.CardReorder
    readonly previousCard?: Model.Card
    readonly nextCard?: Model.Card
  }
}

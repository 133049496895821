import { init, showReportDialog } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { webConfig } from '../../modules/web-config'

export function initializeSentry(): void {
  init({
    dsn: webConfig.sentry.dsn,
    release: `${webConfig.version}-${webConfig.environment.node}-${webConfig.environment.target}-${webConfig.environment.agent}`,
    environment: `${webConfig.environment.node}-${webConfig.environment.target}-${webConfig.environment.agent}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: webConfig.environment.target === 'live' ? 0.3 : 1.0,
    beforeSend(event, hint?) {
      if (event.exception) {
        showReportDialog({ eventId: event.event_id })
      }
      return event
    },
    enabled: false, // It'll be re-enabled later by the DemoProvider if we detect we're not in the demo mode
  })
}

import { useCallback, useContext } from 'react'
import { useRefWrap } from '../../../../hooks/useRefWrap'
import { webConfig } from '../../../../modules/web-config'
import { useServices } from '../services'
import { Api } from './Api'
import { ApiContext } from './ApiContext'

export function useExecutor(api?: Api): {
  executeSmartCommand: (
    command: string,
    options?: { chatMode?: boolean; noChatHistory?: boolean } // TODO: Not implemented
  ) => Promise<{ done: boolean }>
} {
  const baseApi = useContext(ApiContext)

  const evaluatedApiRef = useRefWrap(api ?? baseApi)

  const { services } = useServices()

  return {
    executeSmartCommand: useCallback(
      async (command, options?) => {
        try {
          if (!command) return { done: false }
          const { code } = await services.ai.smartCommand(
            Api.convertApiToAiServiceApi(evaluatedApiRef.current),
            command
          )
          if (webConfig.developerLogs.smartCommand) {
            console.log(`> Command:\n${command}`) // eslint-disable-line no-console
            console.info(`> Code:\n${code || '-'}`) // eslint-disable-line no-console
          }
          if (code) {
            const internalApi: Api.Internal = { $: { command } }
            const executableApi = Object.keys(evaluatedApiRef.current).reduce((current, methodName) => {
              current[methodName] = evaluatedApiRef.current[methodName].function
              return current
            }, internalApi as Record<string, any>)
            const operation = eval(code) as Function // eslint-disable-line no-eval, @typescript-eslint/ban-types
            if (typeof operation === 'function') {
              await operation(executableApi)
            }
          }
          return { done: true }
        } catch {
          return { done: false }
        }
      },
      [services]
    ),
  }
}

import { Direction, PaletteType, ThemeOptions } from '@material-ui/core'
import { darkLtrThemeOptions } from './theme-options/darkLtrThemeOptions'
import { darkRtlThemeOptions } from './theme-options/darkRtlThemeOptions'
import { lightLtrThemeOptions } from './theme-options/lightLtrThemeOptions'
import { lightRtlThemeOptions } from './theme-options/lightRtlThemeOptions'

export function getThemeOptions(paletteType: PaletteType, direction: Direction): ThemeOptions {
  switch (paletteType) {
    case 'light':
      switch (direction) {
        case 'ltr':
          return lightLtrThemeOptions
        case 'rtl':
          return lightRtlThemeOptions
      }
      break
    case 'dark':
      switch (direction) {
        case 'ltr':
          return darkLtrThemeOptions
        case 'rtl':
          return darkRtlThemeOptions
      }
      break
  }
}

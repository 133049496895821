import { PropsWithChildren, useRef } from 'react'
import { HiddenContainerRefContext } from './HiddenContainerRefContext'

export function Provider({ children }: PropsWithChildren<{}>) {
  const hiddenContainerRef = useRef<HTMLDivElement>(null)

  return (
    <HiddenContainerRefContext.Provider value={hiddenContainerRef}>
      {children}

      <div ref={hiddenContainerRef} style={{ display: 'none' }}></div>
    </HiddenContainerRefContext.Provider>
  )
}

import {
  Divider,
  Input,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Popover,
  Slide,
  SlideProps,
  Typography,
  useTheme,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import classNames from 'classnames'
import Color from 'color'
import { forwardRef, useContext, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef } from 'react'
import { useGetSet } from 'react-use'
import {
  OnboardingTour,
  OnboardingWelcomeTourStep,
  useOnboardingCondition,
  useOnboardingConnect,
  useOnboardingControl,
  useOnboardingPreparation,
  useOnboardingState,
  useOnboardingStateMatch,
} from '../../components/App/modules/onboarding'
import { typed } from '../../helpers/typed'
import { useCombineRefs } from '../../hooks/useCombineRefs'
import { useRefWrap } from '../../hooks/useRefWrap'
import { sendAnalyticEvent } from '../analytics'
import { CustomIcon } from '../custom-icon'
import { DeviceProvider } from '../device'
import { commonKeyboardCombinations, KeyboardCombinationsView, useKeyboardHandling } from '../keyboard-handler'
import { NavigationFreeze } from '../navigation'
import { DisplayedCommandGroup, FilteredCommand } from './CommandManager'
import { CommandsContext } from './CommandsProvider'
import { useManageCommands } from './hooks/useManageCommands'

// TODO: Definitely break this file into smaller ones

const WIDTH_UNITS = 80
const TOP_UNITS = 10

const useStyles = makeStyles(
  theme => ({
    root: {
      maxWidth: 'unset', // Unset popover's paper default max-width
    },
    rootNonMobile: {
      width: theme.spacing(WIDTH_UNITS),
    },
    rootMobile: {
      width: '100vw',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    input: {
      padding: theme.spacing(2),
    },
    inputElement: {
      height: theme.spacing(3),
      padding: theme.spacing(1, 0.5),
    },
    dividerWrapper: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    prompt: {
      padding: theme.spacing(0.5, 2, 2, 2),
      whiteSpace: 'pre-line',
    },
    list: {
      margin: theme.spacing(0.5, 0, 2, 0.5),
      maxHeight: theme.spacing(36.5),
      overflowY: 'auto',
    },
    item: {
      marginBottom: theme.spacing(0.25),
      height: theme.spacing(6),
      cursor: 'pointer',
      '&:not(.Mui-selected)': {
        color: theme.palette.text.secondary,
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    groupHeaderNameWrapper: {
      marginBottom: theme.spacing(0.25),
      lineHeight: `${theme.spacing(3)}px`,
      backgroundColor: new Color(theme.palette.action.disabledBackground)
        .mix(new Color(theme.palette.background.paper), 0.5)
        .string(),
      color: theme.palette.text.secondary,
    },
    groupHeaderName: {
      lineHeight: `${theme.spacing(2)}px`,
    },
    groupName: {
      marginRight: theme.spacing(1),
    },
    commandName: {
      flexGrow: 1,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    commandShortcutKeys: {
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'CommandBarDialog' }
)

export enum CommandBarDialogMode {
  Closed = 'CLOSED',
  AllCommands = 'ALL_COMMANDS',
  FilteredCommands = 'FILTERED_COMMANDS',
  SelectItem = 'SELECT_ITEM',
  InputString = 'INPUT_STRING',
  InputStringWithSelectItem = 'INPUT_STRING_WITH_SELECT_ITEM',
}
export type CommandBarDialogState<M extends CommandBarDialogMode> = {
  [CommandBarDialogMode.Closed]: {
    readonly mode: CommandBarDialogMode.Closed
  }
  [CommandBarDialogMode.AllCommands]: {
    readonly mode: CommandBarDialogMode.AllCommands
    readonly allCommands: readonly DisplayedCommandGroup[]
    readonly selectedGroupIndex: number
    readonly selectedCommandIndex: number
  }
  [CommandBarDialogMode.FilteredCommands]: {
    readonly mode: CommandBarDialogMode.FilteredCommands
    readonly query: string
    readonly filteredCommands: readonly FilteredCommand[]
    readonly selectedIndex: number
  }
  [CommandBarDialogMode.SelectItem]: {
    readonly mode: CommandBarDialogMode.SelectItem
    readonly prompt?: string
    readonly description?: string
    readonly items: readonly unknown[]
    readonly getTitle: (item: any) => string
    readonly getDescription?: (item: any) => string
    readonly query: string
    readonly filteredItems: readonly unknown[]
    readonly selectedIndex: number
    readonly resolve: (selectedItem: any) => void
    readonly reject: (reason: any) => void
  }
  [CommandBarDialogMode.InputString]: {
    readonly mode: CommandBarDialogMode.InputString
    readonly prompt?: string
    readonly description?: string
    readonly placeholder?: string
    readonly value: string
    readonly resolve: (value: string) => void
    readonly reject: (reason: any) => void
  }
  [CommandBarDialogMode.InputStringWithSelectItem]: {
    readonly mode: CommandBarDialogMode.InputStringWithSelectItem
    readonly prompt?: string
    readonly description?: string
    readonly placeholder?: string
    readonly value: string
    readonly items: readonly unknown[]
    readonly getTitle: (item: any) => string
    readonly getDescription?: (item: any) => string
    readonly getValue?: (item: any) => string
    readonly selectedIndex?: number
    readonly resolve: (result: { value: string; selectedItem?: any }) => void
    readonly reject: (reason: any) => void
  }
}[M]

export interface CommandBarDialogReferee {
  getMode(): CommandBarDialogMode
  close(): void
  openCommands(): void

  openSelectItem<T>(options: {
    prompt?: string
    description?: string
    items: readonly T[]
    getTitle(item: T): string
    getDescription?(item: T): string
  }): Promise<T>

  openInputString(options?: {
    prompt?: string
    description?: string
    placeholder?: string
    initialValue?: string
  }): Promise<string>

  openInputStringWithSelectItem<T>(options: {
    prompt?: string
    description?: string
    placeholder?: string
    initialValue?: string
    items: readonly T[]
    getTitle(item: T): string
    getDescription?(item: T): string
    getValue?(item: T): string
  }): Promise<{ value: string; selectedItem?: T }>
}

export const CommandBarDialog = forwardRef<CommandBarDialogReferee>(function CommandBarDialog(_, ref) {
  const { screenWidth, isMobile } = useContext(DeviceProvider.Context)
  const { commandManager } = useContext(CommandsContext)

  const inputRef = useRef<HTMLInputElement>(null)
  const allCommandsListRef = useRef<HTMLDivElement>(null)
  const filteredCommandsListRef = useRef<HTMLUListElement>(null)
  const itemsListRef = useRef<HTMLUListElement>(null)

  const [getState, setState] = useGetSet<CommandBarDialogState<CommandBarDialogMode>>({
    mode: CommandBarDialogMode.Closed,
  })
  const state = getState()

  const referee = useMemo<CommandBarDialogReferee>(() => {
    function prepareModeChange(): void {
      const state = getState()
      if (
        state.mode !== CommandBarDialogMode.Closed &&
        state.mode !== CommandBarDialogMode.AllCommands &&
        state.mode !== CommandBarDialogMode.FilteredCommands
      ) {
        state.reject('Canceled')
      }
    }

    return {
      getMode() {
        return getState().mode
      },
      close() {
        prepareModeChange()
        setState({
          mode: CommandBarDialogMode.Closed,
        })
      },
      openCommands() {
        prepareModeChange()
        setState({
          mode: CommandBarDialogMode.AllCommands,
          allCommands: commandManager.getAllAvailableCommands(),
          selectedGroupIndex: 0,
          selectedCommandIndex: 0,
        })
      },
      openSelectItem(options) {
        return new Promise((resolve, reject) => {
          prepareModeChange()
          setState({
            mode: CommandBarDialogMode.SelectItem,
            prompt: options.prompt,
            description: options.description,
            items: options.items,
            getTitle: options.getTitle,
            getDescription: options.getDescription,
            query: '',
            filteredItems: options.items,
            selectedIndex: 0,
            resolve,
            reject,
          })
        })
      },
      openInputString(options) {
        return new Promise((resolve, reject) => {
          prepareModeChange()
          setState({
            mode: CommandBarDialogMode.InputString,
            prompt: options?.prompt,
            placeholder: options?.placeholder,
            description: options?.description,
            value: options?.initialValue ?? '',
            resolve,
            reject,
          })
        })
      },
      openInputStringWithSelectItem(options) {
        return new Promise((resolve, reject) => {
          prepareModeChange()
          setState({
            mode: CommandBarDialogMode.InputStringWithSelectItem,
            prompt: options.prompt,
            description: options.description,
            placeholder: options.placeholder,
            value: options.initialValue ?? '',
            items: options.items,
            getTitle: options.getTitle,
            getDescription: options.getDescription,
            getValue: options.getValue,
            selectedIndex: undefined,
            resolve,
            reject,
          })
        })
      },
    }
  }, [commandManager])
  useImperativeHandle(ref, () => referee, [referee])

  const closeIfStateIsUntouchedRef = useRefWrap((state: CommandBarDialogState<CommandBarDialogMode>): void => {
    if (state === getState()) {
      referee.close()
    }
  })

  const { getOnboardingStateStatic } = useOnboardingState()
  const { jumpToOnboardingTourStepStatic, abortOnboardingTourStatic } = useOnboardingControl()

  useEffect(() => {
    commandManager.subscribeToCommandHandling(subscription)
    return () => commandManager.unsubscribeFromCommandHandling(subscription)

    function subscription(codeName: string): void {
      const state = getState()
      if (state.mode === CommandBarDialogMode.AllCommands || state.mode === CommandBarDialogMode.FilteredCommands) {
        referee.close()
        const onboardingState = getOnboardingStateStatic()
        if (
          onboardingState?.step === OnboardingWelcomeTourStep.CommandBarWelcome ||
          onboardingState?.step === OnboardingWelcomeTourStep.CommandBarShortcuts
        ) {
          abortOnboardingTourStatic()
        }
      }
    }
  }, [commandManager, referee])

  useLayoutEffect(() => {
    const state = getState()
    switch (state.mode) {
      case CommandBarDialogMode.AllCommands:
        allCommandsListRef.current?.children[state.selectedGroupIndex]?.children[
          state.selectedCommandIndex + 1 /* 1 for the group name */
        ]?.scrollIntoView({ block: 'nearest' })
        break

      case CommandBarDialogMode.FilteredCommands:
        filteredCommandsListRef.current?.children[state.selectedIndex]?.scrollIntoView({ block: 'nearest' })
        break

      case CommandBarDialogMode.SelectItem:
        itemsListRef.current?.children[state.selectedIndex]?.scrollIntoView({ block: 'nearest' })
        break

      case CommandBarDialogMode.InputStringWithSelectItem:
        if (state.selectedIndex !== undefined) {
          itemsListRef.current?.children[state.selectedIndex]?.scrollIntoView({ block: 'nearest' })
        }
        break
    }
  })

  const { runCommandStatic } = useManageCommands()

  useKeyboardHandling(
    [
      {
        combinations: 'ArrowDown',
        noConsumption: true,
        handler(event) {
          const state = getState()
          switch (state.mode) {
            case CommandBarDialogMode.AllCommands: {
              const newSelectedCommandIndex =
                (state.selectedCommandIndex + 1) % state.allCommands[state.selectedGroupIndex].commands.length
              const newSelectedGroupIndex =
                newSelectedCommandIndex === 0
                  ? (state.selectedGroupIndex + 1) % state.allCommands.length
                  : state.selectedGroupIndex
              if (newSelectedCommandIndex === 0 && newSelectedGroupIndex === 0) return
              setState(
                typed<CommandBarDialogState<CommandBarDialogMode.AllCommands>>({
                  ...state,
                  selectedGroupIndex: newSelectedGroupIndex,
                  selectedCommandIndex: newSelectedCommandIndex,
                })
              )
              break
            }

            case CommandBarDialogMode.FilteredCommands: {
              const newSelectedIndex = state.selectedIndex + 1
              if (newSelectedIndex > state.filteredCommands.length - 1) return
              setState(
                typed<CommandBarDialogState<CommandBarDialogMode.FilteredCommands>>({
                  ...state,
                  selectedIndex: newSelectedIndex,
                })
              )
              break
            }

            case CommandBarDialogMode.SelectItem: {
              const newSelectedIndex = state.selectedIndex + 1
              if (newSelectedIndex > state.filteredItems.length - 1) return
              setState(
                typed<CommandBarDialogState<CommandBarDialogMode.SelectItem>>({
                  ...state,
                  selectedIndex: newSelectedIndex,
                })
              )
              break
            }

            case CommandBarDialogMode.InputStringWithSelectItem: {
              const newSelectedIndex = (state.selectedIndex ?? -1) + 1
              if (newSelectedIndex > state.items.length - 1) return
              setState(
                typed<CommandBarDialogState<CommandBarDialogMode.InputStringWithSelectItem>>({
                  ...state,
                  value: (state.getValue ?? state.getTitle)(state.items[newSelectedIndex]),
                  selectedIndex: newSelectedIndex,
                })
              )
              // Before we select the input text, we need to wait for the above setState() call to affect the input element value:
              setTimeout(() => {
                inputRef.current?.focus()
                inputRef.current?.select()
              })
              break
            }

            default:
              return 'not handled'
          }
        },
      },
      {
        combinations: 'ArrowUp',
        noConsumption: true,
        handler(event) {
          const state = getState()
          switch (state.mode) {
            case CommandBarDialogMode.AllCommands: {
              if (state.selectedGroupIndex === 0 && state.selectedCommandIndex === 0) return
              const newSelectedGroupIndex =
                state.selectedCommandIndex > 0 ? state.selectedGroupIndex : state.selectedGroupIndex - 1
              const newSelectedCommandIndex =
                state.selectedCommandIndex > 0
                  ? state.selectedCommandIndex - 1
                  : state.allCommands[newSelectedGroupIndex].commands.length - 1
              setState(
                typed<CommandBarDialogState<CommandBarDialogMode.AllCommands>>({
                  ...state,
                  selectedGroupIndex: newSelectedGroupIndex,
                  selectedCommandIndex: newSelectedCommandIndex,
                })
              )
              break
            }

            case CommandBarDialogMode.FilteredCommands:
            case CommandBarDialogMode.SelectItem:
              if (state.selectedIndex === 0) return
              setState(
                typed<CommandBarDialogState<CommandBarDialogMode.FilteredCommands | CommandBarDialogMode.SelectItem>>({
                  ...state,
                  selectedIndex: state.selectedIndex - 1,
                })
              )
              break

            case CommandBarDialogMode.InputStringWithSelectItem: {
              if (state.selectedIndex === undefined || state.selectedIndex === 0) return
              const newSelectedIndex = state.selectedIndex - 1
              setState(
                typed<CommandBarDialogState<CommandBarDialogMode.InputStringWithSelectItem>>({
                  ...state,
                  value: (state.getValue ?? state.getTitle)(state.items[newSelectedIndex]),
                  selectedIndex: newSelectedIndex,
                })
              )
              // Before we select the input text, we need to wait for the above setState() call to affect the input element value:
              setTimeout(() => {
                inputRef.current?.focus()
                inputRef.current?.select()
              })
              break
            }

            default:
              return 'not handled'
          }
        },
      },
      {
        combinations: commonKeyboardCombinations.enter,
        handler(event) {
          const state = getState()
          switch (state.mode) {
            case CommandBarDialogMode.AllCommands: {
              const command = state.allCommands[state.selectedGroupIndex].commands[state.selectedCommandIndex]
              if (command) {
                sendAnalyticEvent('Command bar', 'Run command by enter', command.displayName)
                runCommandStatic(command.codeName)
                closeIfStateIsUntouchedRef.current(state)
              }
              break
            }

            case CommandBarDialogMode.FilteredCommands: {
              const command = state.filteredCommands[state.selectedIndex]
              if (command) {
                sendAnalyticEvent('Command bar', 'Run command by enter after filter', command.commandDisplayName)
                runCommandStatic(command.codeName)
                closeIfStateIsUntouchedRef.current(state)
              }
              break
            }

            case CommandBarDialogMode.SelectItem: {
              const selectedItem = state.filteredItems[state.selectedIndex]
              sendAnalyticEvent('Command bar', 'Select item by enter', JSON.stringify(selectedItem as any))
              if (selectedItem) {
                state.resolve(selectedItem)
                closeIfStateIsUntouchedRef.current(state)
              }
              break
            }

            case CommandBarDialogMode.InputString:
              sendAnalyticEvent('Command bar', 'Input string by enter', state.value)
              state.resolve(state.value)
              closeIfStateIsUntouchedRef.current(state)
              break

            case CommandBarDialogMode.InputStringWithSelectItem: {
              const selectedItem = state.selectedIndex === undefined ? undefined : state.items[state.selectedIndex]
              sendAnalyticEvent(
                'Command bar',
                'Input string with select item by enter',
                JSON.stringify({ selectedItem: selectedItem as any, value: state.value })
              )
              state.resolve(selectedItem ? { value: state.value, selectedItem } : { value: state.value })
              closeIfStateIsUntouchedRef.current(state)
              break
            }

            default:
              return 'not handled'
          }
        },
      },
      {
        combinations: commonKeyboardCombinations.escape,
        handler(event) {
          referee.close()
        },
      },
    ],
    [referee],
    { removed: getState().mode === CommandBarDialogMode.Closed }
  )

  const { onboardingStateMatch } = useOnboardingStateMatch(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.CommandBarShortcuts
  )

  useOnboardingPreparation(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.CommandBarAccess,
    () => {
      if (state.mode === CommandBarDialogMode.AllCommands) {
        jumpToOnboardingTourStepStatic(OnboardingTour.Welcome, OnboardingWelcomeTourStep.CommandBarAccess, +1)
      }
    },
    [state.mode],
    {
      onlyRespondToDependencies: true,
    }
  )

  useOnboardingCondition(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.CommandBarWelcome,
    state.mode !== CommandBarDialogMode.Closed,
    'Please, open the command bar to continue the tour.'
  )

  const { connectOnboardingAnchorElement: connectOnboardingWelcomeStepAnchorElement } = useOnboardingConnect(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.CommandBarWelcome,
    {
      placement: 'top',
      delayMilliseconds: 200,
    }
  )

  useOnboardingCondition(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.CommandBarShortcuts,
    state.mode !== CommandBarDialogMode.Closed,
    'Please, open the command bar to continue the tour.'
  )

  const { connectOnboardingAnchorElement: connectOnboardingShortcutsStepAnchorElement } = useOnboardingConnect(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.CommandBarShortcuts,
    {
      placement: 'top-end',
      delayMilliseconds: 200,
      noHighlight: true,
    }
  )

  const combineRefs = useCombineRefs(
    connectOnboardingWelcomeStepAnchorElement,
    connectOnboardingShortcutsStepAnchorElement
  )

  const theme = useTheme()
  const classes = useStyles()

  return (
    <NavigationFreeze disabled={state.mode === CommandBarDialogMode.Closed}>
      {() => (
        <Popover
          open={state.mode !== CommandBarDialogMode.Closed}
          onClose={() => referee.close()}
          marginThreshold={0}
          {...(isMobile
            ? {
                anchorReference: 'anchorEl',
                anchorEl: window.document.body,
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'bottom', horizontal: 'center' },
                TransitionComponent: Slide,
                TransitionProps: typed<SlideProps>({ direction: 'up' }) as TransitionProps,
                PaperProps: {
                  elevation: 24,
                  className: classNames(classes.root, classes.rootMobile),
                },
              }
            : {
                anchorReference: 'anchorPosition',
                anchorPosition: {
                  top: theme.spacing(TOP_UNITS),
                  left: screenWidth / 2,
                },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
                transitionDuration: 0,
                PaperProps: {
                  elevation: 24,
                  className: classNames(classes.root, classes.rootNonMobile),
                },
              })}
        >
          <div ref={combineRefs()}>
            {state.mode === CommandBarDialogMode.AllCommands ? (
              <>
                <Input
                  inputProps={{ ref: inputRef }}
                  disableUnderline
                  fullWidth
                  autoFocus
                  startAdornment=">"
                  placeholder="Search a command"
                  className={classes.input}
                  classes={{ input: classes.inputElement }}
                  value=""
                  onChange={event =>
                    setState({
                      mode: CommandBarDialogMode.FilteredCommands,
                      query: event.target.value,
                      filteredCommands: commandManager.searchAvailableCommands(event.target.value),
                      selectedIndex: 0,
                    })
                  }
                  onKeyDown={event => {
                    if (
                      event.nativeEvent.code === 'Backspace' &&
                      !(event.nativeEvent.target as HTMLInputElement).value
                    ) {
                      runCommandStatic('main.pageSearch')
                    }
                  }}
                />

                <div className={classes.dividerWrapper}>
                  <Divider />
                </div>

                <div ref={allCommandsListRef} className={classes.list}>
                  {state.allCommands.map((group, groupIndex) => (
                    <List
                      key={group.name}
                      disablePadding
                      subheader={
                        <ListSubheader disableSticky className={classes.groupHeaderNameWrapper}>
                          <Typography variant="overline" className={classes.groupHeaderName}>
                            {group.displayName}
                          </Typography>
                        </ListSubheader>
                      }
                    >
                      {group.commands.map((command, commandIndex) => (
                        <ListItem
                          key={command.codeName}
                          className={classes.item}
                          selected={
                            groupIndex === state.selectedGroupIndex && commandIndex === state.selectedCommandIndex
                          }
                          onMouseMove={() => {
                            if (state.selectedGroupIndex === groupIndex && state.selectedCommandIndex === commandIndex)
                              return
                            setState(
                              typed<CommandBarDialogState<CommandBarDialogMode.AllCommands>>({
                                ...state,
                                selectedGroupIndex: groupIndex,
                                selectedCommandIndex: commandIndex,
                              })
                            )
                          }}
                          onClick={() => {
                            sendAnalyticEvent('Command bar', 'run command by click', command.displayName)
                            runCommandStatic(command.codeName)
                            closeIfStateIsUntouchedRef.current(state)
                          }}
                        >
                          {command.icon !== undefined && (
                            <CustomIcon name={command.icon} size="small" className={classes.icon} />
                          )}
                          <div className={classes.commandName}>{command.displayName}</div>
                          {command.defaultShortcutKeys && (
                            <KeyboardCombinationsView
                              combinations={command.defaultShortcutKeys}
                              highlight={
                                groupIndex === state.selectedGroupIndex && commandIndex === state.selectedCommandIndex
                              }
                              twinkle={onboardingStateMatch}
                              className={classes.commandShortcutKeys}
                            />
                          )}
                        </ListItem>
                      ))}
                      <br />
                    </List>
                  ))}
                </div>
              </>
            ) : state.mode === CommandBarDialogMode.FilteredCommands ? (
              <>
                <Input
                  inputProps={{ ref: inputRef }}
                  disableUnderline
                  fullWidth
                  autoFocus
                  startAdornment=">"
                  className={classes.input}
                  classes={{ input: classes.inputElement }}
                  value={state.query}
                  onChange={event => {
                    if (event.target.value) {
                      setState(
                        typed<CommandBarDialogState<CommandBarDialogMode.FilteredCommands>>({
                          ...state,
                          query: event.target.value,
                          filteredCommands: commandManager.searchAvailableCommands(event.target.value),
                          selectedIndex: 0,
                        })
                      )
                    } else {
                      setState(
                        typed<CommandBarDialogState<CommandBarDialogMode.AllCommands>>({
                          mode: CommandBarDialogMode.AllCommands,
                          allCommands: commandManager.getAllAvailableCommands(),
                          selectedGroupIndex: 0,
                          selectedCommandIndex: 0,
                        })
                      )
                    }
                  }}
                />

                <div className={classes.dividerWrapper}>
                  <Divider />
                </div>

                <List ref={filteredCommandsListRef} disablePadding className={classes.list}>
                  {state.filteredCommands.map((command, index) => (
                    <ListItem
                      key={command.codeName}
                      className={classes.item}
                      selected={index === state.selectedIndex}
                      onMouseMove={() => {
                        if (state.selectedIndex === index) return
                        setState(
                          typed<CommandBarDialogState<CommandBarDialogMode.FilteredCommands>>({
                            ...state,
                            selectedIndex: index,
                          })
                        )
                      }}
                      onClick={() => {
                        sendAnalyticEvent(
                          'Command bar',
                          'run command by click after filter',
                          command.commandDisplayName
                        )
                        runCommandStatic(command.codeName)
                        closeIfStateIsUntouchedRef.current(state)
                      }}
                    >
                      <div className={classes.groupName}>{command.groupDisplayName}:</div>
                      {command.icon !== undefined && (
                        <CustomIcon name={command.icon} size="small" className={classes.icon} />
                      )}
                      <div className={classes.commandName}>{command.commandDisplayName}</div>
                      {command.defaultShortcutKeys && (
                        <KeyboardCombinationsView
                          combinations={command.defaultShortcutKeys}
                          highlight={index === state.selectedIndex}
                          twinkle={onboardingStateMatch}
                          className={classes.commandShortcutKeys}
                        />
                      )}
                    </ListItem>
                  ))}
                </List>
              </>
            ) : state.mode === CommandBarDialogMode.SelectItem ? (
              <>
                <Input
                  inputProps={{ ref: inputRef }}
                  disableUnderline
                  fullWidth
                  autoFocus
                  placeholder="Filter items"
                  classes={{ input: classes.input }}
                  value={state.query}
                  onChange={event => {
                    const query = event.target.value
                    sendAnalyticEvent('Command bar', 'Select item', query)
                    setState(
                      typed<CommandBarDialogState<CommandBarDialogMode.SelectItem>>({
                        ...state,
                        query,
                        filteredItems: state.items.filter(
                          item =>
                            state.getTitle(item).toLowerCase().includes(query.toLowerCase()) ||
                            state.getDescription?.(item).toLowerCase().includes(query.toLowerCase())
                        ),
                        selectedIndex: 0,
                      })
                    )
                  }}
                />

                <div className={classes.dividerWrapper}>
                  <Divider />
                </div>

                {(state.prompt || state.description) && (
                  <div className={classes.prompt}>
                    {state.prompt && (
                      <Typography variant="caption" color="textPrimary" display="block">
                        {state.prompt}
                      </Typography>
                    )}
                    {state.description && (
                      <Typography variant="caption" color="textSecondary">
                        {state.description}
                      </Typography>
                    )}
                  </div>
                )}

                <List ref={itemsListRef} disablePadding className={classes.list}>
                  {state.filteredItems.map((item, index) => (
                    <ListItem
                      key={index}
                      className={classes.item}
                      selected={index === state.selectedIndex}
                      onClick={() => {
                        state.resolve(item)
                        closeIfStateIsUntouchedRef.current(state)
                      }}
                    >
                      <ListItemText primary={state.getTitle(item)} secondary={state.getDescription?.(item)} />
                    </ListItem>
                  ))}
                </List>
              </>
            ) : state.mode === CommandBarDialogMode.InputString ? (
              <>
                <Input
                  inputProps={{ ref: inputRef }}
                  disableUnderline
                  fullWidth
                  autoFocus
                  placeholder={state.placeholder}
                  classes={{ input: classes.input }}
                  value={state.value}
                  onChange={event => {
                    const { value } = event.target
                    sendAnalyticEvent('Command bar', 'Input string', value)
                    setState(
                      typed<CommandBarDialogState<CommandBarDialogMode.InputString>>({
                        ...state,
                        value,
                      })
                    )
                  }}
                />

                {(state.prompt || state.description) && (
                  <div className={classes.prompt}>
                    {state.prompt && (
                      <Typography variant="caption" color="textPrimary" display="block">
                        {state.prompt}
                      </Typography>
                    )}
                    {state.description && (
                      <Typography variant="caption" color="textSecondary">
                        {state.description}
                      </Typography>
                    )}
                  </div>
                )}
              </>
            ) : state.mode === CommandBarDialogMode.InputStringWithSelectItem ? (
              <>
                <Input
                  inputProps={{ ref: inputRef }}
                  disableUnderline
                  fullWidth
                  autoFocus
                  placeholder={state.placeholder}
                  classes={{ input: classes.input }}
                  value={state.value}
                  onChange={event => {
                    const { value } = event.target
                    sendAnalyticEvent('Command bar', 'Input string with select item', value)
                    setState(
                      typed<CommandBarDialogState<CommandBarDialogMode.InputStringWithSelectItem>>({
                        ...state,
                        value,
                        selectedIndex: undefined,
                      })
                    )
                  }}
                />

                <div className={classes.dividerWrapper}>
                  <Divider />
                </div>

                {(state.prompt || state.description) && (
                  <div className={classes.prompt}>
                    {state.prompt && (
                      <Typography variant="caption" color="textPrimary" display="block">
                        {state.prompt}
                      </Typography>
                    )}
                    {state.description && (
                      <Typography variant="caption" color="textSecondary">
                        {state.description}
                      </Typography>
                    )}
                  </div>
                )}

                <List ref={itemsListRef} disablePadding className={classes.list}>
                  {state.items.map((item, index) => (
                    <ListItem
                      key={index}
                      className={classes.item}
                      selected={index === state.selectedIndex}
                      onClick={() => {
                        state.resolve({ value: (state.getValue ?? state.getTitle)(item), selectedItem: item })
                        closeIfStateIsUntouchedRef.current(state)
                      }}
                    >
                      <ListItemText primary={state.getTitle(item)} secondary={state.getDescription?.(item)} />
                    </ListItem>
                  ))}
                </List>
              </>
            ) : null}
          </div>
        </Popover>
      )}
    </NavigationFreeze>
  )
})

import { Button, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useContext, useState } from 'react'
import { getPublicResourceUrl } from '../../../../../../../../../../helpers/getPublicResourceUrl'
import { DeviceProvider } from '../../../../../../../../../../modules/device'
import { webConfig } from '../../../../../../../../../../modules/web-config'
import { Gap } from '../../../../../../../../../Gap'
import { usePersistent, PersistentKey } from '../../../../../../../../modules/persistent'
import { BannerLayout } from './BannerLayout'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(4, 8, 4, 4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(6),
      flexWrap: 'wrap',
    },
    isNotLoaded: {
      position: 'fixed',
      display: 'none',
    },
    image: {
      maxHeight: 260,
    },
    details: {
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  { name: 'ChromeExtensionPromotionBanner' }
)

export function ChromeExtensionPromotionBanner() {
  const {
    persistent,
    currentValues: [showChromeExtensionPromotionBanner],
  } = usePersistent(PersistentKey.ShowChromeExtensionPromotionBanner)

  const { isMobile } = useContext(DeviceProvider.Context)

  const [isLoaded, setIsLoaded] = useState(false)

  const classes = useStyles()

  if (!showChromeExtensionPromotionBanner || isMobile) return null

  return (
    <BannerLayout
      className={classNames(classes.root, !isLoaded && classes.isNotLoaded)}
      onClose={() => persistent(PersistentKey.ShowChromeExtensionPromotionBanner).set(false)}
    >
      <img
        src={getPublicResourceUrl('promote-chrome-extension.webp')}
        alt="Promote Chrome Extension"
        className={classes.image}
        onLoad={() => setIsLoaded(true)}
      />

      <div className={classes.details}>
        <Typography variant="h6">Get the most out of {webConfig.app.name}</Typography>
        <Gap vertical={4} />
        <Button variant="contained" color="primary" component="a" href={webConfig.urls.chromeExtension} target="_blank">
          Add {webConfig.app.name} to Chrome
        </Button>
      </div>
    </BannerLayout>
  )
}

import { IconButton, makeStyles, Paper } from '@material-ui/core'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
      padding: theme.spacing(2),
    },
    success: {
      borderLeft: `${theme.spacing(0.75)}px solid ${theme.palette.success.main}`,
      borderRight: `${theme.spacing(0.75)}px solid ${theme.palette.success.main}`,
    },
    info: {
      borderLeft: `${theme.spacing(0.75)}px solid ${theme.palette.info.main}`,
      borderRight: `${theme.spacing(0.75)}px solid ${theme.palette.info.main}`,
    },
    warning: {
      borderLeft: `${theme.spacing(0.75)}px solid ${theme.palette.warning.main}`,
      borderRight: `${theme.spacing(0.75)}px solid ${theme.palette.warning.main}`,
    },
    error: {
      borderLeft: `${theme.spacing(0.75)}px solid ${theme.palette.error.main}`,
      borderRight: `${theme.spacing(0.75)}px solid ${theme.palette.error.main}`,
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
      padding: theme.spacing(1),
    },
  }),
  { name: 'Layout' }
)

export function Layout({
  variant,
  onClose,
  children,
}: PropsWithChildren<{
  variant?: 'success' | 'info' | 'warning' | 'error'
  onClose?: () => void
}>) {
  const classes = useStyles()

  return (
    <Paper elevation={4} className={classNames(classes.root, variant && classes[variant])}>
      {onClose && (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CustomIcon name="Close" size={2.5} color="text.secondary" />
        </IconButton>
      )}

      {children}
    </Paper>
  )
}

import { Button, makeStyles, Typography } from '@material-ui/core'
import { IpcChannel, WindowIpcMessageType, WindowType } from '@zettelooo/desktop-shared'
import { useLayoutEffect, useState } from 'react'
import { sendAnalyticEvent } from '../../../modules/analytics'
import { CustomIcon } from '../../../modules/custom-icon'
import { webConfig } from '../../../modules/web-config'
import { useClearDatabases } from '../modules/databases'
import { sendIpcMessage } from '../modules/electron'
import { EphemeralKey, useEphemeral } from '../modules/ephemeral'
import { useLoadingLogo } from '../modules/loading-logo'
import { usePersistent, PersistentKey } from '../modules/persistent'

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.default,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      padding: theme.spacing(2),
    },
    title: {
      margin: theme.spacing(5, 0),
    },
    description: {
      maxWidth: theme.spacing(50),
      textAlign: 'center',
      marginBottom: theme.spacing(5),
    },
    buttonsWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-evenly',
    },
    button: {
      width: theme.spacing(20),
      height: theme.spacing(5),
      borderRadius: theme.spacing(3.75),
    },
  }),
  { name: 'FatalError' }
)

export function FatalError({ error }: { error?: Error }) {
  const { persistent } = usePersistent()

  const { ephemeral } = useEphemeral()

  const [refreshing, setRefreshing] = useState(false)

  const { clearDatabases } = useClearDatabases()

  const { removeAllLoadingReasons } = useLoadingLogo()

  useLayoutEffect(() => {
    sendAnalyticEvent('Fatal Error', 'Error occured', JSON.stringify({ error }))
    removeAllLoadingReasons()
  }, [])

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <CustomIcon name="LogoColored" size={10} className={classes.logo} />

        <Typography variant="h4" color="textPrimary" className={classes.title}>
          Something wrong happened.
        </Typography>

        <Typography variant="subtitle1" color="textSecondary" className={classes.description}>
          Something went wrong and you&apos;ve found our error page. Please select one of the options below, or send us
          an email at hiii@{webConfig.app.domain}
        </Typography>

        <div className={classes.buttonsWrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={refreshing}
            onClick={async () => {
              sendAnalyticEvent('Fatal Error', 'Click on Signout')

              setRefreshing(true)

              await clearDatabases({
                persistentDatabase: false,
                actionsDatabase: true,
                mutablesDatabase: true,
                extensionsDatabase: true,
              })

              persistent.reset({
                [PersistentKey.DeviceId]: false,
                [PersistentKey.LastDisplayedChangeLog]: false,
                [PersistentKey.Authentication]: true,
                [PersistentKey.IsMutablesDatabaseInitialized]: true,
                [PersistentKey.FunnelSequences]: true,
                [PersistentKey.ThemeType]: true,
                [PersistentKey.LanguageCode]: true,
                [PersistentKey.SideBarSize]: true,
                [PersistentKey.SideBarVisible]: true,
                [PersistentKey.PageRecentData]: true,
                [PersistentKey.IsCardDialogFullScreen]: false,
                [PersistentKey.OnboardedTours]: false,
                [PersistentKey.RecentSearches]: true,
                [PersistentKey.ShowNewSpaceHelp]: false,
                [PersistentKey.ShowChromeExtensionPromotionBanner]: false,
              })

              ephemeral.reset({
                [EphemeralKey.RecentlyConnectedToServer]: true,
                [EphemeralKey.TimestampCorrection]: true,
                [EphemeralKey.SynchronizingActions]: true,
                [EphemeralKey.SynchronizingActionsDebounced]: true,
                [EphemeralKey.UploadingFileDictionary]: true,
                [EphemeralKey.OnMemoryModelsRegistrations]: false,
                [EphemeralKey.OnMemoryModelsToBeCleared]: false,
                [EphemeralKey.NewlyUpdatedExtensionIds]: false,
              })

              if (webConfig.environment.agent === 'electron') {
                sendIpcMessage(IpcChannel.Window, {
                  type: WindowIpcMessageType.WindowReload,
                  windowType: WindowType.Main,
                })
              } else {
                window.location.reload()
              }
            }}
          >
            Sign out
          </Button>
        </div>
      </div>
    </div>
  )
}

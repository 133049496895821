import ReactGA from 'react-ga'
import { webConfig } from '../../web-config'

export function initializeGoogleAnalytics(): void {
  if (webConfig.analytics.disabled || webConfig.analytics.googleAnalytics.disabled) return

  try {
    ReactGA.initialize(webConfig.analytics.googleAnalytics.trackingId, {
      debug: !webConfig.analytics.disabled, // TODO: Check this logic again
    })
  } catch (error) {
    log.error(error)
  }
}

import { PropsWithChildren } from 'react'
import { createContexts } from '../../../../modules/contexts'
import { useExternalDraggingOverStatus } from './useExternalDraggingOverStatus'

export const AppContexts = createContexts(
  ({ memoize }) =>
    (parameters?: { externalDraggingOverStatus: ReturnType<typeof useExternalDraggingOverStatus> }) => ({
      externalDraggingOverStatus: parameters?.externalDraggingOverStatus!,
    }),
  'App'
)

export function AppContextsProvider({ children }: PropsWithChildren<{}>) {
  return (
    <AppContexts.Provider
      parameters={{
        externalDraggingOverStatus: useExternalDraggingOverStatus(),
      }}
    >
      {children}
    </AppContexts.Provider>
  )
}

import { Dialog, IconButton, Input, makeStyles, Paper } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDebouncedValue } from '../../../../../../../../../hooks/useDebouncedValue'
import { sendAnalyticEvent } from '../../../../../../../../../modules/analytics'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { DeviceProvider } from '../../../../../../../../../modules/device'
import {
  useKeyboardHandling,
  commonKeyboardCombinations,
  KeyboardCombinationView,
} from '../../../../../../../../../modules/keyboard-handler'
import { NavigationFreeze } from '../../../../../../../../../modules/navigation'
import { usePersistent, PersistentKey } from '../../../../../../../modules/persistent'
import { Initializer } from '../../../../../../Initializer'
import { useSearchCards, useSearchPages, useUpdateRecentSearches } from '../../../../../modules/search'
import { Panel, useSwitchPanel } from '../../panel'
import { FilteredPage } from './FilteredPage'
import { PagesFilter } from './PagesFilter'
import { SearchFilters } from './SearchFilters'
import { SearchRecents } from './SearchRecents'
import { SearchResult } from './SearchResult'
import { SearchResultItem } from './types'

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'fixed',
      width: theme.spacing(100),
      // maxHeight: theme.spacing(63),
      maxWidth: '80vw',
      top: theme.spacing(10),
      left: '50%',
      transform: 'translateX(-50%)',
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    inputElement: {
      height: theme.spacing(3.5),
      padding: theme.spacing(1),
    },
    footer: {
      display: 'flex',
      padding: theme.spacing(2, 1),
      color: theme.palette.text.secondary,
      borderRadius: theme.spacing(0, 0, 1, 1),
    },
    footerGap: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'SearchDialog' }
)

export function SearchDialog({ open, onClose }: { open: boolean; onClose(): void }) {
  const { persistent } = usePersistent(PersistentKey.RecentSearches)

  const { isMobile } = useContext(DeviceProvider.Context)

  const { allCards, allPages } = useContexts(Initializer.Contexts)

  const [query, setQuery] = useState('')
  const [selectedItemIndex, setSelectedItemIndex] = useState(0)
  const [selectedSearchFilter, setSelectedSearchFilter] = useState(useSearchCards.SearchFilter.All)
  const [selectedPageFilter, setSelectedPageFilter] = useState<Model.Page>()

  const rawDebouncedQuery = useDebouncedValue(query, { timeoutMilliseconds: 100 })
  const debouncedQuery = query ? rawDebouncedQuery : query

  useLayoutEffect(() => {
    setSelectedItemIndex(0)
  }, [debouncedQuery])

  const { filteredPages } = useSearchPages(debouncedQuery)
  const { filteredCardSearchContents } = useSearchCards(debouncedQuery, selectedSearchFilter, {
    pageFilterId: selectedPageFilter?.id,
  })

  const searchResultItems = useMemo<readonly SearchResultItem[]>(
    () =>
      filteredCardSearchContents.flatMap(cardSearchContent => {
        const text =
          selectedSearchFilter === useSearchCards.SearchFilter.All
            ? cardSearchContent.text
            : selectedSearchFilter === useSearchCards.SearchFilter.Files
            ? cardSearchContent.files
            : ''

        const results: SearchResultItem[] = [
          {
            id: cardSearchContent.card.id,
            card: cardSearchContent.card,
            text,
          },
        ]

        return results
      }),
    [filteredCardSearchContents, selectedSearchFilter]
  )

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (open) {
      sendAnalyticEvent('Search Dialog', 'Open Search Dialog')
    }
  }, [open])

  const { switchPanelStatic } = useSwitchPanel()

  const { updateRecentSearchesStatic } = useUpdateRecentSearches()

  useKeyboardHandling(
    [
      {
        combinations: 'ArrowDown',
        noConsumption: true,
        handler() {
          sendAnalyticEvent('Search Dialog', 'Arrow Down')
          const length = debouncedQuery ? searchResultItems.length : persistent[PersistentKey.RecentSearches].length
          if (selectedItemIndex < length - 1) {
            setSelectedItemIndex(selectedItemIndex + 1)
          }
        },
      },
      {
        combinations: 'ArrowUp',
        noConsumption: true,
        handler() {
          sendAnalyticEvent('Search Dialog', 'Arrow Up')
          if (selectedItemIndex > 0) {
            setSelectedItemIndex(selectedItemIndex - 1)
          }
        },
      },
      {
        combinations: commonKeyboardCombinations.enter,
        handler() {
          sendAnalyticEvent('Search Dialog', 'Open by enter')

          let selectedCard: Model.Card
          if (debouncedQuery) {
            selectedCard = searchResultItems[selectedItemIndex].card
            updateRecentSearchesStatic(selectedCard.id, debouncedQuery, selectedSearchFilter)
          } else {
            const recentSearch = persistent[PersistentKey.RecentSearches][selectedItemIndex]
            selectedCard = allCards.dictionary[recentSearch.cardId]!
            if (!selectedCard) return
          }

          const page = allPages.dictionary[selectedCard.pageId]
          if (!page) return

          switchPanelStatic({
            type: Panel.Type.Page,
            pageId: page.id,
            cardId: selectedCard.id,
          })
          setQuery('')
          onClose()
        },
      },
    ],
    [persistent, debouncedQuery, searchResultItems, selectedItemIndex, allCards, allPages],
    {
      removed: !open,
    }
  )

  const classes = useStyles()

  return (
    <NavigationFreeze disabled={!open}>
      {() => (
        <Dialog
          open={open}
          onClose={() => {
            sendAnalyticEvent('Search Dialog', 'Close Search Dialog')
            setQuery('')
            onClose()
          }}
        >
          <Paper elevation={4} className={classes.root}>
            <>
              <div className={classes.inputContainer}>
                {selectedPageFilter ? (
                  <FilteredPage
                    page={selectedPageFilter}
                    onRemoveFilter={() => {
                      setSelectedPageFilter(undefined)
                      inputRef.current?.focus()
                    }}
                  />
                ) : (
                  <CustomIcon name="Search" size="small" />
                )}

                <Input
                  inputProps={{ ref: inputRef }}
                  disableUnderline
                  fullWidth
                  autoFocus
                  placeholder="Search..."
                  classes={{ input: classes.inputElement }}
                  value={query}
                  onChange={event => setQuery(event.target.value)}
                />

                {(query || selectedPageFilter) && (
                  <IconButton
                    onClick={() => {
                      setQuery('')
                      setSelectedPageFilter(undefined)
                    }}
                  >
                    <CustomIcon name="Close" size="small" color="text.secondary" />
                  </IconButton>
                )}
              </div>

              {debouncedQuery ? (
                <>
                  <SearchFilters
                    selectedFilter={selectedSearchFilter}
                    onFilter={filter => {
                      setSelectedSearchFilter(filter)
                      inputRef.current?.focus()
                    }}
                  />

                  {!selectedPageFilter && filteredPages && filteredPages.length > 0 && (
                    <PagesFilter
                      pages={filteredPages}
                      onFilter={page => {
                        setSelectedPageFilter(page)
                        inputRef.current?.focus()
                      }}
                    />
                  )}

                  <SearchResult
                    query={debouncedQuery}
                    selectedItemIndex={selectedItemIndex}
                    setSelectedItemIndex={setSelectedItemIndex}
                    selectedSearchFilter={selectedSearchFilter}
                    searchResultItems={searchResultItems}
                    onSelectItem={card => {
                      sendAnalyticEvent('Search Dialog', 'Click on filtered content')
                      setQuery('')
                      onClose()
                    }}
                    onOpenPage={page => {
                      sendAnalyticEvent('Search Dialog', 'Click on page')
                      setQuery('')
                      onClose()
                    }}
                  />
                </>
              ) : (
                !selectedPageFilter &&
                persistent[PersistentKey.RecentSearches].length > 0 && (
                  <SearchRecents
                    selectedCardIndex={selectedItemIndex}
                    setSelectedCardIndex={setSelectedItemIndex}
                    onSelectCard={cardContent => {
                      sendAnalyticEvent('Search Dialog', 'Click on recent content')
                      setQuery('')
                      onClose()
                    }}
                  />
                )
              )}

              {!isMobile &&
                debouncedQuery &&
                (filteredCardSearchContents.length > 0 || persistent[PersistentKey.RecentSearches].length > 0) && (
                  <div className={classes.footer}>
                    <KeyboardCombinationView combination="Enter" postfix="to select" className={classes.footerGap} />
                    <KeyboardCombinationView combination="ArrowUp" />
                    &nbsp;
                    <KeyboardCombinationView
                      combination="ArrowDown"
                      postfix="to navigate"
                      className={classes.footerGap}
                    />
                    <KeyboardCombinationView combination="Escape" postfix="to close" />
                  </div>
                )}
            </>
          </Paper>
        </Dialog>
      )}
    </NavigationFreeze>
  )
}

import { IpcChannel, LauncherIpcMessageType, WindowType, LauncherIpcMessage } from '@zettelooo/desktop-shared'
import { useEffect } from 'react'
import { useGetSet } from 'react-use'
import { useRefWrap } from '../../../../../../../../hooks/useRefWrap'
import { sendIpcMessage, useIpcMessageHandling } from '../../../../../../modules/electron'

export function useBrowserSuggestions(
  query: string,
  options?: {
    readonly disabled?: boolean
  }
): {
  browserSuggestionsResult: useBrowserSuggestions.Result | undefined
} {
  const [getResult, setResult] = useGetSet<useBrowserSuggestions.Result | undefined>(undefined)

  const queryRef = useRefWrap(query)

  useEffect(() => {
    if (options?.disabled || !query) {
      setResult(undefined)
      return
    }

    sendIpcMessage(IpcChannel.Launcher, {
      type: LauncherIpcMessageType.LauncherGetBrowserSuggestions,
      input: query,
    })
  }, [query, options?.disabled])

  useIpcMessageHandling(
    IpcChannel.Launcher,
    (event, message) => {
      switch (message.type) {
        case LauncherIpcMessageType.ElectronSetBrowserSuggestions:
          if (message.query === queryRef.current) {
            const { type, ...result } = message
            const currentResult = getResult()
            if (currentResult?.query !== result.query || currentResult.version <= result.version) {
              setResult(result)
            }
          }
          break
      }
    },
    [],
    {
      windowType: WindowType.Launcher,
      disabled: options?.disabled,
    }
  )

  return {
    browserSuggestionsResult: getResult(),
  }
}

export namespace useBrowserSuggestions {
  export type Result = Omit<LauncherIpcMessage<LauncherIpcMessageType.ElectronSetBrowserSuggestions>, 'type'>
}

import { webConfig } from '../../../../modules/web-config'
import { PersistentKey, PersistentType } from './types'

export const defaultValues: Readonly<PersistentType> = {
  [PersistentKey.DeviceId]: '', // It will be initialized once at the app startup and never changes again after that
  [PersistentKey.LastDisplayedChangeLog]: { forVersion: webConfig.version },
  [PersistentKey.Authentication]: undefined,
  [PersistentKey.IsMutablesDatabaseInitialized]: false,
  [PersistentKey.FunnelSequences]: { forSpaces: {}, forPages: {} },
  [PersistentKey.ThemeType]: 'system',
  [PersistentKey.LanguageCode]: 'en',
  [PersistentKey.SideBarSize]: undefined,
  [PersistentKey.SideBarVisible]: true,
  [PersistentKey.PageRecentData]: {},
  [PersistentKey.IsCardDialogFullScreen]: false,
  [PersistentKey.OnboardedTours]: [],
  [PersistentKey.RecentSearches]: [],
  [PersistentKey.ShowNewSpaceHelp]: true,
  [PersistentKey.ShowChromeExtensionPromotionBanner]: true,
}

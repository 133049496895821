import { useEffect } from 'react'
import { useDebouncedValue } from '../../../../../hooks/useDebouncedValue'
import { useContexts } from '../../../../../modules/contexts'
import { useAppNotistack } from '../../app-notistack'
import { OnboardingProviderContexts } from '../OnboardingProvider'
import { OnboardingStep, OnboardingTour } from '../types'
import { useOnboardingStateMatch } from './useOnboardingStateMatch'

export function useOnboardingCondition<T extends OnboardingTour>(
  tour: T,
  step: OnboardingStep<T>,
  condition: boolean,
  message: string
): void {
  const { preparationCount } = useContexts(OnboardingProviderContexts)

  const { onboardingStateMatch } = useOnboardingStateMatch(tour, step)

  const enhancedCondition = !onboardingStateMatch || preparationCount > 0 || condition

  // Sometimes, preparers need a little time to make the condition true before displaying a message to the user:
  const debouncedEnhancedCondition = useDebouncedValue(enhancedCondition, { initialValue: true })

  const { enqueueSnackbar, closeSnackbar } = useAppNotistack()

  useEffect(() => {
    if (!debouncedEnhancedCondition && !enhancedCondition) {
      const snackbarKey = enqueueSnackbar('Tour is pending!', message, { persist: true })

      return () => closeSnackbar(snackbarKey)
    }
  }, [enhancedCondition, debouncedEnhancedCondition])
}

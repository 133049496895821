import { Button, makeStyles, Menu, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { forwardRef, Fragment, PropsWithChildren, ReactElement, ReactNode, RefAttributes, useContext } from 'react'
import { useCombineRefs } from '../../../../../../../../../hooks/useCombineRefs'
import { DeviceProvider } from '../../../../../../../../../modules/device'
import { Navigable } from '../../../../../../../../../modules/navigation'
import { Dropdown } from '../../../../../../../../Dropdown'
import { Gap } from '../../../../../../../../Gap'
import { PanelLayout } from './PanelLayout'

const useStyles = makeStyles(
  theme => ({
    root: {
      height: '100%',
      display: 'flex',
    },
    tabs: {
      width: theme.spacing(23.5),
      flexShrink: 0,
      padding: theme.spacing(4.5, 3.5),
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      width: 0,
      flexGrow: 1,
      height: '100%',
      padding: theme.spacing(5),
      overflow: 'auto',
    },
    caption: {
      margin: theme.spacing(4, 0, 2, 1),
      userSelect: 'none',
    },
    tab: {
      borderRadius: theme.spacing(1),
      height: theme.spacing(5),
      marginBottom: theme.spacing(1),
      justifyContent: 'flex-start',
    },
    tabLabel: {
      ...theme.typography.subtitle1,
    },
    tabSelected: {
      backgroundColor: theme.palette.action.selected,
      '& $tabLabel': {
        fontWeight: 700,
      },
    },
  }),
  { name: 'PanelMenu' }
)

export const PanelMenu: {
  <I>(
    props: PropsWithChildren<{
      containerClassName?: string
      header: string
      items: readonly I[]
      getCaption?(item: I): ReactNode
      getLabel(item: I): ReactNode
      selectedItem: I
      onSelectItem(item: I): void
    }> &
      RefAttributes<HTMLDivElement>
  ): ReactElement | null
} = forwardRef(function PanelMenu(
  { containerClassName, header, items, getCaption, getLabel, selectedItem, onSelectItem, children },
  ref
) {
  const { isMobile } = useContext(DeviceProvider.Context)

  const combineRefs = useCombineRefs(ref)

  const classes = useStyles()

  function renderTabs(done?: () => void) {
    return (
      <div className={classes.tabs}>
        {items.map((item, index) => {
          const caption = getCaption?.(item)
          return (
            <Fragment key={index}>
              {caption && (
                <Typography variant="caption" color="textSecondary" noWrap className={classes.caption}>
                  {caption}
                </Typography>
              )}

              <Navigable>
                {({ connectNavigable: connectButton }) => (
                  <Button
                    ref={connectButton}
                    classes={{
                      root: classNames(classes.tab, item === selectedItem && classes.tabSelected),
                      label: classes.tabLabel,
                    }}
                    onClick={() => {
                      onSelectItem(item)
                      done?.()
                    }}
                  >
                    {getLabel(item)}
                  </Button>
                )}
              </Navigable>
            </Fragment>
          )
        })}
      </div>
    )
  }

  return (
    <Navigable>
      {({ connectNavigable }) => (
        <PanelLayout
          ref={combineRefs(connectNavigable)}
          header={
            <>
              <Gap horizontal={2} />
              <Typography variant="h5" display="inline">
                {header}
              </Typography>

              {isMobile && (
                <>
                  <Gap horizontal={2} />
                  <Typography display="inline">/</Typography>
                  <Gap horizontal={1} />

                  <Dropdown label={<Typography variant="button">{getLabel(selectedItem)}</Typography>}>
                    {({ open, close, rootRef }) => (
                      <Menu
                        open={open}
                        onClose={close}
                        anchorEl={rootRef.current}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        MenuListProps={{ disablePadding: true }}
                      >
                        {renderTabs(close)}
                      </Menu>
                    )}
                  </Dropdown>
                </>
              )}
            </>
          }
        >
          <div className={classes.root}>
            {!isMobile && renderTabs()}

            <div className={classNames(classes.container, containerClassName)}>{children}</div>
          </div>
        </PanelLayout>
      )}
    </Navigable>
  )
})

import { ReadonlyRecord } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { referenceKey } from './referenceKey'

export type WithReferenceKey<H extends ZettelExtensions.HtmlContent<any>> = H &
  ReadonlyRecord<typeof referenceKey, symbol>

export function withReferenceKey<H extends ZettelExtensions.HtmlContent<any>>(htmlContent: H): WithReferenceKey<H> {
  const htmlContentWithReferenceKey: WithReferenceKey<H> = {
    ...htmlContent,
    [referenceKey]: Symbol('HtmlContentTools.referenceValue'),
  }
  return htmlContentWithReferenceKey
}

import { List, ListItem, makeStyles, Typography, useTheme } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { useContext, useLayoutEffect } from 'react'
import { arrayHelpers } from '../../../../../../../../../helpers/native/arrayHelpers'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { DeviceProvider } from '../../../../../../../../../modules/device'
import { useInfiniteVirtualScrollList } from '../../../../../../../../../modules/infinite-virtual-scroll'
import { tokenize } from '../../../../../../../../../modules/search'
import { Initializer } from '../../../../../../Initializer'
import { useSearchCards, useUpdateRecentSearches } from '../../../../../modules/search'
import { Panel, useSwitchPanel } from '../../panel'
import { SearchCardPreview } from './SearchCardPreview'
import { SearchItemLayout } from './SearchItemLayout'
import { SearchResultItem } from './types'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'stretch',
      height: theme.spacing(45),
    },
    list: {
      width: 0,
      flexGrow: 1,
      margin: theme.spacing(1),
      maxHeight: theme.spacing(45),
      overflowY: 'auto',
    },
    rootMobile: {
      height: `calc(100vh - ${theme.spacing(43)}px)`,
      flexDirection: 'column',
      gap: theme.spacing(3),
      '& $list': {
        width: 'auto',
        height: 0,
      },
    },
    item: {
      marginBottom: theme.spacing(1.5),
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      height: theme.spacing(7.25),
      borderRadius: theme.spacing(1),
      cursor: 'pointer',
      '&:not(.Mui-selected)': {
        color: theme.palette.text.secondary,
      },
    },
    noResult: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: 'SearchResult' }
)

export function SearchResult({
  query,
  selectedItemIndex,
  setSelectedItemIndex,
  selectedSearchFilter,
  searchResultItems,
  onSelectItem,
  onOpenPage,
}: {
  query: string
  selectedItemIndex: number
  setSelectedItemIndex(index: number): void
  selectedSearchFilter: useSearchCards.SearchFilter
  searchResultItems: readonly SearchResultItem[]
  onSelectItem(item: SearchResultItem): void
  onOpenPage(page: Model.Page): void
}) {
  const { isMobile } = useContext(DeviceProvider.Context)
  const { allPages } = useContexts(Initializer.Contexts)

  const theme = useTheme()

  const selectedItemId = searchResultItems[selectedItemIndex]?.id

  const {
    setScrollElementStatic,
    getScrollElementStatic,
    setItemElementFactoryStatic,
    getItemElementStatic,
    itemsFrom,
    itemsTo,
    clippedItems,
    getIsScrollAtBottomStatic,
    jumpToTopStatic,
    jumpToButtomStatic,
  } = useInfiniteVirtualScrollList(
    {
      minimumThresholdInViewsCount: 2,
      minimumThresholdInPixels: theme.spacing(100),
      maximumThresholdInViewsCount: 4,
      maximumThresholdInPixels: theme.spacing(400),
      ultimateMaximumThresholdInViewsCount: 6,
      ultimateMaximumThresholdInPixels: theme.spacing(600),
      averageItemHeight: theme.spacing(7),
      highlightedItemId: selectedItemId,
      highlightedItemScrollIntoViewOption: 'nearest',
      items: searchResultItems,
      initialItemsCount: 10,
    },
    []
  )

  useLayoutEffect(() => {
    getItemElementStatic(selectedItemId)?.scrollIntoView({ block: 'nearest' })
  }, [selectedItemId])

  const { switchPanelStatic } = useSwitchPanel()

  const { updateRecentSearchesStatic } = useUpdateRecentSearches()

  const classes = useStyles()

  return (
    <div className={classNames(classes.root, isMobile && classes.rootMobile)}>
      {clippedItems.length > 0 ? (
        <>
          <List ref={setScrollElementStatic} disablePadding className={classes.list}>
            {clippedItems.map((item, index) => (
              <ListItem
                key={item.id}
                ref={setItemElementFactoryStatic(item.id)}
                className={classes.item}
                selected={index + (itemsFrom ?? 0) === selectedItemIndex}
                onMouseMove={() => setSelectedItemIndex(index + (itemsFrom ?? 0))}
                onClick={() => {
                  onSelectItem(item)
                  updateRecentSearchesStatic(item.id, query, selectedSearchFilter)

                  const page = allPages.dictionary[item.card.pageId]
                  if (!page) return

                  switchPanelStatic({
                    type: Panel.Type.Page,
                    pageId: page.id,
                    cardId: item.id,
                  })
                }}
              >
                <SearchItemLayout item={item} tokens={arrayHelpers.distinct(tokenize(query, 1))} />
              </ListItem>
            ))}
          </List>

          <div className={classes.list}>
            {searchResultItems[selectedItemIndex] && (
              <SearchCardPreview
                key={searchResultItems[selectedItemIndex].id + query}
                card={searchResultItems[selectedItemIndex].card}
                searchText={
                  searchResultItems[selectedItemIndex].compromiseResult
                    ? searchResultItems[selectedItemIndex].compromiseResult!.value
                    : query
                        .replace(/(^\s+|\s+$)/g, '')
                        .replace(/^[^\w]+|[^\w]+$/g, '') // Characters to strip from start and end of the input string
                        .replace(/[^\w'-]+/g, '|') // Characters used to break up the input string into words
                        .replace(/^\||\|$/g, '')
                }
                onOpenPage={() => {
                  const { card } = searchResultItems[selectedItemIndex]
                  const page = allPages.dictionary[card.pageId]
                  if (!page) return

                  switchPanelStatic({
                    type: Panel.Type.Page,
                    pageId: page.id,
                  })

                  onOpenPage(page)
                }}
              />
            )}
          </div>
        </>
      ) : (
        <div className={classes.noResult}>
          <Typography variant="subtitle1" color="textSecondary">
            No Results
          </Typography>
          <div>&nbsp;</div>
          <Typography variant="subtitle2" color="textSecondary">
            There was no results.
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Try a new search.
          </Typography>
        </div>
      )}
    </div>
  )
}

import { useEffect } from 'react'
import { DemoMode } from '../../../../modules/demo-mode'
import { useServices } from '../../modules/services'

export function usePostDemoModeByUsername(): void {
  const { services } = useServices()

  useEffect(() => {
    if (DemoMode.data?.username) {
      services.admin.postDemoModeByUsername(DemoMode.data.username)
    }
  }, [services])
}

import { Button, makeStyles, Typography } from '@material-ui/core'
import { captureException } from '@sentry/react'
import { IpcChannel, UpdateIpcMessageType } from '@zettelooo/desktop-shared'
import { useContext, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { sendAnalyticEvent } from '../../../../modules/analytics'
import { webConfig } from '../../../../modules/web-config'
import { useAppNotistack } from '../app-notistack'
import { sendIpcMessage } from '../electron'
import { PersistentKey, usePersistent } from '../persistent'
import { PresentationDialogSlide } from '../presentation-dialog'
import { StaticService, useServices } from '../services'
import { UpdateProvider } from './UpdateProvider'

const useStyles = makeStyles(
  theme => ({
    actions: {
      display: 'flex',
      gap: theme.spacing(2),
      flexWrap: 'wrap',
    },
    action: {
      flexShrink: 0,
    },
  }),
  { name: 'OverviewUpdater' }
)

export function OverviewUpdater() {
  const { persistent } = usePersistent()

  const { updateIsReady, isUpdating, update } = useContext(UpdateProvider.Context)

  const [overviewStep, setOverviewStep] = useState<StaticService.UpdateSlides.Step>()
  const [overviewIsOpen, setOverviewIsOpen] = useState(false)

  const { services } = useServices()

  const { enqueueSnackbar } = useAppNotistack()

  useEffectOnce(() => {
    // It will force the value to be actually stored for the key. Therefore, after the next update, we can detect
    // that "a new version is installed" not that "it's the first run of the app on this device":
    persistent[PersistentKey.LastDisplayedChangeLog] = { ...persistent[PersistentKey.LastDisplayedChangeLog] }
  })

  function postponeUpdate(): void {
    if (isUpdating) return

    setTimeout(getOverviewStep, webConfig.timings.showUpdateOverviewInterval)

    if (webConfig.environment.agent === 'electron') {
      sendIpcMessage(IpcChannel.Update, {
        type: UpdateIpcMessageType.BrowserLater,
      })
    }
  }

  async function getOverviewStep(): Promise<void> {
    if (isUpdating) return

    try {
      const updateSlides = await services.static.getUpdateSlides()

      if (updateSlides.version !== 1) return
      if (
        updateSlides.contentVersion !== persistent[PersistentKey.LastDisplayedChangeLog].contentVersion &&
        !webConfig.temporary.temporarilyDisabledOrToBeRemoved
      ) {
        setOverviewStep(updateSlides.overviewStep)
      } else {
        enqueueSnackbar(
          'New version available',
          <>
            <Typography variant="body2">An improved version of {webConfig.app.name} is available.</Typography>
            <Typography variant="body2" gutterBottom>
              Please, restart to update.
            </Typography>

            <Button
              variant="text"
              color="secondary"
              disabled={isUpdating}
              onClick={() => {
                if (isUpdating) return
                update()
              }}
            >
              {isUpdating ? 'Restarting...' : 'Restart now'}
            </Button>
          </>,
          {
            variant: 'warning',
            persist: true,
            onClose(event, reason, key) {
              postponeUpdate()
            },
          }
        )
      }
    } catch (error) {
      log.error(error)
      captureException(error, { tags: { module: 'update' } })
    }
  }

  useEffect(() => {
    if (updateIsReady) {
      getOverviewStep()
    }
  }, [persistent, services, updateIsReady])

  useEffect(() => {
    setOverviewIsOpen(Boolean(overviewStep))
  }, [overviewStep])

  const classes = useStyles()

  return (
    <>
      {overviewStep && (
        <PresentationDialogSlide.Component
          open={overviewIsOpen}
          onClose={() => {
            setOverviewIsOpen(false)
            postponeUpdate()
          }}
          caption={overviewStep.caption}
          title={overviewStep.title}
          description={
            overviewStep.descriptionSections && (
              <PresentationDialogSlide.Sections sections={overviewStep.descriptionSections} />
            )
          }
          actions={
            <div className={classes.actions}>
              <Button
                variant="contained"
                color="primary"
                className={classes.action}
                disabled={isUpdating}
                onClick={() => {
                  sendAnalyticEvent('Overview Updater', 'Click on Update now')
                  update()
                }}
              >
                {isUpdating ? 'Updating...' : 'Update now'}
              </Button>

              {!isUpdating && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.action}
                  onClick={() => {
                    sendAnalyticEvent('Overview Updater', 'Click on Update later')
                    setOverviewIsOpen(false)
                    postponeUpdate()
                  }}
                >
                  Update later
                </Button>
              )}
            </div>
          }
        >
          {overviewStep.imageFileName ? (
            <PresentationDialogSlide.Image url={services.static.getFileUrl(overviewStep.imageFileName)} />
          ) : overviewStep.videoFileName ? (
            <PresentationDialogSlide.Video url={services.static.getFileUrl(overviewStep.videoFileName)} />
          ) : null}
        </PresentationDialogSlide.Component>
      )}
    </>
  )
}

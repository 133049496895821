import { makeStyles, Paper, PaperProps } from '@material-ui/core'
import { generateSequence } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { ComponentRef, forwardRef, memo, useImperativeHandle, useRef, useState } from 'react'
import { useApplyAction } from '../../../../hooks/useApplyAction'
import { useGenerateModelId } from '../../../../hooks/useGenerateModelId'
import { ExtensionRolesProvider } from '../../../../modules/extension'
import { CardComposer } from './CardComposer'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: theme.spacing(1.75),
    },
    cardComposer: {
      flex: 'auto',
    },
  }),
  { name: 'CardAdder' }
)

export const CardAdder = memo(
  forwardRef<
    {
      readonly root: HTMLDivElement | null
      readonly cardComposer: ComponentRef<typeof CardComposer> | null
    },
    {
      page: Model.Page
      onSubmitStarting?: () => void
      onSubmitStarted?: () => void
      onSubmitFinished?: () => void
    } & PaperProps
  >(function CardAdder({ page, onSubmitStarted, onSubmitStarting, onSubmitFinished, className, ...otherProps }, ref) {
    const [submitting, setSubmitting] = useState(false)

    const rootRef = useRef<HTMLDivElement>(null)
    const cardComposerRef = useRef<ComponentRef<typeof CardComposer>>(null)

    useImperativeHandle(
      ref,
      () => ({
        get root() {
          return rootRef.current
        },
        get cardComposer() {
          return cardComposerRef.current
        },
      }),
      []
    )

    const { applyActionStatic } = useApplyAction()

    const { generateModelIdStatic } = useGenerateModelId()

    const classes = useStyles()

    return (
      <ExtensionRolesProvider role="CardAdder">
        <Paper ref={rootRef} {...otherProps} elevation={0} className={classNames(classes.root, className)}>
          <CardComposer
            ref={cardComposerRef}
            className={classes.cardComposer}
            page={page}
            onSubmit={async value => {
              onSubmitStarting?.()
              if (submitting) return
              try {
                setSubmitting(true)
                onSubmitStarted?.()
                await applyActionStatic.createModel({
                  type: Model.Type.Card,
                  id: generateModelIdStatic(),
                  pageId: page.id,
                  sequence: generateSequence(),
                  dataDictionary: value,
                })
              } finally {
                onSubmitFinished?.()
                setSubmitting(false)
              }
            }}
          />
        </Paper>
      </ExtensionRolesProvider>
    )
  })
)

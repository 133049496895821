import { useTheme } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { webConfig } from '../../../modules/web-config'
import { Tidio } from '../modules/tidio'

export function AppHelmet() {
  const theme = useTheme()

  const tidioIsEnabled = Tidio.useIsEnabled()

  return (
    <Helmet>
      <meta name="theme-color" content={theme.palette.primary.main} />
      <meta name="description" content={`${webConfig.app.name} web application`} />
      <title>{webConfig.app.name}</title>
      {tidioIsEnabled && <script async src={webConfig.tidioBundleUrl} />}
    </Helmet>
  )
}

import mixpanel from 'mixpanel-browser'
import { webConfig } from '../../web-config'

export function initializeMixpanel(): void {
  if (webConfig.analytics.disabled || webConfig.analytics.mixpanel.disabled) return

  try {
    mixpanel.init(webConfig.analytics.mixpanel.apiKey)
  } catch (error) {
    log.error(error)
  }
}

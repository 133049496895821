import { makeStyles, Paper } from '@material-ui/core'
import classNames from 'classnames'
import { forwardRef, PropsWithChildren } from 'react'

const useStyles = makeStyles(
  theme => ({
    root: {
      marginBottom: theme.spacing(2),
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    padding: {
      padding: theme.spacing(1, 1, 1, 2),
    },
  }),
  { name: 'FieldPaper' }
)

export const FieldPaper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    flex?: boolean
    noPadding?: boolean
    className?: string
  }>
>(function FieldPaper({ flex, noPadding, className, children }, ref) {
  const classes = useStyles()

  return (
    <Paper
      ref={ref}
      elevation={0}
      className={classNames(classes.root, flex && classes.flex, !noPadding && classes.padding, className)}
    >
      {children}
    </Paper>
  )
})

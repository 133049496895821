import { configureScope } from '@sentry/react'
import { Model } from '@zettelooo/server-shared'
import { useSyncEffect } from '../../../../hooks/useSyncEffect'
import { PersistentKey, usePersistent } from '../../modules/persistent'

export function useSentryConfigureScope(account: Model.Account | undefined): void {
  const [, authentication] = usePersistent(PersistentKey.Authentication)

  useSyncEffect(() => {
    configureScope(scope => {
      scope.setUser(
        account
          ? {
              id: account.id,
              email: account.email,
              username: account.userName,
              name: account.name,
              walletAddress: account.walletAddress,
            }
          : authentication
          ? {
              id: authentication.decodedAccessToken.userId,
            }
          : null
      )
    })
  }, [account, authentication])
}

import { useContext, useLayoutEffect } from 'react'
import { useRefEffect } from '../../hooks/useRefEffect'
import { useRefWrap } from '../../hooks/useRefWrap'
import { DRAG_EVENT_MANAGED_KEY } from './constants'
import { Context } from './Context'

export function useDrag<DragObject, DropResult>(options: {
  dragObject: DragObject
  dragSource?: HTMLElement | null
  dragPreview?: HTMLElement | null
  dragPreviewX?: number
  dragPreviewY?: number
  dragStart?(event: DragEvent): void
  dragStartAfterPreview?(event: DragEvent): void
  drag?(event: DragEvent): void
  dragEnd?(event: DragEvent): void
  drop?(dragObject: DragObject, dropResult: DropResult): void
  disabled?: boolean
}): {
  connectDragSource: useRefEffect.Connect
  connectDragPreview: useRefEffect.Connect
} {
  const { setDragDataStatic } = useContext(Context)

  const optionsRef = useRefWrap(options)

  const connectDragSource = useRefEffect(
    dragSourceElement => {
      if (dragSourceElement && !options.disabled) {
        dragSourceElement.draggable = true

        const handleDragStart = (event: DragEvent): void => {
          if (getAndSetManaged(event)) return
          const dragPreviewElement = optionsRef.current.dragPreview ?? connectDragPreview.current
          if (dragPreviewElement) {
            const dragPreviewBoundingClientRect = dragPreviewElement.getBoundingClientRect()
            const x = optionsRef.current.dragPreviewX ?? event.pageX - dragPreviewBoundingClientRect.x
            const y = optionsRef.current.dragPreviewY ?? event.pageY - dragPreviewBoundingClientRect.y
            event.dataTransfer?.setDragImage(dragPreviewElement, x, y)
          }
          setDragDataStatic({
            dragObject: optionsRef.current.dragObject,
            drop: optionsRef.current.drop,
          })
          optionsRef.current.dragStart?.(event)
          setTimeout(() => optionsRef.current.dragStartAfterPreview?.(event))
        }

        const handleDrag = (event: DragEvent): void => {
          if (getAndSetManaged(event)) return
          setDragDataStatic({
            dragObject: optionsRef.current.dragObject,
            drop: optionsRef.current.drop,
          })
          options.drag?.(event)
        }

        const handleDragEnd = (event: DragEvent): void => {
          if (getAndSetManaged(event)) return
          setDragDataStatic(undefined)
          options.dragEnd?.(event)
        }

        dragSourceElement.addEventListener('dragstart', handleDragStart)
        dragSourceElement.addEventListener('drag', handleDrag)
        dragSourceElement.addEventListener('dragend', handleDragEnd)

        return () => {
          dragSourceElement.draggable = false

          dragSourceElement.removeEventListener('dragstart', handleDragStart)
          dragSourceElement.removeEventListener('drag', handleDrag)
          dragSourceElement.removeEventListener('dragend', handleDragEnd)
        }
      }

      function getAndSetManaged(event: DragEvent): boolean {
        const extendedEvent = event as any
        const managed = extendedEvent[DRAG_EVENT_MANAGED_KEY] ?? false
        extendedEvent[DRAG_EVENT_MANAGED_KEY] = true
        return managed
      }
    },
    [options.disabled]
  )

  const connectDragPreview = useRefEffect(() => {}, [])

  useLayoutEffect(() => {
    if (options.dragSource) {
      connectDragSource(options.dragSource)
      return () => void connectDragSource(null)
    }
  }, [options.dragSource, connectDragSource])

  return {
    connectDragSource,
    connectDragPreview,
  }
}

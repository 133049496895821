// It's under control, we need to get user data as many times as the user insert them correctly, no performance problem here:
/* eslint-disable no-await-in-loop */

import { captureException } from '@sentry/react'
import { IpcChannel, MainIpcMessageType } from '@zettelooo/desktop-shared'
import { useState } from 'react'
import { useEffectOnce, useMountedState } from 'react-use'
import { useAsyncEffect } from '../../../../../../../hooks/useAsyncEffect'
import { ChromeExtensionMode } from '../../../../../../../modules/chrome-extension-mode'
import { webConfig } from '../../../../../../../modules/web-config'
import { useAppHistoryControl } from '../../../../../modules/app-history'
import { useAppNotistack } from '../../../../../modules/app-notistack'
import { sendIpcMessage } from '../../../../../modules/electron'
import { useServices } from '../../../../../modules/services'
import { routes } from '../../../modules/routes'
import { FinishSignIn } from './useFinishSignIn'
import { NewUserDataControl } from './useNewUserData'
import { Query } from './useQuery'
import { SubscriptionCheckControl } from './useSubscriptionCheck'

export function useWeb2SignIn({
  query,
  newUserDataControl,
  subscriptionCheckControl,
  finishSignIn,
}: {
  query: Query
  newUserDataControl: NewUserDataControl
  subscriptionCheckControl: SubscriptionCheckControl
  finishSignIn: FinishSignIn
}): {
  web2: {
    signInWithGoogle(): Promise<void>
    redirecting: boolean
    loading: boolean
  }
} {
  const [redirecting, setRedirecting] = useState(false)
  const [loading, setLoading] = useState(false)

  const { services } = useServices()

  const { appHistoryControlStatic } = useAppHistoryControl()

  const { enqueueSnackbar } = useAppNotistack()

  const isMounted = useMountedState()

  async function signInWithGoogle() {
    const popupWindow = ChromeExtensionMode.isActive
    try {
      setRedirecting(!popupWindow)
      const { signInLink } = await services.account.googleSignInGetAuthLink(popupWindow)
      if (ChromeExtensionMode.isActive) {
        window.open(signInLink, 'Sign-in', 'width=600,height=850')
      } else if (webConfig.environment.agent === 'browser') {
        window.location.href = signInLink
      } else if (webConfig.environment.agent === 'electron') {
        sendIpcMessage(IpcChannel.Main, { type: MainIpcMessageType.MainRequestSignIn, url: signInLink })
        setRedirecting(false)
      }
    } catch (error) {
      log.error('sign in', error)
      captureException(error, { tags: { module: 'sign in' } })
      enqueueSnackbar('Error', 'Our server is on a break', { variant: 'error' })
      setRedirecting(false)
    }
  }

  useEffectOnce(() => {
    window.addEventListener('message', handleMessage)
    return () => void window.removeEventListener('message', handleMessage)

    async function handleMessage(event: MessageEvent): Promise<void> {
      if (event.data?.type === 'GOOGLE_SIGN_IN_AUTHENTICATED') {
        event.stopImmediatePropagation()
        const sourceWindow = event.source as WindowProxy | null
        sourceWindow?.close()
        if (event.data.query && typeof event.data.query === 'string') {
          appHistoryControlStatic.push(`${routes.signIn.getPath({}, {})}${event.data.query}`)
        }
      }
    }
  })

  useAsyncEffect(async () => {
    if (!query.isGoogleSigningIn || !query.accessToken || !query.refreshToken) return
    try {
      setLoading(true)
      const { accountStatus } = await services.account.getAccountStatus(query.accessToken)
      if (!isMounted()) return
      setLoading(false)
      if (accountStatus.neededFields.length > 0) {
        const { canceled } = await newUserDataControl.submit(query.accessToken, accountStatus.neededFields)
        if (canceled) return
      }
      if (accountStatus.subscription === 'none') {
        const { canceled } = await subscriptionCheckControl.subscribe(query.accessToken, query.refreshToken)
        if (canceled) return
      }
      finishSignIn(query.accessToken, query.refreshToken)
    } catch (error) {
      log.error(error)
    } finally {
      if (isMounted()) {
        setLoading(false)
      }
    }
  }, [query])

  return {
    web2: {
      signInWithGoogle,
      redirecting,
      loading,
    },
  }
}

import { ReadonlyRecord } from '@zettelooo/commons'
import { useMemo } from 'react'
import { useUnmount } from 'react-use'

type UniqueNameRepository = ReadonlyRecord<string | number, string>

const repositories: Record<string, UniqueNameRepository> = {}

export function useUniqueNameRepository(): { uniqueNameRepositoryStatic: UniqueNameRepository } {
  const registerationKeyStatic = useMemo(() => {
    let registerationKeyCandidate: string
    do {
      registerationKeyCandidate = Math.random().toString().slice(2).padEnd(10, '0').substring(0, 10)
    } while (registerationKeyCandidate in repositories)
    repositories[registerationKeyCandidate] = new Proxy(
      {},
      {
        get(target, property, receiver) {
          return `\u2590\u2588${registerationKeyStatic}-${String(property)}\u2588\u258C`
        },
      }
    )
    return registerationKeyCandidate
  }, [])

  useUnmount(() => {
    delete repositories[registerationKeyStatic]
  })

  return {
    uniqueNameRepositoryStatic: repositories[registerationKeyStatic],
  }
}

import { createTheme, ThemeProvider } from '@material-ui/core'
import { PropsWithChildren, useContext, useMemo } from 'react'
import { DeviceProvider } from '../../../../modules/device'
import { webConfig } from '../../../../modules/web-config'
import { getThemeOptions } from './getThemeOptions'

export function QuickStylingProvider({ children }: PropsWithChildren<{}>) {
  const { systemThemePaletteType } = useContext(DeviceProvider.Context)

  const evaluatedTheme = useMemo(() => {
    const themeOptions = getThemeOptions(systemThemePaletteType, 'ltr')
    return createTheme(themeOptions)
  }, [systemThemePaletteType])

  if (webConfig.environment.node === 'development' || webConfig.environment.target === 'stage') {
    const windowObject = window as any
    windowObject.theme = evaluatedTheme // For test & development purposes
  }

  return (
    <ThemeProvider theme={evaluatedTheme}>
      <>{children}</>
    </ThemeProvider>
  )
}

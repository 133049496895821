import { PartialReadonlyRecord, Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useAccount } from './hooks/useAccount'
import { useAllBadges } from './hooks/useAllBadges'
import { useAllCards } from './hooks/useAllCards'
import { useAllPages } from './hooks/useAllPages'
import { useAllUsers } from './hooks/useAllUsers'
import { useCard } from './hooks/useCard'
import { useCardsForPage } from './hooks/useCardsForPage'
import { usePage } from './hooks/usePage'
import { useUser } from './hooks/useUser'

// TODO: Review again all the following to see which one is not used anymore:
export const commonMutablesDatabaseReaders = {
  useAccount,

  useAllUsers,
  useUser,

  useAllPages,
  usePage,

  useAllCards,
  useCard,
  useCardsForPage,

  useAllBadges,
} as const

export namespace commonMutablesDatabaseReaders {
  export interface AllModels<T extends Model.Type> {
    readonly stillLoading: boolean
    readonly array: readonly Model.ByType<T>[]
    readonly dictionary: PartialReadonlyRecord<Id, Model.ByType<T>>
  }
}

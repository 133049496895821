import { DependencyList, useContext, useEffect, useMemo } from 'react'
import { KeyboardHandlerContext } from './KeyboardHandlerProvider'
import { KeyboardSubscription } from './types'

export function useKeyboardHandling(
  subscription: KeyboardSubscription | (() => KeyboardSubscription),
  dependencies: DependencyList,
  options?: {
    removed?: boolean
    disabled?: boolean
  }
): void {
  const { keyboardHandler } = useContext(KeyboardHandlerContext)

  const subscriptionKey = useMemo(
    () => (options?.removed ? undefined : keyboardHandler.subscribe()),
    [keyboardHandler, options?.removed]
  )

  useEffect(
    () => () => {
      if (subscriptionKey) {
        keyboardHandler.unsubscribe(subscriptionKey)
      }
    },
    [keyboardHandler, subscriptionKey]
  )

  const memoizedSubscription = useMemo(
    options?.disabled ? () => undefined : typeof subscription === 'function' ? subscription : () => subscription,
    [options?.disabled, ...dependencies]
  )

  useEffect(() => {
    if (subscriptionKey) {
      if (memoizedSubscription) {
        keyboardHandler.setSubscription(subscriptionKey, memoizedSubscription)
      } else {
        keyboardHandler.clearSubscription(subscriptionKey)
      }
    }
  }, [keyboardHandler, subscriptionKey, memoizedSubscription])
}

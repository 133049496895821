import { Button, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useState } from 'react'
import { getPublicResourceUrl } from '../../../../../../../../helpers/getPublicResourceUrl'
import { useCommonStyles } from '../../../../../../../../hooks/useCommonStyles'
import { sendAnalyticEvent } from '../../../../../../../../modules/analytics'
import { useContexts } from '../../../../../../../../modules/contexts'
import { webConfig } from '../../../../../../../../modules/web-config'
import { PresentationDialogSlide } from '../../../../../../modules/presentation-dialog'
import { useServices } from '../../../../../../modules/services'
import { AccountData } from '../../../../modules/account-data'

const useStyles = makeStyles(
  theme => ({
    canvas: {
      width: '100%',
      height: '100%',
      backgroundImage: `url(${getPublicResourceUrl('background-confetti.png')})`,
      backgroundSize: 'cover',
      borderRadius: theme.spacing(1.25),
    },
  }),
  { name: 'WelcomeSlides' }
)

export function WelcomeSlides({ onClose, onFinish }: { onClose(): void; onFinish?(): void }) {
  const { account } = useContexts(AccountData.Contexts)

  const [stepIndex, setStepIndex] = useState(-1) // Initialized with the step index with the first slide, the introduction slide
  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement | null>(null)

  // TODO: No canvas confetti for now:
  // useEffect(() => {
  //   if (canvasElement) {
  //     const canvasConfettiInstance = canvasConfetti.create(canvasElement, { resize: true })
  //
  //     let animationTimeout: any
  //
  //     const frame = (): void => {
  //       canvasConfettiInstance({
  //         particleCount: 7,
  //         spread: 199,
  //         angle: 270,
  //         startVelocity: 50,
  //         origin: { x: 0.5, y: -1 },
  //         ticks: 5000,
  //         gravity: 0.69,
  //         scalar: 0.69,
  //       })
  //
  //       animationTimeout = setTimeout(() => {
  //         requestAnimationFrame(frame)
  //       }, 50)
  //     }
  //
  //     frame()
  //
  //     return () => clearTimeout(animationTimeout)
  //   }
  // }, [canvasElement])

  const { services } = useServices()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  function trackedOnClose(): void {
    sendAnalyticEvent('Welcome Slides', 'Click on Close')
    onClose()
  }

  function renderActions() {
    return (
      <PresentationDialogSlide.Actions
        presentationDialogSlideIsOpen={stepIndex >= 0}
        stepIndex={stepIndex}
        stepsCount={slideRenderers.length - 1} // The first slide is just an introduction, so it's not counted
        onPreviousStep={() => {
          sendAnalyticEvent('Welcome Slides', `Click on Previous on index ${stepIndex}`)
          setStepIndex(stepIndex - 1)
        }}
        onNextStep={() => {
          sendAnalyticEvent('Welcome Slides', `Click on Next on index ${stepIndex}`)
          setStepIndex(stepIndex + 1)
        }}
        onDone={() => {
          sendAnalyticEvent('Welcome Slides', 'Click on Done')
          trackedOnClose()
          onFinish?.()
        }}
      />
    )
  }

  const slideRenderers: readonly ((slideStepIndex: number) => React.JSX.Element)[] = [
    slideStepIndex => (
      <PresentationDialogSlide.Component
        key={slideStepIndex}
        open
        onClose={trackedOnClose}
        className={classNames(stepIndex !== slideStepIndex && commonClasses.hidden)}
        caption={`Hey ${account.name} 👋`}
        title={`Welcome to ${webConfig.app.name}!`}
        description={`Thank you for being an early ${webConfig.app.name} beta tester 🤗`}
        actions={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              sendAnalyticEvent('Welcome Slides', 'Click on Show me around')
              setStepIndex(0)
            }}
          >
            Show me around
          </Button>
        }
      >
        <canvas ref={setCanvasElement} className={classes.canvas} />
      </PresentationDialogSlide.Component>
    ),

    slideStepIndex => (
      <PresentationDialogSlide.Component
        key={slideStepIndex}
        open
        onClose={trackedOnClose}
        className={classNames(stepIndex !== slideStepIndex && commonClasses.hidden)}
        caption={`What is ${webConfig.app.name}?`}
        title="A personal assistant for your memory"
        description="All your files, folders, notes, bookmarks, inspiration, articles, email to yourself or saved messages in one single place"
        actions={renderActions()}
      >
        <PresentationDialogSlide.Image url={services.static.getFileUrl('slide1.png')} />
      </PresentationDialogSlide.Component>
    ),

    slideStepIndex => (
      <PresentationDialogSlide.Component
        key={slideStepIndex}
        open
        onClose={trackedOnClose}
        className={classNames(stepIndex !== slideStepIndex && commonClasses.hidden)}
        caption="COMPOSER"
        title="Save anything, wherever"
        description={`${webConfig.app.name} lets you save thoughts without thinking twice, Store little pieces of information (we call them notes) without any friction`}
        actions={renderActions()}
      >
        <PresentationDialogSlide.Image url={services.static.getFileUrl('slide2.png')} />
      </PresentationDialogSlide.Component>
    ),

    slideStepIndex => (
      <PresentationDialogSlide.Component
        key={slideStepIndex}
        open
        onClose={trackedOnClose}
        className={classNames(stepIndex !== slideStepIndex && commonClasses.hidden)}
        caption="COLLABORATION"
        title="Designed for seamless colleboration"
        description="Organize your way, with anyone, from anywhere. Invite friends to any page with their email or username and start collaborating"
        actions={renderActions()}
      >
        <PresentationDialogSlide.Image url={services.static.getFileUrl('slide3.png')} />
      </PresentationDialogSlide.Component>
    ),

    slideStepIndex => (
      <PresentationDialogSlide.Component
        key={slideStepIndex}
        open
        onClose={trackedOnClose}
        className={classNames(stepIndex !== slideStepIndex && commonClasses.hidden)}
        caption="Launcher"
        title="Save any website or any highlight text from anywhere"
        description="press Ctrl + SPACE to activate launcher while browsing on any website to save it"
        actions={renderActions()}
      >
        <PresentationDialogSlide.Video url={services.static.getFileUrl('l-capture.mp4')} />
      </PresentationDialogSlide.Component>
    ),

    slideStepIndex => (
      <PresentationDialogSlide.Component
        key={slideStepIndex}
        open
        onClose={trackedOnClose}
        className={classNames(stepIndex !== slideStepIndex && commonClasses.hidden)}
        caption="Launcher"
        title="Paste any information you saved, anywhere"
        description="Press Enter to directly paste this information from launcher to wherever your cursor is, in any app"
        actions={renderActions()}
      >
        <PresentationDialogSlide.Video url={services.static.getFileUrl('paste.mp4')} />
      </PresentationDialogSlide.Component>
    ),

    slideStepIndex => (
      <PresentationDialogSlide.Component
        key={slideStepIndex}
        open
        onClose={trackedOnClose}
        className={classNames(stepIndex !== slideStepIndex && commonClasses.hidden)}
        caption="Composer"
        title="Create quick note from launcher"
        description="Open Compose from launcher and save thoughts as quickly as possible, you can drag and drop your files and images"
        actions={renderActions()}
      >
        <PresentationDialogSlide.Video url={services.static.getFileUrl('compose.mp4')} />
      </PresentationDialogSlide.Component>
    ),
  ]

  // The first slide is just an introduction, so its step index is -1:
  return <>{slideRenderers.map((renderSlide, index) => renderSlide(index - 1))}</>
}

import { makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import { CustomAvatar } from '../../../../../../../CustomAvatar'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      margin: theme.spacing(0.5, 1),
      padding: theme.spacing(0.5, 0),
      overflowX: 'scroll',
      height: theme.spacing(7),
      cursor: 'pointer',
      marginBottom: theme.spacing(2),
    },
    page: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(3),
      paddingRight: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        '& > $name': {
          maxWidth: 'unset',
        },
      },
    },
    name: {
      maxWidth: theme.spacing(10),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'PagesFilter' }
)

export function PagesFilter({ pages, onFilter }: { pages: readonly Model.Page[]; onFilter(page: Model.Page): void }) {
  const theme = useTheme()

  const classes = useStyles()

  return (
    <div className={classes.root}>
      {pages.map(page => (
        <div key={page.id} className={classes.page} onClick={() => onFilter(page)}>
          <Tooltip title={page.name}>
            <>
              <CustomAvatar
                size={5.625}
                avatarFileId={page.avatarFileId}
                name={page.name}
                emoji={page.iconEmoji}
                color={page.color || theme.palette.action.disabledBackground}
                margin={[0, 1, 0, 0]}
              />

              <Typography variant="subtitle2" className={classes.name}>
                {page.name}
              </Typography>
            </>
          </Tooltip>
        </div>
      ))}
    </div>
  )
}

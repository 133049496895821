import { ComponentProps, ComponentRef, DependencyList, forwardRef, RefObject, useMemo, useRef, useState } from 'react'
import { useCombineRefs } from '../../hooks/useCombineRefs'
import {
  Navigable,
  NavigableStatus,
  useNavigationAreaAbilityStatus,
  useNavigationAreaActivityStatus,
} from '../navigation'
import { useCommandGroupRegistration } from './hooks/useCommandGroupRegistration'
import { CommandGroup } from './types'

export const NavigableWithCommands = forwardRef<
  ComponentRef<typeof Navigable>,
  ComponentProps<typeof Navigable> & {
    commandGroup(provided: { navigableRef: RefObject<ComponentRef<typeof Navigable>> }): CommandGroup
    commandGroupDependencies?: DependencyList
  }
>(function NavigableWithCommands(
  { commandGroup, commandGroupDependencies, onNavigableStatus, ...otherNavigableProps },
  ref
) {
  const navigableRef = useRef<ComponentRef<typeof Navigable>>(null)

  const [navigableStatus, setNavigableStatus] = useState<NavigableStatus | undefined>(undefined)

  const { isNavigationEnabledForThisArea } = useNavigationAreaAbilityStatus()
  const { isNavigationActiveForThisArea } = useNavigationAreaActivityStatus()

  const group = useMemo<CommandGroup>(() => {
    const rawGroup = commandGroup({ navigableRef })
    if (isNavigationActiveForThisArea) return rawGroup
    return {
      ...rawGroup,
      commands: rawGroup.commands.map(rawCommand => ({
        ...rawCommand,
        defaultShortcutKeys: undefined,
      })),
    }
  }, [...(commandGroupDependencies ?? []), isNavigationActiveForThisArea])

  useCommandGroupRegistration(() => group, [group], {
    removed: !isNavigationEnabledForThisArea,
    disabled: otherNavigableProps.disabled || !navigableStatus || navigableStatus === NavigableStatus.NotNavigated,
  })

  const combineRefs = useCombineRefs(ref, navigableRef)

  return (
    <Navigable
      ref={combineRefs()}
      onNavigableStatus={(newNavigableStatus, oldNavigableStatus) => {
        setNavigableStatus(newNavigableStatus)
        onNavigableStatus?.(newNavigableStatus, oldNavigableStatus)
      }}
      {...otherNavigableProps}
    />
  )
})

import { Button, List, makeStyles, Tooltip } from '@material-ui/core'
import { useState } from 'react'
import { sendAnalyticEvent } from '../../../../../../../../../../../../modules/analytics'
import { useManageCommands } from '../../../../../../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../../../../modules/custom-icon'
import { Gap } from '../../../../../../../../../../../Gap'
import { TypographyNoWrap } from '../../../../../../../../../../../TypographyNoWrap'
import { useApplyAction } from '../../../../../../../../../../hooks/useApplyAction'
import { PagePanel } from '../../PagePanel'
import { Layout } from '../Layout'
import { Member } from './Member'

const useStyles = makeStyles(
  theme => ({
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    layoutBody: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'Members' }
)

export function Members() {
  const { page } = useContexts(PagePanel.Contexts)

  const [disabled, setDisabled] = useState(false)

  const { runCommandStatic } = useManageCommands()

  const { applyActionStatic } = useApplyAction()

  const classes = useStyles()

  return (
    <Layout
      title={
        <div className={classes.header}>
          <TypographyNoWrap>Members</TypographyNoWrap>
          <Tooltip title="Invite new members">
            <span>
              <Button
                variant="outlined"
                size="small"
                disabled={disabled}
                onClick={() => {
                  sendAnalyticEvent('PageSettings', 'Open invite member dialog')
                  runCommandStatic('main.inviteMembers')
                }}
              >
                <CustomIcon name="Add" size={2.5} />
                <Gap horizontal={1} />
                Add
              </Button>
            </span>
          </Tooltip>
        </div>
      }
      bodyClassName={classes.layoutBody}
    >
      <List disablePadding>
        {page.memberUserIds.map(memberUserId => (
          <Member key={memberUserId} memberUserId={memberUserId} />
        ))}
      </List>
    </Layout>
  )
}

import { PartialRecord } from '@zettelooo/commons'
import { Broadcaster } from '../../service-worker'
import { EqualityCheckers, KeyValueStoreTypeBase } from '../types'
import { KeyValueEngine } from './KeyValueEngine'
import { KeyValueEngineBroadcasterMessage } from './types'

/**
 * Not persistent, only on memory.
 * Shared between all the client windows and service worker through broadcaster.
 */
export class KeyValueEngineByBroadcaster<
  KeyValueStoreType extends KeyValueStoreTypeBase
> extends KeyValueEngine<KeyValueStoreType> {
  private readonly watchers: PartialRecord<keyof KeyValueStoreType, (() => void)[]>

  constructor(
    getDefaultValues: () => Readonly<KeyValueStoreType>,
    equalityCheckers: EqualityCheckers<KeyValueStoreType>,
    private readonly broadcaster: Broadcaster,
    private readonly broadcasterChannel: string,
    initialValues?: Partial<Readonly<KeyValueStoreType>>
  ) {
    super(getDefaultValues, equalityCheckers, { ...getDefaultValues(), ...initialValues })

    this.watchers = {}

    broadcaster.addMessageEventListener<KeyValueEngineBroadcasterMessage>(
      this.broadcasterChannel,
      ({ key, newValue }) => {
        if (newValue === this.get(key)) return
        super.set(key, newValue)
        this.watchers[key]?.forEach(handler => handler())
      }
    )
  }

  set<K extends keyof KeyValueStoreType>(key: K, newValue: KeyValueStoreType[K]): void | Promise<void> {
    if (newValue === this.get(key)) return
    super.set(key, newValue)
    this.broadcaster.postMessage<KeyValueEngineBroadcasterMessage>(this.broadcasterChannel, { key, newValue })
  }

  watch<K extends keyof KeyValueStoreType>(key: K, handler: () => void): void {
    this.watchers[key] = this.watchers[key] ?? []
    this.watchers[key]!.push(handler)
  }
}

import { KeyValueEngine } from './engine'
import { KeyValueStoreTypeBase } from './types'

export class ValueAccessor<KeyValueStoreType extends KeyValueStoreTypeBase, K extends keyof KeyValueStoreType> {
  private readonly subscriptions: (() => void)[] = []

  constructor(private readonly engine: KeyValueEngine<KeyValueStoreType>, private readonly key: K) {
    engine.watch(key, () => this.fire())
  }

  get(): KeyValueStoreType[K] {
    return this.engine.get(this.key)
  }

  set(newValue: KeyValueStoreType[K]): KeyValueStoreType[K] {
    const value = this.engine.get(this.key)
    if (newValue === value) return value
    Promise.resolve(this.engine.set(this.key, newValue)).then(() => this.fire())
    return newValue
  }

  reset(): void {
    this.engine.reset(this.key)
  }

  subscribe(subscription: () => void): void {
    this.subscriptions.push(subscription)
  }

  unsubscribe(subscription: () => void): void {
    const index = this.subscriptions.indexOf(subscription)
    if (index >= 0) {
      this.subscriptions.splice(index, 1)
    }
  }

  private fire(): void {
    this.subscriptions.forEach(subscription => subscription())
  }
}

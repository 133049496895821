import { useContexts } from '../../../../../modules/contexts'
import { ExtensionProviderContexts } from '../components/ExtensionProvider'
import { ExtensionManager } from '../types'

export function useExtensionManager(): { extensionManager: ExtensionManager } {
  const { extensionManager } = useContexts(ExtensionProviderContexts)

  return {
    extensionManager,
  }
}

import { Portal as MuiPortal } from '@material-ui/core'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { PropsWithChildren, useContext } from 'react'
import { HiddenContainerRefContext } from './HiddenContainerRefContext'

export function Portal({ container, hidden, children }: PropsWithChildren<ZettelExtensions.RenderedElement>) {
  const hiddenContainerRef = useContext(HiddenContainerRefContext)

  return <MuiPortal container={(!hidden && container) || hiddenContainerRef.current}>{children}</MuiPortal>
}

import { makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useContext } from 'react'
import { sendAnalyticEvent } from '../../../../../../../../../../../../modules/analytics'
import { useManageCommands, NavigableWithCommands } from '../../../../../../../../../../../../modules/commands'
import { DeviceProvider } from '../../../../../../../../../../../../modules/device'
import { commonKeyboardCombinations } from '../../../../../../../../../../../../modules/keyboard-handler'
import { usePersistent, PersistentKey } from '../../../../../../../../../../modules/persistent'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(4),
    },
    themeContainer: {
      display: 'inline-block',
      textAlign: 'center',
      cursor: 'pointer',
    },
    theme: {
      width: theme.spacing(36),
      maxWidth: '70vw',
      height: theme.spacing(22.5),
      boxSizing: 'border-box',
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(1.5),
    },
    light: {
      backgroundColor: '#FFFFFF',
    },
    dark: {
      backgroundColor: '#2F2F2F',
    },
    selected: {
      border: `${theme.spacing(0.5)}px solid ${theme.palette.secondary.main}`,
    },
  }),
  { name: 'ThemeSubPanel' }
)

export function ThemeSubPanel() {
  const { persistent } = usePersistent(PersistentKey.ThemeType)

  const { systemThemePaletteType } = useContext(DeviceProvider.Context)

  const { runCommandStatic } = useManageCommands()

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <NavigableWithCommands
        padding={2}
        commandGroup={({ navigableRef }) => ({
          name: 'systemTheme',
          commands: [
            {
              name: 'apply',
              defaultShortcutKeys: commonKeyboardCombinations.enter,
              handler() {
                persistent[PersistentKey.ThemeType] = 'system'
              },
            },
          ],
        })}
      >
        {({ connectNavigable }) => (
          <div
            ref={connectNavigable}
            className={classes.themeContainer}
            onClick={() => {
              sendAnalyticEvent('Theme', 'Change to system')
              runCommandStatic('systemTheme.apply')
            }}
          >
            <div
              className={classNames(
                classes.theme,
                classes[systemThemePaletteType],
                persistent[PersistentKey.ThemeType] === 'system' && classes.selected
              )}
            />
            <Typography variant="subtitle2">System</Typography>
          </div>
        )}
      </NavigableWithCommands>

      <NavigableWithCommands
        padding={2}
        commandGroup={({ navigableRef }) => ({
          name: 'lightTheme',
          commands: [
            {
              name: 'apply',
              defaultShortcutKeys: commonKeyboardCombinations.enter,
              handler() {
                persistent[PersistentKey.ThemeType] = 'light'
              },
            },
          ],
        })}
      >
        {({ connectNavigable }) => (
          <div
            ref={connectNavigable}
            className={classes.themeContainer}
            onClick={() => {
              sendAnalyticEvent('Theme', 'Change to light')
              runCommandStatic('lightTheme.apply')
            }}
          >
            <div
              className={classNames(
                classes.theme,
                classes.light,
                persistent[PersistentKey.ThemeType] === 'light' && classes.selected
              )}
            />
            <Typography variant="subtitle2">Light</Typography>
          </div>
        )}
      </NavigableWithCommands>

      <NavigableWithCommands
        padding={2}
        commandGroup={({ navigableRef }) => ({
          name: 'darkTheme',
          commands: [
            {
              name: 'apply',
              defaultShortcutKeys: commonKeyboardCombinations.enter,
              handler() {
                persistent[PersistentKey.ThemeType] = 'dark'
              },
            },
          ],
        })}
      >
        {({ connectNavigable }) => (
          <div
            ref={connectNavigable}
            className={classes.themeContainer}
            onClick={() => {
              sendAnalyticEvent('Theme', 'Change to dark')
              runCommandStatic('darkTheme.apply')
            }}
          >
            <div
              className={classNames(
                classes.theme,
                classes.dark,
                persistent[PersistentKey.ThemeType] === 'dark' && classes.selected
              )}
            />
            <Typography variant="subtitle2">Dark</Typography>
          </div>
        )}
      </NavigableWithCommands>
    </div>
  )
}

import { makeStyles, MenuItem, MenuItemProps } from '@material-ui/core'
import { Keyboard } from '@zettelooo/commons'
import classNames from 'classnames'
import { useCallback } from 'react'
import { useRefWrap } from '../../hooks/useRefWrap'
import { useKeyboardHandling, KeyboardCombinationsView } from '../../modules/keyboard-handler'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
    },
    gap: {
      flexGrow: 1,
      minWidth: theme.spacing(2),
    },
  }),
  { name: 'MenuItemWithKeyboardShortcut' }
)

export function MenuItemWithKeyboardShortcut({
  shortcutKeys,
  enableShortcutKeys,
  disabled,
  onClick,
  className,
  children,
  ...otherProps
}: MenuItemProps & {
  shortcutKeys: Keyboard.Combinations
  enableShortcutKeys?: boolean
  onClick?(event: KeyboardEvent | React.MouseEvent): void
}) {
  const onClickRef = useRefWrap(onClick)

  const memoizedHandler = useCallback(
    (event: KeyboardEvent): void => {
      if (disabled) return
      onClickRef.current?.(event)
    },
    [disabled]
  )

  useKeyboardHandling(
    [
      {
        combinations: shortcutKeys,
        handler: memoizedHandler,
      },
    ],
    [memoizedHandler],
    { disabled: !enableShortcutKeys }
  )

  const classes = useStyles()

  return (
    <MenuItem
      {...(otherProps as any)}
      className={classNames(classes.root, className)}
      disabled={disabled}
      onClick={memoizedHandler}
    >
      <div>{children}</div>
      <div className={classes.gap} />
      <KeyboardCombinationsView combinations={shortcutKeys} />
    </MenuItem>
  )
}

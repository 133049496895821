import { webConfig } from '../web-config'

export namespace PeekMode {
  export const data =
    webConfig.initialQuery.m === 'peek'
      ? ({
          accessToken: webConfig.initialQuery.at ?? '',
          refreshToken: webConfig.initialQuery.rt ?? '',
        } as const)
      : undefined
}

import { Model } from '@zettelooo/server-shared'
import { PropsWithChildren } from 'react'
import { useContexts } from '../../../../../../../modules/contexts'
import { useApplyAction } from '../../../../../hooks/useApplyAction'
import { useGenerateModelId } from '../../../../../hooks/useGenerateModelId'
import { SmartCommand } from '../../../../../modules/SmartCommand'
import { INITIAL_PAGE_EXTENSION_SETUP } from '../../../constants'
import { getPagePublicUrl } from '../../../helpers/getPagePublicUrl'
import { AccountData } from '../../../modules/account-data'
import { Panel, useOpenMainPage } from '../../pages/MainPage'

export function SmartCommandApiProvider({ children }: PropsWithChildren<{}>) {
  const { account, accountPagesOrdered, accountCardsOrdered } = useContexts(AccountData.Contexts)

  const { openMainPageStatic } = useOpenMainPage()

  const { applyActionStatic } = useApplyAction()

  const { generateModelIdStatic } = useGenerateModelId()

  const { smartCommandApi } = SmartCommand.useApiBuilder(
    {
      account,
      accountPagesOrdered,
      accountCardsOrdered,
    },
    contentRef => ({
      getCurrentUser: {
        signatures: ["(): { id: string, name: string } // Returns the current signed-in user's information"],
        function(): { id: string; name: string } {
          return {
            id: contentRef.current.account.id,
            name: contentRef.current.account.name,
          }
        },
      },

      getPageById: {
        signatures: [
          "(pageId: string): { id: string, name: string, iconEmoji: string, color: string } | undefined // Finds the page data by its ID and returns it. Page ID is different from page name or title. It returns undefined if such a page doesn't exist",
        ],
        function(pageId: string): { id: string; name: string; iconEmoji: string; color: string } | undefined {
          const targetPage = contentRef.current.accountPagesOrdered.find(page => page.id === pageId)
          return (
            targetPage && {
              id: targetPage.id,
              name: targetPage.name,
              iconEmoji: targetPage.iconEmoji,
              color: targetPage.color,
            }
          )
        },
      },

      createAndOpenPage: {
        signatures: [
          '(name: string, avatarEmoji: string, color: string): Promise<string> // Creates a page with the given name and avatarEmoji and color, then opens this new page, then returns its ID. Find a proper emoji character for avatarEmoji and a color if not described',
        ],
        async function(name: string, avatarEmoji: string, color: string): Promise<string> {
          const pageId = generateModelIdStatic()
          await applyActionStatic.createModel({
            type: Model.Type.Page,
            id: pageId,
            name,
            description: '',
            iconEmoji: avatarEmoji,
            avatarFileId: null,
            color,
            memberUserIds: [contentRef.current.account.id],
            public: false,
            ...INITIAL_PAGE_EXTENSION_SETUP,
          })
          openMainPageStatic({
            type: Panel.Type.Page,
            pageId,
            isNewPage: true,
          })
          return pageId
        },
      },

      publishPage: {
        signatures: [
          '(pageId: string): Promise<string> // Makes the page public to every one and returns the link to the published page',
        ],
        async function(pageId: string): Promise<string> {
          const upsertModelAction = await applyActionStatic.updateModel({
            type: Model.Type.Page,
            id: pageId,
            public: true,
          })
          return getPagePublicUrl(upsertModelAction.newModel as Model.Page)
        },
      },

      deletePage: {
        signatures: ['(pageId: string): Promise<void> // Deletes the specified page'],
        async function(pageId: string): Promise<void> {
          await applyActionStatic.deleteModel({
            type: Model.Type.Page,
            id: pageId,
          })
        },
      },

      getPageCardIds: {
        signatures: [
          '(pageId: string): string[] // Gets all the card IDs of the specified page in order of appearance',
        ],
        function(pageId: string): string[] {
          return contentRef.current.accountCardsOrdered.filter(card => card.pageId === pageId).map(card => card.id)
        },
      },

      deleteCard: {
        signatures: ['(cardId: string): Promise<void> // Deletes the specified card'],
        async function(cardId: string): Promise<void> {
          await applyActionStatic.deleteModel({
            type: Model.Type.Card,
            id: cardId,
          })
        },
      },
    }),
    []
  )

  return <SmartCommand.ApiProvider api={smartCommandApi}>{children}</SmartCommand.ApiProvider>
}

import { Id } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { convertCardModelToCardPublicModel, convertPageModelToPagePublicModel, Model } from '@zettelooo/server-shared'
import { useCallback } from 'react'
import { useGetSet } from 'react-use'
import { arrayHelpers } from '../../../../../helpers/native/arrayHelpers'
import { useRefWrap } from '../../../../../hooks/useRefWrap'
import { Run } from '../../../../Run'
import { useExtensionLifeSpan, useExtensionManager } from '../../../modules/extension'
import { PersistentKey, usePersistent } from '../../../modules/persistent'

interface ProvidedService {
  readonly extensionId: Id
  readonly serviceName: string
  readonly service: (requestData: any) => any | Promise<any>
}

export function useExtensionPageLifeSpan({
  page,
  cards,
}: {
  page: Model.Page | undefined
  cards: readonly Model.Card[]
}): {
  renderExtensionLifeSpan(): React.JSX.Element | null
} {
  const [, deviceId, authentication] = usePersistent(PersistentKey.DeviceId, PersistentKey.Authentication)

  const [getProvidedServices, setProvidedServices] = useGetSet<readonly ProvidedService[]>([])

  const { extensionManager } = useExtensionManager()

  const contentRef = useRefWrap({
    page,
    extensionManager,
  })

  return {
    renderExtensionLifeSpan() {
      if (!page) return null

      return (
        <Run key="pageLifeSpan">
          {() =>
            useExtensionLifeSpan({
              name: 'page',
              target: {
                pageId: page.id,
              },
              scopedValues: {
                [ZettelExtensions.Scope.Device]: deviceId,
                [ZettelExtensions.Scope.User]: authentication?.decodedAccessToken.userId ?? '',
                [ZettelExtensions.Scope.Page]: page.id,
              },
              dataFactories: {
                page: useCallback(({ header }) => convertPageModelToPagePublicModel(page, header.id), [page]),
                cards: useCallback(
                  ({ header }) => cards.map(card => convertCardModelToCardPublicModel(card, header.id)),
                  [cards]
                ),
              },
              accessFactory: ({ header }) => ({
                consumeService(name) {
                  const matchingServiceBinding = contentRef.current.page?.extensionConfiguration.serviceBindings.find(
                    serviceBinding =>
                      serviceBinding.consumingExtensionId === header.id && serviceBinding.consumingServiceName === name
                  )
                  if (!matchingServiceBinding) return null
                  const matchingProvidedService = getProvidedServices().find(
                    providedService =>
                      providedService.extensionId === matchingServiceBinding.providingExtensionId &&
                      providedService.serviceName === matchingServiceBinding.providingServiceName
                  )
                  if (!matchingProvidedService) return null
                  return async requestData => {
                    const convertRequestData =
                      contentRef.current.extensionManager
                        .getExecutables(header.id)
                        ?.services?.consumes?.find(service => service.name === name)
                        ?.bindings?.find(
                          binding =>
                            binding.extensionId === matchingServiceBinding.providingExtensionId &&
                            binding.serviceName === matchingServiceBinding.providingServiceName
                        )?.convertRequestData ??
                      contentRef.current.extensionManager
                        .getExecutables(matchingServiceBinding.providingExtensionId)
                        ?.services?.consumes?.find(
                          service => service.name === matchingServiceBinding.providingServiceName
                        )
                        ?.bindings?.find(binding => binding.extensionId === header.id && binding.serviceName === name)
                        ?.convertRequestData
                    const convertResponseData =
                      contentRef.current.extensionManager
                        .getExecutables(header.id)
                        ?.services?.consumes?.find(service => service.name === name)
                        ?.bindings?.find(
                          binding =>
                            binding.extensionId === matchingServiceBinding.providingExtensionId &&
                            binding.serviceName === matchingServiceBinding.providingServiceName
                        )?.convertResponseData ??
                      contentRef.current.extensionManager
                        .getExecutables(matchingServiceBinding.providingExtensionId)
                        ?.services?.consumes?.find(
                          service => service.name === matchingServiceBinding.providingServiceName
                        )
                        ?.bindings?.find(binding => binding.extensionId === header.id && binding.serviceName === name)
                        ?.convertResponseData
                    return convertResponseData?.(
                      await matchingProvidedService.service(convertRequestData?.(requestData))
                    )
                  }
                },
              }),
              registryFactory: ({ header }) => ({
                serviceProvider(name, service) {
                  return () => {
                    const providedService: ProvidedService = {
                      extensionId: header.id,
                      serviceName: name,
                      service,
                    }
                    setProvidedServices([...getProvidedServices(), providedService])
                    return () => setProvidedServices(arrayHelpers.remove(getProvidedServices(), providedService))
                  }
                },
              }),
              dependencies: [deviceId, authentication?.decodedAccessToken.userId, page.id],
            })
          }
        </Run>
      )
    },
  }
}

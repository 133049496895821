// It's under control, we need to get user data as many times as the user insert them correctly, no performance problem here:
/* eslint-disable no-await-in-loop */

// import Torus from '@toruslabs/torus-embed'
// import WalletConnectProvider from '@walletconnect/web3-provider'
import { captureException, captureMessage, startTransaction, withScope } from '@sentry/react'
import { IpcChannel, MainIpcMessageType } from '@zettelooo/desktop-shared'
import { ethers } from 'ethers'
import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import Web3Modal from 'web3modal'
import { webConfig } from '../../../../../../../modules/web-config'
import { sendIpcMessage } from '../../../../../modules/electron'
import { useServices } from '../../../../../modules/services'
import { routes } from '../../../modules/routes'
import { FinishSignIn } from './useFinishSignIn'
import { NewUserDataControl } from './useNewUserData'
import { Query } from './useQuery'
import { SubscriptionCheckControl } from './useSubscriptionCheck'

export function useWeb3SignIn({
  query,
  newUserDataControl,
  subscriptionCheckControl,
  finishSignIn,
}: {
  query: Query
  newUserDataControl: NewUserDataControl
  subscriptionCheckControl: SubscriptionCheckControl
  finishSignIn: FinishSignIn
}): {
  web3: {
    signIn(): Promise<void>
    signingIn: boolean
    notMainnet: boolean
  }
} {
  const [signingIn, setSigningIn] = useState(false)
  const [notMainnet, setNotMainnet] = useState(false)

  const { services } = useServices()

  useEffectOnce(() => {
    if (query.isForDesktop && query.isWeb3SigningIn) {
      signIn()
    }
  })

  async function signIn(): Promise<void> {
    if (webConfig.environment.agent === 'electron') {
      sendIpcMessage(IpcChannel.Main, {
        type: MainIpcMessageType.MainRequestSignIn,
        url: `${webConfig.origin}${routes.signIn.getPath({}, { agent: 'electron', method: 'web3' })}`,
      })
      return
    }

    const transaction = startTransaction({ op: 'web3-sign-in', name: 'Web3 Sign-in' })
    try {
      setSigningIn(true)
      const web3Modal = new Web3Modal({
        network: 'mainnet',
        cacheProvider: false,
        providerOptions: {
          // torus: {
          //  package: Torus,
          // },
          // walletconnect: {
          //  package: WalletConnectProvider,
          //  options: {
          //    infuraId: webConfig.web3.signIn.infuraId,
          //  },
          // },
        },
      })
      const web3ModalConnectionSpan = transaction.startChild({ op: 'web3-modal-connection' })
      const connection = await web3Modal.connect()
      const provider = new ethers.providers.Web3Provider(connection)
      const network = await provider.getNetwork()
      web3ModalConnectionSpan.finish()
      if (network.chainId !== 1) {
        setNotMainnet(true)
        throw Error('The selected network is not Mainnet.')
      }
      const listAccountsSpan = transaction.startChild({ op: 'list-accounts' })
      const walletAddresses = await provider.listAccounts()
      const [walletAddress] = walletAddresses
      listAccountsSpan.finish()
      const signInSpan = transaction.startChild({ op: 'sign-in' })
      const { nonceMessage } = await services.account.walletSignInAuthenticate(walletAddress, signInSpan)
      const signer = provider.getSigner()
      const signedNonceMessage = await signer.signMessage(nonceMessage!)
      const { userTokens } = await services.account.walletSignInVerigy(
        walletAddress,
        nonceMessage!,
        signedNonceMessage,
        signInSpan
      )
      signInSpan.finish()
      const getAccountStatusSpan = transaction.startChild({ op: 'get-account-status-in' })
      const { accountStatus } = await services.account.getAccountStatus(userTokens.accessToken, getAccountStatusSpan)
      getAccountStatusSpan.finish()
      if (accountStatus.neededFields.length > 0) {
        const newUserDataSpan = transaction.startChild({ op: 'new-user-data' })
        const { canceled } = await newUserDataControl.submit(
          userTokens.accessToken,
          accountStatus.neededFields,
          newUserDataSpan
        )
        newUserDataSpan.finish()
        if (canceled) return
      }
      if (accountStatus.subscription === 'none') {
        const subscriptionCheckSpan = transaction.startChild({ op: 'subscription-check' })
        const { canceled } = await subscriptionCheckControl.subscribe(
          userTokens.accessToken,
          userTokens.refreshToken,
          subscriptionCheckSpan
        )
        subscriptionCheckSpan.finish()
        if (canceled) return
      }
      finishSignIn(userTokens.accessToken, userTokens.refreshToken)
    } catch (error: any) {
      log.error(error)
      withScope(scope => {
        scope.setSpan(transaction)
        // Check if the error is either one of these:
        // Provider error, see: https://eips.ethereum.org/EIPS/eip-1193#provider-errors
        // RPC error, see: https://eips.ethereum.org/EIPS/eip-1474#error-codes
        if (
          typeof error.code === 'number' &&
          Number.isInteger(error.code) &&
          ((error.code >= 4000 && error.code <= 4099) || (error.code >= -32767 && error.code <= -32000))
        ) {
          captureMessage(String(error), { tags: { module: 'sign in' } })
        } else {
          captureException(error, { tags: { module: 'sign in' } })
        }
      })
    } finally {
      setSigningIn(false)
      transaction.finish()
    }
  }

  return {
    web3: {
      signIn,
      signingIn,
      notMainnet,
    },
  }
}

import { ExtensionTargetRoles } from './types'

export function checkTargetRolesCompatibility(
  containingTargetRoles: ExtensionTargetRoles,
  evaluatingTargetRoles: ExtensionTargetRoles
): boolean {
  return (
    Object.keys(evaluatingTargetRoles.target).every(
      key =>
        !(key in containingTargetRoles.target) ||
        containingTargetRoles.target[key] === evaluatingTargetRoles.target[key]
    ) && containingTargetRoles.roles.every((role, index) => evaluatingTargetRoles.roles[index] === role)
  )
}

import { makeStyles, Typography } from '@material-ui/core'
import { forwardRef } from 'react'
import { KeyboardCombinationView } from '../../../../../../../../../../modules/keyboard-handler'
import { webConfig } from '../../../../../../../../../../modules/web-config'
import { PanelLayout } from '../PanelLayout'
import { ChromeExtensionPromotionBanner } from './ChromeExtensionPromotionBanner'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      overflow: 'hidden',
    },
    message: {},
  }),
  { name: 'EmptyPanel' }
)

export const EmptyPanel = forwardRef<HTMLDivElement, {}>(function EmptyPanel({}, ref) {
  const classes = useStyles()

  return (
    <PanelLayout ref={ref} className={classes.root}>
      <Typography variant="subtitle1" color="textSecondary" className={classes.message}>
        {webConfig.environment.agent === 'browser' ? (
          <span>Please select a page to start</span>
        ) : (
          <span>
            Start capturing using <KeyboardCombinationView combination={{ cmd: true, code: 'Space' }} />
          </span>
        )}
      </Typography>

      {!webConfig.temporary.temporarilyDisabledOrToBeRemoved && <ChromeExtensionPromotionBanner />}
    </PanelLayout>
  )
})

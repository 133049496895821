import { Model } from '@zettelooo/server-shared'
import { PropsWithChildren, useEffect } from 'react'
import { useContexts } from '../../../../../../../../../../modules/contexts'
import { SmartCommand } from '../../../../../../../../modules/SmartCommand'
import { MainPage } from '../../../MainPage'

export function SmartCommandApiProvider({ page, children }: PropsWithChildren<{ page: Model.Page }>) {
  const {
    refs: { setOverridingSmartCommandApi },
  } = useContexts(MainPage.Contexts)

  const { smartCommandApi } = SmartCommand.useApiBuilder(
    {
      page,
    },
    contentRef => ({
      getOpenedPageId: {
        signatures: ["(): string // Get currently opened page's ID"],
        function(): string {
          return contentRef.current.page.id
        },
      },
    }),
    []
  )

  useEffect(() => {
    setOverridingSmartCommandApi(smartCommandApi)
    return () => setOverridingSmartCommandApi(undefined)
  }, [smartCommandApi])

  return <SmartCommand.ApiProvider api={smartCommandApi}>{children}</SmartCommand.ApiProvider>
}

import { LanguagePack } from '../types'

export const en: LanguagePack = {
  language: {
    code: 'en',
    name: 'English',
    direction: 'ltr',
  },
  resource: {
    translation: {
      title: 'The main title',
      description: 'Them main description',
    },
  },
}

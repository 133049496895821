import { PartialRecord, Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { Index } from 'flexsearch'
import { createContext, PropsWithChildren, useMemo } from 'react'
import { arrayHelpers } from '../../../../../../helpers/native/arrayHelpers'
import { useSyncEffect } from '../../../../../../hooks/useSyncEffect'
import { useContexts } from '../../../../../../modules/contexts'
import { MemoizedContextProvider } from '../../../../../MemoizedContextProvider'
import { AccountData } from '../account-data'
import { useGetCardText } from '../card'

export function SearchProvider({ children }: PropsWithChildren<{}>) {
  const { accountPagesOrdered, accountCardsOrdered } = useContexts(AccountData.Contexts)

  const { getCardText } = useGetCardText()

  const flexSearchIndexStatic = useMemo<SearchProvider.FlexSearchIndex>(
    () => ({
      pages: new Index({ tokenize: 'full' }),
      cards: new Index({ tokenize: 'full' }),
      files: new Index({ tokenize: 'full' }),
    }),
    []
  )

  const cardSearchContentsDictionaryStatic = useMemo<PartialRecord<Id, SearchProvider.CardSearchContent>>(
    () => ({}),
    []
  )

  const accountPagesDictionary = useMemo(
    () => arrayHelpers.toDictionaryById(accountPagesOrdered),
    [accountPagesOrdered]
  )

  useSyncEffect(
    (_, previousDependencies) => {
      accountPagesOrdered.forEach(page => {
        if (page !== previousDependencies?.[1][page.id]) {
          flexSearchIndexStatic.pages.add(page.id, page.name)
        }
      })

      previousDependencies?.[0].forEach(page => {
        if (!(page.id in accountPagesDictionary)) {
          flexSearchIndexStatic.pages.remove(page.id)
        }
      })
    },
    [accountPagesOrdered, accountPagesDictionary] as const
  )

  useSyncEffect(() => {
    const accountCardIds: Record<Id, true> = {}

    accountCardsOrdered.forEach(card => {
      accountCardIds[card.id] = true

      if (card !== cardSearchContentsDictionaryStatic[card.id]?.card) {
        const cardSearchContent: SearchProvider.CardSearchContent = {
          card,
          text: getCardText(card) ?? '',
          files: '', // TODO: card.files.map(file => file.name).join('\n'), // There is no card files anymore
        }

        flexSearchIndexStatic.cards.add(card.id, cardSearchContent.text)
        flexSearchIndexStatic.files.add(card.id, cardSearchContent.files)

        cardSearchContentsDictionaryStatic[card.id] = cardSearchContent
      }
    })

    Object.keys(cardSearchContentsDictionaryStatic).forEach(cardId => {
      if (!(cardId in accountCardIds)) {
        flexSearchIndexStatic.cards.remove(cardId)
        flexSearchIndexStatic.files.remove(cardId)

        delete cardSearchContentsDictionaryStatic[cardId]
      }
    })
  }, [accountCardsOrdered])

  return (
    <MemoizedContextProvider
      context={SearchProvider.Context}
      value={{
        flexSearchIndexStatic,
        cardSearchContentsDictionaryStatic,
      }}
      dependencies={[]}
    >
      {children}
    </MemoizedContextProvider>
  )
}

export namespace SearchProvider {
  export const Context = createContext<{
    flexSearchIndexStatic: SearchProvider.FlexSearchIndex
    cardSearchContentsDictionaryStatic: PartialRecord<Id, SearchProvider.CardSearchContent>
  }>(undefined as any)

  export interface FlexSearchIndex {
    readonly pages: Index
    readonly cards: Index
    readonly files: Index
  }

  export interface CardSearchContent {
    readonly card: Model.Card
    readonly text: string
    readonly files: string
  }
}

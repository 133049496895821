import { useContext } from 'react'
import { ServicesProvider } from './ServicesProvider'
import { Services } from './types'

export function useServices(): {
  services: Services
} {
  const services = useContext(ServicesProvider.Context)

  return {
    services,
  }
}

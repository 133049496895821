import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import Color from 'color'
import {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  RefCallback,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { NativeDragObject, useDrop } from '../../../../../modules/drag-and-drop'

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
    },
    active: {
      minHeight: theme.spacing(13),
      '& $highlight': {
        display: 'block',
      },
    },
    highlight: {
      display: 'none',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: theme.zIndex.mobileStepper,
      pointerEvents: 'none',
      userSelect: 'none',
      overflow: 'hidden',
      border: `${theme.spacing(0.25)}px dashed ${theme.palette.secondary.main}`,
      borderRadius: theme.spacing(1),
      backgroundColor: new Color(theme.palette.secondary.main).alpha(theme.palette.action.selectedOpacity).string(),
    },
    isDraggingOver: {
      backgroundColor: new Color(theme.palette.secondary.main).alpha(theme.palette.action.focusOpacity).string(),
    },
    highlightInfo: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '100%',
      flexDirection: 'column',
      textAlign: 'center',
      padding: theme.spacing(2),
    },
  }),
  { name: 'Dropzone' }
)

export const Dropzone = forwardRef<
  HTMLDivElement,
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    visible?: boolean
    disabled?: boolean
    icon?: React.JSX.Element
    message?: React.JSX.Element
    activeClassName?: string
    highlightClassName?: string
    onDroppedFiles?: (items: DataTransferItemList) => void
    onDroppedHtml?: (html: string) => void
    onDroppedText?: (text: string) => void
    onDroppedUrls?: (urls: readonly string[]) => void
  }
>(function Dropzone(
  {
    visible,
    disabled,
    icon,
    message,
    activeClassName,
    highlightClassName,
    onDroppedFiles,
    onDroppedHtml,
    onDroppedText,
    onDroppedUrls,
    className,
    children,
    ...otherProps
  },
  ref
) {
  const rootRef = useRef<HTMLDivElement | null>(null)

  const [notUnderBackdrop, setNotUnderBackdrop] = useState(false)
  const [isDraggingOver, setIsDraggingOver] = useState(false)

  const active = visible && !disabled
  const activeAndNotUnderBackdrop = active && notUnderBackdrop

  useImperativeHandle(ref, () => rootRef.current!, [])

  const { connectDropTarget } = useDrop<
    NativeDragObject.File | NativeDragObject.Html | NativeDragObject.Text | NativeDragObject.Url,
    void
  >({
    check(event, dragObject) {
      switch (dragObject.type) {
        case NativeDragObject.Type.File:
          return onDroppedFiles ? 'allowed' : 'denied'

        case NativeDragObject.Type.Html:
          return onDroppedHtml || onDroppedText ? 'allowed' : 'denied'

        case NativeDragObject.Type.Text:
          return onDroppedText ? 'allowed' : 'denied'

        case NativeDragObject.Type.Url:
          return onDroppedUrls || onDroppedHtml || onDroppedText ? 'allowed' : 'denied'
      }
    },
    drag(event, dragObject, { accepted }) {
      switch (dragObject.type) {
        case NativeDragObject.Type.File:
        case NativeDragObject.Type.Html:
        case NativeDragObject.Type.Text:
        case NativeDragObject.Type.Url:
          setIsDraggingOver(accepted)
          return 'copy'
      }
    },
    drop(event, dragObject, drop) {
      switch (dragObject.type) {
        case NativeDragObject.Type.File:
          onDroppedFiles?.(dragObject.items)
          break

        case NativeDragObject.Type.Html:
          if (onDroppedHtml) {
            onDroppedHtml(dragObject.html)
          } else if (dragObject.text && onDroppedText) {
            onDroppedText(dragObject.text)
          }
          break

        case NativeDragObject.Type.Text:
          onDroppedText?.(dragObject.text)
          break

        case NativeDragObject.Type.Url:
          if (onDroppedUrls) {
            onDroppedUrls(dragObject.urls)
          } else if (onDroppedHtml) {
            onDroppedHtml(dragObject.urls.map(url => `<p><a href="${url}">${url}</a></p>`).join('\n'))
          } else if (onDroppedText) {
            onDroppedText(dragObject.urls.join('\n'))
          }
          break
      }
    },
    disabled: !activeAndNotUnderBackdrop,
  })

  // TODO: Any active Backdrop (Like context menu, dialog, tooltip, or so)
  // prevents file drop to work. So, we need to detect if this element
  // is being rendered under an active Backdrop or not. The implementation
  // below is a hacky one and depends on the internal implementation of
  // Material-UI. Make sure it won't break on any version update, or find
  // a more dependable solution.
  useLayoutEffect(() => {
    if (active) {
      let rootAncestorElement: HTMLElement = rootRef.current!
      while (rootAncestorElement.parentElement && rootAncestorElement.parentElement !== window.document.body) {
        rootAncestorElement = rootAncestorElement.parentElement
      }
      for (
        let rootAncestorSiblingElement = rootAncestorElement.nextElementSibling;
        rootAncestorSiblingElement;
        rootAncestorSiblingElement = rootAncestorSiblingElement.nextElementSibling
      ) {
        // Next we pinpoint the MUI Backdrop elements:
        if (rootAncestorSiblingElement.constructor === HTMLDivElement) {
          const rootAncestorSiblingDivElement = rootAncestorSiblingElement as HTMLDivElement
          if (
            rootAncestorSiblingDivElement.getAttribute('role') === 'presentation' &&
            rootAncestorSiblingDivElement.style.position === 'fixed' &&
            rootAncestorSiblingDivElement.style.visibility !== 'hidden'
          ) {
            setNotUnderBackdrop(false)
            return
          }
        }
      }
      setNotUnderBackdrop(true)
    } else {
      setNotUnderBackdrop(false)
    }
  }, [active])

  const rootRefCallback = useCallback<RefCallback<HTMLDivElement>>(
    rootElement => {
      rootRef.current = rootElement
      connectDropTarget(activeAndNotUnderBackdrop ? rootElement : null)
    },
    [activeAndNotUnderBackdrop]
  )

  const classes = useStyles()

  return (
    <div
      {...otherProps}
      ref={rootRefCallback}
      className={classNames(
        classes.root,
        activeAndNotUnderBackdrop && classes.active,
        className,
        activeAndNotUnderBackdrop && activeClassName
      )}
    >
      {children}

      <div className={classNames(classes.highlight, isDraggingOver && classes.isDraggingOver, highlightClassName)}>
        <div className={classes.highlightInfo}>
          {icon}
          {message}
        </div>
      </div>
    </div>
  )
})

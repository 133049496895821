import { ZettelExtensions } from '@zettelooo/extension-api'
import { referenceKey } from './referenceKey'
import { stateAndListenersMap } from './stateAndListenersMap'
import { WithReferenceKey } from './withReferenceKey'

export function addListener<S>(
  htmlContentWithReferenceKey: WithReferenceKey<ZettelExtensions.HtmlContent<S>>,
  listener: (newState: S) => void
): void {
  const referenceValue = htmlContentWithReferenceKey[referenceKey]
  const stateAndListeners = stateAndListenersMap.get(referenceValue)
  if (stateAndListeners) {
    stateAndListeners.listeners.push(listener)
  }
}

import { ZettelExtensions } from '@zettelooo/extension-api'
import { referenceKey } from './referenceKey'
import { stateAndListenersMap } from './stateAndListenersMap'
import { WithReferenceKey } from './withReferenceKey'

export function getState<S>(htmlContentWithReferenceKey: WithReferenceKey<ZettelExtensions.HtmlContent<S>>): S {
  const referenceValue = htmlContentWithReferenceKey[referenceKey]
  const stateAndListeners = stateAndListenersMap.get(referenceValue)
  const state = stateAndListeners ? stateAndListeners.state : htmlContentWithReferenceKey.initialState
  return state
}

import { ReadonlyRecord } from '@zettelooo/commons'
import { AiServiceSignature } from '@zettelooo/server-shared'
import { DemoMode } from '../../../../../modules/demo-mode'
import { PeekMode } from '../../../../../modules/peek-mode'
import { Persistent } from '../../persistent'
import { RestService } from './RestService'

export class AiService extends RestService {
  constructor(persistent: Persistent) {
    super('ai', persistent)
  }

  async openAiChatGpt(request: AiServiceSignature.OpenAiChatGpt.Request): Promise<string> {
    if (DemoMode.data || PeekMode.data) return ''

    const { answer } = await this.requestAuthenticated<
      AiServiceSignature.OpenAiChatGpt.Request,
      AiServiceSignature.OpenAiChatGpt.Response
    >('open-ai-chat-gpt', request)

    return answer
  }

  async smartCommand(
    api: ReadonlyRecord<string, AiServiceSignature.SmartCommand.Action>,
    command: string
  ): Promise<{ code: string | null }> {
    if (DemoMode.data || PeekMode.data) return { code: null }

    const { code } = await this.requestAuthenticated<
      AiServiceSignature.SmartCommand.Request,
      AiServiceSignature.SmartCommand.Response
    >('smart-command', { api, command })

    return {
      code,
    }
  }
}

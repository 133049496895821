import { Id } from '@zettelooo/commons'
import { NavigableStatus, NavigationState } from '../types'

export function getNavigableStatus(navigableId: Id, navigationState: NavigationState): NavigableStatus {
  const index = navigationState.navigatedPath.indexOf(navigableId)
  if (index < 0) return NavigableStatus.NotNavigated
  if (index < navigationState.navigatedPath.length - 1) return NavigableStatus.NavigatedThrough
  if (!navigationState.selected) return NavigableStatus.NavigatedTo
  return NavigableStatus.Selected
}

import { makeStyles, Paper, Typography } from '@material-ui/core'
import { IpcChannel, MainIpcMessageType } from '@zettelooo/desktop-shared'
import { useState } from 'react'
import { useAsyncEffect } from '../../../../../../hooks/useAsyncEffect'
import { ChromeExtensionMode } from '../../../../../../modules/chrome-extension-mode'
import { webConfig } from '../../../../../../modules/web-config'
import { Gap } from '../../../../../Gap'
import { sendIpcMessage } from '../../../../modules/electron'
import { PersistentKey, usePersistent } from '../../../../modules/persistent'
import { useServices } from '../../../../modules/services'
import { AutomaticUpdater } from '../../../../modules/update'
import { Anchor } from '../../../Anchor'
import { convertUrlToAnchorHref } from '../../helpers/convertUrlToAnchorHref'
import { routes } from '../../modules/routes'

const useStyles = makeStyles(
  theme => ({
    root: {
      height: '100vh',
      padding: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      width: '100%',
      maxWidth: theme.spacing(100),
      minHeight: theme.spacing(50),
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(2),
    },
  }),
  { name: 'NotSubscribedPage' }
)

export function NotSubscribedPage() {
  const [, authentication] = usePersistent(PersistentKey.Authentication)

  const [gettingSubscriptionLinkUrl, setGettingSubscriptionLinkUrl] = useState(false)

  const { services } = useServices()

  useAsyncEffect(
    async isMounted => {
      if (!gettingSubscriptionLinkUrl) return
      try {
        const { subscriptionLinkUrl } = await services.account.getSubscriptionLink(
          authentication?.accessToken ?? '',
          authentication?.refreshToken ?? '',
          webConfig.environment.agent
        )
        if (!isMounted() || !subscriptionLinkUrl) return
        if (ChromeExtensionMode.isActive) {
          window.open(subscriptionLinkUrl, 'Subscription', 'width=600,height=850')
          setGettingSubscriptionLinkUrl(false)
        } else if (webConfig.environment.agent === 'browser') {
          window.location.href = subscriptionLinkUrl
        } else if (webConfig.environment.agent === 'electron') {
          sendIpcMessage(IpcChannel.Main, {
            type: MainIpcMessageType.MainRequestSignIn,
            url: subscriptionLinkUrl,
          })
          setGettingSubscriptionLinkUrl(false)
        }
      } catch (error) {
        console.error('Get Subscription Link Url:', error)
        if (!isMounted()) return
        setGettingSubscriptionLinkUrl(false)
      }
    },
    [gettingSubscriptionLinkUrl]
  )

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AutomaticUpdater />

      <Paper className={classes.content}>
        {gettingSubscriptionLinkUrl ? (
          <Typography variant="h6">Redirecting to the subscription page...</Typography>
        ) : (
          <>
            <Typography variant="h6">You don't have an active subscription.</Typography>

            <Gap vertical={4} />

            <div>
              <Typography variant="subtitle1">Subscribe to:</Typography>
              <Typography variant="subtitle1">
                <Anchor highlight onClick={() => setGettingSubscriptionLinkUrl(true)}>
                  Basic Plan
                </Anchor>
                : 4.99 USD/month, 14 day free trial, Cancel any time.
              </Typography>

              <Gap vertical={4} />

              <Typography variant="subtitle1">
                Request a{' '}
                <Anchor highlight href={convertUrlToAnchorHref(webConfig.supportEmail)}>
                  data backup
                </Anchor>{' '}
                or sign-in with a{' '}
                <Anchor highlight href={routes.signOut.getPath({}, {})}>
                  different account
                </Anchor>
                .
              </Typography>
            </div>
          </>
        )}
      </Paper>
    </div>
  )
}

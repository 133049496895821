import { captureException, getCurrentHub } from '@sentry/react'
import { delay } from '@zettelooo/commons'
import { PropsWithChildren, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { webConfig } from '../web-config'
import { useWindowDev } from '../window-dev'
import { ContentContext } from './ContentContext'
import { data } from './data'
import { Content } from './types'

export function Provider({ children }: PropsWithChildren<{}>) {
  const [content, setContent] = useState<Content>()

  useEffectOnce(() => {
    void (async function fetchContent(): Promise<void> {
      if (!data) return

      try {
        const response = await fetch(`${webConfig.services.baseUrls.privateApi.rest}/static/demo.json`) // It may have parameters from `data`
        const content: Content = await response.json()
        setContent(content)
      } catch (error) {
        log.error('Fetch demo content', error)
        captureException(error, { tags: { module: 'demo' } })
        await delay(10 * 1000)
        fetchContent()
      }
    })()
  })

  useWindowDev(
    () => ({
      demo: data
        ? {
            data,
            content,
          }
        : null,
    }),
    [content]
  )

  // Enable sentry iff we're not in the Demo mode:
  const sentryOptions = getCurrentHub().getClient()?.getOptions()
  const sentryEnabled = !data
  if (sentryOptions && sentryOptions.enabled !== sentryEnabled) {
    sentryOptions.enabled = sentryEnabled
  }

  return <ContentContext.Provider value={content}>{children}</ContentContext.Provider>
}

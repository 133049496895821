import { Id } from '@zettelooo/commons'

export type Panel<T extends Panel.Type = Panel.Type> = {
  [Panel.Type.Settings]: {
    readonly type: Panel.Type.Settings
    readonly subType: Panel.Settings.Type
  }
  [Panel.Type.Page]: {
    readonly type: Panel.Type.Page
    readonly pageId: Id
    readonly cardId?: Id
    readonly isNewPage?: boolean
  }
  [Panel.Type.NewPage]: {
    readonly type: Panel.Type.NewPage
  }
  [Panel.Type.Activity]: {
    readonly type: Panel.Type.Activity
  }
}[T]

export namespace Panel {
  // The values are the same as the URL parameter:
  export enum Type {
    Settings = 'settings',
    Page = 'page',
    NewPage = 'new-page',
    Activity = 'activity',
  }

  export type Params<T extends Type = Type> = {
    [Type.Settings]: [subPanelType: Settings.Type]
    [Type.Page]: [pagePublicId: Id]
    [Type.NewPage]: []
    [Type.Activity]: []
  }[T]

  export type Query<T extends Type = Type> = {
    [Type.Settings]: {}
    [Type.Page]: { readonly c?: Id | undefined; readonly n?: 'true' | undefined }
    [Type.NewPage]: {}
    [Type.Activity]: {}
  }[T]

  export namespace Settings {
    // The values are the same as the URL parameter:
    export enum Type {
      Account = 'account',
      Theme = 'theme',
      Desktop = 'launcher',
      Api = 'api',
      Subscription = 'subscription',
      About = 'about',
    }
  }
}

import { Divider, List, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import { Id } from '@zettelooo/commons'
import classNames from 'classnames'
import { useContext } from 'react'
import { useContextMenu } from '../../../../../../../../../../hooks/useContextMenu'
import { sendAnalyticEvent } from '../../../../../../../../../../modules/analytics'
import { useManageCommands } from '../../../../../../../../../../modules/commands'
import { DeviceProvider } from '../../../../../../../../../../modules/device'
import { webConfig } from '../../../../../../../../../../modules/web-config'
import { CustomAvatar } from '../../../../../../../../../CustomAvatar'
import { MenuItemWithKeyboardShortcut } from '../../../../../../../../../menu/MenuItemWithKeyboardShortcut'
import { TypographyNoWrap } from '../../../../../../../../../TypographyNoWrap'
import { Anchor } from '../../../../../../../Anchor'

const useStyles = makeStyles(
  theme => ({
    paper: {
      width: theme.spacing(31.25),
    },
  }),
  { name: 'ContextMenu' }
)

export function ContextMenu({
  menuProps,
  closeMenu,
  userAvatarFileId,
  userDisplayName,
  color,
}: {
  menuProps: ReturnType<typeof useContextMenu>['menuProps']
  closeMenu: () => void
  userAvatarFileId: Id | null
  userDisplayName: string
  color: string
}) {
  const { isMobile } = useContext(DeviceProvider.Context)

  const { runCommandStatic } = useManageCommands()

  const classes = useStyles()

  return (
    <Menu
      {...menuProps}
      classes={{
        ...menuProps.classes,
        paper: classNames(classes.paper, menuProps.classes?.paper),
      }}
      MenuListProps={{ disablePadding: true }}
    >
      <List>
        <MenuItem
          onClick={() => {
            sendAnalyticEvent('Title Bar (Account Context Menu)', 'Click on Account name')
            runCommandStatic('accountItem.closeMenu')
          }}
        >
          <CustomAvatar
            size={3.5}
            avatarFileId={userAvatarFileId}
            name={userDisplayName}
            color={color}
            margin={[0, 2, 0, 0]}
          />
          <Typography variant="subtitle2" color="textSecondary" noWrap>
            {userDisplayName}
          </Typography>
        </MenuItem>
      </List>

      <Divider />

      <List>
        <MenuItemWithKeyboardShortcut
          shortcutKeys={{ cmd: true, code: 'Comma' }}
          onClick={() => {
            sendAnalyticEvent('Title Bar (Account Context Menu)', 'Click on Settings')
            runCommandStatic('accountItem.openSettings')
          }}
        >
          <TypographyNoWrap variant="subtitle2">Settings</TypographyNoWrap>
        </MenuItemWithKeyboardShortcut>

        <MenuItem
          onClick={() => {
            sendAnalyticEvent('Title Bar (Account Context Menu)', 'Click on Subscription')
            runCommandStatic('accountItem.openSubscription')
          }}
        >
          <TypographyNoWrap variant="subtitle2">Subscription</TypographyNoWrap>
        </MenuItem>

        {!webConfig.temporary.temporarilyDisabledOrToBeRemoved &&
          webConfig.environment.agent === 'browser' &&
          !isMobile && (
            <MenuItem>
              <Anchor href={webConfig.urls.downloads} target="_blank">
                <Typography variant="subtitle2">Download desktop app</Typography>
              </Anchor>
            </MenuItem>
          )}

        <MenuItemWithKeyboardShortcut
          shortcutKeys={{ alt: true, shift: true, code: 'KeyQ' }}
          onClick={() => {
            sendAnalyticEvent('Title Bar (Account Context Menu)', 'Click on Sign out')
            runCommandStatic('accountItem.closeMenu')
            runCommandStatic('navigation.signOut')
          }}
        >
          <TypographyNoWrap variant="subtitle2" color="error">
            Sign out
          </TypographyNoWrap>
        </MenuItemWithKeyboardShortcut>
      </List>

      <Divider />

      {!webConfig.temporary.temporarilyDisabledOrToBeRemoved && (
        <>
          <List>
            <MenuItem
              onClick={() => {
                closeMenu()
                runCommandStatic('main.onboardingWelcomeTour')
              }}
            >
              <Typography variant="subtitle2">Reset onboarding</Typography>
            </MenuItem>

            <MenuItem>
              <Anchor href={webConfig.urls.changelogs} target="_blank">
                <TypographyNoWrap variant="subtitle2">Changelogs</TypographyNoWrap>
              </Anchor>
            </MenuItem>
          </List>

          <Divider />
        </>
      )}

      <List>
        <MenuItem disabled>
          <Typography variant="subtitle2" color="textSecondary">
            {webConfig.app.name} v{webConfig.version}
          </Typography>
        </MenuItem>
      </List>
    </Menu>
  )
}

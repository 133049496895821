import { DemoMode } from '../../../../../modules/demo-mode'
import { webConfig } from '../../../../../modules/web-config'
import { PresentationDialogSlide } from '../../presentation-dialog'

export namespace StaticService {
  export function create() {
    return {
      getFileUrl(path: string): string {
        return `${webConfig.services.baseUrls.privateApi.rest}/static/${path}`
      },

      async getUpdateSlides(): Promise<UpdateSlides.Steps> {
        const response = await fetch(this.getFileUrl('update.json'))
        const updateSlides = await response.json()
        return updateSlides
      },

      /**
       * @deprecated The demo content is only being used in the demo provider
       * which is in the root modules and does not have access to this module.
       */
      async getDemoContent(): Promise<DemoMode.Content> {
        const response = await fetch(this.getFileUrl('demo.json'))
        const demoContent = await response.json()
        return demoContent
      },
    } as const
  }

  export namespace UpdateSlides {
    export interface Steps {
      readonly version: number
      readonly contentVersion: string | number
      readonly overviewStep: Step
      readonly changeLogSteps: readonly Step[]
    }

    export interface Step {
      readonly caption?: string
      readonly title?: string
      readonly descriptionSections?: readonly PresentationDialogSlide.Section.Data[]
      readonly imageFileName?: string
      readonly videoFileName?: string
    }
  }
}

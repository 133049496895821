import { KeyboardCombinationView } from '../../../../../../modules/keyboard-handler'
import { Section } from './Section'

export function Part({ part }: { part: Section.Data[number] }) {
  if (typeof part === 'string') return <>{part}</>

  switch (part.type) {
    case 'styled text': {
      const textWithStrong = part.strong ? <strong>{part.text}</strong> : <>{part.text}</>
      const textWithStrongAndEmphasis = part.emphasis ? <em>{textWithStrong}</em> : textWithStrong
      return textWithStrongAndEmphasis
    }

    case 'keyboard combination':
      return <KeyboardCombinationView combination={part.combination} />
  }
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { captureException } from '@sentry/react'
import { useLayoutEffect, useState } from 'react'
import { arrayHelpers } from '../../../../../../../../../helpers/native/arrayHelpers'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { useRefWrap } from '../../../../../../../../../hooks/useRefWrap'
import { useStateAccessor } from '../../../../../../../../../hooks/useStateAccessor'
import { sendAnalyticEvent } from '../../../../../../../../../modules/analytics'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { useKeyboardHandling, commonKeyboardCombinations } from '../../../../../../../../../modules/keyboard-handler'
import { NavigationArea, Navigable } from '../../../../../../../../../modules/navigation'
import { webConfig } from '../../../../../../../../../modules/web-config'
import { useAppNotistack } from '../../../../../../../modules/app-notistack'
import { useServices } from '../../../../../../../modules/services'
import { InvitingUser } from './types'

const useStyles = makeStyles(
  theme => ({
    root: {},
    paper: {
      width: theme.spacing(62.5),
      overflow: 'visible',
    },
    inputWrapper: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  }),
  { name: 'InviteUsersDialog' }
)

export function InviteUsersDialog({ onClose }: { onClose(): void }) {
  const onCloseRef = useRefWrap(onClose)

  const [editingUserKey, setEditingUsberKey] = useState<number>()
  const [notSubmittedYet, setNotSubmittedYet] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const invitingUsersAccessor = useStateAccessor<readonly InvitingUser[]>(
    () => [
      {
        key: Date.now(),
        value: { email: '' },
      },
    ],
    [],
    {
      equalityFunction(first, second) {
        return (
          first.length === second.length &&
          first.every((item, index) => item.key === second[index].key && item.value.email === second[index].value.email)
        )
      },
      middleware(newValue, oldValue) {
        return newValue.map(invitingUser => ({
          ...invitingUser,
          validationError: !invitingUser.value.email
            ? undefined
            : /^\w+@gmail\.com$/i.test(invitingUser.value.email)
            ? undefined
            : 'Invalid Gmail address',
        }))
      },
    }
  )

  useLayoutEffect(() => {
    let revisedUsers = invitingUsersAccessor.value

    // Remove empty unfocused emails (except the last one):
    if (revisedUsers.slice(0, -1).some(member => !member.value.email && editingUserKey !== member.key)) {
      revisedUsers = revisedUsers.filter(
        (mem, index) => index === revisedUsers.length - 1 || mem.value.email || editingUserKey === mem.key
      )
    }

    // Ensure an empty email at the end of the list:
    const lastMember = arrayHelpers.last(revisedUsers)
    if (lastMember!.value.email || lastMember!.value.email) {
      revisedUsers = [...revisedUsers, { key: Date.now(), value: { email: '' } }]
    }

    invitingUsersAccessor.set(revisedUsers)
  }, [invitingUsersAccessor.value, editingUserKey])

  const { services } = useServices()

  const { enqueueSnackbar } = useAppNotistack()

  const invalidForm = invitingUsersAccessor.value.some(invitingUser => invitingUser.validationError)

  const submitRef = useRefWrap(async (): Promise<void> => {
    sendAnalyticEvent('Invite users dialog', 'Submit invite')
    if (isSubmitting) return

    setNotSubmittedYet(false)

    if (invalidForm) return

    const invitingUsers = invitingUsersAccessor.get().filter(invitingUser => invitingUser.value.email)
    if (invitingUsers.length === 0) return

    setIsSubmitting(true)

    try {
      await services.user.inviteUsers(invitingUsers.map(invitingUser => invitingUser.value.email))

      invitingUsers.forEach(invitedUser => {
        enqueueSnackbar('User Invitation', `${invitedUser.value.email} has been invited to the ${webConfig.app.name}`, {
          variant: 'info',
        })
      })

      onClose()
    } catch (error) {
      log.error('invite users', error)
      captureException(error, { tags: { module: 'invite users' } })
      enqueueSnackbar('Error', `User invitation failed`, { variant: 'error' })
    }
    setIsSubmitting(false)
  })

  useKeyboardHandling(
    [
      {
        combinations: commonKeyboardCombinations.enter,
        noConsumption: true,
        handler(event) {
          submitRef.current()
        },
      },
      {
        combinations: commonKeyboardCombinations.escape,
        handler(event) {
          onCloseRef.current()
        },
      },
    ],
    [],
    {
      disabled: isSubmitting,
    }
  )

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <NavigationArea>
      <Dialog scroll="paper" classes={{ paper: classes.paper }} open onClose={onClose}>
        <DialogTitle>
          <Typography variant="subtitle1">Invite your friends to {webConfig.app.name}</Typography>

          <div className={commonClasses.grow} />

          <Navigable padding={1}>
            {({ connectNavigable }) => (
              <IconButton ref={connectNavigable} size="small" onClick={onClose}>
                <CustomIcon name="Close" size={2.5} />
              </IconButton>
            )}
          </Navigable>
        </DialogTitle>

        <DialogContent dividers>
          {invitingUsersAccessor.get().map(invitingUser => (
            <TextField
              key={invitingUser.key}
              className={classes.inputWrapper}
              variant="outlined"
              fullWidth
              autoComplete="off"
              placeholder="Enter a gmail address"
              error={!!(notSubmittedYet ? undefined : invitingUser.validationError)}
              helperText={notSubmittedYet ? undefined : invitingUser.validationError}
              focused={editingUserKey === invitingUser.key}
              value={invitingUser.value.email}
              onFocus={() => setEditingUsberKey(invitingUser.key)}
              onChange={event => {
                invitingUsersAccessor.set(
                  arrayHelpers.replace(invitingUsersAccessor.get(), invitingUser, {
                    ...invitingUser,
                    value: { email: event.target.value },
                  })
                )
              }}
            />
          ))}
        </DialogContent>

        <DialogActions>
          <Navigable>
            {({ connectNavigable }) => (
              <Button ref={connectNavigable} onClick={onClose}>
                Cancel
              </Button>
            )}
          </Navigable>

          <Navigable>
            {({ connectNavigable }) => (
              <Button
                ref={connectNavigable}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitRef.current}
              >
                Invite
              </Button>
            )}
          </Navigable>
        </DialogActions>
      </Dialog>
    </NavigationArea>
  )
}

import { useEffect } from 'react'
import { ControlOptions } from './types'

export function useControl(options: ControlOptions): void {
  const isHidden = 'hide' in options ? options.hide : 'show' in options ? !options.show : false

  useEffect(() => {
    if (isHidden) {
      window.tidioChatApi?.hide()
      return () => void window.tidioChatApi?.show()
    }
  }, [isHidden])
}

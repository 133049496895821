import { Id } from '@zettelooo/commons'
import { CoreServiceSignature, Model } from '@zettelooo/server-shared'
import { DemoMode } from '../../../../../modules/demo-mode'
import { PeekMode } from '../../../../../modules/peek-mode'
import { webConfig } from '../../../../../modules/web-config'
import { Persistent } from '../../persistent'
import { RestService } from './RestService'

export class CoreService extends RestService {
  constructor(persistent: Persistent) {
    super('core', persistent)
  }

  async upsertAccount(account: Model.Account): Promise<void> {
    if (webConfig.developerLogs.actionSynchronization) {
      console.log('Upsert Account:', account) // eslint-disable-line no-console
    }

    if (DemoMode.data || PeekMode.data) return

    const {} = await this.requestAuthenticated<
      CoreServiceSignature.UpsertAccount.Request,
      CoreServiceSignature.UpsertAccount.Response
    >('upsert-account', { account })
  }

  async upsertPage(page: Model.Page): Promise<void> {
    if (webConfig.developerLogs.actionSynchronization) {
      console.log('Upsert Page:', page) // eslint-disable-line no-console
    }

    if (DemoMode.data || PeekMode.data) return

    const {} = await this.requestAuthenticated<
      CoreServiceSignature.UpsertPage.Request,
      CoreServiceSignature.UpsertPage.Response
    >('upsert-page', { page })
  }

  async upsertCard(card: Model.Card): Promise<void> {
    if (webConfig.developerLogs.actionSynchronization) {
      console.log('Upsert Card:', card) // eslint-disable-line no-console
    }

    if (DemoMode.data || PeekMode.data) return

    const {} = await this.requestAuthenticated<
      CoreServiceSignature.UpsertCard.Request,
      CoreServiceSignature.UpsertCard.Response
    >('upsert-card', { card })
  }

  async upsertBadge(badge: Model.Badge): Promise<void> {
    if (webConfig.developerLogs.actionSynchronization) {
      console.log('Upsert Badge:', badge) // eslint-disable-line no-console
    }

    if (DemoMode.data || PeekMode.data) return

    const {} = await this.requestAuthenticated<
      CoreServiceSignature.UpsertBadge.Request,
      CoreServiceSignature.UpsertBadge.Response
    >('upsert-badge', { badge })
  }

  async invitePageMemberByEmail(pageId: Id, email: string): Promise<void> {
    if (webConfig.developerLogs.actionSynchronization) {
      console.log('Invite Page Member by Email:', pageId, email) // eslint-disable-line no-console
    }

    if (DemoMode.data || PeekMode.data) return

    const {} = await this.requestAuthenticated<
      CoreServiceSignature.InvitePageMemberByEmail.Request,
      CoreServiceSignature.InvitePageMemberByEmail.Response
    >('invite-page-member-by-email', {
      pageId,
      email,
    })
  }

  async duplicatePage(pageId: Id): Promise<{ createdPageId: Id }> {
    if (DemoMode.data || PeekMode.data) return { createdPageId: '' }

    const { createdPageId } = await this.requestAuthenticated<
      CoreServiceSignature.DuplicatePage.Request,
      CoreServiceSignature.DuplicatePage.Response
    >('duplicate-page', { pageId })

    return {
      createdPageId,
    }
  }
}

import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'

export type ActionModel<T extends ActionModel.Type = ActionModel.Type> = {
  readonly type: T
  readonly id: Id
} & {
  [ActionModel.Type.UpsertModel]: {
    readonly type: ActionModel.Type.UpsertModel
    readonly oldModel?: Model.ByType
    readonly newModel: Model.ByType
  }
  [ActionModel.Type.InvitePageMemberByEmail]: {
    readonly type: ActionModel.Type.InvitePageMemberByEmail
    readonly pageId: Id
    readonly email: string
  }
}[T]

export namespace ActionModel {
  export enum Type {
    UpsertModel = 'UPSERT_ENTITY',
    InvitePageMemberByEmail = 'INVITE_PAGE_MEMBER_BY_EMAIL',
  }
}

import { Model } from '@zettelooo/server-shared'
import { useEffect } from 'react'
import { useDatabases } from '../../databases'
import { usePersistent, PersistentKey } from '../../persistent'

export function useMutablesDatabaseMaintenance(): void {
  const { persistent } = usePersistent()

  const { databases } = useDatabases()

  useEffect(() => {
    const { subscriptionKey } = databases.mutablesDatabase.subscribe({
      handleMutations(models) {
        const accountId = persistent(PersistentKey.Authentication).get()?.decodedAccessToken.userId
        if (!accountId) return

        const thereAreUnrelatedPages = models.some(
          model => model.type === Model.Type.Page && (!model.memberUserIds.includes(accountId) || model.isDeleted)
        )
        if (!thereAreUnrelatedPages) return

        return databases.mutablesDatabase.cleanIrrelevantData(accountId)
      },

      handleReload() {
        const accountId = persistent(PersistentKey.Authentication).get()?.decodedAccessToken.userId
        if (!accountId) return

        return databases.mutablesDatabase.cleanIrrelevantData(accountId)
      },
    })

    return () => {
      databases.mutablesDatabase.unsubscribe(subscriptionKey)
    }
  }, [databases.mutablesDatabase, persistent])
}

import { Button, IconButton, makeStyles, Tooltip, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { useContext } from 'react'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { useManageCommands } from '../../../../../../../../../modules/commands'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { DeviceProvider } from '../../../../../../../../../modules/device'
import { KeyboardCombinationsView } from '../../../../../../../../../modules/keyboard-handler'
import { webConfig } from '../../../../../../../../../modules/web-config'
import { Gap } from '../../../../../../../../Gap'

const useStyles = makeStyles(
  theme => ({
    button: {
      width: theme.spacing(40),
      height: 'unset', // Let the height follow text and vertical paddings
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
      justifyContent: 'unset', // Undo the Button's default center content justification
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.hint,
      cursor: 'text',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    iconButton: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'SearchItem' }
)

export function SearchItem() {
  const { screenWidth, isMobile } = useContext(DeviceProvider.Context)

  const { runCommandStatic } = useManageCommands()

  const theme = useTheme()

  const isSideBarHidden =
    true || // Temporarily, we don't want to show the search input anyway
    isMobile ||
    (webConfig.temporary.temporarilyDisabledOrToBeRemoved &&
      screenWidth <= theme.constants.sidebar.resizableSideBarMinimumScreenWidth)

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <Tooltip
      title={
        <KeyboardCombinationsView
          combinations={[{ code: 'Slash' }, { cmd: true, code: 'Slash' }]}
          prefix="Search"
          highlight
        />
      }
    >
      {isSideBarHidden ? (
        <IconButton
          className={classNames(classes.iconButton, commonClasses.electronNoDrag)}
          onClick={() => runCommandStatic('main.search')}
        >
          <CustomIcon name="Search" size={2.5} />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          size="small"
          className={classNames(classes.button, commonClasses.electronNoDrag)}
          onClick={() => runCommandStatic('main.search')}
        >
          Search your mind
          <Gap grow />
          <CustomIcon name="Search" size="small" />
        </Button>
      )}
    </Tooltip>
  )
}

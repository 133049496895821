import { Input, InputAdornment, InputProps, makeStyles } from '@material-ui/core'
import { Keyboard } from '@zettelooo/commons'
import classNames from 'classnames'
import { CustomIcon } from '../modules/custom-icon'
import { Navigable } from '../modules/navigation'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(0.5, 1, 0.5, 2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(1.25),
    },
    icon: {
      color: theme.palette.text.hint,
    },
  }),
  { name: 'SearchBox' }
)

export function SearchBox({
  disabledKeyboardNavigation,
  placeholder,
  query,
  onSearch,
  onSubmit,
  className,
  ...otherProps
}: {
  disabledKeyboardNavigation?: boolean
  query?: string
  onSearch?(query: string): void
  onSubmit?(): void
} & Omit<
  InputProps,
  'onSubmit' | 'inputRef' | 'disableUnderline' | 'fullWidth' | 'StartAdornment' | 'value' | 'onChange' | 'onKeyDown'
>) {
  const classes = useStyles()

  return (
    <Navigable selectable focusable disabled={disabledKeyboardNavigation}>
      {({ connectNavigable, connectFocusable }) => (
        <Input
          ref={connectNavigable}
          inputRef={connectFocusable}
          {...otherProps}
          disableUnderline
          fullWidth
          className={classNames(classes.root, className)}
          startAdornment={
            <InputAdornment position="start">
              <CustomIcon name="Search" size="small" className={classes.icon} />
            </InputAdornment>
          }
          placeholder={placeholder ?? 'Search'}
          value={query}
          onChange={event => {
            onSearch?.(event.target.value)
          }}
          onKeyDown={event => {
            const code = event.code as Keyboard.NativeEventCode
            if (code === 'Enter' || code === 'NumpadEnter') {
              onSubmit?.()
            }
          }}
        />
      )}
    </Navigable>
  )
}

import { DeepPartial, Id, PartialRecord } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Extension } from '@zettelooo/server-shared'
import { getAvatarFilePreviewUrl, getFilePreviewUrl, getFileUrl } from '../../../../../../modules/file'
import { webConfig } from '../../../../../../modules/web-config'
import { Databases } from '../../../databases'
import { Services } from '../../../services'
import {
  ExtensionEffectWatchers,
  ExtensionLifeSpanEnabledByScope,
  ExtensionLifeSpanProvider,
  ExtensionModel,
} from '../types'
import { checkTargetRolesCompatibility } from './checkTargetRolesCompatibility'
import { getExecutables } from './getExecutables'
import { getKeyFromTargetRoles } from './getKeyFromTargetRoles'
import { getTargetRolesFromKey } from './getTargetRolesFromKey'
import {
  ExtensionContext,
  ExtensionEffect,
  ExtensionEffectContext,
  ExtensionEffectPool,
  ExtensionExecutables,
  ExtensionTargetRoles,
} from './types'

export class ExtensionManager {
  private readonly lifeSpansByNameAndTargetRolesKey: PartialRecord<
    ZettelExtensions.LifeSpan.Name,
    PartialRecord<
      string,
      {
        enabledByScope: ExtensionLifeSpanEnabledByScope<ZettelExtensions.LifeSpan.Name>
        provider: ExtensionLifeSpanProvider<any>
      }
    >
  > = {}
  private readonly extensionContexts: ExtensionContext[] = []
  private readonly extensionContextsById: PartialRecord<Id, ExtensionContext> = {}

  constructor(
    private readonly input: {
      readonly generateModelIdStatic: () => Id
      readonly databases: Databases
      readonly services: Services
    }
  ) {}

  registerLifeSpan<N extends ZettelExtensions.LifeSpan.Name>(
    name: N,
    target: ZettelExtensions.LifeSpan.Target<N>,
    roles: readonly string[],
    enabledByScope: ExtensionLifeSpanEnabledByScope<N>,
    provider: ExtensionLifeSpanProvider<N>
  ): void {
    const targetRolesKey = getKeyFromTargetRoles({ target, roles })
    const lifeSpansByTargetRolesKey = this.getLifeSpansByTargetRolesKey(name)

    if (targetRolesKey in lifeSpansByTargetRolesKey)
      throw Error(`Extension life span '${name}' with target-roles '${targetRolesKey}' has been registered already.`)

    lifeSpansByTargetRolesKey[targetRolesKey] = {
      enabledByScope,
      provider,
    }

    this.updateEffectContextsForLifeSpan(name, targetRolesKey)
  }

  unregisterLifeSpan<N extends ZettelExtensions.LifeSpan.Name>(
    name: N,
    target: ZettelExtensions.LifeSpan.Target<N>,
    roles: readonly string[]
  ): void {
    const targetRolesKey = getKeyFromTargetRoles({ target, roles })
    const lifeSpansByTargetRolesKey = this.getLifeSpansByTargetRolesKey(name)

    if (!(targetRolesKey in lifeSpansByTargetRolesKey)) return

    delete lifeSpansByTargetRolesKey[targetRolesKey]

    this.updateEffectContextsForLifeSpan(name, targetRolesKey)
  }

  updateLifeSpanEnabledByScope<N extends ZettelExtensions.LifeSpan.Name>(
    name: N,
    target: ZettelExtensions.LifeSpan.Target<N>,
    roles: readonly string[],
    enabledByScope: ExtensionLifeSpanEnabledByScope<N>
  ): void {
    const targetRolesKey = getKeyFromTargetRoles({ target, roles })
    const lifeSpansByTargetRolesKey = this.getLifeSpansByTargetRolesKey(name)
    const lifeSpan = lifeSpansByTargetRolesKey[targetRolesKey]

    if (!lifeSpan) return

    lifeSpan.enabledByScope = enabledByScope

    this.updateEffectContextsForLifeSpan(name, targetRolesKey)
  }

  private getLifeSpansByTargetRolesKey<N extends ZettelExtensions.LifeSpan.Name>(name: N) {
    if (!(name in this.lifeSpansByNameAndTargetRolesKey)) {
      this.lifeSpansByNameAndTargetRolesKey[name] = {}
    }
    return this.lifeSpansByNameAndTargetRolesKey[name as ZettelExtensions.LifeSpan.Name]!
  }

  private updateEffectContextsForLifeSpan(lifeSpanName: ZettelExtensions.LifeSpan.Name, targetRolesKey: string): void {
    const lifeSpan = this.getLifeSpansByTargetRolesKey(lifeSpanName)[targetRolesKey]
    const enabledByScope = lifeSpan?.enabledByScope
    this.extensionContexts.forEach(extensionContext => {
      const extensionId = extensionContext.header.id

      const oldEnabledScopes =
        (extensionContext.enabledScopesByLifeSpanNameAndTargetRolesKey[lifeSpanName]?.[
          targetRolesKey
        ] as ZettelExtensions.Scope[]) ?? []
      const oldExists = Boolean(
        extensionContext.enabledScopesByLifeSpanNameAndTargetRolesKey[lifeSpanName]?.[targetRolesKey]
      )

      const newEnabledScopes = enabledByScope
        ? (Object.keys(enabledByScope) as ZettelExtensions.Scope[]).filter(scope =>
            enabledByScope[scope].extensionIdSet.has(extensionId)
          )
        : []
      const newExists = Boolean(
        newEnabledScopes.length > 0 ||
          (enabledByScope &&
            (Object.keys(enabledByScope) as ZettelExtensions.Scope[]).some(scope =>
              enabledByScope[scope].effectiveExtensionIdSet.has(extensionId)
            ))
      )

      const changed =
        newEnabledScopes.length !== oldEnabledScopes.length ||
        newEnabledScopes.some(scope => !oldEnabledScopes.includes(scope)) ||
        oldEnabledScopes.some(scope => !newEnabledScopes.includes(scope)) ||
        oldExists !== newExists

      if (!changed) return

      if (oldExists) {
        delete extensionContext.enabledScopesByLifeSpanNameAndTargetRolesKey[lifeSpanName]![targetRolesKey]

        extensionContext.effectPool.iterateByLifeSpanName(lifeSpanName, effect =>
          effect.contextsByTargetRolesKey[targetRolesKey]?.dispose()
        )
      }

      if (newExists) {
        extensionContext.enabledScopesByLifeSpanNameAndTargetRolesKey[lifeSpanName] =
          extensionContext.enabledScopesByLifeSpanNameAndTargetRolesKey[lifeSpanName] ?? {}
        extensionContext.enabledScopesByLifeSpanNameAndTargetRolesKey[lifeSpanName]![targetRolesKey] = newEnabledScopes
        extensionContext.effectPool.iterateByLifeSpanName(lifeSpanName, effect =>
          this.runEffect(extensionContext, effect, targetRolesKey, newEnabledScopes, lifeSpan!.provider)
        )
      }
    })
  }

  updateInstalledExtensions(installedExtensions: readonly ExtensionModel[]): void {
    const toBeDeleted = this.extensionContexts.filter(extensionContexts =>
      installedExtensions.every(
        extension =>
          extension.id !== extensionContexts.header.id || extension.version !== extensionContexts.header.version
      )
    )

    const toBeCreated = installedExtensions.filter(extension =>
      this.extensionContexts.every(
        extensionContext =>
          extensionContext.header.id !== extension.id || extensionContext.header.version !== extension.version
      )
    )

    toBeDeleted.forEach(extensionContext => {
      this.extensionContexts.splice(this.extensionContexts.indexOf(extensionContext), 1)
      delete this.extensionContextsById[extensionContext.header.id]
      extensionContext.effectPool.finishAll()

      if (webConfig.developerLogs.extensions) {
        // eslint-disable-next-line no-console
        console.log(
          `Finish extension "${extensionContext.header.name}" ${extensionContext.header.version} (${extensionContext.header.id})`
        )
      }
    })

    toBeCreated.forEach(extension => {
      if (webConfig.developerLogs.extensions) {
        // eslint-disable-next-line no-console
        console.log(`Start extension "${extension.name}" ${extension.version} (${extension.id})`)
      }

      const { js, ...header } = extension
      const extensionContext: ExtensionContext = {
        header,
        executables: getExecutables(extension),
        isRegistering: false,
        enabledScopesByLifeSpanNameAndTargetRolesKey: {},
        effectPool: new ExtensionEffectPool(),
      }
      const lifeSpanNames = Object.keys(this.lifeSpansByNameAndTargetRolesKey) as ZettelExtensions.LifeSpan.Name[]
      lifeSpanNames.forEach(lifeSpanName => {
        const lifeSpansByTargetRolesKey = this.getLifeSpansByTargetRolesKey(lifeSpanName)
        const targetRolesKeys = Object.keys(this.lifeSpansByNameAndTargetRolesKey[lifeSpanName]!)
        targetRolesKeys.forEach(targetRolesKey => {
          const lifeSpan = lifeSpansByTargetRolesKey[targetRolesKey]!
          const enabledScopes = (Object.keys(lifeSpan.enabledByScope) as ZettelExtensions.Scope[]).filter(scope =>
            lifeSpan.enabledByScope[scope].extensionIdSet.has(extension.id)
          )
          const exists =
            enabledScopes.length > 0 ||
            (Object.keys(lifeSpan.enabledByScope) as ZettelExtensions.Scope[]).some(scope =>
              lifeSpan.enabledByScope[scope].effectiveExtensionIdSet.has(extension.id)
            )
          if (exists) {
            extensionContext.enabledScopesByLifeSpanNameAndTargetRolesKey[lifeSpanName] =
              extensionContext.enabledScopesByLifeSpanNameAndTargetRolesKey[lifeSpanName] ?? {}
            extensionContext.enabledScopesByLifeSpanNameAndTargetRolesKey[lifeSpanName]![targetRolesKey] = enabledScopes
          }
        })
      })
      this.extensionContexts.push(extensionContext)
      this.extensionContextsById[extensionContext.header.id] = extensionContext

      if (!extensionContext.executables.starter || typeof extensionContext.executables.starter !== 'function') return

      const api = this.createApi(extensionContext)
      try {
        extensionContext.executables.starter.bind(api)(api)
      } catch (error) {
        log.error('ExtensionManager:', 'Start:', extensionContext.header)
        this.extensionContexts.splice(this.extensionContexts.indexOf(extensionContext), 1)
        delete this.extensionContextsById[extensionContext.header.id]
        extensionContext.effectPool.finishAll()
      }
    })
  }

  dispose(): void {
    this.extensionContexts.forEach(extensionContext => extensionContext.effectPool.finishAll())
  }

  private createApi(extensionContext: ExtensionContext): ZettelExtensions.Starter.Api {
    return {
      coreVersion: webConfig.version,
      header: {
        id: extensionContext.header.id,
        version: extensionContext.header.version,
        author: {
          id: extensionContext.header.author.id,
          name: extensionContext.header.author.name,
          email: extensionContext.header.author.email,
        },
        name: extensionContext.header.name,
        description: extensionContext.header.description,
        ...(extensionContext.header.avatar.file
          ? {
              avatarUrl: this.input.services.extension.getFileUrl(
                extensionContext.header,
                extensionContext.header.avatar.file
              ),
            }
          : extensionContext.header.avatar.dataUrl
          ? {
              avatarUrl: extensionContext.header.avatar.dataUrl,
            }
          : {}),
        documentationMarkdownFile: extensionContext.header.documentationMarkdownFile,
      },
      getFileUrl: options => {
        if ('filePath' in options)
          return this.input.services.extension.getFileUrl(extensionContext.header, options.filePath)
        if ('avatarFileId' in options) return getAvatarFilePreviewUrl(options.avatarFileId, { size: options.size })
        if (options.previewOptions) return getFilePreviewUrl(options.fileId, options.previewOptions, options.fileName)
        return getFileUrl(options.fileId, options.fileName)
      },
      generateId: () => this.input.generateModelIdStatic(),
      localStorage: {
        read: () => this.input.databases.extensionsDatabase.readData(extensionContext.header.id),
        write: async data => {
          const evaluatedData =
            typeof data === 'function'
              ? (data as (oldData: any) => any)(
                  await this.input.databases.extensionsDatabase.readData(extensionContext.header.id)
                )
              : data
          await this.input.databases.extensionsDatabase.writeData(extensionContext.header.id, evaluatedData)
        },
      },
      while: this.createWhile(extensionContext, extensionContext.effectPool, { target: {}, roles: [] }),
    }
  }

  private createWhile(
    extensionContext: ExtensionContext,
    effectPool: ExtensionEffectPool,
    containingTargetRoles: ExtensionTargetRoles
  ): ZettelExtensions.Starter.While {
    return (name, affect) => {
      const effect: ExtensionEffect<any> = {
        lifeSpanName: name,
        affect,
        containingTargetRoles,
        contextsByTargetRolesKey: {},

        finish() {
          if (!effectPool.has(effect)) return
          effectPool.remove(effect)

          const effectContexts = Object.values(effect.contextsByTargetRolesKey)
          effectContexts.forEach(effectContext => effectContext?.dispose())
        },
      }

      effectPool.add(effect)

      // Run the new effect vs all the current registered life spans:
      const lifeSpanEnabledScopesByTargetRolesKey =
        extensionContext.enabledScopesByLifeSpanNameAndTargetRolesKey[name as ZettelExtensions.LifeSpan.Name]
      if (lifeSpanEnabledScopesByTargetRolesKey) {
        const targetRolesKeys = Object.keys(lifeSpanEnabledScopesByTargetRolesKey)
        targetRolesKeys.forEach(targetRolesKey => {
          const enabledScopes = lifeSpanEnabledScopesByTargetRolesKey[targetRolesKey]!
          const lifeSpan = this.getLifeSpansByTargetRolesKey(name)[targetRolesKey]!
          this.runEffect(extensionContext, effect, targetRolesKey, enabledScopes, lifeSpan.provider)
        })
      }

      return {
        get isFinished() {
          return !effectPool.has(effect)
        },
        finish: effect.finish,
      }
    }
  }

  private runEffect(
    extensionContext: ExtensionContext,
    effect: ExtensionEffect,
    targetRolesKey: string,
    scopes: ZettelExtensions.LifeSpan.Scopes<ZettelExtensions.LifeSpan.Name>,
    provider: ExtensionLifeSpanProvider<ZettelExtensions.LifeSpan.Name>
  ): void {
    // TODO: Just a hacky workaround, we need to first completely understand how this whole scope thing works, then we need to refactor it so it works seemlessly without this following hack:
    if (scopes.length === 0 && Object.keys(JSON.parse(targetRolesKey)).length !== 1) return

    if (!checkTargetRolesCompatibility(effect.containingTargetRoles, getTargetRolesFromKey(targetRolesKey))) return

    const effectContext: ExtensionEffectContext = {
      watchers: {
        dataConverterContext: {
          usedFactories: {},
          resultedValues: {} as any, // It's fine, because we won't access a value here before first calculating it at least once
        },
        callbackDependenciesMap: new Map(),
      },
      disposers: [],
      sideEffects: new ExtensionEffectPool(),

      dispose() {
        if (effect.contextsByTargetRolesKey[targetRolesKey] !== effectContext) return
        delete effect.contextsByTargetRolesKey[targetRolesKey]

        effectContext.isDisposed = true
        effectContext.watchers.callbackDependenciesMap.clear()
        while (effectContext.disposers.length > 0) {
          effectContext.disposers.pop()!()
        }
        effectContext.sideEffects.finishAll()
      },
    }

    effect.contextsByTargetRolesKey[targetRolesKey]?.dispose() // Not gonna happen, just in case!
    effect.contextsByTargetRolesKey[targetRolesKey] = effectContext

    const provided = provider({
      header: extensionContext.header,
      scopes,
      isDisposed() {
        return Boolean(effectContext.isDisposed)
      },
      watchers: effectContext.watchers,
      isRegistering() {
        return extensionContext.isRegistering
      },
      startRegistering() {
        extensionContext.isRegistering = true
      },
      finishRegistering() {
        extensionContext.isRegistering = false
      },
      disposers: effectContext.disposers,
      whileFunction: this.createWhile(
        extensionContext,
        effectContext.sideEffects,
        getTargetRolesFromKey(targetRolesKey)
      ),
    })

    try {
      const cleanUp = effect.affect.bind(provided)({ [`${effect.lifeSpanName}Api`]: provided } as any)

      if (typeof cleanUp === 'function') {
        effectContext.disposers.push(cleanUp)
      }
    } catch (error) {
      log.error('ExtensionManager:', 'Run Effect:', extensionContext.header, targetRolesKey, error)
      effectContext.dispose()
    }
  }

  forEachWatchersPerLifeSpan<N extends ZettelExtensions.LifeSpan.Name>(
    name: N,
    target: ZettelExtensions.LifeSpan.Target<N>,
    roles: readonly string[],
    callback: (header: Extension.Header, watchers: ExtensionEffectWatchers<N>) => void
  ): void {
    const targetRolesKey = getKeyFromTargetRoles({ target, roles })

    this.extensionContexts.forEach(extensionContext => {
      extensionContext.effectPool.iterateByLifeSpanName(name, effect => {
        const watchers = effect.contextsByTargetRolesKey[targetRolesKey]?.watchers
        if (watchers) {
          callback(extensionContext.header, watchers)
        }
      })
    })
  }

  getExecutables(extensionId: Id): ExtensionExecutables | undefined {
    return this.extensionContextsById[extensionId]?.executables
  }

  constructCardData(
    extensionId: Id,
    previousData: any | undefined,
    issuingExtractions: readonly any[],
    getOtherExtractions: () => any[]
  ): any | undefined {
    const construct = this.extensionContextsById[extensionId]?.executables.cardData?.construct
    if (!construct) return previousData
    try {
      return construct(previousData, issuingExtractions, getOtherExtractions)
    } catch {
      return previousData
    }
  }

  extractCardData(fromExtensionId: Id, toExtensionId: Id, fromCardData: any | undefined): any | undefined {
    const extractor =
      this.extensionContextsById[fromExtensionId]?.executables.cardData?.extractors?.[toExtensionId]?.to ??
      this.extensionContextsById[toExtensionId]?.executables.cardData?.extractors?.[fromExtensionId]?.from
    if (!extractor || fromCardData === undefined) return undefined
    try {
      return extractor(fromCardData)
    } catch {
      return undefined
    }
  }

  extractCardDataFromCommonData(
    extensionId: Id,
    fromCommonData: ZettelExtensions.CardData.CommonData | undefined
  ): any | undefined {
    const extractor = this.extensionContextsById[extensionId]?.executables.cardData?.commonExtractor?.from
    if (!extractor || fromCommonData === undefined) return undefined
    try {
      return extractor(fromCommonData)
    } catch {
      return undefined
    }
  }

  extractCommonDataFromCardData(
    extensionId: Id,
    fromCardData: any | undefined
  ): DeepPartial<ZettelExtensions.CardData.CommonData> | undefined {
    const extractor = this.extensionContextsById[extensionId]?.executables.cardData?.commonExtractor?.to
    if (!extractor || fromCardData === undefined) return undefined
    try {
      return extractor(fromCardData)
    } catch {
      return undefined
    }
  }
}

import { useCommandHandling } from '../../../../../modules/commands'
import { useAppHistoryControl } from '../../../modules/app-history'
import { useConfirmationDialog } from '../../../modules/confirmation-dialog'
import { routes } from '../modules/routes'

export function useRoutingRelatedCommandHandling(): void {
  const { appHistoryControlStatic } = useAppHistoryControl()

  const { confirmStatic } = useConfirmationDialog()

  useCommandHandling(
    'navigation.signOut',
    async () => {
      const confirmed = await confirmStatic({
        title: 'Sign out',
        content: 'Are you sure to sign out?',
        confirmLabel: 'Sign out',
        cancelLabel: 'Cancel',
      })
      if (!confirmed) return
      appHistoryControlStatic.push(routes.signOut.getPath({}, {}))
    },
    []
  )
}

import { Button, Typography } from '@material-ui/core'
import { IpcChannel, WindowIpcMessageType, WindowType } from '@zettelooo/desktop-shared'
import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { sendAnalyticEvent } from '../../../modules/analytics'
import { webConfig } from '../../../modules/web-config'
import { sendIpcMessage } from '../modules/electron'

// This component has to be independent of almost all the app providers.
// Because, it's supposed to be displayed when some of those providers breaks.
// Therefore, not many styling/operational options are available in here.
// This component is basically a simplified version of the FatalError component.

export function FatalErrorStandalone({ error }: { error?: Error }) {
  const [refreshing, setRefreshing] = useState(false)

  useEffectOnce(() => {
    sendAnalyticEvent('Fatal Error Standalone', 'Error occured', JSON.stringify({ error }))
  })

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h4"
          color="textPrimary"
          style={{
            margin: 40,
          }}
        >
          Something wrong happened.
        </Typography>

        <Typography
          variant="subtitle1"
          color="textSecondary"
          style={{
            maxWidth: 400,
            textAlign: 'center',
            marginBottom: 40,
          }}
        >
          Something went wrong and you&apos;ve found our error page. Please select one of the options below, or send us
          an email at hiii@{webConfig.app.domain}
        </Typography>

        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              width: 160,
              height: 40,
              borderRadius: 30,
            }}
            disabled={refreshing}
            onClick={async () => {
              setRefreshing(true)

              if (webConfig.environment.agent === 'electron') {
                sendIpcMessage(IpcChannel.Window, {
                  type: WindowIpcMessageType.WindowReload,
                  windowType: WindowType.Main,
                })
              } else {
                window.location.reload()
              }
            }}
          >
            Refresh
          </Button>
        </div>
      </div>
    </div>
  )
}

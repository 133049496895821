import { IconButton, makeStyles, Typography, useTheme } from '@material-ui/core'
import { SnackbarKey, OptionsObject, useSnackbar } from 'notistack'
import { ReactNode, useCallback } from 'react'
import { useRefWrap } from '../../../../hooks/useRefWrap'
import { CustomIcon } from '../../../../modules/custom-icon'

const useStyles = makeStyles(
  theme => ({
    root: {
      maxWidth: theme.spacing(50),
      display: 'flex',
      padding: theme.spacing(2),
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(1),
    },
    content: {
      flexGrow: 1,
      margin: theme.spacing(0, 2),
    },
    body: {
      opacity: 0.75,
    },
    closeButton: {
      alignSelf: 'flex-start',
      padding: 0,
      color: theme.palette.primary.contrastText,
    },
  }),
  { name: 'useAppNotistack' }
)

export function useAppNotistack(): {
  enqueueSnackbar(title: ReactNode, body?: ReactNode, options?: OptionsObject): SnackbarKey
  closeSnackbar(key?: SnackbarKey): void
} {
  const snackbar = useSnackbar()

  const requirementsRef = useRefWrap({
    enqueueSnackbar: snackbar.enqueueSnackbar,
    theme: useTheme(),
    classes: useStyles(),
  })

  return {
    enqueueSnackbar: useCallback(
      (title, body, options) =>
        requirementsRef.current.enqueueSnackbar(body, {
          ...options,
          content:
            options?.content ??
            ((key, message) => (
              <div className={requirementsRef.current.classes.root}>
                <CustomIcon
                  name="Info"
                  size="small"
                  color={
                    options?.variant === 'error'
                      ? requirementsRef.current.theme.palette.error.main
                      : options?.variant === 'success'
                      ? requirementsRef.current.theme.palette.success.main
                      : options?.variant === 'warning'
                      ? requirementsRef.current.theme.palette.warning.main
                      : options?.variant === 'info'
                      ? requirementsRef.current.theme.palette.info.main
                      : undefined
                  }
                />

                <div className={requirementsRef.current.classes.content}>
                  <Typography variant="subtitle1" gutterBottom>
                    {title}
                  </Typography>
                  {body && (
                    <Typography variant="body2" component="div" className={requirementsRef.current.classes.body}>
                      {body}
                    </Typography>
                  )}
                </div>

                <IconButton
                  className={requirementsRef.current.classes.closeButton}
                  onClick={() => snackbar.closeSnackbar(key)}
                >
                  <CustomIcon name="Close" />
                </IconButton>
              </div>
            )),
        }),
      []
    ),

    closeSnackbar: snackbar.closeSnackbar,
  }
}

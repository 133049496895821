import { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { arrayHelpers } from '../../../../helpers/native/arrayHelpers'
import { useRefWrap } from '../../../../hooks/useRefWrap'
import { webConfig } from '../../../../modules/web-config'
import { AppHistoryContext } from './AppHistoryProvider'
import { getLocationPath } from './getLocationPath'
import { AppHistoryState } from './types'

const MAXIMUM_HISTORY_STACK_SIZE = 100

export function useAppHistoryControl(): {
  appHistoryControlStatic: {
    push(path: string, options?: { readonly clearHistory?: boolean }): void
    replace(path: string): void

    goBackward(): boolean
    goForward(): boolean

    /**
     * See: https://github.com/remix-run/history/blob/main/docs/blocking-transitions.md
     */
    block(
      prompt?:
        | boolean
        | string
        | ((
            location: {
              pathname: string
              search: string
              state?: AppHistoryState
              hash: string
              key?: string
            },
            action: 'PUSH' | 'POP' | 'REPLACE'
          ) => string | false | void)
    ): () => void
  }
} {
  const { stateRef } = useContext(AppHistoryContext)

  const history = useHistory<AppHistoryState>()

  const historyRef = useRefWrap(history)

  function getStateStatic(): AppHistoryState {
    return webConfig.environment.agent === 'electron' ? stateRef.current : historyRef.current.location.state
  }
  function setStateStatic(state: AppHistoryState): AppHistoryState {
    if (webConfig.environment.agent === 'electron') {
      stateRef.current = state
    }
    return state
  }

  return {
    appHistoryControlStatic: useMemo(
      () => ({
        push(path, options) {
          const state = getStateStatic()
          historyRef.current.push(
            path,
            setStateStatic(
              options?.clearHistory
                ? undefined
                : !state
                ? {
                    stack: [getLocationPath(historyRef.current.location), path],
                    index: 1,
                  }
                : {
                    stack: state.stack
                      .slice(0, state.index + 1)
                      .concat(path)
                      .slice(-MAXIMUM_HISTORY_STACK_SIZE),
                    index: state.index + 1,
                  }
            )
          )
        },

        replace(path) {
          const state = getStateStatic()
          historyRef.current.push(
            path,
            setStateStatic(
              !state
                ? {
                    stack: [path],
                    index: 1,
                  }
                : {
                    stack: arrayHelpers.replaceIndex(state.stack, state.index, path),
                    index: state.index,
                  }
            )
          )
        },

        goBackward() {
          const state = getStateStatic()
          if (!state) return false

          const backwardPath = state.stack[state.index - 1]
          if (!backwardPath) return false

          historyRef.current.push(
            backwardPath,
            setStateStatic({
              stack: state.stack,
              index: state.index - 1,
            })
          )
          return true
        },

        goForward() {
          const state = getStateStatic()
          if (!state) return false

          const forwardPath = state.stack[state.index + 1]
          if (!forwardPath) return false

          historyRef.current.push(
            forwardPath,
            setStateStatic({
              stack: state.stack,
              index: state.index + 1,
            })
          )
          return true
        },

        block(prompt) {
          return historyRef.current.block(prompt)
        },
      }),
      []
    ),
  }
}

import { useContext } from 'react'
import { Contexts, ValuesBase } from './types'

export function useContexts<Values extends ValuesBase>(contexts: Contexts<Values, any>): Readonly<Values> {
  return new Proxy({} as Readonly<Values>, {
    get<Key extends keyof Values>(_: any, key: Key | symbol) {
      return useContext(contexts.contexts[key as Key]) // The key is always a Key not a symbol due to the output type of the hook, so this type cast is safe
    },
  })
}

import { DependencyList, useContext, useMemo } from 'react'
import { useRefWrap } from '../../../../hooks/useRefWrap'
import { Api } from './Api'
import { ApiContext } from './ApiContext'

export function useApiBuilder<C>(
  content: C,
  apiFactory: (contentRef: { readonly current: C }) => Api,
  dependencies: DependencyList
): { smartCommandApi: Api } {
  const baseApi = useContext(ApiContext)

  const contentRef = useRefWrap(content)

  const mergedApi = useMemo(
    () => ({
      ...baseApi,
      ...apiFactory(contentRef),
    }),
    [...dependencies, baseApi]
  )

  return {
    smartCommandApi: mergedApi,
  }
}

import { ReadonlyRecord } from '@zettelooo/commons'
import { AiServiceSignature } from '@zettelooo/server-shared'
import { Action } from './Action'

export type Api = ReadonlyRecord<string, Action>

export namespace Api {
  export interface Internal {
    readonly $: {
      readonly command: string
    }
  }

  export function convertApiToAiServiceApi(api: Api): ReadonlyRecord<string, AiServiceSignature.SmartCommand.Action> {
    return Object.keys(api).reduce((current, name) => {
      current[name] = Action.convertActionToAiServiceAction(api[name])
      return current
    }, {} as Record<string, AiServiceSignature.SmartCommand.Action>)
  }
}

import { Model } from '@zettelooo/server-shared'
import { useMemo } from 'react'
import { useContexts } from '../../../../../../../../../../../../modules/contexts'
import { PagePanel } from '../../PagePanel'

const MAXIMUM_TIPS_COUNT_TO_DISPLAY = 3

export function useSuggestedTips(): {
  suggestedTips: readonly Tip[]
} {
  const { page, cards } = useContexts(PagePanel.Contexts)

  const context = useMemo<Context>(() => ({ page, cards }), [page, cards])

  const scoredTipGroups = useMemo<readonly (TipGroup & { readonly score: number })[]>(
    () =>
      tipGroupsWithScoreCalculation
        .map(({ calculateScore, ...tip }) => ({ ...tip, score: calculateScore(context) }))
        .filter(scoredTip => scoredTip.score > 0),
    [context]
  )

  const suggestedTipGroups = useMemo<readonly TipGroup[]>(() => {
    const selectedTipGroups: TipGroup[] = []
    const currentScoredTipGroups = [...scoredTipGroups]
    while (selectedTipGroups.length < MAXIMUM_TIPS_COUNT_TO_DISPLAY) {
      const tip = pickTipGroup()
      if (!tip) break
      selectedTipGroups.push(tip)
    }
    return selectedTipGroups

    function pickTipGroup(): TipGroup | null {
      const totalScore = currentScoredTipGroups.reduce((current, scoredTip) => current + scoredTip.score, 0)
      let randomPoint = totalScore * Math.random()
      for (let i = 0; i < currentScoredTipGroups.length; i += 1) {
        const { score, ...tip } = currentScoredTipGroups[i]
        randomPoint -= score
        if (randomPoint >= 0) continue
        currentScoredTipGroups.splice(i, 1)
        return tip
      }
      return null
    }
  }, [scoredTipGroups])

  const suggestedTips = useMemo<readonly Tip[]>(
    () =>
      suggestedTipGroups
        .map(tipGroup => tipGroup.commands[Math.floor(tipGroup.commands.length * Math.random())])
        .filter(Boolean)
        .map(command => ({ command })),
    [suggestedTipGroups]
  )

  return {
    suggestedTips,
  }
}

interface TipGroup {
  readonly commands: readonly string[]
}

interface Tip {
  readonly command: string
}

interface Context {
  readonly page: Model.Page
  readonly cards: readonly Model.Card[]
}

const tipGroupsWithScoreCalculation: readonly (TipGroup & {
  readonly calculateScore: (context: Context) => number
})[] = [
  {
    commands: ['I want to mask my URLs', 'Help me shorten and mask my links', 'Make me a link redirecter'],
    calculateScore: context =>
      context.page.extensionConfiguration.extensionIds.includes('zettel.link-redirect') ? 0 : 5,
  },
  {
    commands: [
      'I want to schedule a time tomorrow on working hours with my firend',
      "Help me schedule a call. I'm free tomorrow from 9am to 11am. Every call is 30min",
      'Make me a meeting scheduler',
    ],
    calculateScore: context => (context.page.extensionConfiguration.extensionIds.includes('zettel.scheduler') ? 0 : 5),
  },
  // {
  //   command: "Make me a tool to organize my team's daily standup",
  //   calculateScore: context => (context.page.extensionConfiguration.extensionIds.includes('zettel.daily-standup') ? 0 : 3),
  // },
  // {
  //   command: "Check in my team's mood everyday",
  //   calculateScore: context => (context.page.extensionConfiguration.extensionIds.includes('zettel.mood-tracker') ? 0 : 3),
  // },
  // {
  //   command: 'Help my team set 3 daily goals to stay focused',
  //   calculateScore: context => (context.page.extensionConfiguration.extensionIds.includes('zettel.daily-focus') ? 0 : 3),
  // },
  {
    commands: ['Back it up to Google Doc', 'Synchronize my cards into Google Docs'],
    calculateScore: context =>
      ['zettel.daily-standup', 'zettel.mood-tracker', 'zettel.daily-focus', 'zettel.link-summarizer'].some(
        extensionId => context.page.extensionConfiguration.extensionIds.includes(extensionId)
      )
        ? 5
        : context.cards.length > 0
        ? 4
        : 0,
  },
  // {
  //   command: 'View it like Pinterest',
  //   calculateScore: context =>
  //     ['zettel.daily-standup', 'zettel.mood-tracker', 'zettel.daily-focus'].some(extensionId =>
  //       context.page.extensionConfiguration.extensionIds.includes(extensionId)
  //     )
  //       ? 5
  //       : 0,
  // },
  {
    commands: ['Make it a public page. Give me the URL'],
    calculateScore: context =>
      context.page.public
        ? 0
        : ['zettel.scheduler'].some(extensionId =>
            context.page.extensionConfiguration.extensionIds.includes(extensionId)
          )
        ? 5
        : context.cards.length > 0
        ? 4
        : 2,
  },
  // {
  //   command: 'View cards in grid',
  //   calculateScore: context =>
  //     ['zettel.scheduler', 'zettel.mood-tracker'].some(extensionId => context.page.extensionConfiguration.extensionIds.includes(extensionId))
  //       ? 5
  //       : 0,
  // },
  // {
  //   command: 'Track NFT collection price',
  //   calculateScore: context => (context.page.extensionConfiguration.extensionIds.includes('zettel.nft-collection-info') ? 0 : 3),
  // },
  // {
  //   command: 'Show me ETH wallet balance',
  //   calculateScore: context => (context.page.extensionConfiguration.extensionIds.includes('zettel.wallet-address-balance') ? 0 : 3),
  // },
  // {
  //   command: 'Convert ETH to USD',
  //   calculateScore: context => (context.page.extensionConfiguration.extensionIds.includes('zettel.eth-fiat') ? 0 : 3),
  // },
  // {
  //   command: 'Check in with my mood everyday',
  //   calculateScore: context => (context.page.extensionConfiguration.extensionIds.includes('zettel.mood-tracker') ? 0 : 3),
  // },
  // {
  //   command: 'Summarize all links',
  //   calculateScore: context => (context.page.extensionConfiguration.extensionIds.includes('zettel.link-summarizer') ? 0 : 3),
  // },
  // {
  //   command: 'Preview all links',
  //   calculateScore: context => (context.page.extensionConfiguration.extensionIds.includes('zettel.link-preview') ? 0 : 3),
  // },
  // {
  //   command: 'Stop preview links',
  //   calculateScore: context => (context.page.extensionConfiguration.extensionIds.includes('zettel.link-preview') ? 2 : 0),
  // },
  // {
  //   command: 'Help my team organize daily standup',
  //   calculateScore: context => (context.page.extensionConfiguration.extensionIds.includes('zettel.daily-standup') ? 0 : 3),
  // },
]

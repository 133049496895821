import { useLayoutEffect } from 'react'
import { DemoMode } from '../../../../../../modules/demo-mode'
import { useAppHistoryControl } from '../../../../modules/app-history'
import { Authentication } from '../../../../modules/authentication'
import { useLoadingLogo, LoadingReason } from '../../../../modules/loading-logo'
import { usePersistent, PersistentKey } from '../../../../modules/persistent'
import { routes } from '../../modules/routes'

export function InitializingPage() {
  const [persistent, authentication, isMutablesDatabaseInitialized] = usePersistent(
    PersistentKey.Authentication,
    PersistentKey.IsMutablesDatabaseInitialized
  )

  const { then } = routes.initializing.useQuery()

  const { demoContent } = DemoMode.useContent()

  const { addLoadingReason, removeLoadingReason } = useLoadingLogo()

  const { appHistoryControlStatic } = useAppHistoryControl()

  useLayoutEffect(() => {
    if (DemoMode.data && !demoContent) {
      addLoadingReason(LoadingReason.FetchingDemoContent)
      persistent(PersistentKey.Authentication).reset()
    } else if (DemoMode.data && demoContent && !authentication) {
      persistent(PersistentKey.Authentication).set(
        Authentication.decode(demoContent.accessToken, demoContent.refreshToken)
      )
      removeLoadingReason(LoadingReason.FetchingDemoContent)
    } else if (!authentication) {
      appHistoryControlStatic.push(routes.signOut.getPath({}, {}))
    } else if (!isMutablesDatabaseInitialized) {
      addLoadingReason(LoadingReason.WorkspaceInitialization)
    } else {
      appHistoryControlStatic.push(
        then === 'launcher' ? routes.launcher.getPath({}, {}) : routes.main.getPath({}, {}),
        { clearHistory: true }
      )
      removeLoadingReason(LoadingReason.WorkspaceInitialization)
    }
  }, [persistent, authentication, isMutablesDatabaseInitialized, demoContent])

  return <></>
}

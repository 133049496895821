import { Persistent } from '../persistent'
import { EphemeralKey, EphemeralType } from './types'

export function getDefaultValues(persistent: Persistent): Readonly<EphemeralType> {
  return {
    [EphemeralKey.RecentlyConnectedToServer]: false,
    [EphemeralKey.TimestampCorrection]: undefined,
    [EphemeralKey.SynchronizingActions]: false,
    [EphemeralKey.SynchronizingActionsDebounced]: false,
    [EphemeralKey.UploadingFileDictionary]: {},
    [EphemeralKey.OnMemoryModelsRegistrations]: {},
    [EphemeralKey.OnMemoryModelsToBeCleared]: {},
    [EphemeralKey.NewlyUpdatedExtensionIds]: [],
  }
}

import { IconButton, makeStyles, Paper } from '@material-ui/core'
import classNames from 'classnames'
import { PropsWithChildren, ReactNode } from 'react'
import { useContexts } from '../../../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../../../modules/custom-icon'
import { TypographyNoWrap } from '../../../../../../../../../../TypographyNoWrap'
import { PagePanel } from '../PagePanel'

const useStyles = makeStyles(
  theme => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(2),
      padding: theme.spacing(1),
    },
    header: {
      height: theme.spacing(4.5),
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    close: {
      flex: 'none',
      padding: theme.spacing(1),
    },
    body: {
      flex: 'auto',
      overflow: 'auto',
    },
  }),
  { name: 'Layout' }
)

export function Layout({
  title,
  children,
  className,
  bodyClassName,
}: PropsWithChildren<{ title?: ReactNode; className?: string; bodyClassName?: string }>) {
  const {
    refs: { setSideBarMode },
    sideBarMode,
  } = useContexts(PagePanel.Contexts)

  const classes = useStyles()

  return (
    <Paper elevation={0} className={classNames(classes.root, className)}>
      {(sideBarMode || title) && (
        <div className={classes.header}>
          {sideBarMode && (
            <IconButton size="small" className={classes.close} onClick={() => setSideBarMode(undefined)}>
              <CustomIcon name="Close" size={2.5} />
            </IconButton>
          )}

          {title && <TypographyNoWrap variant="subtitle1">{title}</TypographyNoWrap>}
        </div>
      )}

      <div className={classNames(classes.body, bodyClassName)}>{children}</div>
    </Paper>
  )
}

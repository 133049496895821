import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  theme => ({
    root: {
      paddingRight: theme.spacing(1),
      borderRadius: theme.spacing(1),
      userSelect: 'none',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    selected: {
      backgroundColor: theme.palette.action.selected,
      '&:hover': {
        backgroundColor: theme.palette.action.selected,
      },
    },
    isDraggingOver: {
      backgroundColor: theme.palette.action.hover,
    },
    pageAvatar: {
      margin: theme.spacing(1, 2, 1, 1),
    },
    infoWrapper: {
      width: 0,
    },
    infoWrapperUnread: {
      '& $name': {
        fontWeight: 900,
      },
      '& $snippet': {
        color: theme.palette.text.primary,
      },
    },
    nameWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(0.5),
    },
    name: {},
    badges: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    snippetWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    snippet: {},
    badge: {
      marginLeft: theme.spacing(1),
    },
    membersIcon: {
      marginLeft: theme.spacing(1),
    },
    timestamp: {
      marginLeft: theme.spacing(1),
      color: theme.palette.text.disabled,
      whiteSpace: 'pre',
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        marginRight: theme.spacing(1),
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    formInput: {
      padding: 0,
    },
  }),
  { name: 'PageItem' }
)

import { categoriesData, Emoji, EmojiData } from 'emoji-data-ts'

export const emojiData = new EmojiData()

export type { Emoji } from 'emoji-data-ts'

export enum EmojiCategory {
  SmileysAndEmotion = 'Smileys & Emotion',
  PeopleAndBody = 'People & Body',
  AnimalsAndNature = 'Animals & Nature',
  FoodAndDrink = 'Food & Drink',
  Activities = 'Activities',
  TravelAndPlaces = 'Travel & Places',
  Objects = 'Objects',
  Symbols = 'Symbols',
  Flags = 'Flags',
}

export const emojiCategories = categoriesData as readonly EmojiCategory[]

export const emojiSkinToneKeys = ['', '', '1F3FB', '1F3FC', '1F3FD', '1F3FE', '1F3FF'] as const

export const emojiColonRegex = /(:[^\s:]+(?:::skin-tone-[2-6])?:)/

// TODO: Check why do we need this? Isn't there a dependable property emoji.char available?
export function getEmojiCharacter(emoji: Emoji): string {
  return emoji.unified
    .split('-')
    .map(unifiedCharacter => String.fromCodePoint(parseInt(unifiedCharacter, 16)))
    .join('')
}

import mixpanel from 'mixpanel-browser'
import { webConfig } from '../../web-config'
import { Account } from '../types'

export function setMixpanelUserIdentifier(account: Account | undefined, userIdentifier: string) {
  if (webConfig.analytics.disabled || webConfig.analytics.mixpanel.disabled) return

  try {
    mixpanel.identify(userIdentifier)
    mixpanel.register(account || {})
  } catch (error) {
    log.error(error)
  }
}

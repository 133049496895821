import { useEffect, useMemo } from 'react'
import { Broadcaster, BroadcasterForClient } from '../../../../modules/service-worker'
import { ServiceWorkerMessageType } from './types'

export function useNewBroadcaster(serviceWorkerIsReady: boolean): {
  broadcaster: Broadcaster | undefined
} {
  const broadcaster = useMemo(
    () => (serviceWorkerIsReady ? new BroadcasterForClient(ServiceWorkerMessageType.Broadcaster) : undefined),
    [serviceWorkerIsReady]
  )

  useEffect(
    () => () => {
      if (broadcaster instanceof BroadcasterForClient) {
        broadcaster.dispose()
      }
    },
    [broadcaster]
  )

  return {
    broadcaster,
  }
}

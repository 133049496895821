import { delay, Id, Timestamp } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import Dexie from 'dexie'
import { getEnumKeyValues } from '../../../../../helpers/getEnumKeyValues'
import { arrayHelpers } from '../../../../../helpers/native/arrayHelpers'
import { dateHelpers } from '../../../../../helpers/native/dateHelpers'
import { DemoMode } from '../../../../../modules/demo-mode'
import { PeekMode } from '../../../../../modules/peek-mode'
import { Broadcaster } from '../../../../../modules/service-worker'
import { webConfig } from '../../../../../modules/web-config'
import { ActionModel } from '../../models'
import { BroadcasterChannel } from '../../service-worker'
import { Database } from '../Database'
import { DatabaseTable } from '../DatabaseTable'

export class MutablesDatabase implements Database {
  private readonly dexie!: Dexie

  private readonly models: {
    readonly [T in Model.Type]: DatabaseTable<Model.ByType<T>>
  }

  private idledMutations: Model.ByType[] = []
  private idledMutationsTimeout?: any
  private idledMutationsFirstTimestamp?: Timestamp

  private readonly subscriptions: Record<
    string,
    MutablesDatabase.EventSubscription & { promise?: void | Promise<void> }
  > = {}

  constructor(private readonly broadcaster: Broadcaster) {
    if (DemoMode.data || PeekMode.data) {
      this.models = arrayHelpers.toDictionary(
        getEnumKeyValues<Model.Type>(Model.Type).map(({ value }) => value),
        mutableModelType => mutableModelType,
        mutableModelType => DatabaseTable.createOnMemory('id') as DatabaseTable<any>
      )

      return
    }

    this.dexie = new Dexie('MutablesDatabase')

    this.dexie
      .version(36)
      .stores({
        ACCOUNT: 'id',
        USER: 'id',
        PAGE: 'id, *memberUserIds',
        CARD: 'id, version, createdAt, updatedAt, pageId, sequence',
        BADGE: 'id, updatedAt',
      })
      .upgrade(transaction => {
        // This means we may safely remove the older version migrations:
        return Promise.all(transaction.db.tables.map(table => table.clear()))
      })

    this.models = arrayHelpers.toDictionary(
      getEnumKeyValues<Model.Type>(Model.Type).map(({ value }) => value),
      mutableModelType => mutableModelType,
      mutableModelType => DatabaseTable.createOnDexie(this.dexie, mutableModelType) as DatabaseTable<any>
    )

    this.dexie.open()

    broadcaster.addMessageEventListener<MutablesDatabase.BroadcasterMessage>(
      BroadcasterChannel.MutablesDatabase,
      message => this.fire(message)
    )
  }

  dispose(): void {
    if (DemoMode.data || PeekMode.data) return

    this.dexie.close()
  }

  async clearAll(): Promise<void> {
    if (DemoMode.data || PeekMode.data) {
      Object.values(this.models).forEach(table => table.memory.deleteAll())
      return
    }

    await Promise.all(this.dexie.tables.map(table => table.clear()))
  }

  reduceMutation(model: Model.ByType): Promise<void> {
    return this.reduceMutations([model], { fireMutationsEvent: true })
  }

  async reduceMutations(
    models: readonly Model.ByType[],
    options?: {
      fireMutationsEvent?: boolean
      fireReloadEvent?: boolean
    }
  ): Promise<void> {
    const entitiesByType = arrayHelpers.groupBy(models, 'type')
    const types = Object.keys(entitiesByType) as Model.Type[]

    const unifiedUpdatingModelsList = await Promise.all(
      types.map(async type => {
        const unifiedModels = entitiesByType[type]
        const unifiedModelsDictionary: Record<Id, Model.ByType> = {}
        unifiedModels.forEach(model => {
          if (!(model.id in unifiedModelsDictionary) || model.version > unifiedModelsDictionary[model.id].version) {
            unifiedModelsDictionary[model.id] = model
          }
        })

        if (DemoMode.data || PeekMode.data) {
          const keys = Object.keys(unifiedModelsDictionary)
          const existingModels = keys.map(key => this.models[type].memory.get(key))
          existingModels.forEach(model => {
            if (
              model &&
              model.id in unifiedModelsDictionary &&
              model.version > unifiedModelsDictionary[model.id].version
            ) {
              delete unifiedModelsDictionary[model.id]
            }
          })
          const unifiedUpdatingModels = Object.values(unifiedModelsDictionary)
          if (unifiedUpdatingModels.length > 0) {
            await delay(0) // Some parts of the UI depend on this operation to be actually asynchronous
            this.models[type].memory.putAll(unifiedUpdatingModels as readonly Model.ByType<any>[])
          }
          return unifiedUpdatingModels
        }

        return this.dexie.transaction('rw', this.models[type].dexie, async (): Promise<Model.ByType[]> => {
          const keys = Object.keys(unifiedModelsDictionary)
          const existingModels = await this.models[type].dexie.bulkGet(keys)
          existingModels.forEach(model => {
            if (
              model &&
              model.id in unifiedModelsDictionary &&
              model.version > unifiedModelsDictionary[model.id].version
            ) {
              delete unifiedModelsDictionary[model.id]
            }
          })
          const unifiedUpdatingModels = Object.values(unifiedModelsDictionary)
          if (unifiedUpdatingModels.length > 0) {
            await (this.models[type].dexie as Dexie.Table<Model.ByType, Id>).bulkPut(unifiedUpdatingModels)
          }
          return unifiedUpdatingModels
        })
      })
    )

    if (options?.fireReloadEvent) {
      this.fire({ type: 'reload' }, true)
    }

    if (options?.fireMutationsEvent) {
      const updatingModels = unifiedUpdatingModelsList.flat()

      if (updatingModels.length > 0) {
        const currentTimestamp = dateHelpers.getCurrentTimestamp()

        arrayHelpers.extend(this.idledMutations, updatingModels)
        this.idledMutationsFirstTimestamp = this.idledMutationsFirstTimestamp ?? currentTimestamp

        if (this.idledMutationsTimeout) {
          clearTimeout(this.idledMutationsTimeout)
          if (
            this.idledMutations.length > webConfig.timings.mutablesDatabase.mutationEvents.maximumCount ||
            currentTimestamp - this.idledMutationsFirstTimestamp >
              webConfig.timings.mutablesDatabase.mutationEvents.maximumDelay
          ) {
            this.fireIdledMutations()
          }
        } else {
          this.fireIdledMutations()
        }

        this.idledMutationsTimeout = setTimeout(() => {
          delete this.idledMutationsTimeout
          this.fireIdledMutations()
        }, webConfig.timings.mutablesDatabase.mutationEvents.debounce)
      }
    }
  }

  private fireIdledMutations(): void {
    // TODO: It doesn't probably hurt, but do we really need this timeout?
    setTimeout(() => {
      if (this.idledMutations.length === 0) return

      const models = this.idledMutations
      this.idledMutations = []
      delete this.idledMutationsFirstTimestamp

      this.fire({ type: 'mutations', models }, true)
    })
  }

  async revertUpsertModelActions(actions: readonly ActionModel<ActionModel.Type.UpsertModel>[]): Promise<void> {
    if (DemoMode.data || PeekMode.data) {
      for (let i = 0; i < actions.length; i += 1) {
        const action = actions[i]
        const table = this.models[action.newModel.type].memory
        if (action.oldModel) {
          table.put(action.oldModel as Model.ByType<any>)
        } else {
          table.delete(action.newModel.id)
        }
      }
    } else {
      const tables = arrayHelpers
        .distinctString(actions.map(action => action.newModel.type))
        .map(type => this.models[type].dexie)

      await this.dexie.transaction('rw', tables, async () => {
        for (let i = 0; i < actions.length; i += 1) {
          const action = actions[i]
          const table = this.models[action.newModel.type].dexie as Dexie.Table<Model.ByType, Id>

          // Reverting actions should occur in order, we need to revert them from the newest back to the oldest:
          if (action.oldModel) {
            await table.put(action.oldModel) // eslint-disable-line no-await-in-loop
          } else {
            await table.delete(action.newModel.id) // eslint-disable-line no-await-in-loop
          }
        }
      })
    }

    this.fire({ type: 'reload' }, true)
  }

  // TODO: Improve for new models as well, specially User:
  // TODO: We need to keep an eye on model deletion, and try to clean up all the dead models after that.
  // todo: Delete anything which is about to be filtered out and is not going to be displayed in the UI.
  async cleanIrrelevantData(accountId: Id): Promise<void> {
    if (DemoMode.data || PeekMode.data) return

    const areAnyPagesDeleted = await this.dexie.transaction(
      'readwrite',
      this.models[Model.Type.Account].dexie,
      this.models[Model.Type.Card].dexie,
      this.models[Model.Type.Page].dexie,
      async (): Promise<boolean> => {
        const relatedPages = await this.models[Model.Type.Page].dexie.where('memberUserIds').equals(accountId).toArray()
        const relatedPageIds = relatedPages.map(page => page.id)
        const unrelatedPagesCount = await this.models[Model.Type.Page].dexie.where('id').noneOf(relatedPageIds).delete()
        if (unrelatedPagesCount === 0) return false
        await Promise.all([
          this.models[Model.Type.Account].dexie.where('id').notEqual(accountId).delete(),
          this.models[Model.Type.Card].dexie.where('pageId').noneOf(relatedPageIds).delete(),
        ])
        return true
      }
    )

    if (areAnyPagesDeleted) {
      this.fire({ type: 'reload' }, true)
    }
  }

  async get<T extends Model.Type = Model.Type>(type: T, id: Id): Promise<Model.ByType<T> | undefined> {
    const model =
      DemoMode.data || PeekMode.data ? this.models[type].memory.get(id) : await this.models[type].dexie.get(id)
    return model?.isDeleted ? undefined : (model as Model.ByType<T> | undefined)
  }

  async getAccount(id: Id): Promise<Model.Account | undefined> {
    const account =
      DemoMode.data || PeekMode.data
        ? this.models[Model.Type.Account].memory.get(id)
        : await this.models[Model.Type.Account].dexie.get(id)
    return account?.isDeleted ? undefined : account
  }

  async getUser(id: Id): Promise<Model.User | undefined> {
    const user =
      DemoMode.data || PeekMode.data
        ? this.models[Model.Type.User].memory.get(id)
        : await this.models[Model.Type.User].dexie.get(id)
    return user?.isDeleted ? undefined : user
  }

  async getAllUsers() {
    const cards =
      DemoMode.data || PeekMode.data
        ? this.models[Model.Type.User].memory.getAll()
        : await this.models[Model.Type.User].dexie.toArray()
    return cards.filter(card => !card.isDeleted)
  }

  async getPage(id: Id): Promise<Model.Page | undefined> {
    const page =
      DemoMode.data || PeekMode.data
        ? this.models[Model.Type.Page].memory.get(id)
        : await this.models[Model.Type.Page].dexie.get(id)
    return page?.isDeleted ? undefined : page
  }

  async getAllPages(): Promise<Model.Page[]> {
    const pages =
      DemoMode.data || PeekMode.data
        ? this.models[Model.Type.Page].memory.getAll()
        : await this.models[Model.Type.Page].dexie.toArray()
    return pages.filter(page => !page.isDeleted)
  }

  async getCard(id: Id): Promise<Model.Card | undefined> {
    const card =
      DemoMode.data || PeekMode.data
        ? this.models[Model.Type.Card].memory.get(id)
        : await this.models[Model.Type.Card].dexie.get(id)
    return card?.isDeleted ? undefined : card
  }

  // TODO: Needs to be renamed to getCardsForPageOrderedBySequence or something:
  async getCardsForPage(pageId: Id): Promise<Model.Card[]> {
    const cards =
      DemoMode.data || PeekMode.data
        ? arrayHelpers.sortBy(
            this.models[Model.Type.Card].memory.getAll().filter(card => card.pageId === pageId),
            'sequence'
          )
        : await this.models[Model.Type.Card].dexie.where({ pageId }).sortBy('sequence')
    return cards.filter(card => !card.isDeleted)
  }

  async getPageLastCard(pageId: Id): Promise<Model.Card | undefined> {
    return DemoMode.data || PeekMode.data
      ? arrayHelpers.last(
          arrayHelpers
            .sortBy(this.models[Model.Type.Card].memory.getAll(), 'sequence')
            .filter(card => card.pageId === pageId && !card.isDeleted)
        )
      : this.models[Model.Type.Card].dexie
          .orderBy('sequence')
          .filter(card => card.pageId === pageId && !card.isDeleted)
          .last()
  }

  async getPageLatestCardTimestamp(pageId: Id): Promise<Timestamp | undefined> {
    const cardWithLatestTimestamp =
      DemoMode.data || PeekMode.data
        ? arrayHelpers.last(
            arrayHelpers
              .sortBy(this.models[Model.Type.Card].memory.getAll(), 'updatedAt')
              .filter(card => card.pageId === pageId && !card.isDeleted)
          )
        : await this.models[Model.Type.Card].dexie
            .orderBy('updatedAt')
            .filter(card => card.pageId === pageId && !card.isDeleted)
            .last()
    return cardWithLatestTimestamp?.updatedAt
  }

  async getAllCards() {
    const cards =
      DemoMode.data || PeekMode.data
        ? arrayHelpers.sortBy(this.models[Model.Type.Card].memory.getAll(), 'createdAt')
        : await this.models[Model.Type.Card].dexie.orderBy('createdAt').toArray() // TODO: The orderBy() should not be necessary
    return cards.filter(card => !card.isDeleted)
  }

  async getAllBadges(): Promise<Model.Badge[]> {
    const badges =
      DemoMode.data || PeekMode.data
        ? this.models[Model.Type.Badge].memory.getAll()
        : await this.models[Model.Type.Badge].dexie.toArray()
    return badges.filter(badge => !badge.isDeleted)
  }

  // TODO: Make subscriptions like ID based dictionary, so that the search for the related subscription becomes more efficient

  subscribe(subscription: MutablesDatabase.EventSubscription): { subscriptionKey: string } {
    let subscriptionKey: string
    do subscriptionKey = Math.random().toString()
    while (subscriptionKey in this.subscriptions)
    this.subscriptions[subscriptionKey] = subscription
    return { subscriptionKey }
  }

  unsubscribe(subscriptionKey: string): void {
    delete this.subscriptions[subscriptionKey]
  }

  private fire(message: MutablesDatabase.BroadcasterMessage, propagateEventThroughServiceWorker?: boolean): void {
    const subscriptions = Object.values(this.subscriptions) // It's required, because some subscription execution may change this.subscriptions directly because of setStates in the middle of the process

    switch (message.type) {
      case 'mutations':
        subscriptions.forEach(subscription => {
          subscription.promise = subscription.promise
            ? subscription.promise.finally(() => subscription.handleMutations(message.models))
            : subscription.handleMutations(message.models)
        })
        break

      case 'reload':
        subscriptions.forEach(subscription => {
          subscription.promise = subscription.promise
            ? subscription.promise.finally(() => subscription.handleReload())
            : subscription.handleReload()
        })
        break
    }

    if (propagateEventThroughServiceWorker) {
      this.broadcaster.postMessage<MutablesDatabase.BroadcasterMessage>(BroadcasterChannel.MutablesDatabase, message)
    }
  }
}

export namespace MutablesDatabase {
  export interface EventSubscription {
    handleMutations(models: readonly Model.ByType[]): void | Promise<void>
    handleReload(): void | Promise<void>
  }

  export type BroadcasterMessage =
    | {
        readonly type: 'mutations'
        readonly models: readonly Model.ByType[]
      }
    | {
        readonly type: 'reload'
      }
}

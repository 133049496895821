import { useEffectOnce } from 'react-use'
import { sendAnalyticEvent } from '../../../../../../modules/analytics'
import { useAppHistoryControl } from '../../../../modules/app-history'
import { ClearDatabasesOptions, useClearDatabases } from '../../../../modules/databases'
import { useEphemeral, EphemeralKey } from '../../../../modules/ephemeral'
import { useLoadingLogo, LoadingReason } from '../../../../modules/loading-logo'
import { useOnboardingControl } from '../../../../modules/onboarding'
import { usePersistent, PersistentKey } from '../../../../modules/persistent'
import { routes } from '../../modules/routes'

export function SignOutPage() {
  const { persistent } = usePersistent()

  const { ephemeral } = useEphemeral()

  const { abortOnboardingTourStatic } = useOnboardingControl()

  const { confirmClearingDatabasesIfNecessary, clearDatabases } = useClearDatabases()

  const { addLoadingReason, removeLoadingReason } = useLoadingLogo()

  const { appHistoryControlStatic } = useAppHistoryControl()

  useEffectOnce(() => {
    signOut()

    async function signOut(): Promise<void> {
      sendAnalyticEvent('Signout', 'Signout user')

      abortOnboardingTourStatic()

      const clearDatabasesOptions: ClearDatabasesOptions = {
        persistentDatabase: false,
        actionsDatabase: true,
        mutablesDatabase: true,
        extensionsDatabase: false,
      }

      const confirmed = await confirmClearingDatabasesIfNecessary(clearDatabasesOptions)

      if (!confirmed) {
        appHistoryControlStatic.goBackward()
        return
      }

      addLoadingReason(LoadingReason.SigningOut)

      await clearDatabases(clearDatabasesOptions)

      removeLoadingReason(LoadingReason.SigningOut)

      persistent.reset({
        [PersistentKey.DeviceId]: false,
        [PersistentKey.LastDisplayedChangeLog]: false,
        [PersistentKey.Authentication]: true,
        [PersistentKey.IsMutablesDatabaseInitialized]: true,
        [PersistentKey.FunnelSequences]: true,
        [PersistentKey.ThemeType]: true,
        [PersistentKey.LanguageCode]: true,
        [PersistentKey.SideBarSize]: true,
        [PersistentKey.SideBarVisible]: true,
        [PersistentKey.PageRecentData]: true,
        [PersistentKey.IsCardDialogFullScreen]: false,
        [PersistentKey.OnboardedTours]: false,
        [PersistentKey.RecentSearches]: true,
        [PersistentKey.ShowNewSpaceHelp]: true,
        [PersistentKey.ShowChromeExtensionPromotionBanner]: true,
      })

      ephemeral.reset({
        [EphemeralKey.RecentlyConnectedToServer]: true,
        [EphemeralKey.TimestampCorrection]: true,
        [EphemeralKey.SynchronizingActions]: true,
        [EphemeralKey.SynchronizingActionsDebounced]: true,
        [EphemeralKey.UploadingFileDictionary]: true,
        [EphemeralKey.OnMemoryModelsRegistrations]: false,
        [EphemeralKey.OnMemoryModelsToBeCleared]: false,
        [EphemeralKey.NewlyUpdatedExtensionIds]: false,
      })

      appHistoryControlStatic.push(routes.signIn.getPath({}, {}), { clearHistory: true })
    }
  })

  return <></>
}

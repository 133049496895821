import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useCopyToClipboard } from 'react-use'
import { sendAnalyticEvent } from '../../../../../../../../../../../../../modules/analytics'
import { CustomIcon } from '../../../../../../../../../../../../../modules/custom-icon'
import { usePersistent, PersistentKey } from '../../../../../../../../../../../modules/persistent'
import { ButtonField } from '../../ButtonField'
import { FieldPaper } from '../../FieldPaper'

const useStyles = makeStyles(
  theme => ({
    infoWrapper: {
      display: 'flex',
    },
    info: {
      flexGrow: 1,
    },
    button: {
      padding: theme.spacing(1.25),
      borderRadius: theme.spacing(1),
    },
  }),
  { name: 'SessionField' }
)

export function SessionField({ className }: { className?: string }) {
  const [, authentication] = usePersistent(PersistentKey.Authentication)

  const [show, setShow] = useState(false)

  const [, copyToClipboard] = useCopyToClipboard()

  const classes = useStyles()

  if (!authentication) return null

  return (
    <div className={className}>
      <ButtonField
        onClick={() => {
          sendAnalyticEvent('Account', 'Show debug info')
          setShow(!show)
        }}
      >
        {show ? 'Hide' : 'Show'} debug info
      </ButtonField>

      {show && (
        <FieldPaper>
          <div className={classes.infoWrapper}>
            <Typography variant="body2" color="textSecondary" className={classes.info}>
              User ID:
              <br />
              {authentication.decodedAccessToken.userId}
            </Typography>
            <Tooltip title="Copy UserID to clipboard">
              <IconButton
                className={classes.button}
                onClick={() => {
                  sendAnalyticEvent('Account', 'Copy UserID')
                  copyToClipboard(authentication.decodedAccessToken.userId)
                }}
              >
                <CustomIcon name="Copy" />
              </IconButton>
            </Tooltip>
          </div>

          <div className={classes.infoWrapper}>
            <Typography variant="body2" color="textSecondary" className={classes.info}>
              Device ID:
              <br />
              {authentication.decodedAccessToken.deviceId}
            </Typography>
            <Tooltip title="Copy DeviceID to clipboard">
              <IconButton
                className={classes.button}
                onClick={() => {
                  sendAnalyticEvent('Account', 'Copy DeviceId')
                  copyToClipboard(authentication.decodedAccessToken.deviceId)
                }}
              >
                <CustomIcon name="Copy" />
              </IconButton>
            </Tooltip>
          </div>

          <div className={classes.infoWrapper}>
            <Typography variant="body2" color="textSecondary" className={classes.info}>
              Session ID:
              <br />
              {authentication.decodedAccessToken.sessionId}
            </Typography>
            <Tooltip title="Copy SessionID to clipboard">
              <IconButton
                className={classes.button}
                onClick={() => {
                  sendAnalyticEvent('Account', 'Copy SessionId')
                  copyToClipboard(authentication.decodedAccessToken.sessionId)
                }}
              >
                <CustomIcon name="Copy" />
              </IconButton>
            </Tooltip>
          </div>
        </FieldPaper>
      )}
    </div>
  )
}

import { createContext, PropsWithChildren, useContext, useMemo } from 'react'

export function ExtensionRolesProvider({ role, children }: PropsWithChildren<{ role: string }>) {
  const parentRoles = useContext(ExtensionRolesProvider.Context)

  const roles = useMemo(() => [...parentRoles, role], [parentRoles, role])

  return <ExtensionRolesProvider.Context.Provider value={roles}>{children}</ExtensionRolesProvider.Context.Provider>
}

export namespace ExtensionRolesProvider {
  export const Context = createContext<readonly string[]>([])
}

import { useContext } from 'react'
import { ContentContext } from './ContentContext'
import { Content } from './types'

export function useContent(): { demoContent: Content } {
  const demoContent = useContext(ContentContext)

  return {
    demoContent,
  }
}

import { useContext } from 'react'
import { Context } from './Context'
import { PostMessage } from './types'

export function usePostMessage(): { postMessageStatic: PostMessage } {
  const { postMessageStatic } = useContext(Context)

  return {
    postMessageStatic,
  }
}

import { Id, Timestamp } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useMemo } from 'react'
import { dateHelpers } from '../../../helpers/native/dateHelpers'
import { useRefWrap } from '../../../hooks/useRefWrap'
import { useDatabases } from '../modules/databases'
import { EphemeralKey, useEphemeral } from '../modules/ephemeral'
import { ActionModel } from '../modules/models'
import { useGenerateModelId } from './useGenerateModelId'

export function useApplyAction(): {
  applyActionStatic: {
    createModel<T extends Model.Type>(
      model: Omit<Model.ByType<T>, 'id' | 'type' | 'version' | 'createdAt' | 'updatedAt' | 'isDeleted'> & {
        readonly type: T
        readonly id?: Id
      }
    ): Promise<ActionModel<ActionModel.Type.UpsertModel>>

    updateModel<T extends Model.Type>(
      updates: Partial<Omit<Model.ByType<T>, 'id' | 'type' | 'version' | 'createdAt' | 'updatedAt' | 'isDeleted'>> & {
        readonly type: T
        readonly id: Id
      }
    ): Promise<ActionModel<ActionModel.Type.UpsertModel>>

    deleteModel(model: {
      readonly type: Model.Type
      readonly id: Id
    }): Promise<ActionModel<ActionModel.Type.UpsertModel>>

    invitePageMemberByEmail(
      invitation: Omit<ActionModel<ActionModel.Type.InvitePageMemberByEmail>, 'type' | 'id'>
    ): Promise<ActionModel<ActionModel.Type.InvitePageMemberByEmail>>
  }
} {
  const { ephemeral } = useEphemeral()

  const { databases } = useDatabases()

  const { generateModelIdStatic } = useGenerateModelId()

  const contentRef = useRefWrap({
    databases,
    getCurrentTimestamp(): Timestamp {
      return dateHelpers.getCurrentTimestamp(ephemeral(EphemeralKey.TimestampCorrection).get())
    },
  })

  return {
    applyActionStatic: useMemo(
      () => ({
        async createModel(model) {
          const createdAt = contentRef.current.getCurrentTimestamp()
          const newModel: Model.ByType = {
            version: 0,
            createdAt,
            updatedAt: createdAt,
            isDeleted: false,
            ...(model as any),
            id: model.id ?? generateModelIdStatic(),
          }
          const action: ActionModel<ActionModel.Type.UpsertModel> = {
            type: ActionModel.Type.UpsertModel,
            id: generateModelIdStatic(),
            newModel,
          }
          await contentRef.current.databases.mutablesDatabase.reduceMutation(newModel)
          await contentRef.current.databases.actionsDatabase.add(action)
          return action
        },

        async updateModel(updates) {
          const oldModel = await contentRef.current.databases.mutablesDatabase.get(
            updates.type as Model.Type,
            updates.id
          )
          const newModel: Model.ByType = {
            ...oldModel!,
            ...updates,
            updatedAt: contentRef.current.getCurrentTimestamp(),
          }
          const action: ActionModel<ActionModel.Type.UpsertModel> = {
            type: ActionModel.Type.UpsertModel,
            id: generateModelIdStatic(),
            oldModel,
            newModel,
          }
          await contentRef.current.databases.mutablesDatabase.reduceMutation(newModel)
          await contentRef.current.databases.actionsDatabase.add(action)
          return action
        },

        async deleteModel(model) {
          const oldModel = await contentRef.current.databases.mutablesDatabase.get(model.type, model.id)
          const newModel: Model.ByType = {
            ...oldModel!,
            updatedAt: contentRef.current.getCurrentTimestamp(),
            isDeleted: true,
          }
          const action: ActionModel<ActionModel.Type.UpsertModel> = {
            type: ActionModel.Type.UpsertModel,
            id: generateModelIdStatic(),
            oldModel,
            newModel,
          }
          await contentRef.current.databases.mutablesDatabase.reduceMutation(newModel)
          await contentRef.current.databases.actionsDatabase.add(action)
          return action
        },

        async invitePageMemberByEmail(invitation) {
          const action: ActionModel<ActionModel.Type.InvitePageMemberByEmail> = {
            type: ActionModel.Type.InvitePageMemberByEmail,
            id: generateModelIdStatic(),
            ...invitation,
          }
          await contentRef.current.databases.actionsDatabase.add(action)
          return action
        },
      }),
      []
    ),
  }
}

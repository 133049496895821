import { EqualityCheckers, KeyValueStoreTypeBase } from '../types'
import { KeyValueEngine } from './KeyValueEngine'
import { KeyValueEngineDatabase } from './types'

/**
 * Uses the database to get persisted.
 * If the database provides synchronization between other client windows
 * through the service worker, then this engine will be synchronized as well.
 */
export class KeyValueEngineByDatabase<
  KeyValueStoreType extends KeyValueStoreTypeBase
> extends KeyValueEngine<KeyValueStoreType> {
  private constructor(
    getDefaultValues: () => Readonly<KeyValueStoreType>,
    equalityCheckers: EqualityCheckers<KeyValueStoreType>,
    initialValues: Partial<KeyValueStoreType>,
    protected readonly database: KeyValueEngineDatabase<KeyValueStoreType>
  ) {
    super(getDefaultValues, equalityCheckers, initialValues)
  }

  static async create<KeyValueStoreType extends KeyValueStoreTypeBase>(
    getDefaultValues: () => Readonly<KeyValueStoreType>,
    equalityCheckers: EqualityCheckers<KeyValueStoreType>,
    database: KeyValueEngineDatabase<KeyValueStoreType>
  ): Promise<KeyValueEngineByDatabase<KeyValueStoreType>> {
    const initialValues = await database.getAll()
    const engine = new KeyValueEngineByDatabase<KeyValueStoreType>(
      getDefaultValues,
      equalityCheckers,
      {
        ...getDefaultValues(),
        ...initialValues,
      },
      database
    )
    return engine
  }

  async set<K extends keyof KeyValueStoreType>(key: K, newValue: KeyValueStoreType[K]): Promise<void> {
    super.set(key, newValue)
    await this.database.set(key, newValue)
  }

  watch<K extends keyof KeyValueStoreType>(key: K, handler: () => void): void {
    this.database.subscribe(key, newValue => {
      this.values[key] = newValue
      handler()
    })
  }
}

import { DependencyList, useEffect, useMemo } from 'react'
import { objectHelpers } from '../../../../helpers/native/objectHelpers'
import { EphemeralKey, OnMemoryModels, useEphemeral } from '../ephemeral'

export function useRegisterOnMemoryModels(getter: () => OnMemoryModels, dependencies: DependencyList): void {
  const {
    ephemeral,
    currentValues: [onMemoryModelsToBeCleared],
  } = useEphemeral(EphemeralKey.OnMemoryModelsToBeCleared)

  const registrationKey = useMemo(() => {
    let key = ''
    while (!key || key in ephemeral(EphemeralKey.OnMemoryModelsRegistrations).get()) {
      key = Math.random().toString().slice(2)
    }
    return key
  }, dependencies)

  useEffect(() => {
    ephemeral(EphemeralKey.OnMemoryModelsRegistrations).set({
      ...ephemeral(EphemeralKey.OnMemoryModelsRegistrations).get(),
      [registrationKey]: getter(),
    })

    return () => {
      ephemeral(EphemeralKey.OnMemoryModelsRegistrations).set(
        objectHelpers.omit(ephemeral(EphemeralKey.OnMemoryModelsRegistrations).get(), registrationKey)
      )
    }
  }, [ephemeral, registrationKey])

  useEffect(() => {
    if (registrationKey in onMemoryModelsToBeCleared) {
      ephemeral(EphemeralKey.OnMemoryModelsToBeCleared).set(
        objectHelpers.omit(ephemeral(EphemeralKey.OnMemoryModelsToBeCleared).get(), registrationKey)
      )
    }
  }, [onMemoryModelsToBeCleared, registrationKey])
}

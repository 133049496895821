import { makeStyles } from '@material-ui/core'
import { useEffect } from 'react'

const useStyles = makeStyles(
  theme => ({
    highlight: {
      backgroundColor: theme.palette.warning.light,
    },
  }),
  { name: 'DomHighlighter' }
)

const SPACE_REGEXP = /(^\s+|\s+$)/g
const END_REGEXP = /^[^\w]+|[^\w]+$/g // Characters to strip from start and end of the input string
const BREAK_REGEXP = /[^\w'-]+/g // Characters used to break up the input string into words

const HIGHLIGHT_TAG = 'MARK'
const SKIP_TAGS = new RegExp(`^(?:${HIGHLIGHT_TAG}|SCRIPT|FORM|SPAN)$`)

export function DomHighlighter({
  targetElement,
  searchPhrase,
}: {
  targetElement?: HTMLElement | null
  searchPhrase: string
}) {
  const classes = useStyles()

  useEffect(() => {
    if (!targetElement || !searchPhrase) return

    const searchTerms = searchPhrase
    // .replace(SPACE_REGEXP, '')
    // .replace(END_REGEXP, '')
    // .replace(BREAK_REGEXP, '|')
    // .replace(/^\||\|$/g, '')
    if (!searchTerms) return

    let matchRegExp: RegExp
    try {
      matchRegExp = new RegExp(`(${searchTerms})`, 'i')
    } catch (error) {
      return
    }

    highlightElement(targetElement)

    function highlightElement(node: ChildNode): void {
      if (SKIP_TAGS.test(node.nodeName)) return

      if (node.hasChildNodes()) {
        for (let i = 0; i < node.childNodes.length; i += 1) {
          highlightElement(node.childNodes[i])
        }
      }

      if (node.nodeType !== 3 /* text node type */) return

      const textNode = node as Text
      const { nodeValue } = textNode
      if (!nodeValue) return

      const execResult = matchRegExp.exec(nodeValue)
      if (!execResult) return

      const match = document.createElement(HIGHLIGHT_TAG)
      match.appendChild(document.createTextNode(execResult[0]))
      match.className = classes.highlight

      const after = textNode.splitText(execResult.index)
      after.nodeValue = after.nodeValue?.substring(execResult[0].length) ?? null
      textNode.parentNode?.insertBefore(match, after)
    }
  }, [targetElement, searchPhrase, classes])

  return <></>
}

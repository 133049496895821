import { makeStyles } from '@material-ui/core'
import { sendAnalyticEvent } from '../../../../../../../../../../modules/analytics'
import { useManageCommands } from '../../../../../../../../../../modules/commands'
import { CustomIcon } from '../../../../../../../../../../modules/custom-icon'
import { TypographyNoWrap } from '../../../../../../../../../TypographyNoWrap'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 3),
      borderRadius: theme.spacing(1),
      gap: theme.spacing(3.5),
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
  { name: 'AddPageItem' }
)

export function AddPageItem() {
  const { runCommandStatic } = useManageCommands()

  const classes = useStyles()

  return (
    <div
      className={classes.root}
      onClick={() => {
        sendAnalyticEvent('Side Bar', 'Click on New Page')
        runCommandStatic('main.createNewPage')
      }}
    >
      <CustomIcon name="Add" />
      <TypographyNoWrap variant="subtitle1">New</TypographyNoWrap>
    </div>
  )
}

import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core'
import { HandlerOutput } from '@zettelooo/commons'
import {
  ClipboardContent,
  IpcChannel,
  LauncherIpcMessageType,
  WindowIpcMessageType,
  WindowType,
} from '@zettelooo/desktop-shared'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import Color from 'color'
import { ComponentRef, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useGetSet } from 'react-use'
import { useCommonStyles } from '../../../../../../../hooks/useCommonStyles'
import { sendAnalyticEvent } from '../../../../../../../modules/analytics'
import { useContexts } from '../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../modules/custom-icon'
import { commonKeyboardCombinations, useKeyboardHandling } from '../../../../../../../modules/keyboard-handler'
import { NavigationArea } from '../../../../../../../modules/navigation'
import { webConfig } from '../../../../../../../modules/web-config'
import { Gap } from '../../../../../../Gap'
import { sendIpcMessage, useIpcMessageHandling } from '../../../../../modules/electron'
import { ExtensionRolesProvider, ExtensionScopeProvider } from '../../../../../modules/extension'
import { Tidio } from '../../../../../modules/tidio'
import { AccountData } from '../../../modules/account-data'
import { CardAdder, useGetCardText } from '../../../modules/card'
import { NewPage } from '../../../modules/NewPage'
import { DialogTransition } from '../../DialogTransition'
import { LauncherButtons } from './LauncherButtons'
import { LauncherNoPermissions } from './LauncherNoPermissions'
import { LauncherSearch } from './LauncherSearch'
import { PageSelect } from './PageSelect'
import { BookmarkMessage, ElectronPermissionStatus } from './types'

const useStyles = makeStyles(
  theme => ({
    body:
      webConfig.environment.agent === 'electron'
        ? {
            backgroundColor: 'transparent',
          }
        : {
            backgroundColor: theme.palette.background.paper,
            margin: 50,
            // background: `url(https://${webConfig.app.domain}/images/${theme.palette.type}/main.webp???) 0/700px`, //!Not to be committed! Just make sure it'll remain commented before committing.
          },
    root: {
      padding: theme.spacing(2),
    },
    container: {
      padding: theme.spacing(1),
      backgroundColor: new Color(theme.palette.background.default).alpha(0.975).string(),
      borderRadius: theme.spacing(1.5),
      overflow: 'hidden',
      boxShadow: theme.shadows[6],
    },
    cardAdder: {
      height: '100%',
    },
  }),
  { name: 'LauncherPage' }
)

export function LauncherPage() {
  const { accountPagesOrdered } = useContexts(AccountData.Contexts)

  const [getQuery, setQuery] = useGetSet('')
  const [getIsComposerOpen, setIsComposerOpen] = useGetSet(false)
  const [getBookmarkMessage, setBookmarkMessage] = useGetSet<BookmarkMessage>({})
  const [getElectronPermissionStatus, setElectronPermissionStatus] = useGetSet(ElectronPermissionStatus.Granted)
  const [composerSelectedPage, setComposerSelectedPage] = useState<Model.Page>()

  const rootRef = useRef<HTMLDivElement>(null)
  const cardAdderRef = useRef<ComponentRef<typeof CardAdder>>(null)

  const openComposeBookmark = useCallback((): HandlerOutput => {
    const message = getBookmarkMessage()
    if (!message.url) return 'not handled'

    setQuery('')
    setIsComposerOpen(true)

    // In order to wait for the composer ref to be set:
    setTimeout(() => {
      cardAdderRef.current?.cardComposer?.reset() // Just in case.
      cardAdderRef.current?.cardComposer?.pasteCommonData({ text: `${message.title || ''}\n${message.url}` })
    })
  }, [])

  const closeLauncherStatic = useCallback((initiatedByElectron = false): void => {
    if (webConfig.environment.agent === 'electron') {
      sendIpcMessage(IpcChannel.Window, {
        type: WindowIpcMessageType.WindowHide,
        windowType: WindowType.Launcher,
        initiatedByElectron,
      })
    }
  }, [])

  useIpcMessageHandling(
    IpcChannel.Window,
    (event, message) => {
      switch (message.type) {
        case WindowIpcMessageType.ElectronRequestHide:
          if (!getQuery() && !getIsComposerOpen()) {
            closeLauncherStatic(true)
          }
          break
      }
    },
    []
  )

  useIpcMessageHandling(
    IpcChannel.Launcher,
    (event, message) => {
      switch (message.type) {
        case LauncherIpcMessageType.ElectronNoAccessibilityPermission:
          setQuery('')
          setIsComposerOpen(false)
          setElectronPermissionStatus(ElectronPermissionStatus.NoAccessibilityPermission)
          sendAnalyticEvent('Launcher', 'no accessibility permission')
          break

        case LauncherIpcMessageType.ElectronNoAutomationPermission:
          setQuery('')
          setIsComposerOpen(false)
          setElectronPermissionStatus(ElectronPermissionStatus.NoAutomationPermission)
          sendAnalyticEvent('Launcher', 'no automation permission')
          break

        case LauncherIpcMessageType.ElectronSetState:
          setQuery('')
          setElectronPermissionStatus(ElectronPermissionStatus.Granted)
          sendAnalyticEvent(
            'Launcher',
            'Capture data',
            JSON.stringify({
              title: message.title ? '***' : null,
              url: message.url ? '***' : null,
              copiedContent: message.copiedContent ? '***' : null,
            })
          )

          if (message.copiedContent) {
            setBookmarkMessage({})
            setIsComposerOpen(true)
            const commonData: ZettelExtensions.CardData.CommonData = {
              text:
                message.copiedContent?.text && message.url
                  ? `${message.copiedContent.text}\n\nSource: ${message.url}`
                  : message.copiedContent?.text || message.url || '',
              // TODO: Also put `html` property if it exists.
            }
            if (!ZettelExtensions.CardData.CommonData.isEmpty(commonData)) {
              cardAdderRef.current?.cardComposer?.reset()
              // The reset above updates the dependencies of the referee, thus we need to wait for the new one to be used below:
              setTimeout(() => {
                cardAdderRef.current?.cardComposer?.pasteCommonData(commonData)
              })
            }
          } else if (message.url) {
            setIsComposerOpen(false)
            setBookmarkMessage(message)
          } else {
            setBookmarkMessage({})
            setIsComposerOpen(false)
          }
          break
      }
    },
    []
  )

  useLayoutEffect(() => {
    const rootElement = rootRef.current!

    const resizeObserver = new ResizeObserver((entries: readonly ResizeObserverEntry[]) => {
      const { width, height } = rootElement.getBoundingClientRect()
      sendIpcMessage(IpcChannel.Launcher, { type: LauncherIpcMessageType.LauncherUpdateSize, width, height })
    })

    resizeObserver.observe(rootElement)

    return () => resizeObserver.disconnect()
  }, [])

  useKeyboardHandling(
    [
      {
        combinations: commonKeyboardCombinations.escape,
        handler(event) {
          if (getQuery()) return 'not handled'

          if (getIsComposerOpen()) {
            sendAnalyticEvent('Launcher', 'Close composer by Esc')
            setIsComposerOpen(false)
          } else {
            closeLauncherStatic()
            sendAnalyticEvent('Launcher', 'Close launcher by Esc')
          }
        },
      },
    ],
    []
  )

  const { getCardText } = useGetCardText()

  const extensionIds = useMemo(
    () =>
      composerSelectedPage
        ? Model.ExtensionConfiguration.getExtensionIds(composerSelectedPage.extensionConfiguration)
        : [],
    [composerSelectedPage]
  )

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  useLayoutEffect(() => {
    window.document.body.classList.add(classes.body)
    return () => window.document.body.classList.remove(classes.body)
  }, [classes])

  return (
    <NewPage.Provider
      onNewPageModelIsReady={newPageId => {
        if (getIsComposerOpen()) {
          setComposerSelectedPage(accountPagesOrdered.find(page => page.id === newPageId))
        }
      }}
    >
      <Tidio.Control hide />

      <div ref={rootRef} className={classes.root}>
        <div className={classNames(classes.container, commonClasses.electronDrag)}>
          {!getIsComposerOpen() && (
            <LauncherSearch
              getQuery={getQuery}
              setQuery={setQuery}
              onPasteCard={cardSearchResult => {
                const content: ClipboardContent = cardSearchResult.compromiseResult
                  ? {
                      text: cardSearchResult.compromiseResult.value,
                    }
                  : {
                      text: getCardText(cardSearchResult.card) ?? '',
                      // html: getCardHtml(cardSearchResult.card), // TODO: Not maintained.
                    }
                if (content.text || content.html) {
                  sendIpcMessage(IpcChannel.Launcher, {
                    type: LauncherIpcMessageType.LauncherPasteContent,
                    content,
                  })
                }
              }}
              onOpenCard={card => {
                sendIpcMessage(IpcChannel.Launcher, {
                  type: LauncherIpcMessageType.LauncherOpenApp,
                  pageId: card.pageId,
                  cardId: card.id,
                })
              }}
            />
          )}

          {!getQuery() && !getIsComposerOpen() && (
            <>
              <LauncherButtons
                onOpenComposer={() => {
                  sendAnalyticEvent('Launcher', 'Open composer by click')
                  setIsComposerOpen(true)
                }}
                bookmarkMessage={getBookmarkMessage()}
                onOpenComposeBookmark={openComposeBookmark}
              />

              {getElectronPermissionStatus() !== ElectronPermissionStatus.Granted && (
                <LauncherNoPermissions permissionStatus={getElectronPermissionStatus()} />
              )}
            </>
          )}

          {getIsComposerOpen() && (
            <ExtensionRolesProvider role="CardEditorDialog">
              <Gap vertical={50} />
              <NavigationArea>
                <Dialog fullScreen TransitionComponent={DialogTransition} open onClose={() => setIsComposerOpen(false)}>
                  <DialogTitle>
                    <PageSelect
                      className={commonClasses.electronNoDrag}
                      selectedPage={composerSelectedPage}
                      onSelectPage={setComposerSelectedPage}
                    />
                    <Gap grow />
                    <IconButton className={commonClasses.electronNoDrag} onClick={() => setIsComposerOpen(false)}>
                      <CustomIcon name="Close" size={2.5} />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent className={commonClasses.electronNoDrag}>
                    {composerSelectedPage && (
                      <ExtensionScopeProvider
                        scope={ZettelExtensions.Scope.Page}
                        value={composerSelectedPage.id}
                        extensionIds={extensionIds}
                      >
                        <CardAdder
                          ref={cardAdderRef}
                          className={classes.cardAdder}
                          page={composerSelectedPage}
                          onSubmitFinished={() => {
                            sendIpcMessage(IpcChannel.Window, {
                              type: WindowIpcMessageType.WindowShowMessage,
                              windowType: WindowType.Launcher,
                              title: 'Saved',
                              timeoutSeconds: 2,
                            })
                            closeLauncherStatic()
                          }}
                        />
                      </ExtensionScopeProvider>
                    )}
                  </DialogContent>
                </Dialog>
              </NavigationArea>
            </ExtensionRolesProvider>
          )}
        </div>
      </div>
    </NewPage.Provider>
  )
}

import { Button, FormControl, InputLabel, MenuItem, Select, TextField, useTheme } from '@material-ui/core'
import { ZettelExtensions } from '@zettelooo/extension-api'
import React, { useState } from 'react'
import { useCopyToClipboard } from 'react-use'
import { arrayHelpers } from '../../../../../../helpers/native/arrayHelpers'
import { useRefWrap } from '../../../../../../hooks/useRefWrap'
import { useCommandBarDialogRef, useCommandGroupManualRegistration } from '../../../../../../modules/commands'
import { useContexts } from '../../../../../../modules/contexts'
import { useGenerateModelId } from '../../../../hooks/useGenerateModelId'
import { useAppNotistack } from '../../../../modules/app-notistack'
import { useConfirmationDialog } from '../../../../modules/confirmation-dialog'
import { ExtensionRolesProvider, useExtensionLifeSpan } from '../../../../modules/extension'
import { HtmlContentTools } from '../../../../modules/HtmlContentTools'
import { PersistentKey, usePersistent } from '../../../../modules/persistent'
import { RenderedElementTools } from '../../../../modules/RenderedElementTools'
import { Initializer } from '../../../Initializer'
import { getCardPublicUrl } from '../../helpers/getCardPublicUrl'
import { getPagePublicUrl } from '../../helpers/getPagePublicUrl'
import { CardViewer } from '../../modules/card'
import { convertConfirmOptions } from './converters/convertConfirmOptions'
import { convertVariant } from './converters/convertVariant'
import { ExtendedDialog } from './ExtendedDialog'

export function useExtensionActivatedLifeSpan(): {
  renderExtendedContent(): React.JSX.Element
} {
  const [, deviceId, languageCode] = usePersistent(PersistentKey.DeviceId, PersistentKey.LanguageCode)

  const { allPages, allCards } = useContexts(Initializer.Contexts)

  const [extendedDialogs, setExtendedDialogs] = useState<
    readonly HtmlContentTools.WithReferenceKey<ZettelExtensions.LifeSpan.Shared.Activated.Dialog<any>>[]
  >([])
  const [extendedRenderedButtons, setExtendedRenderedButtons] = useState<
    readonly ZettelExtensions.LifeSpan.Shared.Activated.RenderedButton[]
  >([])
  const [extendedRenderedTextFields, setExtendedRenderedTextFields] = useState<
    readonly ZettelExtensions.LifeSpan.Shared.Activated.RenderedTextField[]
  >([])
  const [extendedRenderedSelects, setExtendedRenderedSelects] = useState<
    readonly ZettelExtensions.LifeSpan.Shared.Activated.RenderedSelect[]
  >([])
  const [extendedRenderedCards, setExtendedRenderedCards] = useState<
    readonly ZettelExtensions.LifeSpan.Shared.Activated.RenderedCard[]
  >([])

  const { enqueueSnackbar } = useAppNotistack()

  const { generateModelIdStatic } = useGenerateModelId()

  const { confirmStatic } = useConfirmationDialog()

  const { commandBarDialogRef } = useCommandBarDialogRef()

  const { registerCommandGroup, unregisterCommandGroup } = useCommandGroupManualRegistration()

  const [, copyToClipboard] = useCopyToClipboard()

  const theme = useTheme()

  const contentRef = useRefWrap({
    allPages,
    allCards,
  })

  useExtensionLifeSpan({
    name: 'activated',
    target: {},
    role: 'Content',
    scopedValues: {
      [ZettelExtensions.Scope.Device]: deviceId,
    },
    dataFactories: {
      deviceId,
      themeType: theme.palette.type,
      language: languageCode,
    },
    accessFactory: () => ({
      showMessage(title, message, options) {
        enqueueSnackbar(title, message, { variant: convertVariant(options?.variant), onClick: options?.onClick })
      },
      confirm(options) {
        return confirmStatic(convertConfirmOptions(options))
      },
      selectItem(options) {
        return commandBarDialogRef.current!.openSelectItem(options)
      },
      inputString(options) {
        return commandBarDialogRef.current!.openInputString(options)
      },
      inputStringWithSelectItem(options) {
        return commandBarDialogRef.current!.openInputStringWithSelectItem(options)
      },
      generateId() {
        return generateModelIdStatic()
      },
      copyTextToClipboard(text) {
        copyToClipboard(text) // TODO: Make sure this works in Electron Desktop as well, otherwise we need to use its specific APIs when we're on Electron, see: https://www.electronjs.org/docs/api/clipboard
      },
      getPagePublicUrl(pageId) {
        return getPagePublicUrl({
          id: pageId,
          name: contentRef.current.allPages.dictionary[pageId]?.name ?? '',
        })
      },
      getCardPublicUrl(cardId) {
        return getCardPublicUrl(cardId)
      },
    }),
    registryFactory: () => ({
      dialog(getter) {
        let currentValue: HtmlContentTools.WithReferenceKey<ReturnType<typeof getter>>
        const reference: { current?: ZettelExtensions.LifeSpan.Shared.Activated.Dialog.Reference<any> } = {}
        return [
          () => {
            currentValue = HtmlContentTools.withReferenceKey(getter())
            reference.current = {
              ...HtmlContentTools.createReference(currentValue),
              update(updates) {
                setExtendedDialogs(current => {
                  const evaluatedUpdates = typeof updates === 'function' ? updates(currentValue) : updates
                  const oldValue = currentValue
                  const newValue = { ...oldValue, ...evaluatedUpdates }
                  currentValue = newValue
                  return arrayHelpers.replace(current, oldValue, newValue)
                })
              },
            }
            setExtendedDialogs(current => [...current, currentValue])
            return () => {
              setExtendedDialogs(current => arrayHelpers.remove(current, currentValue))
              delete reference.current
              HtmlContentTools.deleteReference(currentValue)
            }
          },
          reference,
        ]
      },
      renderedButton(getter) {
        let currentValue: ReturnType<typeof getter>
        const reference: { current?: ZettelExtensions.LifeSpan.Shared.Activated.RenderedButton.Reference } = {}
        return [
          () => {
            currentValue = getter()
            reference.current = {
              update(updates) {
                setExtendedRenderedButtons(current => {
                  const evaluatedUpdates = typeof updates === 'function' ? updates(currentValue) : updates
                  const oldValue = currentValue
                  const newValue = { ...oldValue, ...evaluatedUpdates }
                  currentValue = newValue
                  return arrayHelpers.replace(current, oldValue, newValue)
                })
              },
            }
            setExtendedRenderedButtons(current => [...current, currentValue])
            return () => {
              setExtendedRenderedButtons(current => arrayHelpers.remove(current, currentValue))
              delete reference.current
            }
          },
          reference,
        ]
      },
      renderedTextField(getter) {
        let currentValue: ReturnType<typeof getter>
        const reference: { current?: ZettelExtensions.LifeSpan.Shared.Activated.RenderedTextField.Reference } = {}
        return [
          () => {
            currentValue = getter()
            reference.current = {
              update(updates) {
                setExtendedRenderedTextFields(current => {
                  const evaluatedUpdates = typeof updates === 'function' ? updates(currentValue) : updates
                  const oldValue = currentValue
                  const newValue = { ...oldValue, ...evaluatedUpdates }
                  currentValue = newValue
                  return arrayHelpers.replace(current, oldValue, newValue)
                })
              },
            }
            setExtendedRenderedTextFields(current => [...current, currentValue])
            return () => {
              setExtendedRenderedTextFields(current => arrayHelpers.remove(current, currentValue))
              delete reference.current
            }
          },
          reference,
        ]
      },
      renderedSelect(getter) {
        let currentValue: ReturnType<typeof getter>
        const reference: { current?: ZettelExtensions.LifeSpan.Shared.Activated.RenderedSelect.Reference } = {}
        return [
          () => {
            currentValue = getter()
            reference.current = {
              update(updates) {
                setExtendedRenderedSelects(current => {
                  const evaluatedUpdates = typeof updates === 'function' ? updates(currentValue) : updates
                  const oldValue = currentValue
                  const newValue = { ...oldValue, ...evaluatedUpdates }
                  currentValue = newValue
                  return arrayHelpers.replace(current, oldValue, newValue)
                })
              },
            }
            setExtendedRenderedSelects(current => [...current, currentValue])
            return () => {
              setExtendedRenderedSelects(current => arrayHelpers.remove(current, currentValue))
              delete reference.current
            }
          },
          reference,
        ]
      },
      renderedCard(getter) {
        let currentValue: ReturnType<typeof getter>
        const reference: { current?: ZettelExtensions.LifeSpan.Shared.Activated.RenderedCard.Reference } = {}
        return [
          () => {
            currentValue = getter()
            reference.current = {
              update(updates) {
                setExtendedRenderedCards(current => {
                  const evaluatedUpdates = typeof updates === 'function' ? updates(currentValue) : updates
                  const oldValue = currentValue
                  const newValue = { ...oldValue, ...evaluatedUpdates }
                  currentValue = newValue
                  return arrayHelpers.replace(current, oldValue, newValue)
                })
              },
            }
            setExtendedRenderedCards(current => [...current, currentValue])
            return () => {
              setExtendedRenderedCards(current => arrayHelpers.remove(current, currentValue))
              delete reference.current
            }
          },
          reference,
        ]
      },
    }),
    dependencies: [deviceId, enqueueSnackbar, registerCommandGroup, unregisterCommandGroup],
  })

  return {
    renderExtendedContent: () => (
      <ExtensionRolesProvider role="(Extended)">
        {extendedDialogs.map((extendedDialog, index) => (
          <ExtendedDialog key={index} extendedDialog={extendedDialog} />
        ))}

        {extendedRenderedButtons.map((props, index) => {
          const [portalProps, { label, onClick, ...otherProps }] = RenderedElementTools.breakProps(props)
          return (
            <RenderedElementTools.Portal key={index} {...portalProps}>
              <Button
                {...otherProps}
                onClick={
                  onClick && ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClick(event.nativeEvent))
                }
              >
                {label}
              </Button>
            </RenderedElementTools.Portal>
          )
        })}

        {extendedRenderedTextFields.map((props, index) => {
          const [portalProps, { onValueUpdate, ...otherProps }] = RenderedElementTools.breakProps(props)
          return (
            <RenderedElementTools.Portal key={index} {...portalProps}>
              <TextField
                {...otherProps}
                onInput={onValueUpdate && (event => onValueUpdate((event.target as HTMLInputElement).value))}
                InputProps={otherProps.variant === 'standard' ? ({ disableUnderline: true } as any) : {}}
              />
            </RenderedElementTools.Portal>
          )
        })}

        {extendedRenderedSelects.map((props, index) => {
          const [portalProps, { label, onValueUpdate, options, ...otherProps }] = RenderedElementTools.breakProps(props)
          return (
            <RenderedElementTools.Portal key={index} {...portalProps}>
              <FormControl>
                {label && <InputLabel>{label}</InputLabel>}
                <Select {...otherProps} onChange={onValueUpdate && (event => onValueUpdate(event.target.value))}>
                  {options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </RenderedElementTools.Portal>
          )
        })}

        {extendedRenderedCards.map((props, index) => {
          const [portalProps, { cardId, ...otherProps }] = RenderedElementTools.breakProps(props)
          const card = allCards.dictionary[cardId]
          const page = card && allPages.dictionary[card.pageId]
          return (
            <RenderedElementTools.Portal key={index} {...portalProps}>
              {page && card && <CardViewer page={page} card={card} {...otherProps} />}
            </RenderedElementTools.Portal>
          )
        })}
      </ExtensionRolesProvider>
    ),
  }
}

import { ZettelExtensions } from '@zettelooo/extension-api'
import { Model } from '@zettelooo/server-shared'
import { PropsWithChildren } from 'react'
import { useContexts } from '../../../../../../../modules/contexts'
import { webConfig } from '../../../../../../../modules/web-config'
import { useWindowDev } from '../../../../../../../modules/window-dev'
import { Run } from '../../../../../../Run'
import { useAppHistoryControl } from '../../../../../modules/app-history'
import { ExtensionScopeProvider } from '../../../../../modules/extension'
import { useServices } from '../../../../../modules/services'
import { Initializer } from '../../../../Initializer'
import { AccountData } from '../../../modules/account-data'
import { routes } from '../../../modules/routes'
import { SmartCommandApiProvider } from './SmartCommandApiProvider'
import { useExtensionSignedInLifeSpan } from './useExtensionSignedInLifeSpan'

export function AuthenticatedAndInitializedProvider({
  account,
  children,
}: PropsWithChildren<{ account: Model.Account }>) {
  const { pagesExtensionIds } = useContexts(Initializer.Contexts)

  const { services } = useServices()

  const { appHistoryControlStatic } = useAppHistoryControl()

  useWindowDev(
    () => ({
      async wipeOut(): Promise<void> {
        await services.account.wipeOut()
        appHistoryControlStatic.push(routes.signOut.getPath({}, {}))
      },
    }),
    [services],
    {
      disabled: webConfig.environment.target === 'live', // Window dev is not available in Live, just to be on the safer side!
    }
  )

  return (
    <ExtensionScopeProvider
      scope={ZettelExtensions.Scope.User}
      value={account.id}
      extensionIds={[]}
      effectiveExtensionIdsList={[pagesExtensionIds]}
    >
      <AccountData.Provider account={account}>
        <SmartCommandApiProvider>
          <Run key="signedInLifeSpan">{useExtensionSignedInLifeSpan}</Run>
          {children}
        </SmartCommandApiProvider>
      </AccountData.Provider>
    </ExtensionScopeProvider>
  )
}

import { useCallback } from 'react'
import { useContexts } from '../../../../../modules/contexts'
import { PersistentKey, usePersistent } from '../../persistent'
import { tourSteps } from '../constants/tourSteps'
import { OnboardingProviderContexts } from '../OnboardingProvider'
import { OnboardingStep, OnboardingTour } from '../types'

export function useOnboardingControl(): {
  startOnboardingTourStatic<T extends OnboardingTour>(tour: T): void
  abortOnboardingTourStatic(): void
  jumpToOnboardingTourStepStatic<T extends OnboardingTour>(tour: T, step: OnboardingStep<T>, offset?: number): void
} {
  const { persistent } = usePersistent()

  const {
    refs: { setOnboardingState },
  } = useContexts(OnboardingProviderContexts)

  return {
    startOnboardingTourStatic: useCallback(tour => {
      if (!persistent(PersistentKey.OnboardedTours).get().includes(tour)) {
        persistent(PersistentKey.OnboardedTours).set(persistent(PersistentKey.OnboardedTours).get().concat(tour))
      }
      setOnboardingState({
        tour,
        step: tourSteps[tour][0],
      })
    }, []),

    abortOnboardingTourStatic: useCallback(() => setOnboardingState(undefined), []),

    jumpToOnboardingTourStepStatic: useCallback((tour, step, offset = 0) => {
      const newStep = tourSteps[tour][tourSteps[tour].indexOf(step) + offset]
      if (newStep) {
        setOnboardingState({
          tour,
          step: newStep,
        })
      } else {
        setOnboardingState(undefined)
      }
    }, []),
  }
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Keyboard } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { ReactElement, useContext, useLayoutEffect, useMemo, useState } from 'react'
import { arrayHelpers } from '../../../../../../../helpers/native/arrayHelpers'
import { useContexts } from '../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../modules/custom-icon'
import { Gap } from '../../../../../../Gap'
import { AccountData } from '../../../modules/account-data'
import { NewPage } from '../../../modules/NewPage'
import { PageAvatar } from '../../PageAvatar'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    renderedValue: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1.5),
    },
  }),
  { name: 'PageSelect' }
)

export function PageSelect({
  selectedPage,
  onSelectPage,
  className,
}: {
  selectedPage: Model.Page | undefined
  onSelectPage: (page: Model.Page | undefined) => void
  className?: string
}): ReactElement {
  const { accountPagesOrdered } = useContexts(AccountData.Contexts)
  const { newPage, newPageAccessor, newPageSubmitting, submitNewPageStatic, cancelNewPageStatic } = useContext(
    NewPage.Context
  )

  const [addPageDialogOpen, setAddPageDialogOpen] = useState(false)

  useLayoutEffect(() => {
    if (!selectedPage && accountPagesOrdered.length > 0) {
      onSelectPage(accountPagesOrdered[0])
    }
  }, [selectedPage, accountPagesOrdered])

  useLayoutEffect(() => {
    if (addPageDialogOpen) {
      newPageAccessor.reset()
    }
  }, [addPageDialogOpen])

  const pageDictionary = useMemo(() => arrayHelpers.toDictionaryById(accountPagesOrdered), [accountPagesOrdered])

  const classes = useStyles()

  return (
    <div className={classNames(classes.root, className)}>
      {selectedPage ? (
        <>
          <Typography variant="caption">Page</Typography>
          <Gap horizontal={2} />
          <Select
            disableUnderline
            disabled={newPageSubmitting}
            value={selectedPage?.id ?? ''}
            onChange={event => {
              const pageId = event.target.value as string | undefined
              if (pageId) {
                onSelectPage(pageDictionary[pageId])
              }
            }}
            renderValue={value => {
              const page = value ? pageDictionary[value as any] : undefined
              if (!page) return null
              return (
                <div className={classes.renderedValue}>
                  <PageAvatar
                    page={page}
                    size={3}
                    compact
                    noBackgroundColor
                    disableUpdateOnClick
                    popoverExpands="right"
                  />
                  <span>{page.name}</span>
                  <Gap horizontal={2} />
                </div>
              )
            }}
          >
            <MenuItem key="" onClick={() => setAddPageDialogOpen(true)}>
              <ListItemAvatar>
                <CustomIcon name="Add" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    New page
                    <Gap horizontal={10} />
                  </>
                }
              />
            </MenuItem>
            {accountPagesOrdered.map(page => (
              <MenuItem key={page.id} value={page.id}>
                <ListItemAvatar>
                  <PageAvatar
                    page={page}
                    size={3}
                    compact
                    noBackgroundColor
                    disableUpdateOnClick
                    popoverExpands="right"
                  />
                </ListItemAvatar>
                <ListItemText primary={page.name} secondary={page.description} />
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        <Button variant="contained" size="small" color="secondary" onClick={() => setAddPageDialogOpen(true)}>
          <CustomIcon name="Add" />
          &nbsp;&nbsp;Add page
        </Button>
      )}

      <Dialog maxWidth="sm" open={addPageDialogOpen} onClose={() => setAddPageDialogOpen(false)}>
        <DialogTitle>Add page</DialogTitle>
        <DialogContent>
          <DialogContentText>Give the new page a name:</DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            label="Page name"
            fullWidth
            disabled={newPageSubmitting}
            value={newPage.name}
            onChange={event => newPageAccessor.merge({ name: event.target.value })}
            onKeyDown={async event => {
              const code = event.code as Keyboard.NativeEventCode
              if (code === 'Enter' || code === 'NumpadEnter') {
                await submitNewPageStatic()
                setAddPageDialogOpen(false)
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disabled={newPageSubmitting}
            onClick={() => {
              cancelNewPageStatic()
              setAddPageDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={newPageSubmitting}
            onClick={async () => {
              await submitNewPageStatic()
              setAddPageDialogOpen(false)
            }}
          >
            Add new page
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import { Id, PartialReadonlyRecord } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { convertCardModelToCardPublicModel, convertPageModelToPagePublicModel, Model } from '@zettelooo/server-shared'
import { useCallback, useState } from 'react'
import { arrayHelpers } from '../../../../../../../../../../helpers/native/arrayHelpers'
import { objectHelpers } from '../../../../../../../../../../helpers/native/objectHelpers'
import { useContexts } from '../../../../../../../../../../modules/contexts'
import { Run } from '../../../../../../../../../Run'
import { useExtensionLifeSpan } from '../../../../../../../../modules/extension'
import { HtmlContentTools } from '../../../../../../../../modules/HtmlContentTools'
import { PersistentKey, usePersistent } from '../../../../../../../../modules/persistent'
import { useExtensionPageLifeSpan } from '../../../../../../hooks/useExtensionPageLifeSpan'
import { AccountData } from '../../../../../../modules/account-data'
import { Panel } from '../../../panel'

export function useExtensionPagePanelLifeSpan({
  panel,
  page,
  cards,
}: {
  panel: Panel<Panel.Type.Page>
  page: Model.Page | undefined
  cards: readonly Model.Card[]
}): {
  extentionInitializers: PartialReadonlyRecord<Id, ZettelExtensions.LifeSpan.Shared.PagePanel.Initializer>
  extendedMenuItems: readonly ZettelExtensions.LifeSpan.Shared.PagePanel.MenuItem[]
  extendedMessages: readonly HtmlContentTools.WithReferenceKey<
    ZettelExtensions.LifeSpan.Shared.PagePanel.Message<any>
  >[]
  extendedLoadingIndicatorMessages: readonly string[]
  extendedQuickActions: readonly ZettelExtensions.LifeSpan.Shared.PagePanel.QuickAction[]
  // extendedCommandLinePromptInputs: readonly ZettelExtensions.LifeSpan.Shared.PagePanel.CommandLinePromptInput[]
  renderExtensionLifeSpan(): React.JSX.Element | null
} {
  const [, deviceId] = usePersistent(PersistentKey.DeviceId)

  const { account } = useContexts(AccountData.Contexts)

  const [extentionInitializers, setExtentionInitializers] = useState<
    PartialReadonlyRecord<Id, ZettelExtensions.LifeSpan.Shared.PagePanel.Initializer>
  >({})
  const [extendedMenuItems, setExtendedMenuItems] = useState<
    readonly ZettelExtensions.LifeSpan.Shared.PagePanel.MenuItem[]
  >([])
  const [extendedMessages, setExtendedMessages] = useState<
    readonly HtmlContentTools.WithReferenceKey<ZettelExtensions.LifeSpan.Shared.PagePanel.Message<any>>[]
  >([])
  const [extendedLoadingIndicatorMessages, setExtendedLoadingIndicatorMessages] = useState<readonly string[]>([])
  const [extendedQuickActions, setExtendedQuickActions] = useState<
    readonly ZettelExtensions.LifeSpan.Shared.PagePanel.QuickAction[]
  >([])
  // const [extendedCommandLinePromptInputs, setExtendedCommandLinePromptInputs] = useState<
  //   readonly ZettelExtensions.LifeSpan.Shared.PagePanel.CommandLinePromptInput[]
  // >([])

  const { renderExtensionLifeSpan } = useExtensionPageLifeSpan({ page, cards })

  return {
    extentionInitializers,
    extendedMenuItems,
    extendedMessages,
    extendedLoadingIndicatorMessages,
    extendedQuickActions,
    // extendedCommandLinePromptInputs,

    renderExtensionLifeSpan() {
      if (!page) return null

      return (
        <>
          {renderExtensionLifeSpan()}

          <Run key="pagePanelLifeSpan">
            {() =>
              useExtensionLifeSpan({
                name: 'pagePanel',
                target: {
                  pageId: panel.pageId,
                },
                scopedValues: {
                  [ZettelExtensions.Scope.Device]: deviceId,
                  [ZettelExtensions.Scope.User]: account.id,
                  [ZettelExtensions.Scope.Page]: panel.pageId,
                },
                dataFactories: {
                  page: useCallback(({ header }) => convertPageModelToPagePublicModel(page, header.id), [page]),
                  cards: useCallback(
                    ({ header }) => cards.map(card => convertCardModelToCardPublicModel(card, header.id)),
                    [cards]
                  ),
                },
                accessFactory: () => ({}),
                registryFactory: ({ header }) => ({
                  initializer(initializer) {
                    return () => {
                      setExtentionInitializers(current => ({ ...current, [header.id]: initializer }))
                      return () => {
                        setExtentionInitializers(current => objectHelpers.omit(current, header.id))
                      }
                    }
                  },
                  menuItem(getter) {
                    let currentValue: ReturnType<typeof getter>
                    const reference: { current?: ZettelExtensions.LifeSpan.Shared.PagePanel.MenuItem.Reference } = {}
                    return [
                      () => {
                        currentValue = getter()
                        reference.current = {
                          update(updates) {
                            setExtendedMenuItems(current => {
                              const evaluatedUpdates = typeof updates === 'function' ? updates(currentValue) : updates
                              const oldValue = currentValue
                              const newValue = { ...oldValue, ...evaluatedUpdates }
                              currentValue = newValue
                              return arrayHelpers.replace(current, oldValue, newValue)
                            })
                          },
                        }
                        setExtendedMenuItems(current => [...current, currentValue])
                        return () => {
                          setExtendedMenuItems(current => arrayHelpers.remove(current, currentValue))
                          delete reference.current
                        }
                      },
                      reference,
                    ]
                  },
                  message(getter) {
                    let currentValue: HtmlContentTools.WithReferenceKey<ReturnType<typeof getter>>
                    const reference: {
                      current?: ZettelExtensions.LifeSpan.Shared.PagePanel.Message.Reference<any>
                    } = {}
                    return [
                      () => {
                        currentValue = HtmlContentTools.withReferenceKey(getter())
                        reference.current = {
                          ...HtmlContentTools.createReference(currentValue),
                          update(updates) {
                            setExtendedMessages(current => {
                              const evaluatedUpdates = typeof updates === 'function' ? updates(currentValue) : updates
                              HtmlContentTools.handleUpdates(currentValue, evaluatedUpdates)
                              const oldValue = currentValue
                              const newValue = { ...oldValue, ...evaluatedUpdates }
                              currentValue = newValue
                              return arrayHelpers.replace(current, oldValue, newValue)
                            })
                          },
                        }
                        setExtendedMessages(current => [...current, currentValue])
                        return () => {
                          setExtendedMessages(current => arrayHelpers.remove(current, currentValue))
                          delete reference.current
                          HtmlContentTools.deleteReference(currentValue)
                        }
                      },
                      reference,
                    ]
                  },
                  loadingIndicator(getter) {
                    return () => {
                      const currentValue = getter()
                      setExtendedLoadingIndicatorMessages(current => [...current, currentValue])
                      return () => {
                        setExtendedLoadingIndicatorMessages(current => arrayHelpers.remove(current, currentValue))
                      }
                    }
                  },
                  quickAction(getter) {
                    let currentValue: ReturnType<typeof getter>
                    const reference: {
                      current?: ZettelExtensions.LifeSpan.Shared.PagePanel.QuickAction.Reference
                    } = {}
                    return [
                      () => {
                        currentValue = getter()
                        reference.current = {
                          update(updates) {
                            setExtendedQuickActions(current => {
                              const evaluatedUpdates = typeof updates === 'function' ? updates(currentValue) : updates
                              const oldValue = currentValue
                              const newValue = { ...oldValue, ...evaluatedUpdates }
                              currentValue = newValue
                              return arrayHelpers.replace(current, oldValue, newValue)
                            })
                          },
                        }
                        setExtendedQuickActions(current => [...current, currentValue])
                        return () => {
                          setExtendedQuickActions(current => arrayHelpers.remove(current, currentValue))
                          delete reference.current
                        }
                      },
                      reference,
                    ]
                  },
                  // commandLinePromptInput(getter) {
                  //   let currentValue: ReturnType<typeof getter>
                  //   return () => {
                  //     currentValue = getter()
                  //     setExtendedCommandLinePromptInputs(current => [...current, currentValue])
                  //     setSideBarMode(current => (!current || current === SideBar.Mode.CommandLine ? current : undefined))
                  //     return () => {
                  //       setExtendedCommandLinePromptInputs(current => arrayHelpers.remove(current, currentValue))
                  //     }
                  //   }
                  // },
                }),
                dependencies: [deviceId, account.id, panel.pageId],
              })
            }
          </Run>
        </>
      )
    },
  }
}

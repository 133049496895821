import { captureException, Span, withScope } from '@sentry/react'
import { AccountServiceSignature, Model } from '@zettelooo/server-shared'
import { useState } from 'react'
import { useAppNotistack } from '../../../../../modules/app-notistack'
import { useServices } from '../../../../../modules/services'

export interface NewUserDataState {
  readonly formIsOpen: boolean
  readonly neededFields: AccountServiceSignature.AccountStatus.NeededFields
  readonly neededValues: AccountServiceSignature.AccountStatus.NeededValues
  readonly setNeededValue: <F extends AccountServiceSignature.AccountStatus.NeededField>(
    field: F,
    value: Model.Account[F]
  ) => void
  readonly neededValuesErrors: AccountServiceSignature.AccountStatus.NeededValuesErrors
  readonly submitting: boolean
  readonly submit: () => Promise<void>
  readonly cancel: () => void
}

export interface NewUserDataControl {
  readonly submit: (
    accessToken: string,
    neededFields: AccountServiceSignature.AccountStatus.NeededFields,
    span?: Span
  ) => Promise<{ canceled: boolean }>
}

export function useNewUserData(): {
  newUserDataState: NewUserDataState
  newUserDataControl: NewUserDataControl
} {
  const [submitContent, setSubmitContent] = useState<{
    readonly resolve: (value: { canceled: boolean }) => void | Promise<void>
    readonly accessToken: string
    readonly neededFields: AccountServiceSignature.AccountStatus.NeededFields
    readonly span: Span | undefined
  }>()
  const [neededValues, setNeededValues] = useState<AccountServiceSignature.AccountStatus.NeededValues>({})
  const [neededValuesErrors, setNeededValuesErrors] =
    useState<AccountServiceSignature.AccountStatus.NeededValuesErrors>({})
  const [submitting, setSubmitting] = useState(false)

  const { services } = useServices()

  const { enqueueSnackbar } = useAppNotistack()

  return {
    newUserDataState: {
      formIsOpen: Boolean(submitContent),

      neededFields: submitContent?.neededFields ?? [],

      neededValues,

      setNeededValue(field, value) {
        setNeededValues(current => ({ ...current, [field]: value }))
        setNeededValuesErrors(current => ({ ...current, [field]: undefined }))
      },

      neededValuesErrors,

      submitting,

      async submit() {
        if (!submitContent) return
        const submittedSuccessfully = await submit()
        if (submittedSuccessfully) {
          submitContent.resolve({ canceled: false })
          setSubmitContent(undefined)
        }

        async function submit(): Promise<boolean> {
          if (!submitContent) return false
          const basicErrors: typeof neededValuesErrors = {
            name: !submitContent.neededFields.includes('name')
              ? undefined
              : neededValuesErrors.name || (!neededValues.name ? 'Required' : undefined),
            userName: !submitContent.neededFields.includes('userName')
              ? undefined
              : neededValuesErrors.userName || (!neededValues.userName ? 'Required' : undefined),
            email: !submitContent.neededFields.includes('email')
              ? undefined
              : neededValuesErrors.email || (!neededValues.email ? 'Required' : undefined),
            walletAddress: !submitContent.neededFields.includes('walletAddress')
              ? undefined
              : neededValuesErrors.walletAddress,
          }
          const hasBasicErrors = Object.values(basicErrors).some(Boolean)
          if (hasBasicErrors) {
            setNeededValuesErrors(basicErrors)
            return false
          }
          try {
            setSubmitting(true)
            setNeededValuesErrors({})
            const { neededValuesErrors: newErrors } = await services.account.editAccount(
              submitContent.accessToken,
              neededValues,
              submitContent.span
            )
            const hasErrors = Object.values(newErrors ?? {}).some(Boolean)
            setNeededValuesErrors(hasErrors ? newErrors ?? {} : {})
            return !hasErrors
          } catch (error) {
            log.error(error)
            withScope(scope => {
              scope.setSpan(submitContent.span)
              captureException(error, { tags: { module: 'sign in' } })
            })
            enqueueSnackbar('Unable to submit the form', 'Please, check the connection and try again.', {
              variant: 'error',
            })
            return false
          } finally {
            setSubmitting(false)
          }
        }
      },

      cancel() {
        if (!submitContent) return
        submitContent.resolve({ canceled: true })
        setSubmitContent(undefined)
      },
    },

    newUserDataControl: {
      submit(accessToken, neededFields, span?) {
        return new Promise(resolve => {
          setNeededValues({})
          setNeededValuesErrors({})
          setSubmitting(false)
          setSubmitContent({ resolve, accessToken, neededFields, span })
        })
      },
    },
  }
}

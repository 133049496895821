import { PropsWithChildren } from 'react'
import { Run } from '../../../../../../Run'
import { useExtensionSignedOutLifeSpan } from './useExtensionSignedOutLifeSpan'

export function UnauthenticatedProvider({ children }: PropsWithChildren<{}>) {
  return (
    <>
      <Run key="signedOutLifeSpan">{useExtensionSignedOutLifeSpan}</Run>
      {children}
    </>
  )
}

import {
  CircularProgress,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  MenuList,
  OutlinedInput,
  Paper,
  Popper,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { captureException } from '@sentry/react'
import { Model, SearchServiceSignature } from '@zettelooo/server-shared'
import { useCallback, useLayoutEffect, useRef } from 'react'
import { useRefWrap } from '../../../../../../../../../hooks/useRefWrap'
import { useStateAccessor } from '../../../../../../../../../hooks/useStateAccessor'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { commonKeyboardCombinations, useKeyboardHandling } from '../../../../../../../../../modules/keyboard-handler'
import { webConfig } from '../../../../../../../../../modules/web-config'
import { AsyncActionState } from '../../../../../../../../../types/AsyncActionState'
import { CustomAvatar } from '../../../../../../../../CustomAvatar'
import { useServices } from '../../../../../../../modules/services'

const useStyles = makeStyles(
  theme => ({
    root: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
    userInfoContainer: {
      display: 'flex',
    },
    popper: {
      zIndex: theme.zIndex.tooltip,
    },
    paper: {
      width: theme.spacing(58.5),
      maxHeight: theme.spacing(35),
      overflow: 'auto',
    },
    circularProgress: {
      margin: 'auto',
    },
    message: {
      padding: theme.spacing(1, 0),
    },
    avatar: {
      ...theme.typography.caption,
      cursor: 'pointer',
    },
    userInput: {
      color: theme.palette.text.primary,
    },
  }),
  { name: 'InvitePageMember' }
)

export function InvitePageMember({
  value,
  validationError,
  onChange,
  focused,
  onFocus,
  selectedPage,
  invitingUsers,
}: {
  value: InvitePageMember.Value
  validationError?: string
  onChange(value: InvitePageMember.Value): void
  focused?: boolean
  onFocus(): void
  selectedPage: Model.Page
  invitingUsers: readonly SearchServiceSignature.BasicUser[]
}) {
  const rootRef = useRef<HTMLDivElement>(null)
  const searchMenuRef = useRef<HTMLUListElement>(null)
  const debounceTimeoutHandleRef = useRef<NodeJS.Timeout | null>(null)

  const contentRef = useRefWrap({
    value,
    onChange,
    focused,
  })

  const searchStateAccessor = useStateAccessor<{
    readonly requestState: AsyncActionState
    readonly open: boolean
    readonly users: readonly SearchServiceSignature.BasicUser[]
    readonly selectedUserIndex: number
  }>(
    {
      requestState: { status: AsyncActionState.Status.NotDone },
      open: false,
      users: [],
      selectedUserIndex: 0,
    },
    [value.query]
  )

  const { services } = useServices()

  useLayoutEffect(() => {
    if (debounceTimeoutHandleRef.current) {
      clearTimeout(debounceTimeoutHandleRef.current)
    }

    debounceTimeoutHandleRef.current = value.query
      ? setTimeout(async () => {
          try {
            searchStateAccessor.merge({
              requestState: { status: AsyncActionState.Status.Doing },
              open: true,
            })
            let users = await services.search.onUsers(value.query)
            users = users.filter(user => user.email === value.query) // TODO: This here helps hiding our users' emails, how should it be?
            if (isResponseStillValid()) {
              const filteredUsers = users.filter(
                user =>
                  selectedPage.memberUserIds.every(memberUserId => memberUserId !== user.id) &&
                  invitingUsers.every(invitingUser => invitingUser.id !== user.id)
              )
              searchStateAccessor.merge({
                requestState: { status: AsyncActionState.Status.DoneOrNotDoing },
                users: filteredUsers,
              })
            }
          } catch (error) {
            if (isResponseStillValid()) {
              log.error(error)
              captureException(error, { tags: { module: 'page', action: 'search users' } })
              searchStateAccessor.merge({
                requestState: { status: AsyncActionState.Status.Failed, error },
              })
            }
          }

          function isResponseStillValid(): boolean {
            return (
              value.query === contentRef.current.value.query &&
              searchStateAccessor.value.requestState.status === AsyncActionState.Status.Doing
            )
          }
        }, webConfig.timings.searchingDebounce)
      : null
  }, [value.query, services])

  useKeyboardHandling(
    () => [
      {
        combinations: 'ArrowUp',
        noConsumption: true,
        handler(event) {
          if (searchStateAccessor.value.selectedUserIndex > 0) {
            const selectedUserIndex = searchStateAccessor.value.selectedUserIndex - 1
            searchStateAccessor.merge({ selectedUserIndex })
            searchMenuRef.current?.children[selectedUserIndex].scrollIntoView({ block: 'nearest' })
          }
        },
      },
      {
        combinations: 'ArrowDown',
        noConsumption: true,
        handler(event) {
          if (searchStateAccessor.value.selectedUserIndex < searchStateAccessor.value.users.length - 1) {
            const selectedUserIndex = searchStateAccessor.value.selectedUserIndex + 1
            searchStateAccessor.merge({ selectedUserIndex })
            searchMenuRef.current?.children[selectedUserIndex].scrollIntoView({ block: 'nearest' })
          }
        },
      },
      {
        combinations: commonKeyboardCombinations.enter,
        handler(event) {
          contentRef.current.onChange({
            ...value,
            user: searchStateAccessor.value.users[searchStateAccessor.value.selectedUserIndex],
          })
          searchStateAccessor.reset()
        },
      },
      {
        combinations: commonKeyboardCombinations.escape,
        handler(event) {
          searchStateAccessor.reset()
        },
      },
    ],
    [],
    { disabled: !searchStateAccessor.value.open }
  )

  const inputRef = useCallback((inputElement: HTMLInputElement | undefined) => {
    if (contentRef.current.focused) {
      inputElement?.focus()
    } else {
      searchStateAccessor.reset()
    }
  }, [])

  const theme = useTheme()

  const classes = useStyles()

  return (
    <>
      <div ref={rootRef} className={classes.root}>
        {value.user ? (
          <OutlinedInput
            fullWidth
            disabled
            classes={{ input: classes.userInput }}
            value={`${value.user.name}`}
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  size="small"
                  edge="end"
                  onClick={() => {
                    onChange({ query: '' })
                    onFocus()
                  }}
                >
                  <CustomIcon name="Close" size={2.5} />
                </IconButton>
              </InputAdornment>
            }
          />
        ) : (
          <TextField
            variant="outlined"
            fullWidth
            autoComplete="off"
            placeholder="Insert email" // TODO: After removing the "only match email" constraint, change this to something like: "Search by name or email"
            error={!!validationError}
            helperText={validationError}
            inputProps={{
              ref: inputRef,
            }}
            value={value.query}
            onFocus={onFocus}
            onChange={event => onChange({ ...value, query: event.target.value.toLowerCase() })}
          />
        )}
      </div>

      <Popper open={searchStateAccessor.value.open} anchorEl={rootRef.current} className={classes.popper}>
        <ClickAwayListener onClickAway={searchStateAccessor.reset}>
          <Paper className={classes.paper}>
            <MenuList ref={searchMenuRef}>
              {searchStateAccessor.value.requestState.status === AsyncActionState.Status.Doing ? (
                <MenuItem disabled>
                  <CircularProgress size={theme.spacing(3)} className={classes.circularProgress} />
                </MenuItem>
              ) : searchStateAccessor.value.requestState.status === AsyncActionState.Status.Failed ? (
                <MenuItem disabled>
                  <Typography variant="body1" color="error" className={classes.message}>
                    Our search server is on a break!
                  </Typography>
                </MenuItem>
              ) : searchStateAccessor.value.users.length === 0 ? (
                <MenuItem disabled>
                  <Typography variant="body1" color="textSecondary" className={classes.message}>
                    No users found.
                  </Typography>
                </MenuItem>
              ) : (
                searchStateAccessor.value.users.map((user, index) => (
                  <MenuItem
                    key={user.id}
                    selected={index === searchStateAccessor.value.selectedUserIndex}
                    onClick={() => {
                      onChange({ ...value, user })
                      searchStateAccessor.reset()
                    }}
                  >
                    <CustomAvatar
                      size={5}
                      avatarFileId={user.avatarFileId}
                      name={user.name}
                      color={user.color}
                      margin={[1, 2.5, 1, 0]}
                      className={classes.avatar}
                    />

                    <div>
                      <Typography variant="subtitle2">{user.name}</Typography>
                      <Typography variant="body2">{`@${user.userName}`}</Typography>
                    </div>
                  </MenuItem>
                ))
              )}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export namespace InvitePageMember {
  export interface Value {
    readonly query: string
    readonly user?: SearchServiceSignature.BasicUser
  }
}

import { Divider, Input, List, ListItem, makeStyles, Popover, Slide, SlideProps, useTheme } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { typed } from '../../../../../../../../helpers/typed'
import { sendAnalyticEvent } from '../../../../../../../../modules/analytics'
import { useManageCommands } from '../../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../../modules/contexts'
import { DeviceProvider } from '../../../../../../../../modules/device'
import { useKeyboardHandling, commonKeyboardCombinations } from '../../../../../../../../modules/keyboard-handler'
import { NavigationFreeze } from '../../../../../../../../modules/navigation'
import { CustomAvatar } from '../../../../../../../CustomAvatar'
import { TypographyNoWrap } from '../../../../../../../TypographyNoWrap'
import { AccountData } from '../../../../modules/account-data'
import { Panel, useSwitchPanel } from '../panel'

// TODO: This component should be merged into the command bar dialog

const WIDTH_UNITS = 80
const TOP_UNITS = 10

const useStyles = makeStyles(
  theme => ({
    root: {
      maxWidth: 'unset', // Unset popover's paper default max-width
    },
    rootNonMobile: {
      width: theme.spacing(WIDTH_UNITS),
    },
    rootMobile: {
      width: '100vw',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    input: {
      padding: theme.spacing(1),
    },
    inputElement: {
      height: theme.spacing(3),
      padding: theme.spacing(1, 0.5),
    },
    dividerWrapper: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    list: {
      margin: theme.spacing(0.5, 0, 2, 0.5),
      maxHeight: theme.spacing(36.5),
      overflowY: 'auto',
    },
    item: {
      marginBottom: theme.spacing(0.25),
      height: theme.spacing(6),
      cursor: 'pointer',
      '&:not(.Mui-selected)': {
        color: theme.palette.text.secondary,
      },
    },
    page: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    pageTitle: {
      marginLeft: theme.spacing(0.5),
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'PageSearchDialog' }
)

export function PageSearchDialog({ open, onClose }: { open: boolean; onClose(): void }) {
  const { screenWidth, isMobile } = useContext(DeviceProvider.Context)
  const { accountPagesOrdered } = useContexts(AccountData.Contexts)

  const pageListRef = useRef<HTMLUListElement>(null)

  const [query, setQuery] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [pages, setPages] = useState<readonly Model.Page[]>([])

  const { switchPanelStatic } = useSwitchPanel()

  useEffect(() => {
    if (open) {
      sendAnalyticEvent('PageSearch', 'Open PageSearch')
    }
  }, [open])

  useLayoutEffect(() => {
    if (open) {
      setPages(accountPagesOrdered.filter(page => page.name.toLowerCase().includes(query.toLowerCase())))
      setSelectedIndex(0)
    }
  }, [open, accountPagesOrdered, query])

  useLayoutEffect(() => {
    pageListRef.current?.children[selectedIndex]?.scrollIntoView({ block: 'nearest' })
  })

  useKeyboardHandling(
    [
      {
        combinations: 'ArrowDown',
        noConsumption: true,
        handler() {
          const newSelectedIndex = selectedIndex + 1
          if (newSelectedIndex > pages.length - 1) return
          setSelectedIndex(newSelectedIndex)
        },
      },
      {
        combinations: 'ArrowUp',
        noConsumption: true,
        handler() {
          if (selectedIndex === 0) return
          setSelectedIndex(selectedIndex - 1)
        },
      },
      {
        combinations: commonKeyboardCombinations.enter,
        handler() {
          sendAnalyticEvent('PageSearch', 'Open page by enter')
          const page = pages[selectedIndex]
          switchPanelStatic({
            type: Panel.Type.Page,
            pageId: page.id,
          })
          setQuery('')
          onClose()
        },
      },
    ],
    [selectedIndex, pages],
    { removed: !open }
  )

  const { runCommandStatic } = useManageCommands()

  const theme = useTheme()

  const classes = useStyles()

  return (
    <NavigationFreeze disabled={!open}>
      {() => (
        <Popover
          open={open}
          onClose={() => {
            sendAnalyticEvent('PageSearch', 'Close PageSearch')
            onClose()
          }}
          marginThreshold={0}
          {...(isMobile
            ? {
                anchorReference: 'anchorEl',
                anchorEl: window.document.body,
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'bottom', horizontal: 'center' },
                TransitionComponent: Slide,
                TransitionProps: typed<SlideProps>({ direction: 'up' }) as TransitionProps,
                PaperProps: {
                  elevation: 24,
                  className: classNames(classes.root, classes.rootMobile),
                },
              }
            : {
                anchorReference: 'anchorPosition',
                anchorPosition: {
                  top: theme.spacing(TOP_UNITS),
                  left: screenWidth / 2,
                },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
                transitionDuration: 0,
                PaperProps: {
                  elevation: 24,
                  className: classNames(classes.root, classes.rootNonMobile),
                },
              })}
        >
          <Input
            disableUnderline
            fullWidth
            autoFocus
            placeholder="Search pages by title"
            className={classes.input}
            classes={{ input: classes.inputElement }}
            value={query}
            onChange={event => {
              if (event.target.value === '>') {
                runCommandStatic('main.commandBar')
                setQuery('')
                onClose()
              } else {
                setQuery(event.target.value)
              }
            }}
          />

          <div className={classes.dividerWrapper}>
            <Divider />
          </div>

          <List ref={pageListRef} disablePadding className={classes.list}>
            {pages.map((page, index) => (
              <ListItem
                key={page.id}
                className={classes.item}
                selected={index === selectedIndex}
                onMouseMove={() => {
                  if (selectedIndex === index) return
                  setSelectedIndex(index)
                }}
                onClick={() => {
                  sendAnalyticEvent('PageSearch', 'Open page by click')
                  switchPanelStatic({
                    type: Panel.Type.Page,
                    pageId: page.id,
                  })
                  setQuery('')
                  onClose()
                }}
              >
                <div className={classes.page}>
                  <CustomAvatar
                    size={2}
                    compact
                    avatarFileId={page.avatarFileId}
                    name={page.name}
                    emoji={page.iconEmoji}
                    margin={0.5}
                  />

                  <TypographyNoWrap variant="subtitle2" className={classes.pageTitle}>
                    {page.name}
                  </TypographyNoWrap>
                </div>
              </ListItem>
            ))}
          </List>
        </Popover>
      )}
    </NavigationFreeze>
  )
}

import { Id, Keyboard } from '@zettelooo/commons'
import { PropsWithChildren, RefObject, useLayoutEffect, useRef } from 'react'
import { arrayHelpers } from '../../../helpers/native/arrayHelpers'
import { StateAccessor, useStateAccessor } from '../../../hooks/useStateAccessor'
import { createContexts } from '../../contexts'
import { useKeyboardHandling, commonKeyboardCombinations } from '../../keyboard-handler'
import { webConfig } from '../../web-config'
import { convertKeyboardCodeToDirection } from '../helpers/convertKeyboardCodeToDirection'
import { NavigationAreaRegistration } from '../types'
import { NavigationArea } from './NavigationArea'

export const NavigationContexts = createContexts(
  ({ memoize }) =>
    (parameters?: {
      pathHighlightContainerRef: RefObject<HTMLDivElement>
      isKeyboardNavigationActiveAccessor: StateAccessor<boolean>
      isNavigationFreezerDegreeAccessor: StateAccessor<number>
      areaRegistrationsAccessor: StateAccessor<readonly NavigationAreaRegistration[]>
    }) => ({
      refs: memoize(
        () => ({
          pathHighlightContainerRef: parameters?.pathHighlightContainerRef!,
          isKeyboardNavigationActiveAccessor: parameters?.isKeyboardNavigationActiveAccessor!,
          isNavigationFreezerDegreeAccessor: parameters?.isNavigationFreezerDegreeAccessor!,
          registerArea(area: NavigationAreaRegistration): void {
            parameters?.areaRegistrationsAccessor.set([...parameters.areaRegistrationsAccessor.get(), area])
          },
          unregisterArea(areaId: Id): void {
            parameters?.areaRegistrationsAccessor.set(
              arrayHelpers.remove(parameters.areaRegistrationsAccessor.get(), area => area.areaId === areaId)
            )
          },
        }),
        []
      ),
      isKeyboardNavigationActive: parameters?.isKeyboardNavigationActiveAccessor.get()!,
      isNavigationFreezed: parameters?.isNavigationFreezerDegreeAccessor.isNot(0)!,
      activeAreaId: (parameters?.areaRegistrationsAccessor &&
        arrayHelpers.last(parameters.areaRegistrationsAccessor.get())?.areaId)!,
    }),
  'Navigation'
)

export function NavigationProvider({
  disabledAutomaticActivation,
  children,
}: PropsWithChildren<{
  disabledAutomaticActivation?: boolean
}>) {
  const pathHighlightContainerRef = useRef<HTMLDivElement>(null)

  const isKeyboardNavigationActiveAccessor = useStateAccessor(false)
  const isNavigationFreezerDegreeAccessor = useStateAccessor(0) // Negative values mean it's freezed!
  const areaRegistrationsAccessor = useStateAccessor<readonly NavigationAreaRegistration[]>([])

  if (webConfig.developerLogs.navigation) {
    // eslint-disable-next-line no-console
    console.log(
      'keyboard, freezed, areas =',
      isKeyboardNavigationActiveAccessor.get(),
      isNavigationFreezerDegreeAccessor.isNot(0),
      areaRegistrationsAccessor.get().map(area => area.areaId)
    )
  }

  useKeyboardHandling(
    [
      {
        combinations: ['Tab', { alt: true, code: 'Tab' }],
        noConsumption: true,
        handler(event) {
          return 'not handled' // TODO: Temporarily disable keyboard navigation, since it's not fully implemented yet
          if (isNavigationFreezerDegreeAccessor.isNot(0)) return 'not handled'
          isKeyboardNavigationActiveAccessor.set(!isKeyboardNavigationActiveAccessor.get())
        },
      },
    ],
    [],
    { disabled: disabledAutomaticActivation }
  )

  useLayoutEffect(() => {
    if (disabledAutomaticActivation) return

    window.addEventListener('mousedown', inactivateKeyboardNavigation)
    window.addEventListener('touchstart', inactivateKeyboardNavigation)

    return () => {
      window.removeEventListener('mousedown', inactivateKeyboardNavigation)
      window.removeEventListener('touchstart', inactivateKeyboardNavigation)
    }

    function inactivateKeyboardNavigation(): void {
      if (isNavigationFreezerDegreeAccessor.isNot(0)) return
      isKeyboardNavigationActiveAccessor.set(false)
    }
  }, [disabledAutomaticActivation])

  useKeyboardHandling(
    [
      {
        combinations: ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'],
        noConsumption: true,
        handler(event) {
          if (isKeyboardNavigationActiveAccessor.is(false) || isNavigationFreezerDegreeAccessor.isNot(0))
            return 'not handled'
          const eventCode = event.code as Keyboard.NativeEventCode
          const direction = convertKeyboardCodeToDirection(eventCode)
          const activeAreaRegistration = arrayHelpers.last(areaRegistrationsAccessor.get())!
          return activeAreaRegistration.navigate(direction)
        },
      },
      {
        combinations: commonKeyboardCombinations.enter,
        noConsumption: true,
        handler(event) {
          const activeAreaRegistration = arrayHelpers.last(areaRegistrationsAccessor.get())!
          return activeAreaRegistration.enter()
        },
      },
      {
        combinations: commonKeyboardCombinations.escape,
        handler(event) {
          const activeAreaRegistration = arrayHelpers.last(areaRegistrationsAccessor.get())!
          return activeAreaRegistration.escape()
        },
      },
    ],
    []
  )

  return (
    <NavigationContexts.Provider
      parameters={{
        pathHighlightContainerRef,
        isKeyboardNavigationActiveAccessor,
        isNavigationFreezerDegreeAccessor,
        areaRegistrationsAccessor,
      }}
    >
      <NavigationArea>{children}</NavigationArea>

      <div ref={pathHighlightContainerRef} />
    </NavigationContexts.Provider>
  )
}

import { ReactNode, useLayoutEffect } from 'react'
import { useContexts } from '../../contexts'
import { NavigationContexts } from './NavigationProvider'

export function NavigationFreeze({
  disabled,
  children,
}: {
  disabled?: boolean
  children(): ReactNode // TODO: I doubt we need children to be a function
}) {
  const {
    refs: { isNavigationFreezerDegreeAccessor },
    isNavigationFreezed,
  } = useContexts(NavigationContexts)

  useLayoutEffect(() => {
    if (!disabled) {
      isNavigationFreezerDegreeAccessor.set(isNavigationFreezerDegreeAccessor.get() - 1)

      return () => {
        isNavigationFreezerDegreeAccessor.set(isNavigationFreezerDegreeAccessor.get() + 1)
      }
    }
  }, [disabled])

  return <>{isNavigationFreezed && children()}</>
}

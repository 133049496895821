import { LanguagePack } from '../types'

export const fa: LanguagePack = {
  language: {
    code: 'fa',
    name: 'فارسی',
    direction: 'rtl',
  },
  resource: {
    translation: {
      title: 'عنوان اصلی',
      description: undefined,
    },
  },
}

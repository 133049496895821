import { PropsWithChildren } from 'react'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import { webConfig } from '../../../../modules/web-config'

export function AppRouterProvider({ children }: PropsWithChildren<{}>) {
  return webConfig.environment.agent === 'browser' ? (
    <BrowserRouter>{children}</BrowserRouter>
  ) : webConfig.environment.agent === 'electron' ? (
    <HashRouter>{children}</HashRouter>
  ) : null
}

import { generateSequence } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import {
  convertCardModelToCardPublicModel,
  convertPageModelToPagePublicModel,
  convertUserModelToUserPublicModel,
  Model,
} from '@zettelooo/server-shared'
import { useCallback } from 'react'
import { objectHelpers } from '../../../../../../../helpers/native/objectHelpers'
import { useRefWrap } from '../../../../../../../hooks/useRefWrap'
import { useContexts } from '../../../../../../../modules/contexts'
import { useApplyAction } from '../../../../../hooks/useApplyAction'
import { useGenerateModelId } from '../../../../../hooks/useGenerateModelId'
import { useDatabases } from '../../../../../modules/databases'
import { useExtensionLifeSpan } from '../../../../../modules/extension'
import { useCardDataDictionaryManipulators } from '../../../../../modules/models'
import { PersistentKey, usePersistent } from '../../../../../modules/persistent'
import { AccountData } from '../../../modules/account-data'

export function useExtensionSignedInLifeSpan(): void {
  const [, deviceId] = usePersistent(PersistentKey.DeviceId)

  const { account, accountPagesOrdered, accountCardsOrdered } = useContexts(AccountData.Contexts)

  const { updateCardDataDictionary } = useCardDataDictionaryManipulators()

  const { databases } = useDatabases()

  const { applyActionStatic } = useApplyAction()

  const { generateModelIdStatic } = useGenerateModelId()

  const contentRef = useRefWrap({
    accountPagesOrdered,
    accountCardsOrdered,
    updateCardDataDictionary,
    databases,
  })

  useExtensionLifeSpan({
    name: 'signedIn',
    target: {},
    scopedValues: {
      [ZettelExtensions.Scope.Device]: deviceId,
      [ZettelExtensions.Scope.User]: account.id,
    },
    dataFactories: {
      account: useCallback(({ header }) => convertUserModelToUserPublicModel(account), [account]),
      accountPagesOrdered: useCallback(
        ({ header }) => accountPagesOrdered.map(page => convertPageModelToPagePublicModel(page, header.id)),
        [accountPagesOrdered]
      ),
      accountCardsOrdered: useCallback(
        ({ header }) => accountCardsOrdered.map(card => convertCardModelToCardPublicModel(card, header.id)),
        [accountCardsOrdered]
      ),
    },
    accessFactory: ({ header }) => ({
      async updatePage(updates) {
        const page = contentRef.current.accountPagesOrdered.find(accountPage => accountPage.id === updates.id)
        if (!page) throw Error("The user doesn't have write access to this page.")
        await applyActionStatic.updateModel({
          type: Model.Type.Page,
          id: updates.id,
          name: updates.name ?? page.name,
          description: updates.description ?? page.description,
          iconEmoji: updates.iconEmoji ?? page.iconEmoji,
          avatarFileId: updates.avatarFileId === undefined ? page.avatarFileId : updates.avatarFileId,
          color: updates.color ?? page.color,
          memberUserIds: updates.memberUserIds ?? page.memberUserIds,
          public: updates.public ?? page.public,
          ...('data' in updates
            ? {
                dataDictionary:
                  updates.data === undefined
                    ? objectHelpers.omit(page.dataDictionary, header.id)
                    : { ...page.dataDictionary, [header.id]: updates.data },
              }
            : {}),
        })
      },

      async createCard(card) {
        const page = contentRef.current.accountPagesOrdered.find(accountPage => accountPage.id === card.pageId)
        if (!page) throw Error("The user doesn't have write access to this page.")
        const { newModel } = await applyActionStatic.createModel({
          type: Model.Type.Card,
          id: generateModelIdStatic(),
          pageId: card.pageId,
          sequence: generateSequence({
            previousSequence: card.sequenceBetween?.previousSequence,
            nextSequence: card.sequenceBetween?.nextSequence,
          }),
          dataDictionary: contentRef.current.updateCardDataDictionary(
            card.data === undefined ? {} : { [header.id]: card.data },
            header.id,
            Model.ExtensionConfiguration.getExtensionIds(page.extensionConfiguration)
          ),
        })
        return convertCardModelToCardPublicModel(newModel as Model.Card, header.id)
      },

      async updateCard(updates) {
        const card = contentRef.current.accountCardsOrdered.find(accountCard => accountCard.id === updates.id)
        if (!card) throw Error('Card is not found for the user.')
        const page = contentRef.current.accountPagesOrdered.find(accountPage => accountPage.id === card.pageId)
        if (!page) throw Error("The user doesn't have write access to this page.")
        await applyActionStatic.updateModel({
          type: Model.Type.Card,
          id: updates.id,
          ...('sequenceBetween' in updates
            ? {
                sequence: generateSequence({
                  previousSequence: updates.sequenceBetween?.previousSequence,
                  nextSequence: updates.sequenceBetween?.nextSequence,
                }),
              }
            : {}),
          ...('data' in updates
            ? {
                dataDictionary: contentRef.current.updateCardDataDictionary(
                  updates.data === undefined
                    ? objectHelpers.omit(card.dataDictionary, header.id)
                    : { ...card.dataDictionary, [header.id]: updates.data },
                  header.id,
                  Model.ExtensionConfiguration.getExtensionIds(page.extensionConfiguration)
                ),
              }
            : {}),
        })
      },
    }),
    registryFactory: () => ({}),
    dependencies: [deviceId, account.id],
  })
}

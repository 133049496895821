import { Button, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useSearchCards } from '../../../../../modules/search'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      paddingBottom: theme.spacing(0.375),
      marginBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    filterButton: {
      minWidth: theme.spacing(7),
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    buttonText: {
      padding: theme.spacing(0.5),
      paddingBottom: theme.spacing(1),
      borderBottom: `2px solid transparent`,
    },
    selected: {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  }),
  { name: 'SearchFilters' }
)

export function SearchFilters({
  selectedFilter,
  onFilter,
}: {
  selectedFilter: useSearchCards.SearchFilter
  onFilter(filter: useSearchCards.SearchFilter): void
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Button size="small" className={classes.filterButton} onClick={() => onFilter(useSearchCards.SearchFilter.All)}>
        <Typography
          variant="subtitle2"
          className={classNames(
            classes.buttonText,
            selectedFilter === useSearchCards.SearchFilter.All && classes.selected
          )}
        >
          ALL
        </Typography>
      </Button>

      <Button size="small" className={classes.filterButton} onClick={() => onFilter(useSearchCards.SearchFilter.Files)}>
        <Typography
          variant="subtitle2"
          className={classNames(
            classes.buttonText,
            selectedFilter === useSearchCards.SearchFilter.Files && classes.selected
          )}
        >
          Files
        </Typography>
      </Button>
    </div>
  )
}

import { Model } from '@zettelooo/server-shared'
import { useContext, useLayoutEffect, useState } from 'react'
import { useContexts } from '../../../../../../../modules/contexts'
import { Initializer } from '../../../../Initializer'
import { AccountData } from '../../account-data'
import { SearchProvider } from '../SearchProvider'

export function useSearchPages(query: string): {
  filteredPages: readonly Model.Page[]
} {
  const { allPages } = useContexts(Initializer.Contexts)
  const { accountPagesOrdered } = useContexts(AccountData.Contexts)
  const { flexSearchIndexStatic } = useContext(SearchProvider.Context)

  const [filteredPages, setFilteredPages] = useState<readonly Model.Page[]>([])

  useLayoutEffect(() => {
    if (!query) {
      setFilteredPages(accountPagesOrdered)
      return
    }

    const searchedPageIds = flexSearchIndexStatic.pages.search(query)
    const searchedPages = searchedPageIds.map(pageId => allPages.dictionary[pageId]!).filter(Boolean)
    setFilteredPages(searchedPages)
  }, [query, accountPagesOrdered])

  return {
    filteredPages,
  }
}

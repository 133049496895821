import { useRef, useState } from 'react'
import { useMountedState, useUpdateEffect } from 'react-use'

export function useDebouncedValue<T>(
  value: T,
  options?: {
    readonly disabled?: boolean
    readonly initialValue?: T
    readonly timeoutMilliseconds?: number
    onDebouncedValue?(debouncedValue: T): void
  }
): T {
  const [debouncedValue, setDebouncedValue] = useState(options?.initialValue ?? value)

  const timeoutRef = useRef<any>(undefined)

  const isMounted = useMountedState()

  useUpdateEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    }

    if (!options?.disabled) {
      timeoutRef.current = setTimeout(() => {
        if (isMounted()) {
          options?.onDebouncedValue ? options.onDebouncedValue(value) : setDebouncedValue(value)
        }
      }, options?.timeoutMilliseconds)
    }
  }, [value, options?.disabled, options?.timeoutMilliseconds])

  return debouncedValue
}

import { useLayoutEffect } from 'react'
import { useLoadingLogo } from '.'
import { LoadingReason } from './types'

export function LoadingLogoSubmitter({ reason }: { reason: LoadingReason }) {
  const { addLoadingReason, removeLoadingReason } = useLoadingLogo()

  useLayoutEffect(() => {
    addLoadingReason(reason)
    return () => removeLoadingReason(reason)
  }, [reason])

  return <></>
}

import { RefObject, useContext } from 'react'
import { CommandBarDialogReferee } from '../CommandBarDialog'
import { CommandsContext } from '../CommandsProvider'

export function useCommandBarDialogRef(): {
  commandBarDialogRef: RefObject<CommandBarDialogReferee>
} {
  const { commandBarDialogRef } = useContext(CommandsContext)

  return {
    commandBarDialogRef,
  }
}

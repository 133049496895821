import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core'
import { captureException } from '@sentry/react'
import { Model } from '@zettelooo/server-shared'
import { useLayoutEffect, useMemo, useState } from 'react'
import { useGetSet, useMountedState } from 'react-use'
import { arrayHelpers } from '../../../../../../../../../helpers/native/arrayHelpers'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { useRefWrap } from '../../../../../../../../../hooks/useRefWrap'
import { useStateAccessor } from '../../../../../../../../../hooks/useStateAccessor'
import { sendAnalyticEvent } from '../../../../../../../../../modules/analytics'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { commonKeyboardCombinations, useKeyboardHandling } from '../../../../../../../../../modules/keyboard-handler'
import { Navigable, NavigationArea } from '../../../../../../../../../modules/navigation'
import { useApplyAction } from '../../../../../../../hooks/useApplyAction'
import { useGenerateModelId } from '../../../../../../../hooks/useGenerateModelId'
import { useAppNotistack } from '../../../../../../../modules/app-notistack'
import { useDatabases } from '../../../../../../../modules/databases'
import { Initializer } from '../../../../../../Initializer'
import { AccountData } from '../../../../../modules/account-data'
import { MainPage } from '../../MainPage'
import { Panel } from '../../panel'
import { InvitePageMember } from './InvitePageMember'
import { PageDropdown } from './PageDropdown'

const useStyles = makeStyles(
  theme => ({
    paper: {
      width: theme.spacing(62.5),
      overflow: 'visible',
    },
  }),
  { name: 'InvitePageMembersDialog' }
)

export function InvitePageMembersDialog({ onClose }: { onClose(): void }) {
  const onCloseRef = useRefWrap(onClose)

  const { allPages } = useContexts(Initializer.Contexts)
  const { accountPagesOrdered } = useContexts(AccountData.Contexts)
  const { panel } = useContexts(MainPage.Contexts)

  const initialSelectedPage = useMemo<Model.Page | undefined>(
    () => (panel?.type === Panel.Type.Page ? allPages.dictionary[panel.pageId] : undefined) ?? accountPagesOrdered[0],
    [panel, allPages, accountPagesOrdered]
  )

  const [getSelectedPage, setSelectedPage] = useGetSet(initialSelectedPage)
  const [editingPageMemberKey, setEditingPageMemberKey] = useState<number>()
  const [notSubmittedYet, setNotSubmittedYet] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const invitingPageMembersAccessor = useStateAccessor<
    readonly {
      readonly key: number
      readonly value: InvitePageMember.Value
      readonly validationError?: string
    }[]
  >(
    () => [
      {
        key: Date.now(),
        value: { query: '' },
      },
    ],
    [],
    {
      equalityFunction(first, second) {
        return (
          first.length === second.length &&
          first.every(
            (item, index) =>
              item.key === second[index].key &&
              item.value.query === second[index].value.query &&
              item.value.user?.id === second[index].value.user?.id
          )
        )
      },
      middleware(newValue, oldValue) {
        return newValue.map(invitingPageMember => ({
          ...invitingPageMember,
          validationError:
            invitingPageMember.value.user || !invitingPageMember.value.query
              ? undefined
              : /^\w+@gmail\.com$/i.test(invitingPageMember.value.query)
              ? undefined
              : 'Invalid Gmail address',
        }))
      },
    }
  )

  const invalidForm = invitingPageMembersAccessor.value.some(invitingPageMember => invitingPageMember.validationError)

  useLayoutEffect(() => {
    if (initialSelectedPage && !getSelectedPage()) {
      setSelectedPage(initialSelectedPage)
    }
  }, [initialSelectedPage])

  const {
    databases: { mutablesDatabase },
  } = useDatabases()

  const isMounted = useMountedState()

  useLayoutEffect(() => {
    let revisedPageMembers = invitingPageMembersAccessor.value

    // Remove empty unfocused emails (except the last one):
    if (revisedPageMembers.slice(0, -1).some(member => !member.value.query && editingPageMemberKey !== member.key)) {
      revisedPageMembers = revisedPageMembers.filter(
        (mem, index) => index === revisedPageMembers.length - 1 || mem.value.query || editingPageMemberKey === mem.key
      )
    }

    // Ensure an empty email at the end of the list:
    const lastPageMember = arrayHelpers.last(revisedPageMembers)
    if (lastPageMember!.value.query) {
      revisedPageMembers = [...revisedPageMembers, { key: Date.now(), value: { query: '' } }]
    }

    invitingPageMembersAccessor.set(revisedPageMembers)
  }, [invitingPageMembersAccessor.value, editingPageMemberKey])

  const { applyActionStatic } = useApplyAction()

  const { generateModelIdStatic } = useGenerateModelId()

  const { enqueueSnackbar } = useAppNotistack()

  const submitRef = useRefWrap(async (): Promise<void> => {
    sendAnalyticEvent('Invite space member dialog', 'Submit invite')

    const selectedPageId = getSelectedPage()?.id
    if (isSubmitting || !selectedPageId) return
    setNotSubmittedYet(false)

    if (invalidForm) return

    const invitingPageMembers = invitingPageMembersAccessor
      .get()
      .filter(invitingPageMember =>
        invitingPageMember.value.user
          ? !selectedPage?.memberUserIds.includes(invitingPageMember.value.user?.id)
          : invitingPageMember.value.query
      )
    if (invitingPageMembers.length === 0) return

    setIsSubmitting(true)
    try {
      const invitingPageMembersByEmail = invitingPageMembers.filter(
        invitingPageMember => !invitingPageMember.value.user && invitingPageMember.value.query
      )
      if (invitingPageMembersByEmail.length > 0) {
        await Promise.all(
          invitingPageMembersByEmail.map(invitingPageMember =>
            applyActionStatic.invitePageMemberByEmail({
              pageId: selectedPageId,
              email: invitingPageMember.value.query,
            })
          )
        )
      }

      invitingPageMembersByEmail.forEach(invitedMemberMember => {
        enqueueSnackbar(
          'Member Invitation',
          `${invitedMemberMember.value.query} has been invited to the page ${getSelectedPage()?.name}`,
          { variant: 'info' }
        )
      })

      const invitingUsers = invitingPageMembers
        .map(invitingPageMember => invitingPageMember.value)
        .filter(value => value.user)
      if (invitingUsers.length > 0 && selectedPage) {
        await applyActionStatic.updateModel({
          type: Model.Type.Page,
          id: selectedPageId,
          memberUserIds: arrayHelpers.distinctString([
            ...selectedPage.memberUserIds,
            ...invitingUsers.map(invitingUser => invitingUser.user!.id),
          ]),
        })
      }

      onClose()
    } catch (error) {
      log.error('invite members', error)
      captureException(error, { tags: { module: 'page', action: 'invite member' } })
      enqueueSnackbar('Error', `Member invitation failed`, { variant: 'error' })
    }
    setIsSubmitting(false)
  })

  useKeyboardHandling(
    [
      {
        combinations: commonKeyboardCombinations.enter,
        noConsumption: true,
        handler(event) {
          submitRef.current()
        },
      },
      {
        combinations: commonKeyboardCombinations.escape,
        handler(event) {
          onCloseRef.current()
        },
      },
    ],
    [],
    {
      disabled: isSubmitting,
    }
  )

  const selectedPage = getSelectedPage()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <NavigationArea>
      <Dialog scroll="paper" classes={{ paper: classes.paper }} open onClose={onClose}>
        <DialogTitle>
          {selectedPage && (
            <PageDropdown disabled={isSubmitting} selectedPage={selectedPage} onSelectPage={setSelectedPage} />
          )}

          <div className={commonClasses.grow} />

          <Navigable padding={1}>
            {({ connectNavigable }) => (
              <IconButton ref={connectNavigable} size="small" onClick={onClose}>
                <CustomIcon name="Close" size={2.5} />
              </IconButton>
            )}
          </Navigable>
        </DialogTitle>

        <DialogContent dividers>
          {selectedPage &&
            invitingPageMembersAccessor.get().map(invitingPageMember => (
              <InvitePageMember
                key={invitingPageMember.key}
                value={invitingPageMember.value}
                validationError={notSubmittedYet ? undefined : invitingPageMember.validationError}
                onChange={value => {
                  invitingPageMembersAccessor.set(
                    arrayHelpers.replace(invitingPageMembersAccessor.get(), invitingPageMember, {
                      ...invitingPageMember,
                      value,
                    })
                  )
                }}
                focused={editingPageMemberKey === invitingPageMember.key}
                onFocus={() => setEditingPageMemberKey(invitingPageMember.key)}
                selectedPage={selectedPage}
                invitingUsers={
                  invitingPageMembersAccessor
                    .get()
                    .map(member => member.value.user)
                    .filter(Boolean) as any[]
                }
              />
            ))}
        </DialogContent>

        <DialogActions>
          <Navigable>
            {({ connectNavigable }) => (
              <Button ref={connectNavigable} onClick={onClose}>
                Cancel
              </Button>
            )}
          </Navigable>

          <Navigable>
            {({ connectNavigable }) => (
              <Button
                ref={connectNavigable}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitRef.current}
              >
                Invite
              </Button>
            )}
          </Navigable>
        </DialogActions>
      </Dialog>
    </NavigationArea>
  )
}

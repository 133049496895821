import { Model } from '@zettelooo/server-shared'
import { useCopyToClipboard } from 'react-use'
import { typed } from '../../../../helpers/typed'
import { DemoMode } from '../../../../modules/demo-mode'
import { useWindowDev } from '../../../../modules/window-dev'
import { useDatabases } from '../../modules/databases'
import { PersistentKey, usePersistent } from '../../modules/persistent'
import { useServices } from '../../modules/services'

export function useExportDemoContentWindowDev(): void {
  const { persistent } = usePersistent()

  const { databases } = useDatabases()

  const { services } = useServices()

  const [, copyToClipboard] = useCopyToClipboard()

  useWindowDev(
    () => ({
      async copyDemoContent(): Promise<void> {
        const account = await databases.mutablesDatabase.getAccount(
          persistent(PersistentKey.Authentication).get()?.decodedAccessToken.userId ?? ''
        )
        if (!account) {
          console.warn('Please, sign in first.') // eslint-disable-line no-console
          return
        }

        const demoContent: DemoMode.Content = {
          accessToken: persistent(PersistentKey.Authentication).get()?.accessToken ?? '',
          refreshToken: persistent(PersistentKey.Authentication).get()?.refreshToken ?? '',
          models: typed<Model.ByType[]>(
            [
              [account],
              await databases.mutablesDatabase.getAllUsers(),
              await databases.mutablesDatabase.getAllPages(),
              await databases.mutablesDatabase.getAllCards(),
              await databases.mutablesDatabase.getAllBadges(),
            ].flat()
          ),
        }

        const demoContentJson = JSON.stringify(demoContent)
        copyToClipboard(demoContentJson)
        console.log(`Copied to clipboard, paste into the file: ${services.static.getFileUrl('demo.json')}`) // eslint-disable-line no-console
      },
    }),
    [persistent, databases, copyToClipboard],
    {
      disabled: Boolean(DemoMode.data),
    }
  )
}

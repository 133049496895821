import React from 'react'
import { is } from '../../../../../../helpers/is'
import { useContexts } from '../../../../../../modules/contexts'
import { PersistentKey, usePersistent } from '../../../../modules/persistent'
import { Initializer } from '../../../Initializer'
import { AuthenticatedAndInitializedProvider } from './AuthenticatedAndInitializedProvider'
import { AuthenticatedAndInitializingProvider } from './AuthenticatedAndInitializingProvider'
import { UnauthenticatedProvider } from './UnauthenticatedProvider'

export function AuthenticationCheck({
  children,
}: {
  children(status: {
    authenticated: boolean
    initialized: boolean
    subscribed: boolean
  }): React.JSX.Element | null | undefined | false
}) {
  const [, authentication, isMutablesDatabaseInitialized] = usePersistent(
    PersistentKey.Authentication,
    PersistentKey.IsMutablesDatabaseInitialized
  )

  const { account } = useContexts(Initializer.Contexts)

  const authenticated = Boolean(authentication)
  const initialized = authenticated && isMutablesDatabaseInitialized && !!account
  const subscribed = initialized && is(account.subscription).notOneOf('none', 'canceled')

  const renderedChildren = children({ authenticated, initialized, subscribed })

  if (!authenticated) return <UnauthenticatedProvider>{renderedChildren}</UnauthenticatedProvider>

  if (!initialized)
    return <AuthenticatedAndInitializingProvider>{renderedChildren}</AuthenticatedAndInitializingProvider>

  return <AuthenticatedAndInitializedProvider account={account}>{renderedChildren}</AuthenticatedAndInitializedProvider>
}

import { environment } from './environment'

export const analytics = {
  disabled: environment.target !== 'live',

  googleAnalytics: {
    disabled: true,
    trackingId: process.env.GOOGLE_ANALYTICS_TRACKING_ID ?? '',
  },

  amplitude: {
    disabled: false,
    apiKey: process.env.AMPLITUDE_API_KEY ?? '',
  },

  mixpanel: {
    disabled: false,
    apiKey: process.env.MIXPANEL_API_KEY ?? '',
  },
} as const

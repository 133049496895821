import { Timestamp, Id } from '@zettelooo/commons'
import { useCallback } from 'react'
import { dateHelpers } from '../../../helpers/native/dateHelpers'
import { generateUUIDv4, generateUUIDv5 } from '../../../modules/generators'
import { PersistentKey, usePersistent } from '../modules/persistent'

// TODO: We should probably improve this approach to make sure different window clients which share the same session ID won't generate duplicated IDs
// TODO: We should probably rename this hook, since it's used for more than generating IDs for models only

let lastUsedTimestamp: Timestamp | undefined
let lastUsedRandomGeneratedNumbers: number[] = []

export function useGenerateModelId(): {
  generateModelIdStatic(): Id
} {
  const { persistent } = usePersistent()

  return {
    generateModelIdStatic: useCallback(() => {
      const sessionId = persistent(PersistentKey.Authentication).get()?.decodedAccessToken.sessionId

      if (!sessionId) return generateUUIDv4()

      const timestamp = dateHelpers.getCurrentTimestamp()
      if (timestamp !== lastUsedTimestamp) {
        lastUsedTimestamp = timestamp
        lastUsedRandomGeneratedNumbers = []
      }

      let randomGeneratedNumber = Math.random()
      while (lastUsedRandomGeneratedNumbers.includes(randomGeneratedNumber)) {
        randomGeneratedNumber = Math.random()
      }
      lastUsedRandomGeneratedNumbers.push(randomGeneratedNumber)

      return generateUUIDv5(sessionId, `${timestamp}-${randomGeneratedNumber}`)
    }, []),
  }
}

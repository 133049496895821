import { extendThemeOptions } from '../extendThemeOptions'
import { lightThemeOptions } from './lightThemeOptions'

export const lightLtrThemeOptions = extendThemeOptions(lightThemeOptions, {
  direction: 'ltr',
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          direction: 'ltr',
        },
      },
    },
  },
})

import mixpanel from 'mixpanel-browser'
import { webConfig } from '../../web-config'

export function sendMixpanelEvent(event: string, data?: any): void {
  if (webConfig.analytics.disabled || webConfig.analytics.mixpanel.disabled) return

  try {
    if (data && typeof data === 'string') {
      data = { data }
    }

    mixpanel.track(event, data)
  } catch (error) {
    log.error(error)
  }
}

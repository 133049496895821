import { makeStyles, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import Color from 'color'
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef, useState } from 'react'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: theme.spacing(1.25),
      border: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      cursor: 'pointer',
    },
  }),
  { name: 'HoverButton' }
)

export const HoverButton = forwardRef<
  HTMLButtonElement,
  {
    backgroundColor?: string
  } & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>(function HoverButton({ backgroundColor, ...otherProps }, ref) {
  const [isHovered, setIsHovered] = useState(false)

  const theme = useTheme()

  const classes = useStyles()

  const evaluatedBackgroundColor = backgroundColor || theme.palette.background.paper

  return (
    <button
      ref={ref}
      {...otherProps}
      className={classNames(classes.root, otherProps.className)}
      style={{
        color: theme.palette.getContrastText(evaluatedBackgroundColor),
        backgroundColor: isHovered
          ? new Color(evaluatedBackgroundColor)
              .mix(
                new Color(theme.palette.getContrastText(evaluatedBackgroundColor)),
                theme.palette.action.hoverOpacity
              )
              .string()
          : evaluatedBackgroundColor,
        ...otherProps.style,
      }}
      onMouseEnter={event => {
        setIsHovered(true)
        otherProps.onMouseEnter?.(event)
      }}
      onMouseLeave={event => {
        setIsHovered(false)
        otherProps.onMouseLeave?.(event)
      }}
    />
  )
})

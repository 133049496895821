import { makeStyles } from '@material-ui/core'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Model } from '@zettelooo/server-shared'
import { arrayHelpers } from '../../../../../../../../../../../helpers/native/arrayHelpers'
import { sendAnalyticEvent } from '../../../../../../../../../../../modules/analytics'
import { useContexts } from '../../../../../../../../../../../modules/contexts'
import { useApplyAction } from '../../../../../../../../../hooks/useApplyAction'
import { ExtensionStore as ExtensionStore_General } from '../../../../../../../../../modules/extension'
import { useServices } from '../../../../../../../../../modules/services'
import { PagePanel } from '../PagePanel'
import { Layout } from './Layout'

const useStyles = makeStyles(
  theme => ({
    extensionStore: {
      flex: 'auto',
      overflow: 'auto',
    },
  }),
  { name: 'ExtensionStore' }
)

export function ExtensionStore() {
  const {
    refs: { addExtensionsToBeInitialized, removeExtensionsFromBeingInitialized },
    page,
  } = useContexts(PagePanel.Contexts)

  const { services } = useServices()

  const { applyActionStatic } = useApplyAction()

  const classes = useStyles()

  return (
    <Layout title="Module Store">
      <ExtensionStore_General
        className={classes.extensionStore}
        scope={ZettelExtensions.Scope.Page}
        enabledExtensionIds={page.extensionConfiguration.extensionIds}
        enableExtensions={async extensionIds => {
          const toBeEnabledExtensionIds = extensionIds.filter(
            extensionId => !page.extensionConfiguration.extensionIds.includes(extensionId)
          )
          if (toBeEnabledExtensionIds.length === 0) return
          sendAnalyticEvent('Main Page > Page Panel > Extensions Store', 'Install extension', toBeEnabledExtensionIds)
          const { extensionConfiguration } = await services.extension.refineConfiguration({
            ...page.extensionConfiguration,
            extensionIds: arrayHelpers.distinctString([
              ...page.extensionConfiguration.extensionIds,
              ...toBeEnabledExtensionIds,
            ]),
          })
          await applyActionStatic.updateModel({
            type: Model.Type.Page,
            id: page.id,
            extensionConfiguration,
          })
          addExtensionsToBeInitialized(toBeEnabledExtensionIds)
        }}
        disableExtensions={async extensionIds => {
          const toBeDisabledExtensionIds = extensionIds.filter(extensionId =>
            page.extensionConfiguration.extensionIds.includes(extensionId)
          )
          if (toBeDisabledExtensionIds.length === 0) return
          sendAnalyticEvent(
            'Main Page > Page Panel > Extensions Store',
            'Uninstall extension',
            toBeDisabledExtensionIds
          )
          const { extensionConfiguration } = await services.extension.refineConfiguration({
            ...page.extensionConfiguration,
            extensionIds: page.extensionConfiguration.extensionIds.filter(
              extensionId => !toBeDisabledExtensionIds.includes(extensionId)
            ),
          })
          await applyActionStatic.updateModel({
            type: Model.Type.Page,
            id: page.id,
            extensionConfiguration,
          })
          removeExtensionsFromBeingInitialized(toBeDisabledExtensionIds)
        }}
      />
    </Layout>
  )
}

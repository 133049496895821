import { Button, IconButton, makeStyles, Typography } from '@material-ui/core'
import { useCommonStyles } from '../../../../../hooks/useCommonStyles'
import { useRefWrap } from '../../../../../hooks/useRefWrap'
import { CustomIcon } from '../../../../../modules/custom-icon'
import { useKeyboardHandling } from '../../../../../modules/keyboard-handler'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      paddingRight: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    previousButton: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'Actions' }
)

export function Actions({
  presentationDialogSlideIsOpen,
  stepIndex,
  stepsCount,
  onPreviousStep,
  onNextStep,
  onDone,
}: {
  presentationDialogSlideIsOpen?: boolean
  stepIndex: number
  stepsCount: number
  onPreviousStep?(): void
  onNextStep?(): void
  onDone?(): void
}) {
  const propsRef = useRefWrap({
    stepIndex,
    stepsCount,
    onPreviousStep,
    onNextStep,
    onDone,
  })

  useKeyboardHandling(
    [
      {
        combinations: 'ArrowLeft',
        handler(event) {
          if (propsRef.current.stepIndex <= 0 || !propsRef.current.onPreviousStep) return 'not handled'
          propsRef.current.onPreviousStep()
        },
      },
      {
        combinations: 'ArrowRight',
        handler(event) {
          if (propsRef.current.stepIndex >= propsRef.current.stepsCount - 1 || !propsRef.current.onNextStep)
            return 'not handled'
          propsRef.current.onNextStep()
        },
      },
    ],
    [],
    {
      removed: !presentationDialogSlideIsOpen,
    }
  )

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <IconButton
        size="small"
        color="primary"
        className={classes.previousButton}
        disabled={stepIndex === 0}
        onClick={onPreviousStep}
      >
        <CustomIcon name="ArrowLeft" />
      </IconButton>

      {stepIndex < stepsCount - 1 ? (
        <Button variant="contained" color="primary" onClick={onNextStep}>
          Next
        </Button>
      ) : (
        <Button variant="contained" color="primary" onClick={onDone}>
          Done
        </Button>
      )}

      <div className={commonClasses.grow} />

      <Typography variant="subtitle1" color="textSecondary">
        {stepIndex + 1} of {stepsCount}
      </Typography>
    </div>
  )
}

import { PropsWithChildren, useEffect, useMemo } from 'react'
import { arrayHelpers } from '../../../../../helpers/native/arrayHelpers'
import { createContexts } from '../../../../../modules/contexts'
import { useGenerateModelId } from '../../../hooks/useGenerateModelId'
import { useDatabases, useExtensionsDatabaseReader } from '../../databases'
import { useServices } from '../../services'
import { ExtensionManager, ExtensionModel } from '../types'

export const ExtensionProviderContexts = createContexts(
  ({ memoize }) =>
    (parameters?: {
      loadingInstalledExtensions: boolean
      updatingInstalledExtensions: boolean
      installedExtensions: readonly ExtensionModel[]
      extensionManager: ExtensionManager
    }) => ({
      installedExtensionsReader: memoize(
        () => ({
          loading: parameters?.loadingInstalledExtensions!,
          updating: parameters?.updatingInstalledExtensions!,
          all: parameters?.installedExtensions!,
          byId: (parameters?.installedExtensions && arrayHelpers.toDictionaryById(parameters?.installedExtensions))!,
        }),
        [
          parameters?.loadingInstalledExtensions,
          parameters?.updatingInstalledExtensions,
          parameters?.installedExtensions,
        ]
      ),
      extensionManager: parameters?.extensionManager!,
    }),
  'Extension'
)

export function ExtensionProvider({ children }: PropsWithChildren<{}>) {
  const { loadingInstalledExtensions, updatingInstalledExtensions, installedExtensions } = useExtensionsDatabaseReader()

  const { generateModelIdStatic } = useGenerateModelId()

  const { databases } = useDatabases()

  const { services } = useServices()

  const extensionManager = useMemo(
    () => new ExtensionManager({ generateModelIdStatic, databases, services }),
    [services]
  )
  useEffect(() => {
    extensionManager.updateInstalledExtensions(installedExtensions)
  }, [extensionManager, installedExtensions])
  useEffect(() => () => extensionManager.dispose(), [extensionManager])

  return (
    <ExtensionProviderContexts.Provider
      parameters={{
        loadingInstalledExtensions,
        updatingInstalledExtensions,
        installedExtensions,
        extensionManager,
      }}
    >
      {children}
    </ExtensionProviderContexts.Provider>
  )
}

import { ReadonlyRecord } from '@zettelooo/commons'
import { DependencyList } from 'react'
import { useSyncEffect } from '../../hooks/useSyncEffect'
import { webConfig } from '../web-config'

export function useWindowDev(
  mapFactory: () => ReadonlyRecord<string, any>,
  dependencies: DependencyList,
  options?: {
    readonly disabled?: boolean
  }
): void {
  useSyncEffect(() => {
    if (
      (webConfig.environment.node === 'development' || webConfig.environment.target === 'stage') &&
      !options?.disabled
    ) {
      const map = mapFactory()
      const keys = Object.keys(map)

      const windowAsAny = window as any

      windowAsAny.dev = windowAsAny.dev || {}
      keys.forEach(key => {
        windowAsAny.dev[key] = map[key]
      })

      return () => {
        keys.forEach(key => {
          delete windowAsAny.dev[key]
        })
        if (Object.keys(windowAsAny.dev).length === 0) delete windowAsAny.dev
      }
    }
  }, [...dependencies, options?.disabled])
}

import { Model } from '@zettelooo/server-shared'
import { webConfig } from '../../../../modules/web-config'

export const GLOBAL_PADDING_X_UNIT = 5
export const GLOBAL_PADDING_X_CHROME_EXTENSION_UNIT = 2
export const MAC_DESKTOP_TOOLS_RESERVED_SPACE_PX = 72

export const INITIAL_PAGE_EXTENSION_SETUP: Pick<Model.Page, 'extensionConfiguration' | 'dataDictionary'> = webConfig.app
  .cashFlowPurpose
  ? {
      extensionConfiguration: {
        extensionIds: ['zettel.text', 'zettel.link-preview', 'zettel.link-contacts'],
        serviceBindings: [],
      },
      dataDictionary: {},
    }
  : {
      extensionConfiguration: {
        extensionIds: [],
        serviceBindings: [],
      },
      dataDictionary: {},
    }

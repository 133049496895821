import { useCallback, useContext } from 'react'
import { Context } from './Context'
import { LoadingReason } from './types'

export function useLoadingLogo(): {
  loadingLogo: boolean
  addLoadingReason(reason: LoadingReason): void
  removeLoadingReason(reason: LoadingReason): void
  removeAllLoadingReasons(): void
} {
  const { reasons, setReasons } = useContext(Context)

  return {
    loadingLogo: reasons.length > 0,

    addLoadingReason: useCallback(
      reason =>
        setReasons(currentReasons => currentReasons.filter(currentReason => currentReason !== reason).concat(reason)),
      []
    ),

    removeLoadingReason: useCallback(
      reason => setReasons(currentReasons => currentReasons.filter(currentReason => currentReason !== reason)),
      []
    ),

    removeAllLoadingReasons: useCallback(() => setReasons([]), []),
  }
}

import { DependencyList, useEffect } from 'react'
import { useContexts } from '../../../../../modules/contexts'
import { OnboardingProviderContexts } from '../OnboardingProvider'
import { OnboardingStep, OnboardingTour } from '../types'
import { useOnboardingStateMatch } from './useOnboardingStateMatch'

export function useOnboardingPreparation<T extends OnboardingTour>(
  tour: T,
  step: OnboardingStep<T>,
  prepare: () => void | Promise<void>,
  dependencies: DependencyList,
  options?: {
    readonly disabled?: boolean
    /** Useful when the trigger of the onboarding state change is another preparer. */
    readonly onlyRespondToDependencies?: boolean
  }
): void {
  const {
    refs: { getPreparationCount, setPreparationCount },
  } = useContexts(OnboardingProviderContexts)

  const { onboardingStateMatch } = useOnboardingStateMatch(tour, step)

  useEffect(
    () => {
      if (!options?.disabled && onboardingStateMatch) {
        const result = prepare()

        if (result) {
          setPreparationCount(getPreparationCount() + 1)
          result.finally(() => setPreparationCount(getPreparationCount() - 1))
        }
      }
    },
    options?.onlyRespondToDependencies
      ? [options?.disabled, ...dependencies]
      : [options?.disabled, onboardingStateMatch, ...dependencies]
  )
}

import { PaletteType } from '@material-ui/core'
import { Id, PartialReadonlyRecord } from '@zettelooo/commons'
import { FunnelServiceGetMessagesSignature } from '@zettelooo/server-shared'
import { KeyValueEngineDatabase, KeyValueStore, ValueAccessor } from '../../../../modules/key-value-store'
import { useSearchCards } from '../../components/Content/modules/search'
import { Authentication } from '../authentication'
import { LanguageCode } from '../localization'
import { OnboardingTour } from '../onboarding'
import { PageTop } from './additionalTypes'

export enum PersistentKey {
  DeviceId = 'DEVICE_ID',
  LastDisplayedChangeLog = 'LAST_DISPLAYED_CHANGE_LOG',
  Authentication = 'AUTHENTICATION',
  IsMutablesDatabaseInitialized = 'IS_MUTABLES_DATABASE_INITIALIZED',
  FunnelSequences = 'FUNNEL_SEQUENCES',
  ThemeType = 'THEME_TYPE',
  LanguageCode = 'LANGUAGE_CODE',
  SideBarSize = 'SIDE_BAR_SIZE',
  SideBarVisible = 'SIDE_BAR_VISIBLE',
  PageRecentData = 'PAGE_RECENT_DATA',
  IsCardDialogFullScreen = 'IS_CARD_DIALOG_FULL_SCREEN',
  OnboardedTours = 'ONBOARDED_TOURS',
  RecentSearches = 'RECENT_SEARCHES',
  ShowNewSpaceHelp = 'SHOW_NEW_SPACE_HELP',
  ShowChromeExtensionPromotionBanner = 'SHOW_CHROME_EXTENSION_PROMOTION_BANNER',
}

export interface PersistentType {
  [PersistentKey.DeviceId]: Id
  [PersistentKey.LastDisplayedChangeLog]: { readonly forVersion: string; readonly contentVersion?: string | number }
  [PersistentKey.Authentication]: Authentication | undefined
  [PersistentKey.IsMutablesDatabaseInitialized]: boolean
  [PersistentKey.FunnelSequences]: FunnelServiceGetMessagesSignature.FunnelSequences
  [PersistentKey.ThemeType]: 'system' | PaletteType
  [PersistentKey.LanguageCode]: LanguageCode // TODO: It should probably be typed `string` since it _can_ accept invalid language codes (then will be defaulted to 'en')
  [PersistentKey.SideBarSize]: number | undefined
  [PersistentKey.SideBarVisible]: boolean
  [PersistentKey.PageRecentData]: PartialReadonlyRecord<Id, PageTop>
  [PersistentKey.IsCardDialogFullScreen]: boolean
  [PersistentKey.OnboardedTours]: readonly OnboardingTour[]
  [PersistentKey.RecentSearches]: readonly {
    readonly cardId: Id
    readonly query: string
    readonly filter: useSearchCards.SearchFilter
  }[]
  [PersistentKey.ShowNewSpaceHelp]: boolean
  [PersistentKey.ShowChromeExtensionPromotionBanner]: boolean
}

export type PersistentEngineDatabase = KeyValueEngineDatabase<PersistentType>
export type Persistent = KeyValueStore<PersistentType>
export type PersistentAccessor<K extends PersistentKey> = ValueAccessor<PersistentType, K>

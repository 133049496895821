import { webConfig } from '../modules/web-config'

/**
 * Absolute paths tend to the serving root and relative paths tend to the relative position of the file.
 * In **Web**, absolute paths are relative to the domain.
 * In **Desktop**, absolute paths tend to the file system itself (like `C:\`),
 * while relative paths (since we use _hash router_) are relative to where `index.html` is served (very similar to absolute paths in **Web**).
 * So, we have no good way to indicate relative paths in **Desktop**.
 * That's why we put `PUBLIC_URL` to `""` in **Web** and `"."` in **Desktop** and always append something like `"/abc"` to it to get the actual resource path.
 * In fact, all our resource files are being referenced by their absolute paths.
 * Here is the helper function to do that for us.
 * We need to make sure we always access our resources (files in `web/public` folder) using this function.
 * @param resourceFileName The name of the resource or its relative path based on its location in `web/public`
 * @returns The absolute resource URL we can use (for example as image `href`s)
 */
export function getPublicResourceUrl(resourceFileName: string): string {
  return `${webConfig.publicUrl}/${resourceFileName.replace(/^\//, '')}`
}

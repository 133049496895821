import { useCallback } from 'react'
import { useConfirmationDialog } from '../confirmation-dialog'
import { ClearDatabasesOptions, Databases } from './types'
import { useDatabases } from './useDatabases'

export function useClearDatabases(): {
  confirmClearingDatabasesIfNecessary(options: ClearDatabasesOptions): Promise<boolean>
  clearDatabases(options: ClearDatabasesOptions): Promise<void>
} {
  const { databases } = useDatabases()

  const { confirmStatic } = useConfirmationDialog()

  return {
    confirmClearingDatabasesIfNecessary: useCallback(
      async options => {
        if (options.mutablesDatabase || options.actionsDatabase) {
          if (!(await databases.actionsDatabase.isEmpty())) {
            const confirmed = await confirmStatic({
              title: "There are changes on your device which aren't synced yet",
              content:
                'By signing out, all the local cache will be cleared permanently. Are you sure to sign out of the application?',
              confirmLabel: 'Clear cache anyway and sign out',
              cancelLabel: 'Lets sync first',
              forceSelect: true,
            })
            return confirmed
          }
        }
        return true
      },
      [databases]
    ),

    clearDatabases: useCallback(
      options =>
        Promise.all(
          (Object.keys(options) as (keyof Databases)[])
            .filter(databaseName => options[databaseName])
            .map(databaseName => databases[databaseName].clearAll())
        ).then(),
      [databases]
    ),
  }
}

import { IconButton, makeStyles } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import classNames from 'classnames'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { sendAnalyticEvent } from '../../../../../../../../../modules/analytics'
import { ChromeExtensionMode } from '../../../../../../../../../modules/chrome-extension-mode'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { MainPage } from '../../MainPage'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: theme.spacing(4.5), // To cover when there is no icon in the button
      flex: 'none',
      padding: theme.spacing(1),
    },
  }),
  { name: 'SideBarMenuItem' }
)

export function SideBarMenuItem() {
  const {
    refs: { setIsSideBarMenuOpen },
    isSideBarMenuOpen,
  } = useContexts(MainPage.Contexts)

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <IconButton
      className={classNames(classes.root, commonClasses.electronNoDrag)}
      onClick={() => {
        sendAnalyticEvent('Side Bar', ' Click on Menu', { isSideBarMenuOpen: !isSideBarMenuOpen })
        setIsSideBarMenuOpen(!isSideBarMenuOpen)
      }}
    >
      {ChromeExtensionMode.isActive ? (
        <>{isSideBarMenuOpen ? null : <CustomIcon name="ChevronLeft" size={2.5} />}</>
      ) : (
        <>{isSideBarMenuOpen ? <CustomIcon name="Close" size={2.5} /> : <MenuIcon fontSize="small" />}</>
      )}
    </IconButton>
  )
}

import { Id } from '@zettelooo/commons'
import { getFilePreviewUrl } from './getFilePreviewUrl'

// Since we have multiple places with avatars, each with its own customized style,
// we need to limit the number of different preview image variations per each avatar files:
const RESTRICTED_SIZES = [64, 192, 512] as const

export function getAvatarFilePreviewUrl(
  avatarFileId: Id,
  options: {
    readonly size: number
  }
): string {
  return getFilePreviewUrl(avatarFileId, {
    width: options.size,
    height: options.size,
    objectFit: 'cover',
    revisePreviewWidthAndHeight(previewWidth, previewHeight) {
      const revisedSize =
        RESTRICTED_SIZES.find(restrictedSize => restrictedSize >= previewWidth) ??
        RESTRICTED_SIZES[RESTRICTED_SIZES.length - 1]
      return [revisedSize, revisedSize]
    },
  })
}

import { createContext, PropsWithChildren, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { webConfig } from '../../../../modules/web-config'
import { MemoizedContextProvider } from '../../../MemoizedContextProvider'
import { EphemeralKey, useEphemeral } from '../ephemeral'

export const ConnectionStatusContext = createContext<{
  isOnline: boolean
  isConnectedToServer: boolean
}>(undefined as any)

export function ConnectionStatusProvider({ children }: PropsWithChildren<{}>) {
  const [, recentlyConnectedToServer] = useEphemeral(EphemeralKey.RecentlyConnectedToServer)

  const [isOnline, setIsOnline] = useState(
    webConfig.temporary.doNotDependOnWindowNavigatorOnLineFeature ? true : window.navigator.onLine
  )

  useEffectOnce(() => {
    if (webConfig.temporary.doNotDependOnWindowNavigatorOnLineFeature) return

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }

    function handleOnline() {
      setIsOnline(true)
    }
    function handleOffline() {
      setIsOnline(false)
    }
  })

  const isConnectedToServer = recentlyConnectedToServer && isOnline

  return (
    <MemoizedContextProvider
      context={ConnectionStatusContext}
      value={{
        isOnline,
        isConnectedToServer,
      }}
      dependencies={[isOnline, isConnectedToServer]}
    >
      {children}
    </MemoizedContextProvider>
  )
}

import { Fade, ThemeOptions } from '@material-ui/core'
import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography'
import Color from 'color'
import { CustomIcon } from '../../../../../modules/custom-icon'
import { webConfig } from '../../../../../modules/web-config'

const spacingUnit = 8
const captionTypographyStyleOptions: TypographyStyleOptions = {
  fontWeight: 700,
  fontSize: '0.75rem',
  lineHeight: 1.21,
  letterSpacing: '0.0333em',
}
const paletteSecondaryMain = '#415AFD'

export const baseThemeOptions: ThemeOptions = {
  spacing: spacingUnit,
  palette: {
    secondary: {
      main: paletteSecondaryMain,
    },
    text: {
      code: '#E14646',
    },
    avatar: [
      '#FDE5BE',
      '#F7C4B4',
      '#F6A3B7',
      '#D9ACBF',
      '#E3E7AF',
      '#CFE0E0',
      '#D9E8DF',
      '#C59EBF',
      '#A9E6CF',
      '#9EBBBD',
      '#9BABC9',
      '#485676',
      '#FABE5D',
      '#EC6B43',
      '#E9184A',
      '#9F2F5E',
      '#ACD773',
      '#27C188',
      '#062E78',
      '#6F0D5F',
      '#137353',
      '#0C5459',
      '#262A48',
      '#1A1532',
    ],
  },
  typography: {
    fontFamily: 'MPLUSRounded1c',
    htmlFontSize: 16, // By the assumption that the default html font size is 16px // TODO: Is this assumption valid? Should we even set this property?
    h1: {
      fontWeight: 400,
      fontSize: '6rem',
      lineHeight: 1.21,
    },
    h2: {
      fontWeight: 500,
      fontSize: '3.75rem',
      lineHeight: 1.21,
      letterSpacing: '0.0167em',
    },
    h3: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: 1.21,
    },
    h4: {
      fontWeight: 800,
      fontSize: '2.125rem',
      lineHeight: 1.21,
      letterSpacing: '0.0074em',
    },
    h5: {
      fontWeight: 800,
      fontSize: '1.5rem',
      lineHeight: 1.21,
    },
    h6: {
      fontWeight: 900,
      fontSize: '1.25rem',
      lineHeight: 1.21,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: 1.21,
      letterSpacing: 0,
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '0.875rem',
      lineHeight: 1.21,
      letterSpacing: 0,
    },
    body1: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.21,
      letterSpacing: 0,
    },
    body2: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.21,
      letterSpacing: 0,
    },
    button: {
      fontWeight: 800,
      fontSize: '1rem',
      lineHeight: 1.21,
      letterSpacing: '0.015625em',
      textTransform: 'inherit',
    },
    caption: captionTypographyStyleOptions,
    overline: {
      // Not yet in the design
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: 'unset', // Back to the default html font size which is supposed to be 16px for most browsers
        },
        a: {
          color: 'inherit',
          textDecoration: 'inherit',
        },
      },
    },
    MuiBackdrop: {
      root: {
        position: 'absolute', // To make it fit inside the Modal container element
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
        height: 6 * spacingUnit,
        borderRadius: 1.5 * spacingUnit,
        padding: `${0.75 * spacingUnit}px ${3 * spacingUnit}px`,
        boxShadow: 'none !important',
      },
      text: {
        padding: undefined,
      },
      outlined: {
        padding: `${0.75 * spacingUnit}px ${3 * spacingUnit}px`,
      },
      sizeSmall: {
        height: 4 * spacingUnit,
        borderRadius: 1.25 * spacingUnit,
        padding: `${0.5 * spacingUnit}px ${2 * spacingUnit}px`,
      },
      sizeLarge: {
        height: 8 * spacingUnit,
        borderRadius: 2 * spacingUnit,
        padding: `${1 * spacingUnit}px ${4 * spacingUnit}px`,
      },
    },
    MuiChip: {
      root: {
        borderRadius: 1.5 * spacingUnit,
      },
      label: {
        paddingLeft: 2 * spacingUnit,
        paddingRight: 2 * spacingUnit,
      },
      labelSmall: {
        paddingLeft: 1.5 * spacingUnit,
        paddingRight: 1.5 * spacingUnit,
      },
      colorSecondary: {
        color: new Color(paletteSecondaryMain).darken(0.4).toString(),
        backgroundColor: new Color(paletteSecondaryMain).desaturate(0.4).lighten(0.4).toString(),
      },
    },
    MuiDialog: {
      root: {
        position: 'absolute !important' as any, // To make it fit inside the Modal container element
      },
    },
    MuiDialogActions: {
      root: {
        padding: 2 * spacingUnit,
        paddingTop: 0,
      },
    },
    MuiDialogContent: {
      root: {
        padding: 2 * spacingUnit,
      },
      dividers: {
        borderBottom: 'none',
        padding: 2 * spacingUnit,
      },
    },
    MuiDialogTitle: {
      root: {
        height: 7 * spacingUnit,
        display: 'flex',
        alignItems: 'center',
        paddingTop: 0,
        paddingRight: 2 * spacingUnit,
        paddingBottom: 0,
        paddingLeft: 2 * spacingUnit,
      },
    },
    MuiFab: {
      root: {
        boxShadow: 'none !important',
      },
    },
    MuiListItem: {
      container: {
        overflow: 'hidden',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 1.25 * spacingUnit,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'inherit',
      },
    },
    MuiTabs: {
      indicator: {
        display: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        ...captionTypographyStyleOptions,
        minHeight: 3.5 * spacingUnit,
        display: 'flex',
        alignItems: 'center',
      },
    },
    MuiTypography: {
      h1: {
        margin: 40,
      },
    },
  },
  props: {
    MuiBadge: {
      color: 'secondary',
    },
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiCheckbox: {
      icon: <CustomIcon name="CheckboxUnchecked" size="small" color="primary" />,
      checkedIcon: <CustomIcon name="CheckboxChecked" size="small" color="primary" />,
      // TODO: How about indeterminateIcon?
    },
    MuiDialogContent: {
      dividers: true,
    },
    MuiDialogTitle: {
      disableTypography: true,
    },
    MuiTooltip: {
      arrow: true,
      disableFocusListener: true,
      TransitionComponent: Fade,
      TransitionProps: { timeout: 0 },
    },
  },
  constants: {
    sidebar: {
      collapsedSize: 86,
      collapsingThreshold: 140,
      expandedMinimumSize: 200,
      expandedMaximumSize: 300,
      resizableSideBarMinimumScreenWidth: webConfig.temporary.temporarilyDisabledOrToBeRemoved ? 1200 : 1000,
      fullWidthSideBarMaximumScreenWidth: 560,
    },
  },
  customShadows: {
    lightShadow: '0 15px 20px 0 rgb(0 0 0 / 4%), 0 19px 40px 0 rgb(0 0 0 / 10%)',
  },
}

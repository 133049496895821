import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { memo, useRef } from 'react'
import { useUpdateEffect } from 'react-use'
import { Navigable } from '../../../../../../../../../modules/navigation'
import { Panel } from '../../panel'
import { ActivityPanel } from './ActivityPanel'
import { EmptyPanel } from './EmptyPanel'
import { NewPagePanel } from './NewPagePanel'
import { PagePanel } from './PagePanel'
import { SettingsPanel } from './SettingsPanel'
import { usePanelOnboardingPreparation } from './usePanelOnboardingPreparation'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      height: 0,
      flexGrow: 1,
    },
  }),
  { name: 'PanelView' }
)

export const PanelView = memo(function PanelView({
  className,
  panel,
}: {
  className?: string
  panel: Panel | undefined
}) {
  const panelRef = useRef<HTMLDivElement>(null)

  useUpdateEffect(() => {
    // Delayed until the references are set:
    setTimeout(() => panelRef.current?.focus()) // TODO: Is this timeout really required? Theoretically, it shouldn't!
  }, [panel])

  usePanelOnboardingPreparation(panel)

  const classes = useStyles()

  return (
    <Navigable group>
      {({ connectNavigable }) => (
        <div ref={connectNavigable} className={classNames(classes.root, className)}>
          {!panel ? (
            // <></> // TODO: Temporarily replaced with a placeholder:
            <EmptyPanel ref={panelRef} />
          ) : panel.type === Panel.Type.Settings ? (
            <SettingsPanel ref={panelRef} panel={panel} />
          ) : panel.type === Panel.Type.Page ? (
            <PagePanel.Component ref={panelRef} panel={panel} />
          ) : panel.type === Panel.Type.NewPage ? (
            <NewPagePanel ref={panelRef} panel={panel} />
          ) : panel.type === Panel.Type.Activity ? (
            <ActivityPanel ref={panelRef} panel={panel} />
          ) : null}
        </div>
      )}
    </Navigable>
  )
})

import { ComponentProps, createContext, useMemo } from 'react'
import { useRefWrap } from '../../hooks/useRefWrap'
import { useWindowDev } from '../window-dev'
import { Contexts, Memoize, ParametersBase, Refer, ValuesBase } from './types'

export function createContexts<Values extends ValuesBase, Parameters extends ParametersBase = undefined>(
  factoryMaker: ({ memoize, refer }: { memoize: Memoize; refer: Refer }) => (parameters?: Parameters) => Values,
  displayName: string
): Contexts<Values, Parameters> {
  const initialValues = factoryMaker({ memoize: factory => factory(), refer: value => ({ current: value }) })(undefined)
  const keys = Object.keys(initialValues)

  function Provider({ parameters, windowDev, children }: ComponentProps<Contexts<Values, Parameters>['Provider']>) {
    const values = factoryMaker({ memoize: useMemo, refer: useRefWrap })(parameters)

    useWindowDev(
      () => ({
        [Provider.displayName]: values,
      }),
      keys.map(key => values[key]),
      {
        disabled: !windowDev,
      }
    )

    const childrenWithProviders = keys.reduce((developingChildrenWithProviders, key) => {
      const Context = contexts[key]
      return <Context.Provider value={values[key]}>{developingChildrenWithProviders}</Context.Provider>
    }, children)

    return <>{childrenWithProviders}</>
  }

  Provider.displayName = `${displayName}Contexts`

  function ProviderWithMemo({
    dependencies,
    children,
    ...providerProps
  }: ComponentProps<Contexts<Values, Parameters>['ProviderWithMemo']>) {
    return <Provider {...providerProps}>{useMemo(children, dependencies)}</Provider>
  }

  ProviderWithMemo.displayName = Provider.displayName

  const contexts = keys.reduce((developingContexts, key) => {
    const Context = createContext(initialValues[key])
    Context.displayName = `${Provider.displayName}.${key}`
    return { ...developingContexts, [key]: Context }
  }, {} as Partial<Contexts<Values>['contexts']>) as Contexts<Values>['contexts']

  return {
    contexts,
    Provider,
    ProviderWithMemo,
  }
}

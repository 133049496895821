import { Id } from '@zettelooo/commons'
import { RouteSpecification } from './RouteSpecification'

export const routes = {
  signIn: new RouteSpecification<
    {},
    {
      agent?: 'browser' | 'electron'
      method?: 'google' | 'web3'
      registered?: 'false'
      payment?: 'success' | 'cancel'
      at?: string
      rt?: string
    }
  >('/signin'),

  signOut: new RouteSpecification('/signout'),

  authenticated: new RouteSpecification<
    {},
    {
      at?: string
      rt?: string
      mobile?: string
    }
  >('/authenticated'),

  initializing: new RouteSpecification<
    {},
    {
      then: 'main' | 'launcher'
    }
  >('/initializing'),

  main: new RouteSpecification('/main'),

  publicPageView: new RouteSpecification<{ publicPageId: Id; cardId?: Id }>('/view/page/:publicPageId/:cardId?'),

  publicCardView: new RouteSpecification<{ cardId: Id }>('/view/card/:cardId'),

  launcher: new RouteSpecification('/launcher'),

  developer: new RouteSpecification('/developer'),

  admin: new RouteSpecification('/admin'),

  // Just for test & development purposes, not accessible in production:
  test: new RouteSpecification('/test'),
  icons: new RouteSpecification('/icons'),
  design: new RouteSpecification<{ panel: string }>('/design/:panel?'),
} as const

import { CircularProgress, makeStyles, Typography, useTheme } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(2),
    },
    progress: {
      flex: 'none',
    },
  }),
  { name: 'LoadingIndicator' }
)

export function LoadingIndicator({ message, className }: { message: string; className?: string }) {
  const theme = useTheme()

  const classes = useStyles()

  return (
    <div className={classNames(classes.root, className)}>
      <CircularProgress size={theme.spacing(3)} className={classes.progress} />
      {message && (
        <Typography variant="caption" noWrap>
          {message}
        </Typography>
      )}
    </div>
  )
}

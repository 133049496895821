import { makeStyles, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { useCommonStyles } from '../../../../../../../../../../hooks/useCommonStyles'
import { useContextMenu } from '../../../../../../../../../../hooks/useContextMenu'
import { sendAnalyticEvent } from '../../../../../../../../../../modules/analytics'
import { NavigableWithCommands } from '../../../../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../../../../modules/contexts'
import { CustomAvatar } from '../../../../../../../../../CustomAvatar'
import { AccountData } from '../../../../../../modules/account-data'
import { MainPage } from '../../../MainPage'
import { Panel, useSwitchPanel } from '../../../panel'
import { ContextMenu } from './ContextMenu'

const useStyles = makeStyles(
  theme => ({
    root: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
    },
  }),
  { name: 'AccountItem' }
)

export function AccountItem() {
  const { account } = useContexts(AccountData.Contexts)
  const {
    refs: { setIsSideBarMenuOpen },
  } = useContexts(MainPage.Contexts)

  const { handleContextMenuStatic, closeContextMenuStatic, isContextMenuOpenStatic, menuProps } = useContextMenu()

  const { switchPanelStatic } = useSwitchPanel()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <>
      <NavigableWithCommands
        padding={[0, 0, 0, -1]}
        commandGroup={({ navigableRef }) => ({
          name: 'accountItem',
          commands: [
            {
              name: 'closeMenu',
              handler() {
                closeContextMenuStatic()
              },
            },
            {
              name: 'openSettings',
              handler() {
                switchPanelStatic({
                  type: Panel.Type.Settings,
                  subType: Panel.Settings.Type.Account,
                })
                closeContextMenuStatic()
                setIsSideBarMenuOpen(false)
              },
            },
            {
              name: 'openSubscription',
              handler() {
                switchPanelStatic({
                  type: Panel.Type.Settings,
                  subType: Panel.Settings.Type.Subscription,
                })
                closeContextMenuStatic()
                setIsSideBarMenuOpen(false)
              },
            },
          ],
        })}
      >
        {({ connectNavigable }) => (
          <div
            ref={connectNavigable}
            className={classNames(classes.root, commonClasses.electronNoDrag)}
            onClick={event => {
              sendAnalyticEvent('Title Bar', 'Click on Account Item')
              handleContextMenuStatic(event)
            }}
            onContextMenu={event => {
              sendAnalyticEvent('Title Bar', 'Right Click on Account Item')
              handleContextMenuStatic(event)
            }}
          >
            <Tooltip title={account.name}>
              <CustomAvatar size={3.5} avatarFileId={account.avatarFileId} name={account.name} color={account.color} />
            </Tooltip>
          </div>
        )}
      </NavigableWithCommands>

      <ContextMenu
        menuProps={menuProps}
        closeMenu={closeContextMenuStatic}
        userAvatarFileId={account.avatarFileId}
        userDisplayName={account.name}
        color={account.color}
      />
    </>
  )
}

import qs from 'qs'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { QueryBase } from './types'

export function useQuery<Q extends QueryBase = any>(): Q {
  const location = useLocation()
  const queryString = location.search

  const query = useMemo(() => qs.parse(queryString, { ignoreQueryPrefix: true }) as Q, [queryString])

  return query
}

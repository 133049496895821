import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import { captureException } from '@sentry/react'
import { Extension } from '@zettelooo/server-shared'
import { SnackbarKey } from 'notistack'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useMountedState } from 'react-use'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import remarkGfm from 'remark-gfm'
import { CustomIcon } from '../../../../../../modules/custom-icon'
import { useAppNotistack } from '../../../app-notistack'
import { useServices } from '../../../services'
import { InstallButton } from './InstallButton'

const useStyles = makeStyles(
  theme => ({
    installButton: {
      margin: theme.spacing(0, 2),
    },
    paper: {
      width: theme.spacing(128),
    },
    title: {
      width: 0,
      flexGrow: 1,
      display: 'flex',
      alignItems: 'baseline',
      gap: theme.spacing(2),
    },
    loaderContainer: {
      height: theme.spacing(20),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(2),
    },
    errorContainer: {
      height: theme.spacing(20),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(2),
    },
    markdown: {},
  }),
  { name: 'DocumentationDialog' }
)

export function DocumentationDialog({ extension, onClose }: { extension: Extension.Header; onClose(): void }) {
  const [state, setState] = useState<
    | {
        readonly status: 'none' | 'not available' | 'retry'
      }
    | {
        readonly status: 'fetching'
        readonly documentationMarkdownFile: string
      }
    | {
        readonly status: 'error'
        readonly error: any
      }
    | {
        readonly status: 'fetched'
        readonly markdown: string
      }
  >({ status: 'none' })

  const { services } = useServices()

  const { enqueueSnackbar, closeSnackbar } = useAppNotistack()

  const isMounted = useMountedState()

  useEffect(() => {
    let snackbarKey: SnackbarKey | undefined
    switch (state.status) {
      case 'none':
      case 'retry':
        if (extension.documentationMarkdownFile) {
          setState({ status: 'fetching', documentationMarkdownFile: extension.documentationMarkdownFile })
        } else {
          setState({ status: 'not available' })
        }
        break

      case 'not available':
        snackbarKey = enqueueSnackbar(
          'Documentation is not available',
          "This extension doesn't provide any documentation.",
          { variant: 'info' }
        )
        break

      case 'fetching':
        void (async (): Promise<void> => {
          try {
            const response = await fetch(services.extension.getFileUrl(extension, state.documentationMarkdownFile))
            const markdown = await response.text()
            if (!isMounted()) return
            setState({ status: 'fetched', markdown })
          } catch (error) {
            log.error(error)
            captureException(error, { tags: { module: 'extensions', action: 'fetch documentation' } })
            setState({ status: 'error', error })
          }
        })()
        break
    }

    return () => {
      if (snackbarKey) {
        closeSnackbar(snackbarKey)
      }
    }
  }, [state])

  const theme = useTheme()

  const classes = useStyles()

  if (state.status === 'none' || state.status === 'not available') return null

  return (
    <Dialog maxWidth="lg" scroll="paper" classes={{ paper: classes.paper }} open onClose={onClose}>
      <DialogTitle>
        <div className={classes.title}>
          <Typography variant="h6" noWrap>
            {extension.name}
          </Typography>
          <Typography variant="subtitle1">{extension.version}</Typography>
          <Typography variant="subtitle2" noWrap>
            {extension.id}
          </Typography>
        </div>

        <InstallButton className={classes.installButton} extension={extension} />

        <IconButton size="small" onClick={onClose}>
          <CustomIcon name="Close" size={2.5} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {state.status === 'fetching' ? (
          <div className={classes.loaderContainer}>
            <CircularProgress size={theme.spacing(3)} />
            <Typography variant="caption">Loading...</Typography>
          </div>
        ) : state.status === 'error' ? (
          <div className={classes.errorContainer}>
            <Typography variant="caption">Unable to fetch the documentation</Typography>
            <Button variant="outlined" color="primary" onClick={() => setState({ status: 'retry' })}>
              Try again
            </Button>
          </div>
        ) : state.status === 'fetched' ? (
          <ReactMarkdown
            className={classes.markdown}
            transformImageUri={(src, alt, title) => services.extension.getFileUrl(extension, src)}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw, rehypeSanitize]}
            components={{}} // TODO: Make sure all the components are rendered according to the theme, see Appendix B from this link: https://www.npmjs.com/package/react-markdown
          >
            {state.markdown}
          </ReactMarkdown>
        ) : null}
      </DialogContent>
    </Dialog>
  )
}

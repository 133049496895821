import { useTheme } from '@material-ui/core'
import { Dispatch, SetStateAction, useContext } from 'react'
import { useRefWrap } from '../../../../../../../../hooks/useRefWrap'
import { sendAnalyticEvent } from '../../../../../../../../modules/analytics'
import {
  CommandBarDialogMode,
  useCommandBarDialogRef,
  useCommandGroupRegistration,
} from '../../../../../../../../modules/commands'
import { DeviceProvider } from '../../../../../../../../modules/device'
import { webConfig } from '../../../../../../../../modules/web-config'
import { useAppHistoryState, useAppHistoryControl } from '../../../../../../modules/app-history'
import { useOnboardingControl, OnboardingTour } from '../../../../../../modules/onboarding'
import { usePersistent, PersistentKey } from '../../../../../../modules/persistent'
import { Panel, useSwitchPanel } from '../panel'

export function useMainPageCommands(
  getIsInvitePageMembersDialogOpen: () => boolean,
  setIsCardAdderDialogOpen: Dispatch<SetStateAction<boolean>>,
  openInvitePageMembersDialogStatic: () => void,
  panel: Panel | undefined
): void {
  const { persistent } = usePersistent()

  const { isMobile } = useContext(DeviceProvider.Context)

  const { commandBarDialogRef } = useCommandBarDialogRef()

  const panelRef = useRefWrap(panel)

  const { appHistoryStateStatic } = useAppHistoryState()
  const { appHistoryControlStatic } = useAppHistoryControl()

  const { switchPanelStatic } = useSwitchPanel()

  const { startOnboardingTourStatic } = useOnboardingControl()

  const theme = useTheme()

  useCommandGroupRegistration(
    () => ({
      name: 'navigation',
      displayName: 'Navigation',
      commands: [
        {
          name: 'openUserSettings',
          displayName: 'Settings',
          icon: 'Settings',
          defaultShortcutKeys: { cmd: true, code: 'Comma' },
          handler() {
            switchPanelStatic({
              type: Panel.Type.Settings,
              subType: Panel.Settings.Type.Account,
            })
          },
        },
        {
          name: 'openActivities',
          displayName: 'Activity',
          disabled: () => webConfig.temporary.temporarilyDisabledOrToBeRemoved,
          icon: 'Activity',
          handler() {
            switchPanelStatic({ type: Panel.Type.Activity })
          },
        },
        {
          name: 'togglePageSettings',
          displayName: 'Page settings',
          disabled: () =>
            webConfig.temporary.temporarilyDisabledOrToBeRemoved ||
            Boolean(panelRef.current && panelRef.current.type !== Panel.Type.Page),
          icon: 'Settings',
        },
        {
          name: 'showInviteUsersDialog',
          displayName: 'Invite Users',
          disabled() {
            return webConfig.temporary.temporarilyDisabledOrToBeRemoved || webConfig.temporary.noInviteUser
          },
          icon: 'Question',
          defaultShortcutKeys: { alt: true, code: 'Slash' },
        },
        {
          name: 'downloadDesktopApp',
          displayName: 'Donwload desktop app',
          disabled() {
            return webConfig.temporary.temporarilyDisabledOrToBeRemoved || webConfig.environment.agent === 'electron'
          },
          icon: 'Download',
          handler() {
            window.location.href = webConfig.urls.downloads
          },
        },
        {
          name: 'goToApiDocumentation',
          displayName: 'Go to API documentation',
          icon: 'Documentation',
          handler() {
            window.location.href = webConfig.urls.documents
          },
        },
        {
          name: 'signOut',
          displayName: 'Sign out',
          defaultShortcutKeys: { alt: true, shift: true, code: 'KeyQ' },
        },
        {
          name: 'jumpToTop',
          displayName: 'Jump to top',
          defaultShortcutKeys: { cmd: true, code: 'ArrowUp' },
        },
        {
          name: 'jumpToBottom',
          displayName: 'Jump to bottom',
          defaultShortcutKeys: { cmd: true, code: 'ArrowDown' },
        },
        {
          name: 'goBackward',
          displayName: 'Go Backward',
          disabled: () => webConfig.temporary.temporarilyDisabledOrToBeRemoved || !appHistoryStateStatic.canGoBackward,
          defaultShortcutKeys: { cmd: true, code: 'BracketLeft' },
          handler() {
            appHistoryControlStatic.goBackward()
          },
        },
        {
          name: 'goForward',
          displayName: 'Go Forward',
          disabled: () => webConfig.temporary.temporarilyDisabledOrToBeRemoved || !appHistoryStateStatic.canGoForward,
          defaultShortcutKeys: { cmd: true, code: 'BracketRight' },
          handler() {
            appHistoryControlStatic.goForward()
          },
        },
      ],
    }),
    [isMobile]
  )

  useCommandGroupRegistration(
    () => ({
      name: 'main',
      displayName: 'General',
      commands: [
        {
          name: 'pageSearch',
          displayName: 'Jump to a page',
          icon: 'Search',
          defaultShortcutKeys: { cmd: true, code: 'KeyP' },
        },
        {
          name: 'commandBar',
          defaultShortcutKeys: { cmd: true, code: 'KeyK' },
          handler() {
            sendAnalyticEvent('Command bar', 'Open')
            // The command bar dialog will be closed by any command execution.
            // Since, toggling the command bar is a command by itself, we
            // delay it so that the dialog won't be closed right after being opened:
            setTimeout(() => {
              if (
                commandBarDialogRef.current?.getMode() === CommandBarDialogMode.AllCommands ||
                commandBarDialogRef.current?.getMode() === CommandBarDialogMode.FilteredCommands
              ) {
                commandBarDialogRef.current.close()
              } else if (commandBarDialogRef.current?.getMode() === CommandBarDialogMode.Closed) {
                commandBarDialogRef.current.openCommands()
              }
            })
          },
        },
        {
          name: 'createNewPage',
          displayName: 'New list',
          icon: 'Add',
          defaultShortcutKeys: { cmd: true, code: 'KeyN' },
        },
        {
          name: 'compose',
          displayName: 'New card',
          icon: 'Add',
          defaultShortcutKeys: 'KeyC',
          handler() {
            setIsCardAdderDialogOpen(true)
          },
        },
        {
          name: 'search',
          displayName: 'Search',
          icon: 'Search',
          defaultShortcutKeys: [{ code: 'Slash' }, { cmd: true, code: 'Slash' }],
        },
        {
          name: 'lightTheme',
          displayName: 'Light theme',
          disabled() {
            return theme.palette.type === 'light'
          },
          icon: 'LightTheme',
          defaultShortcutKeys: { cmd: true, shift: true, code: 'KeyL' },
          handler() {
            persistent(PersistentKey.ThemeType).set('light')
          },
        },
        {
          name: 'darkTheme',
          displayName: 'Dark theme',
          disabled() {
            return theme.palette.type === 'dark'
          },
          icon: 'DarkTheme',
          defaultShortcutKeys: { cmd: true, shift: true, code: 'KeyL' },
          handler() {
            persistent(PersistentKey.ThemeType).set('dark')
          },
        },
        {
          name: 'inviteMembers',
          displayName: 'Invite members',
          disabled() {
            return getIsInvitePageMembersDialogOpen()
          },
          icon: 'People',
          defaultShortcutKeys: [{ code: 'KeyI' }, { cmd: true, code: 'KeyI' }],
          handler() {
            openInvitePageMembersDialogStatic()
          },
        },
        {
          name: 'toggleFullView',
          displayName: 'Toggle full view',
          disabled: () =>
            webConfig.temporary.temporarilyDisabledOrToBeRemoved || panelRef.current?.type === Panel.Type.NewPage,
          icon: 'LeftSideBar',
          defaultShortcutKeys: { cmd: true, code: 'Period' },
        },
        {
          name: 'onboardingWelcomeTour',
          displayName: 'Welcome tour',
          disabled: () => webConfig.temporary.temporarilyDisabledOrToBeRemoved,
          handler() {
            startOnboardingTourStatic(OnboardingTour.Welcome)
          },
        },
      ],
    }),
    [persistent, theme]
  )
}

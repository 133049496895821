import { createTheme, Direction, jssPreset, PaletteType, StylesProvider, Theme, ThemeProvider } from '@material-ui/core'
import { create } from 'jss'
import jssRtl from 'jss-rtl'
import { PropsWithChildren, useContext, useLayoutEffect, useMemo } from 'react'
import { DeviceProvider } from '../../../../modules/device'
import { languageDictionary } from '../localization'
import { usePersistent, PersistentKey } from '../persistent'
import { getThemeOptions } from './getThemeOptions'

export function StylingProvider({
  theme,
  children,
}: PropsWithChildren<{
  theme?: Theme
}>) {
  const [, themeType, languageCode] = usePersistent(PersistentKey.ThemeType, PersistentKey.LanguageCode)

  const { systemThemePaletteType } = useContext(DeviceProvider.Context)

  const jss = useMemo(() => {
    const preset = jssPreset()
    return create({
      ...preset,
      plugins: [...preset.plugins, jssRtl()],
    })
  }, [])

  const evaluatedTheme = useMemo(() => {
    if (theme) return theme

    const paletteType: PaletteType = themeType === 'system' ? systemThemePaletteType : themeType
    const direction: Direction = languageDictionary[languageCode]?.direction ?? 'ltr'
    const themeOptions = getThemeOptions(paletteType, direction)

    return createTheme(themeOptions)
  }, [theme, themeType, languageCode, systemThemePaletteType])

  useLayoutEffect(() => {
    document.body.setAttribute('dir', evaluatedTheme.direction)
  }, [evaluatedTheme])

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={evaluatedTheme}>
        <>{children}</>
      </ThemeProvider>
    </StylesProvider>
  )
}

// TODO: The followings are all temporary, decide them later:
export const temporary = {
  doNotDependOnWindowNavigatorOnLineFeature: true,
  noUndecidedFeature: true,
  noDesktopAppDownloadBannerInWeb: true,
  noInviteUser: true,
  disableTemplates: true,
  disableOnboardingWelcomeTour: true,
  temporarilyDisabledOrToBeRemoved: true,
  cardComposer: {
    hideResetButton: true,
  },
} as const

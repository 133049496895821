import { Button, makeStyles, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { forwardRef, PropsWithChildren, ReactNode, useContext } from 'react'
import { useCombineRefs } from '../../../../../../../../../hooks/useCombineRefs'
import { useRefWrap } from '../../../../../../../../../hooks/useRefWrap'
import { NavigableWithCommands, useManageCommands } from '../../../../../../../../../modules/commands'
import { DeviceProvider } from '../../../../../../../../../modules/device'
import { commonKeyboardCombinations } from '../../../../../../../../../modules/keyboard-handler'

const useStyles = makeStyles(
  theme => ({
    root: {
      flex: 'none',
    },
    selected: {},
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      margin: theme.spacing(1.25, 1, 1.25, 2),
      backgroundClip: 'content-box',
      backgroundColor: 'unset',
      color: theme.palette.text.primary,
    },
  }),
  { name: 'ButtonItem' }
)

export const ButtonItem = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    icon?: ReactNode
    tooltipTitle: ReactNode
    selected?: boolean
    onClick?: () => void
    className?: string
  }>
>(function ButtonItem({ icon, tooltipTitle, selected, onClick, className, children }, ref) {
  const { isMobile } = useContext(DeviceProvider.Context)

  const onClickRef = useRefWrap(onClick)

  const { runCommandStatic } = useManageCommands()

  const combineRefs = useCombineRefs(ref)

  const classes = useStyles()

  return (
    <NavigableWithCommands
      commandGroup={({ navigableRef }) => ({
        name: 'buttonItem',
        commands: [
          {
            name: 'click',
            defaultShortcutKeys: commonKeyboardCombinations.enter,
            handler() {
              onClickRef.current?.()
            },
          },
        ],
      })}
    >
      {({ connectNavigable }) => (
        <Tooltip title={isMobile ? '' : tooltipTitle ?? ''} placement="right">
          <Button
            ref={combineRefs(connectNavigable)}
            variant="contained"
            color="primary"
            fullWidth
            className={classNames(classes.root, selected && classes.selected, className)}
            onClick={() => runCommandStatic('buttonItem.click')}
          >
            {children}
          </Button>
        </Tooltip>
      )}
    </NavigableWithCommands>
  )
})

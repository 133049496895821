import { WindowType } from '@zettelooo/desktop-shared'
import { useEffect } from 'react'
import { useStateAccessor } from '../../hooks/useStateAccessor'

const MOBILE_MAXIMUM_SCREEN_WIDTH = 800

export function useScreenSize(): ScreenSize {
  const screenSizeAccessor = useStateAccessor(() => getScreenSize(), [], {
    equalityFunction(first, second) {
      return (
        first.screenWidth === second.screenWidth &&
        first.screenHeight === second.screenHeight &&
        first.isMobile === second.isMobile
      )
    },
  })

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => void window.removeEventListener('resize', handleResize)

    function handleResize(): void {
      screenSizeAccessor.reset()
    }
  }, [])

  return screenSizeAccessor.value
}

interface ScreenSize {
  readonly screenWidth: number
  readonly screenHeight: number
  readonly isMobile: boolean
}

function getScreenSize(): ScreenSize {
  return {
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    isMobile:
      window.innerWidth <= MOBILE_MAXIMUM_SCREEN_WIDTH && window.electronProxy?.windowType !== WindowType.Launcher,
  }
}

import { PartialRecord } from '@zettelooo/commons'
import { useEffect, useState } from 'react'
import { getEnumKeyValues } from '../../../../helpers/getEnumKeyValues'
import { typed } from '../../../../helpers/typed'
import { DemoMode } from '../../../../modules/demo-mode'
import { PeekMode } from '../../../../modules/peek-mode'
import { Broadcaster } from '../../../../modules/service-worker'
import { useWindowDev } from '../../../../modules/window-dev'
import { Persistent } from '../persistent'
import { ServiceWorkerMessageType } from '../service-worker'
import { createEphemeralOnBroadcaster } from './createEphemeralOnBroadcaster'
import { createEphemeralOnMemory } from './createEphemeralOnMemory'
import {
  Ephemeral,
  EphemeralAccessor,
  EphemeralKey,
  EphemeralServiceWorkerMessage,
  EphemeralServiceWorkerMessageSubType,
} from './types'

export function useNewEphemeral(
  broadcaster: Broadcaster | undefined,
  persistent: Persistent | undefined
): {
  ephemeral: Ephemeral | undefined
} {
  const [output, setOutput] = useState<ReturnType<typeof useNewEphemeral>>({ ephemeral: undefined }) // We need to wrap this in an object, since a key-value store is a function, the set-state method will treat it in a wrong way
  const { ephemeral } = output

  useEffect(() => {
    if (!persistent) {
      setOutput(currentOutput => (currentOutput.ephemeral ? { ephemeral: undefined } : currentOutput))
    } else if (DemoMode.data || PeekMode.data) {
      setOutput({ ephemeral: createEphemeralOnMemory(persistent) })
    } else if (!broadcaster) {
      setOutput(currentOutput => (currentOutput.ephemeral ? { ephemeral: undefined } : currentOutput))
    } else if (!window.navigator.serviceWorker.controller) {
      setOutput({ ephemeral: createEphemeralOnBroadcaster(broadcaster, persistent) })
    } else {
      const messageChannel = new MessageChannel()

      // For some unknown reason, addEventListener didn't work!
      messageChannel.port1.onmessage = event => {
        const message: EphemeralServiceWorkerMessage<EphemeralServiceWorkerMessageSubType.CurrentValues> = event.data
        setOutput({ ephemeral: createEphemeralOnBroadcaster(broadcaster, persistent, message.currentValues) })
      }

      window.navigator.serviceWorker.controller?.postMessage(
        typed<EphemeralServiceWorkerMessage>({
          type: ServiceWorkerMessageType.Ephemeral,
          subType: EphemeralServiceWorkerMessageSubType.RequestCurrentValues,
        }),
        [messageChannel.port2]
      )
    }
  }, [broadcaster, persistent])

  useEffect(() => {
    window.navigator.serviceWorker.addEventListener('message', handleMessage)

    return () => {
      window.navigator.serviceWorker.removeEventListener('message', handleMessage)
    }

    function handleMessage(event: MessageEvent): void {
      if (event.data?.type !== ServiceWorkerMessageType.Ephemeral) return

      const message: EphemeralServiceWorkerMessage = event.data
      switch (message.subType) {
        case EphemeralServiceWorkerMessageSubType.RequestCurrentValues:
          event.ports[0].postMessage(
            typed<EphemeralServiceWorkerMessage>({
              type: ServiceWorkerMessageType.Ephemeral,
              subType: EphemeralServiceWorkerMessageSubType.CurrentValues,
              currentValues: ephemeral?.asObject(),
            })
          )
          break
      }
    }
  }, [ephemeral])

  useWindowDev(
    () => ({
      ephemeral: getEnumKeyValues<EphemeralKey>(EphemeralKey).reduce<
        PartialRecord<string, EphemeralAccessor<EphemeralKey>>
      >((accessors, { key, value }) => {
        accessors[key] = output.ephemeral!(value)
        return accessors
      }, {}),
    }),
    [output],
    {
      disabled: !output.ephemeral,
    }
  )

  return output
}

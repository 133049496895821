import { Typography } from '@material-ui/core'
import { IpcChannel, MainIpcMessageType } from '@zettelooo/desktop-shared'
import { useState } from 'react'
import { useMountedState } from 'react-use'
import { useAsyncEffect } from '../../../../../../../../../../../../hooks/useAsyncEffect'
import { ChromeExtensionMode } from '../../../../../../../../../../../../modules/chrome-extension-mode'
import { useContexts } from '../../../../../../../../../../../../modules/contexts'
import { webConfig } from '../../../../../../../../../../../../modules/web-config'
import { sendIpcMessage } from '../../../../../../../../../../modules/electron'
import { useServices } from '../../../../../../../../../../modules/services'
import { AccountData } from '../../../../../../../../modules/account-data'
import { ButtonField } from '../ButtonField'
import { FieldContainer } from '../FieldContainer'

export function SubscriptionSubPanel() {
  const { account } = useContexts(AccountData.Contexts)

  const [gettingCustomerPortalLinkUrl, setGettingCustomerPortalLinkUrl] = useState(false)

  const { services } = useServices()

  const isMounted = useMountedState()

  useAsyncEffect(async () => {
    if (!gettingCustomerPortalLinkUrl) return
    try {
      const { customerPortalLinkUrl } = await services.account.getCustomerPortalLink(
        // TODO: This URL doesn't work with the Stripe API. Apparently, it expects a valid web URL, not a local app URL:
        webConfig.environment.agent === 'browser'
          ? window.location.href
          : // : webConfig.environment.agent === 'electron'
            // ? webConfig.urls.desktopApp
            'https://zettel.ooo' // TODO: Temporary workaround, it has to redirect to a web URL that opens the desktop app.
      )
      if (!isMounted() || !customerPortalLinkUrl) return
      if (ChromeExtensionMode.isActive) {
        window.open(customerPortalLinkUrl, 'Customer Portal', 'width=600,height=850')
        setGettingCustomerPortalLinkUrl(false)
      } else if (webConfig.environment.agent === 'browser') {
        window.location.href = customerPortalLinkUrl
      } else if (webConfig.environment.agent === 'electron') {
        sendIpcMessage(IpcChannel.Main, { type: MainIpcMessageType.MainRequestSignIn, url: customerPortalLinkUrl })
        setGettingCustomerPortalLinkUrl(false)
      }
    } catch (error) {
      console.error('GettingCustomerPortalLinkUrl:', error)
      if (!isMounted()) return
      setGettingCustomerPortalLinkUrl(false)
    }
  }, [gettingCustomerPortalLinkUrl])

  return (
    <>
      <FieldContainer
        label={
          account.subscription === 'none'
            ? 'Not subscribed:'
            : account.subscription === 'normal'
            ? 'Subscribed to Basic Plan:'
            : 'Subscription unknown:'
        }
      >
        <ButtonField disabled={gettingCustomerPortalLinkUrl} onClick={() => setGettingCustomerPortalLinkUrl(true)}>
          {!gettingCustomerPortalLinkUrl ? (
            <>
              Manage your subscription and payment
              <Typography display="inline" color="textSecondary">
                &nbsp;&nbsp;&gt;
              </Typography>
            </>
          ) : (
            <>Please wait...</>
          )}
        </ButtonField>
      </FieldContainer>
    </>
  )
}

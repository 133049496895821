import { Button, CircularProgress, Input, makeStyles, Typography, useTheme } from '@material-ui/core'
import { captureException } from '@sentry/react'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { useContext, useState } from 'react'
import { useGetSet } from 'react-use'
import { useCommonStyles } from '../../../../../../../../../../../../../hooks/useCommonStyles'
import { useRefWrap } from '../../../../../../../../../../../../../hooks/useRefWrap'
import { sendAnalyticEvent } from '../../../../../../../../../../../../../modules/analytics'
import { useManageCommands, NavigableWithCommands } from '../../../../../../../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../../../../../../../modules/contexts'
import { DeviceProvider } from '../../../../../../../../../../../../../modules/device'
import { commonKeyboardCombinations } from '../../../../../../../../../../../../../modules/keyboard-handler'
import { Navigable, NavigableStatus } from '../../../../../../../../../../../../../modules/navigation'
import { CustomAvatar } from '../../../../../../../../../../../../CustomAvatar'
import { useApplyAction } from '../../../../../../../../../../../hooks/useApplyAction'
import { useFileInputDirectUpload } from '../../../../../../../../../../../hooks/useFileInputDirectUpload'
import { useGenerateModelId } from '../../../../../../../../../../../hooks/useGenerateModelId'
import { useAppNotistack } from '../../../../../../../../../../../modules/app-notistack'
import { AccountData } from '../../../../../../../../../modules/account-data'
import { FieldPaper } from '../../FieldPaper'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(1),
    },
    avatarAndNameContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    avatarWrapper: {
      flex: 'none',
      position: 'relative',
      margin: theme.spacing(1, 2.5, 1, 0),
      width: theme.spacing(6),
      height: theme.spacing(6),
      cursor: 'pointer',
      '&:not($avatarWrapperIsUploading)': {
        '&:hover, &$avatarWrapperMobile': {
          '& $avatar': {
            opacity: 0.3,
          },
          '& $uploadIcon': {
            opacity: 1,
          },
        },
      },
    },
    avatarWrapperMobile: {},
    avatarWrapperIsUploading: {},
    avatar: {
      ...theme.typography.caption,
      transition: '.3s ease',
    },
    uploadIcon: {
      opacity: 0,
      transition: '.3s ease',
    },
    progressWrapper: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      borderRadius: '50%',
      padding: theme.spacing(0.25),
      backgroundColor: theme.palette.background.paper,
      opacity: 0.5,
    },
    progressText: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.main,
    },
    progressCircle: {
      color: theme.palette.primary.main,
    },
    nameInput: {
      ...theme.typography.body2,
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  }),
  { name: 'AvatarField' }
)

export function AvatarField({ className }: { className?: string }) {
  const { isMobile } = useContext(DeviceProvider.Context)
  const { account } = useContexts(AccountData.Contexts)

  const [getName, setName] = useGetSet(account.name)
  const [getIsEditing, setIsEditing] = useGetSet(false)
  const [getIsSubmitting, setIsSubmitting] = useGetSet(false)
  const [getIsUploading, setIsUploading] = useGetSet(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const { enqueueSnackbar } = useAppNotistack()

  const { generateModelIdStatic } = useGenerateModelId()

  const { applyActionStatic } = useApplyAction()

  const { openFileInputAndUploadFile, renderFileInput } = useFileInputDirectUpload({
    allowedFileTypes: ['image/*'],
    onFileSelectAndUploadStart(file) {
      sendAnalyticEvent('User', 'Update avatar')
      setIsUploading(true)
      return generateModelIdStatic()
    },
    onFileUploadProgress(file, fileId, percent) {
      setUploadProgress(percent)
    },
    async onFileUploadSuccess(file, fileId) {
      await applyActionStatic.updateModel({
        type: Model.Type.Account,
        id: account.id,
        avatarFileId: fileId,
      })
      setIsEditing(false)
      setIsUploading(false)
    },
    onFileUploadError(file, fileId, error) {
      log.error('upload user avatar image', 'failed', file.name, file.type, file.size, error)
      captureException(error, { tags: { module: 'account', action: 'upload avatar' } })
      enqueueSnackbar('Error', `Avatar uploading failed.`, { variant: 'error' })
      setIsEditing(false)
      setIsUploading(false)
    },
  })

  const submitRef = useRefWrap(async (): Promise<void> => {
    sendAnalyticEvent('User', 'Update display name')
    if (getName() !== account.name) {
      // TODO: Validate form data
      setIsSubmitting(true)
      await applyActionStatic.updateModel({
        type: Model.Type.Account,
        id: account.id,
        name: getName(),
      })
      setIsSubmitting(false)
    }
    setIsEditing(false)
  })

  const cancelRef = useRefWrap((): void => {
    setName(account.name)
    setIsEditing(false)
  })

  const { runCommandStatic } = useManageCommands()

  const theme = useTheme()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <>
      <Navigable>
        {({ connectNavigable: connectRoot }) => (
          <FieldPaper ref={connectRoot} className={classNames(classes.root, className)}>
            <div className={classes.avatarAndNameContainer}>
              <NavigableWithCommands
                commandGroup={({ navigableRef }) => ({
                  name: 'avatar',
                  commands: [
                    {
                      name: 'selectFile',
                      disabled() {
                        return getIsSubmitting() || getIsUploading()
                      },
                      defaultShortcutKeys: commonKeyboardCombinations.enter,
                      handler() {
                        openFileInputAndUploadFile()
                      },
                    },
                  ],
                })}
              >
                {({ connectNavigable }) => (
                  <div
                    ref={connectNavigable}
                    className={classNames(
                      classes.avatarWrapper,
                      isMobile && classes.avatarWrapperMobile,
                      getIsUploading() && classes.avatarWrapperIsUploading
                    )}
                    onClick={() => {
                      sendAnalyticEvent('User', 'Select avatar file')
                      runCommandStatic('avatar.selectFile')
                    }}
                  >
                    <CustomAvatar
                      size={6}
                      avatarFileId={account.avatarFileId}
                      name={account.name}
                      color={account.color}
                      className={classes.avatar}
                    />

                    {getIsUploading() && (
                      <div className={classNames(commonClasses.center, classes.progressWrapper)}>
                        <CircularProgress
                          size={theme.spacing(5.5)}
                          variant="determinate"
                          className={classes.progressCircle}
                          value={uploadProgress}
                        />
                        <Typography variant="caption" color="textPrimary" className={classes.progressText}>
                          {`${uploadProgress}%`}
                        </Typography>
                      </div>
                    )}

                    <Typography variant="caption" className={classNames(commonClasses.center, classes.uploadIcon)}>
                      <small>Upload</small>
                    </Typography>
                  </div>
                )}
              </NavigableWithCommands>

              <NavigableWithCommands
                selectable
                focusable
                disabled={!getIsEditing()}
                commandGroup={({ navigableRef }) => ({
                  name: 'avatarField',
                  displayName: 'Avatar field',
                  disabled() {
                    return getIsSubmitting() || !getIsEditing()
                  },
                  commands: [
                    {
                      name: 'submit',
                      displayName: 'Submit',
                      disabled() {
                        return getName() === account.name
                      },
                      defaultShortcutKeys: commonKeyboardCombinations.enter,
                      handler() {
                        submitRef.current()
                      },
                    },
                    {
                      name: 'cancel',
                      displayName: 'Cancel',
                      disabled() {
                        return navigableRef.current?.navigableStatus === NavigableStatus.Selected
                      },
                      defaultShortcutKeys: commonKeyboardCombinations.escape,
                      handler() {
                        cancelRef.current()
                      },
                    },
                  ],
                })}
                commandGroupDependencies={[account]}
              >
                {({ connectNavigable }) => (
                  <Input
                    inputRef={connectNavigable}
                    disableUnderline
                    fullWidth
                    autoFocus
                    className={classes.nameInput}
                    placeholder="Full name"
                    readOnly={!getIsEditing()}
                    disabled={getIsSubmitting() || getIsUploading()}
                    value={getName()}
                    onChange={event => setName(event.target.value)}
                  />
                )}
              </NavigableWithCommands>
            </div>

            <div className={classes.actions}>
              {getIsEditing() ? (
                <>
                  <Navigable>
                    {({ connectNavigable }) => (
                      <Button ref={connectNavigable} size="small" onClick={cancelRef.current}>
                        Cancel
                      </Button>
                    )}
                  </Navigable>

                  <Navigable>
                    {({ connectNavigable }) => (
                      <Button
                        ref={connectNavigable}
                        color="secondary"
                        size="small"
                        disabled={getIsSubmitting() || getIsUploading()}
                        onClick={submitRef.current}
                      >
                        Confirm
                      </Button>
                    )}
                  </Navigable>
                </>
              ) : (
                <>
                  <Navigable key="edit button">
                    {({ connectNavigable }) => (
                      <Button
                        ref={connectNavigable}
                        color="secondary"
                        size="small"
                        disabled={getIsSubmitting() || getIsUploading()}
                        onClick={() => {
                          setName(account.name)
                          setIsEditing(true)
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Navigable>
                </>
              )}
            </div>
          </FieldPaper>
        )}
      </Navigable>

      {renderFileInput()}
    </>
  )
}

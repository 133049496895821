import { createContext, useEffect, useState } from 'react'
import { useContexts } from '../../../../../../modules/contexts'
import { MemoizedContextProvider } from '../../../../../MemoizedContextProvider'
import { toursData } from '../../constants/toursData'
import { OnboardingState, OnboardingStep, OnboardingTour, TourStepData } from '../../types'
import { OnboardingProviderContexts } from '../OnboardingProvider'
import { DialogStepView } from './DialogStepView'
import { TooltipStepView } from './TooltipStepView'

export const StepViewContext = createContext<{
  tour: OnboardingTour
  step: OnboardingStep
  stepData: TourStepData
}>(undefined as any)

export function StepView({ onboardingState }: { onboardingState: OnboardingState }) {
  const {
    refs: { tourStepAnchorsRef },
    preparationCount,
  } = useContexts(OnboardingProviderContexts)

  // Just to make sure there are no more preparers willing to prepare for this step:
  const [previousPreparationCount, setPreviousPreparationCount] = useState(1)

  useEffect(() => setPreviousPreparationCount(preparationCount), [preparationCount])

  if (preparationCount > 0 || previousPreparationCount > 0) return null

  const { tour, step } = onboardingState
  const stepData = (toursData as Record<OnboardingTour, Record<string, TourStepData>>)[tour]?.[step]!
  const stepAnchor = tourStepAnchorsRef.current[tour]?.[step]

  return (
    <MemoizedContextProvider
      context={StepViewContext}
      value={{
        tour,
        step,
        stepData,
      }}
      dependencies={[tour, step]}
    >
      {stepAnchor ? (
        <TooltipStepView stepAnchor={stepAnchor} />
      ) : stepData.onlyAsTooltip ? null /* TODO: If we stay at this null state for too long, we should probably quit the onboarding state as well */ : (
        <DialogStepView />
      )}
    </MemoizedContextProvider>
  )
}

import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { typed } from '../../../../helpers/typed'
import { DemoMode } from '../../../../modules/demo-mode'
import { PeekMode } from '../../../../modules/peek-mode'
import { registerServiceWorker, unregisterServiceWorker } from '../../../../modules/service-worker'
import { webConfig } from '../../../../modules/web-config'
import { ServiceWorkerMessageType } from '../service-worker'
import { VersionServiceWorkerMessage, VersionServiceWorkerMessageSubType } from './types'

export function useEnsureServiceWorker(): {
  serviceWorkerIsReady: boolean
} {
  const [serviceWorkerIsReady, setServiceWorkerIsReady] = useState(Boolean(DemoMode.data || PeekMode.data))

  useEffectOnce(() => {
    if (DemoMode.data || PeekMode.data) {
      unregisterServiceWorker()
      return
    }
    registerServiceWorker()

    const messageChannel = new MessageChannel()

    messageChannel.port1.onmessage = handleMessage

    window.navigator.serviceWorker.controller?.postMessage(
      typed<VersionServiceWorkerMessage<VersionServiceWorkerMessageSubType.Get>>({
        type: ServiceWorkerMessageType.Version,
        subType: VersionServiceWorkerMessageSubType.Get,
      }),
      [messageChannel.port2]
    )

    window.navigator.serviceWorker.addEventListener('message', handleMessage)

    return () => {
      window.navigator.serviceWorker.removeEventListener('message', handleMessage)
    }

    function handleMessage(event: MessageEvent): void {
      if (event.data?.type !== ServiceWorkerMessageType.Version) return

      const message: VersionServiceWorkerMessage = event.data
      switch (message.subType) {
        case VersionServiceWorkerMessageSubType.Set:
          setServiceWorkerIsReady(message.version === webConfig.version)
          break
      }
    }
  })

  return {
    serviceWorkerIsReady,
  }
}

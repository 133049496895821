import { useTheme } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import { ComponentProps, ComponentRef, Dispatch, RefObject, SetStateAction } from 'react'
import { useCopyToClipboard } from 'react-use'
import { arrayHelpers } from '../../../../../../../../../../../helpers/native/arrayHelpers'
import { useContextMenu } from '../../../../../../../../../../../hooks/useContextMenu'
import { sendAnalyticEvent } from '../../../../../../../../../../../modules/analytics'
import { NavigableWithCommands } from '../../../../../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../../../../../modules/contexts'
import { commonKeyboardCombinations } from '../../../../../../../../../../../modules/keyboard-handler'
import { useApplyAction } from '../../../../../../../../../hooks/useApplyAction'
import { useAppNotistack } from '../../../../../../../../../modules/app-notistack'
import { useConfirmationDialog } from '../../../../../../../../../modules/confirmation-dialog'
import { getPagePublicUrl } from '../../../../../../../helpers/getPagePublicUrl'
import { PageAvatar } from '../../../../../../PageAvatar'
import { MainPage } from '../../../../MainPage'
import { Panel, useSwitchPanel } from '../../../../panel'

export function useCommandGroupProps(
  account: Model.Account,
  page: Model.Page,
  getIsRenaming: () => boolean,
  setIsRenaming: Dispatch<SetStateAction<boolean>>,
  getNewPageName: () => string,
  setNewPageName: Dispatch<SetStateAction<string>>,
  getDisabled: () => boolean,
  setDisabled: Dispatch<SetStateAction<boolean>>,
  rootRef: RefObject<HTMLDivElement>,
  pageAvatarRef: RefObject<ComponentRef<typeof PageAvatar>>,
  handleContextMenuStatic: ReturnType<typeof useContextMenu>['handleContextMenuStatic'],
  closeContextMenuStatic: ReturnType<typeof useContextMenu>['closeContextMenuStatic'],
  isContextMenuOpenStatic: ReturnType<typeof useContextMenu>['isContextMenuOpenStatic']
): Pick<ComponentProps<typeof NavigableWithCommands>, 'commandGroup' | 'commandGroupDependencies'> {
  const {
    refs: { setIsSideBarMenuOpen },
  } = useContexts(MainPage.Contexts)

  const { switchPanelStatic } = useSwitchPanel()

  const { applyActionStatic } = useApplyAction()

  const [, copyToClipboard] = useCopyToClipboard()

  const { enqueueSnackbar } = useAppNotistack()

  const { confirmStatic } = useConfirmationDialog()

  const theme = useTheme()

  return {
    commandGroup({ navigableRef }) {
      return {
        name: 'page',
        displayName: `Page: ${page.name}`,
        disabled: getDisabled,
        commands: [
          {
            name: 'open',
            displayName: 'Open page',
            disabled() {
              return getIsRenaming() || isContextMenuOpenStatic() || pageAvatarRef.current?.getState() === 'picker'
            },
            defaultShortcutKeys: commonKeyboardCombinations.enter,
            handler() {
              sendAnalyticEvent('PageList', 'Open page tab')
              setIsSideBarMenuOpen(false)
              switchPanelStatic({
                type: Panel.Type.Page,
                pageId: page.id,
              })
            },
          },
          {
            name: 'copyUrl',
            displayName: 'Copy URL',
            disabled() {
              return getIsRenaming() || pageAvatarRef.current?.getState() !== 'none' || !page.public
            },
            icon: 'Copy',
            defaultShortcutKeys: { cmd: true, code: 'KeyC' },
            handler() {
              sendAnalyticEvent('PageList', 'Copy page url')
              closeContextMenuStatic()
              const publicUrl = getPagePublicUrl(page)
              copyToClipboard(publicUrl)
            },
          },
          {
            name: 'rename',
            displayName: 'Rename',
            disabled() {
              return getIsRenaming() || pageAvatarRef.current?.getState() !== 'none'
            },
            icon: 'EditField',
            defaultShortcutKeys: commonKeyboardCombinations.rename,
            handler() {
              sendAnalyticEvent('PageList', 'Rename page name')
              closeContextMenuStatic()
              setNewPageName(page.name)
              setTimeout(() => setIsRenaming(true)) // To let the context menu be closed first before rendering input, so the auto-navigation works correctly
            },
          },
          {
            name: 'submitRename',
            displayName: 'Submit rename',
            disabled() {
              return !getIsRenaming()
            },
            defaultShortcutKeys: commonKeyboardCombinations.enter,
            async handler() {
              sendAnalyticEvent('PageList', 'Submit page rename')
              if (getDisabled()) return
              // TODO: Validate getNewPageName()
              if (getNewPageName() && getNewPageName() !== page.name) {
                setDisabled(true)
                await applyActionStatic.updateModel({
                  type: Model.Type.Page,
                  id: page.id,
                  name: getNewPageName(),
                })
              }
              setIsRenaming(false)
              setDisabled(false)
            },
          },
          {
            name: 'cancelRename',
            displayName: 'Cancel rename',
            disabled() {
              return !getIsRenaming()
            },
            defaultShortcutKeys: commonKeyboardCombinations.escape,
            handler() {
              sendAnalyticEvent('PageList', 'Cancel page rename')
              setIsRenaming(false)
              setDisabled(false)
            },
          },
          {
            name: 'changeAvatar',
            displayName: 'Change avatar',
            disabled() {
              return getIsRenaming() || pageAvatarRef.current?.getState() !== 'none'
            },
            icon: 'ChangeAvatar',
            handler() {
              sendAnalyticEvent('PageList', 'Change page avatar')
              closeContextMenuStatic()
              pageAvatarRef.current?.open()
            },
          },
          {
            name: 'remove',
            displayName: 'Remove',
            disabled() {
              return getIsRenaming() || pageAvatarRef.current?.getState() !== 'none'
            },
            icon: 'Delete',
            defaultShortcutKeys: commonKeyboardCombinations.delete,
            async handler() {
              sendAnalyticEvent('PageList', 'Delete page')
              closeContextMenuStatic()
              const confirmed = await confirmStatic({
                title: `Delete page ${page.name}`,
                content: 'Are you sure to delete this page?',
                confirmLabel: 'Delete page',
                cancelLabel: 'Cancel',
              })
              if (!confirmed) return
              sendAnalyticEvent('PageList', 'Delete page confirmed')
              setDisabled(true)
              await applyActionStatic.deleteModel(page)
              setDisabled(false)
              enqueueSnackbar('Success', 'Page is deleted.', { variant: 'success' })
            },
          },
          {
            name: 'leave',
            displayName: 'Leave',
            disabled() {
              return getIsRenaming() || pageAvatarRef.current?.getState() !== 'none' || page.memberUserIds.length <= 1
            },
            icon: 'Delete',
            defaultShortcutKeys: commonKeyboardCombinations.delete,
            async handler() {
              sendAnalyticEvent('PageList', 'Leave page')
              closeContextMenuStatic()
              const confirmed = await confirmStatic({
                title: `Leave page ${page.name}`,
                content: 'Are you sure to leave this page?',
                confirmLabel: 'Leave page',
                cancelLabel: 'Cancel',
              })
              if (!confirmed) return
              sendAnalyticEvent('PageList', 'Leave page confirmed')
              setDisabled(true)
              await applyActionStatic.updateModel({
                type: Model.Type.Page,
                id: page.id,
                memberUserIds: arrayHelpers.remove(page.memberUserIds, account.id),
              })
              setDisabled(false)
              enqueueSnackbar('Success', "You've left the page.", { variant: 'success' })
            },
          },
          {
            name: 'contextMenu',
            displayName: 'Open context menu',
            disabled() {
              return getIsRenaming() || pageAvatarRef.current?.getState() === 'picker'
            },
            defaultShortcutKeys: ['ContextMenu', 'Backquote'],
            handler() {
              sendAnalyticEvent('PageList', 'Open context menu')
              // TODO: It's not a good idea to simulate an event here, this should have its own handling method:
              handleContextMenuStatic({
                preventDefault() {},
                clientX: (rootRef.current?.offsetLeft ?? 0) + theme.spacing(4),
                clientY: (rootRef.current?.offsetTop ?? 0) + theme.spacing(4),
              })
            },
          },
        ],
      }
    },
    commandGroupDependencies: [account, page, theme],
  }
}

import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'InviteUsersItem' }
)

export function InviteUsersItem({ className, onClick }: { className?: string; onClick(): void }) {
  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <Tooltip title="Invite Users">
      <IconButton className={classNames(classes.root, commonClasses.electronNoDrag, className)} onClick={onClick}>
        <CustomIcon name="InviteUsers" size={2.5} />
      </IconButton>
    </Tooltip>
  )
}

import { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react'
import { createContexts, useContexts } from '../../../../../../../modules/contexts'

export namespace Authentication {
  const sessionStorageKey = 'admin-signed-in-user'

  export type User = 'admin' | null

  export const adminCredentials = {
    username: 'admin',
    password: 'meng',
  } as const

  const Contexts = createContexts(
    ({ memoize, refer }) =>
      (parameters?: { signedInUser: User; setSignedInUser: Dispatch<SetStateAction<User>> }) => ({
        refs: memoize(
          () => ({
            signIn(user: NonNullable<User>): void {
              window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(user))
              parameters?.setSignedInUser(user)
            },
            signOut(): void {
              window.sessionStorage.removeItem(sessionStorageKey)
              parameters?.setSignedInUser(null)
            },
          }),
          []
        ),
        signedInUser: parameters?.signedInUser ?? null,
      }),
    'Authentication'
  )

  export function Provider({ children }: PropsWithChildren<{}>) {
    const [signedInUser, setSignedInUser] = useState<User>(() => {
      const rawSignedInUser = window.sessionStorage.getItem(sessionStorageKey)
      if (!rawSignedInUser) return null
      return JSON.parse(rawSignedInUser)
    })

    return (
      <Contexts.Provider
        parameters={{
          signedInUser,
          setSignedInUser,
        }}
      >
        {children}
      </Contexts.Provider>
    )
  }

  export function useSignedInUser(): {
    signedInUser: User
  } {
    const { signedInUser } = useContexts(Contexts)

    return {
      signedInUser,
    }
  }

  export function useControl(): {
    signInStatic: (user: NonNullable<User>) => void
    signOutStatic: () => void
  } {
    const {
      refs: { signIn, signOut },
    } = useContexts(Contexts)

    return {
      signInStatic: signIn,
      signOutStatic: signOut,
    }
  }
}

import { Dialog, makeStyles } from '@material-ui/core'
import { StepCard } from './StepCard'

const useStyles = makeStyles(
  theme => ({
    paper: {
      padding: theme.spacing(1, 2),
    },
  }),
  { name: 'DialogStepView' }
)

export function DialogStepView() {
  const classes = useStyles()

  return (
    <Dialog maxWidth="xs" classes={{ paper: classes.paper }} open>
      <StepCard />
    </Dialog>
  )
}

import { makeStyles, Paper, Typography } from '@material-ui/core'
import { ActivityList } from './ActivityList'

const useStyles = makeStyles(
  theme => ({
    empty: {
      width: theme.spacing(64),
      maxWidth: '100%',
      margin: '20% auto',
      padding: theme.spacing(10, 6),
      textAlign: 'center',
    },
  }),
  { name: 'ReferencesSubPanel' }
)

export function ReferencesSubPanel({ cardActivities }: { cardActivities: readonly any[] }) {
  const classes = useStyles()

  if (cardActivities.length === 0)
    return (
      <Paper className={classes.empty}>
        <Typography variant="h5" gutterBottom>
          You made it through everything!
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Whenever someone mentions you it will be saved in here.
        </Typography>
      </Paper>
    )

  return <ActivityList cardActivities={cardActivities} />
}

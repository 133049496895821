import { IpcChannel, IpcMessage, IpcInvokeResponse } from '@zettelooo/desktop-shared'
import { webConfig } from '../../../../modules/web-config'

// TODO: This type derivation isn't working:
export async function invokeIpcMessage<C extends IpcChannel, M extends IpcMessage<C>>(
  channel: C,
  message: M
): Promise<IpcInvokeResponse<C, M extends IpcMessage<C, infer T> ? T : never>> {
  if (webConfig.environment.agent === 'electron') {
    return window.electronProxy?.ipc.invoke(channel, message)
  }
  return null as any // Not going to happen
}

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useContext } from 'react'
import { useContexts } from '../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../modules/custom-icon'
import { useConfirmationDialog } from '../../../confirmation-dialog'
import { tourSteps } from '../../constants/tourSteps'
import { OnboardingProviderContexts } from '../OnboardingProvider'
import { StepViewContext } from './StepView'

const useStyles = makeStyles(
  theme => ({
    cardHeaderAction: {
      margin: 0,
    },
    cardMediaWrapper: {
      padding: theme.spacing(0, 2),
    },
    cardMedia: {
      height: 0,
      borderRadius: theme.spacing(1),
      paddingTop: '56.25%', // 16:9
    },
    cardActions: {
      padding: theme.spacing(1, 2, 2, 2),
      justifyContent: 'flex-end',
    },
  }),
  { name: 'StepCard' }
)

export function StepCard() {
  const {
    refs: { setOnboardingState },
  } = useContexts(OnboardingProviderContexts)
  const { tour, step, stepData } = useContext(StepViewContext)

  const { confirmStatic } = useConfirmationDialog()

  const classes = useStyles()

  const steps = tourSteps[tour]
  const stepIndex = steps.indexOf(step)

  return (
    <Card elevation={0}>
      <CardHeader
        title={
          typeof stepData.title === 'string' ? <Typography variant="h6">{stepData.title}</Typography> : stepData.title()
        }
        action={
          <IconButton
            size="small"
            onClick={async () => {
              const confirmed = await confirmStatic({
                content: "There are a few more things we'd like to show you. What would you like to do?",
                confirmLabel: 'Return to tour',
                cancelLabel: 'End tour early',
                forceSelect: true,
              })

              if (!confirmed) {
                setOnboardingState(undefined)
              }
            }}
          >
            <CustomIcon name="Close" />
          </IconButton>
        }
        classes={{ action: classes.cardHeaderAction }}
      />

      {stepData.imageUrl && (
        <div className={classes.cardMediaWrapper}>
          <CardMedia image={stepData.imageUrl} className={classes.cardMedia} />
        </div>
      )}

      <CardContent>
        {typeof stepData.description === 'string' ? (
          <Typography variant="body1">{stepData.description}</Typography>
        ) : (
          stepData.description()
        )}
      </CardContent>

      <CardActions className={classes.cardActions}>
        {stepIndex > 0 && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOnboardingState({
                tour,
                step: steps[stepIndex - 1],
              })
            }}
          >
            Previous
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOnboardingState(
              stepIndex < steps.length - 1
                ? {
                    tour,
                    step: steps[stepIndex + 1],
                  }
                : undefined
            )
          }}
        >
          {stepIndex < steps.length - 1 ? 'Next' : 'Finish'}
        </Button>
      </CardActions>
    </Card>
  )
}

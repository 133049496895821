import { makeStyles, Typography } from '@material-ui/core'
import { ComponentProps, ReactNode } from 'react'
import { PresentationDialogLayout } from '../PresentationDialogLayout'

export * from './Section'
export * from './Sections'
export * from './Actions'
export * from './Image'
export * from './Video'

const useStyles = makeStyles(
  theme => ({
    caption: {
      margin: theme.spacing(0, 3, 2, 0),
    },
    title: {
      margin: theme.spacing(0, 3, 2, 0),
    },
    description: {
      marginRight: theme.spacing(3),
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      boxShadow: theme.customShadows.lightShadow,
    },
  }),
  { name: 'PresentationDialogSlide' }
)

export const Component = function PresentationDialogSlide({
  caption,
  title,
  description,
  children,
  ...presentationDialogLayoutProps
}: Omit<ComponentProps<typeof PresentationDialogLayout>, 'content'> & {
  caption?: ReactNode
  title?: ReactNode
  description?: ReactNode
}) {
  const classes = useStyles()

  return (
    <PresentationDialogLayout
      {...presentationDialogLayoutProps}
      content={
        <>
          {caption && (
            <Typography variant="subtitle1" color="textSecondary" className={classes.caption}>
              {caption}
            </Typography>
          )}

          {title && (
            <Typography variant="h6" color="textPrimary" className={classes.title}>
              {title}
            </Typography>
          )}

          {description && (
            <Typography variant="subtitle1" color="textSecondary" className={classes.description}>
              {description}
            </Typography>
          )}

          <br />
        </>
      }
    >
      {children}
    </PresentationDialogLayout>
  )
}

import { ComponentRef, PropsWithChildren, RefObject, useLayoutEffect, useMemo, useRef } from 'react'
import { useUnmount } from 'react-use'
import { arrayHelpers } from '../../../helpers/native/arrayHelpers'
import { StateAccessor, useStateAccessor } from '../../../hooks/useStateAccessor'
import { createContexts, useContexts } from '../../contexts'
import { generateRuntimeId } from '../../generators'
import { webConfig } from '../../web-config'
import { NavigatorTree } from '../NavigatorTree'
import { NavigationState } from '../types'
import { NavigableContexts } from './Navigable'
import { NavigationContexts } from './NavigationProvider'
import { PathHighlight } from './PathHighlight'

export const NavigationAreaContexts = createContexts(
  ({ memoize }) =>
    (parameters?: {
      pathHighlightRef: RefObject<ComponentRef<typeof PathHighlight>>
      navigationStateAccessor: StateAccessor<NavigationState>
      navigatorTree: NavigatorTree
      isAreaEnabled: boolean
      isAreaActive: boolean
    }) => ({
      refs: memoize(
        () => ({
          redrawPathHighlight(): void {
            parameters?.pathHighlightRef.current?.redraw()
          },
          navigationStateAccessor: parameters?.navigationStateAccessor!,
          navigatorTree: parameters?.navigatorTree!,
        }),
        []
      ),
      isAreaEnabled: parameters?.isAreaEnabled!,
      isAreaActive: parameters?.isAreaActive!,
      navigationState: parameters?.navigationStateAccessor.get()!,
    }),
  'NavigationArea'
)

export function NavigationArea({
  disabled,
  children,
}: PropsWithChildren<{
  disabled?: boolean
}>) {
  const {
    refs: { isKeyboardNavigationActiveAccessor, isNavigationFreezerDegreeAccessor, registerArea, unregisterArea },
    activeAreaId,
  } = useContexts(NavigationContexts)

  const pathHighlightRef = useRef<ComponentRef<typeof PathHighlight>>(null)

  const navigationStateAccessor = useStateAccessor<NavigationState>(
    {
      navigatedPath: [],
      selected: false,
    },
    [],
    {
      equalityFunction(first, second) {
        if (first.selected !== second.selected) return false
        if (first.navigatedPath.length !== second.navigatedPath.length) return false
        if (first.navigatedPath.some((navigableId, index) => navigableId !== second.navigatedPath[index])) return false
        return true
      },
      onChangeBefore(newValue, oldValue) {
        // Disable changing the state if the navigation is freezed:
        if (isNavigationFreezerDegreeAccessor.isNot(0)) return 'ignore change'
      },
      onChangeAfter(newValue, oldValue) {
        navigatorTree.registerNavigatedPath(newValue.navigatedPath)
      },
    }
  )

  const navigatorTree: NavigatorTree = useMemo(
    () =>
      new NavigatorTree({
        onRegister(navigablePath) {
          if (
            navigationStateAccessor.get().navigatedPath.length === 1 &&
            navigationStateAccessor.get().navigatedPath[0] === navigatorTree.rootNavigableId
          ) {
            const extraPath = navigatorTree.getChildNavigableRelativePath(navigatorTree.rootNavigableId)
            if (extraPath) {
              navigationStateAccessor.set({
                navigatedPath: [navigatorTree.rootNavigableId, ...extraPath],
                selected: false,
              })
            }
          }
        },
        onUnregister(navigablePath) {
          const navigableId = arrayHelpers.last(navigablePath)!
          if (navigationStateAccessor.get().navigatedPath.includes(navigableId)) {
            const parentNavigablePath = navigatorTree.getParentNavigablePath(navigablePath)
            const parentNavigableId = arrayHelpers.last(parentNavigablePath)!
            const siblingNavigatorRelativePath = navigatorTree.getChildNavigableRelativePath(parentNavigableId)
            navigationStateAccessor.set({
              navigatedPath: parentNavigablePath.concat(siblingNavigatorRelativePath || []),
              selected: false,
            })
          }
        },
        onUpdateElement(id) {
          // Nothing for now!
        },
        onResizeElements() {
          pathHighlightRef.current?.redraw()
        },
      }),
    []
  )
  useUnmount(() => navigatorTree.dispose())

  const areaId = useMemo(generateRuntimeId, [])
  const rootNavigatorPath = useMemo(() => [navigatorTree.rootNavigableId], [])

  useLayoutEffect(() => {
    if (disabled) return

    registerArea({
      areaId,
      navigate(direction) {
        if (navigationStateAccessor.get().selected) return 'not handled'
        const nextNavigatedPath = navigatorTree.findNextNavigablePath(
          navigationStateAccessor.get().navigatedPath,
          direction
        )
        if (nextNavigatedPath) {
          navigationStateAccessor.set({
            navigatedPath: nextNavigatedPath,
            selected: false,
          })
        }
      },
      enter() {
        if (isKeyboardNavigationActiveAccessor.is(false) || isNavigationFreezerDegreeAccessor.isNot(0))
          return 'not handled'
        if (navigationStateAccessor.get().selected) return 'not handled'
        const navigableId = arrayHelpers.last(navigationStateAccessor.get().navigatedPath)!
        if (navigatorTree.isSelectable(navigableId)) {
          navigationStateAccessor.merge({
            selected: true,
          })
          return
        }
        const extraPath = navigatorTree.getChildNavigableRelativePath(navigableId)
        if (!extraPath) return 'not handled'
        navigationStateAccessor.merge({
          navigatedPath: navigationStateAccessor.get().navigatedPath.concat(extraPath),
        })
      },
      escape() {
        if (isKeyboardNavigationActiveAccessor.is(false) || isNavigationFreezerDegreeAccessor.isNot(0))
          return 'not handled'
        if (navigationStateAccessor.get().selected) {
          navigationStateAccessor.merge({
            selected: false,
          })
          return
        }
        const parentNavigablePath = navigatorTree.getParentNavigablePath(navigationStateAccessor.get().navigatedPath)
        if (parentNavigablePath.length < 2) return 'not handled'
        navigationStateAccessor.merge({
          navigatedPath: parentNavigablePath,
        })
      },
    })

    return () => unregisterArea(areaId)
  }, [disabled])

  useLayoutEffect(() => {
    if (disabled || isNavigationFreezerDegreeAccessor.isNot(0)) return
    navigationStateAccessor.set(navigatorTree.getInitialNavigationState())
  }, [disabled])

  const isAreaEnabled = !disabled
  const isAreaActive = isAreaEnabled && activeAreaId === areaId

  if (webConfig.developerLogs.navigation) {
    if (isAreaActive) {
      // eslint-disable-next-line no-console
      console.log(
        'navigationState =',
        areaId,
        navigationStateAccessor.get().selected,
        navigationStateAccessor.get().navigatedPath
      )
    }
  }

  return (
    <NavigationAreaContexts.Provider
      parameters={{
        pathHighlightRef,
        navigationStateAccessor,
        navigatorTree,
        isAreaEnabled,
        isAreaActive,
      }}
    >
      <NavigableContexts.Provider
        parameters={{
          navigablePath: rootNavigatorPath,
        }}
      >
        {children}
      </NavigableContexts.Provider>

      <PathHighlight ref={pathHighlightRef} />
    </NavigationAreaContexts.Provider>
  )
}

import { Id } from '@zettelooo/commons'
import { Dispatch, PropsWithChildren, SetStateAction, useLayoutEffect, useState } from 'react'
import { objectHelpers } from '../../../../../helpers/native/objectHelpers'
import { createContexts } from '../../../../../modules/contexts'
import { EphemeralKey, useEphemeral } from '../../ephemeral'
import { UploadingFile } from '../types'

export function AttachmentProvider({ children }: PropsWithChildren<{}>) {
  const {
    ephemeral,
    currentValues: [uploadingFileDictionary],
  } = useEphemeral(EphemeralKey.UploadingFileDictionary)

  const [uploadingFilePreviewUrlDictionary, setUploadingFilePreviewUrlDictionary] =
    useState<UploadingFile.PreviewUrl.Dictionary>({})
  const [uploadingFileHandlerDictionary, setUploadingFileHandlerDictionary] =
    useState<UploadingFile.Handler.Dictionary>({})

  useLayoutEffect(() => {
    const deletedFileIds = (Object.keys(uploadingFileHandlerDictionary) as readonly Id[]).filter(
      fileId => !(fileId in uploadingFileDictionary)
    )
    if (deletedFileIds.length > 0) {
      setUploadingFilePreviewUrlDictionary(objectHelpers.omitAll(uploadingFilePreviewUrlDictionary, deletedFileIds))
      setUploadingFileHandlerDictionary(objectHelpers.omitAll(uploadingFileHandlerDictionary, deletedFileIds))
    }

    const fileIds = Object.keys(uploadingFileDictionary) as readonly Id[]
    const abortedFileIds = fileIds.filter(fileId => uploadingFileDictionary[fileId].toBeAborted)
    const retriedFileIds = fileIds.filter(fileId => uploadingFileDictionary[fileId].toBeRetried)
    if (abortedFileIds.length + retriedFileIds.length > 0) {
      const newUploadingFiles = { ...uploadingFileDictionary }
      abortedFileIds.forEach(fileId => {
        uploadingFileHandlerDictionary[fileId]?.abort()
        const { toBeAborted, ...newUploadingFile } = newUploadingFiles[fileId]
        newUploadingFiles[fileId] = newUploadingFile
      })
      retriedFileIds.forEach(fileId => {
        uploadingFileHandlerDictionary[fileId]?.retry()
        const { toBeRetried, ...newUploadingFile } = newUploadingFiles[fileId]
        newUploadingFiles[fileId] = newUploadingFile
      })
      ephemeral[EphemeralKey.UploadingFileDictionary] = newUploadingFiles
    }
  }, [uploadingFileDictionary, uploadingFileHandlerDictionary])

  return (
    <AttachmentProvider.Contexts.Provider
      parameters={{
        uploadingFilePreviewUrlDictionary,
        setUploadingFilePreviewUrlDictionary,
        setUploadingFileHandlerDictionary,
      }}
    >
      {children}
    </AttachmentProvider.Contexts.Provider>
  )
}

AttachmentProvider.Contexts = createContexts(
  ({ memoize }) =>
    (parameters?: {
      uploadingFilePreviewUrlDictionary: UploadingFile.PreviewUrl.Dictionary
      setUploadingFilePreviewUrlDictionary: Dispatch<SetStateAction<UploadingFile.PreviewUrl.Dictionary>>
      setUploadingFileHandlerDictionary: Dispatch<SetStateAction<UploadingFile.Handler.Dictionary>>
    }) => ({
      refs: memoize(
        () => ({
          setUploadingFilePreviewUrlDictionary: parameters?.setUploadingFilePreviewUrlDictionary!,
          setUploadingFileHandlerDictionary: parameters?.setUploadingFileHandlerDictionary!,
        }),
        []
      ),
      uploadingFilePreviewUrlDictionary: parameters?.uploadingFilePreviewUrlDictionary!,
    }),
  'AttachmentProvider'
)

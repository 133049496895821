import { makeStyles } from '@material-ui/core'
import { forwardRef, useState } from 'react'
import { useContexts } from '../../../../../../../../../../modules/contexts'
import { StaticBadge } from '../../../../../../../../../StaticBadge'
import { Initializer } from '../../../../../../../Initializer'
import { Panel } from '../../../panel'
import { PanelMenu } from '../PanelMenu'
import { ReferencesSubPanel } from './components/ReferencesSubPanel'
import { UnreadsSubPanel } from './components/UnreadsSubPanel'

const useStyles = makeStyles(
  theme => ({
    label: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(1),
    },
    title: {
      textAlign: 'left',
    },
  }),
  { name: 'ActivityPanel' }
)

const subPanels: readonly {
  name: string
  renderLabel(count: number, classes: ReturnType<typeof useStyles>): React.JSX.Element
  render(existingActivities: {
    readonly unreadActivities: readonly any[]
    readonly referenceActivities: readonly any[]
  }): React.JSX.Element
}[] = [
  {
    name: 'unreads',
    renderLabel(count, classes) {
      return (
        <div className={classes.label}>
          <span className={classes.title}>Unreads</span>
          {count !== 0 && <StaticBadge badgeContent={count} />}
        </div>
      )
    },
    render(existingActivities) {
      return <UnreadsSubPanel cardActivities={existingActivities.unreadActivities} />
    },
  },
  {
    name: 'mentions',
    renderLabel(count, classes) {
      return (
        <div className={classes.label}>
          <span className={classes.title}>Mentions</span>
          {count !== 0 && <StaticBadge badgeContent={count} />}
        </div>
      )
    },
    render(existingActivities) {
      return <ReferencesSubPanel cardActivities={existingActivities.referenceActivities} />
    },
  },
]

export const ActivityPanel = forwardRef<
  HTMLDivElement,
  {
    panel: Panel<Panel.Type.Activity>
  }
>(function ActivityPanel({ panel }, ref) {
  const { allPages, allCards } = useContexts(Initializer.Contexts)

  const [selectedSubPanel, setSelectedSubPanel] = useState(subPanels[0])

  // TODO: Replace it with the new badges models, when you need to re-enable this component:
  // const existingActivities = useExistingActivities(
  //   cardActivity => {
  //     if (allCards.stillLoading) return false
  //     const card = allCards.dictionary[cardActivity.cardId]
  //     if (!card) return false
  //     const page = allPages.dictionary[card.pageId]
  //     if (!page) return false
  //     return true
  //   },
  //   [allPages, allCards]
  // )

  // const unreadActivities = useMemo(() => existingActivities.filter(isUnreadActivity), [existingActivities])
  // const referenceActivities = useMemo(() => existingActivities.filter(isReferenceActivity), [existingActivities])
  const unreadActivities = [] as any[]
  const referenceActivities = [] as any[]

  const classes = useStyles()

  return (
    <PanelMenu
      ref={ref}
      header="Activity"
      items={subPanels}
      getLabel={panel =>
        panel.renderLabel(panel.name === 'unreads' ? unreadActivities.length : referenceActivities.length, classes)
      }
      selectedItem={selectedSubPanel}
      onSelectItem={panel => setSelectedSubPanel(panel)}
    >
      {selectedSubPanel.render({ unreadActivities, referenceActivities })}
    </PanelMenu>
  )
})

import { Button, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useContext } from 'react'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { sendAnalyticEvent } from '../../../../../../../../../modules/analytics'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { UpdateProvider } from '../../../../../../../modules/update'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(0.25, 1),
    },
  }),
  { name: 'UpdateItem' }
)

export function UpdateItem({ className }: { className?: string }) {
  const { updateIsReady, isUpdating, update } = useContext(UpdateProvider.Context)

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  if (!updateIsReady) return null

  return (
    <Button
      variant="outlined"
      color="inherit"
      size="small"
      className={classNames(classes.root, commonClasses.electronNoDrag, className)}
      disabled={isUpdating}
      onClick={() => {
        sendAnalyticEvent('Title Bar', 'Click on Update Item')
        update()
      }}
    >
      {isUpdating ? (
        'Updating...'
      ) : (
        <>
          <CustomIcon name="ArrowDown" color="inherit" size={2.25} className={commonClasses.easeBlinkAnimation} />
          &nbsp;Update
        </>
      )}
    </Button>
  )
}

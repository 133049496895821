import { makeStyles, Typography } from '@material-ui/core'
import { Keyboard } from '@zettelooo/commons'
import classNames from 'classnames'
import Color from 'color'
import { getKeyboardCombinationDisplayTextParts } from './helpers/get-keyboard-combination-display-text-parts'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      userSelect: 'none',
    },
    prefix: {
      marginRight: theme.spacing(1),
    },
    postfix: {
      marginLeft: theme.spacing(0.5),
    },
    part: {
      borderRadius: theme.spacing(0.5),
      lineHeight: `${theme.spacing(2.5)}px`,
      minWidth: theme.spacing(2.5),
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(0.75),
      textAlign: 'center',
      // color: new Color(theme.palette.text.primary).alpha(0.275).string(),
      backgroundColor: theme.palette.action.selected,
      '&:not(:last-child)': {
        marginRight: theme.spacing(0.5),
      },
    },
    lowercase: {
      textTransform: 'lowercase',
    },
    highlight: {
      // color: theme.palette.text.primary,
      // backgroundColor: theme.palette.action.disabled,
    },
    twinkle: {
      animation: '$twinkle 0.7s ease 0s infinite alternate',
    },
    '@keyframes twinkle': {
      from: {
        color: new Color(theme.palette.text.disabled).alpha(0.275).string(),
        backgroundColor: theme.palette.action.selected,
      },
      to: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.disabled,
      },
    },
  }),
  { name: 'KeyboardCombinationView' }
)

export function KeyboardCombinationView({
  combination,
  prefix,
  postfix,
  highlight,
  twinkle,
  className,
  modifyParts,
}: {
  combination: Keyboard.Combination
  prefix?: string
  postfix?: string
  highlight?: boolean
  twinkle?: boolean
  className?: string
  modifyParts?(parts: string[], combination: Keyboard.Combination): string[]
}) {
  const parts = getKeyboardCombinationDisplayTextParts(combination)

  const classes = useStyles()

  return (
    <div className={classNames(classes.root, className)}>
      {prefix && (
        <Typography variant="caption" className={classes.prefix}>
          {prefix}
        </Typography>
      )}

      {(modifyParts ? modifyParts(parts, combination) : parts).map((part, index) => (
        <Typography
          key={index}
          variant="caption"
          className={classNames(
            classes.part,
            part.length > 1 && classes.lowercase,
            highlight && classes.highlight,
            twinkle && classes.twinkle
          )}
        >
          {part}
        </Typography>
      ))}

      {postfix && (
        <Typography variant="caption" className={classes.postfix}>
          {postfix}
        </Typography>
      )}
    </div>
  )
}

import { Id } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { convertCardModelToCardPublicModel, Model } from '@zettelooo/server-shared'
import { useCallback, useMemo } from 'react'
import { Run } from '../../../../../../Run'
import { useExtensionLifeSpan } from '../../../../../modules/extension'
import { PersistentKey, usePersistent } from '../../../../../modules/persistent'
import { useExtensionPageLifeSpan } from '../../../hooks/useExtensionPageLifeSpan'

export function useExtensionPublicCardViewLifeSpan(
  cardId: Id,
  card: Model.Card | undefined,
  page: Model.Page | undefined
): {
  renderExtensionLifeSpan(): React.JSX.Element | null
} {
  const [, deviceId, authentication] = usePersistent(PersistentKey.DeviceId, PersistentKey.Authentication)

  const { renderExtensionLifeSpan } = useExtensionPageLifeSpan({
    page,
    cards: useMemo(() => (card ? [card] : []), [card]),
  })

  return {
    renderExtensionLifeSpan() {
      if (!card || !page) return null

      return (
        <>
          {renderExtensionLifeSpan()}

          <Run key="publicCardViewLifeSpan">
            {() =>
              useExtensionLifeSpan({
                name: 'publicCardView',
                target: {
                  cardId,
                },
                scopedValues: {
                  [ZettelExtensions.Scope.Device]: deviceId,
                  [ZettelExtensions.Scope.User]: authentication?.decodedAccessToken.userId ?? '',
                  [ZettelExtensions.Scope.Page]: card.pageId,
                },
                dataFactories: {
                  card: useCallback(({ header }) => convertCardModelToCardPublicModel(card, header.id), [card]),
                },
                accessFactory: () => ({}),
                registryFactory: () => ({}),
                dependencies: [deviceId, authentication, cardId, card.pageId],
              })
            }
          </Run>
        </>
      )
    },
  }
}

import { checkVersionCompatibility } from '@zettelooo/commons'
import { version } from '@zettelooo/extension-api'
import { Extension } from '@zettelooo/server-shared'

export function isExtensionCompatible(extensionHeader: Extension.Header): boolean {
  const demandedExtensionApiVersion = extensionHeader.officialDependencies?.['extension-api'] as string | undefined
  if (!demandedExtensionApiVersion || typeof demandedExtensionApiVersion !== 'string') return false

  return checkVersionCompatibility(version, demandedExtensionApiVersion) === 'compatible'
}

import { PartialRecord } from '@zettelooo/commons'
import { doesKeyboardEventMatchCombinations } from './helpers/does-keyboard-event-match-combinations'
import { isKeyboardEventConsumable } from './helpers/is-keyboard-event-consumable'
import { KeyboardEventHandler, KeyboardSubscription, KeyboardSubscriptionKey } from './types'

export class KeyboardHandler {
  private subscriptions: PartialRecord<KeyboardSubscriptionKey, KeyboardSubscription>
  private subscriptionKeys: KeyboardSubscriptionKey[]
  private disablersCount: number
  private masterHandler: (event: KeyboardEvent) => void

  constructor() {
    this.subscriptions = {}
    this.subscriptionKeys = []
    this.disablersCount = 0

    this.masterHandler = event => {
      if (this.disablersCount > 0) return

      const consumableEvent = isKeyboardEventConsumable(event)

      const handlers: KeyboardEventHandler[] = []

      // Later subscriptions have higher priorities:
      for (let i = this.subscriptionKeys.length - 1; i >= 0; i -= 1) {
        const subscription = this.subscriptions[this.subscriptionKeys[i]]

        if (subscription) {
          for (let j = 0; j < subscription.length; j += 1) {
            const { combinations, noConsumption, handler } = subscription[j]

            if ((!consumableEvent || noConsumption) && doesKeyboardEventMatchCombinations(event, combinations)) {
              handlers.push(handler)
            }
          }
        }
      }

      for (let k = 0; k < handlers.length; k += 1) {
        const handled = handlers[k](event) !== 'not handled'

        if (handled) {
          // TODO: This will also prevent a `keypress` event, but not the corresponding `keyup` event.
          //       Make sure it does not cause any problems.
          event.stopImmediatePropagation()
          event.preventDefault()

          return // No more than one handling per each keyboard stroke
        }
      }
    }

    window.addEventListener('keydown', this.masterHandler, true)
  }

  dispose(): void {
    window.removeEventListener('keydown', this.masterHandler, true)
  }

  subscribe(): KeyboardSubscriptionKey {
    let subscriptionKey: KeyboardSubscriptionKey
    do {
      subscriptionKey = Math.random().toString() + Math.random().toString()
    } while (subscriptionKey in this.subscriptions)

    this.subscriptionKeys.push(subscriptionKey)
    return subscriptionKey
  }

  unsubscribe(subscriptionKey: KeyboardSubscriptionKey): void {
    delete this.subscriptions[subscriptionKey]
    const index = this.subscriptionKeys.indexOf(subscriptionKey)
    if (index >= 0) {
      this.subscriptionKeys.splice(index, 1)
    }
  }

  setSubscription(subscriptionKey: KeyboardSubscriptionKey, subscription: KeyboardSubscription): void {
    if (!this.subscriptionKeys.includes(subscriptionKey)) return
    this.subscriptions[subscriptionKey] = subscription
  }

  clearSubscription(subscriptionKey: KeyboardSubscriptionKey): void {
    delete this.subscriptions[subscriptionKey]
  }

  enable(): void {
    if (this.disablersCount === 0) return
    this.disablersCount -= 1
  }

  disable(): void {
    this.disablersCount += 1
  }
}

import { createKeyValueStore, KeyValueEngine } from '../../../../modules/key-value-store'
import { Persistent } from '../persistent'
import { equalityCheckers } from './equalityCheckers'
import { getDefaultValues } from './getDefaultValues'
import { Ephemeral } from './types'

export function createEphemeralOnMemory(persistent: Persistent): Ephemeral {
  const engine = new KeyValueEngine(() => getDefaultValues(persistent), equalityCheckers, {})
  const ephemeral = createKeyValueStore(engine)

  return ephemeral
}

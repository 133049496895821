import { init } from '@amplitude/analytics-browser'
import { webConfig } from '../../web-config'

export function initializeAmplitude(): void {
  if (webConfig.analytics.disabled || webConfig.analytics.amplitude.disabled) return
  try {
    init(webConfig.analytics.amplitude.apiKey)
  } catch (error) {
    log.error(error)
  }
}

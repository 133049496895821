// See: https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Recommended_drag_types

export namespace NativeDragObject {
  export enum Type {
    File = 'FILE',
    Html = 'HTML',
    Text = 'TEXT',
    Url = 'URL',
    Unknown = 'UNKNOWN',
  }

  export interface File {
    readonly type: NativeDragObject.Type.File
    readonly files: readonly File[]
    readonly items: DataTransferItemList
  }

  export interface Html {
    readonly type: NativeDragObject.Type.Html
    readonly html: string
    readonly text?: string
  }

  export interface Text {
    readonly type: NativeDragObject.Type.Text
    readonly text: string
  }

  export interface Url {
    readonly type: NativeDragObject.Type.Url
    readonly urls: readonly string[]
  }

  export interface Unknown {
    readonly type: NativeDragObject.Type.Unknown
  }
}

import { useEffect, useState } from 'react'
import { NativeDragObject, useDrop } from '../../../../modules/drag-and-drop'

type Status = 'none' | 'files' | 'html' | 'text' | 'urls'

export function useExternalDraggingOverStatus(): 'none' | 'files' | 'html' | 'text' | 'urls' {
  const [status, setStatus] = useState<Status>('none')

  const { connectDropTarget } = useDrop<
    NativeDragObject.File | NativeDragObject.Html | NativeDragObject.Text | NativeDragObject.Url,
    void
  >({
    check(event, dragObject) {
      switch (dragObject.type) {
        case NativeDragObject.Type.File:
        case NativeDragObject.Type.Html:
        case NativeDragObject.Type.Text:
        case NativeDragObject.Type.Url:
          return 'allowed'
      }
    },
    drag(event, dragObject, { entered }) {
      switch (dragObject.type) {
        case NativeDragObject.Type.File:
          setStatus(entered ? 'files' : 'none')
          return 'none'

        case NativeDragObject.Type.Html:
          setStatus(entered ? 'html' : 'none')
          return 'none'

        case NativeDragObject.Type.Text:
          setStatus(entered ? 'text' : 'none')
          return 'none'

        case NativeDragObject.Type.Url:
          setStatus(entered ? 'urls' : 'none')
          return 'none'
      }
    },
  })

  useEffect(() => {
    connectDropTarget(window.document.body)
    return () => void connectDropTarget(null)
  }, [connectDropTarget])

  return status
}

import { createContext, PropsWithChildren } from 'react'
import { Services } from './types'

export function ServicesProvider({
  services,
  children,
}: PropsWithChildren<{
  services: Services
}>) {
  return <ServicesProvider.Context.Provider value={services}>{children}</ServicesProvider.Context.Provider>
}

export namespace ServicesProvider {
  export const Context = createContext<Services>(undefined as any)
}

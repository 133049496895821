import { makeStyles, Typography } from '@material-ui/core'
import { Keyboard } from '@zettelooo/commons'
import classNames from 'classnames'
import { Fragment } from 'react'
import { commonKeyboardCombinations } from './common-keyboard-combinations'
import { KeyboardCombinationView } from './KeyboardCombinationView'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      userSelect: 'none',
    },
    separator: {
      padding: theme.spacing(0, 1),
      // color: theme.palette.text.disabled,
    },
  }),
  { name: 'KeyboardCombinationsView' }
)

export function KeyboardCombinationsView({
  combinations,
  prefix,
  postfix,
  highlight,
  twinkle,
  className,
  modifyParts,
}: {
  combinations: Keyboard.Combinations
  prefix?: string
  postfix?: string
  highlight?: boolean
  twinkle?: boolean
  className?: string
  modifyParts?(parts: string[], combination: Keyboard.Combination): string[]
}) {
  const evaluatedCombinations: readonly Keyboard.Combination[] = !Array.isArray(combinations)
    ? [combinations]
    : combinations === commonKeyboardCombinations.enter
    ? ['Enter']
    : combinations

  const classes = useStyles()

  return (
    <div className={classNames(classes.root, className)}>
      {evaluatedCombinations.map((combination, index) => (
        <Fragment key={index}>
          {index > 0 && (
            <Typography variant="caption" className={classes.separator}>
              or
            </Typography>
          )}
          <KeyboardCombinationView
            combination={combination}
            prefix={index === 0 ? prefix : undefined}
            postfix={index === evaluatedCombinations.length - 1 ? postfix : undefined}
            highlight={highlight}
            twinkle={twinkle}
            modifyParts={modifyParts}
          />
        </Fragment>
      ))}
    </div>
  )
}

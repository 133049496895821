import { useContext, useLayoutEffect, useRef } from 'react'
import { typed } from '../../helpers/typed'
import { useRefEffect } from '../../hooks/useRefEffect'
import { useRefWrap } from '../../hooks/useRefWrap'
import { DRAG_EVENT_MANAGED_KEY } from './constants'
import { Context } from './Context'
import { NativeDragObject } from './NativeDragObject'
import { DragData } from './types'

export function useDrop<DragObject, DropResult>(options: {
  dropTarget?: HTMLElement | null
  check?(event: DragEvent, dragObject: DragObject, flags: { managed: boolean }): 'allowed' | 'denied' | boolean | void
  dragEnter?(event: DragEvent, dragObject: DragObject, flags: { allowed: boolean; managed: boolean }): void
  dragOver?(event: DragEvent, dragObject: DragObject, flags: { allowed: boolean; managed: boolean }): void
  dragLeave?(event: DragEvent, dragObject: DragObject, flags: { allowed: boolean; managed: boolean }): void
  drag?(
    event: DragEvent,
    dragObject: DragObject,
    flags: { entered: boolean; allowed: boolean; accepted: boolean; managed: boolean }
  ): void | DataTransfer['dropEffect']
  drop?(event: DragEvent, dragObject: DragObject, drop: (dropResult: DropResult) => void): void
  dropEvenManaged?(
    event: DragEvent,
    dragObject: DragObject,
    drop: (dropResult: DropResult) => void,
    flags: { managed: boolean }
  ): void
  disabled?: boolean
}): {
  connectDropTarget: useRefEffect.Connect
} {
  const { getDragDataStatic } = useContext(Context)

  const optionsRef = useRefWrap(options)

  // When draging over multiple children, it first enters the new child, then leaves the old child.
  // As a result, the event sequence will be like: Enter(root), Enter(child-1), Leave(root), Enter(child-2), Leave(child-1), Leave(child-2).
  // Therefore, we need to keep track of the last entered event target to not being deceived by the intermediate leave events:
  const lastDragEnterEventTargetRef = useRef<EventTarget | null>(null)

  const connectDropTarget = useRefEffect(
    dropTargetElement => {
      if (dropTargetElement && !options.disabled) {
        const handleDragEnter = (event: DragEvent): void => {
          lastDragEnterEventTargetRef.current = event.target
          const { dragObject } = getDragData(event)
          const managed = getManaged(event)
          const allowed = check(event, dragObject, managed)
          setManaged(event, allowed)
          optionsRef.current.dragEnter?.(event, dragObject, { allowed, managed })
          const dropEffect = optionsRef.current.drag?.(event, dragObject, {
            entered: true,
            allowed,
            accepted: allowed,
            managed,
          })
          if (!managed) {
            if (allowed) {
              event.preventDefault() // To allow dropping
            }
            if (dropEffect && event.dataTransfer) {
              event.dataTransfer.dropEffect = dropEffect
            }
          }
        }

        const handleDragOver = (event: DragEvent): void => {
          const { dragObject } = getDragData(event)
          const managed = getManaged(event)
          const allowed = check(event, dragObject, managed)
          setManaged(event, allowed)
          optionsRef.current.dragOver?.(event, dragObject, { allowed, managed })
          const dropEffect = optionsRef.current.drag?.(event, dragObject, {
            entered: true,
            allowed,
            accepted: allowed,
            managed,
          })
          if (!managed) {
            if (allowed) {
              event.preventDefault() // To allow dropping
            }
            if (dropEffect && event.dataTransfer) {
              event.dataTransfer.dropEffect = dropEffect
            }
          }
        }

        const handleDragLeave = (event: DragEvent): void => {
          const { dragObject } = getDragData(event)
          const managed = getManaged(event)
          const allowed = check(event, dragObject, managed)
          setManaged(event, allowed)
          if (lastDragEnterEventTargetRef.current === event.target) {
            optionsRef.current.drag?.(event, dragObject, { allowed, entered: false, accepted: false, managed })
          }
          optionsRef.current.dragLeave?.(event, dragObject, { allowed, managed })
        }

        const handleDrop = (event: DragEvent): void => {
          const { dragObject, drop } = getDragData(event)
          const managed = getManaged(event)
          const allowed = check(event, dragObject, managed)
          setManaged(event, allowed)
          optionsRef.current.drag?.(event, dragObject, { allowed, entered: false, accepted: false, managed })
          if (allowed) {
            event.preventDefault() // To prevent the browser to handle the dropped link or file by default
            if (!managed) {
              optionsRef.current.drop?.(event, dragObject, dropResult => void drop?.(dragObject, dropResult))
            }
            optionsRef.current.dropEvenManaged?.(event, dragObject, dropResult => void drop?.(dragObject, dropResult), {
              managed,
            })
          }
        }

        dropTargetElement.addEventListener('dragenter', handleDragEnter)
        dropTargetElement.addEventListener('dragover', handleDragOver)
        dropTargetElement.addEventListener('dragleave', handleDragLeave)
        dropTargetElement.addEventListener('drop', handleDrop)

        return () => {
          dropTargetElement.removeEventListener('dragenter', handleDragEnter)
          dropTargetElement.removeEventListener('dragover', handleDragOver)
          dropTargetElement.removeEventListener('dragleave', handleDragLeave)
          dropTargetElement.removeEventListener('drop', handleDrop)
        }
      }

      function getManaged(event: DragEvent): boolean {
        const extendedEvent = event as any
        return extendedEvent[DRAG_EVENT_MANAGED_KEY] ?? false
      }

      function setManaged(event: DragEvent, allowed: boolean): void {
        const extendedEvent = event as any
        extendedEvent[DRAG_EVENT_MANAGED_KEY] ||= allowed
      }

      function getDragData(event: DragEvent): DragData {
        const dragData = getDragDataStatic()
        if (dragData) return dragData

        const hasUrlsData = event.dataTransfer?.types.includes('text/uri-list')
        if (hasUrlsData)
          return {
            dragObject: typed<NativeDragObject.Url>({
              type: NativeDragObject.Type.Url,
              urls:
                event.dataTransfer
                  ?.getData('text/uri-list')
                  .split('\n')
                  .filter(url => url && !url.trim().startsWith('#')) ?? [],
            }),
          }

        if (event.dataTransfer?.types.includes('Files'))
          return {
            dragObject: typed<NativeDragObject.File>({
              type: NativeDragObject.Type.File,
              files: Array.prototype.slice.call(event.dataTransfer.files),
              items: event.dataTransfer.items,
            }),
          }

        const hasHtmlData = event.dataTransfer?.types.includes('text/html')
        if (hasHtmlData)
          return {
            dragObject: typed<NativeDragObject.Html>({
              type: NativeDragObject.Type.Html,
              html: event.dataTransfer?.getData('text/html') ?? '',
              text: event.dataTransfer?.types.includes('text/plain')
                ? event.dataTransfer.getData('text/plain')
                : undefined,
            }),
          }

        const hasTextData = event.dataTransfer?.types.includes('text/plain')
        if (hasTextData)
          return {
            dragObject: typed<NativeDragObject.Text>({
              type: NativeDragObject.Type.Text,
              text: event.dataTransfer?.getData('text/plain') ?? '',
            }),
          }

        return {
          dragObject: typed<NativeDragObject.Unknown>({
            type: NativeDragObject.Type.Unknown,
          }),
        }
      }

      function check(event: DragEvent, dragObject: DragObject, managed: boolean): boolean {
        if (!optionsRef.current.check) return true
        const result = optionsRef.current.check(event, dragObject, { managed })
        return result === 'allowed' || result === true
      }
    },
    [options.disabled]
  )

  useLayoutEffect(() => {
    if (options.dropTarget) {
      connectDropTarget(options.dropTarget)
      return () => void connectDropTarget(null)
    }
  }, [options.dropTarget, connectDropTarget])

  return {
    connectDropTarget,
  }
}

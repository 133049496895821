import { Button, makeStyles } from '@material-ui/core'
import { webConfig } from '../../../../../../../modules/web-config'
import { useAppHistoryControl } from '../../../../../modules/app-history'
import { routes } from '../../../modules/routes'

const useStyles = makeStyles(
  theme => ({
    root: {},
  }),
  { name: 'TryItem' }
)

export function TryItem() {
  const { appHistoryControlStatic } = useAppHistoryControl()

  const classes = useStyles()

  return (
    <Button
      variant="outlined"
      size="small"
      className={classes.root}
      onClick={() => appHistoryControlStatic.push(routes.signIn.getPath({}, {}))}
    >
      Try {webConfig.app.name}
    </Button>
  )
}

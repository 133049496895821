import { Button, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { sendAnalyticEvent, useAnalyticSendEvent } from '../../../../../../../modules/analytics'
import { CustomIcon } from '../../../../../../../modules/custom-icon'
import { DemoMode } from '../../../../../../../modules/demo-mode'
import { PeekMode } from '../../../../../../../modules/peek-mode'
import { webConfig } from '../../../../../../../modules/web-config'
import { Gap } from '../../../../../../Gap'
import { useAppHistoryControl } from '../../../../../modules/app-history'
import { Authentication } from '../../../../../modules/authentication'
import { PersistentKey, usePersistent } from '../../../../../modules/persistent'
import { AutomaticUpdater, UpdateProvider } from '../../../../../modules/update'
import { Anchor } from '../../../../Anchor'
import { TitleBarAppNameItem } from '../../../../TitleBarAppNameItem'
import { routes } from '../../../modules/routes'
import { TitleBarLayout } from '../../TitleBarLayout'
import { DecorativeSquares } from './DecorativeSquares'
import { useFinishSignIn } from './useFinishSignIn'
import { useNewUserData } from './useNewUserData'
import { useQuery } from './useQuery'
import { useSubscriptionCheck } from './useSubscriptionCheck'
import { useWeb2SignIn } from './useWeb2SignIn'
import { useWeb3SignIn } from './useWeb3SignIn'

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    titleBar: {
      zIndex: 1,
    },
    bullet: {
      position: 'relative',
      '&::before': {
        content: "'>'",
        position: 'absolute',
        left: '-1.5em',
      },
    },
    line: {
      display: 'block',
      fontWeight: 800,
      marginBottom: theme.spacing(1),
    },
    anchor: {
      fontWeight: 900,
      color: theme.palette.info.dark,
      cursor: 'pointer',
    },
    button: {
      margin: 'auto',
      display: 'flex',
      width: theme.spacing(27),
      maxWidth: '100%',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2, 4, 2, 2),
      backgroundColor: theme.palette.background.default,
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
    icon: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'SignInPage' }
)

export function SignInPage() {
  useAnalyticSendEvent('SignIn Page', 'Page View')

  const query = useQuery()

  const { persistent } = usePersistent()

  const { updateIsReady } = useContext(UpdateProvider.Context)

  const [inputFocused, setInputFocused] = useState(false)

  const { appHistoryControlStatic } = useAppHistoryControl()

  const { demoContent } = DemoMode.useContent()

  const { openingDesktopAppUrl, finishSignIn } = useFinishSignIn(query)

  const { newUserDataState, newUserDataControl } = useNewUserData()

  const { subscriptionCheckState, subscriptionCheckControl } = useSubscriptionCheck(query, finishSignIn)

  const { web2 } = useWeb2SignIn({
    query,
    subscriptionCheckControl,
    newUserDataControl,
    finishSignIn,
  })

  const { web3 } = useWeb3SignIn({
    query,
    subscriptionCheckControl,
    newUserDataControl,
    finishSignIn,
  })

  useEffect(() => {
    if (PeekMode.data) {
      persistent(PersistentKey.Authentication).set(
        Authentication.decode(PeekMode.data.accessToken, PeekMode.data.refreshToken)
      )
    } else if (DemoMode.data && demoContent) {
      persistent(PersistentKey.Authentication).set(
        Authentication.decode(demoContent.accessToken, demoContent.refreshToken)
      )
    }
  }, [demoContent])

  const classes = useStyles()

  const loading = web2.loading
  const disabled = loading || newUserDataState.submitting || web2.redirecting
  const userIsInteracting = disabled || inputFocused

  function submitNeededValuesForm(): void {
    if (disabled) return
    sendAnalyticEvent('SignIn Page', 'Submit SignUp form')
    newUserDataState.submit()
  }

  if (DemoMode.data || PeekMode.data) return null

  return (
    <>
      <AutomaticUpdater />

      <TitleBarLayout className={classes.titleBar}>
        <TitleBarAppNameItem link />
      </TitleBarLayout>

      {!updateIsReady && (
        <DecorativeSquares className={classes.root} userIsInteracting={userIsInteracting}>
          <Typography variant="h6">
            {openingDesktopAppUrl ? (
              <div className={classes.bullet}>
                <div className={classes.line}>Redirecting to the app...</div>
                <div className={classes.line}>
                  Click{' '}
                  <Anchor highlight className={classes.anchor} href={openingDesktopAppUrl}>
                    here
                  </Anchor>{' '}
                  if the app didn't open.
                </div>
              </div>
            ) : web3.notMainnet ? (
              <div className={classes.bullet}>
                <div className={classes.line}>The selected network is not Mainnet!</div>
                <Anchor
                  highlight
                  className={classNames(classes.line, classes.anchor)}
                  onClick={() => {
                    sendAnalyticEvent('SignIn Page', 'Click on Change the network and try again')
                    web3.signIn()
                  }}
                >
                  Change the network and try again
                </Anchor>
              </div>
            ) : newUserDataState.formIsOpen ? (
              <>
                <div className={classes.bullet}>
                  <div className={classes.line}>Welcome to {webConfig.app.name}!</div>
                </div>
                <Gap vertical={1} />
                <form
                  onSubmit={event => {
                    event.preventDefault()
                    submitNeededValuesForm()
                  }}
                >
                  {newUserDataState.neededFields.includes('userName') && (
                    <>
                      <TextField
                        fullWidth
                        variant="outlined"
                        InputProps={{ startAdornment: <InputAdornment position="start">@</InputAdornment> }}
                        label="Username"
                        placeholder="Your unique user name"
                        autoFocus
                        onFocus={() => setInputFocused(true)}
                        onBlur={() => setInputFocused(false)}
                        error={Boolean(newUserDataState.neededValuesErrors.userName)}
                        helperText={newUserDataState.neededValuesErrors.userName}
                        disabled={disabled}
                        value={newUserDataState.neededValues.userName ?? ''}
                        onChange={event => newUserDataState.setNeededValue('userName', event.target.value)}
                      />
                      <Gap vertical={1} />
                    </>
                  )}
                  {newUserDataState.neededFields.includes('name') && (
                    <>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Name"
                        placeholder="Your name to display"
                        autoFocus
                        onFocus={() => setInputFocused(true)}
                        onBlur={() => setInputFocused(false)}
                        error={Boolean(newUserDataState.neededValuesErrors.name)}
                        helperText={newUserDataState.neededValuesErrors.name}
                        disabled={disabled}
                        value={newUserDataState.neededValues.name ?? ''}
                        onChange={event => newUserDataState.setNeededValue('name', event.target.value)}
                      />
                      <Gap vertical={1} />
                    </>
                  )}
                  {newUserDataState.neededFields.includes('email') && (
                    <>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Email"
                        placeholder="Your email"
                        autoFocus
                        onFocus={() => setInputFocused(true)}
                        onBlur={() => setInputFocused(false)}
                        error={Boolean(newUserDataState.neededValuesErrors.email)}
                        helperText={newUserDataState.neededValuesErrors.email}
                        disabled={disabled}
                        value={newUserDataState.neededValues.email ?? ''}
                        onChange={event => newUserDataState.setNeededValue('email', event.target.value)}
                      />
                      <Gap vertical={1} />
                    </>
                  )}
                  {newUserDataState.neededFields.includes('walletAddress') && (
                    <>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Wallet address (optional)"
                        placeholder="Your wallet address"
                        autoFocus
                        onFocus={() => setInputFocused(true)}
                        onBlur={() => setInputFocused(false)}
                        error={Boolean(newUserDataState.neededValuesErrors.walletAddress)}
                        helperText={newUserDataState.neededValuesErrors.walletAddress}
                        disabled={disabled}
                        value={newUserDataState.neededValues.walletAddress ?? ''}
                        onChange={event => newUserDataState.setNeededValue('walletAddress', event.target.value)}
                      />
                      <Gap vertical={1} />
                    </>
                  )}
                  <input type="submit" hidden />
                  <Button fullWidth variant="contained" color="primary" onClick={submitNeededValuesForm}>
                    Submit
                  </Button>
                </form>
              </>
            ) : subscriptionCheckState.status === 'show plans' ? (
              <>
                <div className={classes.line}>Subscribe to {webConfig.app.name} to continue:</div>
                <Gap vertical={2} />
                <div className={classes.bullet}>
                  <Anchor
                    highlight
                    className={classNames(classes.line, classes.anchor)}
                    onClick={() => subscriptionCheckState.selectPlan('normal')}
                  >
                    Basic Plan
                  </Anchor>
                  <div className={classes.line}>4.99 USD/month, 14 day free trial, Cancel any time.</div>
                </div>
              </>
            ) : subscriptionCheckState.status === 'getting subscription link' ? (
              <div className={classes.line}>Redirecting to the subscription page...</div>
            ) : subscriptionCheckState.status === 'failed to get subscription link' ? (
              <>
                <div className={classes.line}>Failed to get the redirection link.</div>
                <Gap vertical={2} />
                <div className={classes.bullet}>
                  <Anchor
                    highlight
                    className={classNames(classes.line, classes.anchor)}
                    onClick={() => window.location.reload()}
                  >
                    Try again
                  </Anchor>
                </div>
              </>
            ) : subscriptionCheckState.status === 'subscribed' ? (
              <div className={classes.line}>Subscribed successfully!</div>
            ) : subscriptionCheckState.status === 'subscription canceled' ? (
              <>
                <div className={classes.line}>Not subscribed.</div>
                <Gap vertical={2} />
                <div className={classes.bullet}>
                  <Anchor
                    highlight
                    className={classNames(classes.line, classes.anchor)}
                    onClick={() => {
                      appHistoryControlStatic.push(routes.signIn.getPath({}, { agent: query.rawQuery.agent }))
                      window.location.reload()
                    }}
                  >
                    Sign-in with a different account
                  </Anchor>
                </div>
              </>
            ) : web3.signingIn ? (
              <div className={classes.line}>Connecting Metamask...</div>
            ) : loading ? (
              <div className={classes.line}>Please, wait...</div>
            ) : (
              <>
                <div className={classes.bullet}>
                  <div className={classes.line}>Hi, welcome to {webConfig.app.name}!</div>
                </div>
                <Gap vertical={4} />
                <Button
                  variant="outlined"
                  className={classes.button}
                  disabled={disabled}
                  onClick={() => {
                    sendAnalyticEvent('SignIn Page', 'Click on sign in with Google')
                    web2.signInWithGoogle()
                  }}
                >
                  <CustomIcon name="Google" size="small" className={classes.icon} />
                  <Typography variant="subtitle2">Sign in with Google</Typography>
                </Button>
                <Button
                  variant="outlined"
                  className={classes.button}
                  disabled={disabled}
                  onClick={() => {
                    sendAnalyticEvent('SignIn Page', 'Click on sign in with wallet')
                    web3.signIn()
                  }}
                >
                  <CustomIcon name="User" size="small" className={classes.icon} />
                  <Typography variant="subtitle2">Sign in with Wallet</Typography>
                </Button>
              </>
            )}
          </Typography>
        </DecorativeSquares>
      )}
    </>
  )
}

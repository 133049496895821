import { ZettelExtensions } from '@zettelooo/extension-api'
import { referenceKey } from './referenceKey'
import { stateAndListenersMap } from './stateAndListenersMap'
import { WithReferenceKey } from './withReferenceKey'

export function handleUpdates<H extends ZettelExtensions.HtmlContent<any>>(
  oldHtmlContentWithReference: WithReferenceKey<H>,
  updates: Partial<H>
): void {
  const initialStateIsUpdated = 'initialState' in updates
  const renderIsUpdated = 'render' in updates
  const referenceValue = oldHtmlContentWithReference[referenceKey]
  const stateAndListeners = stateAndListenersMap.get(referenceValue)
  if (initialStateIsUpdated && stateAndListeners) {
    stateAndListeners.state = updates.initialState
    stateAndListeners.listeners.forEach(listener => listener(stateAndListeners.state, renderIsUpdated))
  }
}

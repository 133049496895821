import {
  Button,
  CircularProgress,
  IconButton,
  Input,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Keyboard } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useMountedState } from 'react-use'
import { useCombineRefs } from '../../../../../../../../../../../../hooks/useCombineRefs'
import { useRefWrap } from '../../../../../../../../../../../../hooks/useRefWrap'
import { useContexts } from '../../../../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../../../../modules/custom-icon'
import { Navigable } from '../../../../../../../../../../../../modules/navigation'
import { Gap } from '../../../../../../../../../../../Gap'
import { TypographyNoWrap } from '../../../../../../../../../../../TypographyNoWrap'
import { useApplyAction } from '../../../../../../../../../../hooks/useApplyAction'
import { useServices } from '../../../../../../../../../../modules/services'
import { PagePanel } from '../../PagePanel'
import { Layout } from '../Layout'
import { Mode } from '../type'
import { History } from './History'
import { useSuggestedTips } from './useSuggestedTips'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(1),
      paddingTop: 0,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    itemContainer: {
      position: 'relative',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1.25),
      padding: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
      overflow: 'hidden',
      '&:not(:nth-last-child(2))': {
        marginBottom: theme.spacing(2),
      },
      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
      },
    },
    contentWithIcon: {
      display: 'flex',
      gap: theme.spacing(1),
      '& > *:first-child': {
        position: 'relative',
        top: theme.spacing(0.5),
      },
    },
    undoButton: {
      alignSelf: 'flex-end',
    },
    inputEnabled: {
      cursor: 'text',
    },
    inputDisabled: {
      cursor: 'wait',
    },
    input: {
      ...theme.typography.body1,
      padding: 0,
    },
    circularProgressWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      userSelect: 'none',
      pointerEvents: 'none',
    },
    clickableTip: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
  { name: 'CommandLine' }
)

export function CommandLine({
  asDefaultSideBar,
}: // extendedCommandLinePromptInput,
{
  asDefaultSideBar?: boolean
  // extendedCommandLinePromptInput?: ZettelExtensions.LifeSpan.Shared.PagePanel.CommandLinePromptInput | undefined
}) {
  const {
    refs: { setSideBarMode, addExtensionsToBeInitialized },
    page,
  } = useContexts(PagePanel.Contexts)

  const [command, setCommand] = useState('')
  const [promptInput, setPromptInput] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [history, setHistory] = useState<History>([])

  const inputRef = useRef<HTMLInputElement>(null)
  const bottomLineRef = useRef<HTMLDivElement>(null)
  // const submittedExtendedCommandLinePromptInputRef = useRef<
  //   ZettelExtensions.LifeSpan.Shared.PagePanel.CommandLinePromptInput | undefined
  // >(undefined)

  // useLayoutEffect(() => {
  //   if (
  //     submittedExtendedCommandLinePromptInputRef.current &&
  //     submittedExtendedCommandLinePromptInputRef.current !== extendedCommandLinePromptInput
  //   ) {
  //     setHistory(current => {
  //       const prompt = submittedExtendedCommandLinePromptInputRef.current?.prompt ?? ''
  //       submittedExtendedCommandLinePromptInputRef.current = undefined
  //       return [
  //         ...current,
  //         {
  //           type: 'input',
  //           prompt,
  //           input: promptInput,
  //         },
  //       ]
  //     })
  //   }
  //   setPromptInput(extendedCommandLinePromptInput?.initialValue ?? '')
  //   setTimeout(() => inputRef.current?.focus())
  // }, [extendedCommandLinePromptInput])

  useLayoutEffect(() => {
    setHistory([])
  }, [page.id])

  useEffect(() => {
    bottomLineRef.current?.scrollIntoView()
  }, [history])

  const { services } = useServices()

  const { applyActionStatic } = useApplyAction()

  const isMounted = useMountedState()

  const submitCommandRef = useRefWrap(async (): Promise<void> => {
    if (!command || disabled) return
    try {
      setDisabled(true)
      const { extensionConfiguration } = await services.extension.modifyConfiguration(
        command,
        page.extensionConfiguration
      )
      if (!isMounted()) return
      const oldExtensionIds = Model.ExtensionConfiguration.getExtensionIds(page.extensionConfiguration)
      const newExtensionIds = Model.ExtensionConfiguration.getExtensionIds(extensionConfiguration)
      const addedExtensionIds = newExtensionIds.filter(extensionId => !oldExtensionIds.includes(extensionId))
      const done = addedExtensionIds.length > 0
      if (done) {
        await applyActionStatic.updateModel({
          type: Model.Type.Page,
          id: page.id,
          extensionConfiguration,
        })
      }
      setHistory(current => [
        ...current,
        {
          type: 'command',
          command,
          result: done ? 'Done!' : 'Not supported yet, noted! Try another command.',
        },
      ])
      addExtensionsToBeInitialized(addedExtensionIds, command)
      setCommand('')
    } catch (error) {
      setHistory(current => [
        ...current,
        {
          type: 'command',
          command,
          result: 'Try another command.',
        },
      ])
    } finally {
      setDisabled(false)
    }
  })

  // const submitPromptInputRef = useRefWrap(async (): Promise<void> => {
  //   if (!extendedCommandLinePromptInput || (extendedCommandLinePromptInput.required && !promptInput) || disabled) return
  //   submittedExtendedCommandLinePromptInputRef.current = extendedCommandLinePromptInput
  //   extendedCommandLinePromptInput.onSubmit?.(promptInput)
  // })

  const { suggestedTips } = useSuggestedTips()

  const combineInputRef = useCombineRefs(inputRef)

  const theme = useTheme()

  const classes = useStyles()

  return (
    <Layout
      title={
        <div className={classes.header}>
          <TypographyNoWrap>{`${asDefaultSideBar ? '\u00A0\u00A0' : ''}Command Line`}</TypographyNoWrap>
          <Tooltip title="Quick Actions">
            <span>
              <IconButton disabled={disabled} onClick={() => setSideBarMode(Mode.QuickActions)}>
                <CustomIcon name="Action" size={2.5} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Module Store">
            <span>
              <IconButton disabled={disabled} onClick={() => setSideBarMode(Mode.ExtensionStore)}>
                <CustomIcon name="Extension" size={2.5} />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      }
      bodyClassName={classes.root}
    >
      {history.map((item, index) => {
        switch (item.type) {
          case 'command':
            return (
              <div key={index} className={classes.itemContainer}>
                <Typography variant="body1" color="primary" className={classes.contentWithIcon}>
                  <CustomIcon name="ChevronRight" size="small" color="primary" />
                  {item.command}
                </Typography>

                <Typography variant="body1" color="secondary" className={classes.contentWithIcon}>
                  <CustomIcon name="Check" size="small" color="secondary" />
                  {item.result}
                </Typography>

                {item.undoable && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.undoButton}
                    disabled={disabled}
                  >
                    Undo
                  </Button>
                )}
              </div>
            )

          case 'input':
            return (
              <div key={index} className={classes.itemContainer}>
                <Typography variant="body1" color="secondary" className={classes.contentWithIcon}>
                  <CustomIcon name="CaretRight" size="small" color="secondary" />
                  {item.prompt}
                </Typography>

                <Typography variant="body1" color="primary" className={classes.contentWithIcon}>
                  <CustomIcon name="ChevronRight" size="small" color="primary" />
                  {item.input}
                </Typography>
              </div>
            )
        }
      })}

      {history.length > 0 && (
        <div>
          <Gap grow />
          <Button variant="text" size="small" disabled={disabled} onClick={() => setHistory([])}>
            Clear History
          </Button>
        </div>
      )}

      {
        /* !extendedCommandLinePromptInput && */ true ? (
          <div
            className={classNames(classes.itemContainer, disabled ? classes.inputDisabled : classes.inputEnabled)}
            tabIndex={-1}
            onFocus={() => inputRef.current?.focus()}
          >
            <Navigable selectable focusable>
              {({ connectNavigable, connectFocusable }) => (
                <div className={classes.contentWithIcon}>
                  <CustomIcon
                    name="ChevronRight"
                    size="small"
                    color={disabled ? 'text.disabled' : command ? 'text.primary' : 'text.hint'}
                  />
                  <Input
                    ref={connectNavigable}
                    inputRef={combineInputRef(connectFocusable)}
                    fullWidth
                    disableUnderline
                    multiline
                    minRows={4}
                    className={classes.input}
                    placeholder="Describe the app you need"
                    disabled={disabled}
                    value={command}
                    onChange={event => setCommand(event.target.value)}
                    onKeyDown={async event => {
                      const code = event.nativeEvent.code as Keyboard.NativeEventCode
                      switch (code) {
                        case 'Enter':
                        case 'NumpadEnter':
                          await submitCommandRef.current()
                          break
                      }
                    }}
                  />
                </div>
              )}
            </Navigable>

            {disabled && (
              <div className={classes.circularProgressWrapper}>
                <CircularProgress size={theme.spacing(3)} />
              </div>
            )}
          </div>
        ) : (
          <div className={classes.itemContainer} tabIndex={-1} onFocus={() => inputRef.current?.focus()}>
            {/* <Typography variant="body1" color="secondary" className={classes.contentWithIcon}>
            <CustomIcon name="CaretRight" size="small" color="secondary" />
            {extendedCommandLinePromptInput.prompt}
          </Typography>

          <Navigable selectable focusable>
            {({ connectNavigable, connectFocusable }) => (
              <div className={classes.contentWithIcon}>
                <CustomIcon
                  name="ChevronRight"
                  size="small"
                  color={disabled ? 'text.disabled' : command ? 'text.primary' : 'text.hint'}
                />
                <Input
                  ref={connectNavigable}
                  inputRef={combineInputRef(connectFocusable)}
                  fullWidth
                  disableUnderline
                  className={classes.input}
                  placeholder={extendedCommandLinePromptInput.placeholder}
                  disabled={disabled}
                  value={promptInput}
                  onChange={event => setPromptInput(event.target.value)}
                  onKeyDown={async event => {
                    const code = event.nativeEvent.code as Keyboard.NativeEventCode
                    switch (code) {
                      case 'Enter':
                      case 'NumpadEnter':
                        await submitPromptInputRef.current()
                        break

                      case 'Escape':
                        extendedCommandLinePromptInput.onCancel?.()
                        break
                    }
                  }}
                />
              </div>
            )}
          </Navigable>

          {disabled && (
            <div className={classes.circularProgressWrapper}>
              <CircularProgress size={theme.spacing(3)} />
            </div>
          )} */}
          </div>
        )
      }

      {suggestedTips.length > 0 && (
        <div className={classes.itemContainer}>
          <Typography variant="body2" color="textSecondary">
            Try:
          </Typography>
          {suggestedTips.map((tip, index) => (
            <Typography
              key={index}
              variant="body1"
              color="textSecondary"
              className={classNames(classes.contentWithIcon, !disabled && classes.clickableTip)}
              onClick={() => {
                if (disabled) return
                setCommand(tip.command)
                setTimeout(() => submitCommandRef.current()) // Making sure the command is set by the line above
              }}
            >
              <CustomIcon name="ChevronRight" size="small" color="text.secondary" />
              {tip.command}
            </Typography>
          ))}
        </div>
      )}

      <div ref={bottomLineRef} />
    </Layout>
  )
}

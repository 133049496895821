import { Id } from '@zettelooo/commons'
import { DemoMode } from '../../demo-mode'
import { generateLocalStorageBasedId } from '../../generators'
import { PeekMode } from '../../peek-mode'
import { Broadcaster } from './Broadcaster'

export class BroadcasterForClient extends Broadcaster {
  private broadcastedIds!: Set<Id>
  private handleServiceWorkerMessage!: (event: MessageEvent) => void

  constructor(serviceWorkerMessageType: string) {
    super(serviceWorkerMessageType)

    if (DemoMode.data || PeekMode.data) return

    this.broadcastedIds = new Set()

    this.handleServiceWorkerMessage = event => {
      this.handleBroadcasterEvent(event, (channel, message, id) => {
        if (id && this.broadcastedIds.has(id)) {
          this.broadcastedIds.delete(id)
          return
        }
        this.fireListeners(channel, message)
      })
    }

    window.navigator.serviceWorker.addEventListener('message', this.handleServiceWorkerMessage)
  }

  dispose(): void {
    if (DemoMode.data || PeekMode.data) return

    window.navigator.serviceWorker.removeEventListener('message', this.handleServiceWorkerMessage)
  }

  postMessage<M = any>(channel: string, message: M): void {
    if (DemoMode.data || PeekMode.data) return

    const id = generateLocalStorageBasedId()
    this.broadcastedIds.add(id)
    window.navigator.serviceWorker.controller?.postMessage(this.composeBroadcasterMessage(channel, message, id))
  }
}

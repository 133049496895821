import { extendThemeOptions } from '../extendThemeOptions'
import { darkThemeOptions } from './darkThemeOptions'

export const darkLtrThemeOptions = extendThemeOptions(darkThemeOptions, {
  direction: 'ltr',
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          direction: 'ltr',
        },
      },
    },
  },
})

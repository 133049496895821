import { createStore, update } from 'idb-keyval'

// TODO: Replace it with a more global store to be used for all the key values:
const store = createStore('Super Window Client - Database', 'Super Window Client - Collection')

export function getNextIncrementalId(callback: (nextIncrementalId: bigint) => void): Promise<void> {
  return update(
    'incrementalId',
    incrementalIdString => {
      const incrementalId = incrementalIdString ? BigInt(incrementalIdString) : BigInt(0)
      const nextIncrementalId = incrementalId + BigInt(1)
      callback(nextIncrementalId)
      const nextIncrementalIdString = String(nextIncrementalId)
      return nextIncrementalIdString
    },
    store
  )
}

import { PartialRecord, Id, HandlerOutput } from '@zettelooo/commons'
import { generateRuntimeId } from '../../generators'
import { BroadcasterMessageEventListener, BroadcasterServiceWorkerMessage } from './types'

export abstract class Broadcaster {
  private messageEventListeners: PartialRecord<string, Record<Id, BroadcasterMessageEventListener>> = {}

  constructor(private readonly serviceWorkerMessageType: string) {}

  protected composeBroadcasterMessage(channel: string, message: any, id?: Id): BroadcasterServiceWorkerMessage {
    return {
      type: this.serviceWorkerMessageType,
      channel,
      message,
      id,
    }
  }
  protected handleBroadcasterEvent(
    event: { readonly data: any },
    callback: (channel: string, message: any, id?: Id) => void
  ): HandlerOutput {
    if (event.data?.type !== this.serviceWorkerMessageType) return 'not handled'
    const broadcasterMessage: BroadcasterServiceWorkerMessage = event.data
    callback(broadcasterMessage.channel, broadcasterMessage.message, broadcasterMessage.id)
  }

  addMessageEventListener<M = any>(channel: string, messageEventListener: BroadcasterMessageEventListener<M>): Id {
    const subscriptionId = generateRuntimeId()
    this.messageEventListeners[channel] = this.messageEventListeners[channel] ?? {}
    this.messageEventListeners[channel]![subscriptionId] = messageEventListener
    return subscriptionId
  }
  removeMessageEventListener(channel: string, subscriptionId: Id): void {
    if (this.messageEventListeners[channel]) {
      delete this.messageEventListeners[channel]![subscriptionId]
    }
  }

  protected fireListeners(channel: string, message: any): void {
    Object.values(this.messageEventListeners[channel] ?? {}).forEach(eventListener => eventListener(message))
  }

  abstract postMessage<M = any>(channel: string, message: M): void
}

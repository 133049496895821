import { makeStyles, Portal } from '@material-ui/core'
import { WindowType } from '@zettelooo/desktop-shared'
import classNames from 'classnames'
import { forwardRef, useImperativeHandle } from 'react'
import { useInterval, useUpdate } from 'react-use'
import { useContexts } from '../../contexts'
import { webConfig } from '../../web-config'
import { getNavigableStatus } from '../helpers/getNavigableStatus'
import { NavigableStatus } from '../types'
import { NavigationAreaContexts } from './NavigationArea'
import { NavigationContexts } from './NavigationProvider'

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'fixed',
      userSelect: 'none',
      pointerEvents: 'none',
      zIndex: theme.zIndex.tooltip + 1,
    },
    [NavigableStatus.NotNavigated]: {
      outline: 0,
      border: 0,
    },
    [NavigableStatus.NavigatedThrough]: {
      border: `${theme.spacing(0.5)}px solid ${theme.palette.secondary.light}`,
      borderRadius: theme.spacing(0.5),
      opacity: 0.25,
    },
    [NavigableStatus.NavigatedTo]: {
      border: `${theme.spacing(0.625)}px solid ${theme.palette.secondary.main}`,
      borderRadius: theme.spacing(0.625),
      opacity: 0.6,
    },
    [NavigableStatus.Selected]: {
      border: `${theme.spacing(0.75)}px solid ${theme.palette.secondary.dark}`,
      borderRadius: theme.spacing(0.75),
      opacity: 0.8,
    },
  }),
  { name: 'PathHighlight' }
)

export const PathHighlight = forwardRef<{
  redraw(): void
}>(function PathHighlight(_, ref) {
  const {
    refs: { pathHighlightContainerRef },
    isKeyboardNavigationActive,
    isNavigationFreezed,
  } = useContexts(NavigationContexts)
  const {
    refs: { navigatorTree },
    isAreaActive,
    navigationState,
  } = useContexts(NavigationAreaContexts)

  const visible =
    isKeyboardNavigationActive &&
    !isNavigationFreezed &&
    isAreaActive &&
    navigationState.navigatedPath.length > 0 &&
    window.electronProxy?.windowType !== WindowType.Launcher // TODO: Temporarily hide keyboard first navigation path highlight for the Launcher window, it's not yet implemented in there

  const update = useUpdate()

  useInterval(update, visible ? webConfig.timings.navigationPathHighlightRedrawingInterval : null)

  useImperativeHandle(
    ref,
    () => ({
      redraw() {
        update()
      },
    }),
    [update]
  )

  const classes = useStyles()

  if (!visible) return null

  return (
    <Portal container={pathHighlightContainerRef.current}>
      {navigatorTree.getHighlightingPath(navigationState).map(navigableId => (
        <div
          key={navigableId}
          className={classNames(classes.root, classes[getNavigableStatus(navigableId, navigationState)])}
          style={navigatorTree.getElementPosition(navigableId)}
        />
      ))}
    </Portal>
  )
})

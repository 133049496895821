import { IpcChannel, DesktopSettingsIpcMessageType } from '@zettelooo/desktop-shared'
import { useContext } from 'react'
import { webConfig } from '../../../../../../../../../../../../modules/web-config'
import { invokeIpcMessage } from '../../../../../../../../../../modules/electron'
import { DesktopSyncProvider } from '../../../../../../../../../DesktopSyncProvider'
import { ButtonField } from '../ButtonField'
import { FieldContainer } from '../FieldContainer'
import { Separator } from '../Separator'
import { ShortcutField } from '../ShortcutField'

export function DesktopSubPanel() {
  const { electronBased, updateElectronBasedStatic } = useContext(DesktopSyncProvider.Context)

  return (
    <>
      <FieldContainer
        label="Launcher hotkey:"
        description="Edit this field and type the hotkey you would like to use to open the launcher."
      >
        <ShortcutField
          currentShortcut={electronBased.launcherShortcut ?? undefined}
          onChange={launcherShortcut => updateElectronBasedStatic({ launcherShortcut })}
        />
      </FieldContainer>
      <FieldContainer
        label="Capture hotkey:"
        description="Edit this field and type the hotkey you would like to use to capture data from anywhere."
      >
        <ShortcutField
          currentShortcut={electronBased.captureShortcut ?? undefined}
          onChange={captureShortcut => updateElectronBasedStatic({ captureShortcut })}
        />
      </FieldContainer>

      <FieldContainer
        label={`${webConfig.app.name} hotkey:`}
        description={`Edit this field and type the hotkey you would like to use to show ${webConfig.app.name} app.`}
      >
        <ShortcutField
          currentShortcut={electronBased.mainShortcut ?? undefined}
          onChange={mainShortcut => updateElectronBasedStatic({ mainShortcut })}
        />
      </FieldContainer>

      {webConfig.os === 'macOS' && (
        <>
          <Separator />

          <FieldContainer
            label="Permissions"
            labelVariant="subtitle1"
            description={`${webConfig.app.name} requires certain permissions to enable it to automate your Mac.`}
          />

          <FieldContainer
            label="Accessibility:"
            description={`${webConfig.app.name} requires accessibility access on your mac if you'd like to use capture data and paste data, or to simulate key events(from clipboard integration and system commands)`}
          >
            <ButtonField
              onClick={() =>
                invokeIpcMessage(IpcChannel.DesktopSettings, {
                  type: DesktopSettingsIpcMessageType.BrowserGotoAccessibility,
                })
              }
            >
              Go to Accessibility settings
            </ButtonField>
          </FieldContainer>

          <FieldContainer
            label="Automation:"
            description={`For running Applescript and system Events ${webConfig.app.name} needs access to "Automation" permissions. This allows us to capture data from the correct application and also return to it upon closing the ${webConfig.app.name}.`}
          >
            <ButtonField
              onClick={() =>
                invokeIpcMessage(IpcChannel.DesktopSettings, {
                  type: DesktopSettingsIpcMessageType.BrowserGotoAutomation,
                })
              }
            >
              Go to Automation settings
            </ButtonField>
          </FieldContainer>
        </>
      )}
    </>
  )
}

import { Id, PartialRecord } from '@zettelooo/commons'
import Dexie from 'dexie'
import { DemoMode } from '../../../../../modules/demo-mode'
import { PeekMode } from '../../../../../modules/peek-mode'
import { Broadcaster } from '../../../../../modules/service-worker'
import { PersistentType, PersistentKey, PersistentEngineDatabase } from '../../persistent'
import { BroadcasterChannel } from '../../service-worker'
import { Database } from '../Database'
import { DatabaseTable } from '../DatabaseTable'

export class PersistentDatabase implements Database, PersistentEngineDatabase {
  private readonly dexie!: Dexie

  private static readonly recordId: Id = '0'

  private readonly persistent: DatabaseTable<{
    readonly id: Id
    readonly data: Partial<PersistentType>
  }>

  private readonly subscriptions: PartialRecord<PersistentKey, PersistentDatabase.Event.Subscription[]> = {}

  private setterPromise?: Promise<void>

  constructor(private readonly broadcaster: Broadcaster) {
    if (DemoMode.data || PeekMode.data) {
      this.persistent = DatabaseTable.createOnMemory('id')
      return
    }

    this.dexie = new Dexie('PersistentDatabase')

    this.dexie
      .version(39)
      .stores({ persistent: 'id' })
      .upgrade(transaction => {
        transaction
          .table('persistent')
          .toCollection()
          .modify(persistent => {
            persistent.data.IS_CARD_DIALOG_FULL_SCREEN = false
          })
      })

    this.dexie
      .version(38)
      .stores({ persistent: 'id' })
      .upgrade(transaction => {
        // This means we may safely remove the older version migrations:
        return Promise.all(transaction.db.tables.map(table => table.clear()))
      })

    this.dexie
      .version(35)
      .stores({ persistent: 'id' })
      .upgrade(transaction => {
        transaction
          .table('persistent')
          .toCollection()
          .modify(persistent => {
            persistent.data.SHOW_CHROME_EXTENSION_PROMOTION_BANNER = true
          })
      })

    this.dexie
      .version(34)
      .stores({ persistent: 'id' })
      .upgrade(transaction => {
        transaction
          .table('persistent')
          .toCollection()
          .modify(persistent => {
            persistent.data.CARD_EDITOR_DRAFT = undefined
          })
      })

    this.dexie
      .version(23)
      .stores({ persistent: 'id' })
      .upgrade(transaction => {
        transaction
          .table('persistent')
          .toCollection()
          .modify(persistent => {
            persistent.data.MINI_AUDIO_PLAYER_CONFIG = {
              volume: 100,
              repeat: 'DISABLED',
            }
          })
      })

    this.dexie
      .version(22)
      .stores({ persistent: 'id' })
      .upgrade(transaction =>
        transaction
          .table('persistent')
          .toCollection()
          .modify(persistent => {
            persistent.data.LAST_DISPLAYED_CHANGE_LOG = {
              onVersion: persistent.data.LAST_DISPLAYED_CHANGE_LOG_VERSION ?? '',
            }
            delete persistent.data.LAST_DISPLAYED_CHANGE_LOG_VERSION
            delete persistent.data.WELCOME_SLIDES_ARE_SHOWN
          })
      )

    this.dexie
      .version(21)
      .stores({ persistent: 'id' })
      .upgrade(transaction => {
        // This means we may safely remove the older version migrations:
        return Promise.all(transaction.db.tables.map(table => table.clear()))
      })

    this.persistent = DatabaseTable.createOnDexie(this.dexie, 'persistent')

    this.dexie.open()

    broadcaster.addMessageEventListener<PersistentDatabase.BroadcasterMessage>(
      BroadcasterChannel.PersistentDatabase,
      message => this.fire(message.event)
    )
  }

  dispose(): void {
    if (DemoMode.data || PeekMode.data) return

    this.dexie.close()
  }

  async clearAll(): Promise<void> {
    if (DemoMode.data || PeekMode.data) {
      this.persistent.memory.deleteAll()
      return
    }

    await Promise.all(this.dexie.tables.map(table => table.clear()))
  }

  async getAll(): Promise<Partial<PersistentType>> {
    if (DemoMode.data || PeekMode.data) return this.persistent.memory.get(PersistentDatabase.recordId)?.data ?? {}

    const record = await this.persistent.dexie.get(PersistentDatabase.recordId)
    const persistent = record?.data ?? {}
    return persistent
  }

  async set<K extends PersistentKey>(key: K, newValue: PersistentType[K]): Promise<void> {
    // We fire the event synchronously. Because of the following reasons:
    // First, the subscribers will have the new value through the event. So, they probably won't require the database to be updated before.
    // Second, the major subscriber, persistent, is providing a synchronous API. Therefore, it's been expected to reflect the updates synchronously too.
    this.fire({ updates: { [key]: newValue } }, true)

    if (DemoMode.data || PeekMode.data) {
      const persistent = this.persistent.memory.get(PersistentDatabase.recordId)?.data ?? {}
      persistent[key] = newValue
      this.persistent.memory.put({
        id: PersistentDatabase.recordId,
        data: persistent,
      })
      return
    }

    this.setterPromise = Promise.resolve(this.setterPromise).then(async () => {
      const record = await this.persistent.dexie.get(PersistentDatabase.recordId)
      const persistent = record?.data ?? {}
      persistent[key] = newValue
      await this.persistent.dexie.put({
        id: PersistentDatabase.recordId,
        data: persistent,
      })
    })
    await this.setterPromise
  }

  subscribe<K extends PersistentKey>(key: K, handler: PersistentDatabase.Event.Handler<K>): void {
    this.subscriptions[key] = this.subscriptions[key] ?? []
    this.subscriptions[key]!.push(handler as PersistentDatabase.Event.Subscription)
  }

  unsubscribe<K extends PersistentKey>(key: K, handler: PersistentDatabase.Event.Handler<K>): void {
    const index = this.subscriptions[key]?.indexOf(handler as PersistentDatabase.Event.Subscription) ?? -2
    if (index >= 0) {
      this.subscriptions[key]!.splice(index, 1)
    }
  }

  private fire(event: PersistentDatabase.Event, propagateEventThroughServiceWorker?: boolean): void {
    const involvedKeys = Object.keys(event.updates) as readonly PersistentKey[]

    involvedKeys.forEach(key => {
      const subscriptions = [...(this.subscriptions[key] ?? [])] // It's required, because some subscription execution will change this.subscription directly because of setStates in the middle of the process
      subscriptions.forEach(subscription => subscription(event.updates[key]))
    })

    if (propagateEventThroughServiceWorker) {
      this.broadcaster.postMessage<PersistentDatabase.BroadcasterMessage>(BroadcasterChannel.PersistentDatabase, {
        event,
      })
    }
  }
}

export namespace PersistentDatabase {
  export interface Event {
    readonly updates: Readonly<Partial<PersistentType>>
  }

  export namespace Event {
    export type Handler<K extends PersistentKey> = (newValue: PersistentType[K]) => void

    export type Subscription = Handler<PersistentKey>
  }

  export interface BroadcasterMessage {
    readonly event: Event
  }
}

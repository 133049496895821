import { Avatar, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import Color from 'color'
import { CustomIcon } from '../../../../../../../../modules/custom-icon'
import { CustomAvatar } from '../../../../../../../CustomAvatar'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(0, 2),
      marginLeft: 0,
      borderRadius: theme.spacing(3),
      paddingRight: theme.spacing(2),
      cursor: 'pointer',
      '&:not(:hover)': {
        backgroundColor: theme.palette.action.selected,
        '& $closeAvatar': {
          display: 'none',
        },
      },
      '&:hover': {
        backgroundColor: new Color(theme.palette.error.main).alpha(0.2).string(),
        '& $avatar': {
          display: 'none',
        },
      },
    },
    name: {
      maxWidth: theme.spacing(20),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    avatar: {},
    closeAvatar: {
      marginRight: theme.spacing(1),
      width: theme.spacing(5.25),
      height: theme.spacing(5.25),
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
    },
  }),
  { name: 'FilteredPage' }
)

export function FilteredPage({ page, onRemoveFilter }: { page: Model.Page; onRemoveFilter(): void }) {
  const theme = useTheme()

  const classes = useStyles()

  return (
    <div key={page.id} className={classes.root} onClick={() => onRemoveFilter()}>
      <Tooltip title={page.name}>
        <>
          <CustomAvatar
            size={5.25}
            avatarFileId={page.avatarFileId}
            name={page.name}
            emoji={page.iconEmoji}
            color={page.color || theme.palette.background.paper}
            margin={[0, 1, 0, 0]}
            className={classes.avatar}
          />

          <Avatar className={classes.closeAvatar}>
            <CustomIcon name="Close" size="large" />
          </Avatar>

          <Typography variant="subtitle2" color="textSecondary" className={classes.name}>
            {page.name}
          </Typography>
        </>
      </Tooltip>
    </div>
  )
}

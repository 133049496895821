import { useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { useContext, useState } from 'react'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { useBatchCommandHandling } from '../../../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { DeviceProvider } from '../../../../../../../../../modules/device'
import { webConfig } from '../../../../../../../../../modules/web-config'
import { TitleBarAppNameItem } from '../../../../../../TitleBarAppNameItem'
import { TitleBarLayout } from '../../../../TitleBarLayout'
import { MainPage } from '../../MainPage'
import { InviteUsersDialog } from '../InviteUsersDialog'
import { AccountItem } from './AccountItem'
import { InviteUsersItem } from './InviteUsersItem'
import { NavigationItem } from './NavigationItem'
import { SearchItem } from './SearchItem'
import { SettingsItem } from './SettingsItem'
import { SideBarMenuItem } from './SideBarMenuItem'
import { SideBarToggleItem } from './SideBarToggleItem'
import { SwitchToLauncherItem } from './SwitchToLauncherItem'
import { SyncAndOfflineItem } from './SyncAndOfflineItem'
import { ThemeSwitchItem } from './ThemeSwitchItem'
import { UpdateItem } from './UpdateItem'

export function TitleBar({
  // items,
  className,
}: {
  // items: readonly ZettelExtensions.LifeSpan.Shared.MainView.TitleBarItem[]
  className?: string
}) {
  const { screenWidth, isMobile } = useContext(DeviceProvider.Context)
  const { isSideBarMenuOpen, overridingSmartCommandApi } = useContexts(MainPage.Contexts)

  const [openInviteUsersDialog, setOpenInviteUsersDialog] = useState(false)

  useBatchCommandHandling(
    {
      'navigation.showInviteUsersDialog'() {
        setOpenInviteUsersDialog(currentOpenInviteUsersDialog => !currentOpenInviteUsersDialog)
      },
    },
    []
  )

  const theme = useTheme()

  const { commonClasses } = useCommonStyles()

  const isDesktop = webConfig.environment.agent === 'electron'

  const isSideBarHidden =
    isMobile ||
    (webConfig.temporary.temporarilyDisabledOrToBeRemoved &&
      screenWidth <= theme.constants.sidebar.resizableSideBarMinimumScreenWidth)

  return (
    <>
      <TitleBarLayout className={className}>
        {!webConfig.temporary.temporarilyDisabledOrToBeRemoved && isDesktop && <SwitchToLauncherItem />}

        {isSideBarHidden && <SideBarMenuItem />}

        <TitleBarAppNameItem inline />

        {!webConfig.temporary.temporarilyDisabledOrToBeRemoved && !isMobile && <SideBarToggleItem />}

        {!webConfig.temporary.temporarilyDisabledOrToBeRemoved && (
          <NavigationItem className={classNames(isSideBarMenuOpen && commonClasses.hidden)} />
        )}

        <div className={commonClasses.grow} />

        {/* TODO: Temporarily disabled to be decided later:
        <SmartCommand.CommandLine
          api={overridingSmartCommandApi}
          variant="outlined"
          size="small"
          InputProps={{ disableUnderline: true }}
        />
        */}

        <SyncAndOfflineItem className={classNames(isSideBarMenuOpen && commonClasses.hidden)} />

        <UpdateItem className={classNames(isSideBarMenuOpen && commonClasses.hidden)} />

        {/* TODO: Temporarily disabled to be decided later:
        {items.map((item, index) => (
          <ExtraItem key={index} item={item} />
        ))}
        */}

        {!webConfig.temporary.temporarilyDisabledOrToBeRemoved && !webConfig.temporary.noInviteUser && (
          <InviteUsersItem
            className={classNames(isSideBarMenuOpen && commonClasses.hidden)}
            onClick={() => setOpenInviteUsersDialog(true)}
          />
        )}

        <SearchItem />

        <ThemeSwitchItem />

        {!webConfig.temporary.temporarilyDisabledOrToBeRemoved && isMobile && (
          <SettingsItem className={classNames(!isSideBarMenuOpen && commonClasses.hidden)} />
        )}

        <AccountItem />
      </TitleBarLayout>

      {openInviteUsersDialog && <InviteUsersDialog onClose={() => setOpenInviteUsersDialog(false)} />}
    </>
  )
}

import { makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useCommonStyles } from '../../../hooks/useCommonStyles'
import { sendAnalyticEvent } from '../../../modules/analytics'
import { webConfig } from '../../../modules/web-config'
import { Anchor } from './Anchor'

const useStyles = makeStyles(
  theme => ({
    root: {
      flex: 'none',
      '&:not($inline)': {
        position: 'fixed',
        top: theme.spacing(2.5),
        left: theme.spacing(5),
      },
    },
    inline: {},
  }),
  { name: 'TitleBarAppNameItem' }
)

export function TitleBarAppNameItem({ inline, link }: { inline?: boolean; link?: boolean }) {
  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <Typography
      variant="h6"
      color="textPrimary"
      className={classNames(commonClasses.electronDrag, classes.root, inline && classes.inline)}
    >
      {link ? (
        <Anchor
          href={webConfig.urls.landingPage}
          target="_blank"
          onClick={() => sendAnalyticEvent('Title Bar', 'Click on Landing page link')}
        >
          {webConfig.app.name}.
        </Anchor>
      ) : (
        <>{webConfig.app.name}.</>
      )}
    </Typography>
  )
}

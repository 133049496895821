import { Id, PartialRecord } from '@zettelooo/commons'
import { CSSProperties } from 'react'
import { arrayHelpers } from '../../helpers/native/arrayHelpers'
import { generateRuntimeId } from '../generators'
import { calculateDistance } from './helpers/calculateDistance'
import { getNavigableStatus } from './helpers/getNavigableStatus'
import { NavigablePaddings, NavigablePath, NavigableStatus, NavigationDirection, NavigationState } from './types'

export class NavigatorTree {
  readonly rootNavigableId: Id
  private readonly navigables: PartialRecord<
    Id,
    {
      type: 'navigable' | 'group'
      element: HTMLElement | null
      paddings: NavigablePaddings
      selectable: boolean
      lastNavigatedChildId: Id | undefined
      readonly children: Set<Id>
    }
  >
  private readonly resizeObserver: ResizeObserver

  constructor(
    private handlers: {
      onRegister?(navigablePath: NavigablePath): void
      onUnregister?(navigablePath: NavigablePath): void
      onUpdateElement?(navigableId: Id): void
      onResizeElements?(): void
    }
  ) {
    this.rootNavigableId = generateRuntimeId()

    this.navigables = {
      [this.rootNavigableId]: {
        type: 'navigable',
        element: window.document.body,
        selectable: false,
        paddings: { top: 0, right: 0, bottom: 0, left: 0 },
        lastNavigatedChildId: undefined,
        children: new Set(),
      },
    }

    this.resizeObserver = new ResizeObserver(entries => {
      this.handlers.onResizeElements?.()
    })
  }

  dispose(): void {
    this.resizeObserver.disconnect()
  }

  register(
    navigablePath: NavigablePath,
    navigable:
      | {
          type: 'group'
          element: HTMLElement | null
          paddings: NavigablePaddings
        }
      | {
          type: 'navigable'
          element: HTMLElement | null
          paddings: NavigablePaddings
          selectable: boolean
        }
  ): void {
    const navigableId = navigablePath[navigablePath.length - 1]
    const parentNavigableId = navigablePath[navigablePath.length - 2]

    if (!(parentNavigableId in this.navigables)) {
      this.navigables[parentNavigableId] = {
        type: 'group',
        element: null,
        paddings: { top: 0, right: 0, bottom: 0, left: 0 },
        selectable: false,
        lastNavigatedChildId: undefined,
        children: new Set(),
      }
    }
    this.navigables[parentNavigableId]!.children.add(navigableId)

    if (!(navigableId in this.navigables)) {
      this.navigables[navigableId] =
        navigable.type === 'group'
          ? {
              ...navigable,
              selectable: false,
              lastNavigatedChildId: undefined,
              children: new Set(),
            }
          : {
              ...navigable,
              lastNavigatedChildId: undefined,
              children: new Set(),
            }
    } else {
      this.navigables[navigableId] = {
        ...this.navigables[navigableId]!,
        ...navigable,
      }
    }
    this.handlers.onRegister?.(navigablePath)
  }
  unregister(navigablePath: NavigablePath): void {
    const navigableId = navigablePath[navigablePath.length - 1]
    if (!(navigableId in this.navigables)) return

    delete this.navigables[navigableId]

    const navigableParentId = navigablePath[navigablePath.length - 2]
    if (navigableParentId in this.navigables) {
      this.navigables[navigableParentId]!.children.delete(navigableId)
    }

    this.handlers.onUnregister?.(navigablePath)
  }
  update(
    navigableId: Id,
    {
      element,
      paddings,
      selectable,
    }: {
      element: HTMLElement | null
      paddings: NavigablePaddings
      selectable?: boolean
    }
  ): void {
    if (!(navigableId in this.navigables)) return

    const oldElement = this.navigables[navigableId]!.element
    if (oldElement === element) return

    if (oldElement) {
      this.resizeObserver.unobserve(oldElement)
    }
    if (element) {
      this.resizeObserver.observe(element)
    }

    this.navigables[navigableId]!.element = element
    this.navigables[navigableId]!.paddings = paddings
    this.navigables[navigableId]!.selectable = selectable ?? this.navigables[navigableId]!.selectable

    this.handlers.onUpdateElement?.(navigableId)
  }

  getInitialNavigationState(): NavigationState {
    return {
      navigatedPath: [this.rootNavigableId, ...(this.getChildNavigableRelativePath(this.rootNavigableId) || [])],
      selected: false,
    }
  }
  getChildNavigableRelativePath(navigableId: Id): NavigablePath | void {
    if (!(navigableId in this.navigables) || this.navigables[navigableId]?.selectable) return

    const childNavigableIds = this.navigables[navigableId]?.children.values()
    if (!childNavigableIds) return

    const tryToNavigateChildId = (childNavigableId: Id): NavigablePath | void => {
      if (this.navigables[childNavigableId]?.type === 'navigable') return [childNavigableId]

      if (this.navigables[childNavigableId]?.type === 'group') {
        const extraPath = this.getChildNavigableRelativePath(childNavigableId)
        if (extraPath) return [childNavigableId, ...extraPath]
      }
    }

    const preferredChildNavigableId = this.navigables[navigableId]?.lastNavigatedChildId
    if (preferredChildNavigableId) {
      const childNavigableRelativePath = tryToNavigateChildId(preferredChildNavigableId)
      if (childNavigableRelativePath) return childNavigableRelativePath
    }

    while (true) {
      const { value: childNavigableId, done } = childNavigableIds.next()
      if (done) return

      if (childNavigableId !== preferredChildNavigableId) {
        const childNavigableRelativePath = tryToNavigateChildId(childNavigableId)
        if (childNavigableRelativePath) return childNavigableRelativePath
      }
    }
  }
  getParentNavigablePath(navigablePath: NavigablePath): NavigablePath {
    let index = navigablePath.length - 2
    while (this.navigables[navigablePath[index]!]?.type === 'group') {
      index -= 1
    }
    return navigablePath.slice(0, index + 1)
  }
  registerNavigatedPath(navigatedPath: NavigablePath): void {
    navigatedPath.forEach((navigableId, index) => {
      if (index < navigatedPath.length - 1 && navigableId && navigableId in this.navigables) {
        this.navigables[navigableId]!.lastNavigatedChildId = navigatedPath[index + 1]
      }
    })
  }

  findNextNavigablePath(navigablePath: NavigablePath, direction: NavigationDirection): NavigablePath | void {
    const navigableId = arrayHelpers.last(navigablePath)

    const rect = navigableId && this.navigables[navigableId]?.element?.getBoundingClientRect()
    if (!rect) return
    const { paddings } = this.navigables[navigableId!]!

    let nextNavigablePath: NavigablePath | undefined
    let minimumDistance = Number.POSITIVE_INFINITY
    let parentNavigableIndex = navigablePath.length - 1

    do {
      parentNavigableIndex -= 1

      const parentNavigableId = navigablePath[parentNavigableIndex]
      const childNavigableIds = this.navigables[parentNavigableId]?.children.values()

      if (childNavigableIds) {
        while (true) {
          const { value: childNavigableId, done } = childNavigableIds.next()
          if (done) break

          if (!navigablePath.includes(childNavigableId)) {
            const siblingRect = this.navigables[childNavigableId]?.element?.getBoundingClientRect()
            if (siblingRect) {
              const siblingPaddings = this.navigables[childNavigableId]!.paddings
              const distance = calculateDistance(rect, paddings, siblingRect, siblingPaddings, direction)

              if (distance < minimumDistance) {
                if (this.navigables[childNavigableId]?.type === 'navigable') {
                  nextNavigablePath = navigablePath.slice(0, parentNavigableIndex + 1).concat(childNavigableId)
                  minimumDistance = distance
                }

                if (this.navigables[childNavigableId]?.type === 'group') {
                  const deeperChildNavigatedRelativePath = this.getChildNavigableRelativePath(childNavigableId)
                  if (deeperChildNavigatedRelativePath) {
                    nextNavigablePath = [
                      ...navigablePath.slice(0, parentNavigableIndex + 1),
                      childNavigableId,
                      ...deeperChildNavigatedRelativePath,
                    ]
                    minimumDistance = distance
                  }
                }
              }
            }
          }
        }
      }

      if (nextNavigablePath) return nextNavigablePath
    } while (this.navigables[navigablePath[parentNavigableIndex]]?.type === 'group')

    return undefined
  }

  isSelectable(navigableId: Id): boolean {
    return this.navigables[navigableId]?.selectable ?? false
  }
  shouldElementBeFocused(navigableId: Id, navigationState: NavigationState): boolean {
    return (
      getNavigableStatus(navigableId, navigationState) ===
      (this.navigables[navigableId]?.selectable ? NavigableStatus.Selected : NavigableStatus.NavigatedTo)
    )
  }
  getHighlightingPath(navigationState: NavigationState): Id[] {
    // We don't want the root (i.e.: body) to get any highlights:
    return navigationState.navigatedPath.filter(
      navigableId =>
        navigableId && navigableId !== this.rootNavigableId && this.navigables[navigableId]?.type === 'navigable'
    )
  }
  getElementPosition(navigableId: Id): CSSProperties {
    const rect = this.navigables[navigableId]?.element?.getBoundingClientRect()
    const paddings = this.navigables[navigableId]?.paddings!

    return !rect
      ? {
          display: 'none',
        }
      : {
          top: Math.round(rect.top - paddings.top),
          left: Math.round(rect.left - paddings.left),
          width: Math.round(rect.right - rect.left + paddings.right + paddings.left),
          height: Math.round(rect.bottom - rect.top + paddings.top + paddings.bottom),
        }
  }
}

import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useContext, useState } from 'react'
import { arrayHelpers } from '../../../../../../../../../../../../helpers/native/arrayHelpers'
import { useContexts } from '../../../../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../../../../modules/custom-icon'
import { DeviceProvider } from '../../../../../../../../../../../../modules/device'
import { CustomAvatar } from '../../../../../../../../../../../CustomAvatar'
import { useApplyAction } from '../../../../../../../../../../hooks/useApplyAction'
import { useAppNotistack } from '../../../../../../../../../../modules/app-notistack'
import { Initializer } from '../../../../../../../../../Initializer'
import { PagePanel } from '../../PagePanel'

const useStyles = makeStyles(
  theme => ({
    root: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    secondaryAction: {
      paddingRight: theme.spacing(8),
    },
  }),
  { name: 'Member' }
)

export function Member({ memberUserId }: { memberUserId: Id }) {
  const { isMobile } = useContext(DeviceProvider.Context)
  const { allUsers } = useContexts(Initializer.Contexts)
  const { page } = useContexts(PagePanel.Contexts)

  const [disabled, setDisabled] = useState(false)

  const user = allUsers.dictionary[memberUserId]

  const { applyActionStatic } = useApplyAction()

  const { enqueueSnackbar } = useAppNotistack()

  const classes = useStyles()

  const name = user?.name ?? '\u00A0'
  const userName = user?.userName ? `@${user.userName}` : '\u00A0'

  return (
    <Tooltip
      placement="left"
      title={
        isMobile ? (
          ''
        ) : (
          <div>
            <Typography variant="body1" gutterBottom>
              {name}
            </Typography>
            <Typography variant="body2">{userName}</Typography>
          </div>
        )
      }
    >
      <ListItem classes={{ root: classes.root, secondaryAction: classes.secondaryAction }} disabled={disabled}>
        <ListItemAvatar>
          <CustomAvatar size={5} avatarFileId={user?.avatarFileId ?? null} name={user?.name} color={user?.color} />
        </ListItemAvatar>
        <ListItemText
          primary={name}
          primaryTypographyProps={{ noWrap: true }}
          secondary={userName}
          secondaryTypographyProps={{ noWrap: true }}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            disabled={disabled}
            onClick={async () => {
              setDisabled(true)
              await applyActionStatic.updateModel({
                type: Model.Type.Page,
                id: page.id,
                memberUserIds: arrayHelpers.remove(page.memberUserIds, memberUserId),
              })
              setDisabled(false)
              enqueueSnackbar('Member Removed', `${user?.name} has been removed from ${page.name}`, {
                variant: 'success',
              })
            }}
          >
            <CustomIcon name="Delete" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </Tooltip>
  )
}

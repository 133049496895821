import { CircularProgress, IconButton, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core'
import { captureException } from '@sentry/react'
import { useLayoutEffect, useState } from 'react'
import { useCopyToClipboard } from 'react-use'
import { sendAnalyticEvent } from '../../../../../../../../../../../../modules/analytics'
import { CustomIcon } from '../../../../../../../../../../../../modules/custom-icon'
import { Navigable } from '../../../../../../../../../../../../modules/navigation'
import { webConfig } from '../../../../../../../../../../../../modules/web-config'
import { AsyncActionState } from '../../../../../../../../../../../../types/AsyncActionState'
import { TypographyNoWrap } from '../../../../../../../../../../../TypographyNoWrap'
import { useServices } from '../../../../../../../../../../modules/services'
import { Anchor } from '../../../../../../../../../Anchor'
import { ButtonField } from '../ButtonField'
import { FieldPaper } from '../FieldPaper'

const useStyles = makeStyles(
  theme => ({
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    circularProgressWrapper: {
      padding: theme.spacing(2, 0),
      textAlign: 'center',
    },
    copyButton: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(0.5),
    },
    deleteButton: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(0.5),
    },
  }),
  { name: 'ApiSubPanel' }
)

export function ApiSubPanel() {
  const [personalKeys, setPersonalKeys] = useState<readonly string[]>([])
  const [fetchingPersonalKeysState, setFetchingPersonalKeysState] = useState<AsyncActionState>({
    status: AsyncActionState.Status.NotDone,
  })
  const [addingPersonalKeyState, setAddingPersonalKeyState] = useState<AsyncActionState>({
    status: AsyncActionState.Status.DoneOrNotDoing,
  })

  const { services } = useServices()

  useLayoutEffect(() => {
    if (fetchingPersonalKeysState.status === AsyncActionState.Status.NotDone) {
      setFetchingPersonalKeysState({ status: AsyncActionState.Status.Doing })
      services.personalKey.personalKeys().then(
        fetchedPersonalKeys => {
          setPersonalKeys(fetchedPersonalKeys)
          setFetchingPersonalKeysState({ status: AsyncActionState.Status.DoneOrNotDoing })
        },
        error => {
          log.error('user profile', 'api', 'fetch personal keys', error)
          captureException(error, { tags: { module: 'api keys', action: 'get all' } })
          setFetchingPersonalKeysState({ status: AsyncActionState.Status.Failed, error })
        }
      )
    }
  }, [fetchingPersonalKeysState, services])

  useLayoutEffect(() => {
    if (addingPersonalKeyState.status === AsyncActionState.Status.NotDone) {
      setAddingPersonalKeyState({ status: AsyncActionState.Status.Doing })
      services.personalKey.addPersonalKey().then(
        newPersonalKey => {
          setFetchingPersonalKeysState({ status: AsyncActionState.Status.NotDone })
          setAddingPersonalKeyState({ status: AsyncActionState.Status.DoneOrNotDoing })
        },
        error => {
          log.error('user profile', 'api', 'add personal key', error)
          captureException(error, { tags: { module: 'api keys', action: 'add' } })
          setAddingPersonalKeyState({ status: AsyncActionState.Status.Failed, error })
        }
      )
    }
  }, [addingPersonalKeyState, services])

  const [, copyToClipboard] = useCopyToClipboard()

  const theme = useTheme()
  const classes = useStyles()

  return (
    <>
      <FieldPaper>
        <Typography variant="h6" gutterBottom>
          API Keys
        </Typography>
        <Typography variant="body2">
          Create API Keys to access {webConfig.app.name}’s API for other integrations.
        </Typography>
        <br />
        <Anchor href={webConfig.urls.documents} target="_blank">
          API Documentation
          <Typography display="inline" color="textSecondary">
            &nbsp;&nbsp;&gt;
          </Typography>
        </Anchor>
      </FieldPaper>

      {fetchingPersonalKeysState.status === AsyncActionState.Status.NotDone ? (
        <></>
      ) : fetchingPersonalKeysState.status === AsyncActionState.Status.Doing ? (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress size={theme.spacing(4)} />
        </div>
      ) : fetchingPersonalKeysState.status === AsyncActionState.Status.Failed ? (
        <ButtonField onClick={() => setFetchingPersonalKeysState({ status: AsyncActionState.Status.NotDone })}>
          <Typography color="error">Failed to fetch all the keys. Please, try again</Typography>
        </ButtonField>
      ) : (
        <>
          {personalKeys.length === 0 ? (
            <FieldPaper>
              <Typography variant="body1" color="textSecondary">
                Create your first API key
              </Typography>
            </FieldPaper>
          ) : (
            <>
              {personalKeys.map(personalKey => (
                <Navigable key={personalKey} group>
                  {({ connectNavigable }) => (
                    <FieldPaper ref={connectNavigable} flex>
                      <TypographyNoWrap variant="body1">{personalKey}</TypographyNoWrap>
                      <Navigable padding={1}>
                        {({ connectNavigable: connectButton }) => (
                          <Tooltip title="Copy to clipboard">
                            <IconButton
                              ref={connectButton}
                              className={classes.copyButton}
                              onClick={() => copyToClipboard(personalKey)}
                            >
                              <CustomIcon name="Copy" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Navigable>
                      <Navigable padding={1}>
                        {({ connectNavigable: connectButton }) => (
                          <Tooltip title="Delete">
                            <IconButton
                              ref={connectButton}
                              className={classes.deleteButton}
                              onClick={async () => {
                                try {
                                  await services.personalKey.removePersonalKey(personalKey)
                                  setFetchingPersonalKeysState({ status: AsyncActionState.Status.NotDone })
                                } catch (error) {
                                  log.error(error)
                                  captureException(error, { tags: { module: 'api keys', action: 'delete' } })
                                }
                              }}
                            >
                              <CustomIcon name="Delete" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Navigable>
                    </FieldPaper>
                  )}
                </Navigable>
              ))}
            </>
          )}

          <ButtonField
            disabled={addingPersonalKeyState.status === AsyncActionState.Status.Doing}
            onClick={() => {
              sendAnalyticEvent('API', 'Create key')
              setAddingPersonalKeyState({ status: AsyncActionState.Status.NotDone })
            }}
          >
            {personalKeys.length === 0 ? 'Create key' : 'Generate new key'}
          </ButtonField>
        </>
      )}
    </>
  )
}

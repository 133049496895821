import { AutomaticUpdater } from '../../../../../modules/update'
import { Authentication } from './Authentication'
import { Views } from './Views'

export function AdminPage() {
  return (
    <>
      <AutomaticUpdater />

      <Authentication.Provider>
        <Views />
      </Authentication.Provider>
    </>
  )
}

import { PersonalKeyServiceSignature } from '@zettelooo/server-shared'
import { DemoMode } from '../../../../../modules/demo-mode'
import { PeekMode } from '../../../../../modules/peek-mode'
import { Persistent } from '../../persistent'
import { RestService } from './RestService'

export class PersonalKeyService extends RestService {
  constructor(persistent: Persistent) {
    super('personal-key', persistent)
  }

  async addPersonalKey(): Promise<string> {
    if (DemoMode.data || PeekMode.data) return '' // No personal key in demo mode

    const { personalKey } = await this.requestAuthenticated<
      PersonalKeyServiceSignature.AddPersonalKey.Request,
      PersonalKeyServiceSignature.AddPersonalKey.Response
    >('add-personal-key', {})

    return personalKey
  }

  async personalKeys(): Promise<string[]> {
    if (DemoMode.data) return [] // No personal key in demo mode

    const { personalKeys } = await this.requestAuthenticated<
      PersonalKeyServiceSignature.PersonalKeys.Request,
      PersonalKeyServiceSignature.PersonalKeys.Response
    >('personal-keys', {})

    return [...personalKeys]
  }

  async removePersonalKey(personalKey: string): Promise<void> {
    if (DemoMode.data || PeekMode.data) return // No personal key in demo mode

    const {} = await this.requestAuthenticated<
      PersonalKeyServiceSignature.RemovePersonalKey.Request,
      PersonalKeyServiceSignature.RemovePersonalKey.Response
    >('remove-personal-key', { personalKey })
  }
}

import { app } from './app'

export const urls = {
  landingPage: `https://www.${app.domain}/`,
  privacy: 'https://app.zettel.ooo/view/private-policy-05f7b1c1-cfff-51f2-8bfa-41f9559dc028',
  downloads: `https://www.${app.domain}/downloads`,
  changelogs: `https://www.${app.domain}/changelogs`,
  lastChangelogs: `https://www.${app.domain}/last-changelog.html`,
  documents: `https://docs.${app.domain}/`,
  chromeExtension:
    'https://chrome.google.com/webstore/detail/zettel-your-productivity/dkhknpgpnonjofbmilhnagccnngccfae',
  desktopApp: `zettel-desktop://ooo.zettel.app`,
} as const

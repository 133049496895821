import { useTheme } from '@material-ui/core'
import { useContext } from 'react'
import { useCommandGroupRegistration } from '../../../../../../../modules/commands'
import { DeviceProvider } from '../../../../../../../modules/device'
import { webConfig } from '../../../../../../../modules/web-config'
import { usePersistent, PersistentKey } from '../../../../../modules/persistent'

export function useCommands(): void {
  const { persistent } = usePersistent()

  const { isMobile } = useContext(DeviceProvider.Context)

  const theme = useTheme()

  useCommandGroupRegistration(
    () => ({
      name: 'navigation',
      displayName: 'Navigation',
      commands: [
        {
          name: 'signOut',
          displayName: 'Sign out',
          defaultShortcutKeys: { alt: true, shift: true, code: 'KeyQ' },
        },
        {
          name: 'jumpToTop',
          displayName: 'Jump to top',
          defaultShortcutKeys: { cmd: true, code: 'ArrowUp' },
        },
        {
          name: 'jumpToBottom',
          displayName: 'Jump to bottom',
          defaultShortcutKeys: { cmd: true, code: 'ArrowDown' },
        },
      ],
    }),
    [isMobile]
  )

  useCommandGroupRegistration(
    () => ({
      name: 'main',
      displayName: 'General',
      commands: [
        {
          name: 'lightTheme',
          displayName: 'Light theme',
          disabled() {
            return webConfig.temporary.temporarilyDisabledOrToBeRemoved || theme.palette.type === 'light'
          },
          icon: 'LightTheme',
          defaultShortcutKeys: { cmd: true, shift: true, code: 'KeyL' },
          handler() {
            persistent(PersistentKey.ThemeType).set('light')
          },
        },
        {
          name: 'darkTheme',
          displayName: 'Dark theme',
          disabled() {
            return webConfig.temporary.temporarilyDisabledOrToBeRemoved || theme.palette.type === 'dark'
          },
          icon: 'DarkTheme',
          defaultShortcutKeys: { cmd: true, shift: true, code: 'KeyL' },
          handler() {
            persistent(PersistentKey.ThemeType).set('dark')
          },
        },
      ],
    }),
    [persistent, theme]
  )
}

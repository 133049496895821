import { PropsWithChildren, useRef } from 'react'
import { MemoizedContextProvider } from '../../components/MemoizedContextProvider'
import { Context } from './Context'
import { DragData } from './types'

export function DragAndDropProvider({ children }: PropsWithChildren<{}>) {
  const dragDataRef = useRef<DragData | undefined>(undefined)
  const timeoutRef = useRef<any>(null)

  return (
    <MemoizedContextProvider
      context={Context}
      valueFactory={() => ({
        getDragDataStatic() {
          return dragDataRef.current
        },
        setDragDataStatic(dragData) {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
          }
          dragDataRef.current = dragData
          timeoutRef.current = dragData
            ? setTimeout(() => {
                dragDataRef.current = undefined
                timeoutRef.current = null
              }, 1000) // The maximum amount of time which we keep the drag data without any drag event, otherwise we'll assume the drag operation is already aborted
            : null
        },
      })}
      dependencies={[]}
    >
      {children}
    </MemoizedContextProvider>
  )
}

import { Button, Divider, Fab } from '@material-ui/core'
import { Hint } from '../components/Hint'
import { InlineStack } from '../components/InlineStack'

export function ButtonPanel() {
  return (
    <>
      <Hint
        description={
          <>
            Under
            <strong>
              &nbsp;
              <code>override.MuiButton</code>
              &nbsp;
            </strong>
            and
            <strong>
              &nbsp;
              <code>override.MuiFab</code>
              &nbsp;
            </strong>
            properties
          </>
        }
      >
        web/src/App/modules/styling/theme-options/baseThemeOptions.tsx
      </Hint>

      <Divider />

      <InlineStack>
        <Button variant="text">Text Default</Button>
        <Button variant="text" color="primary">
          Text Primary
        </Button>
        <Button variant="text" color="secondary">
          Text Secondary
        </Button>
      </InlineStack>

      <Divider />

      <InlineStack>
        <Button variant="outlined">Outlined Default</Button>
        <Button variant="outlined" color="primary">
          Outlined Primary
        </Button>
        <Button variant="outlined" color="secondary">
          Outlined Secondary
        </Button>
      </InlineStack>

      <Divider />

      <InlineStack>
        <Button variant="contained">Contained Default</Button>
        <Button variant="contained" color="primary">
          Contained Primary
        </Button>
        <Button variant="contained" color="secondary">
          Contained Secondary
        </Button>
      </InlineStack>

      <Divider />

      <InlineStack>
        <Button variant="contained" color="primary" size="small">
          Small
        </Button>
        <Button variant="contained" color="primary">
          Medium
        </Button>
        <Button variant="contained" color="primary" size="large">
          Large
        </Button>
      </InlineStack>

      <Divider />

      <InlineStack>
        <Fab variant="circular">Ci</Fab>
        <Fab variant="extended">Extended</Fab>
      </InlineStack>

      <Divider />

      <InlineStack>
        <Fab size="small">sm</Fab>
        <Fab size="medium">md</Fab>
        <Fab size="large">lg</Fab>
      </InlineStack>

      <Divider />

      <InlineStack>
        <Fab color="default">df</Fab>
        <Fab color="primary">pr</Fab>
        <Fab color="secondary">se</Fab>
      </InlineStack>
    </>
  )
}

import { makeStyles } from '@material-ui/core'
import { memo } from 'react'
import { Section } from './Section'

const useStyles = makeStyles(
  theme => ({
    section: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  }),
  { name: 'Sections' }
)

export const Sections = memo(function Sections({ sections }: { sections: readonly Section.Data[] }) {
  const classes = useStyles()

  return (
    <>
      {sections.map((section, index) => (
        <Section key={index} className={classes.section} section={section} />
      ))}
    </>
  )
})

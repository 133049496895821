import { Span, startTransaction } from '@sentry/react'
import { UserDailyActivity, AccountServiceSignature, version } from '@zettelooo/server-shared'
import { ChromeExtensionMode } from '../../../../../modules/chrome-extension-mode'
import { webConfig } from '../../../../../modules/web-config'
import { Authentication } from '../../authentication'
import { Persistent, PersistentKey } from '../../persistent'

export abstract class RestService {
  constructor(private readonly name: string, protected readonly persistent: Persistent) {}

  protected async requestUnauthenticated<Request, Response>(
    endPoint: string,
    request: Request,
    span?: Span
  ): Promise<Response> {
    const requestSpan =
      span?.startChild({ op: 'request-unauthenticated', description: `Request Unauthenticated: ${endPoint}` }) ??
      startTransaction({ op: 'request-unauthenticated', name: `Request Unauthenticated: ${endPoint}` })

    const endPointUrl = RestService.getServiceEndPointUrl(this.name, endPoint)
    const rawResponse = await fetch(endPointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'sentry-trace': requestSpan.toTraceparent(),
      },
      body: JSON.stringify(request),
    })

    if (rawResponse.ok) {
      const response: Response = await rawResponse.json()
      requestSpan.finish()
      return response
    }

    throw Error(`Request to ${endPointUrl} failed with ${rawResponse.status} ${rawResponse.statusText}`)
  }

  protected async requestAuthenticated<Request, Response>(
    endPoint: string,
    request: Request,
    span?: Span
  ): Promise<Response> {
    const requestSpan =
      span?.startChild({ op: 'request-authenticated', description: `Request Authenticated: ${endPoint}` }) ??
      startTransaction({ op: 'request-authenticated', name: `Request Authenticated: ${endPoint}` })

    const request1Span = requestSpan.startChild({ op: 'request-1' })
    const endPointUrl = this.getServiceEndPointUrl(endPoint)
    const client: UserDailyActivity.Client = ChromeExtensionMode.isActive
      ? 'chrome extension'
      : webConfig.environment.agent === 'electron'
      ? 'desktop'
      : webConfig.os === 'Windows' || webConfig.os === 'macOS' || webConfig.os === 'Linux'
      ? 'web desktop'
      : webConfig.os === 'Android' || webConfig.os === 'iOS'
      ? 'web mobile'
      : 'not specified'
    const rawResponse1 = await fetch(endPointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authentication: this.persistent(PersistentKey.Authentication).get()?.accessToken ?? '',
        'X-Data-Client': client,
        'sentry-trace': request1Span.toTraceparent(),
      },
      body: JSON.stringify(request),
    })

    if (rawResponse1.ok) {
      const response: Response = await rawResponse1.json()
      request1Span.finish()
      requestSpan.finish()
      return response
    }
    request1Span.finish()

    if (rawResponse1.status !== 401 /* Unauthorized HTTP response code */)
      throw Error(`Request to ${endPointUrl} failed with ${rawResponse1.status} ${rawResponse1.statusText}`)

    const refreshTokenSpan = requestSpan.startChild({ op: 'refresh-token' })
    const refreshTokenRequest: AccountServiceSignature.RefreshToken.Request = {
      refreshToken: this.persistent(PersistentKey.Authentication).get()?.refreshToken ?? '',
    }
    const refreshTokenRawResponse = await fetch(RestService.getServiceEndPointUrl('account', 'refresh-token'), {
      method: 'POST',
      body: JSON.stringify(refreshTokenRequest),
    })
    refreshTokenSpan.finish()

    if (!refreshTokenRawResponse.ok)
      throw Error(
        `Refresh token request failed with ${refreshTokenRawResponse.status} ${refreshTokenRawResponse.statusText}`
      )

    const refreshTokenResponse: AccountServiceSignature.RefreshToken.Response = await refreshTokenRawResponse.json()
    this.persistent(PersistentKey.Authentication).set(
      Authentication.decode(refreshTokenResponse.userTokens.accessToken, refreshTokenResponse.userTokens.refreshToken)
    )

    const request2Span = requestSpan.startChild({ op: 'request-2' })
    const rawResponse2 = await fetch(endPointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authentication: this.persistent(PersistentKey.Authentication).get()?.accessToken ?? '',
        'X-Data-Client': client,
        'sentry-trace': request2Span.toTraceparent(),
      },
      body: JSON.stringify(request),
    })

    if (rawResponse2.ok) {
      const response: Response = await rawResponse2.json()
      request2Span.finish()
      requestSpan.finish()
      return response
    }

    throw Error(`Request to ${endPointUrl} failed with ${rawResponse2.status} ${rawResponse2.statusText}`)
  }

  private static getServiceEndPointUrl(name: string, endPoint: string): string {
    return `${webConfig.services.baseUrls.privateApi.rest}/${version}/${name}/${endPoint}`
  }

  protected getServiceEndPointUrl(endPoint: string): string {
    return RestService.getServiceEndPointUrl(this.name, endPoint)
  }
}

export namespace GrpcService {
  export namespace ServerStreaming {
    export interface Handlers<D> {
      onData(data: D): void
      onError?(error: Error): void
      onEnd?(): void
    }

    export interface Controlers {
      cancel(): void
    }
  }
}

import { makeStyles, IconButton } from '@material-ui/core'
import classNames from 'classnames'
import Color from 'color'
import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { CustomIcon } from '../../../../../../../../../../../modules/custom-icon'
import { Messages } from './Messages'

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
      border: `${theme.spacing(0.125)}px solid transparent`,
      borderRadius: theme.spacing(1.25),
      minHeight: theme.spacing(7),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
    information: {
      borderColor: theme.palette.info.main,
      backgroundColor: new Color(theme.palette.info.main).alpha(0.15).string(),
    },
    success: {
      borderColor: theme.palette.success.main,
      backgroundColor: new Color(theme.palette.success.main).alpha(0.15).string(),
    },
    warning: {
      borderColor: theme.palette.warning.main,
      backgroundColor: new Color(theme.palette.warning.main).alpha(0.15).string(),
    },
    error: {
      borderColor: theme.palette.error.main,
      backgroundColor: new Color(theme.palette.error.main).alpha(0.15).string(),
    },
    close: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      padding: theme.spacing(1),
    },
    container: {
      padding: theme.spacing(2),
      overflow: 'auto',
    },
  }),
  { name: 'Messages' }
)

export function Message({
  containerProps,
  variant,
  onClose,
}: {
  containerProps: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  variant?: Messages.Variant
  onClose?(): void
}) {
  const classes = useStyles()

  return (
    <div className={classNames(classes.root, classes[variant ?? 'information'])}>
      <IconButton color="inherit" className={classes.close} onClick={onClose}>
        <CustomIcon name="Close" />
      </IconButton>

      <div {...containerProps} className={classNames(classes.container, containerProps.className)} />
    </div>
  )
}

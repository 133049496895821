import { IpcChannel, MainIpcMessageType, WindowType } from '@zettelooo/desktop-shared'
import { useAppHistoryControl } from '../../../modules/app-history'
import { useIpcMessageHandling } from '../../../modules/electron'
import { Panel, useOpenMainPage } from '../components/pages/MainPage'
import { routes } from '../modules/routes'

export function useMainIpcMessageHandling(): void {
  const { appHistoryControlStatic } = useAppHistoryControl()

  const { openMainPageStatic } = useOpenMainPage()

  useIpcMessageHandling(
    IpcChannel.Main,
    (event, message) => {
      switch (message.type) {
        case MainIpcMessageType.ElectronSetState:
          if (message.pageId) {
            openMainPageStatic({
              type: Panel.Type.Page,
              pageId: message.pageId,
              cardId: message.cardId,
            })
          }
          break

        case MainIpcMessageType.ElectronSignOut:
          appHistoryControlStatic.push(routes.signOut.getPath({}, {}))
          break

        case MainIpcMessageType.ElectronOpenSettingsDesktop:
          openMainPageStatic({
            type: Panel.Type.Settings,
            subType: Panel.Settings.Type.Desktop,
          })
          break
      }
    },
    [],
    { windowType: WindowType.Main }
  )
}

import { makeStyles, Typography } from '@material-ui/core'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { StaticBadge } from '../../../../../../../../StaticBadge'
import {
  OnboardingTour,
  OnboardingWelcomeTourStep,
  useOnboardingConnect,
} from '../../../../../../../modules/onboarding'
import { Initializer } from '../../../../../../Initializer'
import { AccountData } from '../../../../../modules/account-data'
import { MainPage } from '../../MainPage'
import { Panel, useSwitchPanel } from '../../panel'
import { ButtonItem } from './ButtonItem'

const useStyles = makeStyles(
  theme => ({
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(1),
    },
  }),
  { name: 'InboxItem' }
)

export function InboxItem() {
  const { allPages, allCards } = useContexts(Initializer.Contexts)
  const {
    refs: { setIsSideBarMenuOpen },
    panel,
  } = useContexts(MainPage.Contexts)

  // TODO: Replace it with the new badges models, when you need to re-enable this component:
  const existingActivities = []
  // const existingActivities = useExistingActivities(
  //   cardActivity => {
  //     const card = allCards.dictionary[cardActivity.cardId]
  //     if (!card) return false
  //     const page = allPages.dictionary[card.pageId]
  //     if (!page) return false
  //     return true
  //   },
  //   [allCards, allPages]
  // )

  const { switchPanelStatic } = useSwitchPanel()

  const { connectOnboardingAnchorElement } = useOnboardingConnect(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.Activity,
    {
      placement: 'right',
    }
  )

  const classes = useStyles()

  return (
    <ButtonItem
      ref={connectOnboardingAnchorElement}
      icon={<CustomIcon name="Activity" size={2.5} />}
      tooltipTitle="Inbox"
      selected={panel?.type === Panel.Type.Activity}
      onClick={() => {
        setIsSideBarMenuOpen(false)
        switchPanelStatic({ type: Panel.Type.Activity })
      }}
    >
      <div className={classes.title}>
        <Typography variant="subtitle1" noWrap>
          Inbox
        </Typography>
        <StaticBadge badgeContent={existingActivities.length} invisible={existingActivities.length === 0} />
      </div>
    </ButtonItem>
  )
}

import { makeStyles, Typography } from '@material-ui/core'
import { useContexts } from '../../../../../../../../../../../../../modules/contexts'
import { AccountData } from '../../../../../../../../../modules/account-data'
import { FieldPaper } from '../../FieldPaper'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(1),
      color: theme.palette.text.disabled,
    },
  }),
  { name: 'EmailField' }
)

export function EmailField({ className }: { className?: string; onChange?(email: string): Promise<void> }) {
  const { account } = useContexts(AccountData.Contexts)

  const classes = useStyles()

  return (
    <FieldPaper className={className}>
      <Typography variant="body2" className={classes.root} noWrap>
        {account.email}
      </Typography>
    </FieldPaper>
  )
}

import { useTheme } from '@material-ui/core'
import { useEffect } from 'react'
import { ChromeExtensionMode } from '../../../../modules/chrome-extension-mode'

export function useChromeExtensionThemTypeUpdating(): void {
  const { postMessageStatic } = ChromeExtensionMode.usePostMessage()

  const theme = useTheme()

  useEffect(() => {
    postMessageStatic({
      type: ChromeExtensionMode.Message.TYPE,
      subType: ChromeExtensionMode.Message.SubType.ToContentScriptSetThemeType,
      themeType: theme.palette.type,
    })
  }, [theme.palette.type])
}

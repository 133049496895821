import { useState } from 'react'
import { useWindowDev } from '../../../modules/window-dev'

// This component is for test purposes only:

export function UIErrorGenerator() {
  const [noErrorYet, setNoErrorYet] = useState(true)

  useWindowDev(
    () => ({
      generateUIError(): void {
        setNoErrorYet(false)
      },
    }),
    []
  )

  if (noErrorYet) return <></>

  throw Error('Manually generated error by UIErrorGenerator component.')
}

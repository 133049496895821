import { createContext, PropsWithChildren } from 'react'
import { Databases } from './types'

export function DatabasesProvider({ databases, children }: PropsWithChildren<{ databases: Databases }>) {
  return <DatabasesProvider.Context.Provider value={databases}>{children}</DatabasesProvider.Context.Provider>
}

export namespace DatabasesProvider {
  export const Context = createContext<Databases>(undefined as any)
}

import { makeStyles, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { createContext, useContext } from 'react'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { DeviceProvider } from '../../../../../../../../../modules/device'
import { useNavigation, Navigable } from '../../../../../../../../../modules/navigation'
import { webConfig } from '../../../../../../../../../modules/web-config'
import { MemoizedContextProvider } from '../../../../../../../../MemoizedContextProvider'
import { usePersistent, PersistentKey } from '../../../../../../../modules/persistent'
import { useAvailableScreenWidth } from '../../hooks/useAvailableScreenWidth'
import { MainPage } from '../../MainPage'
import { Panel } from '../../panel'
import { ComposeItem } from './ComposeItem'
import { InboxItem } from './InboxItem'
import { PageList } from './PageList'
import { SearchItem } from './SearchItem'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1.5, 0, 2, 0),
    },
    header: {
      flex: 'none',
    },
    container: {
      height: 0,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
      overflow: 'hidden',
    },
    containerMinimized: {
      paddingBottom: theme.spacing(10), // To make space for the new page fab // TODO: Remove this
    },
  }),
  { name: 'SideBar' }
)

export function SideBar({ className }: { className?: string }) {
  const { persistent } = usePersistent(PersistentKey.SideBarSize)

  const { screenWidth, isMobile } = useContext(DeviceProvider.Context)
  const { isSideBarLocked, panel } = useContexts(MainPage.Contexts)

  const { redrawNavigationPathHighlight } = useNavigation()

  const { availableScreenWidth } = useAvailableScreenWidth()

  const theme = useTheme()

  const classes = useStyles()

  const actualSideBarSize =
    screenWidth <= theme.constants.sidebar.fullWidthSideBarMaximumScreenWidth
      ? webConfig.temporary.temporarilyDisabledOrToBeRemoved
        ? availableScreenWidth
        : availableScreenWidth - theme.spacing(8.125) // TODO: The width of the Spaces component, not to be explicitly calculated here
      : isMobile || webConfig.temporary.temporarilyDisabledOrToBeRemoved
      ? theme.constants.sidebar.expandedMaximumSize
      : panel?.type === Panel.Type.NewPage
      ? theme.constants.sidebar.expandedMaximumSize
      : isSideBarLocked
      ? theme.constants.sidebar.collapsedSize
      : persistent[PersistentKey.SideBarSize] ?? theme.constants.sidebar.expandedMaximumSize

  const minimized = actualSideBarSize === theme.constants.sidebar.collapsedSize

  return (
    <MemoizedContextProvider
      context={SideBar.Context}
      value={{
        minimized,
      }}
      dependencies={[minimized]}
    >
      <Navigable>
        {({ connectNavigable }) => (
          <div className={classNames(classes.root, className)} style={{ width: actualSideBarSize }}>
            {/* <Header className={classes.header} /> */}

            <div
              ref={connectNavigable}
              className={classNames(classes.container, minimized && classes.containerMinimized)}
              onScroll={redrawNavigationPathHighlight}
            >
              {/* TODO: When any of the other items besides the page list are enabled, remove the min-height from the page list's page wrapper: */}

              {!webConfig.temporary.temporarilyDisabledOrToBeRemoved && <ComposeItem />}

              {!webConfig.temporary.temporarilyDisabledOrToBeRemoved && <SearchItem />}

              {!webConfig.temporary.temporarilyDisabledOrToBeRemoved && <InboxItem />}

              <PageList />
            </div>
          </div>
        )}
      </Navigable>
    </MemoizedContextProvider>
  )
}

export namespace SideBar {
  export const Context = createContext<{
    minimized: boolean
  }>(undefined as any)
}

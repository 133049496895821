import log, { RootLogger } from 'loglevel'
import remote from 'loglevel-plugin-remote'
import { webConfig } from '../../modules/web-config'

export function initializeLoglevel(): void {
  switch (webConfig.environment.node) {
    case 'development':
      initializeLoglevelForDevelopment()
      break
    case 'test':
      initializeLoglevelForTest()
      break
    case 'production':
      initializeLoglevelForProduction()
      break
  }

  function initializeLoglevelForDevelopment(): void {
    log.enableAll()
    setLoglevelToGlobal()
  }
  function initializeLoglevelForTest(): void {
    log.enableAll()
    // jest.spyOn(log, 'trace')
    // jest.spyOn(log, 'debug')
    // jest.spyOn(log, 'info')
    // jest.spyOn(log, 'warn')
    // jest.spyOn(log, 'error')
    // jest.spyOn(log, 'log')
    setLoglevelToGlobal()
  }
  function initializeLoglevelForProduction(): void {
    log.setLevel('info')
    setLoglevelToGlobal()
    // initializeLoglevelPluginRemote() // TODO: Temporary, until the server support is implemented
    integrateLogJsonStringifier()
  }

  function setLoglevelToGlobal(): void {
    const editableLogWindow = window as unknown as { log: RootLogger }
    editableLogWindow.log = log
  }

  function initializeLoglevelPluginRemote(): void {
    remote.apply(log, {
      url: '/api/logger', // TODO: Use a real one
      method: 'POST',
      level: 'info',
      format: remote.json,
    })
  }

  function integrateLogJsonStringifier(): void {
    const originalMethodFactory = log.methodFactory

    log.methodFactory = function methodFactory(methodName, logLevel, loggerName) {
      const originalMethod = originalMethodFactory(methodName, logLevel, loggerName)

      return (...args) => {
        try {
          originalMethod(JSON.stringify(args))
        } catch (error) {
          originalMethod('"(Bad Content)"')
        }
      }
    }

    log.setLevel(log.getLevel())
  }
}

import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import { IpcChannel, MainIpcMessageType } from '@zettelooo/desktop-shared'
import classNames from 'classnames'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { sendIpcMessage } from '../../../../../../../modules/electron'

const useStyles = makeStyles(
  theme => ({
    root: {
      flex: 'none',
    },
    button: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'SwitchToLauncherItem' }
)

export function SwitchToLauncherItem({ className }: { className?: string }) {
  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <div className={classNames(classes.root, className)}>
      <Tooltip title="Launcher">
        <span>
          <IconButton
            className={classNames(classes.button, commonClasses.electronNoDrag)}
            onClick={() => {
              sendIpcMessage(IpcChannel.Main, { type: MainIpcMessageType.MainSwitchTo, target: 'launcher' })
            }}
          >
            <CustomIcon name="Action" size={2.5} />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  )
}

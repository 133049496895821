import { CircularProgress, makeStyles, Tooltip, useTheme } from '@material-ui/core'
import { Replay as ReplayIcon } from '@material-ui/icons' // TODO: Use our custom icons
import { Id } from '@zettelooo/commons'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { useCommonStyles } from '../../../../../hooks/useCommonStyles'
import { usePersistedUploadingFile } from '../hooks/usePersistedUploadingFile'
import { useUploadingFileHandlers } from '../hooks/useUploadingFileHandlers'

const PROGRESS_INDICATOR_SIZE_UNITS = 4.5

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
    },
    statusWrapper: {
      borderRadius: '50%',
      padding: theme.spacing(0.5),
    },
    progressWrapper: {
      width: theme.spacing(PROGRESS_INDICATOR_SIZE_UNITS + 1),
      height: theme.spacing(PROGRESS_INDICATOR_SIZE_UNITS + 1),
      backgroundColor: theme.palette.background.default,
    },
    progressCircle: {
      color: theme.palette.text.secondary,
    },
    errorWrapper: {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.paper,
    },
    errorIcon: {
      margin: theme.spacing(0.5),
    },
  }),
  { name: 'UploadingFileProgress' }
)

export function UploadingFileProgress({
  fileId,
  className,
  children,
}: PropsWithChildren<{
  fileId: Id
  className?: string
}>) {
  const { persistedUploadingFile } = usePersistedUploadingFile(fileId)

  const { retryUploadingFileStatic } = useUploadingFileHandlers()

  const theme = useTheme()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  if (
    !persistedUploadingFile ||
    (persistedUploadingFile.progressPercent !== undefined && persistedUploadingFile.progressPercent >= 100)
  )
    return null

  return (
    <div className={classNames(classes.root, className)}>
      {persistedUploadingFile.error ? (
        <Tooltip title="Upload again" className={classNames(classes.statusWrapper, classes.errorWrapper)}>
          <ReplayIcon className={classes.errorIcon} onClick={() => retryUploadingFileStatic(fileId)} />
        </Tooltip>
      ) : (
        <div className={classNames(classes.statusWrapper, classes.progressWrapper)}>
          {persistedUploadingFile.progressPercent === undefined || persistedUploadingFile.progressPercent <= 1 ? (
            <CircularProgress
              size={theme.spacing(PROGRESS_INDICATOR_SIZE_UNITS)}
              className={classes.progressCircle}
              variant="indeterminate"
            />
          ) : (
            <CircularProgress
              size={theme.spacing(PROGRESS_INDICATOR_SIZE_UNITS)}
              className={classes.progressCircle}
              variant="determinate"
              value={persistedUploadingFile.progressPercent}
            />
          )}

          <div className={commonClasses.center}>{children}</div>
        </div>
      )}
    </div>
  )
}

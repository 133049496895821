import { ServiceWorkerMessage, ServiceWorkerMessageType } from '../service-worker'

export enum VersionServiceWorkerMessageSubType {
  Get = 'GET',
  Set = 'SET',
}

export type VersionServiceWorkerMessage<
  T extends VersionServiceWorkerMessageSubType = VersionServiceWorkerMessageSubType
> = ServiceWorkerMessage<ServiceWorkerMessageType.Version> &
  {
    [VersionServiceWorkerMessageSubType.Get]: {
      readonly subType: VersionServiceWorkerMessageSubType.Get
    }
    [VersionServiceWorkerMessageSubType.Set]: {
      readonly subType: VersionServiceWorkerMessageSubType.Set
      readonly version: string
    }
  }[T]

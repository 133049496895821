import { List, Menu, MenuItem } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import { useContextMenu } from '../../../../../../../../../../../hooks/useContextMenu'
import { sendAnalyticEvent } from '../../../../../../../../../../../modules/analytics'
import { useManageCommands } from '../../../../../../../../../../../modules/commands'
import { Navigable, NavigationArea } from '../../../../../../../../../../../modules/navigation'
import { MenuItemWithKeyboardShortcut } from '../../../../../../../../../../menu/MenuItemWithKeyboardShortcut'

export function ContextMenu({
  account,
  page,
  menuProps,
  disabled,
}: {
  account: Model.Account
  page: Model.Page
  menuProps: ReturnType<typeof useContextMenu>['menuProps']
  disabled?: boolean
}) {
  const { runCommandStatic } = useManageCommands()

  return (
    <NavigationArea disabled={!menuProps.open}>
      <Menu {...menuProps} MenuListProps={{ disablePadding: true }}>
        <List>
          {page.public && (
            <Navigable disabled={disabled}>
              {({ connectNavigable }) => (
                <MenuItem
                  ref={connectNavigable}
                  disabled={disabled}
                  onClick={() => {
                    sendAnalyticEvent('Side Bar (Page Context Menu)', 'Click on Copy URL')
                    runCommandStatic('page.copyUrl')
                  }}
                >
                  Copy URL
                </MenuItem>
              )}
            </Navigable>
          )}

          <Navigable disabled={disabled}>
            {({ connectNavigable }) => (
              <MenuItem
                ref={connectNavigable}
                disabled={disabled}
                onClick={() => {
                  sendAnalyticEvent('Side Bar (Page Context Menu)', 'Click on Rename')
                  runCommandStatic('page.rename')
                }}
              >
                Rename
              </MenuItem>
            )}
          </Navigable>

          <Navigable disabled={disabled}>
            {({ connectNavigable }) => (
              <MenuItem
                ref={connectNavigable}
                disabled={disabled}
                onClick={() => {
                  sendAnalyticEvent('Side Bar (Page Context Menu)', 'Click on Change avatar')
                  runCommandStatic('page.changeAvatar')
                }}
              >
                Change avatar
              </MenuItem>
            )}
          </Navigable>

          <Navigable disabled={disabled}>
            {({ connectNavigable }) => (
              <MenuItemWithKeyboardShortcut
                innerRef={connectNavigable}
                shortcutKeys="Delete"
                enableShortcutKeys={menuProps.open}
                disabled={disabled}
                onClick={() => {
                  sendAnalyticEvent('Side Bar (Page Context Menu)', 'Click on Delete')
                  runCommandStatic('page.remove')
                }}
              >
                Delete
              </MenuItemWithKeyboardShortcut>
            )}
          </Navigable>

          {page.memberUserIds.includes(account.id) && page.memberUserIds.length > 1 && (
            <Navigable disabled={disabled}>
              {({ connectNavigable }) => (
                <MenuItem
                  ref={connectNavigable}
                  disabled={disabled}
                  onClick={() => {
                    sendAnalyticEvent('Side Bar (Page Context Menu)', 'Click on Leave')
                    runCommandStatic('page.leave')
                  }}
                >
                  Leave
                </MenuItem>
              )}
            </Navigable>
          )}
        </List>
      </Menu>
    </NavigationArea>
  )
}

import { Typography } from '@material-ui/core'
import { typed } from '../../../../../helpers/typed'
import { webConfig } from '../../../../../modules/web-config'
import {
  OnboardingLauncherTourStep,
  OnboardingReferencesTourStep,
  OnboardingTour,
  OnboardingWelcomeTourStep,
  TourData,
  ToursData,
} from '../types'

export const toursData: ToursData = {
  [OnboardingTour.Welcome]: typed<TourData<OnboardingTour.Welcome>>({
    [OnboardingWelcomeTourStep.Pages]: {
      title: 'Pages',
      description:
        'A Page is a collection of any type of information on a certain topic. eg.: 1-1 meeting, receipts, daily note, moodboard, weekly planning, etc...',
    },
    [OnboardingWelcomeTourStep.CardAdderDialog]: {
      title: 'Compose',
      description: 'Compose gives you versatile new ways to capture and organize your thoughts.',
    },
    [OnboardingWelcomeTourStep.Activity]: {
      title: 'Activity',
      description() {
        return (
          <>
            <Typography variant="body1" gutterBottom>
              Watch activities as they occur.
            </Typography>
            <Typography variant="body1">
              See everything that&apos;s going on in your pages, and see who&apos;s done what tasks and who&apos;s edits
              what.
            </Typography>
          </>
        )
      },
    },
    [OnboardingWelcomeTourStep.Card]: {
      title: 'Note',
      description: `A note in ${webConfig.app.name} acts much the way as a physical index note. A page may contain many notes. You can have text, task, and media, or a combination of them in one note. You can reorder, duplicate, or double click on any note to edit it.`,
      onlyAsTooltip: true,
    },
    [OnboardingWelcomeTourStep.CardAdder]: {
      title: 'Composer',
      description: `We designed the ${webConfig.app.name} for fast input, you can save any information from here, upload files, create tasks, format your text, and connect your information by pressing “#” for referencing pages or “##” for referencing notes.`,
      onlyAsTooltip: true,
    },
    [OnboardingWelcomeTourStep.PageSideBar]: {
      title: 'Page menu',
      description: 'You can customize your page settings by opening the sidebar, change the layout and install apps.',
      onlyAsTooltip: true,
    },
    [OnboardingWelcomeTourStep.CommandBarAccess]: {
      title: 'Command bar',
      description() {
        return (
          <>
            <Typography variant="body1" gutterBottom>
              Try the most powerfull shortcut of all...
            </Typography>
            <Typography variant="body1">
              <strong>Command + K</strong>
            </Typography>
          </>
        )
      },
    },
    [OnboardingWelcomeTourStep.CommandBarWelcome]: {
      title: `${webConfig.app.name} command`,
      description() {
        return (
          <>
            <Typography variant="body1" gutterBottom>
              Welcome to {webConfig.app.name} command bar!
            </Typography>
            <Typography variant="body1">From here you can do eveything ✨💫</Typography>
          </>
        )
      },
      onlyAsTooltip: true,
    },
    [OnboardingWelcomeTourStep.CommandBarShortcuts]: {
      title: `${webConfig.app.name} command`,
      description() {
        return (
          <>
            <Typography variant="body1" gutterBottom>
              Almost all commands have shortcuts.
            </Typography>
            <Typography variant="body1">Use these commands to go blazingly fast 🚀</Typography>
          </>
        )
      },
      onlyAsTooltip: true,
    },
  }),
  [OnboardingTour.References]: typed<TourData<OnboardingTour.References>>({
    [OnboardingReferencesTourStep.ReferencesStep]: {
      title: 'References first',
      description: 'Here is the description for this first step',
    },
  }),
  [OnboardingTour.Launcher]: typed<TourData<OnboardingTour.Launcher>>({
    [OnboardingLauncherTourStep.LauncherStep]: {
      title: 'Launcher first',
      description: 'Here is the description for this first step',
    },
  }),
}

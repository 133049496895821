import { Id } from '@zettelooo/commons'
import { Developer, DeveloperServiceSignature, Extension } from '@zettelooo/server-shared'
import { Persistent } from '../../persistent'
import { RestService } from './RestService'

export class DeveloperService extends RestService {
  constructor(persistent: Persistent) {
    super('developer', persistent)
  }

  async adminGetPageData(): Promise<{
    developers: readonly Developer.Display[]
    reviewingExtensionHeaders: readonly Extension.Header[]
  }> {
    const { developers, reviewingExtensionHeaders } = await this.requestUnauthenticated<
      DeveloperServiceSignature.AdminGetPageData.Request,
      DeveloperServiceSignature.AdminGetPageData.Response
    >('admin-get-page-data', {})

    return {
      developers,
      reviewingExtensionHeaders,
    }
  }

  async adminAddDeveloper(newDeveloper: Pick<Developer.Document, 'id' | 'password' | 'name' | 'email'>): Promise<void> {
    await this.requestUnauthenticated<
      DeveloperServiceSignature.AdminAddDeveloper.Request,
      DeveloperServiceSignature.AdminAddDeveloper.Response
    >('admin-add-developer', { newDeveloper })
  }

  async adminRemoveDeveloper(developerId: Id): Promise<void> {
    await this.requestUnauthenticated<
      DeveloperServiceSignature.AdminRemoveDeveloper.Request,
      DeveloperServiceSignature.AdminRemoveDeveloper.Response
    >('admin-remove-developer', { developerId })
  }

  async adminEditDeveloper(
    developerId: Id,
    updates: {
      readonly password?: string
      readonly name?: string
      readonly email?: string
    }
  ): Promise<void> {
    await this.requestUnauthenticated<
      DeveloperServiceSignature.AdminEditDeveloper.Request,
      DeveloperServiceSignature.AdminEditDeveloper.Response
    >('admin-edit-developer', { developerId, updates })
  }

  async adminApproveExtension(extensionId: Id): Promise<void> {
    await this.requestUnauthenticated<
      DeveloperServiceSignature.AdminApproveExtension.Request,
      DeveloperServiceSignature.AdminApproveExtension.Response
    >('admin-approve-extension', { extensionId })
  }

  async signIn(id: string, password: string): Promise<{ developerId?: Id }> {
    if (!id) throw Error('ID is required.')
    if (!password) throw Error('Password is required.')

    const { developerId } = await this.requestUnauthenticated<
      DeveloperServiceSignature.SignIn.Request,
      DeveloperServiceSignature.SignIn.Response
    >('sign-in', { id, password })

    return {
      developerId,
    }
  }

  async getPageData(developerId: Id): Promise<{
    readonly signedInDeveloper: Developer.Display
    readonly extensionFlows: readonly Extension.Flow.Display[]
  }> {
    const { signedInDeveloper, extensionFlows } = await this.requestUnauthenticated<
      DeveloperServiceSignature.GetPageData.Request,
      DeveloperServiceSignature.GetPageData.Response
    >('get-page-data', { developerId })

    return {
      signedInDeveloper,
      extensionFlows,
    }
  }

  async grantAccessKey(developerId: Id, name: string): Promise<{ readonly accessKey: string }> {
    const { accessKey } = await this.requestUnauthenticated<
      DeveloperServiceSignature.GrantAccessKey.Request,
      DeveloperServiceSignature.GrantAccessKey.Response
    >('grant-access-key', { developerId, name })

    return {
      accessKey,
    }
  }

  async revokeAccessKey(developerId: Id, name: string): Promise<void> {
    await this.requestUnauthenticated<
      DeveloperServiceSignature.RevokeAccessKey.Request,
      DeveloperServiceSignature.RevokeAccessKey.Response
    >('revoke-access-key', { developerId, name })
  }

  async uploadExtension(developerId: Id, file: File): Promise<void> {
    const formData = new FormData()
    formData.set('packed', file)
    const response = await fetch(this.getServiceEndPointUrl('upload-extension'), {
      method: 'POST',
      body: formData,
      headers: {
        'X-Developer-ID': developerId,
      },
    })
    if (!response.ok) {
      const message = await response.text()
      throw Error(message)
    }
  }

  async createExtensionFlow(developerId: Id, appId: Id): Promise<void> {
    await this.requestUnauthenticated<
      DeveloperServiceSignature.CreateExtensionFlow.Request,
      DeveloperServiceSignature.CreateExtensionFlow.Response
    >('create-extension-flow', { developerId, appId })
  }

  async deleteExtension(
    developerId: Id,
    extensionId: Id,
    publicationMode?: Extension.Flow.PublicationMode
  ): Promise<void> {
    await this.requestUnauthenticated<
      DeveloperServiceSignature.DeleteExtension.Request,
      DeveloperServiceSignature.DeleteExtension.Response
    >('delete-extension', { developerId, extensionId, publicationMode })
  }

  async grantExtensionAccessKey(developerId: Id, extensionId: Id, name: string): Promise<{ accessKey: string }> {
    const { accessKey } = await this.requestUnauthenticated<
      DeveloperServiceSignature.GrantExtensionAccessKey.Request,
      DeveloperServiceSignature.GrantExtensionAccessKey.Response
    >('grant-extension-access-key', { developerId, extensionId, name })

    return {
      accessKey,
    }
  }

  async revokeExtensionAccessKey(developerId: Id, extensionId: Id, name: string): Promise<void> {
    await this.requestUnauthenticated<
      DeveloperServiceSignature.RevokeExtensionAccessKey.Request,
      DeveloperServiceSignature.RevokeExtensionAccessKey.Response
    >('revoke-extension-access-key', { developerId, extensionId, name })
  }

  async modifyExtensionRelatedUserName(
    developerId: Id,
    extensionId: Id,
    role: 'tester' | 'target',
    action: 'add' | 'remove',
    userName: string
  ): Promise<void> {
    await this.requestUnauthenticated<
      DeveloperServiceSignature.ModifyExtensionRelatedUserName.Request,
      DeveloperServiceSignature.ModifyExtensionRelatedUserName.Response
    >('modify-extension-related-user-name', { developerId, extensionId, role, action, userName })
  }

  async limitedPublishExtension(developerId: Id, extensionId: Id): Promise<void> {
    await this.requestUnauthenticated<
      DeveloperServiceSignature.LimitedPublishExtension.Request,
      DeveloperServiceSignature.LimitedPublishExtension.Response
    >('limited-publish-extension', { developerId, extensionId })
  }

  async editExtensionStagedToBePublished(
    developerId: Id,
    extensionId: Id,
    stagedToBePublished: boolean
  ): Promise<void> {
    await this.requestUnauthenticated<
      DeveloperServiceSignature.EditExtensionStagedToBePublished.Request,
      DeveloperServiceSignature.EditExtensionStagedToBePublished.Response
    >('edit-extension-staged-to-be-published', { developerId, extensionId, stagedToBePublished })
  }
}

import { KeyValueEngineByBroadcaster, createKeyValueStore } from '../../../../modules/key-value-store'
import { Broadcaster } from '../../../../modules/service-worker'
import { Persistent } from '../persistent'
import { BroadcasterChannel } from '../service-worker'
import { equalityCheckers } from './equalityCheckers'
import { getDefaultValues } from './getDefaultValues'
import { Ephemeral, EphemeralType } from './types'

export function createEphemeralOnBroadcaster(
  broadcaster: Broadcaster,
  persistent: Persistent,
  currentValues?: Readonly<EphemeralType>
): Ephemeral {
  const engine = new KeyValueEngineByBroadcaster(
    () => getDefaultValues(persistent),
    equalityCheckers,
    broadcaster,
    BroadcasterChannel.Ephemeral,
    currentValues
  )
  const ephemeral = createKeyValueStore(engine)

  return ephemeral
}

import { Id } from '@zettelooo/commons'
import Dexie from 'dexie'
import { writable } from '../../../../../helpers/writable'
import { DemoMode } from '../../../../../modules/demo-mode'
import { PeekMode } from '../../../../../modules/peek-mode'
import { Broadcaster } from '../../../../../modules/service-worker'
import { ActionModel } from '../../models'
import { BroadcasterChannel } from '../../service-worker'
import { Database } from '../Database'
import { DatabaseTable } from '../DatabaseTable'

export class ActionsDatabase implements Database {
  private readonly dexie!: Dexie

  private readonly models: DatabaseTable<ActionModel>

  private readonly subscriptions: ActionsDatabase.EventSubscription[] = []

  constructor(private readonly broadcaster: Broadcaster) {
    if (DemoMode.data || PeekMode.data) {
      this.models = DatabaseTable.createOnMemory('id')
      return
    }

    this.dexie = new Dexie('ActionsDatabase')

    this.dexie
      .version(8)
      .stores({
        actions: 'id',
      })
      .upgrade(transaction => {
        // This means we may safely remove the older version migrations:
        return Promise.all(transaction.db.tables.map(table => table.clear()))
      })

    this.models = DatabaseTable.createOnDexie(this.dexie, 'actions')

    this.dexie.open()

    broadcaster.addMessageEventListener<ActionsDatabase.BroadcasterMessage>(
      BroadcasterChannel.ActionsDatabase,
      message => this.fire(message)
    )
  }

  dispose(): void {
    if (DemoMode.data || PeekMode.data) return

    this.dexie.close()
  }

  async clearAll(): Promise<void> {
    if (DemoMode.data || PeekMode.data) {
      this.models.memory.deleteAll()
      return
    }

    await Promise.all(this.dexie.tables.map(table => table.clear()))
  }

  async add(action: ActionModel): Promise<void> {
    if (DemoMode.data || PeekMode.data) {
      this.models.memory.put(action)
    } else {
      await this.models.dexie.add(action)
    }

    this.fire({ action }, true)
  }

  async isEmpty(): Promise<boolean> {
    if (DemoMode.data || PeekMode.data) return this.models.memory.count() === 0

    return (await this.models.dexie.count()) === 0
  }

  async getAll(): Promise<ActionModel[]> {
    const actions = DemoMode.data || PeekMode.data ? this.models.memory.getAll() : await this.models.dexie.toArray()

    actions.sort((firstAction, secondAction) =>
      firstAction.type === ActionModel.Type.UpsertModel && secondAction.type === ActionModel.Type.UpsertModel
        ? firstAction.newModel.updatedAt - secondAction.newModel.updatedAt
        : 0
    )
    return actions
  }

  async bulkDelete(ids: readonly Id[]): Promise<void> {
    if (DemoMode.data || PeekMode.data) {
      ids.forEach(id => this.models.memory.delete(id))
      return
    }

    return this.models.dexie.bulkDelete(writable(ids))
  }

  subscribe(subscription: ActionsDatabase.EventSubscription): void {
    this.subscriptions.push(subscription)
  }

  unsubscribe(subscription: ActionsDatabase.EventSubscription): void {
    const index = this.subscriptions.indexOf(subscription)
    if (index >= 0) {
      this.subscriptions.splice(index, 1)
    }
  }

  private fire(message: ActionsDatabase.BroadcasterMessage, propagateEventThroughServiceWorker?: boolean): void {
    const subscriptions = [...this.subscriptions] // It's required, because some subscription execution may change this.subscriptions directly because of setStates in the middle of the process

    subscriptions.forEach(subscription => subscription(message.action))

    if (propagateEventThroughServiceWorker) {
      this.broadcaster.postMessage<ActionsDatabase.BroadcasterMessage>(BroadcasterChannel.ActionsDatabase, message)
    }
  }
}

export namespace ActionsDatabase {
  export type EventSubscription = (action: ActionModel) => void

  export interface BroadcasterMessage {
    readonly action: ActionModel
  }
}

import { makeStyles, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useRefWrap } from '../../../../../hooks/useRefWrap'
import { useManageCommands, NavigableWithCommands } from '../../../../../modules/commands'
import { commonKeyboardCombinations } from '../../../../../modules/keyboard-handler'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: theme.spacing(48),
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
    avatarColorWrapper: {
      width: '25%',
      paddingTop: '25%',
      position: 'relative',
    },
    avatarColor: {
      position: 'absolute',
      borderRadius: '50%',
      top: '15%',
      bottom: '15%',
      left: '15%',
      right: '15%',
      cursor: 'pointer',
    },
    defaultColor: {
      border: `${theme.spacing(0.25)}px solid ${theme.palette.primary.light}`,
      '&:not($selectedAvatarColor)': {
        opacity: 0.3,
      },
    },
    selectedAvatarColor: {
      border: `${theme.spacing(0.25)}px solid ${theme.palette.primary.light}`,
    },
  }),
  { name: 'ColorSelect' }
)

export function ColorSelect({
  noColorOption,
  color,
  onSelect,
  className,
}: {
  noColorOption?: boolean
  color: string
  onSelect(color: string): void
  className?: string
}) {
  const onSelectRef = useRefWrap(onSelect)

  const { runCommandStatic } = useManageCommands()

  const theme = useTheme()

  const avatarPalette = useMemo(
    () => (noColorOption ? [...theme.palette.avatar, ''] : theme.palette.avatar),
    [Boolean(noColorOption), theme]
  )

  const classes = useStyles()

  return (
    <div className={classNames(classes.root, className)}>
      {avatarPalette.map(avatarColor => (
        <NavigableWithCommands
          key={avatarColor}
          commandGroup={({ navigableRef }) => ({
            name: 'colorSelect',
            commands: [
              {
                name: 'select',
                defaultShortcutKeys: commonKeyboardCombinations.enter,
                handler() {
                  onSelectRef.current(avatarColor)
                },
              },
            ],
          })}
        >
          {({ connectNavigable }) => (
            <div ref={connectNavigable} className={classes.avatarColorWrapper}>
              <div
                style={{ backgroundColor: avatarColor || theme.palette.background.paper }}
                className={classNames(
                  classes.avatarColor,
                  !avatarColor && classes.defaultColor,
                  avatarColor === color && classes.selectedAvatarColor
                )}
                onClick={() => runCommandStatic('colorSelect.select')}
              />
            </div>
          )}
        </NavigableWithCommands>
      ))}
    </div>
  )
}

import { ReadonlyRecord } from '@zettelooo/commons'

interface ObjectHelpers {
  omit<T>(object: T, key: ((string | number | symbol) & {}) | keyof T): T
  omitAll<T>(object: T, keys: readonly (((string | number | symbol) & {}) | keyof T)[]): T

  keyOf<T>(object: T, value: T[keyof T]): undefined | keyof T
  findKey<T>(object: T, predicate: (value: T[keyof T], key: keyof T) => any): undefined | keyof T
  getKeys<T>(object: T): readonly (T extends ReadonlyRecord<infer K, any> ? K : string | number | symbol)[]

  onlyExisting<T>(object: T): Partial<T>
  diff<T>(object: T, reference: T): Partial<T>

  map<T, U>(opject: T, mapper: (key: keyof T, value: T[keyof T]) => U): Record<keyof T, U>
}

const integerRegex = /^[0-9]+$/

export const objectHelpers: ObjectHelpers = {
  omit(object: any, key: any): any {
    const { [key]: omitted, ...result } = object
    return result
  },
  omitAll(object: any, keys: readonly any[]): any {
    const result = { ...object }
    keys.forEach(key => delete result[key])
    return result
  },

  keyOf(object: any, value: any): any {
    const result = Object.keys(object).find(key => object[key] === value)
    if (!result) return undefined
    const isNumericalKey = integerRegex.test(result)
    if (isNumericalKey) return Number(result)
    return result
  },
  findKey(object: any, predicate: (value: any, key: any) => any): any {
    const result = Object.keys(object).find(key => predicate(object[key], key))
    if (!result) return undefined
    const isNumericalKey = integerRegex.test(result)
    if (isNumericalKey) return Number(result)
    return result
  },
  getKeys(object: any): readonly any[] {
    return Object.keys(object)
  },

  onlyExisting(object: any): any {
    const result: any = {}
    Object.keys(object).forEach(key => {
      object[key] !== undefined && (result[key] = object[key])
    })
    return result
  },
  diff(object: any, reference: any): any {
    const result: any = {}
    Object.keys(object).forEach(objectKey => {
      if (object[objectKey] !== reference[objectKey]) {
        result[objectKey] = object[objectKey]
      }
    })
    Object.keys(reference).forEach(referenceKey => {
      if (!(referenceKey in object)) {
        result[referenceKey] = undefined
      }
    })
    return result
  },
  map(object: any, mapper: (key: any, value: any) => any): any {
    const result: any = {}
    const keys = Object.keys(object)
    keys.forEach(key => {
      result[key] = mapper(key, object[key])
    })
    return result
  },
}

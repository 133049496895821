import { Button, Input, makeStyles, Typography } from '@material-ui/core'
import { Keyboard } from '@zettelooo/commons'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useGetSet } from 'react-use'
import { KeyboardCombinationView } from '../../../../../../../../../../../modules/keyboard-handler'
import { useAppNotistack } from '../../../../../../../../../modules/app-notistack'
import { FieldPaper } from './FieldPaper'

const useStyles = makeStyles(
  theme => ({
    shortcutWrapper: {
      width: 0,
      flexGrow: 1,
      marginRight: theme.spacing(2),
    },
    shortcutInput: {
      width: 0,
      ...theme.typography.body2,
      '&>input': {
        width: 0,
        height: 0,
        lineHeight: 0,
        margin: 0,
        padding: 0,
      },
    },
  }),
  { name: 'ShortcutField' }
)

const INVALID_CODES: Keyboard.NativeEventCode[] = [
  'ShiftLeft',
  'ShiftRight',
  'ControlLeft',
  'ControlRight',
  'AltLeft',
  'AltRight',
  'MetaLeft',
  'MetaRight',
]

export function ShortcutField({
  currentShortcut,
  rootClassName,
  onChange,
}: {
  currentShortcut?: Keyboard.Combination
  rootClassName?: string
  onChange(shortcut: Keyboard.Combination): void
}) {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setShortcut(currentShortcut)
  }, [currentShortcut])

  const [getShortcut, setShortcut] = useGetSet(currentShortcut)
  const [isEditing, setIsEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { enqueueSnackbar } = useAppNotistack()

  const cancel = useCallback(() => {
    setShortcut(currentShortcut)
    setIsEditing(false)
  }, [currentShortcut])

  const submit = useCallback(() => {
    const shortcut = getShortcut()
    if (
      !shortcut ||
      typeof shortcut === 'string' ||
      !(shortcut.ctrl || shortcut.shift || shortcut.alt || shortcut.meta) ||
      INVALID_CODES.includes(shortcut.code)
    ) {
      enqueueSnackbar('Invalid shortcut')
      return
    }

    setIsSubmitting(true)
    onChange(shortcut)
    setIsSubmitting(false)
    setIsEditing(false)
  }, [])

  const classes = useStyles()

  return (
    <FieldPaper flex className={rootClassName}>
      <div className={classes.shortcutWrapper} onClick={() => inputRef.current?.focus()}>
        {getShortcut() && (
          <KeyboardCombinationView
            combination={getShortcut()!}
            modifyParts={(parts, combination) => {
              const combinationCode = typeof combination === 'string' ? combination : combination.code
              if (INVALID_CODES.includes(combinationCode)) return parts.slice(0, -1)
              return parts
            }}
          />
        )}
      </div>

      {isEditing ? (
        <>
          <Input
            inputRef={inputRef}
            disableUnderline
            className={classes.shortcutInput}
            disabled={isSubmitting}
            value=""
            onKeyDown={event => {
              setShortcut({
                meta: event.metaKey,
                ctrl: event.ctrlKey,
                alt: event.altKey,
                shift: event.shiftKey,
                code: event.nativeEvent.code as Keyboard.NativeEventCode,
              })

              event.preventDefault()
              event.stopPropagation()
            }}
          />

          <Button disabled={isSubmitting} onClick={cancel}>
            <Typography variant="caption">Cancel</Typography>
          </Button>

          <Button color="secondary" disabled={isSubmitting} onClick={submit}>
            <Typography variant="caption">Confirm</Typography>
          </Button>
        </>
      ) : (
        <>
          <Button
            color="secondary"
            onClick={() => {
              setIsEditing(true)
              // In order to wait for the input ref to be populated after rendering due to setting isEditing to true
              setTimeout(() => inputRef.current?.focus())
            }}
          >
            <Typography variant="caption">Edit</Typography>
          </Button>
        </>
      )}
    </FieldPaper>
  )
}

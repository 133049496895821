import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useState } from 'react'
import { Context } from './Context'
import { PromoteChromeExtension } from './PromoteChromeExtension'

const useStyles = makeStyles(
  theme => ({
    root: {
      margin: theme.spacing(-2, 0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(2),
    },
    invisible: {
      position: 'fixed',
      display: 'none',
    },
  }),
  { name: 'Banner' }
)

export function Banner({ className }: { className?: string }) {
  const [numberOfItems, setNumberOfItems] = useState(0)
  const invisible = numberOfItems === 0

  const classes = useStyles()

  return (
    <Context.Provider value={setNumberOfItems}>
      <div className={classNames(classes.root, invisible && classes.invisible, className)}>
        <PromoteChromeExtension />
      </div>
    </Context.Provider>
  )
}

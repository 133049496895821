import { createContext, PropsWithChildren } from 'react'
import { MemoizedContextProvider } from '../../components/MemoizedContextProvider'
import { useScreenSize } from './useScreenSize'
import { useSystemThemePaletteType } from './useSystemThemePaletteType'

export function DeviceProvider({ children }: PropsWithChildren<{}>) {
  const screenSize = useScreenSize()

  const systemThemePaletteType = useSystemThemePaletteType()

  return (
    <MemoizedContextProvider
      context={DeviceProvider.Context}
      valueFactory={() => ({ ...screenSize, ...systemThemePaletteType })}
      dependencies={[screenSize, systemThemePaletteType]}
    >
      {children}
    </MemoizedContextProvider>
  )
}

export namespace DeviceProvider {
  export const Context = createContext<ReturnType<typeof useScreenSize> & ReturnType<typeof useSystemThemePaletteType>>(
    undefined as any
  )
}

import { ComponentProps, ComponentRef, DependencyList, forwardRef, RefObject, useMemo, useState } from 'react'
import { useCombineRefs } from '../../../hooks/useCombineRefs'
import { useRefWrap } from '../../../hooks/useRefWrap'
import { KeyboardSubscription, useKeyboardHandling } from '../../keyboard-handler'
import { useNavigationAreaActivityStatus } from '../hooks/useNavigationAreaActivityStatus'
import { NavigableStatus } from '../types'
import { Navigable } from './Navigable'

export const NavigableWithKeyboardHandling = forwardRef<
  ComponentRef<typeof Navigable>,
  ComponentProps<typeof Navigable> & {
    keyboardSubscription(provided: { navigableRef: RefObject<ComponentRef<typeof Navigable>> }): KeyboardSubscription
    keyboardSubscriptionDependencies?: DependencyList
  }
>(function NavigableWithKeyboardHandling(
  { keyboardSubscription, keyboardSubscriptionDependencies, ...navigableProps },
  ref
) {
  const { isNavigationActiveForThisArea } = useNavigationAreaActivityStatus()

  const [navigableReferee, setNavigableReferee] = useState<ComponentRef<typeof Navigable> | null>(null)
  const navigableRef = useRefWrap(navigableReferee)

  const combineRefs = useCombineRefs(ref, setNavigableReferee)

  const subscription = useMemo(() => keyboardSubscription({ navigableRef }), keyboardSubscriptionDependencies ?? [])

  useKeyboardHandling(subscription, [subscription], {
    removed: !isNavigationActiveForThisArea,
    disabled:
      navigableProps.disabled || !navigableReferee || navigableReferee.navigableStatus === NavigableStatus.NotNavigated,
  })

  return <Navigable ref={combineRefs()} {...navigableProps} />
})

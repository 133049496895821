import { Button, makeStyles, Typography, useTheme } from '@material-ui/core'
import { DeepWritable, Id, Writable } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { convertCardModelToCardPublicModel, convertPageModelToPagePublicModel, Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { DetailedHTMLProps, forwardRef, HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { arrayHelpers } from '../../../../../../helpers/native/arrayHelpers'
import { useRefWrap } from '../../../../../../hooks/useRefWrap'
import { useReturningImperativeHandle } from '../../../../../../hooks/useReturningImperativeHandle'
import { useStateAccessor } from '../../../../../../hooks/useStateAccessor'
import { useContexts } from '../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../modules/custom-icon'
import { webConfig } from '../../../../../../modules/web-config'
import { Gap } from '../../../../../Gap'
import { AppContexts } from '../../../../modules/app-contexts'
import { useAppNotistack } from '../../../../modules/app-notistack'
import { ExtensionRolesProvider, useExtensionLifeSpan, useExtensionManager } from '../../../../modules/extension'
import { HtmlContentTools } from '../../../../modules/HtmlContentTools'
import { PersistentKey, usePersistent } from '../../../../modules/persistent'
import { Dropzone } from '../../components/Dropzone'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    parts: {
      flex: 'auto',
      // whiteSpace: 'pre-wrap', // It makes all the new-lines in the injected HTML content visible.
      overflowWrap: 'break-word',
      overflowX: 'hidden',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    part: {
      margin: 'auto',
      flex: 'none',
      width: '100%',
      height: '100%',
    },
    footer: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      flexWrap: 'wrap',
    },
    button: {
      minWidth: theme.spacing(5),
    },
  }),
  { name: 'CardComposer' }
)

interface ExtendedPart extends ZettelExtensions.LifeSpan.Shared.Composer.Part<any, any> {
  readonly initialState: any
  readonly extensionId: Id
  readonly data: any
}

export const CardComposer = forwardRef<
  {
    readonly root: HTMLDivElement | null
    readonly submit: () => Promise<void>
    readonly reset: () => void
    readonly pasteCommonData: (commonData: ZettelExtensions.CardData.CommonData) => void
  },
  {
    page: Model.Page
    card?: Model.Card
    onSubmit?: (value: CardComposer.Value) => void | Promise<void>
    onReset?: () => void
    onCancel?: () => void
    contentMaxWidthUnits?: number
  } & Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onSubmit' | 'onReset'>
>(function CardComposer(
  { page, card, onSubmit, onReset, onCancel, contentMaxWidthUnits, className, ...otherProps },
  ref
) {
  const [, deviceId, authentication] = usePersistent(PersistentKey.DeviceId, PersistentKey.Authentication)

  const { externalDraggingOverStatus } = useContexts(AppContexts)

  const [extendedParts, setExtendedParts] = useState<readonly HtmlContentTools.WithReferenceKey<ExtendedPart>[]>([])

  const initialValueAccessor = useStateAccessor<CardComposer.Value>(() => card?.dataDictionary ?? {}, [page, card])
  const initialValue = initialValueAccessor.get()

  const rootRef = useRef<HTMLDivElement>(null)

  const { extensionManager } = useExtensionManager()

  const { enqueueSnackbar, closeSnackbar } = useAppNotistack()

  const errorMessageSnackbarKeyRef = useRef<any>(undefined)

  function closeErrorMessageSnackbar(): void {
    if (errorMessageSnackbarKeyRef.current) {
      closeSnackbar(errorMessageSnackbarKeyRef.current)
      errorMessageSnackbarKeyRef.current = undefined
    }
  }

  useEffect(closeErrorMessageSnackbar, [page, card])

  const submitRef = useRefWrap(async (): Promise<void> => {
    closeErrorMessageSnackbar()
    try {
      const value: DeepWritable<CardComposer.Value> = {}
      extendedParts.forEach(extendedPart => {
        const data = extendedPart.parseState(HtmlContentTools.getState(extendedPart), extendedPart.data)
        if (data !== undefined) {
          value[extendedPart.extensionId] = data
        }
      })
      const notParticipatedExtensionIds = Model.ExtensionConfiguration.getExtensionIds(
        page.extensionConfiguration
      ).filter(extensionId => extendedParts.every(extendedPart => extendedPart.extensionId !== extensionId))
      notParticipatedExtensionIds.forEach(notParticipatedExtensionId => {
        const extractions = extendedParts
          .map(extendedPart =>
            extensionManager.extractCardData(
              extendedPart.extensionId,
              notParticipatedExtensionId,
              value[extendedPart.extensionId]
            )
          )
          .filter(extractedData => extractedData !== undefined)
        const constructed = extensionManager.constructCardData(
          notParticipatedExtensionId,
          initialValueAccessor.get()[notParticipatedExtensionId],
          extractions,
          () => []
        )
        if (constructed === undefined) return
        value[notParticipatedExtensionId] = constructed
      })
      await onSubmit?.(value)
      resetRef.current()
    } catch (error) {
      errorMessageSnackbarKeyRef.current = enqueueSnackbar('Error', String(error), { variant: 'error' })
    }
  })

  const resetRef = useRefWrap((): void => {
    initialValueAccessor.reset()
  })

  const contentRef = useRefWrap({
    page,
    extensionManager,
    extendedParts,
  })

  const referee = useReturningImperativeHandle(
    ref,
    () => ({
      get root() {
        return rootRef.current
      },
      async submit() {
        await submitRef.current()
      },
      reset() {
        resetRef.current()
      },
      pasteCommonData(commonData) {
        const dataDictionary = contentRef.current.extendedParts.reduce((current, extendedPart) => {
          const extraction = contentRef.current.extensionManager.extractCardDataFromCommonData(
            extendedPart.extensionId,
            commonData
          )
          if (extraction !== undefined) {
            const cardData = contentRef.current.extensionManager.constructCardData(
              extendedPart.extensionId,
              undefined, // extendedPart.parseState(HtmlContentTools.getState(extendedPart), extendedPart.data), // Because for the composer we prioritize the pasted data over the initial state.
              [extraction],
              () => []
            )
            if (cardData !== undefined) {
              current[extendedPart.extensionId] = cardData
            }
          }
          return current
        }, {} as Writable<CardComposer.Value>)
        initialValueAccessor.set(dataDictionary)
      },
    }),
    []
  )

  const updatingExtendedPartStateRef = useRef(false)

  useExtensionLifeSpan({
    name: 'composer',
    target: {
      pageId: page.id,
    },
    scopedValues: {
      [ZettelExtensions.Scope.Device]: deviceId,
      [ZettelExtensions.Scope.User]: authentication?.decodedAccessToken.userId ?? '',
      [ZettelExtensions.Scope.Page]: page.id,
    },
    dataFactories: {
      page: useCallback(({ header }) => convertPageModelToPagePublicModel(page, header.id), [page]),
      card: useCallback(({ header }) => card && convertCardModelToCardPublicModel(card, header.id), [card]),
    },
    accessFactory: () => ({
      async submit() {
        await submitRef.current()
      },
      reset() {
        resetRef.current()
      },
    }),
    registryFactory: ({ header }) => ({
      part(getter) {
        let currentValue: (typeof extendedParts)[number]
        const reference: {
          current?: ZettelExtensions.LifeSpan.Shared.Composer.Part.Reference<any, any>
        } = {}
        return [
          () => {
            const part = getter()
            const data = initialValue[header.id]
            currentValue = HtmlContentTools.withReferenceKey({
              ...part,
              initialState: part.formatState(data),
              extensionId: header.id,
              data,
            })
            reference.current = {
              ...HtmlContentTools.createReference(currentValue),
              update(updates) {
                setExtendedParts(current => {
                  const evaluatedUpdates = typeof updates === 'function' ? updates(currentValue) : updates
                  HtmlContentTools.handleUpdates(currentValue, evaluatedUpdates)
                  const oldValue = currentValue
                  const newValue = { ...oldValue, ...evaluatedUpdates }
                  currentValue = newValue
                  return arrayHelpers.replace(current, oldValue, newValue)
                })
              },
            }
            HtmlContentTools.addListener(currentValue, newState => {
              if (updatingExtendedPartStateRef.current) return
              updatingExtendedPartStateRef.current = true
              contentRef.current.extendedParts.forEach(extendedPart => {
                if (extendedPart === currentValue) return
                HtmlContentTools.setState(
                  extendedPart,
                  contentRef.current.extensionManager.constructCardData(
                    extendedPart.extensionId,
                    extendedPart.parseState(HtmlContentTools.getState(extendedPart), extendedPart.data),
                    [
                      contentRef.current.extensionManager.extractCardData(
                        currentValue.extensionId,
                        extendedPart.extensionId,
                        currentValue.parseState(newState, currentValue.data)
                      ),
                    ],
                    () =>
                      contentRef.current.extendedParts
                        .filter(
                          otherExtendedPart => otherExtendedPart !== extendedPart && otherExtendedPart !== currentValue
                        )
                        .map(otherExtendedPart =>
                          contentRef.current.extensionManager.extractCardData(
                            otherExtendedPart.extensionId,
                            extendedPart.extensionId,
                            otherExtendedPart.parseState(
                              HtmlContentTools.getState(otherExtendedPart),
                              otherExtendedPart.data
                            )
                          )
                        )
                  )
                )
              })
              updatingExtendedPartStateRef.current = false
            })
            setExtendedParts(current => [...current, currentValue])
            return () => {
              setExtendedParts(current => arrayHelpers.remove(current, currentValue))
              delete reference.current
              HtmlContentTools.deleteReference(currentValue)
            }
          },
          reference,
        ]
      },
    }),
    dependencies: [page.id, deviceId, authentication, initialValue],
  })

  const orderedExtendedParts = useMemo(
    () =>
      arrayHelpers.orderBy(extendedParts, item => (item.position === 'top' ? -1 : item.position === 'bottom' ? +1 : 0)),
    [extendedParts]
  )

  const theme = useTheme()

  const classes = useStyles()

  return (
    <ExtensionRolesProvider role="CardComposer">
      <Dropzone
        {...otherProps}
        ref={rootRef}
        className={classNames(classes.root, className)}
        visible={externalDraggingOverStatus !== 'none'}
        icon={<CustomIcon name="Upload" size="large" color="secondary" />}
        message={
          <Typography variant="h5" color="secondary" noWrap>
            {externalDraggingOverStatus === 'files'
              ? 'Upload as card'
              : externalDraggingOverStatus === 'html' || externalDraggingOverStatus === 'text'
              ? 'Paste as card'
              : externalDraggingOverStatus === 'urls'
              ? 'Paste as link card'
              : ''}
          </Typography>
        }
        // onDroppedFiles={items => referee.pasteCommonData({ files: items })}
        onDroppedText={text => referee.pasteCommonData({ text })}
      >
        <div className={classes.parts}>
          {orderedExtendedParts.map((part, index) => (
            <HtmlContentTools.Render key={index} htmlContentWithReferenceKey={part}>
              {({ containerRefCallback }) => (
                <div
                  ref={containerRefCallback}
                  className={classes.part}
                  style={{
                    ...(contentMaxWidthUnits === undefined ? {} : { maxWidth: theme.spacing(contentMaxWidthUnits) }),
                    ...(part.grow ? { flexGrow: 1 } : {}),
                  }}
                />
              )}
            </HtmlContentTools.Render>
          ))}
        </div>

        <div className={classes.footer}>
          {!webConfig.temporary.cardComposer.hideResetButton && (
            <Button variant="text" size="small" color="primary" className={classes.button} onClick={resetRef.current}>
              Reset
            </Button>
          )}

          <Gap grow />

          {onCancel && (
            <Button variant="outlined" size="small" color="primary" className={classes.button} onClick={onCancel}>
              Cancel
            </Button>
          )}

          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.button}
            onClick={submitRef.current}
          >
            Submit
          </Button>
        </div>
      </Dropzone>
    </ExtensionRolesProvider>
  )
})

export namespace CardComposer {
  export type Value = Model.DataDictionary
}

export enum ServiceWorkerMessageType {
  SkipWaiting = 'SKIP_WAITING',
  Broadcaster = 'BROADCASTER',
  Version = 'VERSION',
  Ephemeral = 'EPHEMERAL',
  SuperWindowClient = 'SUPER_WINDOW_CLIENT',
}

export type ServiceWorkerMessage<T extends ServiceWorkerMessageType = ServiceWorkerMessageType> = {
  readonly type: T
}

export enum BroadcasterChannel {
  ReloadAllTabs = 'RELOAD_ALL_TABS',
  PersistentDatabase = 'PERSISTENT_DATABASE',
  ActionsDatabase = 'ACTIONS_DATABASE',
  MutablesDatabase = 'MUTABLES_DATABASE',
  ExtensionsDatabase = 'EXTENSIONS_DATABASE',
  Ephemeral = 'EPHEMERAL',
}

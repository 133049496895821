import { generatePath, useParams } from 'react-router-dom'
import { objectHelpers } from '../../../../../../helpers/native/objectHelpers'
import { QueryBase } from './types'
import { useQuery } from './useQuery'

export class RouteSpecification<Params extends {} = {}, Query extends QueryBase = {}> {
  constructor(public readonly pattern: string) {}

  getPath(
    params: Params,
    query: Query,
    options?: {
      extendPath?: string
      extendQuery?: QueryBase
    }
  ): string {
    const paramsPath = generatePath(this.pattern, params)
    const path = options?.extendPath
      ? `${paramsPath}${paramsPath.endsWith('/') || options.extendPath.startsWith('/') ? '' : '/'}${options.extendPath}`
      : paramsPath
    const extendedQuery = options?.extendQuery ? { ...query, ...options.extendQuery } : query
    const queryString = new URLSearchParams(objectHelpers.onlyExisting(extendedQuery) as any).toString()
    return queryString ? `${path}?${queryString}` : path
  }

  // This method requires the classes types only:
  // eslint-disable-next-line class-methods-use-this
  useParams(): Params {
    return useParams()
  }

  // This method requires the classes types only:
  // eslint-disable-next-line class-methods-use-this
  useQuery(): Query {
    return useQuery<Query>()
  }
}

export namespace RouteSpecification {
  export type Params<T> = T extends RouteSpecification<infer P, any> ? P : unknown

  export type Query<T> = T extends RouteSpecification<any, infer Q> ? Q : unknown
}

import { PaletteType, useMediaQuery } from '@material-ui/core'
import { useMemo } from 'react'

export function useSystemThemePaletteType(): {
  systemThemePaletteType: PaletteType
} {
  const systemThemePaletteType = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light'

  return useMemo(() => ({ systemThemePaletteType }), [systemThemePaletteType])
}

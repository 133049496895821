import { developmentOptions } from './developmentOptions'
import { environment } from './environment'

const cashFlowPurpose: boolean =
  environment.target === 'local' ? developmentOptions.localTargetCashFlowPurpose ?? true : true // For now, we only develop the cash flow project

export const app = {
  name: 'Zettel',
  domain: 'zettel.ooo',
  cashFlowPurpose,
} as const

import { Id, PartialReadonlyRecord } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useGetSet } from 'react-use'
import { arrayHelpers } from '../../../../../../../helpers/native/arrayHelpers'
import { webConfig } from '../../../../../../../modules/web-config'
import { useConnectionStatus } from '../../../../../modules/connection-status'
import { EphemeralKey, useEphemeral } from '../../../../../modules/ephemeral'
import { FunnelServiceGetPublicPageMessages } from '../../../../../modules/services'

export function useFunnelServiceGetPublicPageMessages(pageId: Id): {
  stillLoading: boolean
  usersDictionary: PartialReadonlyRecord<Id, Model.User>
  cardsDictionary: PartialReadonlyRecord<Id, Model.Card>
  pagesDictionary: PartialReadonlyRecord<Id, Model.Page>
  cards: readonly Model.Card[]
  page: Model.Page | undefined
} {
  const { ephemeral } = useEphemeral()

  const startTimeoutRef = useRef<any>(undefined)
  const lastSynchronizedSequenceRef = useRef(0)

  const [status, setStatus] = useState(FunnelServiceGetPublicPageMessages.Status.ClosedInitially)
  const [getStillLoading, setStillLoading] = useGetSet(true)
  const [getUsersDictionary, setUsersDictionary] = useGetSet<PartialReadonlyRecord<Id, Model.User>>({})
  const [getCardsDictionary, setCardsDictionary] = useGetSet<PartialReadonlyRecord<Id, Model.Card>>({})
  const [getPagesDictionary, setPagesDictionary] = useGetSet<PartialReadonlyRecord<Id, Model.Page>>({})

  const cards = useMemo(
    () => arrayHelpers.sortBy(Object.values(getCardsDictionary()) as Model.Card[], 'sequence'),
    [getCardsDictionary()]
  )

  const page = getPagesDictionary()[pageId]

  const { isOnline } = useConnectionStatus()

  const service = useMemo(
    () =>
      new FunnelServiceGetPublicPageMessages({
        pageId,

        onStatusChange: setStatus,

        onHeartBeat(currentServerTimestamp) {
          // Do nothing!
        },

        onMutationsEvent(mutations, numberOfRemainingMutations) {
          if (getStillLoading() && numberOfRemainingMutations < 10) {
            setStillLoading(false)
          }

          for (let i = 0; i < mutations.length; i += 1) {
            const mutation = mutations[i]
            const { model, sequence } = mutation
            lastSynchronizedSequenceRef.current = Math.max(lastSynchronizedSequenceRef.current, sequence)
            switch (model.type) {
              case Model.Type.User: {
                const oldModel = getUsersDictionary()[model.id]
                if (oldModel && oldModel.version > model.version) break
                if (model.isDeleted) {
                  const newDictionary = { ...getUsersDictionary() }
                  delete newDictionary[model.id]
                  setUsersDictionary(newDictionary)
                  break
                }
                setUsersDictionary({
                  ...getUsersDictionary(),
                  [model.id]: model,
                })
                break
              }

              case Model.Type.Card: {
                if (model.pageId !== pageId) break
                const oldModel = getCardsDictionary()[model.id]
                if (oldModel && oldModel.version > model.version) break
                if (model.isDeleted) {
                  const newDictionary = { ...getCardsDictionary() }
                  delete newDictionary[model.id]
                  setCardsDictionary(newDictionary)
                  break
                }
                setCardsDictionary({
                  ...getCardsDictionary(),
                  [model.id]: model,
                })
                break
              }

              case Model.Type.Page: {
                if (model.id !== pageId) break
                const oldModel = getPagesDictionary()[model.id]
                if (oldModel && oldModel.version > model.version) break
                if (model.isDeleted) {
                  const newDictionary = { ...getPagesDictionary() }
                  delete newDictionary[model.id]
                  setPagesDictionary(newDictionary)
                  break
                }
                setPagesDictionary({
                  ...getPagesDictionary(),
                  [model.id]: model,
                })
                break
              }

              default:
              // model // NOTE: Type of `model` should be never here
            }
          }
        },

        onExtensionUpdate(extensionId) {
          ephemeral(EphemeralKey.NewlyUpdatedExtensionIds).set([
            ...ephemeral(EphemeralKey.NewlyUpdatedExtensionIds).get(),
            extensionId,
          ])
        },

        getLastSynchronizedSequence() {
          return lastSynchronizedSequenceRef.current
        },
      }),
    [pageId, ephemeral]
  )

  switch (status) {
    case FunnelServiceGetPublicPageMessages.Status.Starting:
      // Do nothing, wait for status change or time out
      break

    case FunnelServiceGetPublicPageMessages.Status.Started:
      if (!isOnline) {
        service.close()
      }
      break

    case FunnelServiceGetPublicPageMessages.Status.ClosedInitially:
    case FunnelServiceGetPublicPageMessages.Status.ClosedByClient:
      if (isOnline) {
        service.start()
      }
      break

    case FunnelServiceGetPublicPageMessages.Status.ClosedDueToStartingTimeout:
    case FunnelServiceGetPublicPageMessages.Status.ClosedByServer:
    case FunnelServiceGetPublicPageMessages.Status.ClosedDueToServerRestartRequest:
    case FunnelServiceGetPublicPageMessages.Status.ClosedDueToHeartBeatTimeout:
    case FunnelServiceGetPublicPageMessages.Status.ClosedDueToServerOrNetworkFailure:
      if (isOnline) {
        if (!startTimeoutRef.current) {
          startTimeoutRef.current = setTimeout(() => {
            startTimeoutRef.current = undefined
            service.start()
          }, webConfig.timings.funnelApiService.getPublicPageMessages.retryStarting)
        }
      } else if (startTimeoutRef.current) {
        clearTimeout(startTimeoutRef.current)
        startTimeoutRef.current = undefined
      }
      break
  }

  useEffect(
    () => () => {
      if (
        service.getStatus() === FunnelServiceGetPublicPageMessages.Status.Starting ||
        service.getStatus() === FunnelServiceGetPublicPageMessages.Status.Started
      ) {
        service.close()
      }
    },
    [service]
  )

  return {
    stillLoading: getStillLoading(),
    usersDictionary: getUsersDictionary(),
    cardsDictionary: getCardsDictionary(),
    pagesDictionary: getPagesDictionary(),
    cards,
    page,
  }
}

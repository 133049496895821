import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useContexts } from '../../../../../../../modules/contexts'
import { AccountData } from '../../account-data'
import { SearchProvider } from '../SearchProvider'

export function useSearchCards(
  query: string,
  searchFilter: useSearchCards.SearchFilter,
  options?: {
    readonly pageFilterId?: Id
    readonly disabled?: boolean
  }
): {
  filteredCardSearchContents: readonly SearchProvider.CardSearchContent[]
} {
  const { accountCardsOrdered } = useContexts(AccountData.Contexts)
  const { flexSearchIndexStatic, cardSearchContentsDictionaryStatic } = useContext(SearchProvider.Context)

  const [filteredCards, setFilteredCards] = useState<readonly (Model.Card | undefined)[]>([])

  useLayoutEffect(() => {
    if (options?.disabled) {
      setFilteredCards([])
      return
    }

    if (!query) {
      setFilteredCards(accountCardsOrdered)
      return
    }

    const index =
      searchFilter === useSearchCards.SearchFilter.All
        ? flexSearchIndexStatic.cards
        : searchFilter === useSearchCards.SearchFilter.Files
        ? flexSearchIndexStatic.files
        : null

    if (!index) {
      setFilteredCards([])
      return
    }

    setFilteredCards(index.search(query).map(cardId => cardSearchContentsDictionaryStatic[cardId]?.card))
  }, [options?.disabled, query, accountCardsOrdered, searchFilter])

  return {
    filteredCardSearchContents: useMemo(
      () =>
        filteredCards
          .map(card => (card && cardSearchContentsDictionaryStatic[card.id])!)
          .filter(
            cardSearchContent =>
              cardSearchContent && (!options?.pageFilterId || cardSearchContent.card.pageId === options.pageFilterId)
          ),
      [options?.pageFilterId, filteredCards]
    ),
  }
}

export namespace useSearchCards {
  export enum SearchFilter {
    All = 'ALL',
    Files = 'FILES',
  }
}

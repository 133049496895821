import { DependencyList, useContext, useEffect } from 'react'
import { CommandsContext } from '../CommandsProvider'
import { CommandHandler } from '../types'

export function useCommandHandling(
  codeName: string,
  handler: CommandHandler,
  dependencies: DependencyList,
  options?: { disabled?: boolean }
): void {
  const { commandManager } = useContext(CommandsContext)

  useEffect(() => {
    if (!options?.disabled) {
      commandManager.subscribeToCommand(codeName, handler)

      return () => {
        commandManager.unsubscribeFromCommand(codeName, handler)
      }
    }
  }, [commandManager, codeName, ...dependencies, options?.disabled])
}

import classNames from 'classnames'
import { DetailedHTMLProps, ImgHTMLAttributes, PropsWithChildren, useState } from 'react'
import { useCommonStyles } from '../hooks/useCommonStyles'

export function DefaultedImage({
  children,
  ...imgProps
}: PropsWithChildren<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>>) {
  const [isLoaded, setIsLoaded] = useState(false)

  const { commonClasses } = useCommonStyles()

  return (
    <>
      <img
        alt="" // Just to keep ESLint happy!
        {...imgProps}
        className={classNames(!isLoaded && commonClasses.hidden, imgProps.className)}
        onLoad={event => {
          setIsLoaded(true)
          imgProps.onLoad?.(event)
        }}
      />

      {!isLoaded && children}
    </>
  )
}

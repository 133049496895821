import { v4 } from 'uuid'

/**
 * Generates some dummy data with different types,
 * widely used in test suits.
 */
export function generateDummyData(): any {
  return {} as any
}

generateDummyData.string = () => `String:${Math.random().toString()}` as any
generateDummyData.number = (upperLimit = 1000000) => Math.floor(Math.random() * upperLimit) as any
generateDummyData.boolean = () => (Math.random() > 0.5) as any
generateDummyData.function = () => (() => {}) as any
generateDummyData.id = () => v4() as any

import { ZettelExtensions } from '@zettelooo/extension-api'
import { OptionsObject } from 'notistack'

export function convertVariant(
  variant?: ZettelExtensions.LifeSpan.Shared.Activated.ShowMessage.Options.Variant
): NonNullable<OptionsObject['variant']> {
  switch (variant) {
    case 'success':
      return 'success'

    case 'information':
      return 'info'

    case 'warning':
      return 'warning'

    case 'error':
      return 'error'

    default:
      return 'default'
  }
}

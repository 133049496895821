import { captureException } from '@sentry/react'
import { Id, Timestamp } from '@zettelooo/commons'
import { FunnelServiceGetMessagesSignature, version } from '@zettelooo/server-shared'
import { dateHelpers } from '../../../../../helpers/native/dateHelpers'
import { webConfig } from '../../../../../modules/web-config'

export class FunnelServiceGetMessages {
  private socket?: WebSocket
  private status: FunnelServiceGetMessages.Status
  private serverTimeoutMilliseconds?: number
  private serverTimeout?: any

  constructor(
    private readonly options: {
      onStatusChange(status: FunnelServiceGetMessages.Status): void
      onHeartBeat(currentCorrectedServerTimestamp: Timestamp): void
      onMutationsEvent(
        mutations: readonly FunnelServiceGetMessagesSignature.Mutation[],
        numberOfRemainingMutations: number
      ): void | Promise<void>
      onExtensionUpdate(extensionId: Id): void | Promise<void>
      getAccessToken(): string
      getSequences(): Promise<FunnelServiceGetMessagesSignature.FunnelSequences>
    }
  ) {
    this.status = FunnelServiceGetMessages.Status.ClosedInitially
  }

  getStatus(): FunnelServiceGetMessages.Status {
    return this.status
  }

  start(): void {
    if (
      this.status === FunnelServiceGetMessages.Status.Starting ||
      this.status === FunnelServiceGetMessages.Status.Started
    )
      return

    FunnelServiceGetMessages.developerLog('Opening...')
    this.stopServerTimeout()
    this.socket = new WebSocket(`${webConfig.services.baseUrls.privateApi.ws}/${version}/funnel/get-messages`)
    this.socket.binaryType = 'blob'
    this.setStatus(FunnelServiceGetMessages.Status.Starting)

    const referencedSocket = this.socket

    const startingTimeout = setTimeout(() => {
      if (this.socket !== referencedSocket) return
      FunnelServiceGetMessages.developerLog('Starting timed out.')
      this.socketClose()
      this.setStatus(FunnelServiceGetMessages.Status.ClosedDueToStartingTimeout)
    }, webConfig.timings.funnelApiService.getMessages.startingTimeout)

    this.socket.onopen = event => {
      if (
        this.socket !== referencedSocket ||
        this.status === FunnelServiceGetMessages.Status.ClosedDueToStartingTimeout
      )
        return
      FunnelServiceGetMessages.developerLog('Opened, sending "Start"...')
      this.socket.send(
        formatRequest({
          type: FunnelServiceGetMessagesSignature.RequestMessage.Type.Start,
          accessToken: this.options.getAccessToken(),
          timeout: webConfig.timings.funnelApiService.getMessages.clientTimeout,
          numberOfAllowedUnacknowledge: webConfig.timings.funnelApiService.getMessages.numberOfAllowedUnacknowledged,
          maximumBatchSize: webConfig.timings.funnelApiService.getMessages.maximumBatchSize,
        })
      )
    }

    this.socket.onmessage = async (event: MessageEvent<string>) => {
      if (this.socket !== referencedSocket) return
      const message = parseResponse(event.data)
      switch (message.type) {
        case FunnelServiceGetMessagesSignature.ResponseMessage.Type.Reinitialize: {
          if (this.status === FunnelServiceGetMessages.Status.ClosedDueToStartingTimeout) return
          FunnelServiceGetMessages.developerLog('"Reinitialize" request received.')
          const sequences = await this.options.getSequences()
          if (this.socket !== referencedSocket) return
          FunnelServiceGetMessages.developerLog('Sending "Initialize"...')
          this.socket.send(
            formatRequest({
              type: FunnelServiceGetMessagesSignature.RequestMessage.Type.Initialize,
              sequences,
            })
          )
          break
        }

        case FunnelServiceGetMessagesSignature.ResponseMessage.Type.Started:
          if (this.status === FunnelServiceGetMessages.Status.ClosedDueToStartingTimeout) return
          FunnelServiceGetMessages.developerLog('"Started"!')
          clearTimeout(startingTimeout)
          this.serverTimeoutMilliseconds = message.serverTimeoutMilliseconds
          this.restartServerTimeout()
          this.setStatus(FunnelServiceGetMessages.Status.Started)
          break

        case FunnelServiceGetMessagesSignature.ResponseMessage.Type.Heartbeat:
          FunnelServiceGetMessages.developerLog('"HeartBeat" received.')
          this.restartServerTimeout()
          this.options.onHeartBeat(message.currentCorrectedServerTimestamp)
          this.socket.send(
            formatRequest({
              type: FunnelServiceGetMessagesSignature.RequestMessage.Type.HeartbeatAck,
              sequence: message.sequence,
              currentUncorrectedClientTimestamp: dateHelpers.getCurrentTimestamp(),
            })
          )
          break

        case FunnelServiceGetMessagesSignature.ResponseMessage.Type.Restart:
          FunnelServiceGetMessages.developerLog('"Restart" received.')
          this.socket.send(
            formatRequest({
              type: FunnelServiceGetMessagesSignature.RequestMessage.Type.Close,
            })
          )
          this.socketClose({ delay: true })
          this.stopServerTimeout()
          this.setStatus(FunnelServiceGetMessages.Status.ClosedDueToServerRestartRequest)
          break

        case FunnelServiceGetMessagesSignature.ResponseMessage.Type.MutationBatch:
          FunnelServiceGetMessages.developerLog('"MutationBatch" received:', message)
          this.restartServerTimeout()
          try {
            await this.options.onMutationsEvent(message.mutations, message.numberOfRemainingMutations ?? 0)
            if (this.socket !== referencedSocket) return
            this.socket.send(
              formatRequest({
                type: FunnelServiceGetMessagesSignature.RequestMessage.Type.Ack,
                id: message.id,
              })
            )
          } catch (error) {
            log.error(error)
            captureException(error, { tags: { module: 'funnel service - get messages' } })
            if (this.socket !== referencedSocket) return
            FunnelServiceGetMessages.developerLog('"MutationBatch" is not acknowledged:', message)
            this.socket.send(
              formatRequest({
                type: FunnelServiceGetMessagesSignature.RequestMessage.Type.Nack,
                id: message.id,
              })
            )
          }
          break

        case FunnelServiceGetMessagesSignature.ResponseMessage.Type.UpdateExtension:
          FunnelServiceGetMessages.developerLog('"UpdateExtension" received:', message.extensionId)
          this.restartServerTimeout()
          try {
            await this.options.onExtensionUpdate(message.extensionId)
          } catch (error) {
            log.error(error)
            captureException(error, { tags: { module: 'funnel service - get messages' } })
          }
          break
      }
    }

    this.socket.onclose = event => {
      if (this.socket !== referencedSocket) return
      delete this.socket
      this.stopServerTimeout()
      if (event.wasClean) {
        FunnelServiceGetMessages.developerLog(`Closed clean from server, code=${event.code}, reason="${event.reason}".`)
        this.setStatus(FunnelServiceGetMessages.Status.ClosedByServer)
      } else {
        FunnelServiceGetMessages.developerLog('Closed due to a server or network failure.')
        this.setStatus(FunnelServiceGetMessages.Status.ClosedDueToServerOrNetworkFailure)
      }
    }

    this.socket.onerror = event => {
      if (this.socket !== referencedSocket) return
      delete this.socket
      FunnelServiceGetMessages.developerLog('Error:', event)
      this.stopServerTimeout()
      this.setStatus(FunnelServiceGetMessages.Status.ClosedDueToServerOrNetworkFailure)
    }
  }

  destroy(): void {
    if (
      this.status !== FunnelServiceGetMessages.Status.Starting &&
      this.status !== FunnelServiceGetMessages.Status.Started
    )
      return
    FunnelServiceGetMessages.developerLog('Sending "Destroy"...')
    this.stopServerTimeout()
    this.socket!.send(
      formatRequest({
        type: FunnelServiceGetMessagesSignature.RequestMessage.Type.Destroy,
      })
    )
    this.socketClose({ delay: true })
    FunnelServiceGetMessages.developerLog('Destroyed.')
    this.setStatus(FunnelServiceGetMessages.Status.ClosedAndDestroyedByClient)
  }

  close(): void {
    if (
      this.status !== FunnelServiceGetMessages.Status.Starting &&
      this.status !== FunnelServiceGetMessages.Status.Started
    )
      return
    FunnelServiceGetMessages.developerLog('Sending "Close"...')
    this.stopServerTimeout()
    this.socket!.send(
      formatRequest({
        type: FunnelServiceGetMessagesSignature.RequestMessage.Type.Close,
      })
    )
    this.socketClose({ delay: true })
    FunnelServiceGetMessages.developerLog('Closed.')
    this.setStatus(FunnelServiceGetMessages.Status.ClosedByClient)
  }

  private setStatus(status: FunnelServiceGetMessages.Status): void {
    if (this.status !== status) {
      this.status = status
      this.options.onStatusChange(status)
    }
  }

  private restartServerTimeout(): void {
    const referencedSocket = this.socket!
    this.stopServerTimeout()
    this.serverTimeout = setTimeout(() => {
      if (this.socket !== referencedSocket) return
      FunnelServiceGetMessages.developerLog('Heart beat timed out.')
      this.socket.send(
        formatRequest({
          type: FunnelServiceGetMessagesSignature.RequestMessage.Type.Close,
        })
      )
      this.socketClose({ delay: true })
      delete this.serverTimeout
      this.setStatus(FunnelServiceGetMessages.Status.ClosedDueToHeartBeatTimeout)
    }, this.serverTimeoutMilliseconds)
  }

  private stopServerTimeout(): void {
    if (!this.serverTimeout) return
    clearTimeout(this.serverTimeout)
    delete this.serverTimeout
  }

  private socketClose(options?: { delay?: boolean }): void {
    const { socket } = this
    delete this.socket
    if (socket) {
      if (options?.delay) {
        // We need to delay closing the channel right after sending a message so the server has enough time to receive it:
        setTimeout(() => socket.close(), webConfig.timings.funnelApiService.getMessages.closeRightAfterMessageDelay)
      } else {
        socket.close()
      }
    }
  }

  private static developerLog(...args: any[]): void {
    if (webConfig.developerLogs.webSocket) {
      console.log('[WebSocket:Funnel.GetMessages]', ...args) // eslint-disable-line no-console
    }
  }
}

export namespace FunnelServiceGetMessages {
  export enum Status {
    Starting = 'STARTING',
    Started = 'STARTED',
    ClosedInitially = 'CLOSED_INITIALLY',
    ClosedDueToStartingTimeout = 'CLOSED_DUE_TO_STARTING_TIMEOUT',
    ClosedByServer = 'CLOSED_BY_SERVER',
    ClosedDueToServerRestartRequest = 'CLOSED_DUE_TO_SERVER_RESTART_REQUEST',
    ClosedAndDestroyedByClient = 'CLOSED_AND_DESTROYED_BY_CLIENT',
    ClosedByClient = 'CLOSED_BY_CLIENT',
    ClosedDueToHeartBeatTimeout = 'CLOSED_DUE_TO_HEART_BEAT_TIMEOUT',
    ClosedDueToServerOrNetworkFailure = 'CLOSED_DUE_TO_SERVER_OR_NETWORK_FAILURE',
  }
}

function formatRequest<T extends FunnelServiceGetMessagesSignature.RequestMessage.Type>(
  message: FunnelServiceGetMessagesSignature.RequestMessage<T>
): string {
  return JSON.stringify(message)
}

function parseResponse(message: string): FunnelServiceGetMessagesSignature.ResponseMessage {
  return JSON.parse(message)
}

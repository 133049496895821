import { PartialRecord } from '@zettelooo/commons'
import { useEffect, useState } from 'react'
import { getEnumKeyValues } from '../../../../helpers/getEnumKeyValues'
import { DemoMode } from '../../../../modules/demo-mode'
import { useWindowDev } from '../../../../modules/window-dev'
import { PersistentDatabase } from '../databases'
import { createPersistentOnDatabase } from './createPersistentOnDatabase'
import { createPersistentOnMemory } from './createPersistentOnMemory'
import { Persistent, PersistentAccessor, PersistentKey } from './types'

export function useNewPersistent(persistentDatabase: PersistentDatabase | undefined): {
  persistent: Persistent | undefined
} {
  const [output, setOutput] = useState<ReturnType<typeof useNewPersistent>>({ persistent: undefined }) // We need to wrap this in an object, since a key-value store is a function, the set-state method will treat it in a wrong way

  useEffect(() => {
    if (DemoMode.data) {
      setOutput({ persistent: createPersistentOnMemory() })
    } else if (persistentDatabase) {
      createPersistentOnDatabase(persistentDatabase).then(persistent => setOutput({ persistent }))
    } else {
      setOutput(currentOutput => (currentOutput.persistent ? { persistent: undefined } : currentOutput))
    }
  }, [persistentDatabase])

  useWindowDev(
    () => ({
      persistent: getEnumKeyValues<PersistentKey>(PersistentKey).reduce<
        PartialRecord<string, PersistentAccessor<PersistentKey>>
      >((accessors, { key, value }) => {
        accessors[key] = output.persistent!(value)
        return accessors
      }, {}),
    }),
    [output],
    {
      disabled: !output.persistent,
    }
  )

  return output
}

import { ZettelExtensions } from '@zettelooo/extension-api'
import { HtmlContentTools } from '../../../../../../../../../modules/HtmlContentTools'
import { Message } from './Message'

export function ExtendedMessage({
  message,
}: {
  message: HtmlContentTools.WithReferenceKey<ZettelExtensions.LifeSpan.Shared.PagePanel.Message<any>>
}) {
  const { containerRefCallback } = HtmlContentTools.useRender(message)

  return (
    <Message
      containerProps={{ ref: containerRefCallback }}
      variant={message.variant} // TODO: Structurally, this kind of needs a converter
      onClose={message.onClose}
    />
  )
}

import { PropsWithChildren } from 'react'
import { SmartCommand } from '../../../modules/SmartCommand'
import { Panel, useOpenMainPage } from './pages/MainPage'

export function SmartCommandApiProvider({ children }: PropsWithChildren<{}>) {
  const { openMainPageStatic } = useOpenMainPage()

  const { smartCommandApi } = SmartCommand.useApiBuilder(
    {},
    contentRef => ({
      openPage: {
        signatures: [
          '(pageId: string, options?: { highlightedCardId?: string; }): void // Opens the specified page, options.highlightedCardId is optionally the ID of the highlighting card in the page',
        ],
        function(pageId: string, options?: { highlightedCardId?: string }): void {
          openMainPageStatic({
            type: Panel.Type.Page,
            pageId,
            cardId: options?.highlightedCardId,
          })
        },
      },
    }),
    []
  )

  return <SmartCommand.ApiProvider api={smartCommandApi}>{children}</SmartCommand.ApiProvider>
}

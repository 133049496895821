import { useTheme } from '@material-ui/core'
import { useCallback } from 'react'
import { createPageCoverSuggestions } from '../../helpers/createPageCoverSuggestions'
import { NewPage } from './types'

// TODO: Temporary solution, remove it later:

export function usePageCoverSuggestions(): {
  makePageCoverSuggestions(newPage: NewPage): NewPage
} {
  const theme = useTheme()

  return {
    makePageCoverSuggestions: useCallback(
      newPage => {
        if (newPage.name.length === 0) return newPage

        const pageCoverSuggestions = createPageCoverSuggestions(theme)

        return {
          ...newPage,
          iconEmoji: newPage.iconEmoji || pageCoverSuggestions.iconEmoji,
          color: newPage.color || pageCoverSuggestions.color,
        }
      },
      [theme]
    ),
  }
}

import { Keyboard } from '@zettelooo/commons'
import { NavigationDirection } from '../types'

export function convertKeyboardCodeToDirection(code: Keyboard.NativeEventCode): NavigationDirection {
  if (code === 'ArrowUp') return 'up'
  if (code === 'ArrowDown') return 'down'
  if (code === 'ArrowLeft') return 'left'
  if (code === 'ArrowRight') return 'right'

  throw Error('Invalid arrow key code.')
}

import { useEffect, useMemo } from 'react'
import { Broadcaster } from '../../../../modules/service-worker'
import { createDatabases } from './createDatabases'
import { Database } from './Database'
import { Databases } from './types'

export function useNewDatabases(broadcaster: Broadcaster | undefined): {
  databases: Databases | undefined
} {
  const databases = useMemo(() => broadcaster && createDatabases(broadcaster), [broadcaster])

  useEffect(
    () => () => {
      if (databases) {
        const databasesArray = Object.values(databases) as Database[]
        databasesArray.forEach(database => database.dispose())
      }
    },
    [databases]
  )

  return {
    databases,
  }
}

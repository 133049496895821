import { Avatar, makeStyles, Typography, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { useHumanizedTimestamp } from '../../../../../../../../../modules/date-time'
import { CustomAvatar } from '../../../../../../../../CustomAvatar'
import { Initializer } from '../../../../../../Initializer'
import { TextHighlighter } from '../../../../../../TextHighlighter'
import { CompromiseResultType, SearchResultItem } from '../types'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      height: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      overflow: 'hidden',
      cursor: 'pointer',
      userSelect: 'none',
    },
    nlpResult: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    cardText: {
      flexGrow: 1,
      // width: 0,
      overflow: 'hidden',
    },
    avatarWrapper: {
      width: theme.spacing(4),
      display: 'flex',
      justifyContent: 'center',
      flex: 'none',
    },
    avatar: {
      color: theme.palette.primary.main,
      width: theme.spacing(5.25),
      height: theme.spacing(5.25),
    },
  }),
  { name: 'CardSearchResultItem' }
)

export function CardSearchResultItem({
  item,
  tokens,
}: {
  item: SearchResultItem<SearchResultItem.Type.CardSearchResult>
  tokens: string[]
}) {
  const { allPages } = useContexts(Initializer.Contexts)

  const itemCardUpdatedAt = useHumanizedTimestamp(item.cardSearchResult.card.updatedAt, 'date only')

  const theme = useTheme()

  const classes = useStyles()

  const page = allPages.dictionary[item.cardSearchResult.card.pageId]

  return item.cardSearchResult.compromiseResult ? (
    <div className={classes.root}>
      <div className={classes.nlpResult}>
        <Typography variant="subtitle2" display="block" className={classes.cardText}>
          {item.cardSearchResult.compromiseResult.value}
        </Typography>
        <Typography variant="caption" color="textSecondary" display="block" className={classes.cardText}>
          {itemCardUpdatedAt}&nbsp;.&nbsp;
          {item.cardSearchResult.compromiseResult.type === CompromiseResultType.AtMention
            ? 'mention'
            : item.cardSearchResult.compromiseResult.type === CompromiseResultType.Email
            ? 'email'
            : item.cardSearchResult.compromiseResult.type === CompromiseResultType.HashTag
            ? 'hash tag'
            : item.cardSearchResult.compromiseResult.type === CompromiseResultType.Organization
            ? 'organization'
            : item.cardSearchResult.compromiseResult.type === CompromiseResultType.Person
            ? 'person'
            : item.cardSearchResult.compromiseResult.type === CompromiseResultType.PhoneNumber
            ? 'tel'
            : item.cardSearchResult.compromiseResult.type === CompromiseResultType.Place
            ? 'place'
            : '?'}
        </Typography>
      </div>

      <Avatar
        className={classNames(classes.avatar)}
        style={{
          backgroundColor: page?.color || theme.palette.background.default,
        }}
      >
        <CustomIcon
          name={
            item.cardSearchResult.compromiseResult.type === CompromiseResultType.AtMention
              ? 'AtSign'
              : item.cardSearchResult.compromiseResult.type === CompromiseResultType.Email
              ? 'Mail'
              : item.cardSearchResult.compromiseResult.type === CompromiseResultType.HashTag
              ? 'HashTag'
              : item.cardSearchResult.compromiseResult.type === CompromiseResultType.Organization
              ? 'SuitCase'
              : item.cardSearchResult.compromiseResult.type === CompromiseResultType.Person
              ? 'User'
              : item.cardSearchResult.compromiseResult.type === CompromiseResultType.PhoneNumber
              ? 'Tel'
              : item.cardSearchResult.compromiseResult.type === CompromiseResultType.Place
              ? 'Send2'
              : 'Send2'
          }
          color={theme.palette.getContrastText(page?.color || theme.palette.background.default)}
        />
      </Avatar>
    </div>
  ) : page ? (
    <div className={classes.root}>
      <div className={classes.avatarWrapper}>
        <CustomAvatar
          size={3}
          compact
          avatarFileId={page.avatarFileId}
          name={page.name}
          emoji={page.iconEmoji}
          color={page.color || theme.palette.action.disabledBackground}
        />
      </div>

      <Typography variant="subtitle2" display="block" className={classes.cardText}>
        <TextHighlighter tokens={tokens} range={20}>
          {item.cardSearchResult.text}
        </TextHighlighter>
      </Typography>
    </div>
  ) : null
}

import { useTheme } from '@material-ui/core'
import { ComponentRef, CSSProperties, forwardRef, useMemo } from 'react'
import { merge } from '../../helpers/merge'
import { icons } from './icons'
import { SvgComponent } from './SvgComponent'

export const CustomIcon = merge({ names: Object.keys(icons) as readonly CustomIcon.Name[] } as const, () =>
  forwardRef<
    ComponentRef<typeof SvgComponent>,
    {
      name: CustomIcon.Name
      size?: 'extra small' | 'small' | 'medium' | 'large' | number // TODO: Apparently, we need some size for 2.5 (maybe the medium itself)
      sizeOn?: 'width' | 'height' | 'width & height'
      rectangle?: boolean
      color?:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'primary.contrastText'
        | 'secondary.contrastText'
        | 'text.primary'
        | 'text.secondary'
        | 'text.disabled'
        | 'text.hint'
        | (string & {})
      className?: string
      style?: CSSProperties
    }
  >(function CustomIcon(
    { name, size = 'medium', sizeOn = 'width & height', rectangle, color = 'inherit', className, style },
    ref
  ) {
    const theme = useTheme()

    const sizePixels = useMemo(
      () =>
        theme.spacing(
          size === 'extra small' ? 1.5 : size === 'small' ? 2 : size === 'medium' ? 3 : size === 'large' ? 4 : size
        ),
      [size, theme]
    )

    const applyOnWidth = sizeOn !== 'height'
    const applyOnHeight = sizeOn !== 'width'

    const colorCode = useMemo(
      () =>
        color === 'inherit'
          ? 'currentColor'
          : color === 'primary'
          ? theme.palette.primary.main
          : color === 'secondary'
          ? theme.palette.secondary.main
          : color === 'primary.contrastText'
          ? theme.palette.primary.contrastText
          : color === 'secondary.contrastText'
          ? theme.palette.secondary.contrastText
          : color === 'text.primary'
          ? theme.palette.text.primary
          : color === 'text.secondary'
          ? theme.palette.text.secondary
          : color === 'text.disabled'
          ? theme.palette.text.disabled
          : color === 'text.hint'
          ? theme.palette.text.hint
          : color,
      [color, theme]
    )

    return icons[name]({
      theme,
      color: colorCode,
      svgComponentProps: {
        ref,
        className,
        style,
        sizePixels,
        applyOnWidth,
        applyOnHeight,
        square: !rectangle,
      },
    })
  })
)

export namespace CustomIcon {
  export type Name = keyof typeof icons
}

import { Writable } from '@zettelooo/commons'
import { DependencyList, RefCallback, useCallback, useRef } from 'react'

export function useRefEffect<T = HTMLElement>(
  effect: (instance: T | null) => void | (() => void),
  dependencies: DependencyList
): useRefEffect.Connect<T> {
  const cleanUpRef = useRef<void | (() => void)>(undefined)

  const refCallback = useCallback<RefCallback<T>>(instance => {
    cleanUpRef.current?.()
    const writableConnect = connect as Writable<useRefEffect.Connect<T>>
    writableConnect.current = instance
    cleanUpRef.current = effect(instance)
  }, dependencies)

  const connect = refCallback as useRefEffect.Connect<T>

  return connect
}

export namespace useRefEffect {
  export type Connect<T = HTMLElement> = RefCallback<T> & {
    readonly current: T | null
  }
}

import { useCallback } from 'react'
import { useAppHistoryControl } from '../../../../../../modules/app-history'
import { getPanelPath } from './getPanelPath'
import { Panel } from './Panel'

// TODO: The signature is not the best it could be, specially the combination of selectedSpaceId and keepCurrentSelectedSpace, do something about it:
export function useSwitchPanel(): {
  switchPanelStatic(
    panel: Panel | undefined,
    options?: {
      readonly replaceHistoryState?: boolean
    }
  ): void
} {
  const { appHistoryControlStatic } = useAppHistoryControl()

  return {
    switchPanelStatic: useCallback((panel, options) => {
      const panelPath = getPanelPath(panel)
      if (options?.replaceHistoryState) {
        appHistoryControlStatic.replace(panelPath)
      } else {
        appHistoryControlStatic.push(panelPath)
      }
    }, []),
  }
}

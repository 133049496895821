import { app } from './app'
import { developmentOptions } from './developmentOptions'
import { environment } from './environment'

const liveServer = environment.target === 'live' || developmentOptions.connectToLiveServer
const localTarget = environment.target === 'local' && !liveServer
const subdomainPostfix = environment.target === 'stage' && !liveServer ? '-stage' : ''

export const services = {
  baseUrls: {
    api: {
      rest: localTarget ? 'http://localhost:5001' : `https://api${subdomainPostfix}.${app.domain}`,
      ws: localTarget ? 'ws://localhost:5001' : `wss://api${subdomainPostfix}.${app.domain}`,
    },

    privateApi: {
      rest: localTarget ? 'http://localhost:5002' : `https://papi${subdomainPostfix}.${app.domain}`,
      ws: localTarget ? 'ws://localhost:5002' : `wss://papi${subdomainPostfix}.${app.domain}`,
    },

    upload: localTarget ? 'http://localhost:5003' : `https://upload${subdomainPostfix}.${app.domain}`,

    download: localTarget ? 'http://localhost:5004' : `https://download${subdomainPostfix}.${app.domain}`,

    preview: localTarget ? 'http://localhost:5005' : `https://preview${subdomainPostfix}.${app.domain}`,
  },
} as const

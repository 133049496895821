import { Button, Input, InputAdornment, makeStyles } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { useGetSet } from 'react-use'
import { useRefWrap } from '../../../../../../../../../../../../../hooks/useRefWrap'
import { sendAnalyticEvent } from '../../../../../../../../../../../../../modules/analytics'
import { NavigableWithCommands } from '../../../../../../../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../../../../../../../modules/contexts'
import { commonKeyboardCombinations } from '../../../../../../../../../../../../../modules/keyboard-handler'
import { Navigable, NavigableStatus } from '../../../../../../../../../../../../../modules/navigation'
import { useApplyAction } from '../../../../../../../../../../../hooks/useApplyAction'
import { AccountData } from '../../../../../../../../../modules/account-data'
import { FieldPaper } from '../../FieldPaper'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(1),
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    usernameInput: {
      ...theme.typography.body2,
    },
  }),
  { name: 'UsernameField' }
)

export function UsernameField({ className }: { className?: string }) {
  const { account } = useContexts(AccountData.Contexts)

  const [getUserName, setUserName] = useGetSet(account.userName)
  const [getIsEditing, setIsEditing] = useGetSet(false)
  const [getIsSubmitting, setIsSubmitting] = useGetSet(false)

  const { applyActionStatic } = useApplyAction()

  const submitRef = useRefWrap(async (): Promise<void> => {
    if (getUserName() !== account.userName) {
      setIsSubmitting(true)
      sendAnalyticEvent('User', 'Update username')
      await applyActionStatic.updateModel({
        type: Model.Type.Account,
        id: account.id,
        userName: getUserName(),
      })
      setIsSubmitting(false)
    }
    setIsEditing(false)
  })

  const cancelRef = useRefWrap((): void => {
    setIsEditing(false)
    setUserName(account.userName)
  })

  const classes = useStyles()

  return (
    <Navigable>
      {({ connectNavigable: connectRoot }) => (
        <FieldPaper ref={connectRoot} className={classNames(classes.root, className)}>
          <NavigableWithCommands
            selectable
            focusable
            disabled={!getIsEditing()}
            commandGroup={({ navigableRef }) => ({
              name: 'usernameField',
              displayName: 'Username field',
              disabled() {
                return getIsSubmitting() || !getIsEditing()
              },
              commands: [
                {
                  name: 'submit',
                  displayName: 'Submit',
                  disabled() {
                    return getUserName() === account.userName
                  },
                  defaultShortcutKeys: commonKeyboardCombinations.enter,
                  handler() {
                    submitRef.current()
                  },
                },
                {
                  name: 'cancel',
                  displayName: 'Cancel',
                  disabled() {
                    return navigableRef.current?.navigableStatus === NavigableStatus.Selected
                  },
                  defaultShortcutKeys: commonKeyboardCombinations.escape,
                  handler() {
                    cancelRef.current()
                  },
                },
              ],
            })}
            commandGroupDependencies={[account]}
          >
            {({ connectNavigable, connectFocusable }) => (
              <Input
                ref={connectNavigable}
                inputRef={connectFocusable}
                disableUnderline
                fullWidth
                autoFocus
                className={classes.usernameInput}
                placeholder="username"
                startAdornment={<InputAdornment position="start">@</InputAdornment>}
                readOnly={!getIsEditing()}
                disabled={getIsSubmitting()}
                value={getUserName()}
                onChange={event => setUserName(event.target.value)}
              />
            )}
          </NavigableWithCommands>

          <div className={classes.actions}>
            {getIsEditing() ? (
              <>
                <Navigable>
                  {({ connectNavigable }) => (
                    <Button
                      ref={connectNavigable}
                      size="small"
                      disabled={getIsSubmitting()}
                      onClick={cancelRef.current}
                    >
                      Cancel
                    </Button>
                  )}
                </Navigable>

                <Navigable>
                  {({ connectNavigable }) => (
                    <Button
                      ref={connectNavigable}
                      color="secondary"
                      size="small"
                      disabled={getIsSubmitting()}
                      onClick={submitRef.current}
                    >
                      Confirm
                    </Button>
                  )}
                </Navigable>
              </>
            ) : (
              <>
                <Navigable>
                  {({ connectNavigable }) => (
                    <Button
                      ref={connectNavigable}
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setUserName(account.userName)
                        setIsEditing(true)
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </Navigable>
              </>
            )}
          </div>
        </FieldPaper>
      )}
    </Navigable>
  )
}

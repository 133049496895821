import { WindowType } from '@zettelooo/desktop-shared'
import { useEffect, useState } from 'react'
import { useMountedState } from 'react-use'
import { typed } from '../../../../helpers/typed'
import { DemoMode } from '../../../../modules/demo-mode'
import { PeekMode } from '../../../../modules/peek-mode'
import { webConfig } from '../../../../modules/web-config'
import { ServiceWorkerMessageType } from '../service-worker'
import { getNextIncrementalId } from './getNextIncrementalId'
import { SuperWindowClientServiceWorkerMessage, SuperWindowClientServiceWorkerMessageSubType } from './types'

const SET_IS_SUPER_TIMEOUT = 1 * 1000 // Increase to have less possibly glitching super window clients; Decrease to have less initial synchronization time
const CLAIM_TIMEOUT_BASE = 17 * 1000 // Increase to have less performance hit and unnecessary service worker messagings; Decrease to have less sync-engine inactive time when the current super window client is not accessible anymore

export function useDetector({ disabled }: { disabled: boolean }): { isSuper: boolean } {
  const [isSuper, setIsSuper] = useState(false)
  const [windowClientIncrementalId, setWindowClientIncerementalId] = useState<bigint>()

  useEffect(() => {
    if (disabled) {
      setIsSuper(false)
      setWindowClientIncerementalId(undefined)
    } else if (webConfig.environment.agent === 'electron' || DemoMode.data || PeekMode.data) {
      setIsSuper(webConfig.environment.agent !== 'electron' || window.electronProxy?.windowType === WindowType.Main)
    } else {
      getNextIncrementalId(setWindowClientIncerementalId)
    }
  }, [disabled])

  const isMutated = useMountedState()

  useEffect(() => {
    if (disabled || !windowClientIncrementalId) {
      setWindowClientIncerementalId(undefined)
      return
    }

    let toBeSuperTimeout: any = null
    let claimTimeout: any = null
    let unloaded = false

    setToBeSuper(true)

    void (function claim(): void {
      claimTimeout = setTimeout(claim, CLAIM_TIMEOUT_BASE + Math.random() * 2 * 1000)
      window.navigator.serviceWorker.controller?.postMessage(
        typed<SuperWindowClientServiceWorkerMessage<SuperWindowClientServiceWorkerMessageSubType.Claim>>({
          type: ServiceWorkerMessageType.SuperWindowClient,
          subType: SuperWindowClientServiceWorkerMessageSubType.Claim,
          initiatorIncrementalId: windowClientIncrementalId,
          claimingIncrementalId: windowClientIncrementalId,
        })
      )
    })()

    // Since the `pagehide` event is more relieble than `unload`, see: https://developer.chrome.com/blog/page-lifecycle-api/#the-unload-event
    const unloadEvent = 'onpagehide' in window ? 'pagehide' : 'unload'

    window.addEventListener(unloadEvent, handleUnload)
    window.navigator.serviceWorker.addEventListener('message', handleMessage)

    return () => {
      toBeSuperTimeout && clearTimeout(toBeSuperTimeout)
      claimTimeout && clearTimeout(claimTimeout)

      window.removeEventListener(unloadEvent, handleUnload)
      window.navigator.serviceWorker.removeEventListener('message', handleMessage)
    }

    function handleUnload(): void {
      unloaded = true
      getNextIncrementalId(nextIncrementalId => {
        window.navigator.serviceWorker.controller?.postMessage(
          typed<SuperWindowClientServiceWorkerMessage<SuperWindowClientServiceWorkerMessageSubType.Claim>>({
            type: ServiceWorkerMessageType.SuperWindowClient,
            subType: SuperWindowClientServiceWorkerMessageSubType.Claim,
            initiatorIncrementalId: nextIncrementalId,
            claimingIncrementalId: nextIncrementalId,
          })
        )
      })
    }

    function handleMessage(event: MessageEvent): void {
      if (unloaded) return
      if (event.data?.type !== ServiceWorkerMessageType.SuperWindowClient) return

      const message: SuperWindowClientServiceWorkerMessage = event.data
      switch (message.subType) {
        case SuperWindowClientServiceWorkerMessageSubType.Claim:
          {
            const outdated = message.initiatorIncrementalId < windowClientIncrementalId!
            if (outdated) break

            const redundant = message.claimingIncrementalId === windowClientIncrementalId!
            if (redundant) break

            const outranked = message.claimingIncrementalId < windowClientIncrementalId!
            if (outranked) {
              setToBeSuper(false)
              break
            }

            const objection = message.claimingIncrementalId > windowClientIncrementalId!
            if (objection) {
              setToBeSuper(true)
              window.navigator.serviceWorker.controller?.postMessage(
                typed<SuperWindowClientServiceWorkerMessage<SuperWindowClientServiceWorkerMessageSubType.Claim>>({
                  type: ServiceWorkerMessageType.SuperWindowClient,
                  subType: SuperWindowClientServiceWorkerMessageSubType.Claim,
                  initiatorIncrementalId: message.initiatorIncrementalId,
                  claimingIncrementalId: windowClientIncrementalId!,
                })
              )
              break
            }
          }
          break
      }
    }

    function setToBeSuper(toBeSuper: boolean): void {
      if (toBeSuper) {
        toBeSuperTimeout =
          toBeSuperTimeout ||
          setTimeout(() => {
            if (isMutated()) {
              setIsSuper(true)
            }
          }, SET_IS_SUPER_TIMEOUT)
      } else if (toBeSuperTimeout) {
        clearTimeout(toBeSuperTimeout)
        toBeSuperTimeout = null
        setIsSuper(false)
      }
    }
  }, [disabled, windowClientIncrementalId])

  return {
    isSuper,
  }
}

import { Span } from '@sentry/react'
import { AccountServiceSignature } from '@zettelooo/server-shared'
import { DemoMode } from '../../../../../modules/demo-mode'
import { PeekMode } from '../../../../../modules/peek-mode'
import { webConfig } from '../../../../../modules/web-config'
import { Persistent, PersistentKey } from '../../persistent'
import { RestService } from './RestService'

export class AccountService extends RestService {
  constructor(persistent: Persistent) {
    super('account', persistent)
  }

  async googleSignInGetAuthLink(popupWindow: boolean): Promise<{ signInLink: string }> {
    if (DemoMode.data || PeekMode.data) return { signInLink: '' } // Not going to be called ever

    const { signInLink } = await this.requestUnauthenticated<
      AccountServiceSignature.GoogleSignInGetAuthLink.Request,
      AccountServiceSignature.GoogleSignInGetAuthLink.Response
    >('google-sign-in-get-auth-link', {
      deviceId: this.persistent(PersistentKey.DeviceId).get(),
      agentEnvironment: webConfig.environment.agent,
      popupWindow,
    })

    return {
      signInLink,
    }
  }

  async walletSignInAuthenticate(walletAddress: string, span?: Span): Promise<{ nonceMessage?: string }> {
    if (DemoMode.data || PeekMode.data) return {} // Not going to be called ever

    const { nonceMessage } = await this.requestUnauthenticated<
      AccountServiceSignature.WalletSignInAuthenticate.Request,
      AccountServiceSignature.WalletSignInAuthenticate.Response
    >('wallet-sign-in-authenticate', { walletAddress }, span)

    return {
      nonceMessage,
    }
  }

  async walletSignInVerigy(
    walletAddress: string,
    nonceMessage: string,
    signedNonceMessage: string,
    span?: Span
  ): Promise<{ userTokens: AccountServiceSignature.UserTokens }> {
    if (DemoMode.data || PeekMode.data) return { userTokens: { accessToken: '', refreshToken: '' } } // Not going to be called ever

    const { userTokens } = await this.requestUnauthenticated<
      AccountServiceSignature.WalletSignInVerify.Request,
      AccountServiceSignature.WalletSignInVerify.Response
    >(
      'wallet-sign-in-verify',
      {
        deviceId: this.persistent(PersistentKey.DeviceId).get(),
        walletAddress,
        nonceMessage,
        signedNonceMessage,
      },
      span
    )

    return {
      userTokens,
    }
  }

  async getAccountStatus(
    accessToken: string,
    span?: Span
  ): Promise<{ accountStatus: AccountServiceSignature.AccountStatus }> {
    if (DemoMode.data || PeekMode.data) return { accountStatus: { neededFields: [], subscription: 'normal' } } // Not going to be called ever

    const { accountStatus } = await this.requestUnauthenticated<
      AccountServiceSignature.GetAccountStatus.Request,
      AccountServiceSignature.GetAccountStatus.Response
    >('get-account-status', { accessToken }, span)

    return {
      accountStatus,
    }
  }

  async editAccount(
    accessToken: string,
    neededValues: AccountServiceSignature.AccountStatus.NeededValues,
    span?: Span
  ): Promise<{
    neededValuesErrors?: AccountServiceSignature.AccountStatus.NeededValuesErrors
  }> {
    if (DemoMode.data || PeekMode.data) return {} // Not going to be called ever

    const { neededValuesErrors } = await this.requestUnauthenticated<
      AccountServiceSignature.EditAccount.Request,
      AccountServiceSignature.EditAccount.Response
    >(
      'edit-account',
      {
        accessToken,
        neededValues,
      },
      span
    )

    return {
      neededValuesErrors,
    }
  }

  async getSubscriptionLink(
    accessToken: string,
    refreshToken: string,
    agentEnvironment?: 'browser' | 'electron',
    span?: Span
  ): Promise<{ subscriptionLinkUrl: string | null }> {
    if (DemoMode.data || PeekMode.data) return { subscriptionLinkUrl: '' } // Not going to be called ever

    const { subscriptionLinkUrl } = await this.requestUnauthenticated<
      AccountServiceSignature.GetSubscriptionLink.Request,
      AccountServiceSignature.GetSubscriptionLink.Response
    >(
      'get-subscription-link',
      {
        agentEnvironment: agentEnvironment ?? webConfig.environment.agent,
        accessToken,
        refreshToken,
      },
      span
    )

    return {
      subscriptionLinkUrl,
    }
  }

  async getCustomerPortalLink(redirectBackUrl: string, span?: Span): Promise<{ customerPortalLinkUrl: string | null }> {
    if (DemoMode.data || PeekMode.data) return { customerPortalLinkUrl: '' } // Not going to be called ever

    const { customerPortalLinkUrl } = await this.requestAuthenticated<
      AccountServiceSignature.GetCustomerPortalLink.Request,
      AccountServiceSignature.GetCustomerPortalLink.Response
    >('get-customer-portal-link', { redirectBackUrl }, span)

    return {
      customerPortalLinkUrl,
    }
  }

  async wipeOut(): Promise<void> {
    if (webConfig.environment.target === 'live') return

    if (DemoMode.data || PeekMode.data) return

    await this.requestAuthenticated<AccountServiceSignature.WipeOut.Request, AccountServiceSignature.WipeOut.Response>(
      'wipe-out',
      {}
    )
  }
}

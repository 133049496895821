import { KeyValueEngine } from './engine'
import { ValueAccessor } from './ValueAccessor'
import { KeyValueStore, KeyValueStoreTypeBase } from './types'

export function createKeyValueStore<KeyValueStoreType extends KeyValueStoreTypeBase>(
  engine: KeyValueEngine<KeyValueStoreType>
): KeyValueStore<KeyValueStoreType> {
  const keys = engine.getAllKeys()
  const accessors = keys.reduce((developingAccessors, key) => {
    developingAccessors[key] = new ValueAccessor(engine, key)
    return developingAccessors
  }, {} as Record<keyof KeyValueStoreType, ValueAccessor<KeyValueStoreType, keyof KeyValueStoreType>>)

  function keyValueStoreFunction<K extends keyof KeyValueStoreType>(key: K): ValueAccessor<KeyValueStoreType, K> {
    if (keys.includes(key)) return accessors[key] as ValueAccessor<KeyValueStoreType, K>
    throw Error(`Key-value store invalid key: ${key as string}`)
  }

  keyValueStoreFunction.reset = function keyValueStoreReset(
    scope?: Readonly<Record<keyof KeyValueStoreType, boolean>>
  ): void {
    const filteredKeys = scope ? keys.filter(key => scope[key]) : keys
    filteredKeys.forEach(key => keyValueStoreFunction(key).reset())
  }
  keyValueStoreFunction.asObject = function keyValueStoreAsObject(): KeyValueStoreType {
    return keys.reduce((object, key) => {
      object[key] = keyValueStoreFunction(key).get()
      return object
    }, {} as KeyValueStoreType)
  }

  const keyValueStore = new Proxy(keyValueStoreFunction as KeyValueStore<KeyValueStoreType>, {
    get(keyValueStoreFunctionTarget: KeyValueStore<KeyValueStoreType>, key: any): any {
      if (keys.includes(key)) return keyValueStoreFunctionTarget(key).get()
      return keyValueStoreFunctionTarget[key]
    },

    set(keyValueStoreFunctionTarget: KeyValueStore<KeyValueStoreType>, key: any, value: any): boolean {
      keyValueStoreFunctionTarget(key).set(value)
      return true
    },

    deleteProperty(keyValueStoreFunctionTarget: KeyValueStore<KeyValueStoreType>, key: any): boolean {
      keyValueStoreFunctionTarget(key).reset()
      return true
    },
  })

  return keyValueStore
}

import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { CustomIcon } from '../../../../../../modules/custom-icon'
import { NavigationArea } from '../../../../../../modules/navigation'
import { Gap } from '../../../../../Gap'
import { useApplyAction } from '../../../../hooks/useApplyAction'
import { ExtensionRolesProvider } from '../../../../modules/extension'
import { PersistentKey, usePersistent } from '../../../../modules/persistent'
import { Tidio } from '../../../../modules/tidio'
import { DialogTransition } from '../../components/DialogTransition'
import { CardComposer } from './CardComposer'

const useStyles = makeStyles(
  theme => ({
    content: {
      padding: 0,
    },
    contentFullScreen: {
      '& $cardComposer': {
        maxHeight: '100%',
      },
    },
    contentNotFullScreen: {
      '& $cardComposer': {
        maxHeight: `max(100px, calc(100vh - ${theme.spacing(20)}px))`,
      },
    },
    cardComposer: {
      height: '100%',
    },
  }),
  { name: 'CardEditorDialog' }
)

export function CardEditorDialog({
  page,
  card,
  onClose,
  onSubmitStarted,
  onSubmitFinished,
}: {
  page: Model.Page
  card: Model.Card
  onClose(): void
  onSubmitStarted?(): void
  onSubmitFinished?(): void
}) {
  const {
    persistent,
    currentValues: [isCardDialogFullScreen],
  } = usePersistent(PersistentKey.IsCardDialogFullScreen)

  const { applyActionStatic } = useApplyAction()

  const classes = useStyles()

  return (
    <ExtensionRolesProvider role="CardEditorDialog">
      <NavigationArea>
        <Tidio.Control hide />
        <Dialog
          fullWidth
          fullScreen={isCardDialogFullScreen}
          TransitionComponent={DialogTransition}
          open
          onClose={onClose}
        >
          <DialogTitle>
            <Gap grow />
            {isCardDialogFullScreen ? (
              <IconButton onClick={() => persistent(PersistentKey.IsCardDialogFullScreen).set(false)}>
                <CustomIcon name="Collapse" size="small" />
              </IconButton>
            ) : (
              <IconButton onClick={() => persistent(PersistentKey.IsCardDialogFullScreen).set(true)}>
                <CustomIcon name="Expand" size="small" />
              </IconButton>
            )}
            <IconButton onClick={onClose}>
              <CustomIcon name="Close" size={2.5} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={classNames(
              classes.content,
              isCardDialogFullScreen ? classes.contentFullScreen : classes.contentNotFullScreen
            )}
          >
            <CardComposer
              className={classes.cardComposer}
              page={page}
              card={card}
              onSubmit={async value => {
                try {
                  onSubmitStarted?.()
                  await applyActionStatic.updateModel({
                    type: Model.Type.Card,
                    id: card.id,
                    dataDictionary: value,
                  })
                  onClose()
                } finally {
                  onSubmitFinished?.()
                }
              }}
              onCancel={onClose}
              contentMaxWidthUnits={isCardDialogFullScreen ? 120 : undefined}
            />
          </DialogContent>
        </Dialog>
      </NavigationArea>
    </ExtensionRolesProvider>
  )
}

import { useCallback, useContext } from 'react'
import { CommandsContext } from '../CommandsProvider'
import { CommandGroup } from '../types'

export function useCommandGroupManualRegistration(): {
  registerCommandGroup(commandGroup: CommandGroup): string
  unregisterCommandGroup(registrationKey: string): void
} {
  const { commandManager } = useContext(CommandsContext)

  return {
    registerCommandGroup: useCallback(
      commandGroup => {
        const registrationKey = commandManager.registerCommandGroup(commandGroup.name)
        commandManager.setCommandGroupRegistration(registrationKey, commandGroup)
        return registrationKey
      },
      [commandManager]
    ),

    unregisterCommandGroup: useCallback(
      registrationKey => {
        commandManager.clearCommandGroupRegistration(registrationKey) // This one is not really required!
        commandManager.unregisterCommandGroup(registrationKey)
      },
      [commandManager]
    ),
  }
}

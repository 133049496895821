import { Span } from '@sentry/react'
import { delay } from '@zettelooo/commons'
import { useEffect, useState } from 'react'
import { useMountedState } from 'react-use'
import { useAsyncEffect } from '../../../../../../../hooks/useAsyncEffect'
import { useServices } from '../../../../../modules/services'
import { FinishSignIn } from './useFinishSignIn'
import { Query } from './useQuery'

export interface SubscriptionCheckState {
  readonly status:
    | 'none'
    | 'show plans'
    | 'normal plan selected'
    | 'getting subscription link'
    | 'failed to get subscription link'
    | 'subscribed'
    | 'subscription canceled'
  readonly selectPlan: (plan: 'normal') => void
}

export interface SubscriptionCheckControl {
  readonly subscribe: (accessToken: string, refreshToken: string, span?: Span) => Promise<{ canceled: boolean }>
}

export function useSubscriptionCheck(
  query: Query,
  finishSignIn: FinishSignIn
): {
  subscriptionCheckState: SubscriptionCheckState
  subscriptionCheckControl: SubscriptionCheckControl
} {
  const [subscribeContent, setSubscribeContent] = useState<{
    readonly resolve: (value: { canceled: boolean }) => void | Promise<void>
    readonly accessToken: string
    readonly refreshToken: string
    readonly span: Span | undefined
  }>()
  const [status, setStatus] = useState<SubscriptionCheckState['status']>(() =>
    query.paymentStatus === 'success'
      ? 'subscribed'
      : query.paymentStatus === 'cancel'
      ? 'subscription canceled'
      : 'none'
  )

  const { services } = useServices()

  const isMounted = useMountedState()

  useAsyncEffect(async () => {
    switch (query.paymentStatus) {
      case 'none':
        if (!subscribeContent) break
        setStatus('show plans')
        break

      case 'success':
        if (!query.accessToken || !query.refreshToken) break
        await delay(1 * 1000) // To let the success message be displayed
        finishSignIn(query.accessToken, query.refreshToken)
        break

      case 'cancel':
        // Do nothing!
        break
    }
  }, [query, subscribeContent])

  useAsyncEffect(async () => {
    switch (status) {
      case 'normal plan selected':
        try {
          if (!subscribeContent) break
          setStatus('getting subscription link')
          const { subscriptionLinkUrl } = await services.account.getSubscriptionLink(
            subscribeContent.accessToken,
            subscribeContent.refreshToken,
            query.rawQuery.agent,
            subscribeContent.span
          )
          if (!isMounted()) break
          if (subscriptionLinkUrl) {
            // In this point we're always on the browser, not the electron agent environment:
            window.location.href = subscriptionLinkUrl
          } else {
            setStatus('failed to get subscription link')
          }
        } catch (error) {
          log.error(error)
          if (!isMounted()) break
          setStatus('failed to get subscription link')
        }
        break
    }
  }, [subscribeContent, status])

  return {
    subscriptionCheckState: {
      status,

      selectPlan(plan) {
        setStatus(current => (plan === 'normal' ? 'normal plan selected' : current))
      },
    },

    subscriptionCheckControl: {
      subscribe(accessToken, refreshToken, span?) {
        return new Promise(resolve => {
          setSubscribeContent({ resolve, accessToken, refreshToken, span })
        })
      },
    },
  }
}

export type AsyncActionState<S extends AsyncActionState.Status = AsyncActionState.Status, E = unknown> = {
  [AsyncActionState.Status.NotDone]: {
    readonly status: AsyncActionState.Status.NotDone
  }
  [AsyncActionState.Status.DoneOrNotDoing]: {
    readonly status: AsyncActionState.Status.DoneOrNotDoing
  }
  [AsyncActionState.Status.Doing]: {
    readonly status: AsyncActionState.Status.Doing
  }
  [AsyncActionState.Status.Failed]: {
    readonly status: AsyncActionState.Status.Failed
    readonly error: E
  }
}[S]

export namespace AsyncActionState {
  export enum Status {
    NotDone = 'NOT_DONE', // We probably need this status state for initializing actions only
    DoneOrNotDoing = 'DONE_OR_NOT_DOING',
    Doing = 'DOING',
    Failed = 'FAILED',
  }
}

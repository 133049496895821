import { useSwitchPanel } from '../panel'

export { Panel } from '../panel'

export function useOpenMainPage(): {
  openMainPageStatic: ReturnType<typeof useSwitchPanel>['switchPanelStatic']
} {
  const { switchPanelStatic } = useSwitchPanel()

  return {
    openMainPageStatic: switchPanelStatic,
  }
}

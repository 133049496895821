import { PropsWithChildren, useState } from 'react'
import { MemoizedContextProvider } from '../../../MemoizedContextProvider'
import { Context } from './Context'
import { LoadingLogo } from './LoadingLogo'
import { LoadingReason } from './types'

export function LoadingLogoProvider({ children }: PropsWithChildren<{}>) {
  const [reasons, setReasons] = useState<readonly LoadingReason[]>([])

  return (
    <MemoizedContextProvider
      context={Context}
      value={{
        reasons,
        setReasons,
      }}
      dependencies={[reasons]}
    >
      {children}

      <LoadingLogo />
    </MemoizedContextProvider>
  )
}

import { Persistent } from '../persistent'
import { AccountService } from './services/AccountService'
import { AdminService } from './services/AdminService'
import { AiService } from './services/AiService'
import { CoreService } from './services/CoreService'
import { DeveloperService } from './services/DeveloperService'
import { ExtensionService } from './services/ExtensionService'
import { PersonalKeyService } from './services/PersonalKeyService'
import { SearchService } from './services/SearchService'
import { StaticService } from './services/StaticService'
import { UserService } from './services/UserService'

export function createServices(persistent: Persistent) {
  return {
    account: new AccountService(persistent),
    admin: new AdminService(persistent),
    ai: new AiService(persistent),
    core: new CoreService(persistent),
    developer: new DeveloperService(persistent),
    extension: new ExtensionService(persistent),
    personalKey: new PersonalKeyService(persistent),
    search: new SearchService(persistent),
    static: StaticService.create(),
    user: new UserService(persistent),
  } as const
}

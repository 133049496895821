// This is all this file is about!
/* eslint-disable no-console, no-irregular-whitespace */

import { webConfig } from '../../../modules/web-config'
import { getVersion } from './getVersion'

export function printWelcomeMessage(): void {
  // TODO: These two should probably become one properly styled message with version on it:

  console.log(`


     oooooooooooo               .       .             oooo  
    d'""""""d888'             .o8     .o8             \`888  
          .888P    .ooooo.  .o888oo .o888oo  .ooooo.   888  
         d888'    d88' \`88b   888     888   d88' \`88b  888  
       .888P      888ooo888   888     888   888ooo888  888  
      d888'    .P 888    .o   888 .   888 . 888    .o  888    \u24D2 2020-2021 ${webConfig.app.name}
    .8888888888P  \`Y8bod8P'   "888"   "888" \`Y8bod8P' o888o   \u24E5 ${getVersion()}


  `)

  if (webConfig.environment.node === 'production') {
    console.log(
      `%cCreated with ❤ by ${webConfig.app.name}`,
      `
        font-family: Comic Sans MS;
        font-size:30px;
        font-weight: bold;
        background: linear-gradient(#f00, yellow);
        border-radius: 5px;
        padding: 20px;
      `
    )
  }
}

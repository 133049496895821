import { makeStyles } from '@material-ui/core'
import { generateSequence, Id } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { DetailedHTMLProps, forwardRef, HTMLAttributes, useLayoutEffect, useState } from 'react'
import { useCopyToClipboard, useGetSet } from 'react-use'
import { useCombineRefs } from '../../../../../../../hooks/useCombineRefs'
import { useCommonStyles } from '../../../../../../../hooks/useCommonStyles'
import { sendAnalyticEvent } from '../../../../../../../modules/analytics'
import { NavigableWithCommands, useManageCommands } from '../../../../../../../modules/commands'
import { NativeDragObject, useDrag, useDrop } from '../../../../../../../modules/drag-and-drop'
import { commonKeyboardCombinations } from '../../../../../../../modules/keyboard-handler'
import { NavigableStatus } from '../../../../../../../modules/navigation'
import { useApplyAction } from '../../../../../hooks/useApplyAction'
import {
  AppDragObject,
  AppDropResult,
  detectDroppingArea,
  DroppingArea,
} from '../../../../../modules/app-drag-and-drop'
import { useConfirmationDialog } from '../../../../../modules/confirmation-dialog'
import { ExtensionRolesProvider } from '../../../../../modules/extension'
import { OnboardingTour, OnboardingWelcomeTourStep, useOnboardingConnect } from '../../../../../modules/onboarding'
import { getCardPublicUrl } from '../../../helpers/getCardPublicUrl'
import { CardEditorDialog } from '../CardEditorDialog'
import { CardViewer } from '../CardViewer'
import { useCreateTextCard } from '../useCreateTextCard'
import { CardContextMenu } from './CardContextMenu'

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
      paddingTop: theme.spacing(1.5),
    },
    isHighlighted: {
      border: `${theme.spacing(0.25)}px solid ${theme.palette.secondary.light}`,
    },
    dropIndicator: {
      display: 'none',
      pointerEvents: 'none',
      position: 'absolute',
      left: theme.spacing(0),
      right: 0,
      border: 0,
      borderTop: `${theme.spacing(0.25)}px solid ${theme.palette.secondary.main}`,
    },
    topDropIndicator: {
      top: theme.spacing(-0.25),
    },
    bottomDropIndicator: {
      bottom: theme.spacing(-1.75),
    },
    visible: {
      display: 'block',
    },
    topExtendedContentsContainer: {},
    bottomExtendedContentsContainer: {},
  }),
  { name: 'CardViewerWrapper' }
)

export const CardViewerWrapper = forwardRef<
  HTMLDivElement,
  {
    page: Model.Page
    card: Model.Card
    previousCard: Model.Card | undefined
    nextCard: Model.Card | undefined
    highlightedCardId?: Id
    readOnly?: boolean
  } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>(function CardViewerWrapper(
  { page, card, previousCard, nextCard, highlightedCardId, readOnly = false, ...otherProps },
  ref
) {
  const [extendedMenuItems, setExtendedMenuItems] = useState<readonly ZettelExtensions.LifeSpan.Shared.Card.MenuItem[]>(
    []
  )
  const [getEditingCard, setEditingCard] = useGetSet<Model.Card | undefined>(undefined)
  const [isBeingDragged, setIsBeingDragged] = useState(false)
  const [droppingAreaVertical, setDroppingAreaVertical] = useState<DroppingArea['vertical']>()
  const [navigableStatus, setNavigableStatus] = useState(NavigableStatus.NotNavigated)

  useLayoutEffect(() => {
    if (readOnly && getEditingCard()) {
      setEditingCard(undefined)
    }
  }, [readOnly])

  const { applyActionStatic } = useApplyAction()

  const { createTextCard } = useCreateTextCard()

  const { connectDragSource, connectDragPreview } = useDrag<
    AppDragObject.Card,
    AppDropResult.CardOnPage | AppDropResult.CardReorder
  >({
    dragObject: {
      type: AppDragObject.Type.Card,
      card,
    },
    dragStart(event) {
      setIsBeingDragged(true)
    },
    dragEnd(event) {
      setIsBeingDragged(false)
    },
    async drop(dragObject, dropResult) {
      switch (dropResult.type) {
        case AppDropResult.Type.CardOnPage: {
          sendAnalyticEvent('CardViewer', 'Drop card on page')
          throw Error('Not implemented.')
          // TODO: It also needs to construct extension data out of the extracted from the old data:
          // await applyActionStatic.createModel({
          //   ...dragObject.card,
          //   type: Model.Type.Card,
          //   id: undefined,
          //   pageId: dropResult.pageId,
          //   sequence: generateSequence(),
          //   extensionData: {},
          // })
          // await applyActionStatic.deleteModel(dragObject.card)
          // break
        }

        case AppDropResult.Type.CardReorder: {
          sendAnalyticEvent('CardViewer', 'Card Reorder')
          await applyActionStatic.updateModel({
            type: Model.Type.Card,
            id: dragObject.card.id,
            sequence: generateSequence({
              previousSequence: dropResult.previousCard?.sequence,
              nextSequence: dropResult.nextCard?.sequence,
            }),
          })
          break
        }
      }
    },
    disabled: readOnly,
  })

  const { connectDropTarget } = useDrop<
    NativeDragObject.File | NativeDragObject.Text | NativeDragObject.Html | NativeDragObject.Url | AppDragObject.Card,
    AppDropResult.CardReorder
  >({
    check(event, dragObject) {
      switch (dragObject.type) {
        case NativeDragObject.Type.Html:
          return dragObject.text ? 'allowed' : 'denied'

        case NativeDragObject.Type.File:
        case NativeDragObject.Type.Text:
        case NativeDragObject.Type.Url:
          return 'allowed'

        case AppDragObject.Type.Card: {
          const droppingArea = detectDroppingArea(event)
          if (droppingArea?.vertical === 'top') {
            if (dragObject.card.id === previousCard?.id) return 'denied'
            if (dragObject.card.id === card.id) return 'denied'
            return 'allowed'
          }
          if (droppingArea?.vertical === 'bottom') {
            if (dragObject.card.id === card.id) return 'denied'
            if (dragObject.card.id === nextCard?.id) return 'denied'
            return 'allowed'
          }
        }
      }
    },
    drag(event, dragObject, { accepted }) {
      switch (dragObject.type) {
        case NativeDragObject.Type.File:
        case NativeDragObject.Type.Html:
        case NativeDragObject.Type.Text:
        case NativeDragObject.Type.Url:
          setDroppingAreaVertical(accepted ? detectDroppingArea(event)?.vertical : undefined)
          return 'copy'

        case AppDragObject.Type.Card:
          setDroppingAreaVertical(accepted ? detectDroppingArea(event)?.vertical : undefined)
          return 'move'
      }
    },
    drop(event, dragObject, drop) {
      const droppingArea = detectDroppingArea(event)
      if (!droppingArea) return

      const newPreviousCard = droppingArea.vertical === 'top' ? previousCard : card
      const newNextCard = droppingArea.vertical === 'bottom' ? nextCard : card
      const generateNewSequence = (): string =>
        generateSequence({
          previousSequence: newPreviousCard?.sequence,
          nextSequence: newNextCard?.sequence,
        })

      switch (dragObject.type) {
        // case NativeDragObject.Type.File:
        //   createAttachmentCard(card.pageId, dragObject.items, generateNewSequence())
        //   break

        case NativeDragObject.Type.Html:
          if (dragObject.text) {
            createTextCard(card.pageId, dragObject.text, generateNewSequence())
          }
          break

        case NativeDragObject.Type.Text:
          createTextCard(card.pageId, dragObject.text, generateNewSequence())
          break

        case NativeDragObject.Type.Url:
          createTextCard(card.pageId, dragObject.urls.join('\n'), generateNewSequence())
          break

        case AppDragObject.Type.Card: {
          drop({
            type: AppDropResult.Type.CardReorder,
            previousCard: newPreviousCard,
            nextCard: newNextCard,
          })
          break
        }
      }
    },
    disabled: readOnly,
  })

  const { confirmStatic } = useConfirmationDialog()

  const { runCommandStatic } = useManageCommands()

  const [, copyToClipboard] = useCopyToClipboard()

  const { connectOnboardingAnchorElement } = useOnboardingConnect(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.Card,
    {
      padding: [-1, 0, -1, -6],
    }
  )

  const combineRootRefs = useCombineRefs(connectDropTarget, !nextCard && connectOnboardingAnchorElement, ref)

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <NavigableWithCommands
      key={card.id}
      padding={[0, 1, 0, 0]}
      disabledAutoScrollIntoView
      onNavigableStatus={setNavigableStatus}
      commandGroup={({ navigableRef }) => ({
        name: 'card',
        displayName: 'Card',
        disabled() {
          return !!getEditingCard()
        },
        commands: [
          {
            name: 'edit',
            displayName: 'Edit',
            disabled: () => readOnly,
            icon: 'Edit',
            defaultShortcutKeys: commonKeyboardCombinations.enter,
            handler() {
              setEditingCard(card)
            },
          },
          {
            name: 'duplicate',
            displayName: 'Duplicate',
            disabled: () => readOnly,
            icon: 'Copy',
            defaultShortcutKeys: { alt: true, code: 'KeyD' },
            async handler() {
              await applyActionStatic.createModel({
                ...card,
                type: Model.Type.Card,
                id: undefined,
                sequence: generateSequence({
                  previousSequence: card.sequence,
                  nextSequence: nextCard?.sequence,
                }),
              })
            },
          },
          {
            name: 'copyPublicUrl',
            displayName: 'Copy Public URL',
            icon: 'Copy',
            handler() {
              copyToClipboard(getCardPublicUrl(card.id))
            },
          },
          {
            name: 'delete',
            displayName: 'Delete',
            disabled: () => readOnly,
            icon: 'Delete',
            defaultShortcutKeys: commonKeyboardCombinations.delete,
            async handler() {
              const confirmed = await confirmStatic({
                title: 'Delete note',
                content: 'Are you sure to delete this note?',
                confirmLabel: 'Delete note',
                cancelLabel: 'Cancel',
              })
              if (!confirmed) return
              await applyActionStatic.deleteModel(card)
            },
          },
        ],
      })}
      commandGroupDependencies={[card, nextCard, readOnly]}
    >
      {({ connectNavigable }) => (
        <ExtensionRolesProvider role="CardViewerWrapper">
          <div
            {...otherProps}
            ref={combineRootRefs(connectNavigable)}
            className={classNames(classes.root, otherProps.className)}
            onDoubleClick={event => {
              if (!readOnly) {
                event.preventDefault()
                event.stopPropagation()
                runCommandStatic('card.edit')
              }
              otherProps.onDoubleClick?.(event)
            }}
          >
            <hr
              className={classNames(
                classes.dropIndicator,
                classes.topDropIndicator,
                droppingAreaVertical === 'top' && classes.visible
              )}
            />
            <hr
              className={classNames(
                classes.dropIndicator,
                classes.bottomDropIndicator,
                droppingAreaVertical === 'bottom' && classes.visible
              )}
            />

            <CardViewer
              ref={connectDragPreview}
              className={classNames(
                highlightedCardId === card.id && classes.isHighlighted,
                isBeingDragged && commonClasses.isBeingDragged
              )}
              page={page}
              card={card}
              isHighlighted={navigableStatus !== NavigableStatus.NotNavigated}
              setExtendedMenuItems={setExtendedMenuItems}
            />

            <CardContextMenu
              page={page}
              readOnly={readOnly}
              reorderButtonRef={connectDragSource}
              extendedMenuItems={extendedMenuItems}
            />

            {!readOnly && getEditingCard() && (
              <CardEditorDialog page={page} card={getEditingCard()!} onClose={() => setEditingCard(undefined)} />
            )}
          </div>
        </ExtensionRolesProvider>
      )}
    </NavigableWithCommands>
  )
})

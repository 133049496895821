import { DependencyList, useContext, useEffect } from 'react'
import { Context } from './Context'
import { MessageHandler } from './types'

export function useHandleMessage(handler: MessageHandler | null, dependencies: DependencyList): void {
  const { registerMessageHandlerStatic } = useContext(Context)

  useEffect(() => {
    if (handler) return registerMessageHandlerStatic(handler)
  }, dependencies)
}

import { PartialReadonlyRecord, PartialRecord, HandlerOutput, Keyboard } from '@zettelooo/commons'
import { MutableRefObject } from 'react'
import { arrayHelpers } from '../../helpers/native/arrayHelpers'
import { CustomIcon } from '../custom-icon'
import { doesKeyboardEventMatchCombinations } from '../keyboard-handler'
import { webConfig } from '../web-config'
import { composeCommandCodeName } from './helpers/compose-command-code-name'
import { decomposeCommandCodeName } from './helpers/decompose-command-code-name'
import { Command, CommandGroup, CommandHandler } from './types'

interface EnhancedCommandGroup extends CommandGroup {
  readonly commandsByName: PartialReadonlyRecord<string, Command>
}

export interface DisplayedCommand {
  readonly codeName: string
  readonly displayName: string
  readonly icon?: CustomIcon.Name
  readonly defaultShortcutKeys?: Keyboard.Combinations
}
export interface DisplayedCommandGroup {
  readonly name: string
  readonly displayName: string
  readonly commands: readonly DisplayedCommand[]
}

export interface FilteredCommand {
  readonly codeName: string
  readonly score: number
  readonly groupDisplayName: string
  readonly commandDisplayName: string
  readonly icon?: CustomIcon.Name
  readonly defaultShortcutKeys?: Keyboard.Combinations
}

export interface QueriedCommand {
  readonly codeName: string
  readonly group: {
    readonly name: string
    readonly displayName?: string
  }
  readonly command: {
    readonly name: string
    readonly displayName?: string
    readonly icon?: CustomIcon.Name
    readonly defaultShortcutKeys?: Keyboard.Combinations
  }
}

type CommandHandlingSubscription = (codeName: string) => void

export class CommandManager {
  private groupRegistrationKeys: string[]
  private groupsByRegistrationKey: PartialRecord<string, EnhancedCommandGroup>
  private groupRegistrationKeysByName: PartialRecord<string, string[]>

  private commandSubscriptionsByCodeName: PartialRecord<string, CommandHandler[]>

  private commandHandlingSubscriptions: CommandHandlingSubscription[]

  constructor() {
    this.groupRegistrationKeys = []
    this.groupsByRegistrationKey = {}
    this.groupRegistrationKeysByName = {}

    this.commandSubscriptionsByCodeName = {}

    this.commandHandlingSubscriptions = []
  }

  registerCommandGroup(groupName: string): string {
    let registrationKey: string
    do {
      registrationKey = Math.random().toString() // Some simple random string key
    } while (registrationKey in this.groupRegistrationKeys)
    this.groupRegistrationKeys.unshift(registrationKey)
    this.groupRegistrationKeysByName[groupName] = this.groupRegistrationKeysByName[groupName] ?? []
    this.groupRegistrationKeysByName[groupName]!.unshift(registrationKey)
    return registrationKey
  }
  unregisterCommandGroup(registrationKey: string): void {
    let index = this.groupRegistrationKeys.indexOf(registrationKey)
    if (index >= 0) {
      this.groupRegistrationKeys.splice(index, 1)
    }
    const groupName = this.groupsByRegistrationKey[registrationKey]?.name
    delete this.groupsByRegistrationKey[registrationKey]
    if (groupName && groupName in this.groupRegistrationKeysByName) {
      index = this.groupRegistrationKeysByName[groupName]!.indexOf(registrationKey)
      if (index >= 0) {
        this.groupRegistrationKeysByName[groupName]!.splice(index, 1)
      }
      if (!this.groupRegistrationKeysByName[groupName]?.length) delete this.groupRegistrationKeysByName[groupName]
    }
  }

  setCommandGroupRegistration(registrationKey: string, group: CommandGroup): void {
    this.groupsByRegistrationKey[registrationKey] = {
      ...group,
      commandsByName: arrayHelpers.toDictionary(group.commands, command => command.name),
    }
  }
  clearCommandGroupRegistration(registrationKey: string): void {
    delete this.groupsByRegistrationKey[registrationKey]
  }

  subscribeToCommand(codeName: string, handler: CommandHandler): void {
    this.commandSubscriptionsByCodeName[codeName] = this.commandSubscriptionsByCodeName[codeName] ?? []
    this.commandSubscriptionsByCodeName[codeName]!.push(handler)
  }
  unsubscribeFromCommand(codeName: string, handler: CommandHandler): void {
    if (!(codeName in this.commandSubscriptionsByCodeName)) return
    const index = this.commandSubscriptionsByCodeName[codeName]?.indexOf(handler)
    if (index !== undefined && index >= 0) {
      this.commandSubscriptionsByCodeName[codeName]!.splice(index, 1)
    }
    if (index === undefined || this.commandSubscriptionsByCodeName[codeName]!.length === 0) {
      delete this.commandSubscriptionsByCodeName[codeName]
    }
  }

  subscribeToCommandHandling(subscription: CommandHandlingSubscription): void {
    this.commandHandlingSubscriptions.push(subscription)
  }
  unsubscribeFromCommandHandling(subscription: CommandHandlingSubscription): void {
    const index = this.commandHandlingSubscriptions.indexOf(subscription)
    if (index >= 0) {
      this.commandHandlingSubscriptions.splice(index, 1)
    }
  }

  handleCommand(codeName: string): HandlerOutput {
    const [groupName, commandName] = decomposeCommandCodeName(codeName)
    const firstRegistrationKeyWithEnabledCommand = this.groupRegistrationKeysByName[groupName]?.find(
      registrationKey => {
        const group = this.groupsByRegistrationKey[registrationKey]
        if (!group || group.disabled?.()) return false
        const command = group.commandsByName[commandName]
        if (!command || command.disabled?.()) return false
        return true
      }
    )
    const firstEnabledCommand =
      firstRegistrationKeyWithEnabledCommand &&
      this.groupsByRegistrationKey[firstRegistrationKeyWithEnabledCommand]?.commandsByName[commandName]

    if (!firstEnabledCommand) {
      if (webConfig.developerLogs.commands) {
        const firstRegistrationKeyWithCommand = this.groupRegistrationKeysByName[groupName]?.find(registrationKey => {
          const group = this.groupsByRegistrationKey[registrationKey]
          if (!group) return false
          const command = group.commandsByName[commandName]
          if (!command) return false
          return true
        })
        const firstommand =
          firstRegistrationKeyWithCommand &&
          this.groupsByRegistrationKey[firstRegistrationKeyWithCommand]?.commandsByName[commandName]

        if (!firstommand) {
          console.log(`Command "${codeName}" is not found.`) // eslint-disable-line no-console
        }
      }

      return 'not handled'
    }

    const commandSubscriptions = this.commandSubscriptionsByCodeName[codeName]
    const handlers = [firstEnabledCommand.handler, ...(commandSubscriptions ?? [])]
    const result = handlers.reduce<HandlerOutput>(
      (result, handler) =>
        (!handler || handler() === 'not handled') && result === 'not handled' ? 'not handled' : undefined,
      'not handled'
    )

    if (result !== 'not handled') {
      this.commandHandlingSubscriptions.forEach(commandHandlingSubscription => commandHandlingSubscription(codeName))
    }

    return result
  }
  handleCommandDelayed(
    codeName: string,
    isMountedRef?: MutableRefObject<() => boolean>
  ): HandlerOutput | Promise<HandlerOutput> {
    const result = this.handleCommand(codeName)
    if (result !== 'not handled') return result

    return new Promise(resolve =>
      setTimeout(() => {
        if (!isMountedRef || isMountedRef.current()) {
          resolve(this.handleCommand(codeName))
        } else {
          resolve('not handled')
        }
      })
    )
  }
  handleKeyPress(event: KeyboardEvent): HandlerOutput {
    for (let i = 0; i < this.groupRegistrationKeys.length; i += 1) {
      const group = this.groupsByRegistrationKey[this.groupRegistrationKeys[i]]
      if (group && !group.disabled?.()) {
        for (let j = 0; j < group.commands.length; j += 1) {
          const command = group.commands[j]
          if (
            !command.disabled?.() &&
            command.defaultShortcutKeys &&
            doesKeyboardEventMatchCombinations(event, command.defaultShortcutKeys) // TODO: Improve with the user defined shortcut keys
          ) {
            return this.handleCommand(composeCommandCodeName(group.name, command.name))
          }
        }
      }
    }
    return 'not handled'
  }

  getAllAvailableCommands(): readonly DisplayedCommandGroup[] {
    return this.groupRegistrationKeys
      .map(registrationKey => this.groupsByRegistrationKey[registrationKey]!)
      .filter(group => group && group.displayName && !group.disabled?.())
      .map<DisplayedCommandGroup>(group => ({
        name: group.name,
        displayName: group.displayName!,
        commands: group.commands
          .filter(
            command =>
              command.displayName &&
              !command.disabled?.() &&
              (command.handler ||
                composeCommandCodeName(group.name, command.name) in this.commandSubscriptionsByCodeName)
          )
          .map<DisplayedCommand>(command => ({
            codeName: composeCommandCodeName(group.name, command.name),
            displayName: command.displayName!,
            icon: command.icon,
            defaultShortcutKeys: command.defaultShortcutKeys,
          })),
      }))
      .filter(group => group.commands.length > 0)
  }
  // TODO: Improve searching experience, when a whole word matches, it should get to top, no matter what the current calculated score is:
  searchAvailableCommands(query: string): readonly FilteredCommand[] {
    const queryRegExp = RegExp(
      query
        .replace(/[^a-zA-Z0-9]*/g, '')
        .split('')
        .map(character => `(${character})?`)
        .join(''),
      'gi'
    )
    const scoredEnabledCommands: FilteredCommand[] = []
    this.groupRegistrationKeys.forEach(registrationKey => {
      const group = this.groupsByRegistrationKey[registrationKey]
      if (!group || !group.displayName || group.disabled?.()) return
      group.commands.forEach(command => {
        if (!command.displayName || command.disabled?.()) return
        const codeName = composeCommandCodeName(group.name, command.name)
        if (!command.handler && !(codeName in this.commandSubscriptionsByCodeName)) return
        const score = calculateScore(`${group.displayName} ${command.displayName}`)
        if (score === 0) return
        scoredEnabledCommands.push({
          codeName,
          score,
          groupDisplayName: group.displayName!,
          commandDisplayName: command.displayName,
          icon: command.icon,
          defaultShortcutKeys: command.defaultShortcutKeys,
        })

        function calculateScore(text: string): number {
          if (!text) return 0
          const matches = text.match(queryRegExp)
          if (!matches) return 0
          const scoreSum = matches.map(match => match.length ** 1.5).reduce((sum, partialScore) => sum + partialScore)
          return scoreSum / (text.length + 5)
        }
      })
    })
    const sortedScoredEnabledCommands = arrayHelpers.sortByDescending(scoredEnabledCommands, 'score')
    const topScore = sortedScoredEnabledCommands[0]?.score ?? 0
    const minimumScore = topScore / 3
    const sortedFilteredCommands = sortedScoredEnabledCommands.filter(command => command.score >= minimumScore)
    return sortedFilteredCommands
  }
  queryAvailableCommand(codeName: string): QueriedCommand | undefined {
    const [groupName, commandName] = decomposeCommandCodeName(codeName)
    const firstRegistrationKeyWithEnabledCommand = this.groupRegistrationKeysByName[groupName]?.find(
      registrationKey => {
        const group = this.groupsByRegistrationKey[registrationKey]
        if (!group || group.disabled?.()) return false
        const command = group.commandsByName[commandName]
        if (!command || command.disabled?.()) return false
        return true
      }
    )
    if (!firstRegistrationKeyWithEnabledCommand) return undefined

    const firstEnabledGroup = this.groupsByRegistrationKey[firstRegistrationKeyWithEnabledCommand]!
    const firstEnabledCommand = firstEnabledGroup.commandsByName[commandName]!

    return {
      codeName,
      group: {
        name: firstEnabledGroup.name,
        displayName: firstEnabledGroup.displayName,
      },
      command: {
        name: firstEnabledCommand.name,
        displayName: firstEnabledCommand.displayName,
        icon: firstEnabledCommand.icon,
        defaultShortcutKeys: firstEnabledCommand.defaultShortcutKeys,
      },
    }
  }
}

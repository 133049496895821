import { Model } from '@zettelooo/server-shared'
import { useExtensionManager } from '../../../../modules/extension'

export function useGetCardText(): {
  getCardText(card: Model.Card): string | undefined
} {
  const { extensionManager } = useExtensionManager()

  return {
    getCardText(card) {
      const extensionIds = Object.keys(card.dataDictionary)
      for (let i = 0; i < extensionIds.length; i++) {
        const extensionId = extensionIds[i]
        const cardData = card.dataDictionary[extensionId]
        if (cardData === undefined) continue
        const extraction = extensionManager.extractCommonDataFromCardData(extensionId, cardData)
        if (extraction?.text) return extraction.text
      }
      return undefined
    },
  }
}

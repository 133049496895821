import { Theme } from '@material-ui/core'
import { NavigablePaddings } from '../types'

export function evaluateNavigablePaddings(theme: Theme, padding?: number | readonly number[]): NavigablePaddings {
  if (!padding) return { top: 0, right: 0, bottom: 0, left: 0 }

  if (typeof padding === 'number') {
    const paddingValue = theme.spacing(padding)
    return { top: paddingValue, right: paddingValue, bottom: paddingValue, left: paddingValue }
  }

  return {
    top: theme.spacing(padding[0] ?? 0),
    right: theme.spacing(padding[1] ?? padding[0] ?? 0),
    bottom: theme.spacing(padding[2] ?? padding[0] ?? 0),
    left: theme.spacing(padding[3] ?? padding[1] ?? padding[0] ?? 0),
  }
}

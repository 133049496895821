import { Keyboard } from '@zettelooo/commons'
import { typed } from '../../helpers/typed'
import { webConfig } from '../web-config'

export const commonKeyboardCombinations = {
  enter: typed<Keyboard.Combinations>(['Enter', 'NumpadEnter']),
  escape: typed<Keyboard.Combinations>('Escape'),
  delete: typed<Keyboard.Combinations>(webConfig.os === 'macOS' ? { cmd: true, code: 'Backspace' } : 'Delete'),
  rename: typed<Keyboard.Combinations>(webConfig.os === 'macOS' ? { cmd: true, shift: true, code: 'KeyR' } : 'F2'),
} as const

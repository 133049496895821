import { Button, Container, makeStyles, TextField, Typography } from '@material-ui/core'
import { useState } from 'react'
import { Gap } from '../../../../../../Gap'
import { Authentication } from './Authentication'

const useStyles = makeStyles(
  theme => ({
    root: {
      minHeight: '100vh',
    },
    form: {
      padding: theme.spacing(10, 0),
    },
  }),
  { name: 'SignInView' }
)

export function SignInView() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const { signInStatic } = Authentication.useControl()

  const classes = useStyles()

  return (
    <Container maxWidth="sm" className={classes.root}>
      <form
        className={classes.form}
        onSubmit={async event => {
          event.preventDefault()
          if (
            username === Authentication.adminCredentials.username &&
            password === Authentication.adminCredentials.password
          ) {
            setErrorMessage('')
            signInStatic('admin')
          } else {
            setErrorMessage('Incorrect username or password.')
          }
        }}
      >
        <Typography variant="h5" gutterBottom>
          Welcome dear administrator!
        </Typography>
        <Typography variant="subtitle2">Use your administrator account credential to sign in:</Typography>

        <Gap vertical={3} />

        <TextField
          variant="outlined"
          label="Username"
          placeholder="username"
          fullWidth
          autoFocus
          value={username}
          onChange={event => setUsername(event.target.value)}
        />

        <Gap vertical={2} />

        <TextField
          variant="outlined"
          label="Password"
          placeholder="password"
          type="password"
          fullWidth
          value={password}
          onChange={event => setPassword(event.target.value)}
        />

        <Gap vertical={2} />

        <Button variant="contained" color="primary" type="submit" fullWidth>
          Sign in
        </Button>

        {errorMessage && (
          <>
            <Gap vertical={2} />

            <Typography variant="subtitle1" color="error">
              {errorMessage}
            </Typography>
          </>
        )}
      </form>
    </Container>
  )
}

import { ReadonlyRecord } from '@zettelooo/commons'
import { arrayHelpers } from '../../../../helpers/native/arrayHelpers'
import { en } from './language-packs/en'
import { fa } from './language-packs/fa'
import { Language, LanguageCode, LanguagePack, LanguageResource } from './types'

export const languagePacks: readonly LanguagePack[] = [en, fa]

export const languages: readonly Language[] = languagePacks.map(({ language }) => language)
export const languageCodes: readonly LanguageCode[] = languages.map(({ code }) => code)
export const languageDictionary: ReadonlyRecord<LanguageCode, Language> = arrayHelpers.toDictionary(languages, 'code')
export const languageResources: ReadonlyRecord<LanguageCode, LanguageResource> = arrayHelpers.toDictionary(
  languagePacks,
  ({ language }) => language.code,
  ({ resource }) => resource
)

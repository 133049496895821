import { useTheme } from '@material-ui/core'
import { forwardRef, memo, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useRefWrap } from '../../../hooks/useRefWrap'
import { createContexts, useContexts } from '../../contexts'
import { generateRuntimeId } from '../../generators'
import { evaluateNavigablePaddings } from '../helpers/evaluateNavigablePaddings'
import { getNavigableStatus } from '../helpers/getNavigableStatus'
import { NavigablePath, NavigableProvided, NavigableStatus } from '../types'
import { NavigationAreaContexts } from './NavigationArea'

export const NavigableContexts = createContexts(
  ({ memoize }) =>
    (parameters?: { navigablePath: NavigablePath }) => ({ parentNavigablePath: parameters?.navigablePath! }),
  'Navigable'
)

export const Navigable = memo(
  forwardRef<
    {
      navigableStatus: NavigableStatus
    },
    {
      padding?: number | readonly number[]
      disabled?: boolean
      onNavigableStatus?: (newNavigableStatus: NavigableStatus, oldNavigableStatus: NavigableStatus) => void
      children?: (provided: NavigableProvided) => React.JSX.Element
    } & (
      | {
          group?: undefined
          /** Selectable navigables can not have child navigables */ selectable?: boolean
          /** Focusable navigables have auto-scrolling into view set by default */ focusable?: boolean
          /** Doesn't have any effect on focusable navigables */ disabledAutoScrollIntoView?: boolean
          autoNavigate?: boolean
        }
      | {
          group: true
        }
    )
  >(function Navigable({ padding, disabled, onNavigableStatus, children, ...otherProps }, ref) {
    const {
      refs: { navigationStateAccessor, navigatorTree },
      isAreaActive,
      navigationState,
    } = useContexts(NavigationAreaContexts)
    const { parentNavigablePath } = useContexts(NavigableContexts)

    const selectable = Boolean(!otherProps.group && otherProps.selectable)
    const focusable = Boolean(!otherProps.group && otherProps.focusable)
    const disabledAutoScrollIntoView = Boolean(!otherProps.group && otherProps.disabledAutoScrollIntoView)
    const autoNavigate = Boolean(!otherProps.group && otherProps.autoNavigate)

    const [navigationElement, setNavigationElement] = useState<HTMLElement | null>(null)
    const [focusElement, setFocusElement] = useState<HTMLElement | null>(null)
    const evaluatedFocusElement = focusElement ?? navigationElement

    const navigableIdStatic = useMemo(generateRuntimeId, [])
    const navigablePath = useMemo(() => [...parentNavigablePath, navigableIdStatic], [parentNavigablePath])

    const onNavigableStatusRef = useRefWrap(onNavigableStatus)
    const newNavigableStatus = useMemo(() => getNavigableStatus(navigableIdStatic, navigationState), [navigationState])
    const navigableStatusRef = useRef(newNavigableStatus)
    const oldNavigableStatus = navigableStatusRef.current
    if (newNavigableStatus !== oldNavigableStatus) {
      navigableStatusRef.current = newNavigableStatus
      // In many cases, this callback will cause this component to be rerendered.
      // React will complain about not being able to render the component while rendering some other component.
      // So, we need a tiny break here to pass the currently rendering component:
      setTimeout(() => onNavigableStatusRef.current?.(newNavigableStatus, oldNavigableStatus))
    }

    useImperativeHandle(
      ref,
      () => ({
        get navigableStatus() {
          return navigableStatusRef.current
        },
      }),
      []
    )

    // TODO: Remove the next comment if you can! And, add more dependencies to the hook:
    // No need to set back any of the followings changes later again:
    useLayoutEffect(() => {
      if (disabled) return

      if (evaluatedFocusElement) {
        if (focusable) {
          evaluatedFocusElement.tabIndex = -1 // To make it focusable
        }
        evaluatedFocusElement.style.outline = 'none'
        // evaluatedFocusElement.style.outline = '3px dotted green' //Just for development
        evaluatedFocusElement.dataset.navigableId = navigableIdStatic
      }
    }, [disabled, evaluatedFocusElement])

    useLayoutEffect(() => {
      if (disabled) return

      evaluatedFocusElement?.addEventListener('mousedown', handleMouseAndTouch, { capture: true })
      evaluatedFocusElement?.addEventListener('touchstart', handleMouseAndTouch, { capture: true })
      evaluatedFocusElement?.addEventListener('focus', handleFocus)

      return () => {
        evaluatedFocusElement?.removeEventListener('mousedown', handleMouseAndTouch, { capture: true })
        evaluatedFocusElement?.removeEventListener('touchstart', handleMouseAndTouch, { capture: true })
        evaluatedFocusElement?.removeEventListener('focus', handleFocus)
      }

      function handleMouseAndTouch(event: MouseEvent | TouchEvent): void {
        const path = event.composedPath()
        const pathNavigableIds = path.map(element => (element as HTMLElement).dataset?.navigableId!).filter(Boolean)
        const navigableIndex = pathNavigableIds.indexOf(navigableIdStatic)
        if (navigableIndex === 0) {
          if (focusable) {
            evaluatedFocusElement?.focus()
          } else {
            navigate()
          }
        }
      }
      function handleFocus(event: FocusEvent): void {
        navigate()
      }
      function navigate(): void {
        navigationStateAccessor.set(
          otherProps.group
            ? {
                navigatedPath: [
                  ...navigablePath,
                  ...(navigatorTree.getChildNavigableRelativePath(navigableIdStatic) || []),
                ],
                selected: false,
              }
            : {
                navigatedPath: navigablePath,
                selected: selectable,
              }
        )
      }
    }, [disabled, selectable, focusable, evaluatedFocusElement, navigablePath])

    useLayoutEffect(() => {
      if (otherProps.group || disabled || !isAreaActive) return
      if (!disabledAutoScrollIntoView && navigationState.navigatedPath.includes(navigableIdStatic)) {
        navigationElement?.scrollIntoView({ block: 'nearest', inline: 'nearest' })
      }
      if (focusable) {
        if (navigatorTree.shouldElementBeFocused(navigableIdStatic, navigationState)) {
          evaluatedFocusElement?.focus()
        } else {
          evaluatedFocusElement?.blur()
        }
      }
    }, [disabled, focusable, disabledAutoScrollIntoView, isAreaActive, navigationState, navigationElement, evaluatedFocusElement])

    const theme = useTheme()

    useLayoutEffect(() => {
      if (disabled) return
      navigatorTree.register(
        navigablePath,
        otherProps.group
          ? {
              type: 'group',
              element: navigationElement,
              paddings: evaluateNavigablePaddings(theme, padding),
            }
          : {
              type: 'navigable',
              element: navigationElement,
              paddings: evaluateNavigablePaddings(theme, padding),
              selectable,
            }
      )
      return () => navigatorTree.unregister(navigablePath)
    }, [disabled, navigablePath])

    useLayoutEffect(() => {
      if (disabled) return
      navigatorTree.update(
        navigableIdStatic,
        otherProps.group
          ? {
              element: navigationElement,
              paddings: evaluateNavigablePaddings(theme, padding),
            }
          : {
              element: navigationElement,
              paddings: evaluateNavigablePaddings(theme, padding),
              selectable,
            }
      )
    }, [selectable, disabled, navigationElement, theme])

    useLayoutEffect(() => {
      if (otherProps.group || !autoNavigate || disabled) return
      navigationStateAccessor.set({
        navigatedPath: navigablePath,
        selected: selectable,
      })
    }, [autoNavigate, disabled, navigablePath])

    const navigableProvided = useMemo<NavigableProvided>(
      () => ({
        connectNavigable(element: HTMLElement | null): any {
          setNavigationElement(element)
          return element
        },
        connectFocusable(element: HTMLElement | null): any {
          setFocusElement(element)
          return element
        },
      }),
      []
    )

    return (
      <NavigableContexts.Provider
        parameters={{
          navigablePath,
        }}
      >
        {children?.(navigableProvided)}
      </NavigableContexts.Provider>
    )
  })
)

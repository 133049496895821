import {
  AppBar,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Toolbar,
} from '@material-ui/core'
import { useMemo, useState } from 'react'
import { arrayHelpers } from '../../../../../../helpers/native/arrayHelpers'
import { CustomIcon } from '../../../../../../modules/custom-icon'
import { Gap } from '../../../../../Gap'
import { AutomaticUpdater } from '../../../../modules/update'

const COLUMNS_NUMBER = 3
const SORTS = ['Definition', 'Alphabet'] as const

const useStyles = makeStyles(
  theme => ({
    icons: {
      display: 'flex',
    },
    column: {
      width: 0,
      flexGrow: 1,
    },
    icon1: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    icon2: {
      outline: `1px dotted ${theme.palette.primary.light}`,
    },
    icon3: {},
  }),
  { name: 'IconsPage' }
)

export function IconsPage() {
  const [sortedBy, setSortedBy] = useState<(typeof SORTS)[number]>(SORTS[0])
  const [filter, setFilter] = useState('')

  const names = useMemo(
    () =>
      (sortedBy === 'Definition' ? CustomIcon.names : arrayHelpers.orderBy(CustomIcon.names, name => name)).filter(
        name => name.toLowerCase().includes(filter.toLowerCase())
      ),
    [sortedBy, filter]
  )

  const classes = useStyles()

  return (
    <div>
      <AutomaticUpdater />

      <AppBar position="sticky" color="default">
        <Toolbar>
          <FormControl>
            <InputLabel id="sort-by">Sort by</InputLabel>
            <Select labelId="sort-by" value={sortedBy} onChange={event => setSortedBy(event.target.value as any)}>
              {SORTS.map(sort => (
                <MenuItem key={sort} value={sort}>
                  {sort}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Gap horizontal={2} />

          <TextField label="Search" value={filter} onChange={event => setFilter(event.target.value)} />
        </Toolbar>
      </AppBar>

      <div className={classes.icons}>
        {Array(COLUMNS_NUMBER)
          .fill(null)
          .map((_, column) => (
            <List key={column} className={classes.column}>
              {names
                .slice(
                  column * Math.ceil(names.length / COLUMNS_NUMBER),
                  (column + 1) * Math.ceil(names.length / COLUMNS_NUMBER)
                )
                .map(name => (
                  <ListItem key={name}>
                    <ListItemIcon>
                      <CustomIcon name={name} size="large" className={classes.icon1} />
                    </ListItemIcon>
                    <ListItemIcon>
                      <CustomIcon name={name} size="large" className={classes.icon2} />
                    </ListItemIcon>
                    <ListItemIcon>
                      <CustomIcon name={name} size="large" className={classes.icon3} />
                    </ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItem>
                ))}
            </List>
          ))}
      </div>
    </div>
  )
}

import { PartialRecord, HandlerOutput } from '@zettelooo/commons'
import { PropsWithChildren, useCallback, useRef } from 'react'
import { useEffectOnce } from 'react-use'
import { MemoizedContextProvider } from '../../components/MemoizedContextProvider'
import { Context } from './Context'
import { isActive } from './isActive'
import { Message } from './Message'

export function Provider({ children }: PropsWithChildren<{}>) {
  const messageResolversDictionaryRef = useRef<
    PartialRecord<Message.SubType, ((message: Omit<Message, 'type'>) => void)[]>
  >({})
  const messageHandlersRef = useRef<((message: Omit<Message, 'type'>) => HandlerOutput)[]>([])

  useEffectOnce(() => {
    if (!isActive) return

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)

    function handleMessage(event: MessageEvent): void {
      if (event.data?.type !== Message.TYPE) return
      const message = event.data as Message
      const messageResolver = messageResolversDictionaryRef.current[message.subType]?.shift()
      if (messageResolver) {
        messageResolver(message)
        return
      }
      for (let i = 0; i < messageHandlersRef.current.length; i += 1) {
        if (messageHandlersRef.current[i](message) !== 'not handled') return
      }
    }
  })

  return (
    <MemoizedContextProvider
      context={Context}
      value={{
        postMessageStatic: useCallback(
          (sendingMessage: Omit<Message, 'type'>, receivingMessageType?: Message.SubType): any => {
            if (!isActive) return
            const typedSendingMessage: Message = {
              type: Message.TYPE,
              ...(sendingMessage as any),
            }
            if (receivingMessageType)
              return new Promise((resolve, reject) => {
                window.parent.postMessage(typedSendingMessage, '*')
                const messageResolvers =
                  messageResolversDictionaryRef.current[receivingMessageType] ||
                  (messageResolversDictionaryRef.current[receivingMessageType] = [])
                messageResolvers.push(resolve)
                setTimeout(() => {
                  const index = messageResolvers.indexOf(resolve)
                  if (index >= 0) {
                    messageResolvers.splice(index, 1)
                    reject(Error('Timed out.'))
                  }
                }, 10 * 1000)
              })
            window.parent.postMessage(typedSendingMessage, '*')
          },
          []
        ),

        registerMessageHandlerStatic: useCallback(handler => {
          messageHandlersRef.current.push(handler)
          return () => {
            messageHandlersRef.current.splice(messageHandlersRef.current.indexOf(handler), 1)
          }
        }, []),
      }}
      dependencies={[]}
    >
      {children}
    </MemoizedContextProvider>
  )
}

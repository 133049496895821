import { AdminView } from './AdminView'
import { Authentication } from './Authentication'
import { SignInView } from './SignInView'

export function Views() {
  const { signedInUser } = Authentication.useSignedInUser()

  if (!signedInUser) return <SignInView />

  return <AdminView />
}

import { Broadcaster } from '../../../../modules/service-worker'
import { ActionsDatabase } from './actions'
import { ExtensionsDatabase } from './extensions'
import { MutablesDatabase } from './mutables'
import { PersistentDatabase } from './persistent'
import { Databases } from './types'

export function createDatabases(broadcaster: Broadcaster): Databases {
  return {
    persistentDatabase: new PersistentDatabase(broadcaster),
    actionsDatabase: new ActionsDatabase(broadcaster),
    mutablesDatabase: new MutablesDatabase(broadcaster),
    extensionsDatabase: new ExtensionsDatabase(broadcaster),
  }
}

import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { ComponentProps, forwardRef, ReactNode, RefObject, useImperativeHandle, useRef } from 'react'
import { useGetSet } from 'react-use'
import { useCombineRefs } from '../hooks/useCombineRefs'
import { useRefWrap } from '../hooks/useRefWrap'
import { NavigableWithCommands, useManageCommands } from '../modules/commands'
import { CustomIcon } from '../modules/custom-icon'
import { commonKeyboardCombinations } from '../modules/keyboard-handler'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: theme.spacing(0, 0.5),
    },
    block: {
      display: 'flex',
    },
    hoverEffect: {
      padding: theme.spacing(0.5, 1),
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    enabled: {
      cursor: 'pointer',
    },
    icon: {
      margin: theme.spacing(0.5, 0, 0.5, 1),
    },
  }),
  { name: 'Dropdown' }
)

export const Dropdown = forwardRef<
  {
    open(): void
    close(): void
    isOpened(): boolean
  },
  {
    label: ReactNode | ((props: { open: boolean; disabled: boolean; hideIcon: boolean }) => ReactNode)
    initiallyOpen?: boolean
    hideIcon?: boolean
    block?: boolean
    disableHoverEffect?: boolean
    disabled?: boolean
    className?: string
    overrideIconProps?: (props: { open: boolean; disabled: boolean }) => ComponentProps<typeof CustomIcon>
    onOpen?: () => void
    onClose?: () => void
    children?: (props: { open: boolean; close: () => void; rootRef: RefObject<HTMLDivElement> }) => ReactNode
  }
>(function Dropdown(
  {
    label,
    initiallyOpen = false,
    hideIcon = false,
    block = false,
    disableHoverEffect = false,
    disabled = false,
    className,
    overrideIconProps,
    onOpen,
    onClose,
    children,
  },
  ref
) {
  const [getOpen, setOpen] = useGetSet(initiallyOpen)

  const rootRef = useRef<HTMLDivElement>(null)

  const disabledRef = useRefWrap(disabled)
  const onOpenRef = useRefWrap(onOpen)
  const onCloseRef = useRefWrap(onClose)

  useImperativeHandle(
    ref,
    () => ({
      open() {
        setOpen(true)
      },
      close() {
        setOpen(false)
      },
      isOpened() {
        return getOpen()
      },
    }),
    []
  )

  const combineRefs = useCombineRefs(rootRef)

  const { runCommandStatic } = useManageCommands()

  const defaultIconProps: ComponentProps<typeof CustomIcon> = {
    name: getOpen() ? 'ChevronUp' : 'ChevronDown',
    size: 'small',
    color: 'text.secondary',
  }
  const iconProps = { ...defaultIconProps, ...overrideIconProps?.({ open: getOpen(), disabled }) }

  const classes = useStyles()

  return (
    <NavigableWithCommands
      padding={[1, 0]}
      commandGroup={({ navigableRef }) => ({
        name: 'dropdown',
        disabled() {
          return disabledRef.current
        },
        commands: [
          {
            name: 'toggleOpen',
            disabled() {
              return getOpen()
            },
            defaultShortcutKeys: commonKeyboardCombinations.enter,
            handler() {
              const state = !disabledRef.current && !getOpen()
              setOpen(state)
              state ? onOpenRef.current?.() : onCloseRef.current?.()
            },
          },
        ],
      })}
    >
      {({ connectNavigable }) => (
        <>
          <div
            ref={combineRefs(connectNavigable)}
            className={classNames(
              classes.root,
              block && classes.block,
              !disableHoverEffect && classes.hoverEffect,
              !disabled && classes.enabled,
              className
            )}
            onClick={event => {
              event.stopPropagation()
              event.preventDefault()
              runCommandStatic('dropdown.toggleOpen')
            }}
          >
            {typeof label === 'function' ? { open: getOpen(), disabled, hideIcon } : label}

            {!hideIcon && <CustomIcon {...iconProps} className={classNames(classes.icon, iconProps.className)} />}
          </div>

          {children?.({
            open: getOpen(),
            close() {
              setOpen(false)
              onClose?.()
            },
            rootRef,
          })}
        </>
      )}
    </NavigableWithCommands>
  )
})

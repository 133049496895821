import { Avatar, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core'
import { Extension } from '@zettelooo/server-shared'
import { TypographyNoWrap } from '../../../../../TypographyNoWrap'
import { useServices } from '../../../services'
import { InstallButton } from './InstallButton'

const useStyles = makeStyles(
  theme => ({
    root: {
      borderRadius: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2),
    },
    avatar: {
      borderRadius: 0,
    },
    installButton: {
      margin: theme.spacing(0.5, -1, 0, 0.5),
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: 'ExtensionItem' }
)

export function ExtensionItem({ extension, onClick }: { extension: Extension.Header; onClick(): void }) {
  const { services } = useServices()

  const classes = useStyles()

  return (
    <ListItem button className={classes.root} onClick={onClick}>
      <ListItemAvatar>
        <Avatar
          src={
            extension.avatar.file
              ? services.extension.getFileUrl(extension, extension.avatar.file)
              : extension.avatar.dataUrl
          }
          alt={extension.name}
          className={classes.avatar}
        />
      </ListItemAvatar>

      <ListItemText
        primary={
          <div className={classes.flex}>
            <TypographyNoWrap variant="body1" color="textPrimary">
              {extension.name}
            </TypographyNoWrap>
            <Typography variant="caption" color="textSecondary">
              {extension.version || 'N/A'}
            </Typography>
          </div>
        }
        primaryTypographyProps={{ component: 'div', gutterBottom: true }}
        secondary={
          <div>
            <TypographyNoWrap variant="body2" color="textSecondary">
              {extension.description}
            </TypographyNoWrap>

            <div className={classes.flex}>
              <TypographyNoWrap variant="body2" color="textPrimary">
                {extension.author.name}
              </TypographyNoWrap>

              <InstallButton className={classes.installButton} extension={extension} />
            </div>
          </div>
        }
        secondaryTypographyProps={{ component: 'div' }}
      />
    </ListItem>
  )
}

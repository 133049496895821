import { Id } from '@zettelooo/commons'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { useDatabases } from '../../../../../../../modules/databases'
import {
  OnboardingTour,
  OnboardingWelcomeTourStep,
  useOnboardingCondition,
  useOnboardingPreparation,
} from '../../../../../../../modules/onboarding'
import { AccountData } from '../../../../../modules/account-data'
import { Panel, useSwitchPanel } from '../../panel'

export function usePanelOnboardingPreparation(panel: Panel | undefined): void {
  const { accountPagesOrdered } = useContexts(AccountData.Contexts)

  const {
    databases: { mutablesDatabase },
  } = useDatabases()

  const { switchPanelStatic } = useSwitchPanel()

  const aPageIsVisible = panel?.type === Panel.Type.Page

  useOnboardingPreparation(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.Card,
    async (): Promise<void> => {
      if (aPageIsVisible) {
        if (await checkPageHasCards(panel.pageId)) return
      }

      for (let i = 0; i < accountPagesOrdered.length; i += 1) {
        const page = accountPagesOrdered[i]

        // Otherwise, we need to get all the card of all the pages in parallel first, then decide a page! That absolutely makes no sense:
        // eslint-disable-next-line no-await-in-loop
        if (await checkPageHasCards(page.id)) {
          switchPanelStatic({
            type: Panel.Type.Page,
            pageId: page.id,
          })
          return
        }
      }

      async function checkPageHasCards(pageId: Id): Promise<boolean> {
        const cards = await mutablesDatabase.getCardsForPage(pageId)
        return cards.length > 0
      }
    },
    [aPageIsVisible, accountPagesOrdered, mutablesDatabase]
  )

  useOnboardingCondition(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.Card,
    aPageIsVisible,
    'Please, open a page with some notes to continue the tour.'
  )

  const aComposerIsVisible = panel?.type === Panel.Type.Page

  useOnboardingPreparation(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.CardAdder,
    async (): Promise<void> => {
      if (aComposerIsVisible) return

      for (let i = 0; i < accountPagesOrdered.length; i += 1) {
        const page = accountPagesOrdered[i]

        switchPanelStatic({
          type: Panel.Type.Page,
          pageId: page.id,
        })
        return
      }
    },
    [aComposerIsVisible, accountPagesOrdered]
  )

  useOnboardingCondition(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.CardAdder,
    aComposerIsVisible,
    'Please, open a page that you have access to create cards in it.'
  )

  const anActionMenuIsVisible = panel?.type === Panel.Type.Page

  useOnboardingPreparation(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.PageSideBar,
    async (): Promise<void> => {
      if (anActionMenuIsVisible) return

      const page = accountPagesOrdered[0]
      if (page) {
        switchPanelStatic({
          type: Panel.Type.Page,
          pageId: page.id,
        })
      }
    },
    [anActionMenuIsVisible, accountPagesOrdered]
  )

  useOnboardingCondition(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.PageSideBar,
    anActionMenuIsVisible,
    'Please, open a page to continue.'
  )
}

import { useEffect } from 'react'
import { objectHelpers } from '../../../../../helpers/native/objectHelpers'
import { webConfig } from '../../../../../modules/web-config'
import { EphemeralKey, useEphemeral } from '../../ephemeral'

export function useOnMemoryModelsMaintenance(): void {
  const { ephemeral } = useEphemeral()

  useEffect(() => {
    ephemeral(EphemeralKey.OnMemoryModelsToBeCleared).set({})

    const interval = setInterval(() => {
      const toBeClearedRegistrationKeys = Object.keys(ephemeral(EphemeralKey.OnMemoryModelsToBeCleared).get())
      if (toBeClearedRegistrationKeys.length > 0) {
        ephemeral(EphemeralKey.OnMemoryModelsRegistrations).set(
          objectHelpers.omitAll(ephemeral(EphemeralKey.OnMemoryModelsRegistrations).get(), toBeClearedRegistrationKeys)
        )
      }
      ephemeral(EphemeralKey.OnMemoryModelsToBeCleared).set(
        objectHelpers.map(ephemeral(EphemeralKey.OnMemoryModelsRegistrations).get(), () => true as const)
      )
    }, webConfig.timings.clearOnMemoryModelsAttemptInterval)

    return () => clearInterval(interval)
  }, [ephemeral])
}

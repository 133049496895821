import { makeStyles, MenuProps, PopoverPosition } from '@material-ui/core'
import { MouseEvent, useCallback, useState } from 'react'
import { useGetSet } from 'react-use'

const useStyles = makeStyles(
  theme => ({
    paper: {
      minWidth: theme.spacing(20),
    },
  }),
  { name: 'useContextMenu' }
)

export function useContextMenu(): {
  handleContextMenuStatic(event: Pick<MouseEvent, 'preventDefault' | 'clientX' | 'clientY'>): void
  closeContextMenuStatic(): void
  isContextMenuOpenStatic(): boolean
  menuProps: Pick<MenuProps, 'open' | 'onClose' | 'anchorReference' | 'anchorPosition' | 'classes'>
} {
  const [getOpen, setOpen] = useGetSet(false)
  const [position, setPosition] = useState<PopoverPosition>({ top: 0, left: 0 })

  const classes = useStyles()

  return {
    handleContextMenuStatic: useCallback(event => {
      event.preventDefault()
      setPosition({
        top: event.clientY,
        left: event.clientX,
      })
      setOpen(true)
    }, []),

    closeContextMenuStatic: useCallback(() => setOpen(false), []),

    isContextMenuOpenStatic: getOpen,

    menuProps: {
      open: getOpen(),
      onClose: () => setOpen(false),
      anchorReference: 'anchorPosition',
      anchorPosition: position,
      classes: { paper: classes.paper },
    },
  }
}

import { extendThemeOptions } from '../extendThemeOptions'
import { darkThemeOptions } from './darkThemeOptions'

export const darkRtlThemeOptions = extendThemeOptions(darkThemeOptions, {
  direction: 'rtl',
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          // direction: 'rtl'
        },
      },
    },
  },
})

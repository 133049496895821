import { SearchServiceSignature } from '@zettelooo/server-shared'
import { DemoMode } from '../../../../../modules/demo-mode'
import { PeekMode } from '../../../../../modules/peek-mode'
import { Persistent } from '../../persistent'
import { RestService } from './RestService'

export class SearchService extends RestService {
  constructor(persistent: Persistent) {
    super('search', persistent)
  }

  async onUsers(query: string): Promise<SearchServiceSignature.BasicUser[]> {
    if (DemoMode.data || PeekMode.data) return [] // TODO: Do something else here to enable user invitation to page in the demo mode

    const { basicUsers } = await this.requestAuthenticated<
      SearchServiceSignature.OnUsers.Request,
      SearchServiceSignature.OnUsers.Response
    >('on-users', {
      query,
    })

    return [...basicUsers]
  }
}

import { Divider, List, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Model } from '@zettelooo/server-shared'
import { RefCallback } from 'react'
import { useCombineRefs } from '../../../../../../../hooks/useCombineRefs'
import { sendAnalyticEvent } from '../../../../../../../modules/analytics'
import { useManageCommands } from '../../../../../../../modules/commands'
import { CustomIcon } from '../../../../../../../modules/custom-icon'
import { HoverButton } from '../../../../../../HoverButton'
import { HoverContextMenu } from '../../../../../../menu/HoverContextMenu'
import { MenuItemWithKeyboardShortcut } from '../../../../../../menu/MenuItemWithKeyboardShortcut'

const useStyles = makeStyles(
  theme => ({
    root: {
      top: theme.spacing(2.5),
      right: theme.spacing(1),
      display: 'flex',
    },
    reorderButton: {
      cursor: 'grab',
      '&:active': {
        cursor: 'grabbing',
      },
    },
    deleteButton: {
      color: theme.palette.error.main,
    },
  }),
  { name: 'CardContextMenu' }
)

export function CardContextMenu({
  page,
  readOnly = false,
  reorderButtonRef,
  extendedMenuItems,
}: {
  page: Model.Page
  readOnly?: boolean
  reorderButtonRef?: RefCallback<HTMLElement>
  extendedMenuItems: readonly ZettelExtensions.LifeSpan.Shared.Card.MenuItem[]
}) {
  const combineRefs = useCombineRefs(reorderButtonRef)

  const { runCommandStatic } = useManageCommands()

  const classes = useStyles()

  return (
    <HoverContextMenu className={classes.root}>
      {({ connectMoreMenuButton, moreMenuProps }) => (
        <>
          {/* TODO: Tooltip doesn't go well with the dragging snapshot, so I used the native `title` attribute instead: */}
          <HoverButton ref={combineRefs(connectMoreMenuButton)} title="Drag to move">
            <CustomIcon name="SixDots" size="small" />
          </HoverButton>

          <Menu {...moreMenuProps} transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <List disablePadding>
              {!readOnly && (
                <MenuItem
                  onClick={() => {
                    sendAnalyticEvent('Card Context Menu', 'Edit')
                    moreMenuProps.onClose()
                    runCommandStatic('card.edit')
                  }}
                >
                  Edit
                </MenuItem>
              )}

              {!readOnly && (
                <MenuItemWithKeyboardShortcut
                  shortcutKeys={{ alt: true, code: 'KeyD' }}
                  enableShortcutKeys={moreMenuProps.open}
                  onClick={() => {
                    sendAnalyticEvent('Card Context Menu', 'Duplicate')
                    moreMenuProps.onClose()
                    runCommandStatic('card.duplicate')
                  }}
                >
                  Duplicate
                </MenuItemWithKeyboardShortcut>
              )}

              {page.public && (
                <MenuItem
                  onClick={() => {
                    sendAnalyticEvent('Card Context Menu', 'Copy public URL')
                    moreMenuProps.onClose()
                    runCommandStatic('card.copyPublicUrl')
                  }}
                >
                  Copy public URL
                </MenuItem>
              )}

              {!readOnly && (
                <MenuItemWithKeyboardShortcut
                  className={classes.deleteButton}
                  shortcutKeys="Delete"
                  enableShortcutKeys={moreMenuProps.open}
                  onClick={() => {
                    sendAnalyticEvent('Card Context Menu', 'Delete')
                    moreMenuProps.onClose()
                    runCommandStatic('card.delete')
                  }}
                >
                  Delete
                </MenuItemWithKeyboardShortcut>
              )}
            </List>

            {extendedMenuItems.length > 0 && (
              <div>
                <Divider />

                <List disablePadding>
                  {extendedMenuItems.map((extendedMenuItem, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        sendAnalyticEvent('Card Context Menu', 'Click on item', extendedMenuItem.title)
                        moreMenuProps.onClose()
                        extendedMenuItem.handler()
                      }}
                    >
                      {extendedMenuItem.title}
                    </MenuItem>
                  ))}
                </List>
              </div>
            )}
          </Menu>
        </>
      )}
    </HoverContextMenu>
  )
}

import { PropsWithChildren } from 'react'
import { useSyncEffect } from '../../../../hooks/useSyncEffect'
import { usePersistent } from '../persistent'
import { initializeLocalization } from './initialize-localization'

export function LocalizationProvider({ children }: PropsWithChildren<{}>) {
  const { persistent } = usePersistent()

  useSyncEffect(() => {
    initializeLocalization(persistent)
  }, [persistent])

  return <>{children}</>
}

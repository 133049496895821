import { useContext, useLayoutEffect } from 'react'
import { Context } from './Context'

export function useItemVisiblityCheck(visible: boolean): void {
  const setNumberOfItems = useContext(Context)

  useLayoutEffect(() => {
    if (visible) {
      setNumberOfItems(current => current + 1)
      return () => void setNumberOfItems(current => current - 1)
    }
  }, [visible])
}

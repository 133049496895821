import { captureException } from '@sentry/react'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useCommonStyles } from '../../../../../../../../hooks/useCommonStyles'
import { sendAnalyticEvent } from '../../../../../../../../modules/analytics'
import { webConfig } from '../../../../../../../../modules/web-config'
import { usePersistent, PersistentKey } from '../../../../../../modules/persistent'
import { PresentationDialogSlide } from '../../../../../../modules/presentation-dialog'
import { StaticService, useServices } from '../../../../../../modules/services'

export function ChangeLogSlides() {
  const { persistent } = usePersistent(PersistentKey.LastDisplayedChangeLog)

  const [changeLogSteps, setChangeLogSteps] = useState<readonly StaticService.UpdateSlides.Step[]>()
  const [changeLogStepIndex, setChangeLogStepIndex] = useState<number>()

  const fetchingRef = useRef(false)

  const { services } = useServices()

  const shouldBeFetched =
    !webConfig.temporary.temporarilyDisabledOrToBeRemoved &&
    persistent[PersistentKey.LastDisplayedChangeLog].forVersion !== webConfig.version

  useEffect(() => {
    if (shouldBeFetched && !fetchingRef.current) {
      fetchingRef.current = true
      services.static
        .getUpdateSlides()
        .then(updateSlides => {
          if (updateSlides.version !== 1) return
          if (updateSlides.contentVersion !== persistent[PersistentKey.LastDisplayedChangeLog].contentVersion) {
            setChangeLogSteps(updateSlides.changeLogSteps)
          }
          persistent[PersistentKey.LastDisplayedChangeLog] = {
            forVersion: webConfig.version,
            contentVersion: updateSlides.contentVersion,
          }
        })
        .catch(error => {
          log.error(error)
          captureException(error, { tags: { module: 'update', action: 'fetch slides' } })
        })
        .finally(() => {
          fetchingRef.current = false
        })
    }
  }, [services, shouldBeFetched])

  useEffect(() => {
    setChangeLogStepIndex(changeLogSteps ? 0 : undefined)
  }, [changeLogSteps])

  const { commonClasses } = useCommonStyles()

  if (!changeLogSteps || changeLogSteps.length === 0 || changeLogStepIndex === undefined) return null

  return (
    <>
      {changeLogSteps.map((step, index) => (
        <PresentationDialogSlide.Component
          key={index}
          open
          onClose={() => setChangeLogStepIndex(undefined)}
          className={classNames(index !== changeLogStepIndex && commonClasses.hidden)}
          caption={step.caption}
          title={step.title}
          description={
            step.descriptionSections && <PresentationDialogSlide.Sections sections={step.descriptionSections} />
          }
          actions={
            <PresentationDialogSlide.Actions
              presentationDialogSlideIsOpen
              stepIndex={changeLogStepIndex}
              stepsCount={changeLogSteps.length}
              onPreviousStep={() => {
                sendAnalyticEvent('ChangeLog Slides', `Click on Previous on index ${changeLogStepIndex}`)
                setChangeLogStepIndex(changeLogStepIndex - 1)
              }}
              onNextStep={() => {
                sendAnalyticEvent('ChangeLog Slides', `Click on Next on index ${changeLogStepIndex}`)
                setChangeLogStepIndex(changeLogStepIndex + 1)
              }}
              onDone={() => {
                sendAnalyticEvent('ChangeLog Slides', 'Click on Done')
                setChangeLogStepIndex(undefined)
              }}
            />
          }
        >
          {step.imageFileName ? (
            <PresentationDialogSlide.Image url={services.static.getFileUrl(step.imageFileName)} />
          ) : step.videoFileName ? (
            <PresentationDialogSlide.Video url={services.static.getFileUrl(step.videoFileName)} />
          ) : null}
        </PresentationDialogSlide.Component>
      ))}
    </>
  )
}

import { IpcChannel, WindowIpcMessageType } from '@zettelooo/desktop-shared'
import { useEffectOnce } from 'react-use'
import { webConfig } from '../../../../modules/web-config'
import { sendIpcMessage } from '../../modules/electron'

export function useInitialIpcMessageHandling(): void {
  useEffectOnce(() => {
    if (webConfig.environment.agent === 'electron') {
      sendIpcMessage(IpcChannel.Window, {
        type: WindowIpcMessageType.WindowReady,
        windowType: window.electronProxy?.windowType!,
      })
    }
  })
}

import { useTheme } from '@material-ui/core'
import { ComponentProps, useCallback } from 'react'
import { usePersistent, PersistentKey } from '../../../../../../modules/persistent'
import { Resizer } from '../components/Resizer'

export function useSideBarSizeHandling(): {
  handleResizerResize: NonNullable<ComponentProps<typeof Resizer>['onResize']>
} {
  const { persistent } = usePersistent()

  const theme = useTheme()

  return {
    handleResizerResize: useCallback(
      difference => {
        const sidebarSize = persistent[PersistentKey.SideBarSize] ?? theme.constants.sidebar.expandedMaximumSize ?? 0
        const meantSideBarSize = sidebarSize + difference
        const newSideBarSize =
          meantSideBarSize < theme.constants.sidebar.collapsingThreshold
            ? theme.constants.sidebar.collapsedSize
            : meantSideBarSize < theme.constants.sidebar.expandedMinimumSize
            ? theme.constants.sidebar.expandedMinimumSize
            : meantSideBarSize <= theme.constants.sidebar.expandedMaximumSize
            ? meantSideBarSize
            : theme.constants.sidebar.expandedMaximumSize
        const actualDifference = newSideBarSize - sidebarSize
        persistent[PersistentKey.SideBarSize] = newSideBarSize
        return actualDifference
      },
      [persistent, theme]
    ),
  }
}

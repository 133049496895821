import { OnboardingStep, OnboardingTour } from '../types'
import { useOnboardingState } from './useOnboardingState'

export function useOnboardingStateMatch<T extends OnboardingTour>(
  tour: T,
  step: OnboardingStep<T>
): {
  onboardingStateMatch: boolean
} {
  const { onboardingState } = useOnboardingState()

  return {
    onboardingStateMatch: onboardingState?.tour === tour && onboardingState?.step === step,
  }
}

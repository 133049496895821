import { makeStyles, Typography } from '@material-ui/core'
import { useMemo } from 'react'
import { arrayHelpers } from '../../../../../../../../../../../helpers/native/arrayHelpers'
import { humanizeTimestamp } from '../../../../../../../../../../../modules/date-time'
import { ActivityCard } from './ActivityCard'

const useStyles = makeStyles(
  theme => ({
    group: {
      marginBottom: theme.spacing(5),
      maxWidth: theme.spacing(95),
    },
    date: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'ActivityList' }
)

export function ActivityList({ cardActivities }: { cardActivities: readonly any[] }) {
  const sortedCardActivitiesGroupedByDate = useMemo(() => {
    const sortedCardActivities = arrayHelpers.orderByDescending(cardActivities, 'timestamp')
    return arrayHelpers.groupBy(sortedCardActivities, cardActivity =>
      humanizeTimestamp(cardActivity.timestamp, 'date only')
    )
  }, [cardActivities])

  const classes = useStyles()

  return (
    <div>
      {Object.keys(sortedCardActivitiesGroupedByDate).map(date => (
        <div key={date} className={classes.group}>
          <Typography variant="h5" className={classes.date}>
            {date}
          </Typography>

          {sortedCardActivitiesGroupedByDate[date].map(cardActivity => (
            <ActivityCard key={`${cardActivity.cardId}-${cardActivity.timestamp}`} cardActivity={cardActivity} />
          ))}
        </div>
      ))}
    </div>
  )
}

import { setAmplitudeUserIdentifier } from './amplitude'
import { setGoogleAnalyticsUserIdentifier } from './google-analytics'
import { setMixpanelUserIdentifier } from './mixpanel'
import { Account } from './types'

export function setAnalyticUserIdentifier(account: Account | undefined) {
  const userIdentifier = account ? `${account.name}|${account.userName}|${account.id}` : ''

  setGoogleAnalyticsUserIdentifier(userIdentifier)
  setAmplitudeUserIdentifier(userIdentifier)
  setMixpanelUserIdentifier(account, userIdentifier)
}

import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { useContext } from 'react'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { useManageCommands } from '../../../../../../../../../modules/commands'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { DeviceProvider } from '../../../../../../../../../modules/device'
import { useAppHistoryState } from '../../../../../../../modules/app-history'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      padding: theme.spacing(0.5),
    },
  }),
  { name: 'NavigationItem' }
)

export function NavigationItem({ className }: { className?: string }) {
  const { isMobile } = useContext(DeviceProvider.Context)

  const { appHistoryStateStatic } = useAppHistoryState()

  const { runCommandStatic } = useManageCommands()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <div className={classNames(classes.root, className)}>
      <Tooltip title="Back">
        <span>
          <IconButton
            disabled={!appHistoryStateStatic.canGoBackward}
            className={classNames(classes.button, commonClasses.electronNoDrag)}
            onClick={() => runCommandStatic('navigation.goBackward')}
          >
            <CustomIcon name="ChevronLeft" />
          </IconButton>
        </span>
      </Tooltip>

      {!isMobile && (
        <Tooltip title="Forward">
          <span>
            <IconButton
              disabled={!appHistoryStateStatic.canGoForward}
              className={classNames(classes.button, commonClasses.electronNoDrag)}
              onClick={() => runCommandStatic('navigation.goForward')}
            >
              <CustomIcon name="ChevronRight" />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </div>
  )
}

import { Id } from '@zettelooo/commons'
import { Extension } from '@zettelooo/server-shared'
import Dexie from 'dexie'
import { arrayHelpers } from '../../../../../helpers/native/arrayHelpers'
import { DemoMode } from '../../../../../modules/demo-mode'
import { PeekMode } from '../../../../../modules/peek-mode'
import { Broadcaster } from '../../../../../modules/service-worker'
import { ExtensionBody, ExtensionData, ExtensionModel } from '../../extension'
import { BroadcasterChannel } from '../../service-worker'
import { Database } from '../Database'
import { DatabaseTable } from '../DatabaseTable'

export class ExtensionsDatabase implements Database {
  private readonly dexie!: Dexie

  private readonly headers: DatabaseTable<Extension.Header>
  private readonly bodies: DatabaseTable<ExtensionBody>
  private readonly data: DatabaseTable<ExtensionData>

  private readonly subscriptions: ExtensionsDatabase.Event.Subscription[] = []

  constructor(private readonly broadcaster: Broadcaster) {
    if (DemoMode.data || PeekMode.data) {
      this.headers = DatabaseTable.createOnMemory('id')
      this.bodies = DatabaseTable.createOnMemory('id')
      this.data = DatabaseTable.createOnMemory('id')

      // Demo table hook callbacks need to not be arrow functions and depend on their this context. So, we have no choice but to alias this:
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const database = this

      this.headers.memory.onCreating = function (key, record) {
        this.onSuccess = () => database.fire({ id: key, type: 'created' })
      }
      this.headers.memory.onUpdating = function (key, newRecord, oldRecord) {
        this.onSuccess = () => database.fire({ id: key, type: 'updated' })
      }
      this.headers.memory.onDeleting = function (key, record) {
        this.onSuccess = () => database.fire({ id: key, type: 'deleted' })
      }

      return
    }

    this.dexie = new Dexie('ExtensionsDatabase')

    this.dexie
      .version(9)
      .stores({
        headers: 'id, version',
        bodies: 'id',
        data: 'id',
      })
      .upgrade(transaction => {
        // This means we may safely remove the older version migrations:
        return Promise.all(transaction.db.tables.map(table => table.clear()))
      })

    this.headers = DatabaseTable.createOnDexie(this.dexie, 'headers')
    this.bodies = DatabaseTable.createOnDexie(this.dexie, 'bodies')
    this.data = DatabaseTable.createOnDexie(this.dexie, 'data')

    // Dexie's hook callbacks need to not be arrow functions and depend on their this context. So, we have no choice but to alias this:
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const database = this

    this.headers.dexie.hook('creating', function (primaryKey, object, transaction) {
      // Modify (mutate) the object to the final object to write
      this.onsuccess = () => database.fire({ id: primaryKey, type: 'created' }, true)
    })
    this.headers.dexie.hook(
      'updating',
      function (modifications: Partial<Extension.Header>, primaryKey, object, transaction) {
        // Return the extra modification, will be merged with the current modifications
        this.onsuccess = () => database.fire({ id: primaryKey, type: 'updated' }, true)
      }
    )
    this.headers.dexie.hook('reading', function (object) {
      // Modify (mutate) the object and return it as final object to read
      return object
    })
    this.headers.dexie.hook('deleting', function (primaryKey, object, transaction) {
      // Any modifications will be ignored
      this.onsuccess = () => database.fire({ id: primaryKey, type: 'deleted' }, true)
    })

    this.dexie.open()

    broadcaster.addMessageEventListener<ExtensionsDatabase.BroadcasterMessage>(
      BroadcasterChannel.ExtensionsDatabase,
      message => this.fire(message.event)
    )
  }

  dispose(): void {
    if (DemoMode.data || PeekMode.data) return

    this.dexie.close()
  }

  async clearAll(): Promise<void> {
    if (DemoMode.data || PeekMode.data) {
      this.headers.memory.deleteAll()
      this.bodies.memory.deleteAll()
      this.data.memory.deleteAll()
      return
    }

    await Promise.all(this.dexie.tables.map(table => table.clear()))
  }

  async getAllHeaders(): Promise<Extension.Header[]> {
    if (DemoMode.data || PeekMode.data) return this.headers.memory.getAll()

    return this.headers.dexie.toArray()
  }

  async getAll(): Promise<ExtensionModel[]> {
    if (DemoMode.data || PeekMode.data) {
      const headers = this.headers.memory.getAll()
      const bodies = this.bodies.memory.getAll()
      const bodiesById = arrayHelpers.toDictionaryById(bodies)
      return headers.map(header => ({
        ...header,
        ...bodiesById[header.id],
      }))
    }

    return this.dexie.transaction('readonly', this.headers.dexie, this.bodies.dexie, async () => {
      const headers = await this.headers.dexie.toArray()
      const bodies = await this.bodies.dexie.toArray()
      const bodiesById = arrayHelpers.toDictionaryById(bodies)
      return headers.map(header => ({
        ...header,
        ...bodiesById[header.id],
      }))
    })
  }

  async getHeader(id: Id): Promise<Extension.Header | undefined> {
    if (DemoMode.data || PeekMode.data) return this.headers.memory.get(id)

    return this.headers.dexie.get(id)
  }

  async get(id: Id): Promise<ExtensionModel | undefined> {
    if (DemoMode.data || PeekMode.data) {
      const header = this.headers.memory.get(id)
      const body = this.bodies.memory.get(id)
      if (!header || !body) return undefined
      return {
        ...header,
        ...body,
      }
    }

    return this.dexie.transaction('readonly', this.headers.dexie, this.bodies.dexie, async () => {
      const header = await this.headers.dexie.get(id)
      const body = await this.bodies.dexie.get(id)
      if (!header || !body) return undefined
      return {
        ...header,
        ...body,
      }
    })
  }

  async put(extension: ExtensionModel): Promise<void> {
    if (DemoMode.data || PeekMode.data) {
      const { js, ...header } = extension
      const body: ExtensionBody = { id: header.id, js }
      // We need to make sure the body is being updated before the header,
      // so we won't get an update event with an updated header with an old body:
      this.bodies.memory.put(body)
      this.headers.memory.put(header)
      return
    }

    await this.dexie.transaction('readwrite', this.headers.dexie, this.bodies.dexie, async () => {
      const { js, ...header } = extension
      const body: ExtensionBody = { id: header.id, js }
      // We need to make sure the body is being updated before the header,
      // so we won't get an update event with an updated header with an old body:
      await this.bodies.dexie.put(body)
      await this.headers.dexie.put(header)
    })
  }

  async remove(id: Id): Promise<void> {
    if (DemoMode.data || PeekMode.data) {
      this.headers.memory.delete(id)
      this.bodies.memory.delete(id)
      this.data.memory.delete(id)
      return
    }

    await this.dexie.transaction('readwrite', this.headers.dexie, this.bodies.dexie, this.data.dexie, () =>
      Promise.all([this.headers.dexie.delete(id), this.bodies.dexie.delete(id), this.data.dexie.delete(id)])
    )
  }

  async readData(id: Id): Promise<any> {
    if (DemoMode.data || PeekMode.data) return this.data.memory.get(id)?.data

    const document = await this.data.dexie.get(id)
    return document?.data
  }

  async writeData(id: Id, data: any): Promise<void> {
    if (DemoMode.data || PeekMode.data) {
      if (data === undefined) {
        this.data.memory.delete(id)
      } else {
        this.data.memory.put({ id, data })
      }
      return
    }

    if (data === undefined) {
      await this.data.dexie.delete(id)
    } else {
      await this.data.dexie.put({ id, data })
    }
  }

  subscribe(subscription: ExtensionsDatabase.Event.Subscription): void {
    this.subscriptions.push(subscription)
  }

  unsubscribe(subscription: ExtensionsDatabase.Event.Subscription): void {
    const index = this.subscriptions.indexOf(subscription)
    if (index >= 0) {
      this.subscriptions.splice(index, 1)
    }
  }

  private fire(event: ExtensionsDatabase.Event, propagateEventThroughServiceWorker?: boolean): void {
    const subscriptions = [...this.subscriptions] // It's required, because some subscription execution may change this.subscription directly because of setStates in the middle of the process
    subscriptions.forEach(subscription => subscription(event))

    if (propagateEventThroughServiceWorker) {
      this.broadcaster.postMessage<ExtensionsDatabase.BroadcasterMessage>(BroadcasterChannel.ExtensionsDatabase, {
        event,
      })
    }
  }
}

export namespace ExtensionsDatabase {
  export interface Event {
    readonly id: Id
    readonly type: 'created' | 'updated' | 'deleted'
  }

  export namespace Event {
    export type Handler = (event: Event) => void

    export type Subscription = Handler
  }

  export interface BroadcasterMessage {
    readonly event: Event
  }
}

import { Typography } from '@material-ui/core'
import { sendAnalyticEvent } from '../../../../../../../../../modules/analytics'
import { useManageCommands } from '../../../../../../../../../modules/commands'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { KeyboardCombinationsView } from '../../../../../../../../../modules/keyboard-handler'
import { ButtonItem } from './ButtonItem'

export function ComposeItem() {
  const { runCommandStatic } = useManageCommands()

  return (
    <ButtonItem
      icon={<CustomIcon name="EditField" size={2.5} />}
      tooltipTitle={<KeyboardCombinationsView combinations={{ code: 'KeyC' }} prefix="New card" highlight />}
      onClick={() => {
        sendAnalyticEvent('Side Bar', 'Click on Compose Item')
        runCommandStatic('main.compose')
      }}
    >
      <Typography variant="subtitle1" noWrap>
        New card
      </Typography>
    </ButtonItem>
  )
}

import { environment } from './environment'

const isDeveloperLogsAllowed = environment.node === 'development' || environment.target === 'stage'

export const developerLogs = {
  i18next: false && isDeveloperLogsAllowed,
  actionSynchronization: true && isDeveloperLogsAllowed,
  webSocket: true && isDeveloperLogsAllowed,
  navigation: false && isDeveloperLogsAllowed,
  blocksComposer: false && isDeveloperLogsAllowed,
  commands: true && isDeveloperLogsAllowed,
  superWindowClient: true && isDeveloperLogsAllowed,
  extensions: true && isDeveloperLogsAllowed,
  smartCommand: true && isDeveloperLogsAllowed,
} as const

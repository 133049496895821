import { Grid, makeStyles } from '@material-ui/core'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Model } from '@zettelooo/server-shared'
import { useMemo } from 'react'
import { useCommonStyles } from '../../../../../../../hooks/useCommonStyles'
import { useAnalyticSendEvent } from '../../../../../../../modules/analytics'
import { useContexts } from '../../../../../../../modules/contexts'
import { CustomAvatar } from '../../../../../../CustomAvatar'
import { TypographyNoWrap } from '../../../../../../TypographyNoWrap'
import { useAppHistoryControl } from '../../../../../modules/app-history'
import { ExtensionRolesProvider, ExtensionScopeProvider } from '../../../../../modules/extension'
import { useRegisterOnMemoryModels } from '../../../../../modules/super-window-client'
import { AutomaticUpdater } from '../../../../../modules/update'
import { Initializer } from '../../../../Initializer'
import { TitleBarAppNameItem } from '../../../../TitleBarAppNameItem'
import { GLOBAL_PADDING_X_UNIT } from '../../../constants'
import { getPageIdFromPublicId } from '../../../helpers/getPageIdFromPublicId'
import { getPagePublicId } from '../../../helpers/getPagePublicId'
import { CardsViewer, CardViewer } from '../../../modules/card'
import { routes } from '../../../modules/routes'
import { TitleBarLayout } from '../../TitleBarLayout'
import { AccountItem } from './AccountItem'
import { TryItem } from './TryItem'
import { useCommands } from './useCommands'
import { useExtensionPublicPageViewLifeSpan } from './useExtensionPublicPageViewLifeSpan'
import { useFunnelServiceGetPublicPageMessages } from './useFunnelServiceGetPublicPageMessages'

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, GLOBAL_PADDING_X_UNIT),
    },
    header: {
      margin: 'auto',
      maxWidth: theme.spacing(105),
      height: theme.spacing(8),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
    },
    titleWrapper: {
      width: 0,
      textAlign: 'start',
      overflow: 'hidden',
    },
    title: {
      marginLeft: theme.spacing(0.5),
      whiteSpace: 'nowrap',
    },
    pageLink: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
    },
    logoIcon: {
      marginRight: theme.spacing(1),
    },
    content: {
      height: 0,
      flexGrow: 1,
      overflow: 'auto',
    },
    cardsViewer: {
      height: '100%',
    },
    cardViewer: {
      maxWidth: theme.spacing(100),
      margin: 'auto',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      backgroundColor: 'unset',
    },
  }),
  { name: 'PublicPageViewPage' }
)

export function PublicPageViewPage() {
  const { publicPageId, cardId } = routes.publicPageView.useParams()
  const pageId = getPageIdFromPublicId(publicPageId)

  const { account } = useContexts(Initializer.Contexts)

  useAnalyticSendEvent('Public Page', 'Page View')

  const { stillLoading, usersDictionary, pagesDictionary, cardsDictionary, page, cards } =
    useFunnelServiceGetPublicPageMessages(pageId)
  const card = cardId ? cardsDictionary[cardId] : undefined

  useRegisterOnMemoryModels(
    () => ({
      [Model.Type.User]: Object.values(usersDictionary).filter(Boolean) as Model.User[],
      [Model.Type.Card]: Object.values(cardsDictionary).filter(Boolean) as Model.Card[],
      [Model.Type.Page]: Object.values(pagesDictionary).filter(Boolean) as Model.Page[],
    }),
    [usersDictionary, cardsDictionary, pagesDictionary]
  )

  useCommands()

  const { renderExtensionLifeSpan } = useExtensionPublicPageViewLifeSpan(pageId, page, cards)

  const { appHistoryControlStatic } = useAppHistoryControl()

  const extensionIds = useMemo(
    () => (page ? Model.ExtensionConfiguration.getExtensionIds(page.extensionConfiguration) : []),
    [page]
  )

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  if (!page) return <AutomaticUpdater />

  return (
    <ExtensionRolesProvider role="PublicPageViewPage">
      <ExtensionScopeProvider scope={ZettelExtensions.Scope.Page} value={pageId} extensionIds={extensionIds}>
        <AutomaticUpdater />

        {renderExtensionLifeSpan()}

        <div className={classes.root}>
          <TitleBarLayout>
            <TitleBarAppNameItem inline link />

            <div className={commonClasses.grow} />

            {account ? <AccountItem pageId={pageId} account={account} /> : <TryItem />}
          </TitleBarLayout>

          <Grid container wrap="nowrap" alignItems="center" spacing={1} className={classes.header}>
            <Grid item>
              <CustomAvatar
                size={3.5}
                compact
                avatarFileId={page.avatarFileId}
                name={page.name}
                emoji={page.iconEmoji}
              />
            </Grid>

            <Grid item xs className={classes.titleWrapper}>
              <TypographyNoWrap variant="h5" className={classes.title}>
                {page.name}
              </TypographyNoWrap>
            </Grid>
          </Grid>

          <div className={classes.content}>
            {!cardId ? (
              <CardsViewer
                className={classes.cardsViewer}
                page={page}
                cardsAreLoading={stillLoading}
                cards={cards}
                readOnly
                onCardDoubleClick={(card, event) => {
                  event.preventDefault()
                  appHistoryControlStatic.push(
                    routes.publicPageView.getPath({ publicPageId: getPagePublicId(page), cardId: card.id }, {})
                  )
                }}
              />
            ) : card ? (
              <CardViewer className={classes.cardViewer} page={page} card={card} />
            ) : null}
          </div>
        </div>
      </ExtensionScopeProvider>
    </ExtensionRolesProvider>
  )
}

import { createContext, PropsWithChildren, RefObject, useContext, useEffect, useMemo, useRef } from 'react'
import {
  useOnboardingPreparation,
  OnboardingTour,
  OnboardingWelcomeTourStep,
} from '../../components/App/modules/onboarding'
import { MemoizedContextProvider } from '../../components/MemoizedContextProvider'
import { dateHelpers } from '../../helpers/native/dateHelpers'
import { DeviceProvider } from '../device'
import { useKeyboardHandling } from '../keyboard-handler'
import { CommandBarDialog, CommandBarDialogReferee } from './CommandBarDialog'
import { CommandManager } from './CommandManager'

export const CommandsContext = createContext<{
  commandManager: CommandManager
  commandBarDialogRef: RefObject<CommandBarDialogReferee>
}>(undefined as any)

export function CommandsProvider({
  commandManager,
  children,
}: PropsWithChildren<{
  commandManager?: CommandManager
}>) {
  const { isMobile } = useContext(DeviceProvider.Context)

  const commandBarDialogRef = useRef<CommandBarDialogReferee>(null)

  const evaluatedCommandManager = useMemo(() => commandManager ?? new CommandManager(), [commandManager])

  useKeyboardHandling(
    [
      {
        handler(event) {
          return evaluatedCommandManager.handleKeyPress(event)
        },
      },
    ],
    [evaluatedCommandManager]
  )

  // DoubleClick event is absorbed as "zoom" in iPad and iPhone devices.
  // Therefore, we need to manually detect DoubleTap event on mobile devices:
  useEffect(() => {
    let latestTapTimestamp = 0
    let doubleTapDetected = false

    if (isMobile) {
      window.addEventListener('touchstart', handleTouchStart)
      window.addEventListener('touchend', handleTouchEnd)
      window.addEventListener('touchcancel', handleTouchCancel)

      return () => {
        window.removeEventListener('touchstart', handleTouchStart)
        window.removeEventListener('touchend', handleTouchEnd)
        window.removeEventListener('touchcancel', handleTouchCancel)
      }
    }

    function handleTouchStart(event: TouchEvent): void {
      if (dateHelpers.getCurrentTimestamp() < latestTapTimestamp + 300) {
        doubleTapDetected = true
        event.preventDefault()
        commandBarDialogRef.current?.openCommands()
      }
    }
    function handleTouchEnd(event: TouchEvent): void {
      if (doubleTapDetected) {
        doubleTapDetected = false
        event.preventDefault()
      }
      latestTapTimestamp = dateHelpers.getCurrentTimestamp()
    }
    function handleTouchCancel(event: TouchEvent): void {
      if (doubleTapDetected) {
        doubleTapDetected = false
        event.preventDefault()
      }
    }
  }, [isMobile])

  useOnboardingPreparation(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.CommandBarWelcome,
    () => {
      commandBarDialogRef.current?.openCommands()
    },
    []
  )

  useOnboardingPreparation(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.CommandBarShortcuts,
    () => {
      commandBarDialogRef.current?.openCommands()
    },
    []
  )

  return (
    <MemoizedContextProvider
      context={CommandsContext}
      value={{
        commandManager: evaluatedCommandManager,
        commandBarDialogRef,
      }}
      dependencies={[evaluatedCommandManager]}
    >
      {children}

      <CommandBarDialog ref={commandBarDialogRef} />
    </MemoizedContextProvider>
  )
}

import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useMutablesDatabaseReader } from '../../useMutablesDatabaseReader'

export function useUser(
  id: Id,
  options?: {
    disabled?: boolean
  }
): {
  userIsLoading: boolean
  user: Model.User | undefined
} {
  return useMutablesDatabaseReader(
    db => (options?.disabled ? undefined : db.getUser(id)),
    {
      type: Model.Type.User,
      id,
      onMutation(model, state, reload) {
        if (options?.disabled) return state
        if (model.id !== id) return state
        return model.isDeleted ? undefined : model
      },
    },
    [id, options?.disabled],
    (loading, state) => ({
      userIsLoading: loading,
      user: !options?.disabled && state && state.id === id ? state : undefined,
    })
  )
}

import { extendThemeOptions } from '../extendThemeOptions'
import { lightThemeOptions } from './lightThemeOptions'

export const lightRtlThemeOptions = extendThemeOptions(lightThemeOptions, {
  direction: 'rtl',
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          // direction: 'rtl'
        },
      },
    },
  },
})

import { makeStyles, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { DetailedHTMLProps, HTMLAttributes, useRef } from 'react'

const SQUARE_WIDTH_UNITS = 75
const SQUARE_HEIGHT_UNITS = 60
const SQUARE_DISTANCE_UNITS = 3

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
    },
    content: {
      width: theme.spacing(SQUARE_WIDTH_UNITS),
      height: theme.spacing(SQUARE_HEIGHT_UNITS),
      maxWidth: '100%',
      padding: theme.spacing(0, 10),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    square: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: theme.spacing(SQUARE_WIDTH_UNITS),
      height: theme.spacing(SQUARE_HEIGHT_UNITS),
      border: `5px solid ${theme.palette.text.primary}`,
      borderRadius: '5px',
      pointerEvents: 'none',
      [`@media screen and (max-width: ${theme.spacing(SQUARE_WIDTH_UNITS + 15)}px)`]: {
        display: 'none',
      },
      [`@media screen and (max-height: ${theme.spacing(SQUARE_HEIGHT_UNITS + 15)}px)`]: {
        display: 'none',
      },
    },
  }),
  { name: 'DecorativeSquares' }
)

export function DecorativeSquares({
  userIsInteracting,
  onMouseMove,
  onMouseLeave,
  className,
  children,
  ...otherProps
}: {
  userIsInteracting?: boolean
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const rootRef = useRef<HTMLDivElement>(null)
  const square1Ref = useRef<HTMLDivElement>(null)
  const square2Ref = useRef<HTMLDivElement>(null)
  const square3Ref = useRef<HTMLDivElement>(null)

  const theme = useTheme()
  const classes = useStyles()

  function moveAllSquares(mouseX: number, mouseY: number): void {
    const rootCenterX = rootRef.current!.offsetWidth / 2
    const rootCenterY = rootRef.current!.offsetHeight / 2
    const diffX = mouseX - rootCenterX
    const diffY = mouseY - rootCenterY
    const translateX = theme.spacing(SQUARE_DISTANCE_UNITS * (diffX / rootCenterX))
    const translateY = theme.spacing(SQUARE_DISTANCE_UNITS * (diffY / rootCenterY))

    square1Ref.current!.style.transition = 'all 100ms'
    square2Ref.current!.style.transition = 'all 100ms'
    square3Ref.current!.style.transition = 'all 100ms'

    square1Ref.current!.style.transform = `translate(${
      theme.spacing(-SQUARE_WIDTH_UNITS / 2 + SQUARE_DISTANCE_UNITS) + translateX
    }px, ${theme.spacing(-SQUARE_HEIGHT_UNITS / 2 + SQUARE_DISTANCE_UNITS) + translateY}px)`

    setTimeout(() => {
      square2Ref.current!.style.transform = `translate(${theme.spacing(-SQUARE_WIDTH_UNITS / 2) + translateX}px, ${
        theme.spacing(-SQUARE_HEIGHT_UNITS / 2) + translateY
      }px)`
    }, 25)
    setTimeout(() => {
      square3Ref.current!.style.transform = `translate(${
        theme.spacing(-SQUARE_WIDTH_UNITS / 2 - SQUARE_DISTANCE_UNITS) + translateX
      }px, ${theme.spacing(-SQUARE_HEIGHT_UNITS / 2 - SQUARE_DISTANCE_UNITS) + translateY}px)`
    }, 50)
  }

  function centerAllSquares(): void {
    square1Ref.current!.style.transition = 'all 500ms'
    square2Ref.current!.style.transition = 'all 500ms'
    square3Ref.current!.style.transition = 'all 500ms'

    square1Ref.current!.style.transform = `translate(${theme.spacing(-SQUARE_WIDTH_UNITS / 2)}px, ${theme.spacing(
      -SQUARE_HEIGHT_UNITS / 2
    )}px)`

    setTimeout(() => {
      square2Ref.current!.style.transform = `translate(${theme.spacing(-SQUARE_WIDTH_UNITS / 2)}px, ${theme.spacing(
        -SQUARE_HEIGHT_UNITS / 2
      )}px)`
    }, 50)

    setTimeout(() => {
      square3Ref.current!.style.transform = `translate(${theme.spacing(-SQUARE_WIDTH_UNITS / 2)}px, ${theme.spacing(
        -SQUARE_HEIGHT_UNITS / 2
      )}px)`
    }, 100)
  }

  return (
    <div
      ref={rootRef}
      {...otherProps}
      className={classNames(classes.root, className)}
      onMouseMove={event => {
        onMouseMove?.(event)
        if (event.target === rootRef.current && !userIsInteracting) {
          moveAllSquares(event.clientX, event.clientY)
        } else {
          centerAllSquares()
        }
      }}
      onMouseLeave={event => {
        onMouseLeave?.(event)
        centerAllSquares()
      }}
    >
      <div className={classes.content}>{children}</div>

      <div ref={square1Ref} className={classes.square}></div>
      <div ref={square2Ref} className={classes.square}></div>
      <div ref={square3Ref} className={classes.square}></div>
    </div>
  )
}

import { createContext, MutableRefObject, PropsWithChildren, useRef } from 'react'
import { MemoizedContextProvider } from '../../../MemoizedContextProvider'
import { AppHistoryState } from './types'

export const AppHistoryContext = createContext<{
  stateRef: MutableRefObject<AppHistoryState>
}>(undefined as any)

export function AppHistoryProvider({ children }: PropsWithChildren<{}>) {
  const stateRef = useRef<AppHistoryState>(undefined)

  return (
    <MemoizedContextProvider
      context={AppHistoryContext}
      value={{
        stateRef,
      }}
      dependencies={[]}
    >
      {children}
    </MemoizedContextProvider>
  )
}

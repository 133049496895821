import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useEffect, useState } from 'react'
import { useContexts } from '../../../../../../../../../../../modules/contexts'
import { useHumanizedTimestamp } from '../../../../../../../../../../../modules/date-time'
import { CustomAvatar } from '../../../../../../../../../../CustomAvatar'
import { TypographyNoWrap } from '../../../../../../../../../../TypographyNoWrap'
import { useDatabases } from '../../../../../../../../../modules/databases'
import { Initializer } from '../../../../../../../../Initializer'
import { Panel, useSwitchPanel } from '../../../../panel'

const useStyles = makeStyles(
  theme => ({
    root: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      alignItems: 'flex-end',
      whiteSpace: 'pre',
      gap: theme.spacing(0.5),
    },
    infoContainer: {
      width: 0,
      flex: 'auto',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      overflow: 'hidden',
    },
    readIcon: {
      flex: 'none',
      padding: theme.spacing(1),
    },
    userAndActionContainer: {
      marginRight: theme.spacing(1),
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    pageAndDateContainer: {
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    userAvatar: {
      marginRight: theme.spacing(1),
    },
    userName: {
      marginRight: theme.spacing(1),
    },
    page: {
      marginRight: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.25, 0.5),
      display: 'flex',
      alignItems: 'center',
      gap: '0.5em',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    pageName: {
      color: theme.palette.info.main,
    },
    date: {
      color: theme.palette.text.disabled,
    },
  }),
  { name: 'ActivityCardInfo' }
)

export function ActivityCardInfo({ cardActivity, pageId }: { pageId: Id; cardActivity: any }) {
  const { allUsers } = useContexts(Initializer.Contexts)

  const [page, setPage] = useState<Model.Page>()

  const humanizedTimestamp = useHumanizedTimestamp(cardActivity.timestamp, 'date with time')

  const { databases } = useDatabases()

  // TODO: Replace it with the new badges models, when you need to re-enable this component:
  // const { removeUnreadActivities, removeReferenceActivities } = useActiviyManagement()

  useEffect(() => {
    getPage()

    async function getPage() {
      const page = await databases.mutablesDatabase.getPage(pageId)
      setPage(page)
    }
  }, [pageId])

  const user = allUsers.dictionary[cardActivity.userId]

  const { switchPanelStatic } = useSwitchPanel()

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.infoContainer}>
        <div className={classes.userAndActionContainer}>
          <CustomAvatar
            size={3}
            compact
            avatarFileId={user?.avatarFileId ?? null}
            name={user?.name}
            color={user?.color}
            className={classes.userAvatar}
          />

          <Typography variant="body2" noWrap className={classes.userName}>
            {user?.name ?? <>&nbsp;</>}
          </Typography>

          <Typography variant="caption" noWrap>
            {/* {cardActivity.action === ActivityServiceSignature.CardActivity.Action.Create
              ? 'created in'
              : cardActivity.action === ActivityServiceSignature.CardActivity.Action.Edit
              ? 'edited in'
              : cardActivity.action === ActivityServiceSignature.CardActivity.Action.Mention
              ? 'mentioned you in'
              : ''} */}
          </Typography>
        </div>

        <div className={classes.pageAndDateContainer}>
          <div
            className={classes.page}
            onClick={() => {
              if (page) {
                switchPanelStatic({
                  type: Panel.Type.Page,
                  pageId: page.id,
                })
              }
            }}
          >
            <CustomAvatar
              size={3}
              compact
              avatarFileId={page?.avatarFileId ?? null}
              name={page?.name}
              emoji={page?.iconEmoji}
            />

            <TypographyNoWrap variant="body2" className={classes.pageName}>
              {page?.name}
            </TypographyNoWrap>
          </div>

          <Typography variant="caption" noWrap className={classes.date}>
            {humanizedTimestamp}
          </Typography>
        </div>
      </div>

      <Tooltip
        title={
          ''
          // isUnreadActivity(cardActivity)
          //   ? 'Mark as read'
          //   : isReferenceActivity(cardActivity)
          //   ? 'Remove this activity'
          //   : ''
        }
      >
        <IconButton
          className={classes.readIcon}
          onClick={() => {
            // if (isUnreadActivity(cardActivity)) {
            //   removeUnreadActivities([cardActivity])
            // } else if (isReferenceActivity(cardActivity)) {
            //   removeReferenceActivities([cardActivity])
            // }
          }}
        >
          {/* <CustomIcon
            name={isUnreadActivity(cardActivity) ? 'Check' : isReferenceActivity(cardActivity) ? 'Close' : 'Close'}
          /> */}
        </IconButton>
      </Tooltip>
    </div>
  )
}

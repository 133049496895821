import { makeStyles, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { forwardRef, PropsWithChildren, ReactNode, useContext } from 'react'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { DeviceProvider } from '../../../../../../../../../modules/device'
import { useAvailableScreenWidth } from '../../hooks/useAvailableScreenWidth'
import { MainPage } from '../../MainPage'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    rootSideBarMenuClosed: {
      alignItems: 'flex-end',
    },
    header: {
      flex: 'none',
      width: '100%',
      padding: theme.spacing(0.5, 0),
      display: 'flex',
      alignItems: 'center',
    },
    body: {
      flex: 'auto',
      height: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'stretch',
      gap: theme.spacing(5),
      overflow: 'hidden',
    },
    bodyMobile: {
      width: 'unset',
    },
    content: {
      flexGrow: 1,
      width: 0,
      minWidth: theme.spacing(37),
    },
    sideBar: {
      flex: 'none',
      width: theme.spacing(37.5),
      padding: theme.spacing(2.5, 0, 2, 0),
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'PanelLayout' }
)

export const PanelLayout = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    header?: ReactNode
    sideBar?: ReactNode
    className?: string
  }>
>(function PanelLayout({ header, sideBar, className, children }, ref) {
  const { screenWidth, isMobile } = useContext(DeviceProvider.Context)
  const { isSideBarMenuOpen } = useContexts(MainPage.Contexts)

  const { availableScreenWidth } = useAvailableScreenWidth()

  const theme = useTheme()

  const isFullWidth = screenWidth <= theme.constants.sidebar.fullWidthSideBarMaximumScreenWidth

  const classes = useStyles()

  return (
    <div ref={ref} className={classNames(classes.root, !isSideBarMenuOpen && classes.rootSideBarMenuClosed)}>
      {header && <div className={classes.header}>{header}</div>}

      <div className={classNames(classes.body, isMobile && classes.bodyMobile)}>
        <div
          className={classNames(classes.content, className)}
          style={{ ...(isMobile ? { width: availableScreenWidth } : {}) }}
        >
          {children}
        </div>

        {sideBar && !isSideBarMenuOpen && (
          <div className={classes.sideBar} style={{ ...(isFullWidth ? { width: availableScreenWidth } : {}) }}>
            {sideBar}
          </div>
        )}
      </div>
    </div>
  )
})

import { useTheme } from '@material-ui/core'
import { useContext } from 'react'
import { ChromeExtensionMode } from '../../../../../../../../modules/chrome-extension-mode'
import { DeviceProvider } from '../../../../../../../../modules/device'
import { GLOBAL_PADDING_X_CHROME_EXTENSION_UNIT, GLOBAL_PADDING_X_UNIT } from '../../../../constants'

export function useAvailableScreenWidth(): {
  availableScreenWidth: number
} {
  const { screenWidth } = useContext(DeviceProvider.Context)

  const theme = useTheme()

  return {
    availableScreenWidth:
      screenWidth -
      2 * theme.spacing(ChromeExtensionMode.isActive ? GLOBAL_PADDING_X_CHROME_EXTENSION_UNIT : GLOBAL_PADDING_X_UNIT),
  }
}

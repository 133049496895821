import { makeStyles, useTheme, Typography, Avatar } from '@material-ui/core'
import classNames from 'classnames'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { CustomAvatar } from '../../../../../../../../CustomAvatar'
import { Initializer } from '../../../../../../Initializer'
import { TextHighlighter } from '../../../../../../TextHighlighter'
import { SearchResultItem } from './types'

const useStyles = makeStyles(
  theme => ({
    root: {
      height: '100%',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    compromiseResult: {
      marginLeft: theme.spacing(3),
    },
    cardRow: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      overflow: 'hidden',
      borderRadius: theme.spacing(1),
      paddingRight: theme.spacing(1),
      userSelect: 'none',
      cursor: 'pointer',
    },
    cardText: {
      width: 0,
      height: '2.3em',
      flexGrow: 1,
      overflow: 'hidden',
    },
  }),
  { name: 'SearchItemLayout' }
)

export function SearchItemLayout({ item, tokens }: { item: SearchResultItem; tokens: readonly string[] }) {
  const { allPages } = useContexts(Initializer.Contexts)

  const theme = useTheme()
  const classes = useStyles()

  const page = allPages.dictionary[item.card.pageId]

  if (!page) return null

  return item.compromiseResult ? (
    <div className={classNames(classes.root, item.compromiseResult && classes.compromiseResult)}>
      <div className={classes.cardRow}>
        <Typography variant="subtitle2" display="block" className={classes.cardText}>
          {item.compromiseResult.value}
        </Typography>

        <Avatar>
          <CustomIcon name="Home" />
        </Avatar>
      </div>
    </div>
  ) : page ? (
    <div className={classes.root}>
      <div className={classes.cardRow}>
        <Typography variant="subtitle2" display="block" className={classes.cardText}>
          <TextHighlighter tokens={tokens} range={20}>
            {item.text}
          </TextHighlighter>
        </Typography>

        <CustomAvatar
          size={5.25}
          avatarFileId={page.avatarFileId}
          name={page.name}
          emoji={page.iconEmoji}
          color={page.color || theme.palette.action.disabledBackground}
        >
          <CustomIcon name="Home" />
        </CustomAvatar>
      </div>
    </div>
  ) : null
}

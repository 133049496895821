import { Dispatch, MutableRefObject, PropsWithChildren, SetStateAction, useRef } from 'react'
import { useGetSet, useUpdate } from 'react-use'
import { createContexts } from '../../../../../modules/contexts'
import { OnboardingState, TourStepAnchors } from '../types'
import { StepView } from './StepView'
import { WindowDev } from './WindowDev'

export const OnboardingProviderContexts = createContexts(
  ({ memoize }) =>
    (parameters?: {
      tourStepAnchorsRef: MutableRefObject<TourStepAnchors>
      getOnboardingState: () => OnboardingState | undefined
      setOnboardingState: Dispatch<SetStateAction<OnboardingState | undefined>>
      getPreparationCount: () => number
      setPreparationCount: Dispatch<SetStateAction<number>>
      update: () => void
    }) => ({
      refs: memoize(
        () => ({
          tourStepAnchorsRef: parameters?.tourStepAnchorsRef!,
          getOnboardingState: parameters?.getOnboardingState!,
          setOnboardingState: parameters?.setOnboardingState!,
          getPreparationCount: parameters?.getPreparationCount!,
          setPreparationCount: parameters?.setPreparationCount!,
          update: parameters?.update!,
        }),
        []
      ),
      onboardingState: parameters?.getOnboardingState(),
      preparationCount: parameters?.getPreparationCount()!,
    }),
  'Onboarding'
)

export function OnboardingProvider({ children }: PropsWithChildren<{}>) {
  const tourStepAnchorsRef = useRef<TourStepAnchors>({})

  const [getOnboardingState, setOnboardingState] = useGetSet<OnboardingState | undefined>(undefined)
  const onboardingState = getOnboardingState()

  const [getPreparationCount, setPreparationCount] = useGetSet(0)

  const update = useUpdate()

  return (
    <OnboardingProviderContexts.Provider
      parameters={{
        tourStepAnchorsRef,
        getOnboardingState,
        setOnboardingState,
        getPreparationCount,
        setPreparationCount,
        update,
      }}
    >
      <WindowDev />

      {children}

      {onboardingState && <StepView onboardingState={onboardingState} />}
    </OnboardingProviderContexts.Provider>
  )
}

import { Input, makeStyles, MenuItem, MenuList, Paper, Popover, Typography } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import { ComponentRef, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useCombineRefs } from '../../../../../../../../../hooks/useCombineRefs'
import { useRefWrap } from '../../../../../../../../../hooks/useRefWrap'
import { useContexts } from '../../../../../../../../../modules/contexts'
import {
  useKeyboardHandling,
  commonKeyboardCombinations,
  KeyboardCombinationView,
} from '../../../../../../../../../modules/keyboard-handler'
import { NavigationArea, Navigable, NavigableStatus } from '../../../../../../../../../modules/navigation'
import { CustomAvatar } from '../../../../../../../../CustomAvatar'
import { Dropdown } from '../../../../../../../../Dropdown'
import { TypographyNoWrap } from '../../../../../../../../TypographyNoWrap'
import { AccountData } from '../../../../../modules/account-data'

const useStyles = makeStyles(
  theme => ({
    placeholder: {
      color: theme.palette.text.hint,
    },
    paper: {
      width: theme.spacing(32),
      padding: theme.spacing(0.5, 1, 1, 1),
    },
    list: {
      maxHeight: theme.spacing(42),
      overflow: 'auto',
    },
    menuItem: {
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.spacing(0.5),
    },
    page: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    pageTitle: {
      marginLeft: theme.spacing(0.5),
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'PageDropdown' }
)

export function PageDropdown({
  disabled,
  selectedPage,
  onSelectPage,
}: {
  disabled?: boolean
  selectedPage: Model.Page
  onSelectPage(selectedPage: Model.Page): void
}) {
  const { account, accountPagesOrdered } = useContexts(AccountData.Contexts)

  const pageSelectRef = useRef<ComponentRef<typeof Dropdown>>(null)
  const pageSelectInputRef = useRef<HTMLInputElement>(null)
  const pageSelectListRef = useRef<HTMLUListElement>(null)

  const onSelectPageRef = useRefWrap(onSelectPage)

  const [pageQuery, setPageQuery] = useState('')
  const [preSelectedPageIndex, setPreSelectedPageIndex] = useState(0)

  useLayoutEffect(() => setPreSelectedPageIndex(0), [pageQuery])

  const filteredPages = useMemo(
    () => accountPagesOrdered.filter(page => page.name.toLowerCase().includes(pageQuery.toLowerCase())),
    [accountPagesOrdered, pageQuery]
  )

  const preparePageSelectInputRef = useRefWrap((): void => {
    setPageQuery('')
    setPreSelectedPageIndex(accountPagesOrdered.indexOf(selectedPage))
    setTimeout(() => pageSelectInputRef.current?.focus())
  })

  useKeyboardHandling(
    [
      {
        combinations: { ctrl: true, shift: true, code: 'KeyL' },
        handler(event) {
          if (!pageSelectRef.current?.isOpened()) {
            pageSelectRef.current?.open()
            preparePageSelectInputRef.current()
          }
        },
      },
      {
        combinations: 'ArrowUp',
        noConsumption: true,
        handler(event) {
          if (pageSelectRef.current?.isOpened()) {
            if (preSelectedPageIndex > 0) {
              const index = preSelectedPageIndex - 1
              setPreSelectedPageIndex(index)
              pageSelectListRef.current?.children[index].scrollIntoView({ block: 'nearest' })
            }
          }
          return 'not handled'
        },
      },
      {
        combinations: 'ArrowDown',
        noConsumption: true,
        handler(event) {
          if (pageSelectRef.current?.isOpened()) {
            if (preSelectedPageIndex < filteredPages.length - 1) {
              const index = preSelectedPageIndex + 1
              setPreSelectedPageIndex(index)
              pageSelectListRef.current?.children[index].scrollIntoView({ block: 'nearest' })
            }
          }
          return 'not handled'
        },
      },
      {
        combinations: commonKeyboardCombinations.enter,
        handler(event) {
          if (!pageSelectRef.current?.isOpened()) return 'not handled'
          const page = filteredPages[preSelectedPageIndex]
          onSelectPageRef.current(page)
          pageSelectRef.current.close()
        },
      },
    ],
    [account, filteredPages, preSelectedPageIndex]
  )

  const combineRefs = useCombineRefs(pageSelectInputRef)

  const classes = useStyles()

  function renderPageItem(page: Model.Page) {
    return (
      <div className={classes.page}>
        <CustomAvatar
          size={2}
          compact
          avatarFileId={page.avatarFileId}
          name={page.name}
          emoji={page.iconEmoji}
          margin={0.5}
        />
        <TypographyNoWrap variant="subtitle2" className={classes.pageTitle}>
          {page.name}
        </TypographyNoWrap>
      </div>
    )
  }

  return (
    <Dropdown
      ref={pageSelectRef}
      label={
        <>
          <Typography variant="subtitle2">Invite a friend to:&nbsp;</Typography>
          {renderPageItem(selectedPage)}
        </>
      }
      disabled={disabled}
      onOpen={preparePageSelectInputRef.current}
    >
      {({ open, close, rootRef }) => (
        <NavigationArea disabled={!open}>
          <Popover
            open={open}
            onClose={close}
            anchorEl={rootRef.current}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          >
            <Paper className={classes.paper}>
              <Navigable selectable focusable padding={[0, 0, 0, 1]}>
                {({ connectNavigable }) => (
                  <Input
                    inputRef={combineRefs(connectNavigable)}
                    disableUnderline
                    fullWidth
                    endAdornment={<KeyboardCombinationView combination={{ ctrl: true, shift: true, code: 'KeyL' }} />}
                    placeholder="Select a page..."
                    value={pageQuery}
                    onChange={event => setPageQuery(event.target.value)}
                  />
                )}
              </Navigable>

              <MenuList ref={pageSelectListRef} className={classes.list}>
                {filteredPages.map((page, index) => (
                  <Navigable
                    key={page.id}
                    onNavigableStatus={navigableStatus => {
                      if (navigableStatus === NavigableStatus.NavigatedTo) {
                        setPreSelectedPageIndex(index)
                      }
                    }}
                  >
                    {({ connectNavigable }) => (
                      <MenuItem
                        ref={connectNavigable}
                        disableGutters
                        className={classes.menuItem}
                        selected={index === preSelectedPageIndex}
                        onClick={() => {
                          onSelectPage(page)
                          close()
                        }}
                        onMouseMove={() => {
                          setPreSelectedPageIndex(index)
                        }}
                      >
                        {renderPageItem(page)}
                      </MenuItem>
                    )}
                  </Navigable>
                ))}
              </MenuList>
            </Paper>
          </Popover>
        </NavigationArea>
      )}
    </Dropdown>
  )
}

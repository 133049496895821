import { Id } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { getEnumKeyValues } from '../../../../../helpers/getEnumKeyValues'
import { arrayHelpers } from '../../../../../helpers/native/arrayHelpers'
import { typed } from '../../../../../helpers/typed'
import { EnabledExtensions, EnabledExtensionsByScopeAndValue } from '../types'

export const ExtensionScopeProviderContext = createContext<EnabledExtensionsByScopeAndValue | undefined>(
  arrayHelpers.toDictionary(
    getEnumKeyValues<ZettelExtensions.Scope>(ZettelExtensions.Scope),
    ({ value: item }) => item,
    item => ({})
  )
)

export function ExtensionScopeProvider({
  scope,
  value,
  extensionIds,
  effectiveExtensionIdsList = [],
  children,
}: PropsWithChildren<{
  scope: ZettelExtensions.Scope
  value: string
  extensionIds: readonly Id[] | undefined
  effectiveExtensionIdsList?: readonly (readonly Id[] | undefined)[] | undefined
}>) {
  const providedEnabledExtensions = useContext(ExtensionScopeProviderContext)

  const extensionIdSet = useMemo(() => (extensionIds ? new Set(extensionIds) : undefined), [extensionIds])
  const effectiveExtensionIdSet = useMemo(() => {
    if (!extensionIds) return undefined
    const set = new Set(extensionIds)
    effectiveExtensionIdsList.forEach(effectiveExtensionIds =>
      effectiveExtensionIds?.forEach(extensionId => set.add(extensionId))
    )
    return set
  }, [extensionIdSet, ...effectiveExtensionIdsList])

  const providingEnabledExtensions = useMemo<EnabledExtensionsByScopeAndValue | undefined>(
    () =>
      providedEnabledExtensions && extensionIdSet && effectiveExtensionIdSet
        ? {
            ...providedEnabledExtensions,
            [scope]: {
              ...providedEnabledExtensions[scope],
              [value]: typed<EnabledExtensions>({
                extensionIdSet,
                effectiveExtensionIdSet,
              }),
            },
          }
        : undefined,
    [providedEnabledExtensions, scope, value, extensionIdSet, effectiveExtensionIdSet]
  )

  return (
    <ExtensionScopeProviderContext.Provider value={providingEnabledExtensions}>
      {children}
    </ExtensionScopeProviderContext.Provider>
  )
}

import { Box, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import { useState } from 'react'
import { Hint } from '../components/Hint'

export function TypographyPanel() {
  const [selectedVariant, setSelectedVariant] = useState<Variant>('h1')

  return (
    <>
      <Hint
        description={
          <>
            Under
            <strong>
              &nbsp;
              <code>typography</code>
              &nbsp;
            </strong>
            property
          </>
        }
      >
        web/src/App/modules/styling/theme-options/baseThemeOptions.tsx
      </Hint>

      <Divider />

      <Typography variant="h1">Header 1</Typography>
      <Divider />
      <Typography variant="h2">Header 2</Typography>
      <Divider />
      <Typography variant="h3">Header 3</Typography>
      <Divider />
      <Typography variant="h4">Header 4</Typography>
      <Divider />
      <Typography variant="h5">Header 5</Typography>
      <Divider />
      <Typography variant="h6">Header 6</Typography>
      <Divider />
      <Typography variant="subtitle1">Subtitle 1</Typography>
      <Divider />
      <Typography variant="subtitle2">Subtitle 2</Typography>
      <Divider />
      <Typography variant="body1">Body 1</Typography>
      <Divider />
      <Typography variant="body2">Body 2</Typography>
      <Divider />
      <Typography variant="caption">Caption</Typography>
      <Divider />
      <Typography variant="overline">Overline</Typography>
      <Divider />
      <Typography variant="button">Button</Typography>
      <Divider />
      <br />

      <Box padding={4}>
        <FormControl fullWidth>
          <InputLabel>Variant</InputLabel>
          <Select value={selectedVariant} onChange={event => setSelectedVariant(event.target.value as any)}>
            <MenuItem value="h1">Header 1</MenuItem>
            <MenuItem value="h2">Header 2</MenuItem>
            <MenuItem value="h3">Header 3</MenuItem>
            <MenuItem value="h4">Header 4</MenuItem>
            <MenuItem value="h5">Header 5</MenuItem>
            <MenuItem value="h6">Header 6</MenuItem>
            <MenuItem value="subtitle1">Subtitle 1</MenuItem>
            <MenuItem value="subtitle2">Subtitle 2</MenuItem>
            <MenuItem value="body1">Body 1</MenuItem>
            <MenuItem value="body2">Body 2</MenuItem>
            <MenuItem value="caption">Caption</MenuItem>
            <MenuItem value="overline">Overline</MenuItem>
            <MenuItem value="button">Button</MenuItem>
          </Select>
        </FormControl>

        <Box paddingY={4}>
          <Typography variant={selectedVariant}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean condimentum ante in lectus laoreet luctus.
            Donec et urna ac libero fermentum sagittis. Praesent lacus dolor, eleifend iaculis velit at, pellentesque
            volutpat risus. Aenean id magna sed quam rhoncus imperdiet. Sed a justo sit amet lorem ullamcorper bibendum
            eget sed ante. Cras consectetur rhoncus metus quis porta. Nullam posuere quis enim a elementum. Etiam
            pulvinar velit vel ipsum venenatis laoreet. Integer quis mi laoreet, blandit elit ut, elementum mi.
            Suspendisse a ornare odio. Aliquam ut augue massa. Nulla lorem augue, feugiat et tincidunt a, tempus a diam.
            Phasellus commodo venenatis interdum. Morbi mattis suscipit ipsum, vel vulputate tortor ullamcorper vitae.
          </Typography>
        </Box>
      </Box>
    </>
  )
}

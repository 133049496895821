import { Theme } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'

export function createPageCoverSuggestions(theme: Theme): Pick<Model.Page, 'iconEmoji' | 'color' | 'avatarFileId'> {
  return {
    iconEmoji: emojiList[Math.floor(Math.random() * emojiList.length)],
    color: theme.palette.avatar[Math.floor(Math.random() * theme.palette.avatar.length)],
    avatarFileId: null,
  }
}

const emojiList = [
  '😀',
  '😃',
  '😄',
  '😁',
  '😆',
  '😅',
  '😂',
  '🤣',
  '😊',
  '😇',
  '🙂',
  '🙃',
  '😉',
  '😌',
  '😍',
  '🥰',
  '😘',
  '😗',
  '😙',
  '😚',
  '😋',
  '😛',
  '😝',
  '😜',
  '🤪',
  '🤨',
  '🧐',
  '🤓',
  '😎',
  '🤩',
  '🥳',
  '😏',
  '😒',
  '😞',
  '😔',
  '😟',
  '😕',
  '🙁',
  '☹️',
  '😣',
  '😖',
  '😫',
  '😩',
  '🥺',
  '😢',
  '😭',
  '😤',
  '😠',
  '😡',
  '🤬',
  '🤯',
  '😳',
  '🥵',
  '🥶',
  '😱',
  '😨',
  '😰',
  '😥',
  '😓',
  '🤗',
  '🤔',
  '🤭',
  '🤫',
  '🤥',
  '😶',
  '😐',
  '😑',
  '😬',
  '🙄',
  '😯',
  '😦',
  '😧',
  '😮',
  '😲',
  '🥱',
  '😴',
  '🤤',
  '😪',
  '😵',
  '🤐',
  '🥴',
  '🤢',
  '🤮',
  '🤧',
  '😷',
  '🤒',
  '🤕',
  '🤑',
  '🤠',
  '😈',
  '👿',
  '👹',
  '👺',
  '🤡',
  '👻',
  '💀',
  '☠️',
  '👽',
  '👾',
  '🤖',
  '🎃',
  '😺',
  '😸',
  '😹',
  '😻',
  '😼',
  '😽',
  '🙀',
  '😿',
  '😾',
  '👋',
  '🤚',
  '🖐',
  '✋',
  '🖖',
  '👌',
  '🤏',
  '✌️',
  '🤞',
  '🤟',
  '🤘',
  '🤙',
  '👈',
  '👉',
  '👆',
  '🖕',
  '👇',
  '☝️',
  '👍',
  '👎',
  '✊',
  '👊',
  '🤛',
  '🤜',
  '👏',
  '🙌',
  '👐',
  '🤲',
  '🤝',
  '🙏',
  '👶',
  '👧',
  '🧒',
  '👦',
  '👩',
  '🧑',
  '👨',
  '👩‍🦱',
  '👨‍🦱',
  '👩‍🦰',
  '👨‍🦰',
  '👱‍♀️',
  '👱',
  '👱‍♂️',
  '👩‍🦳',
  '👨‍🦳',
  '👩‍🦲',
  '👨‍🦲',
  '🧔',
  '👵',
  '🧓',
  '👴',
  '👲',
  '👳‍♀️',
  '👳',
  '👳‍♂️',
  '🧕',
  '👮‍♀️',
  '👮',
  '👮‍♂️',
  '👷‍♀️',
  '👷',
  '👷‍♂️',
  '💂‍♀️',
  '💂',
  '💂‍♂️',
  '🕵️‍♀️',
  '🕵️',
  '🕵️‍♂️',
  '👩‍⚕️',
  '👨‍⚕️',
  '👩‍🌾',
  '👨‍🌾',
  '👩‍🍳',
  '👨‍🍳',
  '👩‍🎓',
  '👨‍🎓',
  '👩‍🎤',
  '👨‍🎤',
  '👩‍🏫',
  '👨‍🏫',
  '👩‍🏭',
  '👨‍🏭',
  '👩‍💻',
  '👨‍💻',
  '👩‍💼',
  '👨‍💼',
  '👩‍🔧',
  '👨‍🔧',
  '👩‍🔬',
  '👨‍🔬',
  '👩‍🎨',
  '👨‍🎨',
  '👩‍🚒',
  '👨‍🚒',
  '👩‍✈️',
  '👨‍✈️',
  '👩‍🚀',
  '👨‍🚀',
  '🧳',
  '🌂',
  '☂️',
  '🧵',
  '🧶',
  '👓',
  '🥽',
  '🥼',
  '🦺',
  '👔',
  '👕',
  '👖',
  '🧣',
  '🧥',
  '🧦',
  '👗',
  '👘',
  '🥻',
  '🩱',
  '🩲',
  '🩳',
  '👙',
  '👚',
  '👛',
  '👜',
  '👝',
  '🎒',
  '👞',
  '👟',
  '🥾',
  '🥿',
  '👠',
  '👡',
  '🩰',
  '👢',
  '👑',
  '👒',
  '🎩',
  '🎓',
  '🧢',
  '💄',
  '💍',
  '💼',
  '🍏',
  '🍎',
  '🍐',
  '🍊',
  '🍋',
  '🍌',
  '🍉',
  '🍇',
  '🍓',
  '🍈',
  '🍒',
  '🍑',
  '🥭',
  '🍍',
  '🥥',
  '🥝',
  '🍅',
  '🍆',
  '🥑',
  '🥦',
  '🥬',
  '🥒',
  '🌽',
  '🥕',
  '🧄',
  '🧅',
  '🥔',
  '🍠',
  '🥐',
  '🥯',
  '🍞',
  '🥖',
  '🥨',
  '🧀',
  '🥚',
  '🍳',
  '🧈',
  '🥞',
  '🧇',
  '🥓',
  '🥩',
  '🍗',
  '🍖',
  '🦴',
  '🌭',
  '🍔',
  '🍟',
  '🍕',
  '🥪',
  '🥙',
  '🧆',
  '🌮',
  '🌯',
  '🥗',
  '🥘',
  '🥫',
  '🍝',
  '🍜',
  '🍲',
  '🍛',
  '🍣',
  '🍱',
  '🥟',
  '🦪',
  '🍤',
  '🍙',
  '🍚',
  '🍘',
  '🍥',
  '🥠',
  '🥮',
  '🍢',
  '🍡',
  '🍧',
  '🍨',
  '🍦',
  '🥧',
  '🧁',
  '🍰',
  '🎂',
  '🍮',
  '🍭',
  '🍬',
  '🍫',
  '🍿',
  '🍩',
  '🍪',
  '🌰',
  '🍯',
  '🥛',
  '🍼',
  '☕️',
  '🍵',
  '🧃',
  '🥤',
  '🍶',
  '🍺',
  '🍻',
  '🥂',
  '🍷',
  '🥃',
  '🍸',
  '🍹',
  '🧉',
  '🍾',
  '🧊',
  '🥄',
  '🍴',
  '🥣',
  '🥡',
  '🥢',
  '🧂',
  '🚗',
  '🚕',
  '🚙',
  '🚌',
  '🚎',
  '🚓',
  '🚑',
  '🚒',
  '🚐',
  '🚚',
  '🚛',
  '🚜',
  '🦯',
  '🦽',
  '🦼',
  '🛴',
  '🚲',
  '🛵',
  '🛺',
  '🚨',
  '🚔',
  '🚍',
  '🚘',
  '🚖',
  '🚡',
  '🚠',
  '🚟',
  '🚃',
  '🚋',
  '🚞',
  '🚝',
  '🚄',
  '🚅',
  '🚈',
  '🚂',
  '🚆',
  '🚇',
  '🚊',
  '🚉',
  '✈️',
  '🛫',
  '🛬',
  '💺',
  '🚀',
  '🛸',
  '🚁',
  '🛶',
  '⛵️',
  '🚤',
  '🚢',
  '⚓️',
  '⛽️',
  '🚧',
  '🚦',
  '🚥',
  '🚏',
  '🗿',
  '🗽',
  '🗼',
  '🏰',
  '🏯',
  '🎢',
  '🎠',
  '⛲️',
  '🌋',
  '🗻',
  '⛺️',
  '🏠',
  '🏡',
  '🏭',
  '🏢',
  '🏬',
  '🏣',
  '🏤',
  '🏥',
  '🏦',
  '🏨',
  '🏪',
  '🏫',
  '🏩',
  '💒',
  '⛪️',
  '🕌',
  '🕍',
  '🛕',
  '🕋',
  '🗾',
  '🎑',
  '🏞',
  '🌅',
  '🌄',
  '🌠',
  '🎆',
  '🌇',
  '🌆',
  '🌃',
  '🌌',
  '🌉',
  '🌁',
]

import { createContext, PropsWithChildren, useEffect, useMemo } from 'react'
import { MemoizedContextProvider } from '../../components/MemoizedContextProvider'
import { KeyboardHandler } from './KeyboardHandler'

export const KeyboardHandlerContext = createContext<{
  keyboardHandler: KeyboardHandler
}>(undefined as any)

export function KeyboardHandlerProvider({
  keyboardHandler,
  children,
}: PropsWithChildren<{
  keyboardHandler?: KeyboardHandler
}>) {
  const evaluatedKeyboardHandler = useMemo(() => keyboardHandler ?? new KeyboardHandler(), [keyboardHandler])

  useEffect(() => () => evaluatedKeyboardHandler.dispose(), [evaluatedKeyboardHandler])

  return (
    <MemoizedContextProvider
      context={KeyboardHandlerContext}
      value={{ keyboardHandler: evaluatedKeyboardHandler }}
      dependencies={[evaluatedKeyboardHandler]}
    >
      {children}
    </MemoizedContextProvider>
  )
}

// We require to ensure of not existing any unsynchronized action created while synchronizing the current ones,
// and it's not something that happen regularly. Also, we can not synchronize depending actions together in one shot.
// Therefore, the await in loop is required and is not going to cause us any major problem:
/* eslint-disable no-await-in-loop */

import { captureException } from '@sentry/react'
import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useEffect, useMemo, useRef } from 'react'
import { useRefWrap } from '../../../../../hooks/useRefWrap'
import { webConfig } from '../../../../../modules/web-config'
import { PeriodicAsyncAction } from '../../../../../types/PeriodicAsyncAction'
import { useAppNotistack } from '../../app-notistack'
import { useConnectionStatus } from '../../connection-status'
import { commonMutablesDatabaseReaders, useActionsDatabaseReader, useDatabases } from '../../databases'
import { EphemeralKey, useEphemeral } from '../../ephemeral'
import { ActionModel } from '../../models'
import { PersistentKey, usePersistent } from '../../persistent'
import { useServices } from '../../services'

export function useActionSynchronization(): void {
  const [, authentication] = usePersistent(PersistentKey.Authentication)

  const { ephemeral } = useEphemeral()

  const { accountIsLoading, account } = commonMutablesDatabaseReaders.useAccount(
    authentication?.decodedAccessToken.userId ?? '',
    {
      disabled: !authentication?.decodedAccessToken.userId,
    }
  )

  const { databases } = useDatabases()

  const { services } = useServices()

  const { isConnectedToServer } = useConnectionStatus()

  const { enqueueSnackbar } = useAppNotistack()

  const debouncingTimeoutRef = useRef<any>(undefined)

  const periodicAsyncAction = useMemo(
    () =>
      new PeriodicAsyncAction({
        successInterval: webConfig.timings.retrySendingAction.succeeded,
        failureInterval: webConfig.timings.retrySendingAction.failed,

        async action() {
          setActionSynchronizationStatus(true)

          do {
            const actions = await databases.actionsDatabase.getAll()

            if (actions.length === 0) break

            // Find out the maximum subset of the independent actions, prioritized by the timestamp:
            const toBeSynchronizedModelIdDictionary: Record<Id, true> = {}
            const toBeSynchronizedActions: typeof actions = []
            const notToBeSynchronizedActions: typeof actions = []
            actions.forEach(action => {
              switch (action.type) {
                case ActionModel.Type.UpsertModel: {
                  const dependingModelIds = [action.newModel.id] as (string | null)[]

                  switch (action.newModel.type) {
                    case Model.Type.Account:
                      // It doesn't depend on any other model except for itself!
                      break

                    case Model.Type.User:
                      throw Error('No upsert action is allowed on User models.')

                    case Model.Type.Page:
                      // It doesn't depend on any other model except for itself!
                      break

                    case Model.Type.Card:
                      dependingModelIds.push(action.newModel.pageId)
                      break

                    case Model.Type.Badge:
                      switch (action.newModel.action.type) {
                        case Model.Badge.Action.Type.JoinPage:
                          dependingModelIds.push(action.newModel.action.pageId)
                          break

                        case Model.Badge.Action.Type.CreateCard:
                        case Model.Badge.Action.Type.UpdateCard:
                          dependingModelIds.push(action.newModel.action.cardId)
                          break

                        default:
                          throw Error('Unsupported badge action type.')
                      }
                      break

                    default:
                      throw Error('Unsupported model type.')
                  }

                  if (
                    dependingModelIds.some(
                      dependingModelId => dependingModelId && dependingModelId in toBeSynchronizedModelIdDictionary
                    )
                  ) {
                    notToBeSynchronizedActions.push(action)
                  } else {
                    toBeSynchronizedModelIdDictionary[action.newModel.id] = true
                    toBeSynchronizedActions.push(action)
                  }
                  break
                }

                case ActionModel.Type.InvitePageMemberByEmail:
                  toBeSynchronizedActions.push(action)
                  break

                default:
                  throw Error('Unsupported action type.')
              }
            })

            await Promise.all(
              // It's complaining about the use of `log` in the function in the loop, which we know is safe:
              // eslint-disable-next-line no-loop-func, @typescript-eslint/no-loop-func
              toBeSynchronizedActions.map(async action => {
                try {
                  switch (action.type) {
                    case ActionModel.Type.UpsertModel:
                      switch (action.newModel.type) {
                        case Model.Type.Account:
                          await services.core.upsertAccount(action.newModel)
                          break

                        case Model.Type.User:
                          throw Error('No upsert action is allowed on User models.')

                        case Model.Type.Page:
                          await services.core.upsertPage(action.newModel)
                          break

                        case Model.Type.Card:
                          await services.core.upsertCard(action.newModel)
                          break

                        case Model.Type.Badge:
                          await services.core.upsertBadge(action.newModel)
                          break

                        default:
                          throw Error('Unsupported model type.')
                      }
                      break

                    case ActionModel.Type.InvitePageMemberByEmail:
                      await services.core.invitePageMemberByEmail(action.pageId, action.email)
                      break

                    default:
                      throw Error('Unsupported action type.')
                  }
                } catch (error) {
                  log.error(error)
                  captureException(error, { tags: { module: 'super window client' } })
                  enqueueSnackbar('Error', 'Unable to perform action.', { variant: 'error' }) // TODO: Is this required?

                  const toBeRevertedActions = [action]

                  // TODO: Also, extract out all the actions which need to be reverted from notToBeSynchronizedActions to toBeRevertedActions

                  // Make sure we revert the actions in reversed timestamp order, from newest to oldest:
                  toBeRevertedActions.reverse()

                  await databases.actionsDatabase.bulkDelete(
                    toBeRevertedActions.map(toBeRevertedAction => toBeRevertedAction.id)
                  )
                  await databases.mutablesDatabase.revertUpsertModelActions(
                    toBeRevertedActions as ActionModel<ActionModel.Type.UpsertModel>[]
                  )
                }
              })
            )

            await databases.actionsDatabase.bulkDelete(
              toBeSynchronizedActions.map(toBeSynchronizedAction => toBeSynchronizedAction.id)
            )
          } while (!(await databases.actionsDatabase.isEmpty()))
        },

        onSuccess() {
          setActionSynchronizationStatus(false)
        },
        onFailure(error) {
          log.error('useActionSynchronization', error)
          captureException(error, { tags: { module: 'super window client' } })
          setActionSynchronizationStatus(false)
        },
      }),
    [databases, services]
  )

  function setActionSynchronizationStatus(status: boolean): void {
    if (ephemeral[EphemeralKey.SynchronizingActions] === status) return

    ephemeral[EphemeralKey.SynchronizingActions] = status

    if (status) {
      clearTimeout(debouncingTimeoutRef.current)
      ephemeral[EphemeralKey.SynchronizingActionsDebounced] = true
    } else {
      debouncingTimeoutRef.current = setTimeout(() => {
        ephemeral[EphemeralKey.SynchronizingActionsDebounced] = false
      }, webConfig.timings.actionSynchronizationStatusUpdateDebounce)
    }
  }

  const statusRef = useRefWrap(Boolean(!accountIsLoading && account && isConnectedToServer))

  periodicAsyncAction.setStatus(statusRef.current)

  useEffect(() => () => periodicAsyncAction.stop(), [periodicAsyncAction])

  useActionsDatabaseReader(
    action => {
      if (statusRef.current) {
        periodicAsyncAction.actImmediate()
      }
    },
    [periodicAsyncAction]
  )
}

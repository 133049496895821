import { useContexts } from '../../contexts'
import { NavigationAreaContexts } from '../components/NavigationArea'
import { NavigationContexts } from '../components/NavigationProvider'

export function useNavigation(): {
  isKeyboardNavigationActive: boolean
  isKeyboardNavigationActiveForThisArea: boolean
  isNavigationEnabledForThisArea: boolean
  isNavigationActiveForThisArea: boolean
  activateKeyboardNavigation(): void
  inactivateKeyboardNavigation(): void
  freezeNavigation(): void
  unfreezeNavigation(): void
  redrawNavigationPathHighlight(): void
} {
  const {
    refs: { isKeyboardNavigationActiveAccessor, isNavigationFreezerDegreeAccessor },
    isKeyboardNavigationActive,
  } = useContexts(NavigationContexts)

  const {
    refs: { redrawPathHighlight },
    isAreaEnabled,
    isAreaActive,
  } = useContexts(NavigationAreaContexts)

  return {
    isKeyboardNavigationActive,
    isKeyboardNavigationActiveForThisArea: isKeyboardNavigationActive && isAreaActive,
    isNavigationEnabledForThisArea: isAreaEnabled,
    isNavigationActiveForThisArea: isAreaActive,
    activateKeyboardNavigation() {
      isKeyboardNavigationActiveAccessor.set(true)
    },
    inactivateKeyboardNavigation() {
      isKeyboardNavigationActiveAccessor.set(false)
    },
    freezeNavigation() {
      isNavigationFreezerDegreeAccessor.set(isNavigationFreezerDegreeAccessor.get() - 1)
    },
    unfreezeNavigation() {
      isNavigationFreezerDegreeAccessor.set(isNavigationFreezerDegreeAccessor.get() + 1)
    },
    redrawNavigationPathHighlight: redrawPathHighlight,
  }
}

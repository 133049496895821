import { makeStyles } from '@material-ui/core'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Model } from '@zettelooo/server-shared'
import { useMemo, useState } from 'react'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { useHumanizedTimestamp } from '../../../../../../../../../modules/date-time'
import { CustomAvatar } from '../../../../../../../../CustomAvatar'
import { ExtensionRolesProvider, ExtensionScopeProvider } from '../../../../../../../modules/extension'
import { DomHighlighter } from '../../../../../../DomHighlighter'
import { Initializer } from '../../../../../../Initializer'
import { CardViewer } from '../../../../../modules/card'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      marginBottom: theme.spacing(1),
    },
    page: {
      display: 'flex',
      cursor: 'pointer',
      color: theme.palette.info.main,
    },
    pageName: {},
    dot: {
      display: 'flex',
      height: theme.spacing(1.25),
      alignItems: 'center',
    },
    datetime: {},
    cardViewerWrapper: {
      overflow: 'auto',
    },
    cardViewer: {
      backgroundColor: theme.palette.action.selected,
      cursor: 'text',
    },
  }),
  { name: 'CardPreview' }
)

export function CardPreview({
  card,
  searchText,
  onOpenPage,
}: {
  card: Model.Card
  searchText: string
  onOpenPage(): void
}) {
  const { allPages } = useContexts(Initializer.Contexts)

  const [cardViewerWrapperElement, setCardViewerWrapperElement] = useState<HTMLDivElement | null>(null)

  const cardCreatedAt = useHumanizedTimestamp(card.createdAt, 'date with time')

  const classes = useStyles()

  const page = allPages.dictionary[card.pageId]

  const extensionIds = useMemo(
    () => (page ? Model.ExtensionConfiguration.getExtensionIds(page.extensionConfiguration) : []),
    [page]
  )

  if (!page) return null

  return (
    <ExtensionRolesProvider role="CardPreview">
      <ExtensionScopeProvider scope={ZettelExtensions.Scope.Page} value={page.id} extensionIds={extensionIds}>
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.page} onClick={onOpenPage}>
              <CustomAvatar
                size={2}
                styleFontSize="1.1em"
                compact
                avatarFileId={page.avatarFileId}
                name={page.name}
                emoji={page.iconEmoji}
                margin={[0, 1, 0, 0]}
              />

              <div className={classes.pageName}>{page.name}</div>
            </div>

            <div className={classes.dot}>&nbsp;&nbsp;.&nbsp;&nbsp;</div>

            <div className={classes.datetime}>{cardCreatedAt}</div>
          </div>

          <div ref={setCardViewerWrapperElement} className={classes.cardViewerWrapper}>
            <CardViewer className={classes.cardViewer} page={page} card={card} />
          </div>

          <DomHighlighter targetElement={cardViewerWrapperElement} searchPhrase={searchText} />
        </div>
      </ExtensionScopeProvider>
    </ExtensionRolesProvider>
  )
}

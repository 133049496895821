import { Model } from '@zettelooo/server-shared'
import { useEffect } from 'react'
import { setAnalyticUserIdentifier } from '../../../../modules/analytics'
import { webConfig } from '../../../../modules/web-config'

export function useSetAnalyticUserIdentifier(account: Model.Account | undefined): void {
  useEffect(() => {
    if (webConfig.environment.target !== 'live' || webConfig.analytics.disabled) return

    setAnalyticUserIdentifier(account)
  }, [account])
}

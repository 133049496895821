import { makeStyles, Typography, TypographyProps } from '@material-ui/core'
import { PropsWithChildren } from 'react'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
    },
    label: {
      marginBottom: theme.spacing(1),
    },
    container: {
      flexGrow: 1,
    },
  }),
  { name: 'FieldContainer' }
)

export function FieldContainer({
  label,
  labelVariant,
  description,
  children,
}: PropsWithChildren<{
  label?: string
  labelVariant?: TypographyProps['variant']
  description?: string
}>) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {label && (
        <Typography variant={labelVariant || 'subtitle2'} className={classes.label}>
          {label}
        </Typography>
      )}

      <div className={classes.container}>
        {children}

        {description && (
          <Typography variant="caption" color="textSecondary">
            {description}
          </Typography>
        )}
      </div>
    </div>
  )
}

import { makeStyles } from '@material-ui/core'
import { Fragment } from 'react'

const useStyles = makeStyles(
  theme => ({
    highlight: {
      backgroundColor: theme.palette.warning.light,
    },
  }),
  { name: 'TextHighlighter' }
)

export function TextHighlighter({
  children: text,
  tokens,
  range,
}: {
  children: string
  tokens: readonly string[]
  range: number
}) {
  let snippet = ''
  for (let i = 0; i < tokens.length; i += 1) {
    const token = tokens[i]
    const textIndex = text.toLowerCase().indexOf(token)
    if (textIndex !== -1) {
      let startIndex = textIndex - range
      for (let index = startIndex; index >= 0; index -= 1) {
        if (text[index] === ' ') {
          startIndex = index
          break
        }
      }
      let endIndex = textIndex + range
      for (let index = endIndex; index <= text.length; index += 1) {
        if (text[index] === ' ') {
          endIndex = index
          break
        }
      }
      snippet = text.substring(startIndex < 0 ? 0 : startIndex, endIndex > text.length ? text.length : endIndex)
      break
    }
  }

  let currentIndex = 0
  const matches = Array.from(snippet.matchAll(new RegExp(tokens.join('|'), 'gi')))

  const classes = useStyles()

  if (matches.length === 0) return null

  return (
    <>
      {matches.map(match => {
        const result = (
          <Fragment key={`${match[0]}-${match.index}`}>
            {snippet.substring(currentIndex, match.index)}
            <mark className={classes.highlight}>{match[0]}</mark>
          </Fragment>
        )
        currentIndex = (match.index ?? 0) + match[0].length
        return result
      })}
      {snippet.substring(currentIndex)}
    </>
  )
}

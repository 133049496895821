import { makeStyles, Typography, TypographyProps } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles(
  () => ({
    root: {
      flexGrow: 1,
    },
  }),
  { name: 'TypographyNoWrap' }
)

export function TypographyNoWrap({ className, ...otherProps }: Omit<TypographyProps, 'noWrap'>) {
  const classes = useStyles()

  return <Typography {...otherProps} noWrap className={classNames(classes.root, className)} />
}

import { Id } from '@zettelooo/commons'
import { webConfig } from '../web-config'

export function generateLocalStorageBasedId(): Id {
  const rawIdBase = localStorage.getItem(webConfig.localStorageBasedIdGeneratorKeyName)
  const idBase = rawIdBase ? BigInt(rawIdBase) : BigInt(0)
  const newIdBase = idBase + BigInt(1)
  const newRawIdBase = String(newIdBase)
  localStorage.setItem(webConfig.localStorageBasedIdGeneratorKeyName, newRawIdBase)
  return newRawIdBase
}

import { makeStyles, Tooltip, Typography } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { useContext } from 'react'
import { DeviceProvider } from '../../../../../../../../../modules/device'
import { CustomAvatar } from '../../../../../../../../CustomAvatar'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      margin: theme.spacing(0.5, 1),
      padding: theme.spacing(0.5, 0),
      overflowX: 'scroll',
      height: theme.spacing(7),
      cursor: 'pointer',
      marginBottom: theme.spacing(2),
    },
    page: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(3),
      paddingRight: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        '& > $name': {
          maxWidth: 'unset',
        },
      },
    },
    name: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    nameNonMobile: {
      maxWidth: theme.spacing(10),
    },
  }),
  { name: 'PagesFilter' }
)

export function PagesFilter({ pages, onFilter }: { pages: readonly Model.Page[]; onFilter(page: Model.Page): void }) {
  const { isMobile } = useContext(DeviceProvider.Context)

  const classes = useStyles()

  return (
    <div className={classes.root}>
      {pages.map(page => (
        <div key={page.id} className={classes.page} onClick={() => onFilter(page)}>
          <Tooltip title={page.name}>
            <>
              <CustomAvatar
                size={5.625}
                avatarFileId={page.avatarFileId}
                name={page.name}
                emoji={page.iconEmoji}
                color={page.color}
                margin={[0, 1, 0, 0]}
              />

              <Typography variant="subtitle2" className={classNames(classes.name, !isMobile && classes.nameNonMobile)}>
                {page.name}
              </Typography>
            </>
          </Tooltip>
        </div>
      ))}
    </div>
  )
}

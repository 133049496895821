import { ReadonlyRecord } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Extension } from '@zettelooo/server-shared'
import { DependencyList, useCallback, useContext, useEffect, useMemo } from 'react'
import { objectHelpers } from '../../../../../helpers/native/objectHelpers'
import { useDebouncedValue } from '../../../../../hooks/useDebouncedValue'
import { useRefWrap } from '../../../../../hooks/useRefWrap'
import { useContexts } from '../../../../../modules/contexts'
import { ExtensionProviderContexts } from '../components/ExtensionProvider'
import { ExtensionRolesProvider } from '../components/ExtensionRolesProvider'
import { ExtensionScopeProviderContext } from '../components/ExtensionScopeProvider'
import {
  ExtensionLifeSapnDataConverterContext,
  ExtensionLifeSpanEnabledByScope,
  ExtensionLifeSpanProvider,
  ExtensionLifeSpanValuesByScope,
} from '../types'

export function useExtensionLifeSpan<N extends ZettelExtensions.LifeSpan.Name>({
  name,
  target,
  role,
  scopedValues,
  dataFactories,
  accessFactory,
  registryFactory,
  dependencies,
  options,
}: {
  readonly name: N
  readonly target: ZettelExtensions.LifeSpan.Target<N>
  readonly role?: string
  readonly scopedValues: ExtensionLifeSpanValuesByScope<N>
  readonly dataFactories: {
    readonly [K in keyof ZettelExtensions.LifeSpan.Data<N>]:
      | ZettelExtensions.LifeSpan.Data<N>[K]
      | ((provided: { header: Extension.Header }) => ZettelExtensions.LifeSpan.Data<N>[K])
  }
  readonly accessFactory: (provided: { header: Extension.Header }) => ZettelExtensions.LifeSpan.Access<N>
  readonly registryFactory: (provided: { header: Extension.Header }) => ZettelExtensions.LifeSpan.Registry<N>
  readonly dependencies: DependencyList
  readonly options?: {
    readonly disabled?: boolean
  }
}): void {
  const { extensionManager } = useContexts(ExtensionProviderContexts)
  const providedEnabledExtensions = useContext(ExtensionScopeProviderContext)
  const parentRoles = useContext(ExtensionRolesProvider.Context)

  // TODO: I still don't know for sure if this thing is necessary:
  const roles = useMemo(() => (role ? [...parentRoles, role] : parentRoles), [role, parentRoles])

  // Because of the idea of effective extension IDs in the ExtensionScopeProvider,
  // one change in one extensionIds can cause multiple changes in the providedEnabledExtensions in a row.
  // Therefore, we need to debounce it to be affected only once and prevent unnecessary running life spans:
  const providedEnabledExtensionsDebounced = useDebouncedValue(providedEnabledExtensions)

  const dataFactoriesRef = useRefWrap(dataFactories)
  const dataFactoryStatic = useCallback(
    (
      header: Extension.Header,
      dataConverterContext: ExtensionLifeSapnDataConverterContext<N>
    ): ZettelExtensions.LifeSpan.Data<N> =>
      new Proxy({} as any, {
        get(target, property, receiver) {
          const key = property as keyof ZettelExtensions.LifeSpan.Data<N>
          const dataPropertyFactory = Reflect.get(dataFactoriesRef.current, key, receiver)
          if (typeof dataPropertyFactory !== 'function') return dataPropertyFactory
          if (dataPropertyFactory !== dataConverterContext.usedFactories[key]) {
            const typedDataPropertyFactory = dataPropertyFactory as (provided: { header: Extension.Header }) => any
            dataConverterContext.usedFactories[key] = typedDataPropertyFactory
            dataConverterContext.resultedValues[key] = typedDataPropertyFactory({ header })
          }
          return dataConverterContext.resultedValues[key]
        },
      }),
    []
  )

  // TODO: What about the option.initialCallback? Does it work correctly then?
  useEffect(() => {
    if (!options?.disabled) {
      extensionManager.forEachWatchersPerLifeSpan(name, target, roles, (header, watchers) => {
        const data = dataFactoryStatic(header, watchers.dataConverterContext)
        watchers.callbackDependenciesMap.forEach((value, key) => {
          // TODO: Only call 'key' if 'value' is either null or has some modified data property:
          // if (!value || modifiedDataKeys.some(key => value.includes(key)))
          key(data)
        })
      })
    }
  }, [extensionManager, options?.disabled, ...Object.values(dataFactories)])

  const provider = useCallback<ExtensionLifeSpanProvider<N>>(
    ({
      header,
      scopes,
      isDisposed,
      watchers,
      isRegistering,
      startRegistering,
      finishRegistering,
      disposers,
      whileFunction,
    }): ReturnType<ExtensionLifeSpanProvider<N>> => {
      function checkNotToBeDisposed(): void {
        if (isDisposed()) throw Error('The life span of this hook has been terminated.')
      }
      function ensureRestrictedRegistration<T extends (...args: any[]) => ZettelExtensions.LifeSpan.Registrar<any>>(
        registrarFactory: T
      ): T {
        return ((...args: any[]) => {
          const registrar = registrarFactory(...args)
          const [registrarFunction, registrarReferenceRef] = Array.isArray(registrar) ? registrar : [registrar]
          const guardedRegistrarFunction = () => {
            if (!isRegistering())
              throw Error(
                'Registry items, watch(), and api providers/consumers can only be registered by the provided register() method.'
              )
            // TODO: Also, make sure to unregister it when this life span is finished, even before the register(), which may be from another life span
            const unregister = registrarFunction()
            return unregister
          }
          return Array.isArray(registrar) ? [guardedRegistrarFunction, registrarReferenceRef] : guardedRegistrarFunction
        }) as T
      }

      const data = dataFactoryStatic(header, watchers.dataConverterContext)
      const access = accessFactory({ header })
      const registry = registryFactory({ header })

      return {
        target,

        roles,

        scopes,

        data: new Proxy(data, {
          get(target, property, receiver) {
            checkNotToBeDisposed()
            return Reflect.get(target, property, receiver)
          },
        }),

        access: new Proxy({} as ZettelExtensions.LifeSpan.Access<N>, {
          get(target, property, receiver) {
            checkNotToBeDisposed()
            return Reflect.get(access, property, receiver)
          },
        }),

        watch: ensureRestrictedRegistration((selector, callback, options) => {
          checkNotToBeDisposed()
          return () => {
            const watcherCallback = (newData: ZettelExtensions.LifeSpan.Data<N>): void => {
              const newSelectedValue = selector(newData)
              const areEqual = options?.areValuesEqual
                ? options.areValuesEqual(newSelectedValue, oldSelectedValue)
                : newSelectedValue === oldSelectedValue
              if (!areEqual) {
                callback(newSelectedValue, oldSelectedValue)
                oldSelectedValue = newSelectedValue
              }
            }
            watchers.callbackDependenciesMap.set(watcherCallback, [])
            let oldSelectedValue: any
            if (options?.pickDependencies) {
              const fakeData: ReadonlyRecord<
                keyof ZettelExtensions.LifeSpan.Data<N>,
                ZettelExtensions.LifeSpan.Data<N>
              > = new Proxy({} as any, {
                get(target, property, receiver) {
                  const key = property as keyof ZettelExtensions.LifeSpan.Data<N>
                  dependencies.includes(key) || watchers.callbackDependenciesMap.get(watcherCallback)?.push(key)
                  return fakeData
                },
              })
              options.pickDependencies(fakeData)
              oldSelectedValue = selector(data)
            } else {
              oldSelectedValue = selector(
                new Proxy(data, {
                  get(target, property, receiver) {
                    const key = property as keyof ZettelExtensions.LifeSpan.Data<N>
                    dependencies.includes(key) || watchers.callbackDependenciesMap.get(watcherCallback)?.push(key)
                    return Reflect.get(target, property, receiver)
                  },
                })
              )
            }
            if (options?.initialCallback) {
              callback(oldSelectedValue)
            }
            return () => {
              watchers.callbackDependenciesMap.delete(watcherCallback)
            }
          }
        }),

        registry: new Proxy({} as ZettelExtensions.LifeSpan.Registry<N>, {
          get(target, property, receiver) {
            checkNotToBeDisposed()
            return ensureRestrictedRegistration(Reflect.get(registry, property, receiver) as any)
          },
        }),

        register(registrar, options) {
          checkNotToBeDisposed()
          const [registrarFunction, registrarReference] = Array.isArray(registrar) ? registrar : [registrar]
          let unregisterFunction: (() => void) | undefined
          if (!options?.initiallyInactive) {
            activate()
          }

          return {
            reference: registrarReference as NonNullable<typeof registrarReference>,
            isActive,
            isEnabled,
            activate,
            deactivate,
          }

          function isActive(): boolean {
            if (isDisposed()) return false
            return disposers.includes(deactivate)
          }
          function isEnabled(): boolean {
            if (isDisposed()) return false
            return unregisterFunction !== undefined // The same as disposers.includes(unregister)
          }

          function activate(): void {
            checkNotToBeDisposed()
            if (isActive()) return

            disposers.push(deactivate)

            if (options?.condition) {
              watchers.callbackDependenciesMap.set(callback, null)
            }

            if (options?.condition ? options.condition(data) : true) {
              register()
            }
          }
          function deactivate(): void {
            const index = disposers.indexOf(deactivate)
            if (index >= 0) {
              disposers.splice(index, 1)
            }

            watchers.callbackDependenciesMap.delete(callback)

            unregister()
          }

          function callback(newData: any): void {
            const newIsEnabled = Boolean(options?.condition!(newData))
            if (newIsEnabled === isEnabled()) return
            if (newIsEnabled) {
              register()
            } else {
              unregister()
            }
          }

          function register(): void {
            if (isDisposed()) return

            disposers.push(unregister)

            startRegistering()
            unregisterFunction = registrarFunction()
            finishRegistering()
          }
          function unregister(): void {
            const index = disposers.indexOf(unregister)
            if (index >= 0) {
              disposers.splice(index, 1)
            }

            const currentUnregisterFunction = unregisterFunction
            unregisterFunction = undefined
            currentUnregisterFunction?.()
          }
        },

        get disposed() {
          return isDisposed()
        },

        while: whileFunction,
      }
    },
    dependencies
  )

  const enabledByScope = useMemo<ExtensionLifeSpanEnabledByScope<N>>(
    () =>
      objectHelpers.map(
        scopedValues,
        (key, value) =>
          providedEnabledExtensionsDebounced?.[key as ZettelExtensions.Scope][value] ?? {
            extensionIdSet: new Set(),
            effectiveExtensionIdSet: new Set(),
          }
      ),
    [providedEnabledExtensionsDebounced, ...dependencies]
  )

  const noEnabledExtensionsProvided = !providedEnabledExtensionsDebounced

  useEffect(() => {
    if (!options?.disabled && !noEnabledExtensionsProvided) {
      extensionManager.registerLifeSpan(name, target, roles, enabledByScope, provider)
      return () => extensionManager.unregisterLifeSpan(name, target, roles)
    }
  }, [options?.disabled, noEnabledExtensionsProvided, provider])

  useEffect(() => {
    if (!options?.disabled && !noEnabledExtensionsProvided) {
      extensionManager.updateLifeSpanEnabledByScope(name, target, roles, enabledByScope)
    }
  }, [noEnabledExtensionsProvided, enabledByScope])
}

import { ZettelExtensions } from '@zettelooo/extension-api'
import { ExtensionModel } from '../types'
import { ExtensionExecutables } from './types'

export function getExecutables({ js, ...header }: ExtensionModel): ExtensionExecutables {
  const extendedWindow = window as ZettelExtensions.ExtendedWindow

  const { $starter: previousStarter, $cardData: previousCardData, $services: previousServices } = extendedWindow
  delete extendedWindow.$starter
  delete extendedWindow.$cardData
  delete extendedWindow.$services

  try {
    // This is how we extract the extension executables out of the extension itself for now.
    // Nevertheless, we may leverage other approaches in the future:
    // eslint-disable-next-line no-eval
    eval(js)
  } catch (error) {
    log.error('Get Extension Executables:', header, error)
  }

  const executables: ExtensionExecutables = {
    ...('$starter' in extendedWindow ? { starter: extendedWindow.$starter } : {}),
    ...('$cardData' in extendedWindow ? { cardData: extendedWindow.$cardData } : {}),
    ...('$services' in extendedWindow ? { services: extendedWindow.$services } : {}),
  }

  if (previousStarter !== undefined) {
    extendedWindow.$starter = previousStarter
  } else {
    delete extendedWindow.$starter
  }
  if (previousCardData !== undefined) {
    extendedWindow.$cardData = previousCardData
  } else {
    delete extendedWindow.$cardData
  }
  if (previousServices !== undefined) {
    extendedWindow.$services = previousServices
  } else {
    delete extendedWindow.$services
  }

  return executables
}

import { Id } from '@zettelooo/commons'
import { useCallback } from 'react'
import { usePersistent, PersistentKey } from '../../../../../modules/persistent'
import { useSearchCards } from './useSearchCards'

export function useUpdateRecentSearches(): {
  updateRecentSearchesStatic(cardId: Id, query: string, filter: useSearchCards.SearchFilter): void
} {
  const { persistent } = usePersistent()

  return {
    updateRecentSearchesStatic: useCallback((cardId, query, filter) => {
      if (
        persistent[PersistentKey.RecentSearches].every(
          recentSearch => recentSearch.cardId !== cardId || recentSearch.query !== query
        )
      ) {
        persistent[PersistentKey.RecentSearches] = [
          {
            cardId,
            query,
            filter,
          },
          ...persistent[PersistentKey.RecentSearches],
        ].slice(0, 5)
      }
    }, []),
  }
}

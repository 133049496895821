import { Id } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Model } from '@zettelooo/server-shared'
import { memo, useMemo } from 'react'
import { arrayHelpers } from '../../../../helpers/native/arrayHelpers'
import { createContexts } from '../../../../modules/contexts'
import { commonMutablesDatabaseReaders } from '../../modules/databases'
import { EphemeralKey, useEphemeral } from '../../modules/ephemeral'
import { ExtensionScopeProvider } from '../../modules/extension'
import { PersistentKey, usePersistent } from '../../modules/persistent'
import { Content } from '../Content'
import { SmartCommandApiProvider } from './SmartCommandApiProvider'
import { useChromeExtensionDragAndDropMessageHandling } from './useChromeExtensionDragAndDropMessageHandling'
import { useChromeExtensionThemTypeUpdating } from './useChromeExtensionThemTypeUpdating'
import { useExportDemoContentWindowDev } from './useExportDemoContentWindowDev'
import { useInitialIpcMessageHandling } from './useInitialIpcMessageHandling'
import { usePostDemoModeByUsername } from './usePostDemoModeByUsername'
import { useSentryConfigureScope } from './useSentryConfigureScope'
import { useSetAnalyticUserIdentifier } from './useSetAnalyticUserIdentifier'

export namespace Initializer {
  export const Component = memo(function Initializer() {
    const [, deviceId, authentication] = usePersistent(PersistentKey.DeviceId, PersistentKey.Authentication)

    const [, onMemoryModelsRegistrations] = useEphemeral(EphemeralKey.OnMemoryModelsRegistrations)

    // TODO: Put all these model readings into a separate hook:

    const { accountIsLoading, account } = commonMutablesDatabaseReaders.useAccount(
      authentication?.decodedAccessToken.userId ?? '',
      {
        disabled: !authentication,
      }
    )

    const { allUsers } = commonMutablesDatabaseReaders.useAllUsers()
    const { allPages } = commonMutablesDatabaseReaders.useAllPages()
    const { allCards } = commonMutablesDatabaseReaders.useAllCards()
    const { allBadges } = commonMutablesDatabaseReaders.useAllBadges()

    const pagesExtensionIds = useMemo(
      () => [
        ...allPages.array.flatMap(page => Model.ExtensionConfiguration.getExtensionIds(page.extensionConfiguration)),
        ...Object.values(onMemoryModelsRegistrations).flatMap(
          onMemoryModels =>
            onMemoryModels[Model.Type.Page]?.flatMap(page =>
              Model.ExtensionConfiguration.getExtensionIds(page.extensionConfiguration)
            ) ?? []
        ),
      ],
      [allPages, onMemoryModelsRegistrations]
    )

    const groupedBadges = useMemo(() => {
      const developingGroupedBadges = {
        byPageId: new Map<Id, Model.Badge[]>(),
        byCardId: new Map<Id, Model.Badge[]>(),
      }
      if (!accountIsLoading && !allPages.stillLoading && !allCards.stillLoading && !allBadges.stillLoading) {
        allBadges.array.forEach(badge => {
          if (badge.userId !== account?.id) return
          switch (badge.action.type) {
            case Model.Badge.Action.Type.JoinPage:
              if (badge.action.pageId in allPages.dictionary) {
                if (!developingGroupedBadges.byPageId.has(badge.action.pageId)) {
                  developingGroupedBadges.byPageId.set(badge.action.pageId, [])
                }
                developingGroupedBadges.byPageId.get(badge.action.pageId)!.push(badge)
              }
              break

            case Model.Badge.Action.Type.CreateCard:
            case Model.Badge.Action.Type.UpdateCard:
              if (badge.action.cardId in allCards.dictionary) {
                if (!developingGroupedBadges.byCardId.has(badge.action.cardId)) {
                  developingGroupedBadges.byCardId.set(badge.action.cardId, [])
                }
                developingGroupedBadges.byCardId.get(badge.action.cardId)!.push(badge)
              }
              break

            default:
              throw Error(`Unknown badge action type: "${(badge.action as any)?.type}"`)
          }
        })
        Array.from(developingGroupedBadges.byCardId.keys()).forEach(cardId => {
          const { pageId } = allCards.dictionary[cardId]!
          if (pageId in allPages.dictionary) {
            if (!developingGroupedBadges.byPageId.has(pageId)) {
              developingGroupedBadges.byPageId.set(pageId, [])
            }
            arrayHelpers.extend(
              developingGroupedBadges.byPageId.get(pageId)!,
              developingGroupedBadges.byCardId.get(cardId)!
            )
          }
        })
      }
      return developingGroupedBadges
    }, [accountIsLoading, account, allPages, allCards, allBadges])

    useSentryConfigureScope(account)
    useSetAnalyticUserIdentifier(account)
    useInitialIpcMessageHandling()
    useChromeExtensionThemTypeUpdating()
    useChromeExtensionDragAndDropMessageHandling()
    useExportDemoContentWindowDev()
    usePostDemoModeByUsername()

    return (
      <Contexts.Provider
        parameters={{
          accountIsLoading,
          account,
          allUsers,
          allPages,
          allCards,
          allBadges,
          pagesExtensionIds,
          groupedBadges,
        }}
        windowDev
      >
        <ExtensionScopeProvider
          scope={ZettelExtensions.Scope.Device}
          value={deviceId}
          extensionIds={[]}
          effectiveExtensionIdsList={[pagesExtensionIds]}
        >
          <SmartCommandApiProvider>
            <Content />
          </SmartCommandApiProvider>
        </ExtensionScopeProvider>
      </Contexts.Provider>
    )
  })

  // TODO: The following contexts (at least most of it) should be moved to somewhere like MutablesDatabaseContexts or so, not here:
  export const Contexts = createContexts(
    () =>
      (parameters?: {
        accountIsLoading: boolean
        account: Model.Account | undefined
        allUsers: commonMutablesDatabaseReaders.AllModels<Model.Type.User>
        allPages: commonMutablesDatabaseReaders.AllModels<Model.Type.Page>
        allCards: commonMutablesDatabaseReaders.AllModels<Model.Type.Card>
        allBadges: commonMutablesDatabaseReaders.AllModels<Model.Type.Badge>
        pagesExtensionIds: readonly Id[]
        groupedBadges: {
          readonly byPageId: ReadonlyMap<Id, readonly Model.Badge[]>
          readonly byCardId: ReadonlyMap<Id, readonly Model.Badge[]>
        }
      }) => ({
        account: parameters?.accountIsLoading ? undefined : parameters?.account,
        allUsers: parameters?.allUsers!,
        allPages: parameters?.allPages!,
        allCards: parameters?.allCards!,
        allBadges: parameters?.allBadges!,
        pagesExtensionIds: parameters?.pagesExtensionIds!,
        groupedBadges: parameters?.groupedBadges!,
      }),
    'Initializer'
  )
}

import { WindowType } from '@zettelooo/desktop-shared'
import { ChromeExtensionMode } from '../../../../modules/chrome-extension-mode'
import { DemoMode } from '../../../../modules/demo-mode'
import { PeekMode } from '../../../../modules/peek-mode'

export function useIsEnabled(): boolean {
  return (
    (!window.electronProxy || window.electronProxy.windowType === WindowType.Main) &&
    !DemoMode.data &&
    !PeekMode.data &&
    !ChromeExtensionMode.isActive
  )
}

import { makeStyles, Paper, PaperProps } from '@material-ui/core'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { convertCardModelToCardPublicModel, convertPageModelToPagePublicModel, Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { ComponentRef, Dispatch, forwardRef, SetStateAction, useCallback, useMemo, useState } from 'react'
import { arrayHelpers } from '../../../../../../helpers/native/arrayHelpers'
import { useRefWrap } from '../../../../../../hooks/useRefWrap'
import { ExtensionRolesProvider, useExtensionLifeSpan } from '../../../../modules/extension'
import { HtmlContentTools } from '../../../../modules/HtmlContentTools'
import { PersistentKey, usePersistent } from '../../../../modules/persistent'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  }),
  { name: 'CardViewer' }
)

export const CardViewer = forwardRef<
  ComponentRef<typeof Paper>,
  {
    page: Model.Page
    card: Model.Card
    isHighlighted?: boolean
    setExtendedMenuItems?: Dispatch<SetStateAction<readonly ZettelExtensions.LifeSpan.Shared.Card.MenuItem[]>>
  } & PaperProps
>(function CardViewer({ page, card, isHighlighted, setExtendedMenuItems, elevation, className, ...otherProps }, ref) {
  const [, deviceId, authentication] = usePersistent(PersistentKey.DeviceId, PersistentKey.Authentication)

  const [extendedParts, setExtendedParts] = useState<
    readonly HtmlContentTools.WithReferenceKey<ZettelExtensions.LifeSpan.Shared.Card.Part<any>>[]
  >([])

  const contentRef = useRefWrap({
    setExtendedMenuItems,
  })

  useExtensionLifeSpan({
    name: 'card',
    target: {
      pageId: card.pageId,
      cardId: card.id,
    },
    scopedValues: {
      [ZettelExtensions.Scope.Device]: deviceId,
      [ZettelExtensions.Scope.User]: authentication?.decodedAccessToken.userId ?? '',
      [ZettelExtensions.Scope.Page]: card.pageId,
    },
    dataFactories: {
      page: useCallback(({ header }) => convertPageModelToPagePublicModel(page, header.id), [page]),
      card: useCallback(({ header }) => convertCardModelToCardPublicModel(card, header.id), [card]),
    },
    accessFactory: () => ({}),
    registryFactory: () => ({
      menuItem(getter) {
        return () => {
          const currentValue = getter()
          contentRef.current.setExtendedMenuItems?.(current => [...current, currentValue])
          return () =>
            contentRef.current.setExtendedMenuItems?.(current => current.filter(item => item !== currentValue))
        }
      },
      part(getter) {
        let currentValue: (typeof extendedParts)[number]
        const reference: {
          current?: ZettelExtensions.LifeSpan.Shared.Card.Part.Reference<any>
        } = {}
        return [
          () => {
            currentValue = HtmlContentTools.withReferenceKey(getter())
            reference.current = {
              ...HtmlContentTools.createReference(currentValue),
              update(updates) {
                setExtendedParts(current => {
                  const evaluatedUpdates = typeof updates === 'function' ? updates(currentValue) : updates
                  HtmlContentTools.handleUpdates(currentValue, evaluatedUpdates)
                  const oldValue = currentValue
                  const newValue = { ...oldValue, ...evaluatedUpdates }
                  currentValue = newValue
                  return arrayHelpers.replace(current, oldValue, newValue)
                })
              },
            }
            setExtendedParts(current => [...current, currentValue])
            return () => {
              setExtendedParts(current => arrayHelpers.remove(current, currentValue))
              delete reference.current
              HtmlContentTools.deleteReference(currentValue)
            }
          },
          reference,
        ]
      },
    }),
    dependencies: [deviceId, authentication, card.id, card.pageId],
  })

  const orderedExtendedParts = useMemo(
    () =>
      arrayHelpers.orderBy(extendedParts, item => (item.position === 'top' ? -1 : item.position === 'bottom' ? +1 : 0)),
    [extendedParts]
  )

  const classes = useStyles()

  return (
    <ExtensionRolesProvider role="CardViewer">
      <Paper
        {...otherProps}
        ref={ref}
        elevation={elevation ?? (isHighlighted ? 4 : 0)}
        className={classNames(classes.root, className)}
      >
        {orderedExtendedParts.map((part, index) => (
          <HtmlContentTools.Render key={index} htmlContentWithReferenceKey={part}>
            {({ containerRefCallback }) => <div ref={containerRefCallback} />}
          </HtmlContentTools.Render>
        ))}
      </Paper>
    </ExtensionRolesProvider>
  )
})

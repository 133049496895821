import Color from 'color'
import { extendThemeOptions } from '../extendThemeOptions'
import { baseThemeOptions } from './baseThemeOptions'

const palettePrimaryMain = '#2F2F2F'

export const lightThemeOptions = extendThemeOptions(baseThemeOptions, {
  palette: {
    type: 'light',
    primary: {
      main: palettePrimaryMain,
    },
    info: {
      main: '#0288d1',
      dark: '#002FA7',
    },
    background: {
      default: '#FDF5F0',
      paper: '#FFFFFF',
      code: '#F8F9FB',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // Scrollbar for FireFox only:
        '*': {
          scrollbarWidth: 'thin',
          scrollbarColor: 'transparent transparent',
          '&:hover': {
            scrollbarColor: '#BBB transparent',
          },
        },
        // Scrollbar for other browsers:
        '::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '::-webkit-scrollbar-track': {
          margin: 0,
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          borderRadius: '10px',
        },
        ':hover::-webkit-scrollbar-thumb': {
          backgroundColor: '#CCC',
        },
        '::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#BBB',
        },

        '@media screen and (max-width: 480px)': {
          '::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
          },
        },
      },
    },
    MuiChip: {
      colorPrimary: {
        color: new Color(palettePrimaryMain).darken(0.4).toString(),
        backgroundColor: new Color(palettePrimaryMain).desaturate(0.4).lighten(3).toString(),
      },
    },
  },
})

import { Typography } from '@material-ui/core'
import { version as extensionApiVersion } from '@zettelooo/extension-api'
import { version as serverSharedVersion } from '@zettelooo/server-shared'
import { webConfig } from '../../../../../../../../../../../../modules/web-config'
import { getVersion } from '../../../../../../../../../../helpers/getVersion'
import { usePersistent, PersistentKey } from '../../../../../../../../../../modules/persistent'
import { ButtonField } from '../ButtonField'
import { LinkField } from '../LinkField'

export function AboutSubPanel() {
  const { persistent } = usePersistent()

  return (
    <>
      <LinkField href={webConfig.urls.privacy} target="_blank">
        Privacy Term
        <Typography display="inline" color="textSecondary">
          &nbsp;&nbsp;&gt;
        </Typography>
      </LinkField>

      <ButtonField
        onClick={() =>
          persistent(PersistentKey.LastDisplayedChangeLog).set({
            forVersion: '' /* Something that is different from the current version */,
            // No contentVersion to ensure displaying changelog
          })
        }
      >
        Version = {getVersion()}
      </ButtonField>

      <ButtonField>Extension API Version = {extensionApiVersion}</ButtonField>

      <ButtonField>Server Private API Version = {serverSharedVersion}</ButtonField>
    </>
  )
}

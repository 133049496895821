import { makeStyles, MenuItem, MenuItemProps, PopoverProps } from '@material-ui/core'
import classNames from 'classnames'
import { MouseEvent, ReactNode, useRef, useState } from 'react'
import { useCommonStyles } from '../../hooks/useCommonStyles'
import { CustomIcon } from '../../modules/custom-icon'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginLeft: theme.spacing(2),
    },
  }),
  { name: 'MenuItemWithSubMenu' }
)

export function MenuItemWithSubMenu({
  subMenu,
  subMenuOpen,
  onCloseSubMenu,
  className,
  children,
  ...otherProps
}: MenuItemProps & {
  subMenu(provided: {
    popoverProps: Pick<
      PopoverProps,
      'open' | 'onClose' | 'anchorEl' | 'getContentAnchorEl' | 'transformOrigin' | 'anchorOrigin'
    >
    closeSubMenu(options?: { done?: boolean }): void
  }): ReactNode
  subMenuOpen?: boolean
  onCloseSubMenu?(options?: { done?: boolean }): void
}) {
  const [open, setOpen] = useState(false)

  const managed = useRef(typeof subMenuOpen === 'boolean').current

  const rootRef = useRef<HTMLLIElement>(null)

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <>
      <MenuItem
        {...(otherProps as any)}
        ref={rootRef}
        className={classNames(className, classes.root)}
        onMouseDown={
          managed
            ? otherProps.onMouseDown
            : (event: MouseEvent<any, any>) => {
                event.preventDefault()
                setOpen(true)
                otherProps.onMouseDown?.(event)
              }
        }
      >
        {children}

        <div className={commonClasses.grow} />

        <CustomIcon name="ChevronRight" size="small" color="text.disabled" className={classes.icon} />
      </MenuItem>

      {subMenu({
        popoverProps: {
          open: managed ? Boolean(subMenuOpen) : open,
          anchorEl: rootRef.current,
          getContentAnchorEl: null,
          onClose() {
            if (!managed) {
              setOpen(false)
            }
            onCloseSubMenu?.()
          },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        },
        closeSubMenu(options) {
          if (!managed) {
            setOpen(false)
          }
          onCloseSubMenu?.(options)
        },
      })}
    </>
  )
}

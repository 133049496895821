import {
  AppBar,
  Button,
  Chip,
  Container,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { Developer, Extension } from '@zettelooo/server-shared'
import { useCallback, useEffect, useState } from 'react'
import { useCopyToClipboard, useMountedState } from 'react-use'
import { CustomIcon } from '../../../../../../../modules/custom-icon'
import { useFileInput } from '../../../../../../../modules/file'
import { webConfig } from '../../../../../../../modules/web-config'
import { Gap } from '../../../../../../Gap'
import { useAppNotistack } from '../../../../../modules/app-notistack'
import { useConfirmationDialog } from '../../../../../modules/confirmation-dialog'
import { useServices } from '../../../../../modules/services'
import { Authentication } from './Authentication'

const useStyles = makeStyles(
  theme => ({
    initialLoadingMessage: {
      position: 'fixed',
      top: '50vh',
      left: '50vw',
      transform: 'translate(-50%, -50%)',
    },
    root: {
      minHeight: '100vh',
    },
    appBarButtonDisabled: {
      color: 'inherit !important',
      opacity: 0.3,
    },
    tableContainer: {
      margin: theme.spacing(6, 0),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    chip: {
      margin: theme.spacing(1),
    },
  }),
  { name: 'DeveloperView' }
)

export function DeveloperView({ signedInUser }: { signedInUser: Authentication.DeveloperUser }) {
  const [disabled, setDisabled] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [signedInDeveloper, setSignedInDeveloper] = useState<Developer.Display>()
  const [extensionFlows, setExtensionFlows] = useState<readonly Extension.Flow.Display[]>()

  const { signOutStatic } = Authentication.useControl()

  const { services } = useServices()

  const isMounted = useMountedState()

  const fetchData = useCallback(async (): Promise<void> => {
    try {
      setFetching(true)
      const pageData = await services.developer.getPageData(signedInUser.id)
      if (!isMounted()) return
      setSignedInDeveloper(pageData.signedInDeveloper)
      setExtensionFlows(pageData.extensionFlows)
    } catch {
      // Do nothing!
    } finally {
      setFetching(false)
    }
  }, [signedInUser, services])

  useEffect(() => {
    fetchData()
    const interval = setInterval(() => fetchData(), webConfig.timings.developerPageFetchDataInterval)
    return () => clearInterval(interval)
  }, [fetchData])

  const [, copyToClipboard] = useCopyToClipboard()

  const { enqueueSnackbar, closeSnackbar } = useAppNotistack()

  const { confirmStatic } = useConfirmationDialog()

  const { openFileInputStatic, renderFileInput } = useFileInput({
    allowMultipleFilesSelection: true,
    allowedFileTypes: ['.zip'],
  })

  const classes = useStyles()

  if (!signedInDeveloper || !extensionFlows)
    return (
      <Typography variant="h6" className={classes.initialLoadingMessage}>
        Loading...
      </Typography>
    )

  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6">
            {signedInDeveloper.name} &nbsp;&mdash;&nbsp; {signedInUser.id}
          </Typography>

          <Gap grow />

          <Button
            variant="text"
            color="inherit"
            classes={{ disabled: classes.appBarButtonDisabled }}
            disabled={fetching}
            onClick={async () => {
              const appId = prompt(
                'Extension app ID (in kebab-case, without the developer ID, e.g.: "my-app-id" for extension "my-id.my-app-id")'
              )
              if (!appId) return
              const id = `${signedInDeveloper.id}.${appId}`
              if (extensionFlows.some(extensionFlow => extensionFlow.id === id)) {
                enqueueSnackbar('Duplicated extension app ID', `Extension "${id}" already exists.`, {
                  variant: 'warning',
                })
                return
              }
              try {
                setDisabled(true)
                await services.developer.createExtensionFlow(signedInDeveloper.id, appId)
                await fetchData()
              } catch (error) {
                log.error(error)
                enqueueSnackbar('Error', String(error), { variant: 'error' })
              } finally {
                if (isMounted()) {
                  setDisabled(false)
                }
              }
            }}
          >
            <CustomIcon name="Add" />
            &nbsp;&nbsp;New extension
          </Button>

          <Gap horizontal={2} />

          <Button
            variant="contained"
            color="primary"
            classes={{ disabled: classes.appBarButtonDisabled }}
            disabled={disabled}
            onClick={async () => {
              const files = await openFileInputStatic()
              if (!files || files.length <= 0) return
              setDisabled(true)
              for (let i = 0; i < files.length; i += 1) {
                const file = files[i]
                try {
                  await services.developer.uploadExtension(signedInUser.id, file)
                  await fetchData()
                } catch (error) {
                  log.error(error)
                  enqueueSnackbar(`Error on file "${file.name}"`, String(error), { variant: 'error' })
                }
              }
              if (isMounted()) {
                setDisabled(false)
              }
            }}
          >
            <CustomIcon name="Upload" />
            &nbsp;&nbsp;Upload extension packed ZIP files
          </Button>

          <Gap horizontal={2} />

          <Button
            variant="text"
            color="inherit"
            classes={{ disabled: classes.appBarButtonDisabled }}
            disabled={fetching}
            onClick={fetchData}
          >
            <CustomIcon name="Sync" />
            &nbsp;&nbsp;Refresh
          </Button>

          <Gap horizontal={2} />

          <Button variant="text" color="inherit" onClick={signOutStatic}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>

      <Container>
        <div className={classes.tableContainer}>
          <Typography variant="h5">
            🔑 Developer Access Keys:&nbsp;&nbsp;
            <Button
              variant="outlined"
              size="small"
              color="primary"
              disabled={disabled}
              onClick={async () => {
                const name = prompt('Access key name:', 'main')
                if (!name) return
                if (name in signedInDeveloper.shortenedAccessKeysByName) {
                  enqueueSnackbar('Failed to add access key', `Duplicated name "${name}".`, {
                    variant: 'error',
                  })
                  return
                }
                try {
                  setDisabled(true)
                  const { accessKey } = await services.developer.grantAccessKey(signedInUser.id, name)
                  const snackbarKey = enqueueSnackbar(
                    `Successfully created!`,
                    <>
                      Please save your extension access key:
                      <br />
                      <strong>{accessKey}</strong>
                      <br />
                      <Button
                        color="inherit"
                        onClick={() => {
                          copyToClipboard(accessKey)
                          closeSnackbar(snackbarKey)
                        }}
                      >
                        <CustomIcon name="Copy" />
                        &nbsp;&nbsp;Copy to clipboard
                      </Button>
                    </>,
                    { variant: 'success' }
                  )
                  await fetchData()
                } catch (error) {
                  log.error(error)
                  enqueueSnackbar('Error', String(error), { variant: 'error' })
                } finally {
                  if (isMounted()) {
                    setDisabled(false)
                  }
                }
              }}
            >
              + Add new developer access key
            </Button>
          </Typography>
          {Object.keys(signedInDeveloper.shortenedAccessKeysByName).length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No developer access keys.
            </Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Access Key (shortened)</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(signedInDeveloper.shortenedAccessKeysByName).map(accessKeyName => (
                    <TableRow key={accessKeyName}>
                      <TableCell>{accessKeyName}</TableCell>
                      <TableCell>{signedInDeveloper.shortenedAccessKeysByName[accessKeyName]}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          disabled={disabled}
                          onClick={async () => {
                            const confirmed = await confirmStatic({
                              title: 'Delete developer access key',
                              content: `Are you sure to delete developer access key "${accessKeyName}"?`,
                              confirmLabel: `Delete developer access key "${accessKeyName}"`,
                              cancelLabel: 'Cancel',
                            })
                            if (!confirmed) return
                            try {
                              setDisabled(true)
                              await services.developer.revokeAccessKey(signedInUser.id, accessKeyName)
                              await fetchData()
                            } catch (error) {
                              log.error(error)
                              enqueueSnackbar('Error', String(error), { variant: 'error' })
                            } finally {
                              if (isMounted()) {
                                setDisabled(false)
                              }
                            }
                          }}
                        >
                          <CustomIcon name="Delete" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        {extensionFlows.map(extensionFlow => (
          <div key={extensionFlow.id} className={classes.tableContainer}>
            <Typography variant="h5">
              🧩 {extensionFlow.id.slice(signedInDeveloper.id.length + 1)}: &nbsp;&nbsp;
              <Button
                variant="outlined"
                size="small"
                color="primary"
                disabled={disabled}
                onClick={async () => {
                  const name = prompt('Access key name:', 'main')
                  if (!name) return
                  if (name in extensionFlow.shortenedAccessKeysByName) {
                    enqueueSnackbar('Failed to add access key', `Duplicated name "${name}".`, {
                      variant: 'error',
                    })
                    return
                  }
                  try {
                    setDisabled(true)
                    const { accessKey } = await services.developer.grantExtensionAccessKey(
                      signedInUser.id,
                      extensionFlow.id,
                      name
                    )
                    const snackbarKey = enqueueSnackbar(
                      `Successfully created!`,
                      <>
                        Please save your extension access key:
                        <br />
                        <strong>{accessKey}</strong>
                        <br />
                        <Button
                          color="inherit"
                          onClick={() => {
                            copyToClipboard(accessKey)
                            closeSnackbar(snackbarKey)
                          }}
                        >
                          <CustomIcon name="Copy" />
                          &nbsp;&nbsp;Copy to clipboard
                        </Button>
                      </>,
                      { variant: 'success' }
                    )
                    await fetchData()
                  } catch (error) {
                    log.error(error)
                    enqueueSnackbar('Error', String(error), { variant: 'error' })
                  } finally {
                    if (isMounted()) {
                      setDisabled(false)
                    }
                  }
                }}
              >
                + Add new extension access key
              </Button>
              <Gap horizontal={2} />
              <IconButton
                disabled={disabled}
                onClick={async () => {
                  const confirmed = await confirmStatic({
                    title: 'Delete extension flow',
                    content: `Are you sure to permanently delete extension flow "${extensionFlow.id}" along with its stored access keys, user names, etc.?`,
                    confirmLabel: 'Delete this extension',
                    cancelLabel: 'Cancel',
                  })
                  if (!confirmed) return
                  try {
                    setDisabled(true)
                    await services.developer.deleteExtension(signedInUser.id, extensionFlow.id)
                    await fetchData()
                  } catch (error) {
                    log.error(error)
                    enqueueSnackbar('Error', String(error), { variant: 'error' })
                  } finally {
                    if (isMounted()) {
                      setDisabled(false)
                    }
                  }
                }}
              >
                <CustomIcon name="Delete" />
              </IconButton>
            </Typography>
            {Object.values(extensionFlow.phases).filter(Boolean).length === 0 ? (
              <Typography variant="body1" color="textSecondary">
                No active version.
              </Typography>
            ) : (
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>Version</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Accessing user names</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {extensionFlow.phases[Extension.Flow.PublicationMode.Developing] && (
                      <TableRow>
                        <TableCell>🔵 Under development</TableCell>
                        <TableCell>
                          {extensionFlow.phases[Extension.Flow.PublicationMode.Developing].version || <>&mdash;</>}
                        </TableCell>
                        <TableCell>
                          {extensionFlow.phases[Extension.Flow.PublicationMode.Developing].name || <>&mdash;</>}
                        </TableCell>
                        <TableCell>
                          Test users:
                          {extensionFlow.testerUserNames.map(userName => (
                            <Chip
                              key={userName}
                              label={userName}
                              className={classes.chip}
                              disabled={disabled}
                              onDelete={async () => {
                                try {
                                  setDisabled(true)
                                  await services.developer.modifyExtensionRelatedUserName(
                                    signedInUser.id,
                                    extensionFlow.id,
                                    'tester',
                                    'remove',
                                    userName
                                  )
                                  await fetchData()
                                } catch (error) {
                                  log.error(error)
                                  enqueueSnackbar('Error', String(error), { variant: 'error' })
                                } finally {
                                  if (isMounted()) {
                                    setDisabled(false)
                                  }
                                }
                              }}
                            />
                          ))}
                          <IconButton
                            disabled={disabled}
                            onClick={async () => {
                              try {
                                setDisabled(true)
                                const userName = prompt('Insert user name:')
                                if (userName) {
                                  await services.developer.modifyExtensionRelatedUserName(
                                    signedInUser.id,
                                    extensionFlow.id,
                                    'tester',
                                    'add',
                                    userName
                                  )
                                  await fetchData()
                                }
                              } catch (error) {
                                log.error(error)
                                enqueueSnackbar('Error', String(error), { variant: 'error' })
                              } finally {
                                if (isMounted()) {
                                  setDisabled(false)
                                }
                              }
                            }}
                          >
                            <CustomIcon name="AddMember" />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            size="small"
                            disabled={disabled}
                            onClick={async () => {
                              const confirmed = await confirmStatic({
                                title: 'Stage extension version',
                                content: `This version of the extension "${extensionFlow.id}" will be accessible to the target users, then you'll be able to submit it to be published to anyone.`,
                                confirmLabel: 'Publish it to the target users',
                                cancelLabel: 'Cancel',
                              })
                              if (!confirmed) return
                              try {
                                setDisabled(true)
                                await services.developer.limitedPublishExtension(signedInDeveloper.id, extensionFlow.id)
                                await fetchData()
                              } catch (error) {
                                log.error(error)
                                enqueueSnackbar('Error', String(error), { variant: 'error' })
                              } finally {
                                if (isMounted()) {
                                  setDisabled(false)
                                }
                              }
                            }}
                          >
                            &darr; Publish to target users
                          </Button>
                          <Gap horizontal={1} />
                          <IconButton
                            disabled={disabled}
                            onClick={async () => {
                              const confirmed = await confirmStatic({
                                title: 'Delete extension version',
                                content: `Are you sure to delete the developing version of the extension "${extensionFlow.id}"?`,
                                confirmLabel: 'Delete the developing version',
                                cancelLabel: 'Cancel',
                              })
                              if (!confirmed) return
                              try {
                                setDisabled(true)
                                await services.developer.deleteExtension(
                                  signedInDeveloper.id,
                                  extensionFlow.id,
                                  Extension.Flow.PublicationMode.Developing
                                )
                                await fetchData()
                              } catch (error) {
                                log.error(error)
                                enqueueSnackbar('Error', String(error), { variant: 'error' })
                              } finally {
                                if (isMounted()) {
                                  setDisabled(false)
                                }
                              }
                            }}
                          >
                            <CustomIcon name="Delete" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}
                    {extensionFlow.phases[Extension.Flow.PublicationMode.LimitedPublished] && (
                      <TableRow>
                        <TableCell>
                          {extensionFlow.stagedToBePublished
                            ? '🟠 Under review to be published'
                            : '🟡 Staged (limited published)'}
                        </TableCell>
                        <TableCell>
                          {extensionFlow.phases[Extension.Flow.PublicationMode.LimitedPublished].version || (
                            <>&mdash;</>
                          )}
                        </TableCell>
                        <TableCell>
                          {extensionFlow.phases[Extension.Flow.PublicationMode.LimitedPublished].name || <>&mdash;</>}
                        </TableCell>
                        <TableCell>
                          Target users:
                          {extensionFlow.targetUserNames.map(userName => (
                            <Chip
                              key={userName}
                              label={userName}
                              className={classes.chip}
                              disabled={disabled}
                              onDelete={async () => {
                                try {
                                  setDisabled(true)
                                  await services.developer.modifyExtensionRelatedUserName(
                                    signedInUser.id,
                                    extensionFlow.id,
                                    'target',
                                    'remove',
                                    userName
                                  )
                                  await fetchData()
                                } catch (error) {
                                  log.error(error)
                                  enqueueSnackbar('Error', String(error), { variant: 'error' })
                                } finally {
                                  if (isMounted()) {
                                    setDisabled(false)
                                  }
                                }
                              }}
                            />
                          ))}
                          <IconButton
                            disabled={disabled}
                            onClick={async () => {
                              try {
                                setDisabled(true)
                                const userName = prompt('Insert user name:')
                                if (userName) {
                                  await services.developer.modifyExtensionRelatedUserName(
                                    signedInUser.id,
                                    extensionFlow.id,
                                    'target',
                                    'add',
                                    userName
                                  )
                                  await fetchData()
                                }
                              } catch (error) {
                                log.error(error)
                                enqueueSnackbar('Error', String(error), { variant: 'error' })
                              } finally {
                                if (isMounted()) {
                                  setDisabled(false)
                                }
                              }
                            }}
                          >
                            <CustomIcon name="AddMember" />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          {extensionFlow.stagedToBePublished ? (
                            <Button
                              variant="outlined"
                              size="small"
                              disabled={disabled}
                              onClick={async () => {
                                try {
                                  setDisabled(true)
                                  await services.developer.editExtensionStagedToBePublished(
                                    signedInDeveloper.id,
                                    extensionFlow.id,
                                    false
                                  )
                                  await fetchData()
                                } catch (error) {
                                  log.error(error)
                                  enqueueSnackbar('Error', String(error), { variant: 'error' })
                                } finally {
                                  if (isMounted()) {
                                    setDisabled(false)
                                  }
                                }
                              }}
                            >
                              ⨯ Withdraw from publishing review
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              disabled={disabled}
                              onClick={async () => {
                                const confirmed = await confirmStatic({
                                  title: 'Publish extension version',
                                  content: `This version of the extension "${extensionFlow.id}" will be accessible to all the users. Your extension needs to be reviewed before getting published.`,
                                  confirmLabel: 'Publish it to anyone',
                                  cancelLabel: 'Cancel',
                                })
                                if (!confirmed) return
                                try {
                                  setDisabled(true)
                                  await services.developer.editExtensionStagedToBePublished(
                                    signedInDeveloper.id,
                                    extensionFlow.id,
                                    true
                                  )
                                  await fetchData()
                                } catch (error) {
                                  log.error(error)
                                  enqueueSnackbar('Error', String(error), { variant: 'error' })
                                } finally {
                                  if (isMounted()) {
                                    setDisabled(false)
                                  }
                                }
                              }}
                            >
                              &darr;&darr; Publish to anyone
                            </Button>
                          )}
                          <Gap horizontal={1} />
                          <IconButton
                            disabled={disabled}
                            onClick={async () => {
                              const confirmed = await confirmStatic({
                                title: 'Delete extension version',
                                content: `Are you sure to delete the staging version of the extension "${extensionFlow.id}"?`,
                                confirmLabel: 'Delete the staging version',
                                cancelLabel: 'Cancel',
                              })
                              if (!confirmed) return
                              try {
                                setDisabled(true)
                                await services.developer.deleteExtension(
                                  signedInDeveloper.id,
                                  extensionFlow.id,
                                  Extension.Flow.PublicationMode.LimitedPublished
                                )
                                await fetchData()
                              } catch (error) {
                                log.error(error)
                                enqueueSnackbar('Error', String(error), { variant: 'error' })
                              } finally {
                                if (isMounted()) {
                                  setDisabled(false)
                                }
                              }
                            }}
                          >
                            <CustomIcon name="Delete" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}
                    {extensionFlow.phases[Extension.Flow.PublicationMode.Published] && (
                      <TableRow>
                        <TableCell>🟢 Published</TableCell>
                        <TableCell>
                          {extensionFlow.phases[Extension.Flow.PublicationMode.Published].version || <>&mdash;</>}
                        </TableCell>
                        <TableCell>
                          {extensionFlow.phases[Extension.Flow.PublicationMode.Published].name || <>&mdash;</>}
                        </TableCell>
                        <TableCell>Anyone</TableCell>
                        <TableCell align="right">
                          <IconButton
                            disabled={disabled}
                            onClick={async () => {
                              const confirmed = await confirmStatic({
                                title: 'Delete extension version',
                                content: `Are you sure to delete the published version of the extension "${extensionFlow.id}"?`,
                                confirmLabel: 'Delete the published version',
                                cancelLabel: 'Cancel',
                              })
                              if (!confirmed) return
                              try {
                                setDisabled(true)
                                await services.developer.deleteExtension(
                                  signedInDeveloper.id,
                                  extensionFlow.id,
                                  Extension.Flow.PublicationMode.Published
                                )
                                await fetchData()
                              } catch (error) {
                                log.error(error)
                                enqueueSnackbar('Error', String(error), { variant: 'error' })
                              } finally {
                                if (isMounted()) {
                                  setDisabled(false)
                                }
                              }
                            }}
                          >
                            <CustomIcon name="Delete" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {Object.keys(extensionFlow.shortenedAccessKeysByName).length === 0 ? (
              <Typography variant="body1" color="textSecondary">
                No extension access keys.
              </Typography>
            ) : (
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Access Key (shortened)</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(extensionFlow.shortenedAccessKeysByName).map(accessKeyName => (
                      <TableRow key={accessKeyName}>
                        <TableCell>{accessKeyName}</TableCell>
                        <TableCell>{extensionFlow.shortenedAccessKeysByName[accessKeyName]}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            disabled={disabled}
                            onClick={async () => {
                              const confirmed = await confirmStatic({
                                title: 'Delete extension access key',
                                content: `Are you sure to delete extension access key "${accessKeyName}"?`,
                                confirmLabel: `Delete extension access key "${accessKeyName}"`,
                                cancelLabel: 'Cancel',
                              })
                              if (!confirmed) return
                              try {
                                setDisabled(true)
                                await services.developer.revokeExtensionAccessKey(
                                  signedInUser.id,
                                  extensionFlow.id,
                                  accessKeyName
                                )
                                await fetchData()
                              } catch (error) {
                                log.error(error)
                                enqueueSnackbar('Error', String(error), { variant: 'error' })
                              } finally {
                                if (isMounted()) {
                                  setDisabled(false)
                                }
                              }
                            }}
                          >
                            <CustomIcon name="Delete" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        ))}
      </Container>

      {renderFileInput()}
    </div>
  )
}

import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { useCommonStyles } from '../../../../../../../../../hooks/useCommonStyles'
import { useContexts } from '../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { usePersistent, PersistentKey } from '../../../../../../../modules/persistent'
import { MainPage } from '../../MainPage'
import { Panel } from '../../panel'

const useStyles = makeStyles(
  theme => ({
    root: {
      flex: 'none',
    },
    button: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'SideBarToggleItem' }
)

export function SideBarToggleItem({ className }: { className?: string }) {
  const { persistent } = usePersistent()

  const {
    refs: { exitFullView },
    fullView,
    panel,
  } = useContexts(MainPage.Contexts)

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <div className={classNames(classes.root, className)}>
      <Tooltip title="Toggle sidebar">
        <span>
          <IconButton
            className={classNames(classes.button, commonClasses.electronNoDrag)}
            onClick={() => {
              if (fullView) {
                exitFullView()
                persistent[PersistentKey.SideBarVisible] = true
              } else {
                persistent[PersistentKey.SideBarVisible] = !persistent[PersistentKey.SideBarVisible]
              }
            }}
            disabled={panel?.type === Panel.Type.NewPage}
          >
            <CustomIcon name="LeftSideBar" size={2.5} />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  )
}

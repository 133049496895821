import { Divider, List, makeStyles, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { useCommonStyles } from '../../../../../../../hooks/useCommonStyles'
import { useContextMenu } from '../../../../../../../hooks/useContextMenu'
import { useManageCommands } from '../../../../../../../modules/commands'
import { webConfig } from '../../../../../../../modules/web-config'
import { CustomAvatar } from '../../../../../../CustomAvatar'
import { MenuItemWithKeyboardShortcut } from '../../../../../../menu/MenuItemWithKeyboardShortcut'
import { TypographyNoWrap } from '../../../../../../TypographyNoWrap'

const useStyles = makeStyles(
  theme => ({
    duplicateButtonRoot: {},
    menuAvatarRoot: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
    },
    menuPaper: {
      width: theme.spacing(31.25),
    },
  }),
  { name: 'AccountItem' }
)

export function AccountItem({ account }: { account: Model.Account }) {
  const { handleContextMenuStatic, closeContextMenuStatic, isContextMenuOpenStatic, menuProps } = useContextMenu()

  const { runCommandStatic } = useManageCommands()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <>
      <div
        className={classNames(classes.menuAvatarRoot, commonClasses.electronNoDrag)}
        onClick={handleContextMenuStatic}
        onContextMenu={handleContextMenuStatic}
      >
        <Tooltip title={account.name}>
          <CustomAvatar size={3.5} avatarFileId={account.avatarFileId} name={account.name} color={account.color} />
        </Tooltip>
      </div>

      <Menu
        {...menuProps}
        classes={{
          ...menuProps.classes,
          paper: classNames(classes.menuPaper, menuProps.classes?.paper),
        }}
        MenuListProps={{ disablePadding: true }}
      >
        <List>
          <MenuItem onClick={closeContextMenuStatic}>
            <CustomAvatar
              size={3.5}
              avatarFileId={account.avatarFileId}
              name={account.name}
              color={account.color}
              margin={[0, 2, 0, 0]}
            />
            <Typography variant="subtitle2" color="textSecondary" noWrap>
              {account.name}
            </Typography>
          </MenuItem>
        </List>

        <Divider />

        <List>
          <MenuItemWithKeyboardShortcut
            shortcutKeys={{ alt: true, shift: true, code: 'KeyQ' }}
            onClick={() => {
              closeContextMenuStatic()
              runCommandStatic('navigation.signOut')
            }}
          >
            <TypographyNoWrap variant="subtitle2" color="error">
              Sign out
            </TypographyNoWrap>
          </MenuItemWithKeyboardShortcut>
        </List>

        <Divider />

        <List>
          <MenuItem disabled>
            <Typography variant="subtitle2" color="textSecondary">
              {webConfig.app.name} v{webConfig.version}
            </Typography>
          </MenuItem>
        </List>
      </Menu>
    </>
  )
}

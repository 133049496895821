import { makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { ComponentProps } from 'react'
import { Navigable } from '../../../../../../../../../../../modules/navigation'
import { Anchor } from '../../../../../../../../Anchor'
import { FieldPaper } from './FieldPaper'

const useStyles = makeStyles(
  theme => ({
    root: {
      flexGrow: 1,
    },
    text: {
      lineHeight: `${theme.spacing(5.5)}px`,
      margin: theme.spacing(0, 2),
    },
  }),
  { name: 'LinkField' }
)

export function LinkField({
  rootClassName,
  className,
  children,
  ...otherProps
}: Omit<ComponentProps<typeof Anchor>, 'underline'> & {
  rootClassName?: string
}) {
  const classes = useStyles()

  return (
    <FieldPaper noPadding className={rootClassName}>
      <Navigable disabled={!otherProps.href}>
        {({ connectNavigable }) => (
          <Anchor {...otherProps} ref={connectNavigable} className={classNames(classes.root, className)}>
            <Typography variant="subtitle2" className={classes.text}>
              {children}
            </Typography>
          </Anchor>
        )}
      </Navigable>
    </FieldPaper>
  )
}

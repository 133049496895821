import { NavigablePaddings, NavigationDirection } from '../types'

export function calculateDistance(
  rect: DOMRect,
  paddings: NavigablePaddings,
  siblingRect: DOMRect,
  siblingPaddings: NavigablePaddings,
  direction: NavigationDirection
): number {
  const top = Math.round(rect.top - paddings.top)
  const bottom = Math.round(rect.bottom + paddings.bottom)
  const left = Math.round(rect.left - paddings.left)
  const right = Math.round(rect.right + paddings.right)

  const siblingTop = Math.round(siblingRect.top - siblingPaddings.top)
  const siblingBottom = Math.round(siblingRect.bottom + siblingPaddings.bottom)
  const siblingLeft = Math.round(siblingRect.left - siblingPaddings.left)
  const siblingRight = Math.round(siblingRect.right + siblingPaddings.right)

  switch (direction) {
    case 'up': {
      if (siblingTop >= top) return Number.POSITIVE_INFINITY
      const center = (left + right) / 2
      const limitedSiblingBottom = Math.min(siblingBottom, top)
      if (siblingRight <= center) return +center + top - limitedSiblingBottom - siblingRight
      if (siblingLeft >= center) return -center + top - limitedSiblingBottom + siblingLeft
      return top - limitedSiblingBottom
    }

    case 'down': {
      if (siblingBottom <= bottom) return Number.POSITIVE_INFINITY
      const center = (left + right) / 2
      const limitedSiblingTop = Math.max(siblingTop, bottom)
      if (siblingRight <= center) return +center - bottom + limitedSiblingTop - siblingRight
      if (siblingLeft >= center) return -center - bottom + limitedSiblingTop + siblingLeft
      return limitedSiblingTop - bottom
    }

    case 'left': {
      if (siblingLeft >= left) return Number.POSITIVE_INFINITY
      const center = (top + bottom) / 2
      const limitedSiblingRight = Math.min(siblingRight, left)
      if (siblingBottom <= center) return +center + left - limitedSiblingRight - siblingBottom
      if (siblingTop >= center) return -center + left - limitedSiblingRight + siblingTop
      return left - limitedSiblingRight
    }

    case 'right': {
      if (siblingRight <= right) return Number.POSITIVE_INFINITY
      const center = (top + bottom) / 2
      const limitedSiblingLeft = Math.max(siblingLeft, right)
      if (siblingBottom <= center) return +center - right + limitedSiblingLeft - siblingBottom
      if (siblingTop >= center) return -center - right + limitedSiblingLeft + siblingTop
      return limitedSiblingLeft - right
    }
  }
}

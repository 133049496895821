import { Dialog, DialogProps, IconButton, makeStyles, Tooltip } from '@material-ui/core'
import { useRefWrap } from '../../../../hooks/useRefWrap'
import { CustomIcon } from '../../../../modules/custom-icon'
import { useKeyboardHandling } from '../../../../modules/keyboard-handler'

const useStyles = makeStyles(
  theme => ({
    closeButton: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
  { name: 'PresentationDialog' }
)

export function PresentationDialog({
  children,
  ...dialogProps
}: Omit<DialogProps, 'onClose' | 'maxWidth' | 'fullWidth'> & {
  onClose?(): void
}) {
  const dialogPropsRef = useRefWrap(dialogProps)

  useKeyboardHandling(
    [
      {
        combinations: 'Escape',
        handler(event) {
          dialogPropsRef.current.onClose?.()
        },
      },
    ],
    [],
    {
      removed: !dialogProps.open || !dialogProps.onClose,
    }
  )

  const classes = useStyles()

  return (
    <Dialog {...dialogProps} maxWidth="md" fullWidth>
      {dialogProps.onClose && (
        <Tooltip title="Close">
          <IconButton size="small" className={classes.closeButton} onClick={dialogProps.onClose}>
            <CustomIcon name="Close" size={2.5} />
          </IconButton>
        </Tooltip>
      )}

      {children}
    </Dialog>
  )
}

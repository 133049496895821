import { track } from '@amplitude/analytics-browser'
import { webConfig } from '../../web-config'

export function sendAmplitudeEvent(event: string, data?: any): void {
  if (webConfig.analytics.disabled || webConfig.analytics.amplitude.disabled) return

  try {
    if (data && typeof data === 'string') {
      data = { data }
    }

    track(event, data)
  } catch (error) {
    log.error(error)
  }
}

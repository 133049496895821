import { captureException } from '@sentry/react'
import { getPublicResourceUrl } from '../../helpers/getPublicResourceUrl'
import { webConfig } from '../web-config'

// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

interface Callbacks {
  onSuccess?(registration: ServiceWorkerRegistration): void
  onUpdate?(registration: ServiceWorkerRegistration): void
}

export async function registerServiceWorker(callbacks?: Callbacks): Promise<void> {
  if (!('serviceWorker' in window.navigator)) return

  // The URL constructor is available in all browsers that support SW.
  const publicUrl = new URL(webConfig.publicUrl, window.location.href)
  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    return
  }

  const serviceWorkerUrl = getPublicResourceUrl('service-worker.js')

  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  )

  if (isLocalhost) {
    // This is running on localhost. Let's check if a service worker still exists or not.
    await checkValidServiceWorker(serviceWorkerUrl, callbacks)
  } else {
    // Is not localhost. Just register service worker
    await registerValidServiceWorker(serviceWorkerUrl, callbacks)
  }
}

async function registerValidServiceWorker(serviceWorkerUrl: string, callbacks?: Callbacks): Promise<void> {
  try {
    const registration = await navigator.serviceWorker.register(serviceWorkerUrl)

    registration.onupdatefound = (): void => {
      const installingWorker = registration.installing
      if (!installingWorker) return

      installingWorker.onstatechange = (): void => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            // At this point, the updated precached content has been fetched,
            // but the previous service worker will still serve the older
            // content until all client tabs are closed.
            log.log(
              'service worker',
              'New content is available and will be used when all tabs for this page are closed. See https://bit.ly/CRA-PWA.'
            )

            // Execute callback
            callbacks?.onUpdate?.(registration)
          } else {
            // At this point, everything has been precached.
            // It's the perfect time to display a
            // "Content is cached for offline use." message.
            log.log('service worker', 'Content is cached for offline use.')

            // Execute callback
            callbacks?.onSuccess?.(registration)
          }
        }
      }
    }
  } catch (error) {
    log.error('service worker', 'Error during service worker registration:', error)
    captureException(error, { tags: { module: 'service worker' } })
  }
}

async function checkValidServiceWorker(serviceWorkerUrl: string, callbacks?: Callbacks): Promise<void> {
  try {
    // Check if the service worker can be found. If it can't reload the page.
    const response = await fetch(serviceWorkerUrl, {
      headers: { 'Service-Worker': 'script' },
    })

    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = response.headers.get('content-type')

    if (response.status === 404 || (contentType && !contentType.includes('javascript'))) {
      // No service worker found. Probably a different app. Reload the page.
      const registration = await navigator.serviceWorker.ready
      await registration.unregister()
      window.location.reload()
    } else {
      // Service worker found. Proceed as normal.
      await registerValidServiceWorker(serviceWorkerUrl, callbacks)
    }
  } catch {
    log.log('service worker', 'No internet connection found. App is running in offline mode.')
  }
}

import { useEffect } from 'react'
import { useContexts } from '../../../../../modules/contexts'
import { OnboardingProviderContexts } from '../OnboardingProvider'
import { OnboardingStep, OnboardingTour } from '../types'
import { useOnboardingStateMatch } from './useOnboardingStateMatch'

export function useOnboardingWaitFor<T extends OnboardingTour>(tour: T, step: OnboardingStep<T>, condition: any): void {
  const {
    refs: { getPreparationCount, setPreparationCount },
  } = useContexts(OnboardingProviderContexts)

  const { onboardingStateMatch } = useOnboardingStateMatch(tour, step)

  const booleanCondition = Boolean(condition)

  useEffect(() => {
    if (onboardingStateMatch && !booleanCondition) {
      setPreparationCount(getPreparationCount() + 1)

      return () => setPreparationCount(getPreparationCount() - 1)
    }
  }, [onboardingStateMatch, booleanCondition])
}

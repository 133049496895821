import { IpcChannel, IpcRendererEvent, IpcMessage, WindowType } from '@zettelooo/desktop-shared'
import { DependencyList, useEffect } from 'react'
import { webConfig } from '../../../../modules/web-config'

export function useIpcMessageHandling<C extends IpcChannel>(
  channel: C,
  handler: (event: IpcRendererEvent, message: IpcMessage<C>) => void,
  dependencies: DependencyList,
  options?: {
    readonly windowType?: WindowType
    readonly disabled?: boolean
  }
): void {
  useEffect(() => {
    if (
      webConfig.environment.agent === 'electron' &&
      !options?.disabled &&
      (!options?.windowType || options.windowType === window.electronProxy?.windowType)
    ) {
      window.electronProxy?.ipc.on(channel, handler)

      return () => {
        window.electronProxy?.ipc.removeListener(channel, handler)
      }
    }
  }, [...dependencies, options?.disabled])
}

import { Id } from '@zettelooo/commons'
import { UserDailyActivity, AdminServiceSignature } from '@zettelooo/server-shared'
import { DemoMode } from '../../../../../modules/demo-mode'
import { PeekMode } from '../../../../../modules/peek-mode'
import { Persistent, PersistentKey } from '../../persistent'
import { RestService } from './RestService'

export class AdminService extends RestService {
  constructor(persistent: Persistent) {
    super('admin', persistent)
  }

  async putUserDailyActivityNoExceptions(action: UserDailyActivity.Action): Promise<void> {
    try {
      if (DemoMode.data || PeekMode.data) return

      await this.requestAuthenticated<
        AdminServiceSignature.PutUserDailyActivity.Request,
        AdminServiceSignature.PutUserDailyActivity.Response
      >('put-user-daily-activity', { action })
    } catch (error) {
      log.error('AdminService', 'putUserDailyActivity', error)
    }
  }

  async getUserDailyActivities(): Promise<{ userDailyActivities: readonly UserDailyActivity[] }> {
    if (DemoMode.data || PeekMode.data) return { userDailyActivities: [] }

    const { userDailyActivities } = await this.requestAuthenticated<
      AdminServiceSignature.GetUserDailyActivities.Request,
      AdminServiceSignature.GetUserDailyActivities.Response
    >('get-user-daily-activities', {})

    return {
      userDailyActivities,
    }
  }

  async postDemoModeByUsername(username: string): Promise<void> {
    if (!DemoMode.data) return

    await this.requestAuthenticated<
      AdminServiceSignature.PostDemoModeByUsername.Request,
      AdminServiceSignature.PostDemoModeByUsername.Response
    >('post-demo-mode-by-username', { username })
  }

  async deleteDemoModeByUsername(): Promise<void> {
    if (DemoMode.data || PeekMode.data) return

    await this.requestAuthenticated<
      AdminServiceSignature.DeleteDemoModeByUsername.Request,
      AdminServiceSignature.DeleteDemoModeByUsername.Response
    >('delete-demo-mode-by-username', {})
  }

  async getPageData(): Promise<AdminServiceSignature.PageData> {
    if (DemoMode.data || PeekMode.data) return { userDailyActivities: [], users: [], demoModeByUsername: {} } // Not going to be called

    const { pageData } = await this.requestUnauthenticated<
      AdminServiceSignature.GetPageData.Request,
      AdminServiceSignature.GetPageData.Response
    >('get-page-data', {})

    return pageData
  }

  async getPeekUserData(userId: Id): Promise<{
    readonly accessToken: string
    readonly refreshToken: string
  }> {
    if (DemoMode.data || PeekMode.data) return { accessToken: '', refreshToken: '' } // Not going to be called

    const { accessToken, refreshToken } = await this.requestUnauthenticated<
      AdminServiceSignature.GetPeekUserData.Request,
      AdminServiceSignature.GetPeekUserData.Response
    >('get-peek-user-data', {
      deviceId: this.persistent(PersistentKey.Authentication).get()?.decodedAccessToken.deviceId ?? '',
      userId,
    })

    return {
      accessToken,
      refreshToken,
    }
  }
}

import { Button, Divider, List, makeStyles, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core'
import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { SnackbarKey } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useUnmount } from 'react-use'
import { useCommonStyles } from '../../../../../../../hooks/useCommonStyles'
import { useContextMenu } from '../../../../../../../hooks/useContextMenu'
import { useManageCommands } from '../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../modules/contexts'
import { webConfig } from '../../../../../../../modules/web-config'
import { CustomAvatar } from '../../../../../../CustomAvatar'
import { MenuItemWithKeyboardShortcut } from '../../../../../../menu/MenuItemWithKeyboardShortcut'
import { TypographyNoWrap } from '../../../../../../TypographyNoWrap'
import { useAppNotistack } from '../../../../../modules/app-notistack'
import { useServices } from '../../../../../modules/services'
import { Initializer } from '../../../../Initializer'
import { Panel, useOpenMainPage } from '../MainPage'

const useStyles = makeStyles(
  theme => ({
    duplicateButtonRoot: {},
    menuAvatarRoot: {
      flex: 'none',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
    },
    menuPaper: {
      width: theme.spacing(31.25),
    },
  }),
  { name: 'AccountItem' }
)

export function AccountItem({ pageId, account }: { pageId: Id; account: Model.Account }) {
  const { allPages } = useContexts(Initializer.Contexts)

  const { handleContextMenuStatic, closeContextMenuStatic, isContextMenuOpenStatic, menuProps } = useContextMenu()

  const [disabled, setDisabled] = useState(false)
  const [duplicatedPageId, setDuplicatedPageId] = useState<Id>()

  const { enqueueSnackbar, closeSnackbar } = useAppNotistack()

  const snackbarKeyRef = useRef<SnackbarKey | undefined>(undefined)

  useUnmount(() => {
    if (snackbarKeyRef.current) {
      closeSnackbar(snackbarKeyRef.current)
    }
  })

  const { services } = useServices()

  const { openMainPageStatic } = useOpenMainPage()

  const { runCommandStatic } = useManageCommands()

  async function duplicate(): Promise<void> {
    closeContextMenuStatic()
    try {
      setDisabled(true)
      const { createdPageId } = await services.core.duplicatePage(pageId)
      snackbarKeyRef.current = enqueueSnackbar(
        'Successfully duplicted!',
        `This whole page is copied into your ${webConfig.app.name}.\nYou will be navigated to the page as soon as it is ready.`,
        { variant: 'success' }
      )
      setDuplicatedPageId(createdPageId)
    } catch (error) {
      console.error(error)
      snackbarKeyRef.current = enqueueSnackbar(
        'Failed to duplicate',
        'Unable to copy the page, check out the logs in the console for more details.',
        { variant: 'error' }
      )
    } finally {
      setDisabled(false)
    }
  }

  useEffect(() => {
    if (
      duplicatedPageId &&
      !allPages.stillLoading &&
      duplicatedPageId in allPages.dictionary &&
      allPages.dictionary[duplicatedPageId]?.memberUserIds.includes(account.id)
    ) {
      openMainPageStatic({ type: Panel.Type.Page, pageId: duplicatedPageId })
    }
  }, [account, duplicatedPageId, allPages])

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        className={classNames(classes.duplicateButtonRoot, commonClasses.electronDrag)}
        disabled={disabled || Boolean(duplicatedPageId)}
        onClick={duplicate}
      >
        Duplicate
      </Button>

      <div
        className={classNames(classes.menuAvatarRoot, commonClasses.electronNoDrag)}
        onClick={handleContextMenuStatic}
        onContextMenu={handleContextMenuStatic}
      >
        <Tooltip title={account.name}>
          <CustomAvatar size={3.5} avatarFileId={account.avatarFileId} name={account.name} color={account.color} />
        </Tooltip>
      </div>

      <Menu
        {...menuProps}
        classes={{
          ...menuProps.classes,
          paper: classNames(classes.menuPaper, menuProps.classes?.paper),
        }}
        MenuListProps={{ disablePadding: true }}
      >
        <List>
          <MenuItem disabled={disabled} onClick={closeContextMenuStatic}>
            <CustomAvatar
              size={3.5}
              avatarFileId={account.avatarFileId}
              name={account.name}
              color={account.color}
              margin={[0, 2, 0, 0]}
            />
            <Typography variant="subtitle2" color="textSecondary" noWrap>
              {account.name}
            </Typography>
          </MenuItem>
        </List>

        <Divider />

        <List>
          <MenuItem disabled={disabled || Boolean(duplicatedPageId)} onClick={duplicate}>
            <TypographyNoWrap variant="subtitle2">Duplicate</TypographyNoWrap>
          </MenuItem>

          <MenuItemWithKeyboardShortcut
            shortcutKeys={{ alt: true, shift: true, code: 'KeyQ' }}
            disabled={disabled}
            onClick={() => {
              closeContextMenuStatic()
              runCommandStatic('navigation.signOut')
            }}
          >
            <TypographyNoWrap variant="subtitle2" color="error">
              Sign out
            </TypographyNoWrap>
          </MenuItemWithKeyboardShortcut>
        </List>

        <Divider />

        <List>
          <MenuItem disabled>
            <Typography variant="subtitle2" color="textSecondary">
              {webConfig.app.name} v{webConfig.version}
            </Typography>
          </MenuItem>
        </List>
      </Menu>
    </>
  )
}

import { Badge, BadgeProps, makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles(
  theme => ({
    badge: {
      position: 'static',
      transform: 'none',
    },
  }),
  { name: 'StaticBadge' }
)

export function StaticBadge(props: Omit<BadgeProps, 'children'>) {
  const classes = useStyles()

  return (
    <Badge
      {...props}
      classes={{
        ...props.classes,
        badge: classNames(props.classes?.badge, classes.badge),
      }}
    />
  )
}

import { createContext } from 'react'
import { StateAccessor } from '../../../../../../hooks/useStateAccessor'
import { NewPage } from './types'

export const Context = createContext<{
  newPage: NewPage
  newPageAccessor: StateAccessor<NewPage>
  newPageSubmitting: boolean
  submitNewPageStatic: () => Promise<void>
  cancelNewPageStatic: () => void
}>(undefined as any)

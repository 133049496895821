import { IconButton, Input, makeStyles } from '@material-ui/core'
import { BrowserSuggestion } from '@zettelooo/desktop-shared'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { is } from '../../../../../../../../helpers/is'
import { useCommonStyles } from '../../../../../../../../hooks/useCommonStyles'
import { useDebouncedValue } from '../../../../../../../../hooks/useDebouncedValue'
import { useRefWrap } from '../../../../../../../../hooks/useRefWrap'
import { sendAnalyticEvent } from '../../../../../../../../modules/analytics'
import { useContexts } from '../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../modules/custom-icon'
import {
  commonKeyboardCombinations,
  KeyboardCombinationView,
  useKeyboardHandling,
} from '../../../../../../../../modules/keyboard-handler'
import { webConfig } from '../../../../../../../../modules/web-config'
import { Gap } from '../../../../../../../Gap'
import { Initializer } from '../../../../../Initializer'
import { useSearchCards, useSearchPages } from '../../../../modules/search'
import { FilteredPage } from './FilteredPage'
import { PagesFilter } from './PagesFilter'
import { Result } from './Result'
import { SearchFilters } from './SearchFilters'
import { CardSearchResult, SearchResultItem } from './types'
import { useBrowserSuggestions } from './useBrowserSuggestions'

const useStyles = makeStyles(
  theme => ({
    root: {
      // padding: theme.spacing(2),
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    input: {
      // padding: theme.spacing(1),
    },
    inputElement: {
      height: theme.spacing(3.5),
      padding: theme.spacing(1),
    },
    footer: {
      display: 'flex',
      padding: theme.spacing(2, 1),
      color: theme.palette.text.secondary,
      borderRadius: theme.spacing(0, 0, 1, 1),
      margin: theme.spacing(-1),
    },
  }),
  { name: 'LauncherSearch' }
)

export function LauncherSearch({
  getQuery,
  setQuery,
  onPasteCard,
  onOpenCard,
}: {
  getQuery(): string
  setQuery(query: string): void
  onPasteCard(cardSearchResult: CardSearchResult): void
  onOpenCard(card: Model.Card): void
}) {
  const { allCards, allPages } = useContexts(Initializer.Contexts)

  const [selectedSearchResultItemIndex, setSelectedSearchResultItemIndex] = useState(0)
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<useSearchCards.SearchFilter>(
    useSearchCards.SearchFilter.All
  )
  const [selectedPageFilter, setSelectedPageFilter] = useState<Model.Page>()

  const rawDebouncedQuery = useDebouncedValue(getQuery(), { timeoutMilliseconds: 100 })
  const debouncedQuery = getQuery() ? rawDebouncedQuery : getQuery()

  useLayoutEffect(() => {
    setSelectedSearchResultItemIndex(0)
  }, [debouncedQuery])

  const { filteredPages } = useSearchPages(debouncedQuery)
  const { filteredCardSearchContents } = useSearchCards(
    debouncedQuery,
    selectedSearchFilter as useSearchCards.SearchFilter,
    {
      pageFilterId: selectedPageFilter?.id,
    }
  )
  const { browserSuggestionsResult } = useBrowserSuggestions(debouncedQuery, {
    disabled: selectedSearchFilter !== useSearchCards.SearchFilter.All,
  })

  const cardSearchResults = useMemo<readonly CardSearchResult[]>(
    () =>
      filteredCardSearchContents
        .slice(0, 60) // Temporary solution to increase performance even more, we need to improve compromise efficiency and remove this clipping
        .flatMap(cardSearchContent => {
          const text =
            selectedSearchFilter === useSearchCards.SearchFilter.All
              ? cardSearchContent.text
              : selectedSearchFilter === useSearchCards.SearchFilter.Files
              ? cardSearchContent.files
              : ''

          const results: CardSearchResult[] = [
            {
              id: cardSearchContent.card.id,
              card: cardSearchContent.card,
              text,
            },
          ]

          return results

          // TODO: Temporarily disable compromise:
          // const compromiseDocument = compromise(text)
          // const phoneNumbers = compromiseDocument.phoneNumbers().out('array')
          // const hashTags = compromiseDocument.hashTags().out('array')
          // const emails = compromiseDocument.emails().out('array')
          // const atMentions = compromiseDocument.atMentions().out('array')
          // // const urls = compromiseDocument.urls().out('array')
          // const people = compromiseDocument.people().out('array')
          // const places = compromiseDocument.places().out('array')
          // const organizations = compromiseDocument.organizations().out('array')
          //
          // arrayHelpers.distinct(phoneNumbers).forEach(phoneNumber =>
          //   results.push({
          //     id: `${cardSearchContent.card.id}-${CompromiseResultType.PhoneNumber}-${phoneNumber}`,
          //     card: cardSearchContent.card,
          //     text,
          //     compromiseResult: {
          //       type: CompromiseResultType.PhoneNumber,
          //       value: phoneNumber,
          //     },
          //   })
          // )
          // arrayHelpers.distinct(hashTags).forEach(hashTag =>
          //   results.push({
          //     id: `${cardSearchContent.card.id}-${CompromiseResultType.HashTag}-${hashTag}`,
          //     card: cardSearchContent.card,
          //     text,
          //     compromiseResult: {
          //       type: CompromiseResultType.HashTag,
          //       value: hashTag,
          //     },
          //   })
          // )
          // arrayHelpers.distinct(emails).forEach(email =>
          //   results.push({
          //     id: `${cardSearchContent.card.id}-${CompromiseResultType.Email}-${email}`,
          //     card: cardSearchContent.card,
          //     text,
          //     compromiseResult: {
          //       type: CompromiseResultType.Email,
          //       value: email,
          //     },
          //   })
          // )
          // arrayHelpers.distinct(atMentions).forEach(atMention =>
          //   results.push({
          //     id: `${cardSearchContent.card.id}-${CompromiseResultType.AtMention}-${atMention}`,
          //     card: cardSearchContent.card,
          //     text,
          //     compromiseResult: {
          //       type: CompromiseResultType.AtMention,
          //       value: atMention,
          //     },
          //   })
          // )
          // // arrayHelpers.distinct(urls).forEach(url =>
          // //   results.push({
          // //     id: `${cardSearchContent.card.id}-${CompromiseResultType.Url}-${url}`,
          // //     card: cardSearchContent.card,
          // //     text,
          // //     compromiseResult: {
          // //       type: CompromiseResultType.Url,
          // //       value: url,
          // //     },
          // //   })
          // // )
          // arrayHelpers.distinct(people).forEach(person =>
          //   results.push({
          //     id: `${cardSearchContent.card.id}-${CompromiseResultType.Person}-${person}`,
          //     card: cardSearchContent.card,
          //     text,
          //     compromiseResult: {
          //       type: CompromiseResultType.Person,
          //       value: person,
          //     },
          //   })
          // )
          // arrayHelpers.distinct(places).forEach(place =>
          //   results.push({
          //     id: `${cardSearchContent.card.id}-${CompromiseResultType.Place}-${place}`,
          //     card: cardSearchContent.card,
          //     text,
          //     compromiseResult: {
          //       type: CompromiseResultType.Place,
          //       value: place,
          //     },
          //   })
          // )
          // arrayHelpers.distinct(organizations).forEach(organization =>
          //   results.push({
          //     id: `${cardSearchContent.card.id}-${CompromiseResultType.Organization}-${organization}`,
          //     card: cardSearchContent.card,
          //     text,
          //     compromiseResult: {
          //       type: CompromiseResultType.Organization,
          //       value: organization,
          //     },
          //   })
          // )
          //
          // return results
        }),
    [filteredCardSearchContents, selectedSearchFilter]
  )

  const contentRef = useRefWrap({
    allPages,
    onPasteCard,
    onOpenCard,
    selectedSearchResultItemIndex,
    get searchResultItems() {
      return searchResultItems
    },
    get selectedSearchResultItem() {
      return searchResultItems[selectedSearchResultItemIndex] as SearchResultItem | undefined
    },
  })

  const searchResultItems = useMemo<readonly SearchResultItem[]>(() => {
    const cardSearchResultItems = cardSearchResults.map<SearchResultItem>(cardSearchResult => ({
      type: SearchResultItem.Type.CardSearchResult,
      cardSearchResult,
      id: `card search result - ${cardSearchResult.id}`,
      handleSubmit(source) {
        sendAnalyticEvent('Launcher Search', source === 'keyboard' ? 'Paste card by enter' : 'Paste card by click')
        const page = contentRef.current.allPages.dictionary[cardSearchResult.card.pageId]
        if (!page) return 'not handled'
        setQuery('')
        contentRef.current.onPasteCard(cardSearchResult)
      },
      handleCtrlSubmit(source) {
        sendAnalyticEvent('Launcher Search', source === 'keyboard' ? 'Open card by enter' : 'Open card by click')
        const page = contentRef.current.allPages.dictionary[cardSearchResult.card.pageId]
        if (!page) return 'not handled'
        setQuery('')
        contentRef.current.onOpenCard(cardSearchResult.card)
      },
    }))

    // const browserSuggestionItems: SearchResultItem[] =
    //   selectedSearchFilter !== useSearchCards.SearchFilter.All || !browserSuggestionsResult
    //     ? []
    //     : [
    //         anchorme.validate.url(browserSuggestionsResult.query)
    //           ? {
    //               type: SearchResultItem.Type.BrowserBrowseUrl,
    //               url: browserSuggestionsResult.query,
    //               id: 'browser suggestion - direct browse',
    //               handleSubmit(source) {
    //                 sendIpcMessage(IpcChannel.Launcher, {
    //                   type: LauncherIpcMessageType.LauncherOpenUrl,
    //                   url: debouncedQuery,
    //                 })
    //                 setQuery('')
    //               },
    //             }
    //           : {
    //               type: SearchResultItem.Type.BrowserSearchQuery,
    //               searchEngine: browserSuggestionsResult.searchEngine,
    //               query: browserSuggestionsResult.query,
    //               id: 'browser suggestion - direct search',
    //               handleSubmit(source) {
    //                 sendIpcMessage(IpcChannel.Launcher, {
    //                   type: LauncherIpcMessageType.LauncherSearchInBrowser,
    //                   query: browserSuggestionsResult.query,
    //                 })
    //                 setQuery('')
    //               },
    //             },

    //         ...browserSuggestionsResult.suggestions.map<SearchResultItem>(browserSuggestion => {
    //           switch (browserSuggestion.type) {
    //             case BrowserSuggestion.Type.SearchHistory:
    //               return {
    //                 type: SearchResultItem.Type.BrowserSuggestion,
    //                 browserSuggestion,
    //                 id: `browser suggestion - ${JSON.stringify(browserSuggestion)}`,
    //                 handleSubmit(source) {
    //                   sendIpcMessage(IpcChannel.Launcher, {
    //                     type: LauncherIpcMessageType.LauncherSearchInBrowser,
    //                     query: browserSuggestion.query,
    //                   })
    //                   setQuery('')
    //                 },
    //               }

    //             case BrowserSuggestion.Type.BrowseHistory:
    //               return {
    //                 type: SearchResultItem.Type.BrowserSuggestion,
    //                 browserSuggestion,
    //                 id: `browser suggestion - ${JSON.stringify(browserSuggestion)}`,
    //                 handleSubmit(source) {
    //                   sendIpcMessage(IpcChannel.Launcher, {
    //                     type: LauncherIpcMessageType.LauncherOpenUrl,
    //                     url: browserSuggestion.url,
    //                   })
    //                   setQuery('')
    //                 },
    //               }

    //             case BrowserSuggestion.Type.Bookmark:
    //               return {
    //                 type: SearchResultItem.Type.BrowserSuggestion,
    //                 browserSuggestion,
    //                 id: `browser suggestion - ${JSON.stringify(browserSuggestion)}`,
    //                 handleSubmit(source) {
    //                   sendIpcMessage(IpcChannel.Launcher, {
    //                     type: LauncherIpcMessageType.LauncherOpenUrl,
    //                     url: browserSuggestion.url,
    //                   })
    //                   setQuery('')
    //                 },
    //               }

    //             case BrowserSuggestion.Type.SearchEngineSuggestedQuery:
    //               return {
    //                 type: SearchResultItem.Type.BrowserSuggestion,
    //                 browserSuggestion,
    //                 id: `browser suggestion - ${JSON.stringify(browserSuggestion)}`,
    //                 handleSubmit(source) {
    //                   sendIpcMessage(IpcChannel.Launcher, {
    //                     type: LauncherIpcMessageType.LauncherSearchInBrowser,
    //                     query: browserSuggestion.suggestedQuery,
    //                   })
    //                   setQuery('')
    //                 },
    //               }

    //             case BrowserSuggestion.Type.SearchEngineSuggestedUrl:
    //               return {
    //                 type: SearchResultItem.Type.BrowserSuggestion,
    //                 browserSuggestion,
    //                 id: `browser suggestion - ${JSON.stringify(browserSuggestion)}`,
    //                 handleSubmit(source) {
    //                   sendIpcMessage(IpcChannel.Launcher, {
    //                     type: LauncherIpcMessageType.LauncherOpenUrl,
    //                     url: browserSuggestion.url,
    //                   })
    //                   setQuery('')
    //                 },
    //               }
    //           }
    //         }),
    //       ]

    return [
      // ...browserSuggestionItems.slice(0, 5),
      ...cardSearchResultItems,
      // ...browserSuggestionItems.slice(5),
    ]
  }, [debouncedQuery, selectedSearchFilter, cardSearchResults, browserSuggestionsResult])

  const inputRef = useRef<HTMLInputElement>(null)

  useKeyboardHandling(
    [
      {
        combinations: 'ArrowDown',
        noConsumption: true,
        handler() {
          sendAnalyticEvent('Launcher Search', 'Arrow Down')
          if (
            contentRef.current.searchResultItems.length > 0 &&
            contentRef.current.selectedSearchResultItemIndex < contentRef.current.searchResultItems.length - 1
          ) {
            setSelectedSearchResultItemIndex(contentRef.current.selectedSearchResultItemIndex + 1)
          }
        },
      },
      {
        combinations: 'ArrowUp',
        noConsumption: true,
        handler() {
          sendAnalyticEvent('Launcher Search', 'Arrow Up')
          if (contentRef.current.searchResultItems.length > 0 && contentRef.current.selectedSearchResultItemIndex > 0) {
            setSelectedSearchResultItemIndex(contentRef.current.selectedSearchResultItemIndex - 1)
          }
        },
      },
      {
        combinations: commonKeyboardCombinations.escape,
        handler() {
          if (!getQuery()) return 'not handled'
          setQuery('')
        },
      },
      {
        combinations: commonKeyboardCombinations.enter,
        handler() {
          return contentRef.current.selectedSearchResultItem?.handleSubmit('keyboard')
        },
      },
      {
        combinations: [
          { cmd: true, code: 'Enter' },
          { cmd: true, code: 'NumpadEnter' },
        ],
        handler() {
          return contentRef.current.selectedSearchResultItem?.handleCtrlSubmit?.('keyboard')
        },
      },
    ],
    [debouncedQuery, cardSearchResults, selectedSearchResultItemIndex, allCards, allPages],
    {
      disabled: !debouncedQuery,
    }
  )

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.inputContainer}>
        {selectedPageFilter ? (
          <FilteredPage
            page={selectedPageFilter}
            onRemoveFilter={() => {
              setSelectedPageFilter(undefined)
              inputRef.current?.focus()
            }}
          />
        ) : (
          <CustomIcon name="Search" size="small" />
        )}

        <Input
          inputProps={{ ref: inputRef }}
          autoFocus
          disableUnderline
          fullWidth
          placeholder="Search the web and your notes"
          className={classNames(classes.input, commonClasses.electronNoDrag)}
          classes={{ input: classes.inputElement }}
          value={getQuery()}
          onChange={event => setQuery(event.target.value)}
        />

        {(getQuery() || selectedPageFilter) && (
          <IconButton
            className={commonClasses.electronNoDrag}
            onClick={() => {
              setQuery('')
              setSelectedPageFilter(undefined)
            }}
          >
            <CustomIcon name="Close" size="small" color="text.secondary" />
          </IconButton>
        )}
      </div>

      {debouncedQuery && (
        <>
          <SearchFilters
            selectedFilter={selectedSearchFilter}
            onFilter={filter => {
              setSelectedSearchFilter(filter)
              inputRef.current?.focus()
            }}
          />
          {!selectedPageFilter && filteredPages && filteredPages.length > 0 && (
            <PagesFilter
              pages={filteredPages}
              onFilter={page => {
                setSelectedPageFilter(page)
                inputRef.current?.focus()
              }}
            />
          )}

          <Result
            debouncedQuery={debouncedQuery}
            selectedSearchResultItemIndex={selectedSearchResultItemIndex}
            setselectedSearchResultItemIndex={setSelectedSearchResultItemIndex}
            searchResultItems={searchResultItems}
            onOpenPage={card => {
              sendAnalyticEvent('Launcher Search', 'Click on page')
              setQuery('')
              onOpenCard(card)
            }}
          />

          <div className={classes.footer}>
            <KeyboardCombinationView combination="Escape" postfix="To dismiss" />
            <Gap grow />

            {contentRef.current.selectedSearchResultItem?.type === SearchResultItem.Type.CardSearchResult && (
              <>
                <KeyboardCombinationView
                  combination={{ cmd: true, code: 'Enter' }}
                  postfix={`To open in ${webConfig.app.name}`}
                />
                <Gap grow />
                <KeyboardCombinationView combination="Enter" postfix="To paste anywhere" />
              </>
            )}

            {(contentRef.current.selectedSearchResultItem?.type === SearchResultItem.Type.BrowserSearchQuery ||
              (contentRef.current.selectedSearchResultItem?.type === SearchResultItem.Type.BrowserSuggestion &&
                is(contentRef.current.selectedSearchResultItem.browserSuggestion.type).oneOf(
                  BrowserSuggestion.Type.SearchHistory,
                  BrowserSuggestion.Type.SearchEngineSuggestedQuery
                ))) && <KeyboardCombinationView combination="Enter" postfix="To search in the browser" />}

            {(contentRef.current.selectedSearchResultItem?.type === SearchResultItem.Type.BrowserBrowseUrl ||
              (contentRef.current.selectedSearchResultItem?.type === SearchResultItem.Type.BrowserSuggestion &&
                is(contentRef.current.selectedSearchResultItem.browserSuggestion.type).oneOf(
                  BrowserSuggestion.Type.BrowseHistory,
                  BrowserSuggestion.Type.Bookmark,
                  BrowserSuggestion.Type.SearchEngineSuggestedUrl
                ))) && <KeyboardCombinationView combination="Enter" postfix="To browse" />}
          </div>
        </>
      )}
    </div>
  )
}

import ReactGA from 'react-ga'
import { webConfig } from '../../web-config'

export function setGoogleAnalyticsUserIdentifier(userIdentifier: string) {
  if (webConfig.analytics.disabled || webConfig.analytics.googleAnalytics.disabled) return

  try {
    ReactGA.set({ userId: userIdentifier })
  } catch (error) {
    log.error(error)
  }
}

import { Model } from '@zettelooo/server-shared'
import { createContexts } from '../../../../../../modules/contexts'

export const Contexts = createContexts(
  () =>
    (parameters?: {
      account: Model.Account
      accountPagesAreLoading: boolean
      accountPagesOrdered: readonly Model.Page[]
      accountCardsAreLoading: boolean
      accountCardsOrdered: readonly Model.Card[]
    }) => ({
      account: parameters?.account!,
      accountPagesAreLoading: parameters?.accountPagesAreLoading!,
      accountPagesOrdered: parameters?.accountPagesOrdered!,
      accountCardsAreLoading: parameters?.accountCardsAreLoading!,
      accountCardsOrdered: parameters?.accountCardsOrdered!,
    }),
  'AccountData'
)

import { ZettelExtensions } from '@zettelooo/extension-api'
import { useExtensionLifeSpan } from '../../../../../modules/extension'
import { usePersistent, PersistentKey } from '../../../../../modules/persistent'

export function useExtensionSignedOutLifeSpan(): void {
  const [, deviceId] = usePersistent(PersistentKey.DeviceId)

  useExtensionLifeSpan({
    name: 'signedOut',
    target: {},
    scopedValues: {
      [ZettelExtensions.Scope.Device]: deviceId,
    },
    dataFactories: {},
    accessFactory: () => ({}),
    registryFactory: () => ({}),
    dependencies: [deviceId],
  })
}

import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useContext } from 'react'
import { useCommonStyles } from '../../../../../hooks/useCommonStyles'
import { DeviceProvider } from '../../../../../modules/device'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      boxShadow: theme.customShadows.lightShadow,
      borderRadius: theme.spacing(1.25),
    },
    mobileCenter: {
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  }),
  { name: 'Image' }
)

export function Image({ url, alt }: { url: string; alt?: string }) {
  const { isMobile } = useContext(DeviceProvider.Context)

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img
        src={url}
        alt={alt ?? ''}
        className={classNames(classes.image, !isMobile ? commonClasses.center : classes.mobileCenter)}
      />
    </div>
  )
}

import { useTheme } from '@material-ui/core'
import { SnackbarProvider, SnackbarProviderProps } from 'notistack'

export function AppNotistackProvider(props: SnackbarProviderProps) {
  const theme = useTheme()

  return (
    <SnackbarProvider
      maxSnack={7}
      anchorOrigin={{
        horizontal: theme.direction === 'ltr' ? 'right' : 'left',
        vertical: 'bottom',
      }}
      autoHideDuration={5 * 1000}
      {...props}
    />
  )
}

import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { Navigable } from '../../../../../../../../../../../modules/navigation'

const useStyles = makeStyles(
  theme => ({
    root: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'ButtonField' }
)

export function ButtonField(props: ButtonProps) {
  const classes = useStyles()

  return (
    <Navigable>
      {({ connectNavigable }) => (
        <Button
          ref={connectNavigable}
          fullWidth
          variant="outlined"
          {...props}
          className={classNames(classes.root, props.className)}
        />
      )}
    </Navigable>
  )
}

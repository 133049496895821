import { routes, RouteSpecification } from '../../../../modules/routes'
import { Panel } from './Panel'

export function getPanelPath(panel: Panel | undefined): string {
  const routeParams: RouteSpecification.Params<typeof routes.main> = {}

  switch (panel?.type) {
    case undefined:
      return routes.main.getPath(routeParams, {})

    case Panel.Type.Settings:
      return getPath([panel.type, panel.subType], {})

    case Panel.Type.Page:
      return getPath([panel.type, panel.pageId], {
        c: panel.cardId,
        ...(panel.isNewPage ? { n: 'true' } : {}),
      })

    case Panel.Type.NewPage:
      return getPath([panel.type], {})

    case Panel.Type.Activity:
      return getPath([panel.type], {})
  }

  function getPath<T extends Panel.Type>(panelParams: [T, ...Panel.Params<T>], panelQuery: Panel.Query<T>): string {
    return routes.main.getPath(
      routeParams,
      {},
      {
        extendPath: panelParams.join('/'),
        extendQuery: panelQuery,
      }
    )
  }
}

import { IconButton, makeStyles, Paper } from '@material-ui/core'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { CustomIcon } from '../../../../../../../../../../modules/custom-icon'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(4),
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      padding: theme.spacing(1),
    },
  }),
  { name: 'BannerLayout' }
)

export function BannerLayout({
  onClose,
  className,
  children,
}: PropsWithChildren<{
  onClose?: () => void
  className?: string
}>) {
  const classes = useStyles()

  return (
    <Paper elevation={0} className={classNames(classes.root, className)}>
      {onClose && (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CustomIcon name="Close" size={2.5} color="text.secondary" />
        </IconButton>
      )}

      {children}
    </Paper>
  )
}

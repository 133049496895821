import { Redirect } from 'react-router-dom'
import { routes } from '../modules/routes'
import { AuthenticationCheck } from './AuthenticationCheck'

export function AuthenticationRequired({
  toBeInitialized,
  render,
}: {
  toBeInitialized?: boolean
  render(status: { subscribed: boolean }): React.JSX.Element
}) {
  return (
    <AuthenticationCheck>
      {({ authenticated, initialized, subscribed }) =>
        !authenticated ? (
          <Redirect to={routes.signOut.getPath({}, {})} />
        ) : !initialized && toBeInitialized ? null : (
          render({ subscribed })
        )
      }
    </AuthenticationCheck>
  )
}

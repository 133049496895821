import { useCallback, useRef } from 'react'

export function useFileInput({ allowMultipleFilesSelection, allowedFileTypes }: useFileInput.Options): {
  openFileInputStatic(): Promise<FileList | null>
  renderFileInput(): React.JSX.Element
} {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const resolveRef = useRef<((files: FileList | null) => void) | undefined>(undefined)

  const openFileInputStatic = useCallback<ReturnType<typeof useFileInput>['openFileInputStatic']>(() => {
    const fileInputElement = fileInputRef.current
    if (!fileInputElement) return Promise.resolve(null)

    return new Promise(resolve => {
      resolveRef.current = resolve

      fileInputElement.value = ''
      fileInputElement.click()
    })
  }, [])

  function renderFileInput() {
    return (
      <input
        ref={fileInputRef}
        hidden
        type="file"
        multiple={allowMultipleFilesSelection}
        accept={allowedFileTypes && allowedFileTypes.length > 0 ? allowedFileTypes.join(',') : undefined}
        onChange={event => {
          resolveRef.current?.(event.target.files)
          resolveRef.current = undefined
        }}
      />
    )
  }

  return {
    openFileInputStatic,
    renderFileInput,
  }
}

export namespace useFileInput {
  export interface Options {
    allowMultipleFilesSelection?: boolean
    /** Either `'abc/def'` formatted mime-types or `'.abc'` formatted file extensions */ allowedFileTypes?: readonly string[]
  }
}

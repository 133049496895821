import { DeepWritable, Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useEffect, useMemo, useRef, useState } from 'react'
import { dateHelpers } from '../../../../../helpers/native/dateHelpers'
import { useAsyncEffect } from '../../../../../hooks/useAsyncEffect'
import { DemoMode } from '../../../../../modules/demo-mode'
import { webConfig } from '../../../../../modules/web-config'
import { useConnectionStatus } from '../../connection-status'
import { useDatabases } from '../../databases'
import { EphemeralKey, useEphemeral } from '../../ephemeral'
import { PersistentKey, usePersistent } from '../../persistent'
import { FunnelServiceGetMessages } from '../../services'

export function useFunnelServiceGetMessages(): void {
  const {
    persistent,
    currentValues: [authentication],
  } = usePersistent(PersistentKey.Authentication)

  const { ephemeral } = useEphemeral()

  const [status, setStatus] = useState(FunnelServiceGetMessages.Status.ClosedInitially)

  const startTimeoutRef = useRef<any>(undefined)

  const { demoContent } = DemoMode.useContent()

  const { databases } = useDatabases()

  const { isOnline } = useConnectionStatus()

  const service = useMemo(
    () =>
      DemoMode.data
        ? undefined
        : new FunnelServiceGetMessages({
            onStatusChange: setStatus,

            onHeartBeat(currentCorrectedServerTimestamp) {
              const currentClientTimestamp = dateHelpers.getCurrentTimestamp()
              const timestampCorrection = currentCorrectedServerTimestamp - currentClientTimestamp
              ephemeral(EphemeralKey.TimestampCorrection).set(
                timestampCorrection * webConfig.timings.timestampCorrectionAlpha +
                  (ephemeral(EphemeralKey.TimestampCorrection).get() ?? timestampCorrection) *
                    (1 - webConfig.timings.timestampCorrectionAlpha)
              )
            },

            async onMutationsEvent(mutations, numberOfRemainingMutations) {
              const models = mutations.map(mutation => mutation.model)
              const noMoreInitializationData = numberOfRemainingMutations < 10
              const isMutablesDatabaseInitialized = persistent(PersistentKey.IsMutablesDatabaseInitialized).get()
              await databases.mutablesDatabase.reduceMutations(models, {
                fireMutationsEvent: isMutablesDatabaseInitialized,
                fireReloadEvent: !isMutablesDatabaseInitialized && noMoreInitializationData,
              })
              if (noMoreInitializationData) {
                persistent(PersistentKey.IsMutablesDatabaseInitialized).set(true)
              }
              const oldFunnelSequences = persistent(PersistentKey.FunnelSequences).get()
              const newFunnelSequences: DeepWritable<typeof oldFunnelSequences> = JSON.parse(
                JSON.stringify(oldFunnelSequences)
              )
              await Promise.all(
                mutations.map(async mutation => {
                  switch (mutation.sequenceSource) {
                    case 'user': {
                      const userId = ((): Id | null => {
                        switch (mutation.model.type) {
                          case Model.Type.Account:
                            return mutation.model.id

                          case Model.Type.User:
                            return mutation.model.id

                          case Model.Type.Page:
                            return null

                          case Model.Type.Card:
                            return null

                          case Model.Type.Badge:
                            return mutation.model.userId
                        }
                      })()
                      if (
                        userId &&
                        userId === persistent(PersistentKey.Authentication).get()?.decodedAccessToken.userId
                      ) {
                        newFunnelSequences.forUser = Math.max(newFunnelSequences.forUser ?? 0, mutation.sequence)
                      }
                      break
                    }

                    case 'page': {
                      const pageId = await (async (): Promise<Id | null> => {
                        switch (mutation.model.type) {
                          case Model.Type.Account:
                            return null

                          case Model.Type.User:
                            return null

                          case Model.Type.Page:
                            return mutation.model.id

                          case Model.Type.Card:
                            return mutation.model.pageId

                          case Model.Type.Badge:
                            return null
                        }
                      })()
                      if (pageId) {
                        newFunnelSequences.forPages[pageId] = Math.max(
                          newFunnelSequences.forPages[pageId] ?? 0,
                          mutation.sequence
                        )
                      }
                      break
                    }
                  }
                })
              )
              persistent(PersistentKey.FunnelSequences).set(newFunnelSequences)
            },

            onExtensionUpdate(extensionId) {
              ephemeral(EphemeralKey.NewlyUpdatedExtensionIds).set([
                ...ephemeral(EphemeralKey.NewlyUpdatedExtensionIds).get(),
                extensionId,
              ])
            },

            getAccessToken() {
              return persistent(PersistentKey.Authentication).get()?.accessToken ?? ''
            },

            async getSequences() {
              return persistent(PersistentKey.FunnelSequences).get()
            },
          }),
    [persistent, ephemeral, databases]
  )

  if (service && !DemoMode.data) {
    ephemeral(EphemeralKey.RecentlyConnectedToServer).set(status === FunnelServiceGetMessages.Status.Started)

    switch (status) {
      case FunnelServiceGetMessages.Status.Starting:
        // Do nothing, wait for status change or time out
        break

      case FunnelServiceGetMessages.Status.Started:
        if (!authentication) {
          service.destroy()
        } else if (!isOnline) {
          service.close()
        }
        break

      case FunnelServiceGetMessages.Status.ClosedInitially:
      case FunnelServiceGetMessages.Status.ClosedAndDestroyedByClient:
      case FunnelServiceGetMessages.Status.ClosedByClient:
        if (authentication && isOnline) {
          service.start()
        }
        break

      case FunnelServiceGetMessages.Status.ClosedDueToStartingTimeout:
      case FunnelServiceGetMessages.Status.ClosedByServer:
      case FunnelServiceGetMessages.Status.ClosedDueToServerRestartRequest:
      case FunnelServiceGetMessages.Status.ClosedDueToHeartBeatTimeout:
      case FunnelServiceGetMessages.Status.ClosedDueToServerOrNetworkFailure:
        if (authentication && isOnline) {
          if (!startTimeoutRef.current) {
            startTimeoutRef.current = setTimeout(() => {
              startTimeoutRef.current = undefined
              service.start()
            }, webConfig.timings.funnelApiService.getMessages.retryStarting)
          }
        } else if (startTimeoutRef.current) {
          clearTimeout(startTimeoutRef.current)
          startTimeoutRef.current = undefined
        }
        break
    }
  }

  useEffect(
    () => () => {
      if (
        service &&
        (service.getStatus() === FunnelServiceGetMessages.Status.Starting ||
          service.getStatus() === FunnelServiceGetMessages.Status.Started)
      ) {
        service.close()
      }
    },
    [service]
  )

  useAsyncEffect(
    async () => {
      if (DemoMode.data && demoContent && !persistent(PersistentKey.IsMutablesDatabaseInitialized).get()) {
        await databases.mutablesDatabase.reduceMutations(demoContent.models, { fireReloadEvent: true })
        persistent(PersistentKey.IsMutablesDatabaseInitialized).set(true)
        ephemeral(EphemeralKey.RecentlyConnectedToServer).set(true)
      }
    },
    [demoContent, persistent, ephemeral, databases, authentication] // `authentication` is also added to complete auto-sign-in in the sign-in page in demo mode
  )
}

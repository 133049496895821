import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { AnchorHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react'
import { useAppHistoryControl } from '../modules/app-history'

const useStyles = makeStyles(
  theme => ({
    root: {
      cursor: 'pointer',
    },
    underline: {
      textDecoration: 'underline',
    },
    highlight: {
      color: theme.palette.info.main,
    },
  }),
  { name: 'Anchor' }
)

export const Anchor = forwardRef<
  HTMLAnchorElement,
  {
    underline?: boolean
    highlight?: boolean
  } & DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
>(function Anchor({ href, underline, highlight, onClick, className, children, ...otherProps }, ref) {
  const { appHistoryControlStatic } = useAppHistoryControl()

  const classes = useStyles()

  return (
    <a
      {...otherProps}
      ref={ref}
      className={classNames(classes.root, underline && classes.underline, highlight && classes.highlight, className)}
      href={href}
      onClick={event => {
        if (onClick) {
          onClick(event)
        } else if (href && href.startsWith('/')) {
          appHistoryControlStatic.push(href)
          event.preventDefault()
        }
      }}
    >
      {children}
    </a>
  )
})

import { DemoMode } from '../../../../modules/demo-mode'
import { createKeyValueStore, KeyValueEngine } from '../../../../modules/key-value-store'
import { defaultValues } from './defaultValues'
import { equalityCheckers } from './equalityCheckers'
import { initializePersistent } from './initializePersistent'
import { Persistent, PersistentKey } from './types'

export function createPersistentOnMemory(): Persistent {
  const engine = new KeyValueEngine(
    () =>
      DemoMode.data
        ? {
            ...defaultValues,
            [PersistentKey.ThemeType]: DemoMode.data.themeType,
            [PersistentKey.LanguageCode]: DemoMode.data.languageCode as any, // TODO: Remove `as any` whenever the property type has changed from `LanguageCode` to `string`
          }
        : defaultValues,
    equalityCheckers,
    {}
  )
  const persistent = createKeyValueStore(engine)

  initializePersistent(persistent)

  return persistent
}

import { useContexts } from '../../contexts'
import { NavigationAreaContexts } from '../components/NavigationArea'

// Derived from useNavigation() hook:
export function useNavigationAreaActivityStatus(): {
  isNavigationActiveForThisArea: boolean
} {
  const { isAreaActive } = useContexts(NavigationAreaContexts)

  return {
    isNavigationActiveForThisArea: isAreaActive,
  }
}

import { Id } from '@zettelooo/commons'
import { Model } from '@zettelooo/server-shared'
import { useEffect } from 'react'
import { writable } from '../../../../../helpers/writable'
import { useDatabases } from '../../databases'
import { PageTop, PersistentKey, usePersistent } from '../../persistent'

export function usePageRecentDataMaintenance(): void {
  const { persistent } = usePersistent()

  const {
    databases: { mutablesDatabase },
  } = useDatabases()

  useEffect(() => {
    const { subscriptionKey } = mutablesDatabase.subscribe({
      async handleMutations(models) {
        let newPageTops = writable(persistent[PersistentKey.PageRecentData])

        await Promise.all(
          models.map(async model => {
            const pageId =
              model.type === Model.Type.Card ? model.pageId : model.type === Model.Type.Page ? model.id : undefined
            if (!pageId) return

            if (pageId in newPageTops && model.type === Model.Type.Page && model.isDeleted) {
              if (newPageTops === persistent[PersistentKey.PageRecentData]) {
                newPageTops = { ...newPageTops }
              }
              delete newPageTops[pageId]
              return
            }

            const oldLastCard = newPageTops[pageId]?.lastCard
            let newLastCard = oldLastCard
            if (model.type === Model.Type.Card) {
              if (model.id === oldLastCard?.id) {
                newLastCard = await mutablesDatabase.getPageLastCard(pageId)
              } else if (!model.isDeleted && (!oldLastCard || oldLastCard.sequence < model.sequence)) {
                newLastCard = model
              }
            }

            const oldLatestTimestamp = newPageTops[pageId]?.latestTimestamp ?? 0
            const newLatestTimestamp = Math.max(oldLatestTimestamp, model.updatedAt)

            if (oldLastCard?.id !== newLastCard?.id || oldLatestTimestamp !== newLatestTimestamp) {
              if (newPageTops === persistent[PersistentKey.PageRecentData]) {
                newPageTops = { ...newPageTops }
              }
              newPageTops[pageId] = {
                lastCard: newLastCard,
                latestTimestamp: newLatestTimestamp,
              }
            }
          })
        )

        persistent[PersistentKey.PageRecentData] = newPageTops
      },

      async handleReload() {
        const pages = await mutablesDatabase.getAllPages()

        const pageTops = await Promise.all(
          pages.map<Promise<PageTop>>(async page => {
            const [lastCard, latestCardTimestamp] = await Promise.all([
              mutablesDatabase.getPageLastCard(page.id),
              mutablesDatabase.getPageLatestCardTimestamp(page.id),
            ])
            const latestTimestamp = latestCardTimestamp ?? 0
            return {
              lastCard,
              latestTimestamp,
            }
          })
        )

        persistent(PersistentKey.PageRecentData).set(
          pages.reduce((developingPageTops, page, index) => {
            developingPageTops[page.id] = pageTops[index]
            return developingPageTops
          }, {} as Record<Id, PageTop>)
        )
      },
    })

    return () => {
      mutablesDatabase.unsubscribe(subscriptionKey)
    }
  }, [persistent, mutablesDatabase])
}

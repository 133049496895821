import { useContext, useLayoutEffect } from 'react'
import { sendAnalyticEvent } from '../../../../modules/analytics'
import { UpdateProvider } from './UpdateProvider'

export function AutomaticUpdater() {
  const { updateIsReady, update } = useContext(UpdateProvider.Context)

  useLayoutEffect(() => {
    if (updateIsReady) {
      sendAnalyticEvent('Automatic Updater', 'Update')
      update()
    }
  }, [updateIsReady])

  return <></>
}

import { makeStyles, Grid, Input, InputAdornment, IconButton } from '@material-ui/core'
import classNames from 'classnames'
import { useContext, useRef, ComponentRef, useLayoutEffect } from 'react'
import { useEffectOnce } from 'react-use'
import { useCombineRefs } from '../../../../../../../../../../hooks/useCombineRefs'
import { useCommonStyles } from '../../../../../../../../../../hooks/useCommonStyles'
import { NavigableWithCommands } from '../../../../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../../modules/custom-icon'
import { DeviceProvider } from '../../../../../../../../../../modules/device'
import { commonKeyboardCombinations } from '../../../../../../../../../../modules/keyboard-handler'
import { useNavigation, NavigableStatus } from '../../../../../../../../../../modules/navigation'
import { Gap } from '../../../../../../../../../Gap'
import { NewPage } from '../../../../../../modules/NewPage'
import { PageAvatar } from '../../../../../PageAvatar'
import { MainPage } from '../../../MainPage'
import { Panel } from '../../../panel'
import { SideBar } from '../SideBar'

const useStyles = makeStyles(
  theme => ({
    root: {
      border: `${theme.spacing(0.325)}px solid ${theme.palette.secondary.main}`,
      borderRadius: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    pageAvatar: {
      margin: theme.spacing(1.25, 2, 1.25, 1),
    },
    formWrapper: {
      width: 0,
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        marginRight: theme.spacing(1),
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    formInput: {
      padding: theme.spacing(1, 0),
    },
  }),
  { name: 'NewPageItem' }
)

export function NewPageItem() {
  const { isMobile } = useContext(DeviceProvider.Context)
  const { panel } = useContexts(MainPage.Contexts)
  const { newPage, newPageAccessor, newPageSubmitting, submitNewPageStatic, cancelNewPageStatic } = useContext(
    NewPage.Context
  )
  const { minimized } = useContext(SideBar.Context)

  const pageAvatarRef = useRef<ComponentRef<typeof PageAvatar>>(null)
  const windowFocusedRef = useRef(true)
  const submitButtonFocusedRef = useRef(false)
  const cancelButtonFocusedRef = useRef(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const combineInputRef = useCombineRefs(inputRef)

  useLayoutEffect(() => {
    if (panel?.type === Panel.Type.NewPage) {
      newPageAccessor.reset()
      setTimeout(() => inputRef.current?.focus())
    }
  }, [panel])

  useEffectOnce(() => {
    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
    function handleFocus(): void {
      windowFocusedRef.current = true
    }
    function handleBlur(): void {
      windowFocusedRef.current = false
    }
  })

  const { isKeyboardNavigationActiveForThisArea } = useNavigation()

  const { commonClasses } = useCommonStyles()
  const classes = useStyles()

  return (
    <>
      {!minimized && (
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          className={classNames(classes.root, newPageSubmitting && commonClasses.hidden)}
        >
          <Grid item>
            <PageAvatar
              ref={pageAvatarRef}
              page={newPage}
              size={6.75}
              popoverExpands="right"
              className={classes.pageAvatar}
              onUpdate={async updates => {
                newPageAccessor.merge(updates)
              }}
            >
              <CustomIcon name="AddAvatar" color="primary" />
            </PageAvatar>
          </Grid>

          <Grid item xs className={classes.formWrapper}>
            {panel?.type === Panel.Type.NewPage ? (
              <NavigableWithCommands
                selectable
                autoNavigate
                commandGroup={({ navigableRef }) => ({
                  name: 'newPageItem',
                  commands: [
                    {
                      name: 'submit',
                      disabled() {
                        return navigableRef.current?.navigableStatus !== NavigableStatus.Selected
                      },
                      defaultShortcutKeys: commonKeyboardCombinations.enter,
                      handler: submitNewPageStatic,
                    },
                    {
                      name: 'cancel',
                      disabled() {
                        return navigableRef.current?.navigableStatus !== NavigableStatus.Selected
                      },
                      defaultShortcutKeys: commonKeyboardCombinations.escape,
                      handler: cancelNewPageStatic,
                    },
                  ],
                })}
              >
                {({ connectNavigable, connectFocusable }) => (
                  <div ref={connectNavigable} className={classes.form}>
                    <Input
                      fullWidth
                      disableUnderline
                      inputRef={combineInputRef(connectFocusable)}
                      placeholder="Give me a name & icon"
                      classes={{ input: classes.formInput }}
                      endAdornment={
                        isMobile ? (
                          <InputAdornment position="end">
                            <Gap horizontal={1} />

                            <IconButton
                              size="small"
                              onClick={cancelNewPageStatic} // Commands are not available since the component is not focused at this point
                              onFocus={() => {
                                cancelButtonFocusedRef.current = true
                              }}
                              onBlur={() => {
                                cancelButtonFocusedRef.current = false
                              }}
                            >
                              <CustomIcon name="Close" />
                            </IconButton>

                            <Gap horizontal={1} />

                            <IconButton
                              size="small"
                              onClick={() => submitNewPageStatic()} // Commands are not available since the component is not focused at this point
                              onFocus={() => {
                                submitButtonFocusedRef.current = true
                              }}
                              onBlur={() => {
                                submitButtonFocusedRef.current = false
                              }}
                            >
                              <CustomIcon name="Check" />
                            </IconButton>
                          </InputAdornment>
                        ) : undefined
                      }
                      disabled={newPageSubmitting}
                      value={newPage.name}
                      onChange={event => newPageAccessor.merge({ name: event.target.value })}
                      onBlur={() => {
                        // Timeout to let the focused refs be updated first:
                        setTimeout(() => {
                          if (pageAvatarRef.current && pageAvatarRef.current.getState() !== 'none') return
                          if (!windowFocusedRef.current) return
                          if (submitButtonFocusedRef.current || cancelButtonFocusedRef.current) return
                          if (isKeyboardNavigationActiveForThisArea) return
                          submitNewPageStatic() // Commands are not available since the component is not focused at this point
                        })
                      }}
                    />
                  </div>
                )}
              </NavigableWithCommands>
            ) : null}
          </Grid>
        </Grid>
      )}
    </>
  )
}

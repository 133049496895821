import { useContext } from 'react'
import { ConfirmationDialogContext } from './ConfirmationDialogProvider'
import { ConfirmationDialogConfirm } from './types'

export function useConfirmationDialog(): {
  confirmStatic: ConfirmationDialogConfirm
} {
  const { confirmStatic } = useContext(ConfirmationDialogContext)

  return { confirmStatic }
}

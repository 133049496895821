import { Badge, Button, CircularProgress, makeStyles, Popover, Typography, useTheme } from '@material-ui/core'
import { captureException } from '@sentry/react'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import Color from 'color'
import { ComponentRef, forwardRef, PropsWithChildren, useImperativeHandle, useRef, useState } from 'react'
import { NavigableWithCommands, useManageCommands } from '../../../../../modules/commands'
import { EmojiSelect } from '../../../../../modules/emoji'
import { commonKeyboardCombinations } from '../../../../../modules/keyboard-handler'
import { Navigable, NavigationArea, useNavigation } from '../../../../../modules/navigation'
import { CustomAvatar } from '../../../../CustomAvatar'
import { useApplyAction } from '../../../hooks/useApplyAction'
import { useFileInputDirectUpload } from '../../../hooks/useFileInputDirectUpload'
import { useGenerateModelId } from '../../../hooks/useGenerateModelId'
import { useAppNotistack } from '../../../modules/app-notistack'
import { ColorSelect } from './ColorSelect'

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
    },
    avatarEditable: {
      cursor: 'pointer',
    },
    progressWrapper: {
      position: 'absolute',
      top: theme.spacing(0.25),
      bottom: theme.spacing(0.25),
      left: theme.spacing(0.25),
      right: theme.spacing(0.25),
      borderRadius: '50%',
      padding: theme.spacing(0.25),
      backgroundColor: theme.palette.background.paper,
      opacity: 0.5,
    },
    progressText: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.main,
    },
    progressCircle: {
      color: theme.palette.primary.main,
    },
    popoverPaper: {
      padding: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
    },
    selector: {
      width: theme.spacing(48),
      maxWidth: '100%',
      height: theme.spacing(38.75),
      maxHeight: '100%',
      overflowY: 'auto',
    },
    emojiSelect: {
      height: '100%',
    },
    colorSelect: {
      height: '100%',
    },
    tabs: {
      flex: 'none',
      display: 'flex',
      paddingTop: theme.spacing(1.5),
    },
    tab: {
      marginLeft: theme.spacing(1),
    },
    selectedTab: {
      backgroundColor: new Color(theme.palette.primary.main).alpha(theme.palette.action.hoverOpacity).string(),
    },
  }),
  { name: 'PageAvatar' }
)

const tabs = ['Color', 'Emoji'] as const
type ViewState = (typeof tabs)[number] | null

export const PageAvatar = forwardRef<
  {
    open(): void
    close(): void
    getState(): 'none' | 'picker' | 'uploading' | 'updating'
  },
  PropsWithChildren<{
    page: Pick<Model.Page, 'id' | 'iconEmoji' | 'color' | 'avatarFileId'>
    size: number
    compact?: boolean
    noBackgroundColor?: boolean
    popoverExpands: 'left' | 'right'
    badgeStatus?: { readonly justJoined: boolean; readonly unreadCount: number; readonly mentionedCount: number }
    disableUpdateOnClick?: boolean
    className?: string
    onUpdate?(updates: Partial<Pick<Model.Page, 'iconEmoji' | 'color' | 'avatarFileId'>>): Promise<void>
    onUpdateStarted?(): void
    onUpdateFinished?(): void
  }>
>(function PageAvatar(
  {
    page,
    size,
    compact,
    noBackgroundColor,
    popoverExpands,
    badgeStatus,
    disableUpdateOnClick,
    className,
    onUpdate,
    onUpdateStarted,
    onUpdateFinished,
    children,
  },
  ref
) {
  const customAvatarRef = useRef<ComponentRef<typeof CustomAvatar>>(null)

  const [viewState, setViewState] = useState<ViewState>(null)
  const [isUpdatingPage, setIsUpdatingPage] = useState(false)
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  useImperativeHandle(
    ref,
    () => ({
      open() {
        setViewState('Emoji')
      },
      close() {
        setViewState(null)
      },
      getState() {
        return viewState !== null ? 'picker' : isUpdatingPage ? 'updating' : isUploadingAvatar ? 'uploading' : 'none'
      },
    }),
    [viewState, isUpdatingPage, isUploadingAvatar]
  )

  const { applyActionStatic } = useApplyAction()

  const handleUpdate: NonNullable<typeof onUpdate> = async updates => {
    if (onUpdate) {
      await onUpdate(updates)
    } else {
      await applyActionStatic.updateModel({
        type: Model.Type.Page,
        id: page.id,
        ...updates,
      })
    }
  }

  const { generateModelIdStatic } = useGenerateModelId()

  const { enqueueSnackbar } = useAppNotistack()

  const { openFileInputAndUploadFile, renderFileInput } = useFileInputDirectUpload({
    allowedFileTypes: ['image/*'],
    onFileSelectAndUploadStart(file) {
      setIsUploadingAvatar(true)
      return generateModelIdStatic()
    },
    onFileUploadProgress(file, fileId, percent) {
      setUploadProgress(percent)
    },
    async onFileUploadSuccess(file, fileId) {
      setIsUploadingAvatar(false)
      setIsUpdatingPage(true)
      onUpdateStarted?.()
      await handleUpdate({ avatarFileId: fileId })
      onUpdateFinished?.()
      setIsUpdatingPage(false)
    },
    onFileUploadError(file, fileId, error) {
      log.error('upload page avatar image', 'failed', file.name, file.type, file.size, error)
      captureException(error, { tags: { module: 'page', action: 'upload avatar' } })
      enqueueSnackbar('Error', `Upload failed for the file: ${file.name}`, { variant: 'error' })
      setIsUploadingAvatar(false)
    },
  })

  const { redrawNavigationPathHighlight } = useNavigation()

  const { runCommandStatic } = useManageCommands()

  const theme = useTheme()

  const classes = useStyles()

  const editable = !disableUpdateOnClick && !isUploadingAvatar

  return (
    <>
      <NavigableWithCommands
        disabled={!editable}
        commandGroup={({ navigableRef }) => ({
          name: 'pageAvatar',
          commands: [
            {
              name: 'open',
              defaultShortcutKeys: commonKeyboardCombinations.enter,
              handler() {
                setViewState('Emoji')
              },
            },
          ],
        })}
      >
        {({ connectNavigable }) => (
          <div
            ref={connectNavigable}
            className={classNames(classes.root, className)}
            style={{
              width: theme.spacing(size),
              height: theme.spacing(size),
            }}
          >
            <Badge
              badgeContent={badgeStatus?.mentionedCount ?? 0}
              showZero
              variant={typeof badgeStatus?.mentionedCount === 'number' ? 'standard' : 'dot'}
              max={9999}
              invisible={!badgeStatus}
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <CustomAvatar
                ref={customAvatarRef}
                size={size}
                compact={compact}
                avatarFileId={page.avatarFileId}
                name={undefined}
                emoji={page.iconEmoji}
                color={noBackgroundColor ? undefined : page.color || theme.palette.action.disabledBackground}
                className={classNames(editable && classes.avatarEditable)}
                onMouseDown={event => {
                  if (editable) {
                    event.preventDefault()
                    runCommandStatic('pageAvatar.open')
                  }
                }}
              >
                {children}
              </CustomAvatar>
            </Badge>

            {isUploadingAvatar && (
              <div className={classes.progressWrapper}>
                <CircularProgress
                  size={theme.spacing(size - 1.75)}
                  variant="determinate"
                  className={classes.progressCircle}
                  value={uploadProgress}
                />
                <Typography variant="caption" color="textPrimary" className={classes.progressText}>
                  {`${uploadProgress}%`}
                </Typography>
              </div>
            )}
          </div>
        )}
      </NavigableWithCommands>

      <Popover
        anchorEl={customAvatarRef.current}
        classes={{ paper: classes.popoverPaper }}
        open={viewState !== null}
        onClose={() => setViewState(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: popoverExpands === 'left' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: popoverExpands === 'left' ? 'right' : 'left' }}
        TransitionProps={{
          onEntered: redrawNavigationPathHighlight,
        }}
      >
        <NavigationArea disabled={viewState === null}>
          <div className={classes.selector}>
            {viewState === 'Color' ? (
              <ColorSelect
                className={classes.colorSelect}
                noColorOption
                color={page.color}
                onSelect={async color => {
                  setViewState(null)
                  setIsUpdatingPage(true)
                  onUpdateStarted?.()
                  await handleUpdate({ color })
                  onUpdateFinished?.()
                  setIsUpdatingPage(false)
                }}
              />
            ) : (
              <EmojiSelect
                className={classes.emojiSelect}
                onSelect={async emoji => {
                  setViewState(null)
                  setIsUpdatingPage(true)
                  onUpdateStarted?.()
                  await handleUpdate({
                    iconEmoji: emoji.char,
                    avatarFileId: null,
                  })
                  onUpdateFinished?.()
                  setIsUpdatingPage(false)
                }}
              />
            )}
          </div>

          <Navigable group>
            {({ connectNavigable: connectTabs }) => (
              <div ref={connectTabs} className={classes.tabs}>
                {tabs.map(tab => (
                  <Navigable key={tab}>
                    {({ connectNavigable }) => (
                      <Button
                        ref={connectNavigable}
                        color={tab === viewState ? 'primary' : 'default'}
                        className={classNames(classes.tab, tab === viewState && classes.selectedTab)}
                        onClick={() => setViewState(tab)}
                      >
                        {tab}
                      </Button>
                    )}
                  </Navigable>
                ))}

                <Navigable>
                  {({ connectNavigable }) => (
                    <Button
                      ref={connectNavigable}
                      className={classNames(classes.tab)}
                      onClick={() => {
                        setViewState(null)
                        openFileInputAndUploadFile()
                      }}
                    >
                      Upload Image
                    </Button>
                  )}
                </Navigable>
              </div>
            )}
          </Navigable>
        </NavigationArea>
      </Popover>

      {renderFileInput()}
    </>
  )
})

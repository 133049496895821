import { CircularProgress, makeStyles, Typography, useTheme } from '@material-ui/core'
import { captureException } from '@sentry/react'
import { Id } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Extension } from '@zettelooo/server-shared'
import { useEffect, useMemo, useState } from 'react'
import { useMountedState } from 'react-use'
import { webConfig } from '../../../../../../modules/web-config'
import { SearchBox } from '../../../../../SearchBox'
import { useServices } from '../../../services'
import { useInstalledExtensionsReader } from '../../hooks/useInstalledExtensionsReader'
import { Context } from './Context'
import { DocumentationDialog } from './DocumentationDialog'
import { ExtensionItem } from './ExtensionItem'

const useStyles = makeStyles(
  theme => ({
    searchBoxWrapper: {
      marginBottom: theme.spacing(2),
    },
    circularProgressWrapper: {
      height: theme.spacing(8),
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
  }),
  { name: 'ExtensionStore' }
)

export function ExtensionStore({
  className,
  readOnly,
  scope,
  enabledExtensionIds,
  enableExtensions,
  disableExtensions,
}: {
  className?: string
  readOnly?: boolean
  scope: ZettelExtensions.Scope
  enabledExtensionIds?: readonly Id[]
  enableExtensions(extensionIds: readonly Id[]): Promise<void>
  disableExtensions(extensionIds: readonly Id[]): Promise<void>
}) {
  const [query, setQuery] = useState('')
  const [searching, setSearching] = useState(false)
  const [filteredExtensions, setFilteredExtensions] = useState<readonly Extension.Header[]>([])
  const [extensionForDocumentation, setExtensionForDocumentation] = useState<Extension.Header>()

  const { services } = useServices()

  const isMounted = useMountedState()

  useEffect(() => {
    setSearching(true)
    services.extension
      .searchHeaders(query, scope)
      .then(
        ({ headers }) => isMounted() && setFilteredExtensions(headers),
        error => {
          log.error(error)
          captureException(error, { tags: { module: 'extensions', action: 'search' } })
          // TODO: Display 'Unable to search for extensions.' error message somewhere next to the search inbox (not on a snackbar)
        }
      )
      .finally(() => isMounted() && setSearching(false))
  }, [query])

  const { installedExtensionsReader } = useInstalledExtensionsReader()

  const enabledExtensions = useMemo(
    () =>
      enabledExtensionIds && !installedExtensionsReader.loading
        ? installedExtensionsReader.all.filter(extension => enabledExtensionIds.includes(extension.id))
        : [],
    [enabledExtensionIds, installedExtensionsReader]
  )
  const filteredEnabledExtensions = useMemo(
    () =>
      (!query
        ? enabledExtensions
        : enabledExtensions.filter(enabledExtension =>
            filteredExtensions.some(filteredExtension => filteredExtension.id === enabledExtension.id)
          )
      ).filter(extension => !webConfig.app.cashFlowPurpose || extension.id !== 'zettel.text'),
    [query, enabledExtensions, filteredExtensions]
  )
  const filteredDisabledExtensions = useMemo(
    () =>
      filteredExtensions
        .filter(filteredExtension =>
          filteredEnabledExtensions.every(
            filteredEnabledExtension => filteredEnabledExtension.id !== filteredExtension.id
          )
        )
        .filter(extension => !webConfig.app.cashFlowPurpose || extension.id !== 'zettel.text'),
    [filteredExtensions, filteredEnabledExtensions]
  )

  const theme = useTheme()

  const classes = useStyles()

  return (
    <Context.Provider
      value={{
        readOnly,
        enabledExtensionIds,
        enableExtensions,
        disableExtensions,
      }}
    >
      <div className={className}>
        <div className={classes.searchBoxWrapper}>
          <SearchBox query={query} onSearch={setQuery} />
        </div>

        {filteredEnabledExtensions.length > 0 && (
          <>
            <Typography variant="overline">Installed</Typography>

            {filteredEnabledExtensions.map(extension => (
              <ExtensionItem
                key={extension.id}
                extension={extension}
                onClick={() => setExtensionForDocumentation(extension)}
              />
            ))}
          </>
        )}

        {filteredDisabledExtensions.length > 0 && !searching && (
          <>
            <Typography variant="overline">Not installed</Typography>

            {filteredDisabledExtensions.map(extension => (
              <ExtensionItem
                key={extension.id}
                extension={extension}
                onClick={() => setExtensionForDocumentation(extension)}
              />
            ))}
          </>
        )}

        {searching && (
          <div className={classes.circularProgressWrapper}>
            <CircularProgress size={theme.spacing(3)} />
          </div>
        )}

        {filteredEnabledExtensions.length === 0 && filteredDisabledExtensions.length === 0 && !searching && (
          <Typography variant="subtitle2" color="textSecondary">
            Nothing found.
          </Typography>
        )}
      </div>

      {extensionForDocumentation && (
        <DocumentationDialog
          key={`${extensionForDocumentation.id}-${extensionForDocumentation.version}`}
          extension={extensionForDocumentation}
          onClose={() => setExtensionForDocumentation(undefined)}
        />
      )}
    </Context.Provider>
  )
}

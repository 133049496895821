import { PropsWithChildren, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { webConfig } from '../../../../modules/web-config'
import { useWindowDev } from '../../../../modules/window-dev'
import { Run } from '../../../Run'
import { IsSuperContext } from './contexts/IsSuperContext'
import { SetDisabledContext } from './contexts/SetDisabledContext'
import { useAccountLastActiveUpdating } from './duties/useAccountLastActiveUpdating'
import { useActionSynchronization } from './duties/useActionSynchronization'
import { useExtensionsDatabaseMaintenance } from './duties/useExtensionsDatabaseMaintenance'
import { useFunnelServiceGetMessages } from './duties/useFunnelServiceGetMessages'
import { useMutablesDatabaseMaintenance } from './duties/useMutablesDatabaseMaintenance'
import { useOnMemoryModelsMaintenance } from './duties/useOnMemoryModelsMaintenance'
import { usePageRecentDataMaintenance } from './duties/usePageRecentDataMaintenance'
import { useDetector } from './useDetector'

export function SuperWindowClientProvider({ children }: PropsWithChildren<{}>) {
  const [disabled, setDisabled] = useState(false)

  const { isSuper } = useDetector({ disabled })

  useWindowDev(
    () => ({
      isSuperWindowClient: isSuper,
    }),
    [isSuper]
  )

  return (
    <>
      {isSuper && (
        <Run key="duties">
          {() => {
            useEffectOnce(() => {
              if (webConfig.developerLogs.superWindowClient) {
                console.log('Enter "Super Window Client" mode.') // eslint-disable-line no-console
                return () => console.log('Exit "Super Window Client" mode.') // eslint-disable-line no-console
              }
            })

            useFunnelServiceGetMessages()
            useActionSynchronization()
            usePageRecentDataMaintenance()
            useOnMemoryModelsMaintenance()
            useMutablesDatabaseMaintenance()
            useExtensionsDatabaseMaintenance()
            useAccountLastActiveUpdating()
          }}
        </Run>
      )}

      <SetDisabledContext.Provider value={setDisabled}>
        <IsSuperContext.Provider value={isSuper}>
          <>{children}</>
        </IsSuperContext.Provider>
      </SetDisabledContext.Provider>
    </>
  )
}

import { Button, Container, makeStyles, TextField, Typography } from '@material-ui/core'
import { useState } from 'react'
import { Gap } from '../../../../../../Gap'
import { useServices } from '../../../../../modules/services'
import { Authentication } from './Authentication'

const useStyles = makeStyles(
  theme => ({
    root: {
      minHeight: '100vh',
    },
    form: {
      padding: theme.spacing(10, 0),
    },
  }),
  { name: 'SignInView' }
)

export function SignInView() {
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { signInStatic } = Authentication.useControl()

  const { services } = useServices()

  const classes = useStyles()

  return (
    <Container maxWidth="sm" className={classes.root}>
      <form
        className={classes.form}
        onSubmit={async event => {
          event.preventDefault()
          if (id === Authentication.adminCredentials.id && password === Authentication.adminCredentials.password) {
            signInStatic('admin')
            return
          }
          try {
            setErrorMessage('')
            setSubmitting(true)
            const { developerId } = await services.developer.signIn(id, password)
            if (!developerId) throw Error('Unable to sign in.')
            signInStatic({ id: developerId })
          } catch (error) {
            log.error(error)
            setErrorMessage(String(error))
          } finally {
            setSubmitting(false)
          }
        }}
      >
        <Typography variant="h5" gutterBottom>
          Welcome dear developer!
        </Typography>
        <Typography variant="subtitle2">Use your developer account credential to sign in:</Typography>

        <Gap vertical={3} />

        <TextField
          variant="outlined"
          label="ID"
          placeholder="id"
          fullWidth
          autoFocus
          disabled={submitting}
          value={id}
          onChange={event => setId(event.target.value)}
        />

        <Gap vertical={2} />

        <TextField
          variant="outlined"
          label="Password"
          placeholder="password"
          type="password"
          fullWidth
          disabled={submitting}
          value={password}
          onChange={event => setPassword(event.target.value)}
        />

        <Gap vertical={2} />

        <Button variant="contained" color="primary" type="submit" fullWidth disabled={submitting}>
          Sign in
        </Button>

        {errorMessage && (
          <>
            <Gap vertical={2} />

            <Typography variant="subtitle1" color="error">
              {errorMessage}
            </Typography>
          </>
        )}
      </form>
    </Container>
  )
}

import { FieldContainer } from '../../FieldContainer'
import { AvatarField } from './AvatarField'
import { EmailField } from './EmailField'
import { SessionField } from './SessionField'
import { UsernameField } from './UsernameField'
import { WalletAddressField } from './WalletAddressField'

export function AccountSubPanel() {
  return (
    <>
      <FieldContainer label="Avatar & display name:">
        <AvatarField />
      </FieldContainer>

      <FieldContainer label="Username:">
        <UsernameField />
      </FieldContainer>

      <FieldContainer label="Email:">
        <EmailField />
      </FieldContainer>

      <FieldContainer label="Wallet address:">
        <WalletAddressField />
      </FieldContainer>

      <SessionField />
    </>
  )
}

import { Button, makeStyles } from '@material-ui/core'
import { captureException } from '@sentry/react'
import { delay } from '@zettelooo/commons'
import { Extension } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { useContext, useState } from 'react'
import { useMountedState } from 'react-use'
import { useAppNotistack } from '../../../app-notistack'
import { useInstalledExtensionsReader } from '../../hooks/useInstalledExtensionsReader'
import { Context } from './Context'

const useStyles = makeStyles(
  theme => ({
    root: {
      height: theme.spacing(2.5),
      flexShrink: 0,
    },
  }),
  { name: 'InstallButton' }
)

export function InstallButton({ extension, className }: { extension: Extension.Header; className?: string }) {
  const { readOnly, enabledExtensionIds, enableExtensions, disableExtensions } = useContext(Context)

  const [enablingOrDisabling, setEnablingOrDisabling] = useState(false)

  const { installedExtensionsReader } = useInstalledExtensionsReader()

  const { enqueueSnackbar } = useAppNotistack()

  const isMounted = useMountedState()

  const classes = useStyles()

  const determined = Boolean(
    enabledExtensionIds && !installedExtensionsReader.loading && !installedExtensionsReader.updating
  )
  const installed = Boolean(extension.id in installedExtensionsReader.byId)
  const enabled = Boolean(enabledExtensionIds?.includes(extension.id))
  const modifiable = Boolean(determined && extension.version && !enablingOrDisabling && !(enabled && !installed))

  if (readOnly || !determined) return null

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      className={classNames(classes.root, className)}
      disabled={!modifiable}
      onClick={async event => {
        event.stopPropagation()
        if (!modifiable) return
        setEnablingOrDisabling(true)
        // await delay(300) // When the extension is already installed, it's too fast to feel like installing anything!
        try {
          if (enabled) {
            // TODO: The extension dependencies are not managed:
            await disableExtensions([extension.id])
          } else {
            // TODO: The extension dependencies are not managed:
            await enableExtensions([extension.id])
          }
        } catch (error: any) {
          log.error('extension', 'installing or uninstalling', error)
          captureException(error, { tags: { module: 'extensions', action: 'install / uninstall' } })
          const errorMessage =
            typeof error === 'string'
              ? error
              : typeof error.message === 'string'
              ? error.message
              : `Unable to ${enabled ? 'uninstall' : 'install'} extension.`
          enqueueSnackbar('Error', errorMessage, { variant: 'error' })
        }
        if (isMounted()) {
          setEnablingOrDisabling(false)
        }
      }}
    >
      {!extension.version
        ? 'Coming soon'
        : enabled && installed
        ? enablingOrDisabling
          ? 'Uninstalling'
          : 'Uninstall'
        : enabled || enablingOrDisabling
        ? 'Installing'
        : 'Install'}
    </Button>
  )
}

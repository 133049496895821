import { TooltipProps } from '@material-ui/core'
import { ReadonlyRecord, PartialRecord } from '@zettelooo/commons'
import { ReactNode } from 'react'

export type ToursData = ReadonlyRecord<OnboardingTour, ReadonlyRecord<string, TourStepData>>
export type TourData<T extends OnboardingTour> = ReadonlyRecord<OnboardingStep<T>, TourStepData>
export interface TourStepData {
  readonly title: string | (() => ReactNode)
  readonly imageUrl?: string
  readonly description: string | (() => ReactNode)
  readonly onlyAsTooltip?: true
}

export type TourStepAnchors = PartialRecord<OnboardingTour, PartialRecord<string, TourStepAnchor>>
export interface TourStepAnchor {
  readonly element: HTMLElement
  readonly options: TourStepAnchorOptions
}
export interface TourStepAnchorOptions {
  readonly placement?: TooltipProps['placement']
  readonly padding?: number | [number] | [number, number] | [number, number, number] | [number, number, number, number]
  readonly delayMilliseconds?: number
  readonly noHighlight?: boolean
}

export interface OnboardingState {
  readonly tour: OnboardingTour
  readonly step: OnboardingStep
}

export enum OnboardingTour {
  Welcome = 'WELCOME',
  References = 'REFERENCES',
  Launcher = 'LAUNCHER',
}

export type OnboardingStep<T extends OnboardingTour = OnboardingTour> = {
  [OnboardingTour.Welcome]: OnboardingWelcomeTourStep
  [OnboardingTour.References]: OnboardingReferencesTourStep
  [OnboardingTour.Launcher]: OnboardingLauncherTourStep
}[T]

export enum OnboardingWelcomeTourStep {
  Pages = 'PAGES',
  CardAdderDialog = 'CARD_ADDER_DIALOG',
  Activity = 'ACTIVITY',
  Card = 'CARD',
  CardAdder = 'CARD_ADDER',
  PageSideBar = 'PAGE_SIDE_BAR',
  CommandBarAccess = 'COMMAND_BAR_ACCESS',
  CommandBarWelcome = 'COMMAND_BAR_WELCOME',
  CommandBarShortcuts = 'COMMAND_BAR_SHORTCUTS',
}

export enum OnboardingReferencesTourStep {
  ReferencesStep = 'REFERENCES_STEP',
}

export enum OnboardingLauncherTourStep {
  LauncherStep = 'LAUNCHER_STEP',
}

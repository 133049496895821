import { Model } from '@zettelooo/server-shared'

export namespace AppDragObject {
  export enum Type {
    Card = 'CARD',
  }

  export interface Card {
    readonly type: AppDragObject.Type.Card
    readonly card: Model.Card
  }
}

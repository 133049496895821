import { PropsWithChildren } from 'react'
import { useAppNotistack } from '../../modules/app-notistack'
import { SmartCommand } from '../../modules/SmartCommand'

export function SmartCommandApiProvider({ children }: PropsWithChildren<{}>) {
  const { enqueueSnackbar } = useAppNotistack()

  const { smartCommandApi } = SmartCommand.useApiBuilder(
    {
      enqueueSnackbar,
    },
    contentRef => ({
      showMessage: {
        signatures: [
          "(title: string, message: string, options?: { variant?: 'success' | 'error' | 'info' | 'warning' }): void // Displays a message poped up to users with the given title, message body, and variant",
        ],
        function(
          title: string,
          message: string,
          options?: { variant?: 'success' | 'error' | 'info' | 'warning' }
        ): void {
          contentRef.current.enqueueSnackbar(title, message, options)
        },
      },
    }),
    []
  )

  return <SmartCommand.ApiProvider api={smartCommandApi}>{children}</SmartCommand.ApiProvider>
}

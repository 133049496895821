import { PartialRecord, ReadonlyRecord } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Extension } from '@zettelooo/server-shared'
import { ExtensionEffectWatchers } from '../types'

// TODO: Break down this file:

export interface ExtensionExecutables {
  readonly starter?: ZettelExtensions.Starter
  readonly cardData?: ZettelExtensions.CardData
  readonly services?: ZettelExtensions.Services
}

export interface ExtensionContext {
  readonly header: Extension.Header
  readonly executables: ExtensionExecutables
  isRegistering: boolean
  enabledScopesByLifeSpanNameAndTargetRolesKey: PartialRecord<
    ZettelExtensions.LifeSpan.Name,
    PartialRecord<string, ZettelExtensions.LifeSpan.Scopes<ZettelExtensions.LifeSpan.Name>>
  >
  effectPool: ExtensionEffectPool
}

export class ExtensionEffectPool {
  private readonly effectsByLifeSpanName: PartialRecord<ZettelExtensions.LifeSpan.Name, ExtensionEffect[]> = {}

  add(effect: ExtensionEffect): void {
    if (!(effect.lifeSpanName in this.effectsByLifeSpanName)) {
      this.effectsByLifeSpanName[effect.lifeSpanName] = []
    }
    this.effectsByLifeSpanName[effect.lifeSpanName]!.push(effect)
  }
  remove(effect: ExtensionEffect): void {
    if (effect.lifeSpanName in this.effectsByLifeSpanName) {
      const index = this.effectsByLifeSpanName[effect.lifeSpanName]!.indexOf(effect)
      if (index < 0) return
      this.effectsByLifeSpanName[effect.lifeSpanName]!.splice(index, 1)
    }
  }
  has(effect: ExtensionEffect): boolean {
    return Boolean(this.effectsByLifeSpanName[effect.lifeSpanName]?.includes(effect))
  }
  iterateByLifeSpanName(
    lifeSpanName: ZettelExtensions.LifeSpan.Name,
    callback: (effect: ExtensionEffect) => void
  ): void {
    this.effectsByLifeSpanName[lifeSpanName]?.forEach(callback)
    const effectGroups = Object.values(this.effectsByLifeSpanName)
    effectGroups.forEach(effectGroup => {
      const effects = [...effectGroup]
      effects.forEach(effect => {
        if (this.has(effect)) {
          const effectContexts = Object.values(effect.contextsByTargetRolesKey)
          effectContexts.forEach(effectContext => {
            if (effectContext && !effectContext.isDisposed) {
              effectContext.sideEffects.iterateByLifeSpanName(lifeSpanName, callback)
            }
          })
        }
      })
    })
  }
  finishAll(): void {
    const effectGroups = Object.values(this.effectsByLifeSpanName)
    effectGroups.forEach(effectGroup => {
      while (effectGroup.length > 0) {
        const effect = effectGroup.at(-1)!
        effect.finish()
      }
    })
  }
}

export interface ExtensionEffect<N extends ZettelExtensions.LifeSpan.Name = ZettelExtensions.LifeSpan.Name> {
  lifeSpanName: N
  affect: ZettelExtensions.Starter.While.Affect<N>
  containingTargetRoles: ExtensionTargetRoles
  contextsByTargetRolesKey: PartialRecord<string, ExtensionEffectContext<N>>
  finish(): void
}

export interface ExtensionEffectContext<N extends ZettelExtensions.LifeSpan.Name = ZettelExtensions.LifeSpan.Name> {
  isDisposed?: true
  watchers: ExtensionEffectWatchers<N>
  disposers: (() => void)[]
  sideEffects: ExtensionEffectPool
  dispose(): void
}

export interface ExtensionTargetRoles {
  readonly target: ReadonlyRecord<string, any>
  readonly roles: readonly string[]
}

import { IpcChannel, UpdateIpcMessageType, WindowType } from '@zettelooo/desktop-shared'
import { createContext, PropsWithChildren, useCallback, useState } from 'react'
import { useEffectOnce, useGetSet } from 'react-use'
import { typed } from '../../../../helpers/typed'
import { DemoMode } from '../../../../modules/demo-mode'
import { PeekMode } from '../../../../modules/peek-mode'
import { webConfig } from '../../../../modules/web-config'
import { MemoizedContextProvider } from '../../../MemoizedContextProvider'
import { sendIpcMessage, useIpcMessageHandling } from '../electron'
import { ServiceWorkerMessage, ServiceWorkerMessageType } from '../service-worker'

export function UpdateProvider({ children }: PropsWithChildren<{}>) {
  const [updateIsReady, setUpdateIsReady] = useState(false)
  const [getIsUpdating, setIsUpdating] = useGetSet(false)

  useEffectOnce(() => {
    if (DemoMode.data || PeekMode.data) return

    if (!webConfig.serviceWorker.precaching) return

    // See: https://whatwebcando.today/articles/handling-service-worker-updates/

    if (webConfig.environment.agent === 'browser') {
      window.navigator.serviceWorker.getRegistration().then(registration => {
        if (registration) {
          if (registration.waiting) {
            setUpdateIsReady(true)
          }

          registration.addEventListener('updatefound', () => {
            registration.installing?.addEventListener('statechange', () => {
              if (registration.waiting && window.navigator.serviceWorker.controller) {
                setUpdateIsReady(true)
              }
            })
          })
        }
      })
    }

    let reloading = false
    window.navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (!reloading) {
        reloading = true
        window.location.reload()
      }
    })
  })

  useIpcMessageHandling(
    IpcChannel.Update,
    (event, message) => {
      switch (message.type) {
        case UpdateIpcMessageType.ElectronDownloaded:
          setUpdateIsReady(true)
          break
      }
    },
    [],
    {
      windowType: WindowType.Main,
    }
  )

  const update = useCallback((): void => {
    if (!updateIsReady || getIsUpdating()) return
    setIsUpdating(true)

    if (webConfig.environment.agent === 'browser') {
      window.navigator.serviceWorker.getRegistration().then(registration => {
        registration?.waiting?.postMessage(
          typed<ServiceWorkerMessage<ServiceWorkerMessageType.SkipWaiting>>({
            type: ServiceWorkerMessageType.SkipWaiting,
          })
        )
      })
    }

    if (webConfig.environment.agent === 'electron') {
      sendIpcMessage(IpcChannel.Update, {
        type: UpdateIpcMessageType.BrowserInstall,
      })
    }
  }, [updateIsReady, getIsUpdating()])

  return (
    <MemoizedContextProvider
      context={UpdateProvider.Context}
      value={{
        updateIsReady,
        isUpdating: getIsUpdating(),
        update,
      }}
      dependencies={[updateIsReady, getIsUpdating(), update]}
    >
      {children}
    </MemoizedContextProvider>
  )
}

export namespace UpdateProvider {
  export const Context = createContext<{
    updateIsReady: boolean
    isUpdating: boolean
    update(): void
  }>(undefined as any)
}

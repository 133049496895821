import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { App } from '../../components/App'
import { webConfig } from '../../modules/web-config'

export function renderApplication(): void {
  // TODO: Due to a bug in the v4 of the Material-UI library, it has problems with the React's StrictMode.
  //       It is resolved in the v5 of the library, apparently.
  //       Please, remove the condition from the following StrictMode right after upgrading the library to v5:
  const app =
    webConfig.environment.node === 'production' ? (
      <StrictMode>
        <App />
      </StrictMode>
    ) : (
      <App />
    )

  ReactDOM.render(app, document.getElementById('root'))
}

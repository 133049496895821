import { ServiceWorkerMessage, ServiceWorkerMessageType } from '../service-worker'

export enum SuperWindowClientServiceWorkerMessageSubType {
  Claim = 'CLAIM',
}

export type SuperWindowClientServiceWorkerMessage<
  T extends SuperWindowClientServiceWorkerMessageSubType = SuperWindowClientServiceWorkerMessageSubType
> = ServiceWorkerMessage<ServiceWorkerMessageType.SuperWindowClient> &
  {
    [SuperWindowClientServiceWorkerMessageSubType.Claim]: {
      readonly subType: SuperWindowClientServiceWorkerMessageSubType.Claim
      readonly initiatorIncrementalId: bigint
      readonly claimingIncrementalId: bigint
    }
  }[T]

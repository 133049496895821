import { Typography } from '@material-ui/core'
import { sendAnalyticEvent } from '../../../../../../../../../modules/analytics'
import { useManageCommands } from '../../../../../../../../../modules/commands'
import { CustomIcon } from '../../../../../../../../../modules/custom-icon'
import { KeyboardCombinationsView } from '../../../../../../../../../modules/keyboard-handler'
import { ButtonItem } from './ButtonItem'

export function SearchItem() {
  const { runCommandStatic } = useManageCommands()

  return (
    <ButtonItem
      icon={<CustomIcon name="Search" size={2.5} />}
      tooltipTitle={<KeyboardCombinationsView combinations={{ cmd: true, code: 'Slash' }} prefix="Search" highlight />}
      onClick={() => {
        sendAnalyticEvent('SearchTab', 'Open search tab by click')
        runCommandStatic('main.search')
      }}
    >
      <Typography variant="subtitle1" noWrap>
        Search
      </Typography>
    </ButtonItem>
  )
}

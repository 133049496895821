import { IconButton, makeStyles, Paper, Tooltip } from '@material-ui/core'
import { useContext } from 'react'
import { useManageCommands } from '../../../../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../../modules/custom-icon'
import { Navigable } from '../../../../../../../../../../modules/navigation'
import { webConfig } from '../../../../../../../../../../modules/web-config'
import { TypographyNoWrap } from '../../../../../../../../../TypographyNoWrap'
import {
  OnboardingTour,
  OnboardingWelcomeTourStep,
  useOnboardingConnect,
} from '../../../../../../../../modules/onboarding'
import { AccountData } from '../../../../../../modules/account-data'
import { MainPage } from '../../../MainPage'
import { Panel } from '../../../panel'
import { SideBar } from '../SideBar'
import { AddPageItem } from './AddPageItem'
import { NewPageItem } from './NewPageItem'
import { PageItem } from './PageItem'

const useStyles = makeStyles(
  theme => ({
    root: {
      height: 0,
      flex: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    pagesWrapper: {
      width: '100%',
      minHeight: '100%', // We should only have it while there is no more items next to the page list
      padding: theme.spacing(1),
      overflowY: 'auto',
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  }),
  { name: 'PageList' }
)

export function PageList() {
  const { accountPagesOrdered } = useContexts(AccountData.Contexts)
  const { panel } = useContexts(MainPage.Contexts)
  const { minimized } = useContext(SideBar.Context)

  const { connectOnboardingAnchorElement } = useOnboardingConnect(
    OnboardingTour.Welcome,
    OnboardingWelcomeTourStep.Pages,
    {
      padding: [0, 0, -1, -1],
      placement: 'right-start',
    }
  )

  const { runCommandStatic } = useManageCommands()

  const classes = useStyles()

  return (
    <div ref={connectOnboardingAnchorElement} className={classes.root}>
      {!webConfig.temporary.temporarilyDisabledOrToBeRemoved && (
        <Navigable group padding={[0, -4]}>
          {({ connectNavigable }) => (
            <div ref={connectNavigable} className={classes.titleWrapper}>
              {!minimized && <TypographyNoWrap variant="subtitle1">Pages</TypographyNoWrap>}

              <Navigable padding={1}>
                {({ connectNavigable: connectButton }) => (
                  <Tooltip title="New" placement={minimized ? 'right' : 'bottom'}>
                    <IconButton
                      ref={connectButton}
                      size="small"
                      disabled={panel?.type === Panel.Type.NewPage}
                      onClick={() => runCommandStatic('main.createNewPage')}
                    >
                      <CustomIcon name="Add" size={2.5} />
                    </IconButton>
                  </Tooltip>
                )}
              </Navigable>
            </div>
          )}
        </Navigable>
      )}

      <Paper elevation={0} className={classes.pagesWrapper}>
        {panel?.type !== Panel.Type.NewPage ? <AddPageItem /> : <NewPageItem />}

        {accountPagesOrdered.map(page => (
          <PageItem key={page.id} page={page} />
        ))}
      </Paper>
    </div>
  )
}

import { captureException } from '@sentry/react'

export async function unregisterServiceWorker(): Promise<void> {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready
      registration.unregister()
    } catch (error: any) {
      log.error('service worker', error.message)
      captureException(error, { tags: { module: 'service worker' } })
    }
  }
}

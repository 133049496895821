import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Input,
  List,
  makeStyles,
  Menu,
  MenuItem,
  Portal,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import { Keyboard } from '@zettelooo/commons'
import { ZettelExtensions } from '@zettelooo/extension-api'
import { Model } from '@zettelooo/server-shared'
import classNames from 'classnames'
import { useMemo, useRef, useState } from 'react'
import { useCopyToClipboard } from 'react-use'
import { arrayHelpers } from '../../../../../../../../../../helpers/native/arrayHelpers'
import { useContextMenu } from '../../../../../../../../../../hooks/useContextMenu'
import { sendAnalyticEvent } from '../../../../../../../../../../modules/analytics'
import { ChromeExtensionMode } from '../../../../../../../../../../modules/chrome-extension-mode'
import { useManageCommands } from '../../../../../../../../../../modules/commands'
import { useContexts } from '../../../../../../../../../../modules/contexts'
import { CustomIcon } from '../../../../../../../../../../modules/custom-icon'
import { webConfig } from '../../../../../../../../../../modules/web-config'
import { CustomAvatar } from '../../../../../../../../../CustomAvatar'
import { Gap } from '../../../../../../../../../Gap'
import { MenuItemWithSubMenu } from '../../../../../../../../../menu/MenuItemWithSubMenu'
import { TypographyNoWrap } from '../../../../../../../../../TypographyNoWrap'
import { useApplyAction } from '../../../../../../../../hooks/useApplyAction'
import { useAppNotistack } from '../../../../../../../../modules/app-notistack'
import { useConfirmationDialog } from '../../../../../../../../modules/confirmation-dialog'
import { Anchor } from '../../../../../../../Anchor'
import { Initializer } from '../../../../../../../Initializer'
import { getPagePublicUrl } from '../../../../../../helpers/getPagePublicUrl'
import { AccountData } from '../../../../../../modules/account-data'
import { PageAvatar } from '../../../../../PageAvatar'
import { Messages } from './Messages'
import { PagePanel } from './PagePanel'
import { SideBar } from './SideBar'

const useStyles = makeStyles(
  theme => ({
    root: {
      height: theme.spacing(8),
      padding: theme.spacing(0, 1, 0, 0.5),
    },
    titleWrapper: {
      width: 0,
      minWidth: '5em',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(0.5),
      whiteSpace: 'nowrap',
    },
    inputWrapper: {
      width: 0,
      textAlign: 'start',
      overflow: 'hidden',
    },
    input: {
      ...theme.typography.h5,
      marginLeft: theme.spacing(0.5),
    },
    avatarGroup: {
      flex: 'none',
      cursor: 'pointer',
    },
    menuIconButton: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    menuPaper: {
      minWidth: theme.spacing(15),
      maxWidth: theme.spacing(75),
    },
    shareMenuPaper: {
      padding: theme.spacing(1, 2),
    },
    isPublicContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      padding: theme.spacing(0, 2),
    },
    publicUrlContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 2),
    },
    publicUrl: {
      flexGrow: 1,
      width: 0,
      overflowWrap: 'anywhere',
      marginRight: theme.spacing(2),
    },
    delete: {
      color: theme.palette.error.main,
    },
  }),
  { name: 'Header' }
)

export function Header({
  extendedMenuItems,
}: {
  extendedMenuItems: readonly ZettelExtensions.LifeSpan.Shared.PagePanel.MenuItem[]
}) {
  const { allUsers } = useContexts(Initializer.Contexts)
  const { account } = useContexts(AccountData.Contexts)
  const {
    refs: { setSideBarMode, messagesRef },
    sideBarMode,
    page,
  } = useContexts(PagePanel.Contexts)

  const [editingPageName, setEditingPageName] = useState<string>()
  const [isUpsertingPage, setIsUpsertingPage] = useState(false)

  const messageContainerRef: Messages.ContainerRef = useRef(null)

  const [, copyToClipboard] = useCopyToClipboard()

  const { applyActionStatic } = useApplyAction()

  const { runCommandStatic } = useManageCommands()

  const { confirmStatic } = useConfirmationDialog()

  const { enqueueSnackbar } = useAppNotistack()

  const { handleContextMenuStatic, closeContextMenuStatic, isContextMenuOpenStatic, menuProps } = useContextMenu()

  async function updatePageName(): Promise<void> {
    if (isUpsertingPage || !page) return
    if (!editingPageName || editingPageName === page.name) {
      setEditingPageName(undefined)
      return
    }
    sendAnalyticEvent('Main Page > Page Panel > Header', 'Updated page name')
    setIsUpsertingPage(true)
    try {
      await applyActionStatic.updateModel({
        type: Model.Type.Page,
        id: page.id,
        name: editingPageName,
      })
    } finally {
      setEditingPageName(undefined)
      setIsUpsertingPage(false)
    }
  }

  const publicUrl = useMemo(() => (page ? getPagePublicUrl(page) : ''), [page])

  const classes = useStyles()

  const canNotLeave = page.memberUserIds.length === 1 && page.memberUserIds[0] === account.id

  return (
    <>
      <Grid container wrap="nowrap" alignItems="center" spacing={1} className={classes.root}>
        <Grid item>
          <PageAvatar
            page={page}
            size={3.5}
            compact
            noBackgroundColor
            popoverExpands="left"
            disableUpdateOnClick={isUpsertingPage}
            onUpdateStarted={() => {
              sendAnalyticEvent('Main Page > Page Panel > Header', 'Updated page avatar')
              setIsUpsertingPage(true)
            }}
            onUpdateFinished={() => setIsUpsertingPage(false)}
          />
        </Grid>

        {editingPageName === undefined ? (
          <Grid item xs className={classes.titleWrapper}>
            <Typography variant="h5" noWrap className={classes.title}>
              {page.name}
            </Typography>
            {page.public && (
              <Chip
                label={
                  <Anchor href={publicUrl} target="_blank">
                    <strong>Public page</strong>
                  </Anchor>
                }
                color="secondary"
              />
            )}
          </Grid>
        ) : (
          <Grid item xs className={classes.inputWrapper}>
            <Input
              fullWidth
              autoFocus
              disableUnderline
              className={classes.input}
              disabled={isUpsertingPage}
              placeholder="Name..."
              value={editingPageName}
              onChange={event => setEditingPageName(event.target.value)}
              onSubmit={updatePageName} // It does not work as I was expecting it
              onBlur={updatePageName}
              onKeyDown={event => {
                if (isUpsertingPage) return
                const code = event.code as Keyboard.NativeEventCode
                if (code === 'Enter' || code === 'NumpadEnter') {
                  updatePageName()
                } else if (code === 'Escape') {
                  setEditingPageName(undefined)
                }
              }}
            />
          </Grid>
        )}

        {page.memberUserIds.length > 1 && (
          <Grid item>
            <AvatarGroup
              max={5}
              spacing="small"
              className={classes.avatarGroup}
              onClick={() => setSideBarMode(SideBar.Mode.Members)}
            >
              {page.memberUserIds
                .map(memberUserId => allUsers.dictionary[memberUserId]!)
                .filter(Boolean)
                .map(user => (
                  <CustomAvatar
                    key={user.id}
                    size={4}
                    avatarFileId={user.avatarFileId ?? null}
                    name={user.name}
                    color={user.color}
                  />
                ))}
            </AvatarGroup>
          </Grid>
        )}

        {page.memberUserIds.length <= 1 && !ChromeExtensionMode.isActive && (
          <Grid item>
            <Button
              size="small"
              onClick={() => {
                setSideBarMode(SideBar.Mode.Members)
                runCommandStatic('main.inviteMembers')
              }}
            >
              <CustomIcon name="AddMember" size={2.5} />
              <Gap horizontal={1} />
              Invite
            </Button>
          </Grid>
        )}

        {ChromeExtensionMode.isActive && (
          <Grid item>
            <Button
              size="small"
              onClick={() => {
                sendAnalyticEvent('Main Page > Page Panel > Header', 'Toggle extension store')
                setSideBarMode(SideBar.Mode.QuickActions)
              }}
            >
              <CustomIcon name="LogoColored" size={2.5} />
              <Gap horizontal={1} />
              Module Store
            </Button>
          </Grid>
        )}

        <Grid item>
          <IconButton
            size="small"
            className={classes.menuIconButton}
            onClick={event => {
              sendAnalyticEvent('Main Page > Page Panel > Header', 'Open page context menu')
              handleContextMenuStatic(event)
            }}
            onContextMenu={event => {
              sendAnalyticEvent('Main Page > Page Panel > Header', 'Open page context menu')
              handleContextMenuStatic(event)
            }}
          >
            <CustomIcon name="HorizontalDots" size={3.5} />
          </IconButton>

          <Menu
            {...menuProps}
            classes={{
              ...menuProps.classes,
              paper: classNames(classes.menuPaper, menuProps.classes?.paper),
            }}
            MenuListProps={{ disablePadding: true }}
          >
            <List>
              <MenuItem
                disabled={isUpsertingPage || editingPageName !== undefined}
                onClick={() => {
                  closeContextMenuStatic()
                  sendAnalyticEvent('Main Page > Page Panel > Header > Menu', 'Edit page name')
                  if (!page) return
                  // In order to let the context menu be closed first, since we need the the input to be automatically focused:
                  setTimeout(() => setEditingPageName(page.name))
                }}
              >
                <TypographyNoWrap variant="subtitle2">Edit name</TypographyNoWrap>
              </MenuItem>

              <MenuItemWithSubMenu
                disabled={isUpsertingPage}
                onCloseSubMenu={options => {
                  if (options?.done) {
                    closeContextMenuStatic()
                  }
                }}
                subMenu={({ popoverProps, closeSubMenu }) => (
                  <Menu {...popoverProps} classes={{ paper: classes.shareMenuPaper }}>
                    <div className={classes.isPublicContainer}>
                      <TypographyNoWrap variant="subtitle2">Anyone with the link can view the page</TypographyNoWrap>
                      <Switch
                        color="primary"
                        disabled={isUpsertingPage}
                        checked={page.public}
                        onChange={async (_, checked) => {
                          setIsUpsertingPage(true)
                          sendAnalyticEvent('Main Page > Page Panel > Header > Menu', 'Switch public page', { checked })
                          await applyActionStatic.updateModel({
                            type: Model.Type.Page,
                            id: page.id,
                            public: checked,
                          })
                          if (checked) {
                            messagesRef.current?.registerMessageContainerRef(messageContainerRef, {
                              variant: 'information',
                            })
                          } else {
                            messagesRef.current?.unregisterMessageContainerRef(messageContainerRef)
                          }
                          setIsUpsertingPage(false)
                        }}
                      />
                    </div>

                    {page.public && (
                      <div className={classes.publicUrlContainer}>
                        <Tooltip title={publicUrl}>
                          <Anchor className={classes.publicUrl} href={publicUrl} target="_blank">
                            <Typography variant="subtitle2" color="textSecondary" noWrap>
                              {publicUrl}
                            </Typography>
                          </Anchor>
                        </Tooltip>

                        <Tooltip title="Copy to clipboard">
                          <IconButton
                            size="small"
                            onClick={() => {
                              closeSubMenu({ done: true })
                              sendAnalyticEvent('Main Page > Page Panel > Header > Menu', 'Copy public page URL')
                              copyToClipboard(publicUrl)
                              enqueueSnackbar('Link is copied to the clipboard!', publicUrl, { variant: 'success' })
                            }}
                          >
                            <CustomIcon name="Copy" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </Menu>
                )}
              >
                <TypographyNoWrap variant="subtitle2">Get public page URL</TypographyNoWrap>
              </MenuItemWithSubMenu>

              <MenuItem
                disabled={isUpsertingPage}
                onClick={async () => {
                  closeContextMenuStatic()
                  sendAnalyticEvent('Main Page > Page Panel > Header > Menu', 'Delete/leave page', {
                    delete: canNotLeave,
                  })
                  if (!page) return
                  const confirmed = await confirmStatic({
                    title: canNotLeave ? 'Delete page' : 'Leave page',
                    content: `Are you sure to ${canNotLeave ? 'delete' : 'leave'} this page?`,
                    confirmLabel: `${canNotLeave ? 'Delete' : 'Leave'} page`,
                    cancelLabel: 'Cancel',
                  })
                  if (!confirmed) return
                  setIsUpsertingPage(true)
                  if (canNotLeave) {
                    await applyActionStatic.deleteModel(page)
                  } else {
                    await applyActionStatic.updateModel({
                      type: Model.Type.Page,
                      id: page.id,
                      memberUserIds: arrayHelpers.remove(page.memberUserIds, account.id),
                    })
                  }
                  setIsUpsertingPage(false)
                  if (canNotLeave) {
                    enqueueSnackbar('Success', 'Page is deleted.', { variant: 'info' })
                  } else {
                    enqueueSnackbar('Success', "You've left the page.", { variant: 'info' })
                  }
                }}
              >
                <TypographyNoWrap variant="subtitle2" className={classes.delete}>
                  {canNotLeave ? 'Delete' : 'Leave'}
                </TypographyNoWrap>
              </MenuItem>
            </List>

            <Divider />

            <List>
              {!webConfig.app.cashFlowPurpose && (
                <MenuItem
                  selected={!sideBarMode || sideBarMode === SideBar.Mode.CommandLine}
                  onClick={() => {
                    closeContextMenuStatic()
                    sendAnalyticEvent('Main Page > Page Panel > Header > Menu', 'Open command line')
                    setSideBarMode(undefined)
                  }}
                >
                  <TypographyNoWrap variant="subtitle2">Command line</TypographyNoWrap>
                </MenuItem>
              )}

              <MenuItem
                selected={(!sideBarMode && webConfig.app.cashFlowPurpose) || sideBarMode === SideBar.Mode.QuickActions}
                onClick={() => {
                  closeContextMenuStatic()
                  sendAnalyticEvent('Main Page > Page Panel > Header > Menu', 'Open quick actions')
                  setSideBarMode(webConfig.app.cashFlowPurpose ? undefined : SideBar.Mode.QuickActions)
                }}
              >
                <TypographyNoWrap variant="subtitle2">Quick Actions</TypographyNoWrap>
              </MenuItem>

              <MenuItem
                selected={sideBarMode === SideBar.Mode.ExtensionStore}
                onClick={() => {
                  closeContextMenuStatic()
                  sendAnalyticEvent('Main Page > Page Panel > Header > Menu', 'Open extension store')
                  setSideBarMode(SideBar.Mode.ExtensionStore)
                }}
              >
                <TypographyNoWrap variant="subtitle2">Module Store</TypographyNoWrap>
              </MenuItem>

              <MenuItem
                selected={sideBarMode === SideBar.Mode.Members}
                onClick={() => {
                  closeContextMenuStatic()
                  sendAnalyticEvent('Main Page > Page Panel > Header > Menu', 'Open members')
                  setSideBarMode(SideBar.Mode.Members)
                }}
              >
                <TypographyNoWrap variant="subtitle2">Members</TypographyNoWrap>
              </MenuItem>
            </List>

            {extendedMenuItems.length > 0 && <Divider />}

            {extendedMenuItems.length > 0 && (
              <List>
                {extendedMenuItems.map((extendedMenuItem, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      closeContextMenuStatic()
                      sendAnalyticEvent('Main Page > Page Panel > Header > Menu', 'Extended menu item', {
                        title: extendedMenuItem.title,
                      })
                      extendedMenuItem.handler()
                    }}
                  >
                    {extendedMenuItem.title}
                  </MenuItem>
                ))}
              </List>
            )}
          </Menu>
        </Grid>
      </Grid>

      {messageContainerRef.current && (
        <Portal container={messageContainerRef.current}>
          <Typography variant="body1">The page is now public and can be accessed anonymously via this link:</Typography>
          <Typography variant="body2" color="textSecondary" component="div">
            <Anchor
              href={publicUrl}
              target="_blank"
              onClick={() => sendAnalyticEvent('Main Page > Page Panel > Header', 'Click on public page URL message')}
            >
              <pre>{publicUrl}</pre>
            </Anchor>
          </Typography>
        </Portal>
      )}
    </>
  )
}
